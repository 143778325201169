import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import { DataGrid, Column, SortByGroupSummaryInfo, GroupItem, Summary, FilterRow } from 'devextreme-react/data-grid';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Redirect } from "react-router-dom";
import { withStyles, createStyles } from '@mui/styles';
import Chip from '@mui/material/Chip';
const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Site {
    id: number;
    siteName: string;
    siteRef: string
    from: string;
    generation: number;
}

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    groupId: number;
    siteOptions: { value: number; label: string }[];
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    sites: Site[];
    clickSite: Site | null;
    anchorEl: HTMLElement | null;
    redirect: boolean;
    redirectPath: string
    redirectProps: {
        siteName: string
        siteId: number | null
        serial: string | null
    }
}

class SitesPanel extends React.Component<Props, State> {
    gridRef: React.RefObject<DataGrid>;

    constructor(props: Readonly<Props>) {
        super(props);
        this.gridRef = React.createRef();

        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            sites: [],
            clickSite: null,
            anchorEl: null,
            redirect: false,
            redirectPath: "",
            redirectProps: {
                siteName: "",
                siteId: null,
                serial: ""
            }
        }
        this.renderSiteName.bind(this)
    }

    componentDidMount(): void {
        //get detail on logger here
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/GetAllSitesForGroup?groupid=' + me.props.groupId),{})
            .then(data => {
                const sites: Array<Site> = [];
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        const lookup = me.props.siteOptions.filter(s => s.value == data[i].memberId);
                        if (lookup.length > 0) {
                            console.log(lookup, data)
                            const site: Site = {
                                id: data[i].groupId,
                                siteName: lookup[0].siteName,
                                siteRef: lookup[0].siteRef,
                                from: data[i].groupName,
                                generation: data[i].generation,
                            };
                            sites.push(site);
                        }
                    }
                }

                me.setState({
                    sites: sites
                });

            })
            .catch(function () {
                me.setState(
                    {
                        authorized: false,
                    })
            });

        this.setState({ loading: false, tableHidden: false });
    }

    moreRender(key: any): React.ReactNode {
        return (
            <IconButton size="small" onClick={(e: React.MouseEvent<HTMLElement>): void => { this.handleClick(e, key.key); }}><MoreHorizIcon /></IconButton>
        );
    }

    handleClick(event: React.MouseEvent<HTMLElement>, id: number): void {
        this.setState(
            {
                clickSite: this.state.sites.filter(s => s.id == id)[0],
                anchorEl: event.currentTarget
            });
    }

    handleChipClick(data): void {
        CallGetAPI(CreateUrl('/api/aquaguard/SitesForCompany?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(sites => {
                const clickedSite = sites.filter(site => site.siteName === data.siteName)[0]
                console.log(clickedSite)
                this.setState({
                    redirect: true,
                    redirectPath: '/portal/SiteDetail',
                    redirectProps: {
                        siteName: clickedSite.siteNameUserFriendly || clickedSite.siteName,
                        siteId: clickedSite.siteId,
                        serial: '',
                        deviceId: null
                    }
                
                })
            })
        console.log(data)
    }

    renderSiteName(data): React.ReactNode {
        return <Chip
            label={data.data.siteName}
            onClick={():void => this.handleChipClick(data.data) }
        />
    }

    render(): ReactNode {

        return (!this.state.redirect ?

            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                    {!this.state.tableHidden &&
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                            <div>
                                <DataGrid
                                    dataSource={this.state.sites}
                                    ref={this.gridRef}
                                    key="id"
                                    allowColumnResizing={true}
                                    allowColumnReordering={true}
                                        >
                                    <FilterRow visible={true} />
                                    <Column dataField="id" visible={false} dataType="string" />
                                            <Column dataField="siteName" visible={true} dataType="string" cellRender={this.renderSiteName.bind(this)} />
                                    <Column dataField="siteRef" visible={true} dataType="string" />
                                    <Column dataField="from" visible={true} dataType="string" groupIndex={0} />

                                    <Summary>
                                        {/*<GroupItem*/}
                                        {/*    column="generation"*/}
                                        {/*    summaryType="min"*/}
                                        {/*    //displayFormat="Level: {0}"*/}
                                        {/*    showInColumn={true}*/}
                                        {/*/>*/}
                                    </Summary>
                                    <SortByGroupSummaryInfo
                                        summaryItem={0}
                                        sortOrder="asc"
                                    />
                                </DataGrid>
                            </div>

                            </GridItem>
                        </GridContainer>

                    }
                    </div>
                }

            </div> :
            <Redirect push to={{
                pathname: this.state.redirectPath,
                state: this.state.redirectProps
            }} />

        )
    }

}

export default withStyles(style)(SitesPanel);