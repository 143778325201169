import React from "react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Chip, Paper, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { withStyles, createStyles, withTheme } from '@mui/styles';
const style = createStyles(styles);

export class SiteDeviceChips extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { theme, data, added, deleteNeeded, deviceDetailsClick, removeDevice } = this.props;

        return (
            <Paper
                component="ul"
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    padding: 0.5,
                    margin: "0 0 0 5px",
                    boxShadow: "none",
                    background: "none"
                }}
            >
                {data?.map((data) => {
                    let color = 'primary';

                    if (data.defaultGps) {
                        color = "error";
                    }

                    if (added && added.length > 0) {
                        added.forEach(device => {
                            if (device.serial === data.serial) {
                                color = device.hasGps ? 'grey' : 'error';
                            }
                        });
                    }


                    const chipStyles =
                        color === 'grey'
                            ? {
                                backgroundColor: theme.palette.grey.main,
                                color: theme.palette.grey.contrastText,
                                '& .MuiChip-deleteIcon': {
                                    color: 'black', // Default color
                                    '&:hover': {
                                        color: 'black', // Hover color
                                    },
                                }
                            }
                            : {
                                backgroundColor: theme.palette[color]?.main,
                                color: theme.palette[color]?.contrastText,
                                '& .MuiChip-deleteIcon': {
                                    color: color === 'error' ? 'white' : 'black', // Default color
                                    '&:hover': {
                                        color: color === 'error' ? 'white' : 'black', // Hover color
                                    },
                                },
                                '&:hover': {
                                    backgroundColor: theme.palette[color]?.main
                                },
                            };

                    return deleteNeeded ? (
                        <li key={data.key}>
                            <Chip
                                style={{ margin: '2px' }}
                                sx={chipStyles}
                                label={data.serial}
                                onClick={() => deviceDetailsClick(data.serial, data.loggerId)}
                                onDelete={() => removeDevice(data)}
                                key={data.serial}
                                
                            />
                        </li>
                    ) : (
                        <li key={data.key}>
                            <Chip
                                style={{ margin: '2px' }}
                                color="primary"
                                label={data.serial}
                                onClick={() => deviceDetailsClick(data.serial, data.loggerId)}
                                key={data.serial}
                                    sx={{
                                        '& .MuiChip-deleteIcon': {
                                            color: 'black', // Default color
                                            '&:hover': {
                                                color: 'black', // Hover color
                                            },
                                        },
                                    }}
                            />
                        </li>
                    );
                })}
            </Paper>
        );
    }
}
export default withTheme(SiteDeviceChips);