import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { DataGrid, Column, FilterRow, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';

import ClipLoader from "react-spinners/ClipLoader";
//modal window imports
//import InputLabel from '@mui/material/InputLabel';
//import Select from '@mui/material/Select';
//import FormControl from '@mui/material/FormControl';
import { Grid , Switch} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Select from '@mui/material/Select'
// import makeAnimated from 'react-select/animated';
import { CreateUrl, CallGetAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";
import { injectIntl, FormattedMessage } from 'react-intl';
import deMessages from "devextreme/localization/messages/de.json";
import frMessages from "devextreme/localization/messages/fr.json";
import esMessages from "devextreme/localization/messages/es.json";
import ptMessages from "devextreme/localization/messages/pt.json";
import { locale, loadMessages } from "devextreme/localization";
import DateBox, { DateBoxTypes } from 'devextreme-react/date-box';

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

interface Company {
    id: number;
    parentId: number;
    parentCompany: string;
    name: string;
    companyGuid: string;
    contactEmail: string;
    listenerName: string;
    listenerIp: string;
    listenerPort: string;
    listenerCopies: number;
    more: React.ReactNode;
    utcOffset: string;
    calcMNF: boolean
    MNFStart: Date | null;
    MNFEnd: Date | null
}

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
    };
}


interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    modal: boolean;
    selectedUser: number;
    colour: string;
    edit: boolean;
    orgCompany: string | undefined;
    editCompany: boolean;
    tempCompany: Company;
    anchorEl: HTMLElement | null;
    redirect: boolean;
    redirectPath: string;
    redirectProps: {
        userName: string;
        userId: number;
    } | null;
    modalTitle: string;
    adminEmail: string;
    allowSave: boolean;
    canChange: boolean;
    showIPStuff: boolean;
    companies: Array<Companies>

}

export class Companies extends Component<Props, State> {
    store: ArrayStore;
    gridRef: React.RefObject<DataGrid>;
    parentCompanies: Array<Company> = [];


    constructor(props: Readonly<Props>) {

        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: []
        });

        this.state = {
            loading: true,
            tableHidden: false,
            visible: false,
            modal: false,
            selectedUser: 0,
            colour: '',
            edit: false,
            editCompany: false,
            orgCompany: undefined,
            tempCompany: {
                id: 0,
                parentId: 0,
                parentCompany: "",
                name: "",
                contactEmail: "",
                companyGuid: "",
                listenerName: "",
                listenerIp: "",
                listenerPort: "",
                listenerCopies: 0,
                more: <span></span>,
                utcOffset: "0",
                calcMNF: false,
                MNFStart: moment().set({ hour: 2, minute: 0, second: 0 }).toDate(),
                MNFEnd: moment().set({ hour: 4, minute: 0, second: 0 }).toDate()

            },
            companies: [],
            anchorEl: null,
            authorized: true,
            redirect: false,
            redirectPath: '',
            redirectProps: null,
            modalTitle: "",
            adminEmail: "",
            allowSave: false,
            //identity admin or zonelog gutermann useradmin
            canChange: (sessionStorage.getItem('userLevel') === 'identityadmin' ||

                (sessionStorage.getItem('userLevel') === 'useradmin'
                    && window.location.hostname.includes('zonelog')
                    && sessionStorage.getItem('companyId') === '57'
                )),
            //just identity admin - zonelog have NO TOR Units so IP stuff not needed
            showIPStuff: (sessionStorage.getItem('userLevel') === 'identityadmin'),

        };

        this.gridRef = React.createRef();
        this.store.clear();

    }



    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createFailedInsertErrorNotification = (err: string): void => {
        NotificationManager.error(err, 'Error creating new record', 5000);
    };

    componentDidMount(): void {
        if (sessionStorage.getItem('portalUpdate') === 'true') {
            this.clearState()
        }
        this.reloadData(null);
        
    }

    clearState = (): void => {
        this.gridRef.current?.instance.state(null);
    }

    // if editId is not null, then reload() re-displays Modal with record id = editId after refresh
    reloadData = (editId: number | null): void => {
        const me = this;

      
        CallGetAPI(CreateUrl('/api/aquaguard/Companies'), {})
            .then(data => {
                if (data.length > 0) {

                    // Copy the data records into deviceData, adding the clickEvent
                    const records = [];
                    for (let i = 0; i < data.length; i++) {
                        const id = data[i].id;
                        let mnfStart = moment().set({ hour: 2, minute: 0, second: 0 }).toDate()
                        let mnfEnd = moment().set({ hour: 4, minute: 0, second: 0 }).toDate()
                        if (data[i].MNFStart && data[i].MNFEnd) {
                            mnfStart = data[i].MNFStart
                            mnfEnd = data[i].MNFEnd
                        } 
                        if (!data[i].calcMNF) {
                            mnfStart = null
                            mnfEnd = null
                        }

                        const rec: Company = {
                            id: id,
                            parentId: data[i].parentId != null ? data[i].parentId : 0,
                            parentCompany: data[i].parentCompany,
                            name: data[i].name,
                            contactEmail: data[i].contactEmail,
                            companyGuid: data[i].companyGuid,
                            utcOffset: data[i].utcOffset,
                            calcMNF: data[i].calcMNF,
                            listenerName: data[i].listenerName == null ? "Not configured" : data[i].listenerName,
                            listenerIp: data[i].listenerIP == null ? "Not configured" : data[i].listenerIP,
                            listenerPort: data[i].listenerPort == null ? "Not configured" : data[i].listenerPort,
                            listenerCopies: data[i].listenerCopies == null ? "Not configured" : data[i].listenerCopies,
                            MNFStart: mnfStart,
                            MNFEnd: mnfEnd,
                            more: <IconButton onClick={(e): void => { me.handleClick(e, id) }} size="large"><MoreHorizIcon /></IconButton>
                        };

                        //used for companies grid
                        records.push(rec);

                        //used for parent company dropdown
                        me.parentCompanies.push(rec);
                    }

                    //me.store.push(records);

                    me.setState(
                        {
                            companies: records,
                            tableHidden: false,
                            loading: false
                        })
                }
                else {
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function () {
                me.setState(
                    {
                        authorized: true
                    })
            });


    }

   
    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    //new row
    newCompany = (): void => {
        this.setState({
            modal: true,
            editCompany: false,
            tempCompany: {
                id: 0,
                parentId: 0,
                parentCompany: '',
                name: '',
                companyGuid: '',
                contactEmail: '',
                listenerName: '',
                listenerIp: '',
                listenerPort: '',
                listenerCopies: 0,
                utcOffset: '0',
                calcMNF: false,
                MNFStart: moment().set({ hour: 2, minute: 0, second: 0 }).toDate(),
                MNFEnd: moment().set({ hour: 4, minute: 0, second: 0 }).toDate(),
                more: <span></span>,
            },
            modalTitle: "Set Up a New Company",
            allowSave: false,
        });

    }
    //edit row
    editCompany = (): void => {
        this.setState({
            editCompany: true,
            modal: !this.state.modal,
            anchorEl: null,
            modalTitle: "Modify Company Details",
            orgCompany: this.state.tempCompany?.name,
            allowSave: true,
        });
    }

    //save changes, for both edit and new
    saveCompany = (): void => {

        const me = this;

        if (this.state.editCompany === false) { //adding a new Company

            this.setState({
                //modal: false,
                loading: true
            });

            CallGetAPI(CreateUrl('/api/aquaguard/userExists?email=' + encodeURIComponent(me.state.adminEmail)), {})
                .then(response => {
                    if (response !== true) {
                        //********************
                        //send model to API

                        let requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                Name: me.state.tempCompany?.name,
                                contactEmail: me.state.tempCompany?.contactEmail,
                                companyGuid: null,
                                parentId: me.state.tempCompany?.parentId,
                                listenerIP: me.state.tempCompany?.listenerIp,
                                listenerPort: me.state.tempCompany?.listenerPort,
                                listenerCopies: me.state.tempCompany?.listenerCopies,
                                utcOffset: parseInt(me.state.tempCompany?.utcOffset),
                                calcMNF: me.state.tempCompany.calcMNF,
                                startTimeMNF: me.state.tempCompany.MNFStart,
                                endTimeMNF: me.state.tempCompany.MNFEnd
                            })
                        };

                        CallGetAPI(CreateUrl('/api/aquaguard/Company'), requestOptions)
                            .then(data => {
                                if (data.status === undefined) {
                                    const company: Company = me.state.tempCompany;
                                    company.id = data.id
                                    company.parentId = data.parentId;
                                    company.parentCompany = data.parentCompany;
                                    company.name = data.name;
                                    company.companyGuid = data.companyGuid;
                                    company.contactEmail = data.contactEmail;
                                    company.listenerIp = data.listenerIp;
                                    company.listenerPort = data.listenerPort;
                                    company.listenerCopies = data.listenerCopies;
                                    company.utcOffset = data.utcOffset;
                                    company.calcMNF = data.calcMNF;
                                    company.MNFStart = data.MNFStart,
                                    company.MNFEnd = data.MNFEnd
                                    //add to grid array store
                                    //me.store.push([{ type: 'insert', key: company.id, data: company }]);
                                    //need to call reload data to get parent details in the grid - see below

                                    me.setState({
                                        tempCompany: company,
                                    });

                                    requestOptions = {
                                        method: 'POST',
                                        headers: { 'Content-Type': 'application/json' },
                                        body: JSON.stringify({
                                            userName: me.state.adminEmail,
                                            companyGuid: company.companyGuid,
                                            companyName: company.name,
                                            level: "useradmin"
                                        })
                                    };

                                    CallGetAPI(CreateUrl('/api/aquaguard/AdminUser'), requestOptions)
                                        .then(data => {

                                            if (data.status === undefined) {
                                                me.createSuccessNotification();
                                            }
                                            else {
                                                me.createFailedInsertErrorNotification(data.statusText);

                                            }
                                            me.toggleModal();
                                            me.setState({
                                                loading: false,
                                            });
                                        })
                                        .catch(function (error) {
                                            me.createFailedInsertErrorNotification(error.statusText);
                                            console.log(error);
                                            me.toggleModal();
                                            me.setState({
                                                loading: false,
                                            });
                                        });


                                    //to refresh the grid properly
                                    me.reloadData(null);
                                }
                                else {
                                    me.createFailedInsertErrorNotification(data.statusText);
                                    me.toggleModal();

                                    me.setState({
                                        loading: false,
                                    });
                                }
                            })
                            .catch(function (error) {
                                me.createFailedInsertErrorNotification(error.statusText);
                                console.log(error)
                                me.toggleModal();
                                me.setState({
                                    loading: false,
                                });
                            });
                    }
                    else
                    {
                        me.createFailedInsertErrorNotification("Admin User already exists for another company.");
                        me.toggleModal();
                        me.setState({
                            loading: false,
                        });
                    }
                })
                .catch(function (ex) {
                    me.createFailedInsertErrorNotification(ex);
                    me.toggleModal();
                    me.setState({
                        loading: false,
                    });
                });
           

        }
        else {//modifying an existing Company

            //********************
            //send model to API
            
            //save User
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: me.state.tempCompany.id,
                    name: me.state.tempCompany.name,
                    companyGuid: me.state.tempCompany.companyGuid,
                    contactEmail: me.state.tempCompany.contactEmail,
                    utcOffset: parseInt(me.state.tempCompany?.utcOffset),
                    calcMNF: me.state.tempCompany.calcMNF,
                    startTimeMNF: me.state.tempCompany.MNFStart,
                    endTimeMNF: me.state.tempCompany.MNFEnd
                })
            };



            CallPutAPI(CreateUrl('/api/Aquaguard/Company/' + me.state.tempCompany.id), requestOptions)
                .then(async response => {
                    if (response.status == 204) {

                        me.createSuccessNotification()

                        me.store.push([{ type: 'update', key: me.state.tempCompany.id, data: me.state.tempCompany }]);

                        me.setState({
                            modal: !me.state.modal,
                            loading: false,
                            
                        });
                        setTimeout(me.reloadData(), 500)
                    }
                    else {
                        me.toggleModal();
                        me.setState({
                            loading: false,
                        });
                    }


                })
                .catch(function (error) {
                    me.createErrorNotification()
                    me.toggleModal();
                    console.log(error)
                    me.setState({
                        loading: false,
                    });
                });

        }
    }


    //do nothing, close the modal
    cancel = (): void => {
        this.setState({
            anchorEl: null
        });
        this.toggleModal();

    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    //field changed events---
    companyNameChanged = (event: any): void => {
        const tempCompany = this.state.tempCompany;
        tempCompany.name = event.target.value;
        this.setState( {
            tempCompany: tempCompany,
            allowSave: this.checkSave(event.target.value, this.state.tempCompany.contactEmail, this.state.adminEmail, this.state.tempCompany.listenerCopies, this.state.tempCompany.listenerIp, this.state.tempCompany.listenerPort),
            });
    }

    companyEmailChanged = (event: any): void => {
        const tempCompany = this.state.tempCompany;
        tempCompany.contactEmail = event.target.value;
        this.setState({
            tempCompany: tempCompany,
            allowSave: this.checkSave(this.state.tempCompany.name, event.target.value, this.state.adminEmail, this.state.tempCompany.listenerCopies, this.state.tempCompany.listenerIp, this.state.tempCompany.listenerPort),
        });
    }

    utcOffsetChanged = (event: any): void => {
        const tempCompany = this.state.tempCompany
        tempCompany.utcOffset = event.target.value
        this.setState({
            tempCompany: tempCompany
        })
    }

    handleMNFChange = (event): void => {
        const tempCompany = this.state.tempCompany
        tempCompany.calcMNF = event.target.checked
        this.setState({
            tempCompany: tempCompany
        })
    }

    companyParentChanged = (event: any): void => {
        const tempCompany = this.state.tempCompany;
        tempCompany.parentId = event.target.value;
        this.setState({
            tempCompany: tempCompany,
        });
    }

    adminEmailChanged = (event: any): void => {
        this.setState({
            adminEmail: event.target.value,
            allowSave: this.checkSave(this.state.tempCompany.name, this.state.tempCompany.contactEmail, event.target.value, this.state.tempCompany.listenerCopies, this.state.tempCompany.listenerIp, this.state.tempCompany.listenerPort),
        });
    }

    listenerIpChanged = (event: any): void => {

        //ipaddress can be null - no listener, copies will be 0
        const tempCompany = this.state.tempCompany;
        tempCompany.listenerIp = event.target.value;
        this.setState({
            tempCompany: tempCompany,
            allowSave: this.checkSave(this.state.tempCompany.name, this.state.tempCompany.contactEmail, this.state.adminEmail, this.state.tempCompany.listenerCopies, event.target.value, this.state.tempCompany.listenerPort),
        });
    }

    listenerPortChanged = (event: any): void => {
        const tempCompany = this.state.tempCompany;
        tempCompany.listenerPort = event.target.value;
        // if not null check valid port
        this.setState({
            tempCompany: tempCompany,
            allowSave: this.checkSave(this.state.tempCompany.name, this.state.tempCompany.contactEmail, this.state.adminEmail, this.state.tempCompany.listenerCopies, this.state.tempCompany.listenerIp, event.target.value),
        });
    }

    listenerCopiesChanged = (event: any): void => {
        if (!isNaN(parseInt(event.target.value))) {
            const tempCompany = this.state.tempCompany;
            tempCompany.listenerCopies = parseInt(event.target.value);
            this.setState({
                tempCompany: tempCompany,
                allowSave: this.checkSave(this.state.tempCompany.name, this.state.tempCompany.contactEmail, this.state.adminEmail, tempCompany.listenerCopies, this.state.tempCompany.listenerIp, this.state.tempCompany.listenerPort),
            });
        }
    }

    checkSave = (name: string, contact: string, admin: string, copies: number, ip: string, port: string): boolean => {
        return name != ""
            && (contact != "" && this.checkIfValidEmail(contact))
            && ( (admin != "" && this.checkIfValidEmail(admin)) || this.state.editCompany)
            && copies <= 10
            && (ip == "" || (ip != "" && (this.checkIfValidIP(ip) && !isNaN(parseInt(port)))));
    }

    checkIfValidIP = (ipAddress: string): boolean => {
        // Regular expression to check if string is a IP address
        //https://melvingeorge.me/blog/check-if-string-is-valid-ip-address-javascript
        const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
        const result = regexExp.test(ipAddress);
        return result;
    }

    checkIfValidEmail = (emailAddress: string): boolean => {
        // Regular expression to check if string is a email address
        //https://stackoverflow.com/questions/940577/javascript-regular-expression-email-validation
        //const regexExp = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;  this fails if . before @
        const regexExp = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
        const result = regexExp.test(emailAddress);
        return result;
    }

    //-------------------------

    handleClick(event: any, id: number): void {
        const selectedCompany = this.state.companies.filter(company => company.id === id)[0]

            this.setState(
                {
                    tempCompany: selectedCompany,
                    anchorEl: event.currentTarget,
                });
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm");
    }

    moreRender(data: any): React.ReactNode {
        return (
            <IconButton size="small" onClick={(e): void => { this.handleClick(e, data.data.id); }}><MoreHorizIcon /></IconButton>
        );
    }

    renderMNF = (data) => {
        return <p>{data.data.calcMNF ? 'true' : 'false' }</p>
    }

    handleTimeChange = (date: Date, type: string): void => {
        const tempCompany = this.state.tempCompany
        if (type === 'start') {
            tempCompany.MNFStart = date
        } else {
            tempCompany.MNFEnd = date
        }
        this.setState({tempCompany: tempCompany})
    }



    render(): React.ReactNode {
        const { classes, intl } = this.props;


        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                <div>

                    <Modal
                        show={this.state.modal}
                        onHide={(): void => this.toggleModal()}
                        dialogClassName="modal-50w"
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                        style={{marginTop: "50px"} }
                        >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {this.state.modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            {!this.state.editCompany &&
                                <div>
                                    {this.state.showIPStuff && "If no TCP IP listener, enter zero and leave IP / Port blank. Otherwise enter IP address and starting port, ports will be auto incremented according to the number of Copies (listeners) required."}
                                </div>
                            }
                            <hr/>
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <div style={{ padding: 10, width: "100%" }}>
                                        <TextField
                                            id="outlined-input-name"
                                            label="Company Name"
                                            value={this.state.tempCompany.name}
                                            onChange={this.companyNameChanged}
                                            variant="outlined"
                                            style={{width: "100%"} }
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} >
                                    {this.state.editCompany &&
                                    <div style={{ padding: 10 }}>
                                        <TextField
                                            id="outlined-input-name"
                                            disabled={true}
                                            label="Parent Company Name"
                                            value={this.state.tempCompany.parentCompany}
                                            variant="outlined"
                                            inputProps={
                                                { readOnly: true, }
                                            }
                                            style={{width: "100%"} }
                                        />
                                    </div>
                                    }
                                </Grid>
                                {!this.state.editCompany &&
                                    <Grid item xs={12}>
                                        <div style={{ margin: "-30px 10px 10px 10px" }}>
                                            <InputLabel>Select a Parent Company: {!this.state.showIPStuff && " (eg Gutermann Main)"}</InputLabel>
                                            <Select
                                                id="outlined-select"
                                                label="Parent"
                                                value={this.state.tempCompany.parentId ? this.state.tempCompany.parentId : 0}
                                                onChange={this.companyParentChanged}
                                                variant="outlined"
                                                style={{width: "100%"} }
                                            >
                                                <MenuItem value={0}>None (New Parent Company)</MenuItem>
                                                {this.parentCompanies.map((option): React.ReactNode => {
                                                    if (!option.parentCompany) {
                                                        return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                                    }
                                                })

                                                }

                                            </Select>
                                        </div>
                                    </Grid>
                                }
                                <Grid item xs={12} >
                                    <div style={{ padding: 10 }}>
                                        <TextField
                                            id="outlined-input-email"
                                            label="Contact Email"
                                            value={this.state.tempCompany.contactEmail}
                                            onChange={this.companyEmailChanged}
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        />
                                        <p>This must not already exist in ZONELOG</p>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div style={{ margin: "0px 10px 10px 10px" }} >
                                        <FormControlLabel style={{ color: "black" }} control={<Switch checked={this.state.tempCompany.calcMNF} onChange={this.handleMNFChange} />} label="Enable Minimum Night Flow" />
                                    </div>
                                </Grid>
                                {this.state.tempCompany.calcMNF && <Grid item xs={12}>
                                    <div style={{ display: "flex", margin: "0 10px 0 0", padding: 10, width: "100%" } }>
                                        <div style={{width: "50%", marginRight: "5px"} }>
                                            <InputLabel>Night Flow Start Time (Local)</InputLabel>
                                            <DateBox
                                                type={'time'}
                                                defaultValue={moment().set({ hour: 2, minute: 0, second: 0 }).toDate()}
                                                onValueChange={(e): void => this.handleTimeChange(e, 'start')}
                                                name="startTime"
                                             />
                                        </div>
                                        <div style={{ width: "50%", marginLeft: "5px" }}>
                                            <InputLabel>Night Flow End Time (Local)</InputLabel>
                                            <DateBox
                                                type={'time'}
                                                defaultValue={moment().set({ hour: 4, minute: 0, second: 0 }).toDate()}
                                                onValueChange={(e): void => this.handleTimeChange(e, 'end')}
                                                name="endTime"
                                            />
                                        </div>
                                    </div>
                                </Grid>}
                                <Grid item xs={12}>
                                    <div style={{margin: "0px 10px 10px 10px"} } >
                                        <InputLabel>Select Company UTC</InputLabel>
                                        <Select
                                            id="outlined-select"
                                            label="UTC Offset"
                                            value={this.state.tempCompany.utcOffset}
                                            onChange={this.utcOffsetChanged}
                                            variant="outlined"
                                            style={{ width: "100%"}}
                                        >
                                            <MenuItem value="0"><FormattedMessage id="Use UTC" /></MenuItem>
                                            <MenuItem value="1" >+1 {intl.formatMessage({ id: "Hour" })}</MenuItem>
                                            <MenuItem value="2" >+2 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="3" >+3 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="4" >+4 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="5" >+5 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="6" >+6 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="7" >+7 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="8" >+8 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="9" >+9 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="10" >+10 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="11" >+11 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="12" >+12 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="-1" >-1 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="-2" >-2 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="-3" >-3 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="-4" >-4 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="-5" >-5 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="-6" >-6 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="-7" >-7 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="-8" >-8 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="-9" >-9 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="-10" >-10 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                            <MenuItem value="-11" >-11 {intl.formatMessage({ id: "Hours" })}</MenuItem>
                                        </Select>
                                    </div>
                                </Grid>
                                {!this.state.editCompany && this.state.showIPStuff &&
                                    <Grid item xs={12}>
                                        <div style={{ padding: 10 }}>
                                            <TextField
                                            id="outlined-input-listenerip"
                                            label="TCP Listener IP"
                                            value={this.state.tempCompany.listenerIp}
                                            onChange={this.listenerIpChanged}
                                            variant="outlined"
                                            style={{width: "100%"} }
                                            />
                                        </div>
                                    </Grid>
                                }
                                {!this.state.editCompany && this.state.showIPStuff &&
                                    <Grid item xs={12}>
                                        <div style={{ padding: 10 }}>
                                            <TextField
                                                id="outlined-input-listenerport"
                                                label="TCP Listener Port"
                                                value={this.state.tempCompany.listenerPort}
                                                onChange={this.listenerPortChanged}
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                    </Grid>
                                }
                                {!this.state.editCompany && this.state.showIPStuff &&
                                    <Grid item xs={12}>
                                        <div style={{ padding: 10 }}>
                                            <TextField
                                                id="outlined-input-listenercopies"
                                                label="TCP Listener Copies"
                                                value={this.state.tempCompany.listenerCopies}
                                                onChange={this.listenerCopiesChanged}
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                    </Grid>
                                }
                                {!this.state.editCompany &&
                                    <Grid item xs={12}>
                                        <div style={{ padding: 10 }}>
                                        <TextField
                                            id="outlined-input-admin"
                                            label="User Admin Email"
                                            value={this.state.adminEmail}
                                            onChange={this.adminEmailChanged}
                                            variant="outlined"
                                            style={{width: "100%"} }
                                            />
                                            <p>This must not already exist in ZONELOG</p>
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.saveCompany()} disabled={!this.state.allowSave}>Save</Button>
                            <Button color="secondary" variant="contained" onClick={(): void => this.cancel()}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    {this.state.loading &&
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <ClipLoader
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>

                    }
                    {!this.state.loading &&

                        <div>


                            {this.state.tableHidden &&
                                    <ApiFailed />
                            }
                            {!this.state.tableHidden && (window.location.hostname.includes('zonelog.net') || sessionStorage.getItem('userLevel') === 'identityadmin') &&
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                        <h4 className={classes.cardTitleWhite}>Companies</h4>
                                                {this.state.canChange && <div>
                                                    <IconButton aria-label="add" onClick={(): void => this.newCompany()} size="large" style={{ color: "white" }}>
                                                        <AddCircleOutlineIcon />
                                                    </IconButton>
                                                </div>}
                                            </CardHeader>
                                            <CardBody>

                                            <DataGrid

                                                allowColumnResizing={true}
                                                repaintChangesOnly={true}
                                                dataSource={this.state.companies}
                                                ref={this.gridRef} >
                                                <StateStoring enabled={true} type="localStorage" storageKey="UserListGrid" />
                                                <FilterRow visible={true} />

                                                <Column dataField="name" caption="Company" visible={true} dataType="string" />
                                                <Column dataField="parentCompany" caption="Parent" visible={true} dataType="string" />
                                                <Column dataField="contactEmail" visible={true} dataType="string" />
                                                    <Column dataField="companyGuid" visible={false} dataType="string" minWidth={100} />
                                                    <Column dataField="utcOffset" visible={true} dataType="string" />
                                                    <Column dataField="calcMNF" visible={true} dataType="boolean" cellRender={this.renderMNF} />
                                                <Column dataField="listenerName" caption="Listener" visible={this.state.showIPStuff} dataType="string" />
                                                <Column dataField="listenerIp" caption="IP" visible={this.state.showIPStuff} dataType="string" width={100} />
                                                <Column dataField="listenerPort" caption="Start Port" visible={this.state.showIPStuff} dataType="string" width={80} />
                                                <Column dataField="listenerCopies" caption="Listeners" visible={this.state.showIPStuff} dataType="string" width={80} />

                                                {this.state.canChange && <Column fixed={true} fixedPosition='right' type="buttons" cellRender={this.moreRender.bind(this)} width={35} />}


                                                <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                <Paging defaultPageSize={10} />
                                            </DataGrid>
                                            </CardBody>
                                        </Card>
                                    </GridItem>

                                </GridContainer>
                            }


                        </div>
                    }


                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                            disableScrollLock={true}
                    >
                            <MenuItem onClick={this.editCompany.bind(this)}>Edit</MenuItem>
                    </Menu>

                    <NotificationContainer />

                </div>
                )

            :
            (
                <Redirect to={'/Login'} />
            );

    }

}

export default injectIntl(withStyles(style)(Companies));

