/*
 * Used to hold generic Device Config for use by ToolkitTOR component
 */

import { Store } from 'devextreme/data';
import ArrayStore from 'devextreme/data/array_store';
import { schedule } from 'esri/core/scheduling';
import moment from 'moment';
import { number, string } from 'prop-types';
import Primary from '../components/Typography/Primary';
import { decodeFlowMultiplier } from '../Utils/FlowMultiplier';
import { hourlyConnectionsChart } from '../variables/charts';
import { APIGetCurrentConfigurationModel } from './APIGetCurrentConfigurationModel';
import { APIGprsDetail } from './APIGprsDetail';
import { APIPhoneNumber } from './APIPhoneNumber';
import { APISimDetail } from './APISimDetail';

export interface DeviceAlarm {
    Idx: number;
    Message: string;
    Input: string;
    SetOperator: string;
    MaxAlarms: number;
    SetThreshold: number | null;
    ClearThreshold: number | null;
    SetCount: number;
    ClearCount: number;
    EventAlarm: boolean;
    SmsActionSetMessage: boolean;
    SmsActionSetFastline: boolean;
    SmsActionSetSendData: boolean;
    SmsActionSetSendUpdate: boolean;
    SmsActionClrMessage: boolean;
    SmsActionClrFastline: boolean;
    SmsActionClrSendData: boolean;
    SmsActionClrSendUpdate: boolean;
    GprsActionSetConnect: boolean;
    GprsActionClrConnect: boolean;
}

export interface ModbusEntry {
    Idx: number;
    SlaveAddress: number;
    FunctionCode: number;
    DataFormat: number;
    ScalingFactor: string;
    LEF: boolean;
    SRO: boolean;
    StartAddress: number;
    RegisterCount: number;
    PostReadTimeout: number;
}

export interface AnalogueTransfer {
    StartPoint: number;
    MidPoint: number;
    EndPoint: number;
    StartRange: number;
    MidRange: number;
    EndRange: number;
    hasUpdated: boolean;
}

export interface DeviceConfig {
    // Details tab
    Product: string;
    Model: string;
    SWversion: string;
    Battery: number | null;
    ServerTime: Date | null;
    DeviceTime: Date | null;
    CurrentState: string;
    Guid: string;
    HwWakeup: boolean;
    Sitename: string;
    Latitude: number | null;
    Longitude: number | null;
    ModemIMEI: string;
    ModemType: string;
    ChannelsAvail: number;      // To store in template

    // Schedules tab
    PrimarySchedule: boolean;
    PrimarySampleValue: number;
    PrimarySampleUnit: string;
    PrimaryWindow: boolean;
    PrimaryWindowStart: string;
    PrimaryWindowEnd: string;
    PrimaryAlarm: boolean;
    PrimaryAlarmValue: number | null;
    PrimaryAlarmUnit: string;

    SecondarySchedule: boolean;
    SecondarySampleValue: number;
    SecondarySampleUnit: string;
    SecondaryWindow: boolean;
    SecondaryWindowStart: string;
    SecondaryWindowEnd: string;
    SecondaryAlarm: boolean;
    SecondaryAlarmValue: number | null;
    SecondaryAlarmUnit: string;

    AnalogueSchedule: boolean;
    AnalogueSampleValue: number;
    AnalogueSampleUnit: string;
    AnalogueAlarm: boolean;
    AnalogueAlarmValue: number | null;
    AnalogueAlarmUnit: string;

    // Communication tab
    PreferredRAT: string;
    ExtAntennaAvail: boolean;
    Antenna: string;
    Preferred4G: string;
    SimSetting: string;
    SignalCheckPhone: string;
    SignalCheckDelay: string;
    SignalCheckDuration: string;
    SignalCheckRequest: boolean;
    DataCheckServer: string;
    DataCheckRequest: boolean;
    DataTxSchedule: boolean;
    DataTxTime: Date | null;
    DataTxInterval: string;
    SmsTxSchedule: boolean;
    SmsTxTime: Date | null;
    SmsTxInterval: string;
    SmsTxServer1: string;
    SmsTxServer2: string;
    SMSAlarm1: string;
    SMSAlarm2: string;
    PhoneNumbers: Array<APIPhoneNumber | null>;       // Up to 8 entries from PhoneDetails DB
    ListenSchedule: boolean;
    ListenTime: Date | null;
    ListenInterval: string;

    //Channels tab
    MeterConfig: number;    //0011 - Identifies which channels available
    FlowA: boolean;
    FlowAType: string;
    FlowARef: string;
    FlowATotal: number | null;
    FlowAMultiplier: string;
    FlowAUnits: string;
    FlowASetOnSend: boolean;
    FlowAPrimaryReadings: boolean;
    FlowAPrimaryEvents: boolean;
    FlowAPrimaryMinLog: boolean;
    FlowAPrimaryDeDup: boolean;
    FlowASecondaryReadings: boolean;
    FlowASecondaryEvents: boolean;
    FlowASecondaryMinLog: boolean;
    FlowASecondaryDeDup: boolean;

    FlowB: boolean;
    FlowBType: string;
    FlowBRef: string;
    FlowBTotal: number | null;
    FlowBMultiplier: string;
    FlowBUnits: string;
    FlowBSetOnSend: boolean;
    FlowBPrimaryReadings: boolean;
    FlowBPrimaryEvents: boolean;
    FlowBPrimaryMinLog: boolean;
    FlowBPrimaryDeDup: boolean;
    FlowBSecondaryReadings: boolean;
    FlowBSecondaryEvents: boolean;
    FlowBSecondaryMinLog: boolean;
    FlowBSecondaryDeDup: boolean;

    AnalogueC: boolean;
    AnalogueCType: string;
    AnalogueCRef: string;
    AnalogueCExtPower: number | null;
    AnalogueCSensor: string;
    AnalogueCOffset: number | null;
    AnalogueCUnits: string;
    AnalogueCDp: number | null;
    AnalogueCPrimaryReadings: boolean;
    AnalogueCPrimaryEvents: boolean;
    AnalogueCPrimaryMinLog: boolean;
    AnalogueCSecondaryReadings: boolean;
    AnalogueCSecondaryEvents: boolean;
    AnalogueCSecondaryMinLog: boolean;
    AnalogueCTransducerRange: number;
    AnalogueCTransfer: AnalogueTransfer;
    AnalogueCCurrent: boolean;
    AnalogueCExtTransducerPower: boolean;
    AnalogueCIntTransducerPower: boolean;




    AnalogueD: boolean;
    AnalogueDType: string;
    AnalogueDRef: string;
    AnalogueDExtPower: number | null;
    AnalogueDSensor: string;
    AnalogueDOffset: number | null;
    AnalogueDUnits: string;
    AnalogueDDp: number | null;
    AnalogueDPrimaryReadings: boolean;
    AnalogueDPrimaryEvents: boolean;
    AnalogueDPrimaryMinLog: boolean;
    AnalogueDSecondaryReadings: boolean;
    AnalogueDSecondaryEvents: boolean;
    AnalogueDSecondaryMinLog: boolean;
    AnalogueDTransducerRange: number;
    AnalogueDTransfer: AnalogueTransfer;
    AnalogueDCurrent: boolean;
    AnalogueDExtTransducerPower: boolean;
    AnalogueDIntTransducerPower: boolean;


    AnalogueE: boolean;
    AnalogueEType: string;
    AnalogueERef: string;
    AnalogueEExtPower: number | null;
    AnalogueESensor: string;
    AnalogueEOffset: number | null;
    AnalogueEUnits: string;
    AnalogueEDp: number | null;
    AnalogueEPrimaryReadings: boolean;
    AnalogueEPrimaryEvents: boolean;
    AnalogueEPrimaryMinLog: boolean;
    AnalogueESecondaryReadings: boolean;
    AnalogueESecondaryEvents: boolean;
    AnalogueESecondaryMinLog: boolean;
    AnalogueETransducerRange: number;
    AnalogueEExtTransducerPower: boolean;
    AnalogueEIntTransducerPower: boolean;


    // Alarms tab
    Alarms: Array<DeviceAlarm>;

    // Custom tab
    Custom: string;

    // Existing Update Json
    UpdateJSON: string;

    // MODBUS
    ModbusOnly: boolean;
    ModbusBitrate: string;
    ModbusDatabits: number;
    ModbusParity: string;
    ModbusVoltage: string;
    ModbusSettle: number;
    ModbusPower1: boolean;
    ModbusPower2: boolean;
    ModbusPower3: boolean;
    ModbusConfig: Array<ModbusEntry>;
    ModbusUpdated: Array<boolean>;
    Modbus1Offset: number;
    Modbus2Offset: number;
    Modbus3Offset: number;
}

function CreateDeviceAlarm(idx: number): DeviceAlarm {
    return {
        Idx: idx,
        Message: "",
        Input: "Off",
        SetOperator: "Off",
        MaxAlarms: 0,
        SetThreshold: null,
        ClearThreshold: null,
        SetCount: 0,
        ClearCount: 0,
        EventAlarm: false,
        SmsActionSetMessage: false,
        SmsActionSetFastline: false,
        SmsActionSetSendData: false,
        SmsActionSetSendUpdate: false,
        SmsActionClrMessage: false,
        SmsActionClrFastline: false,
        SmsActionClrSendData: false,
        SmsActionClrSendUpdate: false,
        GprsActionSetConnect: false,
        GprsActionClrConnect: false,
    }
}

function CreateModbusEntry(idx: number): ModbusEntry {
    return {
        Idx: idx,
        SlaveAddress: 0,
        FunctionCode: 0,
        DataFormat: 0,
        ScalingFactor: "",
        LEF: false,
        SRO: false,
        StartAddress: 0,
        RegisterCount: 0,
        PostReadTimeout: 0,
    }
}

function DecodeModbusConfig(idx: number, configData: Array<number>, scalingFactorSource: Array<number>): ModbusEntry {

    const tempEntry = CreateModbusEntry(idx);

    // Slave address
    tempEntry.SlaveAddress = configData[0];

    // function code
    tempEntry.FunctionCode = configData[1];

    // data format
    // replace bit in byte
    tempEntry.DataFormat = configData[6] & 0x3F;

    // LEF
    tempEntry.LEF = (configData[6] & 0x40) == 0x40;

    // SRO
    tempEntry.SRO = (configData[6] & 0x80) == 0x80;

    // Start Address
    tempEntry.StartAddress = configData[2] * 256 + configData[3];

    // register count
    tempEntry.RegisterCount = configData[4] * 256 + configData[5];

    // timeout
    tempEntry.PostReadTimeout = configData[7] / 10;
    
    let scaling = ""
    if ((hexArrayToString(scalingFactorSource.slice(1)).includes("50")) || (hexArrayToString(scalingFactorSource.slice(1)).includes("51")) || (hexArrayToString(scalingFactorSource.slice(1)).includes("52"))) {
        if (hexArrayToString(scalingFactorSource.slice(1)).includes("50")) {
            const splittingIndex = hexArrayToString(scalingFactorSource.slice(1)).indexOf("50")
            scaling = (hexArrayToString(scalingFactorSource.slice(1)).slice(0, splittingIndex))
            
        }
        if (hexArrayToString(scalingFactorSource.slice(1)).includes("51")) {
            const splittingIndex = hexArrayToString(scalingFactorSource.slice(1)).indexOf("51")
            scaling = (hexArrayToString(scalingFactorSource.slice(1)).slice(0, splittingIndex))
            
        }
        if (hexArrayToString(scalingFactorSource.slice(1)).includes("52")) {
            const splittingIndex = hexArrayToString(scalingFactorSource.slice(1)).indexOf("52")
            scaling = (hexArrayToString(scalingFactorSource.slice(1)).slice(0, splittingIndex))
           
        }
        switch (scaling) {

            case "020404":
                tempEntry.ScalingFactor = "\u002F100000";
                break;
            case "0404":
                tempEntry.ScalingFactor = "\u002F10000";
                break;
            case "0204":
                tempEntry.ScalingFactor = "\u002F1000";
                break;
            case "04":
                tempEntry.ScalingFactor = "\u002F100";
                break;
            case "02":
                tempEntry.ScalingFactor = "\u002F10";
                break;
            case "01":
                tempEntry.ScalingFactor = "x10";
                break;
            case "03":
                tempEntry.ScalingFactor = "x100";
                break;
            case "0103":
                tempEntry.ScalingFactor = "x1000";
                break;
            case "0303":
                tempEntry.ScalingFactor = "x10000";
                break;
            case "010303":
                tempEntry.ScalingFactor = "x100000";
                break;
            default:
                tempEntry.ScalingFactor = "x1";
                break;
        }

    } else {
        switch (hexArrayToString(scalingFactorSource.slice(1))) {

            case "02040400000000":
                tempEntry.ScalingFactor = "\u002F100000";
                break;
            case "04040000000000":
                tempEntry.ScalingFactor = "\u002F10000";
                break;
            case "02040000000000":
                tempEntry.ScalingFactor = "\u002F1000";
                break;
            case "04000000000000":
                tempEntry.ScalingFactor = "\u002F100";
                break;
            case "02000000000000":
                tempEntry.ScalingFactor = "\u002F10";
                break;
            case "00000000000000":
                tempEntry.ScalingFactor = "x1";
                break;
            case "01000000000000":
                tempEntry.ScalingFactor = "x10";
                break;
            case "03000000000000":
                tempEntry.ScalingFactor = "x100";
                break;
            case "01030000000000":
                tempEntry.ScalingFactor = "x1000";
                break;
            case "03030000000000":
                tempEntry.ScalingFactor = "x10000";
                break;
            case "01030300000000":
                tempEntry.ScalingFactor = "x100000";
                break;
            default:
                tempEntry.ScalingFactor = "-";
                break;
        }
    }
    console.log(tempEntry)

    return tempEntry;

}

// Returns value for updateJson commands
function EncodeModbusConfig(config: ModbusEntry): string {
    const configData = [0, 0, 0, 0, 0, 0, 0, 0];        // Stored as 1404, 1405 or 1406
    console.log(config)
    // Slave address
    configData[0] = config.SlaveAddress;

    // function code
    configData[1] = config.FunctionCode;

    // Slave address
    configData[2] = Math.floor(config.StartAddress / 256);
    configData[3] = config.StartAddress % 256;

    // register count
    configData[4] = Math.floor(config.RegisterCount / 256);
    configData[5] = config.RegisterCount % 256;

    // Data format
    let df = config.DataFormat;

    // LEF
    if (config.LEF)
        df = df | 0x40;

    // SRO
    if (config.SRO)
        df = df | 0x80;

    configData[6] = df;

    // timeout
    configData[7] = config.PostReadTimeout * 10;

    return hexArrayToString(configData);

}

// Returns encoded value for Path (0231, 0241 or 0251) 
function EncodeModbusPath(config: ModbusEntry): string {
    let modbusPathIndex = "";
    let offsetSource = "00";
    let modbusOffsetSource = "";

    switch (config.Idx) {
        case 1:
            modbusPathIndex = "F0";
            modbusOffsetSource = "50";
            break;
        case 2:
            modbusPathIndex = "F1";
            modbusOffsetSource = "51";
            break;
        case 3:
            modbusPathIndex = "F2";
            modbusOffsetSource = "52";
            break;
    }
    
    //if (offset != oldOffset) {
        offsetSource = modbusOffsetSource;
    //}
    let modbusPath = "";
    // scaling factor
    console.log(config.ScalingFactor)
    switch (config.ScalingFactor) {
        case "\u002F100000":
            modbusPath = modbusPathIndex + "020404" + offsetSource + "000000";
            break;
        case "\u002F10000":
            modbusPath = modbusPathIndex + "0404" + offsetSource + "00000000";
            break;
        case "\u002F1000":
            modbusPath = modbusPathIndex + "0204" + offsetSource + "00000000";
            break;
        case "\u002F100":
            modbusPath = modbusPathIndex + "04" + offsetSource + "0000000000";
            break;
        case "\u002F10":
            modbusPath = modbusPathIndex + "02" + offsetSource + "0000000000";
            break;
        case "x1":
            modbusPath = modbusPathIndex + offsetSource + "000000000000";
            break;
        case "x10":
            modbusPath = modbusPathIndex + "01" + offsetSource + "0000000000";
            break;
        case "x100":
            modbusPath = modbusPathIndex + "03" + offsetSource + "0000000000";
            break;
        case "x1000":
            modbusPath = modbusPathIndex + "0103" + offsetSource + "00000000";
            break;
        case "x10000":
            modbusPath = modbusPathIndex + "0303" + offsetSource + "00000000";
            break;
        case "x100000":
            modbusPath = modbusPathIndex + "010303" + offsetSource + "000000";
            break;
        default:
            modbusPath = modbusPathIndex + offsetSource + "000000000000";
            break;
    }
    console.log(modbusPath)
    return modbusPath;

}


function EncodeModbusOffsetPath(pathIndex:number): string {
    let offsetSource = "00";
    let modbusPathIndex = "";
    let modbusPath = "";

    // scaling factor
    switch (pathIndex) {
        case 1:
            modbusPathIndex = "F0";
            offsetSource = "50";
            break;
        case 2:
            modbusPathIndex = "F1";
            offsetSource = "51";
            break;
        case 3:
            modbusPathIndex = "F2";
            offsetSource = "52";
            break;
    }
    modbusPath = modbusPathIndex + offsetSource + "000000000000";
    return modbusPath;

}
// encode/Decode AnalogueTransfer
function encodeAnalogueTransfer(af: AnalogueTransfer, isCurrent: boolean): string {
    if (isCurrent) {
        af.StartRange = 4000;
        af.MidRange = 12000;
        af.EndRange = 20000;
    }
    else {
        af.StartRange = 500;
        af.MidRange = 2500;
        af.EndRange = 4500;
    }

    // 0360 is 29 byte value
    const transfer: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    transfer[0] = 3; // 3 point linear
    transfer[2] = af.StartRange % 256;
    transfer[1] = Math.trunc(af.StartRange / 256);
    transfer[4] = af.MidRange % 256;
    transfer[3] = Math.trunc(af.MidRange / 256);
    transfer[6] = af.EndRange % 256;
    transfer[5] = Math.trunc(af.EndRange / 256);

    transfer[8] = af.StartPoint % 256;
    transfer[7] = Math.trunc(af.StartPoint / 256);
    transfer[10] = af.MidPoint % 256;
    transfer[9] = Math.trunc(af.MidPoint / 256);
    transfer[12] = af.EndPoint % 256;
    transfer[11] = Math.trunc(af.EndPoint / 256);

    return hexArrayToString(transfer);
}

function decodeAnalogueTransfer(val: string): AnalogueTransfer {
    const af: AnalogueTransfer = { StartPoint: 0, MidPoint: 0, EndPoint: 0, StartRange: 0, MidRange: 0, EndRange: 0, hasUpdated: false };
    const transfer = stringToHexArray(val);

    if (transfer[0] == 3) {
        af.StartRange = transfer[2] + transfer[1] * 256;
        af.MidRange = transfer[4] + transfer[3] * 256;
        af.EndRange = transfer[6] + transfer[5] * 256;
        af.StartPoint = transfer[8] + transfer[7] * 256;
        af.MidPoint = transfer[10] + transfer[9] * 256;
        af.EndPoint = transfer[12] + transfer[11] * 256;
    }

    return af;
}

//function decodeModbusOffset(val: string, dp: number): number {
//    const offsetHex = "0x" + val.substr(10, 4);
//    let offset = parseInt(offsetHex)
//    offset = offset / Math.pow(10, dp);
//    return offset;

//}

function decodeModbusOffset(val: string, dp: number): number {
    const offsetHex = val.substr(10, 4);
    let offset = parseInt(offsetHex, 16);

    // Check if the number is negative in two's complement form
    if (offset & 0x8000) { // if the sign bit is set
        offset = offset - 0x10000; // convert from two's complement
    }

    offset = offset / Math.pow(10, dp);
    return offset;
}

//function encodeModbusOffset(val: number, dp: number): string {
//    const offset = val * Math.pow(10, dp);
//    let hexString = offset.toString(16);
//    hexString = zeroPad(hexString, 4);
//    return "0100010001" + hexString;

//}

function encodeModbusOffset(val: number, dp: number): string {
    const offset = Math.round(val * Math.pow(10, dp));

    // Calculate two's complement for negative numbers
    let hexString;
    if (offset < 0) {
        const maxUint16 = 0xFFFF;
        hexString = (maxUint16 + offset + 1).toString(16);
    } else {
        hexString = offset.toString(16);
    }

    // Ensure the hex string is exactly 4 characters long, pad with zeros if necessary
    hexString = hexString.padStart(4, '0');

    return "0100010001" + hexString;
}

function CreateDeviceConfig(): DeviceConfig {
    return {
        // Details tab
        Product: "",
        Model: "",
        SWversion: "",
        Battery: null,
        ServerTime: null,
        DeviceTime: null,
        CurrentState: "Decommissioned",
        Guid: "00000000-0000-0000-0000-000000000000",
        HwWakeup: false,
        Sitename: "",
        Latitude: null,
        Longitude: null,
        ModemIMEI: "",
        ModemType: "Unknown",
        ChannelsAvail: 0,

        // Schedules tab
        PrimarySchedule: false,
        PrimarySampleValue: 0,
        PrimarySampleUnit: "",
        PrimaryWindow: false,
        PrimaryWindowStart: "",
        PrimaryWindowEnd: "",
        PrimaryAlarm: false,
        PrimaryAlarmValue: null,
        PrimaryAlarmUnit: "",

        SecondarySchedule: false,
        SecondarySampleValue: 0,
        SecondarySampleUnit: "",
        SecondaryWindow: false,
        SecondaryWindowStart: "",
        SecondaryWindowEnd: "",
        SecondaryAlarm: false,
        SecondaryAlarmValue: null,
        SecondaryAlarmUnit: "",

        AnalogueSchedule: false,
        AnalogueSampleValue: 0,
        AnalogueSampleUnit: "",
        AnalogueAlarm: false,
        AnalogueAlarmValue: null,
        AnalogueAlarmUnit: "",

        // Communication tab
        PreferredRAT: "00",
        ExtAntennaAvail: false,
        Antenna: "",
        Preferred4G: "00",
        SimSetting: "0",
        SignalCheckPhone: "0",
        SignalCheckDelay: "",
        SignalCheckDuration: "",
        SignalCheckRequest: false,
        DataCheckServer: "0",
        DataCheckRequest: false,
        DataTxSchedule: false,
        DataTxTime: null,
        DataTxInterval: "0",
        SmsTxSchedule: false,
        SmsTxTime: null,
        SmsTxInterval: "0",
        SmsTxServer1: "0",
        SmsTxServer2: "0",
        SMSAlarm1: "0",
        SMSAlarm2: "0",
        PhoneNumbers: [],
        ListenSchedule: false,
        ListenTime: null,
        ListenInterval: "0",

        //Channels tab
        MeterConfig: 0,    //0011 - Identifies which channels available
        FlowA: false,
        FlowAType: "",
        FlowARef: "",
        FlowATotal: 0,
        FlowAMultiplier: "0x10",
        FlowAUnits: "",
        FlowASetOnSend: false,
        FlowAPrimaryReadings: false,
        FlowAPrimaryEvents: false,
        FlowAPrimaryMinLog: false,
        FlowAPrimaryDeDup: false,
        FlowASecondaryReadings: false,
        FlowASecondaryEvents: false,
        FlowASecondaryMinLog: false,
        FlowASecondaryDeDup: false,

        FlowB: false,
        FlowBType: "",
        FlowBRef: "",
        FlowBTotal: 0,
        FlowBMultiplier: "0x10",
        FlowBUnits: "",
        FlowBSetOnSend: false,
        FlowBPrimaryReadings: false,
        FlowBPrimaryEvents: false,
        FlowBPrimaryMinLog: false,
        FlowBPrimaryDeDup: false,
        FlowBSecondaryReadings: false,
        FlowBSecondaryEvents: false,
        FlowBSecondaryMinLog: false,
        FlowBSecondaryDeDup: false,

        AnalogueC: false,
        AnalogueCType: "",
        AnalogueCRef: "",
        AnalogueCExtPower: null,
        AnalogueCSensor: "",
        AnalogueCOffset: null,
        AnalogueCUnits: "",
        AnalogueCDp: 0,
        AnalogueCPrimaryReadings: false,
        AnalogueCPrimaryEvents: false,
        AnalogueCPrimaryMinLog: false,
        AnalogueCSecondaryReadings: false,
        AnalogueCSecondaryEvents: false,
        AnalogueCSecondaryMinLog: false,
        AnalogueCTransducerRange: 0,
        AnalogueCTransfer: { StartPoint: 0, MidPoint: 0, EndPoint: 0, StartRange: 0, MidRange: 0, EndRange: 0, hasUpdated: false },
        AnalogueCCurrent: false,
        AnalogueCExtTransducerPower: false,
        AnalogueCIntTransducerPower: false,


        AnalogueD: false,
        AnalogueDType: "",
        AnalogueDRef: "",
        AnalogueDExtPower: null,
        AnalogueDSensor: "",
        AnalogueDOffset: null,
        AnalogueDUnits: "",
        AnalogueDDp: 0,
        AnalogueDPrimaryReadings: false,
        AnalogueDPrimaryEvents: false,
        AnalogueDPrimaryMinLog: false,
        AnalogueDSecondaryReadings: false,
        AnalogueDSecondaryEvents: false,
        AnalogueDSecondaryMinLog: false,
        AnalogueDTransducerRange: 0,
        AnalogueDTransfer: { StartPoint: 0, MidPoint: 0, EndPoint: 0, StartRange: 0, MidRange: 0, EndRange: 0, hasUpdated: false },
        AnalogueDCurrent: false,
        AnalogueDExtTransducerPower: false,
        AnalogueDIntTransducerPower: false,


        AnalogueE: false,
        AnalogueEType: "",
        AnalogueERef: "",
        AnalogueEExtPower: null,
        AnalogueESensor: "",
        AnalogueEOffset: null,
        AnalogueEUnits: "",
        AnalogueEDp: 0,
        AnalogueEPrimaryReadings: false,
        AnalogueEPrimaryEvents: false,
        AnalogueEPrimaryMinLog: false,
        AnalogueESecondaryReadings: false,
        AnalogueESecondaryEvents: false,
        AnalogueESecondaryMinLog: false,
        AnalogueETransducerRange: 0,
        AnalogueEExtTransducerPower: false,
        AnalogueEIntTransducerPower: false,


        // Alarms tab
        Alarms: [
            CreateDeviceAlarm(1),
            CreateDeviceAlarm(2),
            CreateDeviceAlarm(3),
            CreateDeviceAlarm(4),
            CreateDeviceAlarm(5),
            CreateDeviceAlarm(6),
            CreateDeviceAlarm(7),
            CreateDeviceAlarm(8),
            CreateDeviceAlarm(9),
            CreateDeviceAlarm(10),
            CreateDeviceAlarm(11),
            CreateDeviceAlarm(12),
            CreateDeviceAlarm(13),
            CreateDeviceAlarm(14),
            CreateDeviceAlarm(15),
            CreateDeviceAlarm(16),
        ],

        // Custom tab
        Custom: "",

        // Existing update
        UpdateJSON: "",

        // MODBUS
        ModbusOnly: false,
        ModbusBitrate: "09",
        ModbusDatabits: 0,
        ModbusParity: "None",
        ModbusVoltage: "00",
        ModbusSettle: 0,
        ModbusPower1: false,
        ModbusPower2: false,
        ModbusPower3: false,
        ModbusConfig: [
            CreateModbusEntry(1),
            CreateModbusEntry(2),
            CreateModbusEntry(3),
        ],
        ModbusUpdated: [false, false, false],
        Modbus1Offset: 0,
        Modbus2Offset: 0,
        Modbus3Offset: 0
    }

}

// Utility functions to convert config entries
function zeroPad(str: string, len: number): string {
    let result = str;
    while (result.length < len) {
        result = "0" + result;
    }
    return result;
}

function HexToSignedInt(num: string, numSize: number): number {
    const val = {
        mask: 0x8 * Math.pow(16, numSize - 1), //  0x8000 if numSize = 4
        sub: -0x1 * Math.pow(16, numSize)    //-0x10000 if numSize = 4
    };
    if ((parseInt(num, 16) & val.mask) == 0) { //negative
        return (parseInt(num, 16));
    } else {                                 //positive
        return (val.sub + parseInt(num, 16));
    }
}
function SignedIntToHex(num: number, numSize: number): string {
    const val = {
        mask: 0x8 * Math.pow(16, numSize - 1), //  0x8000 if numSize = 4
        sub: -0x1 * Math.pow(16, numSize)    //-0x10000 if numSize = 4
    };
    if (num >= 0) {
        return zeroPad(num.toString(16), numSize);
    }
    else {
        const neg = num - val.sub;
        return zeroPad(neg.toString(16), numSize);
    }
}

// convert string (of hex values from SMS) into array of numbers
function stringToHexArray(str: string): Array<number> {
    const a: Array<number> = [];
    for (let i = 0; i < str.length; i += 2) {
        a.push(parseInt("0x" + str.substr(i, 2), 16));
    }
    return a;
}
//convert array of numbers to string (of hex values for SMS)
function hexArrayToString(arr: Array<number>): string {
    let str = "";
    for (let i = 0; i < arr.length; i++) {
        const val = Math.trunc(arr[i] % 256).toString(16)
        str = str + zeroPad(val, 2);
    }
    return str;
}
//convert string to string of hex (ASCII) values, pad to length with /0
function encodeString(input: string, length: number): string {
    const a: Array<number> = [];
    let i = 0;
    while (i < input.length && i < length) {
        a.push(input.charCodeAt(i));
        i++;
    }
    while (i < length) {
        a.push(0);
        i++;
    }
    return hexArrayToString(a);
}
//convert string of hex (ASCII) values to string
function decodeString(input: string): string {
    const a = stringToHexArray(input);
    let str = "";
    for (let i = 0; i < a.length; i++) {
        if (a[i] != 0) {
            str = str + String.fromCharCode(a[i]);
        }
    }
    return str;
}

// Version is 2 byte value as major-minor version
function getSwVersion(value: number): string {
    const majorStr = zeroPad(Math.trunc(value / 256).toString(), 2);
    const minorStr = zeroPad((value % 256).toString(), 2);

    return majorStr + "-" + minorStr;
}

/*
 *  Coordinates are stored as double values of Latitude (-90 to 90) & Longitude (-180 to 180)
 *  In parameter 0078 these are encoded as 4 byte hex values of each coordinate * 10,000,000 (7 decimal places)
 *  The byte values are converted to a string with LSB first
 *
 */
function getCooord(lat: number, lng: number): string {
    const latStr = SignedIntToHex(Math.trunc(lat * 10000000), 8).substr(0, 8)
    const longStr = SignedIntToHex(Math.trunc(lng * 10000000), 8).substr(0, 8)
    // reverse byte order
    return latStr.substr(6, 2) + latStr.substr(4, 2) + latStr.substr(2, 2) + latStr.substr(0, 2) + longStr.substr(6, 2) + longStr.substr(4, 2) + longStr.substr(2, 2) + longStr.substr(0, 2) + "0000";
}
function reverseCoordLat(coordStr: string): number {
    coordStr = coordStr.slice(0, -4);
    const latHexReversed = coordStr.slice(0, 8); // First 8 characters: "001784D7"
    const latHex =
        latHexReversed.slice(0, 2) + // First byte
        latHexReversed.slice(2, 4) + // Second byte
        latHexReversed.slice(4, 6) + // Second last byte
        latHexReversed.slice(6, 8); // Last byte
    const latInt = ReverseSignedIntToHex(latHex, 8);
    const lat = latInt / 10000000;
    return lat;
}

function reverseCoordLong(coordStr: string): number {
    coordStr = coordStr.slice(0, -4);
    const lngHexReversed = coordStr.slice(8, 16); // Next 8 characters: "80C3C901"
    const lngHex =
        lngHexReversed.slice(0, 2) +
        lngHexReversed.slice(2, 4) +
        lngHexReversed.slice(4, 6) +
        lngHexReversed.slice(6, 8);
    const lngInt = ReverseSignedIntToHex(lngHex, 8);
    const lng = lngInt / 10000000;
    return lng;
}
function ReverseSignedIntToHex(num: string, numSize: number): number {
    const val = {
        mask: 0x8 * Math.pow(16, numSize - 1), // 0x80000000 if numSize = 8
        sub: -0x1 * Math.pow(16, numSize)     // -0x100000000 if numSize = 8
    };

    const intValue = parseInt(num, 16); // Convert the hex string to an integer

    // Check if the value is negative based on the most significant bit
    if ((intValue & val.mask) === 0) {
        // Positive number
        return intValue;
    } else {
        // Negative number
        return val.sub + intValue;
    }
}

function getLatitude(coord: string, awaitingUpdate: boolean): number {
    let arr: any;
    if (awaitingUpdate) {
        //show values that have been requested - before logger config received
        const coords = reverseCoordLat(coord);
        return coords;
    } else {
        arr = stringToHexArray(coord);
        const latStr = zeroPad(arr[3].toString(16), 2) + zeroPad(arr[2].toString(16), 2) + zeroPad(arr[1].toString(16), 2) + zeroPad(arr[0].toString(16), 2);
        let lat = HexToSignedInt("0x" + latStr, 8);
        if (lat > 90 * 10000000 || lat < -90 * 10000000) {
            lat = 0;
        }
        return lat / 10000000;
    }

}
function getLongitude(coord: string, awaitingUpdate: boolean): number {
    let arr: any;
    if (awaitingUpdate) {
        //show values that have been requested - before logger config received
        const coords = reverseCoordLong(coord);
        return coords;
    } else {
        arr = stringToHexArray(coord);
        const lngStr = zeroPad(arr[7].toString(16), 2) + zeroPad(arr[6].toString(16), 2) + zeroPad(arr[5].toString(16), 2) + zeroPad(arr[4].toString(16), 2);
        let lng = HexToSignedInt("0x" + lngStr, 8);
        if (lng > 180 * 10000000 || lng < -180 * 10000000) {
            lng = 0;
        }
        return lng / 10000000;
    }
}

/*
 * GUID: convert between byte array & string
 * first four bytes should be reversed, as well as two next and two after them
 * 
 */
function guidFromArray(binary: Array<number>): string {
    // reverse first four bytes, and join with following two reversed, joined with following two reversed, joined with rest of the bytes
    const x: Array<number> = binary.slice(0, 4).reverse().concat(binary.slice(4, 6).reverse()).concat(binary.slice(6, 8).reverse()).concat(binary.slice(8));

    const guid = x.map(function (item) {
        // return hex value with "0" padding
        return ('00' + item.toString(16).toUpperCase()).substr(-2, 2);
    });

    return guid[0] + guid[1] + guid[2] + guid[3] + "-" + guid[4] + guid[5] + "-" + guid[6] + guid[7] + "-" + guid[8] + guid[9] + "-" + guid.slice(10).join("");
}
function guidToArray(guid: string): Array<number> {
    let x: Array<number> = [];

    if (guid.length >= 36) {
        // guid is format 4BA84883-4955-AE41-8C1E-79C4F008AECE
        //                0123456789012345678901234
        x.push(parseInt(guid.substr(6, 2), 16));
        x.push(parseInt(guid.substr(4, 2), 16));
        x.push(parseInt(guid.substr(2, 2), 16));
        x.push(parseInt(guid.substr(0, 2), 16));
        x.push(parseInt(guid.substr(11, 2), 16));
        x.push(parseInt(guid.substr(9, 2), 16));
        x.push(parseInt(guid.substr(16, 2), 16));
        x.push(parseInt(guid.substr(14, 2), 16));
        x.push(parseInt(guid.substr(21, 2), 16));
        x.push(parseInt(guid.substr(19, 2), 16));
        x.push(parseInt(guid.substr(24, 2), 16));
        x.push(parseInt(guid.substr(26, 2), 16));
        x.push(parseInt(guid.substr(28, 2), 16));
        x.push(parseInt(guid.substr(30, 2), 16));
        x.push(parseInt(guid.substr(32, 2), 16));
        x.push(parseInt(guid.substr(34, 2), 16));
    }
    else {
        x = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }

    return x;
}

// Phone numbers
//The number is stored with 16 nibbles swapped as this is how they are used in the SMS messages. Unused nibbles must be set to F.
function decodePhone(phoneParam: string): string {
    let num = "";
    const decode = stringToHexArray(phoneParam);
    if (decode[0] == 0xFF) {
        return "";
    }
    for (let i = 0; i < decode.length; i++) {
        // Low nibble first
        let digit = decode[i] & 0x0f;
        if (digit >= 0 && digit <= 9) {
            num = num + digit.toString();
        }
        // high nibble
        digit = (decode[i] & 0xf0) >> 4;
        if (digit >= 0 && digit <= 9) {
            num = num + digit.toString();
        }
    }

    return num;
}
function encodePhone(num: string): string {
    const param: Array<number> = [];
    for (let i = 0; i < 8; i++) {
        let val = 0;
        if (i * 2 < num.length) {
            // Low nibble first
            const digit = num.substr(i * 2, 1);
            val = parseInt(digit);
        }
        else {
            val = 0x0f;
        }
        if ((i * 2) + 1 < num.length) {
            // high nibble
            const digit = num.substr((i * 2) + 1, 1);
            val += parseInt(digit) * 256;
        }
        else {
            val += 0xf0;
        }
        param.push(val);
    }

    return hexArrayToString(param);

}

// Schedule - see schedule parameters.doc
// Encoded as 45 char hex string
// Hour encoded in chars 16-21 and Minute in chars 22-37 
// Interval gives 1,2,4,8,24 times per day (i.e. which hour bits to set working from time entered)
function setSchedule(start: Date, interval: string): string {
    const hour = moment(start).hour();
    let minute = moment(start).minute();

    console.log("IN: hour:" + hour + " min:" + minute + " int: " + interval);
    let hourStr = "000000";
    let minuteStr = "";

    
        switch (interval) {
            case "24":
                switch (hour % 12) {
                    case 0:
                        hourStr = "001";
                        break;
                    case 1:
                        hourStr = "002";
                        break;
                    case 2:
                        hourStr = "004";
                        break;
                    case 3:
                        hourStr = "008";
                        break;
                    case 4:
                        hourStr = "010";
                        break;
                    case 5:
                        hourStr = "020";
                        break;
                    case 6:
                        hourStr = "040";
                        break;
                    case 7:
                        hourStr = "080";
                        break;
                    case 8:
                        hourStr = "100";
                        break;
                    case 9:
                        hourStr = "200";
                        break;
                    case 10:
                        hourStr = "400";
                        break;
                    case 11:
                        hourStr = "800";
                        break;
                }
                if (hour > 12) {
                    hourStr = hourStr + "000";
                }
                else {
                    hourStr = "000" + hourStr;
                }
                break;
            case "12":
                switch (hour % 12) {
                    case 0:
                        hourStr = "001001";
                        break;
                    case 1:
                        hourStr = "002002";
                        break
                    case 2:
                        hourStr = "004004";
                        break
                    case 3:
                        hourStr = "008008";
                        break
                    case 4:
                        hourStr = "010010";
                        break
                    case 5:
                        hourStr = "020020";
                        break
                    case 6:
                        hourStr = "040040";
                        break
                    case 7:
                        hourStr = "080080";
                        break
                    case 8:
                        hourStr = "100100";
                        break
                    case 9:
                        hourStr = "200200";
                        break
                    case 10:
                        hourStr = "400400";
                        break
                    case 11:
                        hourStr = "800800";
                        break
                }
                break;
            case "6":
                switch (hour % 6) {
                    case 0:
                        hourStr = "041041";
                        break;
                    case 1:
                        hourStr = "082082";
                        break;
                    case 2:
                        hourStr = "104104";
                        break;
                    case 3:
                        hourStr = "208208";
                        break;
                    case 4:
                        hourStr = "410410";
                        break;
                    case 5:
                        hourStr = "820820";
                        break;

                }
                break;
            case "3":
                switch (hour % 3) {
                    case 0:
                        hourStr = "249249";
                        break;
                    case 1:
                        hourStr = "492492";
                        break;
                    case 2:
                        hourStr = "924924";
                        break;
                }
                break;
            case "1":
                hourStr = "FFFFFF";
                break;
            default:
                hourStr = "FFFFFF";
                break;
    }
        console.log(minute)
    let c = Math.trunc((minute % 30) / 4);
    let d = 16
    let e = 16
    let f = 16
    if (interval === '30mins') {
        minute = minute % 30
        c = Math.trunc((minute % 30) / 4);
        d = Math.trunc(((minute % 30) + 30) / 4)
    } else if (interval === '15mins') {
        minute = minute % 15
        c = Math.trunc((minute % 30) / 4);
        d = Math.trunc(((minute % 30) + 30) / 4)
        e = Math.trunc(((minute % 15) + 15) / 4)
        f = Math.trunc(((minute % 15) + 45) / 4)
    }
   
    const bit = minute % 4;

        for (let i = 15; i >= 0; i--) {

            if (i === c) {
                minuteStr = minuteStr + (2 ** (c % 4)).toString();
            } else if (i === d) {
                minuteStr = minuteStr + (2 ** (((minute % 30) + 30) % 4)).toString();
            } else if (i === e) {
                minuteStr = minuteStr + (2 ** (((minute % 15) + 15) % 4)).toString();
            } else if (i === f) {
                minuteStr = minuteStr + (2 ** (((minute % 15) + 45) % 4)).toString();
            } else {
                minuteStr = minuteStr + "0";
            }

        }
    
         console.log(minuteStr)
        console.log("OUT: hour:" + hourStr + " min:" + minuteStr);
        console.log("FFFFFFFFFFFFFFFF" + hourStr + minuteStr + "00000000")
        return "FFFFFFFFFFFFFFFF" + hourStr + minuteStr + "00000000";
    
}

function getScheduleStart(val: string): Date {
    let hour = 0, minute = 0;
    

   
    for (let h = 21; h >= 16; h--) {
        const b = parseInt(val.substr(h, 1), 16);
        let mask = 0x01;
        for (let shift = 0; shift < 4; shift++) {
            if ((b & mask) != 0) {
                break;
            }
            hour++;
            mask = mask << 1;
        }
        if ((b & mask) != 0) {
            break;
        }
    }
    if (hour == 24) {
        hour = 0;
    }

    for (let m = 37; m >= 22; m--) {
        const b = parseInt(val.substr(m, 1), 16);
        let mask = 0x01;
        for (let shift = 0; shift < 4; shift++) {
            if ((b & mask) != 0) {
                break;
            }
            minute++;
            mask = mask << 1;
        }
        if ((b & mask) != 0) {
            break;
        }
    }
    if (minute > 59) {
        minute = 0;
    }

    return moment().hour(hour).minute(minute).second(0).toDate();
}

function getScheduleInterval(val: string): string {
        console.log(val)
        let count = 0;
        // Count number of hour bits set
        for (let h = 21; h >= 16; h--) {
            const b = parseInt(val.substr(h, 1), 16);
            console.log(b)
            let mask = 0x01;
            for (let shift = 0; shift < 4; shift++) {
                if ((b & mask) != 0) {
                    count++;
                }
                mask = mask << 1;
            }
    }
    let countMinute = 0
    for (let i = 37; i >= 22; i--) {
        if (val[i] !== '0') {
           countMinute++ 
        }
    }
    if (countMinute === 2) {
        return '30mins'
    } else if (countMinute === 4) {
        return '15mins'
    }
        console.log(count)
        switch (count) {
            case 1:
                return "24";
            case 2:
                return "12";
            case 4:
                return "6";
            case 8:
                return "3";
            case 24:
                return "1";
            default:
                return "0";
        }
    
}

function isScheduleEnabled(value: string): boolean {
    return !(value.substr(16, 30) == "000000000000000000000000000000");
}


// ScheduleWindow - see schedule parameters.doc
// Encoded as 45 char hex string
// Hour encoded in chars 16-21 and Minute in chars 22-37 
// Will be FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF0000???? if Window=false (i.e 24 hour)
// Otherwise need to encode windowStart to windowEnd every day 
// Final 2 bytes are MinCycle/SecCycle - top bit set for seconds(unit), remaining bits = sampleValue
function setScheduleWindow(unit: string, sampleValue: number | null, window: boolean, windowStart: string, windowEnd: string): string {
    let schedule = "FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF0000";
    let cycle = "0000";

    if (sampleValue != null && !isNaN(sampleValue)) {
        if (unit == "s" || unit == "") {
            cycle = (sampleValue + 32768).toString(16).padStart(4, "0");
        }
        else {
            cycle = sampleValue.toString(16).padStart(4, "0");
        }
    }
    if (window) {
        let mask = 1;
        let sched = 0;
        const winStart = parseInt(windowStart.split(":")[0]);
        const winEnd = parseInt(windowEnd.split(":")[0]);
        if (winStart == winEnd) {
            sched = 0xFFFFFF;
        }
        else {
            if (winEnd > winStart) {
                // Within 24 hour day
                for (let hour = 0; hour < 24; hour++) {
                    if (hour >= winStart && hour < winEnd) {
                        sched += mask;
                    }
                    mask = mask << 1;
                }
            }
            else {
                // Period crosses midnight
                for (let hour = 0; hour < 24; hour++) {
                    if (hour >= winStart || hour < winEnd) {
                        sched += mask;
                    }
                    mask = mask << 1;
                }
            }
        }
        schedule = "FFFFFFFFFFFFFFFF" + sched.toString(16).padStart(6, "0") + "FFFFFFFFFFFFFFFF0000";
    }

    return (schedule + cycle).toUpperCase();
}

function isScheduleWindowed(value: string): boolean {
    return !(value.substr(16, 6) == "FFFFFF");
}

function getScheduleWindow(value: string, start: boolean): string {

    let mask = 1;
    const sched = parseInt("0x" + value.substr(16, 6));    //Extract hour bit mask 6 bytes, chars 16 - 21
    let winStart = 0;
    let winEnd = 23;
    let started = (sched & 0x0001) != 0;        // If bit 0 (00:00) set then Enabled over midnight
    for (let hour = 0; hour < 24; hour++) {
        if ((sched & mask) > 0) {
            // Look for first bit set as start
            if (!started) {
                winStart = hour;
                if ((sched & 0x0001) == 0) {
                    started = true;
                }
                else {
                    break;
                }
            }
        }
        else {
            // Once started look for bit not set
            if (started) {
                winEnd = hour;
                if ((sched & 0x0001) == 0) {
                    break;
                }
                else {
                    started = false;
                }
            }
        }
        mask = mask << 1;
    }

    if (start) {
        return winStart.toString().padStart(2, '0') + ":00";
    }
    else {
        return winEnd.toString().padStart(2, '0') + ":00";
    }

}

/* Flow alarm threshold conversions 
 * 
 * Digit	0	            1	            2	            3
 * HexVal	8	4	2	1	8	4	2	1	8	4	2	1	8	4	2	1
 * Bit	    15	14	13	12	11	10	9	8	7	6	5	4	3	2	1	0
 *      	Sign	Exponent	Value
 *
 * NOTE: This in number of pulses in sample interval - so ALWAYS > 1!!
 */

// Convert 16bit value to float number
function flowAlarmThresholdFrom16bit(threshold: number): number {
    let sign = 1;
    let exp = 0;
    let value = 0;

    if ((threshold & 0x8000) != 0) {
        sign = -1;
        threshold = ~threshold;     // Two complement or flip all bits
    }
    value = threshold & 0x03FF;
    exp = (threshold & 0x7c00) >> 10;

    return sign * value * (10 ** exp);
}

// Convert float number to 16 bit value
function valueFromFlowAlarmThreshold(threshold: number): number {

    let sign = 1;
    let exp = 0;
    let value = 0;

    if (threshold < 0) {
        sign = -1;
        threshold = -threshold;
    }

    while (threshold > 1024 && exp <= 31) {
        exp++;
        threshold = Math.trunc(threshold / 10);
    }

    value = (exp << 10) + threshold;

    if (sign > 0) {
        return value;
    }
    else {
        return ~value;
    }
}

// Functions to decode/encode DeviceConfig to APIGetLoggerConfigurationsModel & json for update
//
// Params:
//  product: string - product range of logger
//  model: string - model of logger
//  json: APIGetCurrentConfigurationModel - response from /api/aquaguard/CurrentLoggerConfiguration
//  simStore: ArrayStore - details of SIM settings from /api/aquaguard/SimDetailsForCompany
//  gprsStore: ArrayStore - details of Server serrings from /api/aquaguard/GprsDetailsForCompany
//  phoneStore: ArrayStore - details of phone numbers from /api/aquaguard/PhoneNumbersForCompany
//  newSim: function on calling component to add new Sim details
//  newGprs: function on calling component to add new Server details
//  newPhone: function on calling component to add new Phone number details
//
// TODO: Expand to cover all features of config.


export function configFromJson(product: string, model: string, channels: number,
    json: APIGetCurrentConfigurationModel | undefined,
    simStore: Store,
    gprsStore: Store,
    phoneStore: Store,
    newSim: (simDetail: APISimDetail) => void,
    newGprs: (gprsDetail: APIGprsDetail) => void,
    newPhone: (phoneDetail: APIPhoneNumber, idx: number | null) => void
): DeviceConfig {

    const config = CreateDeviceConfig();
    config.Product = product;
    config.Model = model;
    config.ChannelsAvail = channels;

    if (json) {

        if (json.sendToLogger) {
            // Pending updates to be sent - save them in case of further updates
            config.UpdateJSON = json.updateJson;
        }
        config.ServerTime = json.serverTime;
        config.DeviceTime = json.loggerTime;

        // Parse STD config into key:value pairs and merge updateJson values
        let jsonValues = {};
        const configValues = JSON.parse(json.stdParamJson);
        let updateValues = [];
        if (json.updateJson && json.sendToLogger) {
            updateValues = JSON.parse(json.updateJson);
            jsonValues = { ...configValues, ...updateValues };
        }
        else {
            jsonValues = { ...configValues };
        }
        let calValues = {};
        let hwoptions = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];       // Decode of calValues["0009"]
        if (json.calParamJson) {
            calValues = JSON.parse(json.calParamJson);
            if ("0009" in calValues) {
                hwoptions = stringToHexArray(calValues["0009"]);
            }
        }

        config.DataTxSchedule = true;

        config.SWversion = getSwVersion(json.swVersion);
        if ("0081" in jsonValues) {
            config.Sitename = decodeString(jsonValues["0081"]);
        }
        config.MeterConfig = json.meterConfig;
        if ("0011" in updateValues) {
            // Pending change to channels, update MeterConfig
            config.MeterConfig = parseInt(updateValues["0011"], 16)
        }
        config.FlowAType = "OFF";
        config.FlowBType = "OFF";
        switch (config.MeterConfig & 0x00B) {
            case 0x01:
                config.FlowAType = "Forward";
                config.FlowBType = "OFF";
                break
            case 0x02:
                config.FlowAType = "Forward";
                config.FlowBType = "Reverse";
                break
            case 0x03:
                config.FlowAType = "Pulse";
                config.FlowBType = "Direction";
                break
            case 0x08:
                config.FlowAType = "OFF";
                config.FlowBType = "Forward";
                break
            case 0x09:
                config.FlowAType = "Forward";
                config.FlowBType = "Forward";
                break
        }

        // Modbus?
        config.ModbusOnly = false;
        if (hwoptions[11] == 0x04) {
            config.FlowAType = "OFF";
            config.FlowBType = "OFF";
            config.ModbusOnly = true;
            if ("1400" in jsonValues) {
                config.ModbusBitrate = jsonValues["1400"]
            }
            if ("1401" in jsonValues) {
                const dataFormat = HexToSignedInt(jsonValues["1401"], 2)
                if ((dataFormat & 0x01) == 0) {
                    config.ModbusDatabits = 7;
                }
                else {
                    config.ModbusDatabits = 8;
                }
                switch (dataFormat & 0x06) {
                    case 0x00:
                        config.ModbusParity = "None";
                        break;
                    case 0x02:
                        config.ModbusParity = "Odd";
                        break;
                    case 0x04:
                        config.ModbusParity = "Even";
                        break;
                }
            }
            if ("002B" in calValues) {
                config.ModbusVoltage = calValues["002B"]
            }
            if ("0020" in calValues) {
                config.ModbusSettle = HexToSignedInt(calValues["0020"], 2) / 10;
            }
            if ("0234" in jsonValues) {
                config.ModbusPower1 = (HexToSignedInt(jsonValues["0234"], 2) & 0x100) != 0;
                config.AnalogueCIntTransducerPower = (HexToSignedInt(jsonValues["0234"], 2) & 0x200) != 0;
                config.AnalogueCExtTransducerPower = (HexToSignedInt(jsonValues["0234"], 2) & 0x100) != 0;
            }
            if ("0244" in jsonValues) {
                config.ModbusPower2 = (HexToSignedInt(jsonValues["0244"], 2) & 0x100) != 0;
                config.AnalogueDIntTransducerPower = (HexToSignedInt(jsonValues["0244"], 2) & 0x200) != 0;
                config.AnalogueDExtTransducerPower = (HexToSignedInt(jsonValues["0244"], 2) & 0x100) != 0;
            }
            if ("0254" in jsonValues) {
                config.ModbusPower3 = (HexToSignedInt(jsonValues["0254"], 2) & 0x100) != 0;
                config.AnalogueEIntTransducerPower = (HexToSignedInt(jsonValues["0254"], 2) & 0x200) != 0;
                config.AnalogueEExtTransducerPower = (HexToSignedInt(jsonValues["0254"], 2) & 0x100) != 0;
            }

            if (("1404" in jsonValues) && ("0231" in jsonValues)) {
                config.ModbusConfig[0] = DecodeModbusConfig(1, stringToHexArray(jsonValues["1404"]), stringToHexArray(jsonValues["0231"]));
            }
            if (("1405" in jsonValues) && ("0241" in jsonValues)) {
                config.ModbusConfig[1] = DecodeModbusConfig(2, stringToHexArray(jsonValues["1405"]), stringToHexArray(jsonValues["0241"]));
            }
            if (("1406" in jsonValues) && ("0251" in jsonValues)) {
                config.ModbusConfig[2] = DecodeModbusConfig(3, stringToHexArray(jsonValues["1406"]), stringToHexArray(jsonValues["0251"]));
            }
        }

        if ("0078" in jsonValues) {
            config.Latitude = getLatitude(jsonValues["0078"]);
            config.Longitude = getLongitude(jsonValues["0078"]);
        }
        config.CurrentState = "Decommissioned";
        config.Guid = "00000000-0000-0000-0000-000000000000";
        if ("8012" in calValues) {
            config.CurrentState = "Commissioned";
            if ("8011" in calValues) {
                config.Guid = guidFromArray(stringToHexArray(calValues["8011"]));
            }
        }

        //Schedules
        if ("0060" in jsonValues) {
            config.PrimarySchedule = isScheduleEnabled(jsonValues["0060"]);
            if (config.PrimarySchedule) {
                const psched = stringToHexArray(jsonValues["0060"]);
                if (psched.length == 23) {
                    if ((psched[21] & 0x80) > 0) {
                        //rate in seconds
                        config.PrimarySampleUnit = 's';
                        config.PrimarySampleValue = (psched[21] & 0x7f) * 256 + psched[22];
                    }
                    else {
                        // rate in minutes
                        config.PrimarySampleUnit = 'm';
                        config.PrimarySampleValue = psched[21] * 256 + psched[22];
                    }

                    config.PrimaryWindow = isScheduleWindowed(jsonValues["0060"]);
                    if (config.PrimaryWindow) {
                        config.PrimaryWindowStart = getScheduleWindow(jsonValues["0060"], true);
                        config.PrimaryWindowEnd = getScheduleWindow(jsonValues["0060"], false);
                    }
                }
                else {
                    config.PrimaryWindow = false;
                }
            }
            else {
                config.PrimaryWindow = false;
            }
        }
        else {
            config.PrimarySchedule = false;
        }
        if ("0064" in jsonValues) {
            config.SecondarySchedule = isScheduleEnabled(jsonValues["0064"]);
            if (config.SecondarySchedule) {
                const ssched = stringToHexArray(jsonValues["0064"]);
                if (ssched.length == 23) {
                    if ((ssched[21] & 0x80) > 0) {
                        //rate in seconds
                        config.SecondarySampleUnit = 's';
                        config.SecondarySampleValue = (ssched[21] & 0x7f) * 256 + ssched[22];
                    }
                    else {
                        // rate in minutes
                        config.SecondarySampleUnit = 'm';
                        config.SecondarySampleValue = ssched[21] * 256 + ssched[22];
                    }
                    config.SecondaryWindow = isScheduleWindowed(jsonValues["0064"]);
                    if (config.SecondaryWindow) {
                        config.SecondaryWindowStart = getScheduleWindow(jsonValues["0064"], true);
                        config.SecondaryWindowEnd = getScheduleWindow(jsonValues["0064"], false);
                    }
                }
                else {
                    config.SecondaryWindow = false;
                }
            }
            else {
                config.SecondaryWindow = false;
            }
        }
        else {
            config.SecondarySchedule = false;
        }
        if ("0065" in jsonValues) {
            config.AnalogueSchedule = isScheduleEnabled(jsonValues["0065"]);
            if (config.AnalogueSchedule) {
                const asched = stringToHexArray(jsonValues["0065"]);
                if (asched.length == 23) {
                    if ((asched[21] & 0x80) > 0) {
                        //rate in seconds
                        config.AnalogueSampleUnit = 's';
                        config.AnalogueSampleValue = (asched[21] & 0x7f) * 256 + asched[22];
                    }
                    else {
                        // rate in minutes
                        config.AnalogueSampleUnit = 'm';
                        config.AnalogueSampleValue = asched[21] * 256 + asched[22];
                    }
                }
                else {
                    config.AnalogueSchedule = false;
                }
            }
        }
        else {
            config.AnalogueSchedule = false;
        }
        //Alarm rates
        if ("8070" in jsonValues) {
            config.PrimaryAlarm = isScheduleEnabled(jsonValues["8070"]);
            if (config.PrimaryAlarm) {
                const sched = stringToHexArray(jsonValues["8070"]);
                if ((sched[21] & 0x80) > 0) {
                    //rate in seconds
                    config.PrimaryAlarmUnit = 's';
                    config.PrimaryAlarmValue = (sched[21] & 0x7f) * 256 + sched[22];
                }
                else {
                    // rate in minutes
                    config.PrimaryAlarmUnit = 'm';
                    config.PrimaryAlarmValue = sched[21] * 256 + sched[22];
                }
            }
        }
        if ("8071" in jsonValues) {
            config.SecondaryAlarm = isScheduleEnabled(jsonValues["8071"]);
            if (config.SecondaryAlarm) {
                const sched = stringToHexArray(jsonValues["8071"]);
                if ((sched[21] & 0x80) > 0) {
                    //rate in seconds
                    config.SecondaryAlarmUnit = 's';
                    config.SecondaryAlarmValue = (sched[21] & 0x7f) * 256 + sched[22];
                }
                else {
                    // rate in minutes
                    config.SecondaryAlarmUnit = 'm';
                    config.SecondaryAlarmValue = sched[21] * 256 + sched[22];
                }
            }
        }
        if ("8074" in jsonValues) {
            config.AnalogueAlarm = isScheduleEnabled(jsonValues["8074"]);
            if (config.AnalogueAlarm) {
                const sched = stringToHexArray(jsonValues["8074"]);
                if ((sched[21] & 0x80) > 0) {
                    //rate in seconds
                    config.AnalogueAlarmUnit = 's';
                    config.AnalogueAlarmValue = (sched[21] & 0x7f) * 256 + sched[22];
                }
                else {
                    // rate in minutes
                    config.AnalogueAlarmUnit = 'm';
                    config.AnalogueAlarmValue = sched[21] * 256 + sched[22];
                }
            }
        }

        //Communication
        if ("0080" in jsonValues) {
            config.ModemIMEI = decodeString(jsonValues["0080"]);
        }
        if ("0009" in calValues) {
            const modemByte = stringToHexArray(calValues["0009"])[4];
            if ((modemByte & 0x02) > 0) {
                config.ModemType = "SIM7070 Modem (NB-IoT)";
            }
            else if ((modemByte & 0x04) > 0) {
                config.ModemType = "SIM800F Modem (2G)";
            }
            else if ((modemByte & 0x08) > 0) {
                config.ModemType = "Iridium Edge satellite Modem";
            }
            else if ((modemByte & 0x10) > 0) {
                config.ModemType = "SIM7000E Modem (4G NB-IoT)";
            }
            else if ((modemByte & 0x80) > 0) {
                config.ModemType = "SIM5300E Modem (3G)";
            }
        }

        config.PreferredRAT = "00";
        if ("0803" in jsonValues
            && (config.ModemType == "SIM5300E Modem (3G)" || config.ModemType == "SIM7000E Modem (4G NB-IoT)" || config.ModemType == "SIM7070 Modem (NB-IoT)")
        ) {
            config.PreferredRAT = jsonValues["0803"];
        }
        config.Preferred4G = "00"
        if ("0804" in jsonValues
            && (config.ModemType == "SIM7000E Modem (4G NB-IoT)" || config.ModemType == "SIM7070 Modem (NB-IoT)")
        ) {
            config.Preferred4G = jsonValues["0804"];
        }

        config.Antenna = "Internal";
        if ("0008" in calValues) {
            const hwByte = stringToHexArray(calValues["0008"])[8];
            config.ExtAntennaAvail = (hwByte & 0x80) > 0;
        }
        if (config.ExtAntennaAvail && "0808" in jsonValues) {
            const antByte = parseInt(jsonValues["0808"], 16);
            if ((antByte & 0x02) > 0) {
                config.Antenna = "External";
            }
        }

        config.SimSetting = "0";
        if ("0040" in jsonValues) {
            if (jsonValues["0040"][0] != 0) {
                const simDetail: APISimDetail = {
                    id: 0,
                    name: decodeString(jsonValues["0040"]),
                    apn: decodeString(jsonValues["0040"]),
                    username: decodeString(jsonValues["0041"]),
                    password: decodeString(jsonValues["0042"]),
                    description: "",
                    lastUpdate: new Date(),
                    fkCompanyId: 0
                };
                (simStore as any)._array.map((sim: APISimDetail) => {
                    if (sim.apn == simDetail.apn && sim.username == simDetail.username && sim.password == simDetail.password) {
                        simDetail.id = sim.id;
                        simDetail.name = sim.name;
                        simDetail.description = sim.description;
                    }
                });
                if (simDetail.id > 0) {
                    config.SimSetting = simDetail.id.toString();
                }
                else {
                    // Need to create SIM detail record - id will be saved once created
                    newSim(simDetail);
                }
            }
        }

        // phone numbers lookup phonedetail records from phoneStore for any numbers recorded on logger
        config.PhoneNumbers = [null, null, null, null, null, null, null, null];
        for (let i = 0; i <= 7; i++) {
            const param = (i + 0x50).toString(16).padStart(4, '0');
            if (param in jsonValues) {
                const phoneDetail: APIPhoneNumber = {
                    id: 0,
                    name: decodePhone(jsonValues[param]),
                    type: "Server",
                    number: decodePhone(jsonValues[param]),
                    description: "",
                    lastUpdate: new Date(),
                    fkCompanyId: 0
                };
                (phoneStore as any)._array.map((phone: APIPhoneNumber) => {
                    if (phone.number == phoneDetail.number) {
                        phoneDetail.id = phone.id;
                        phoneDetail.name = phone.name;
                        phoneDetail.description = phone.description;
                    }
                });
                if (phoneDetail.number != "") {
                    if (phoneDetail.id > 0) {
                        config.PhoneNumbers[i] = phoneDetail;
                    }
                    else {
                        // Need to create Phone number record - id will be saved once created
                        newPhone(phoneDetail, i);
                    }
                }
            }
        }

        config.SignalCheckPhone = "0";
        if ("0097" in jsonValues) {
            const param = parseInt(jsonValues["0097"], 16);
            let mask = 0x01;
            for (let i = 0; i < 7; i++) {
                if ((param & mask) > 0 && config.PhoneNumbers[i] != null) {
                    config.SignalCheckPhone = config.PhoneNumbers[i]?.id.toString() || "0";
                }
                mask = mask << 1;
            }
        }
        config.SignalCheckDelay = "0";
        if ("006C" in jsonValues) {
            // 30 sec increments
            const val = parseInt(jsonValues["006C"], 16);
            if (val > 0) {
                config.SignalCheckDelay = "1";
            }
            if (val > 2) {
                config.SignalCheckDelay = "2";
            }
            if (val >= 10) {
                config.SignalCheckDelay = "5";
            }

        }
        config.SignalCheckDuration = "0";
        // Maps to 006A & 006B count & interval (30s)
        if ("006A" in jsonValues) {
            // 30 sec increments
            const val = parseInt(jsonValues["006A"], 16);
            if (val > 0) {
                config.SignalCheckDuration = "1";
            }
            if (val > 2) {
                config.SignalCheckDuration = "2";
            }
            if (val >= 10) {
                config.SignalCheckDuration = "5";
            }

        }
        config.DataCheckServer = "0";
        if ("0043" in jsonValues) {
            if (jsonValues["0043"][0] != 0) {
                const gprsDetail: APIGprsDetail = {
                    id: 0,
                    name: decodeString(jsonValues["0043"]) + ":" + HexToSignedInt(jsonValues["0044"], 4),
                    hostname: decodeString(jsonValues["0043"]),
                    port: HexToSignedInt(jsonValues["0044"], 4),
                    description: "",
                    lastUpdate: new Date(),
                    fkCompanyId: 0
                };
                (gprsStore as any)._array.map((server: APIGprsDetail) => {
                    if (server.hostname == gprsDetail.hostname && server.port == gprsDetail.port) {
                        gprsDetail.id = server.id;
                        gprsDetail.name = server.name;
                        gprsDetail.description = server.description;
                    }
                });
                if (gprsDetail.id > 0) {
                    config.DataCheckServer = gprsDetail.id.toString();
                }
                else {
                    // Need to create Server detail record - id will be saved once created
                    newGprs(gprsDetail);
                }
            }
        }

        if ("0063" in jsonValues) {
            config.DataTxTime = getScheduleStart(jsonValues["0063"]);
            config.DataTxInterval = getScheduleInterval(jsonValues["0063"]);
            if (config.DataTxInterval != "0") {
                config.DataTxSchedule = true;
            }
        }
        else {
            config.DataTxSchedule = false;
            config.DataTxTime = moment().hour(0).minute(0).toDate();
            config.DataTxInterval = "0";
        }

        if ("0061" in jsonValues) {
            config.SmsTxTime = getScheduleStart(jsonValues["0061"]);
            config.SmsTxInterval = getScheduleInterval(jsonValues["0061"]);
            if (config.SmsTxInterval != "0") {
                config.SmsTxSchedule = true;
            }
        }
        else {
            config.SmsTxTime = moment().hour(0).minute(0).toDate();
            config.SmsTxInterval = "0";
            config.SmsTxSchedule = false;
        }

        if ("0062" in jsonValues) {
            config.ListenTime = getScheduleStart(jsonValues["0062"]);
            config.ListenInterval = getScheduleInterval(jsonValues["0062"]);
            if (config.ListenInterval != "0") {
                config.ListenSchedule = true;
            }
        }
        else {
            config.ListenTime = moment().hour(0).minute(0).toDate();
            config.ListenInterval = "0";
            config.ListenSchedule = false;
        }


        config.SmsTxServer1 = "0";
        if ("0090" in jsonValues) {
            const param = parseInt(jsonValues["0090"], 16);
            let mask = 0x01;
            for (let i = 0; i < 7; i++) {
                if ((param & mask) > 0 && config.PhoneNumbers[i] != null) {
                    config.SmsTxServer1 = config.PhoneNumbers[i]?.id.toString() || "0";
                }
                mask = mask << 1;
            }
        }
        config.SmsTxServer2 = "0";
        if ("0091" in jsonValues) {
            const param = parseInt(jsonValues["0091"], 16);
            let mask = 0x01;
            for (let i = 0; i < 7; i++) {
                if ((param & mask) > 0 && config.PhoneNumbers[i] != null) {
                    config.SmsTxServer2 = config.PhoneNumbers[i]?.id.toString() || "0";
                }
                mask = mask << 1;
            }
        }
        config.SMSAlarm1 = "0";
        if ("0092" in jsonValues) {
            const param = parseInt(jsonValues["0092"], 16);
            let mask = 0x01;
            for (let i = 0; i < 7; i++) {
                if ((param & mask) > 0 && config.PhoneNumbers[i] != null) {
                    config.SMSAlarm1 = config.PhoneNumbers[i]?.id.toString() || "0";
                }
                mask = mask << 1;
            }
        }
        config.SMSAlarm2 = "0";
        if ("0094" in jsonValues) {
            const param = parseInt(jsonValues["0094"], 16);
            let mask = 0x01;
            for (let i = 0; i < 7; i++) {
                if ((param & mask) > 0 && config.PhoneNumbers[i] != null) {
                    config.SMSAlarm2 = config.PhoneNumbers[i]?.id.toString() || "0";
                }
                mask = mask << 1;
            }
        }



        // Channels
        if ("0214" in jsonValues) {
            // Channel A
            if ((config.MeterConfig & 0x01) == 0 && (config.MeterConfig & 0x02) == 0) {
                config.FlowA = false;
                config.FlowAType = "OFF";
            }
            else {
                config.FlowA = true;
                const controlFlags = parseInt(jsonValues["0214"], 16);
                if ((controlFlags & 0x01) != 0) {
                    config.FlowAPrimaryReadings = true;
                }
                if ((controlFlags & 0x02) != 0) {
                    config.FlowASecondaryReadings = true;
                }
                if ((controlFlags & 0x10) != 0) {
                    config.FlowAPrimaryDeDup = true;
                }
                if ((controlFlags & 0x20) != 0) {
                    config.FlowASecondaryDeDup = true;
                }
                if ((controlFlags & 0x40) != 0) {
                    config.FlowAPrimaryMinLog = true;
                }
                if ((controlFlags & 0x80) != 0) {
                    config.FlowASecondaryMinLog = true;
                }
                if ((controlFlags & 0x400) != 0) {
                    config.FlowAPrimaryEvents = true;
                }
                if ((controlFlags & 0x800) != 0) {
                    config.FlowASecondaryEvents = true;
                }
                if ("0210" in jsonValues) {
                    config.FlowARef = decodeString(jsonValues["0210"]);
                }
                let multiplier = decodeFlowMultiplier(0x10);
                if ("0012" in jsonValues) {
                    config.FlowAMultiplier = "0x" + jsonValues["0012"];
                    multiplier = decodeFlowMultiplier(parseInt(config.FlowAMultiplier, 16));
                    config.FlowAUnits = multiplier.units;
                }
                config.FlowAType = "Forward";
                if ((config.MeterConfig & 0x0B) == 0x03) {
                    config.FlowAType = "Pulse";
                }
                if ("1014" in jsonValues) {
                    config.FlowATotal = parseInt(jsonValues["1014"], 16) * multiplier.factor;
                }
            }
        }
        if ("0224" in jsonValues) {
            // Channel B
            if ((config.MeterConfig & 0x08) == 0 && (config.MeterConfig & 0x02) == 0) {
                config.FlowB = false;
            }
            else {
                config.FlowB = true;
                const controlFlags = parseInt(jsonValues["0224"], 16);
                if ((controlFlags & 0x01) != 0) {
                    config.FlowBPrimaryReadings = true;
                }
                if ((controlFlags & 0x02) != 0) {
                    config.FlowBSecondaryReadings = true;
                }
                if ((controlFlags & 0x10) != 0) {
                    config.FlowBPrimaryDeDup = true;
                }
                if ((controlFlags & 0x20) != 0) {
                    config.FlowBSecondaryDeDup = true;
                }
                if ((controlFlags & 0x40) != 0) {
                    config.FlowBPrimaryMinLog = true;
                }
                if ((controlFlags & 0x80) != 0) {
                    config.FlowBSecondaryMinLog = true;
                }
                if ((controlFlags & 0x400) != 0) {
                    config.FlowBPrimaryEvents = true;
                }
                if ((controlFlags & 0x800) != 0) {
                    config.FlowBSecondaryEvents = true;
                }

                if ("0220" in jsonValues) {
                    config.FlowBRef = decodeString(jsonValues["0220"]);
                }
                let multiplier = decodeFlowMultiplier(0x10);
                if ("0013" in jsonValues) {
                    config.FlowBMultiplier = "0x" + jsonValues["0013"];
                    multiplier = decodeFlowMultiplier(parseInt(config.FlowBMultiplier, 16));
                    config.FlowBUnits = multiplier.units;
                }
                config.FlowBType = "Forward";
                if ((config.MeterConfig & 0x0B) == 0x02) {
                    config.FlowBType = "Reverse";
                }
                if ((config.MeterConfig & 0x0B) == 0x03) {
                    config.FlowBType = "Direction";
                }
                if ("1016" in jsonValues) {
                    config.FlowBTotal = parseInt(jsonValues["1016"], 16) * multiplier.factor;
                }
            }
        }
        if ("0234" in jsonValues) {
            // Channel C
            if ((config.MeterConfig & 0x100) == 0) {
                config.AnalogueC = false;
            }
            else {
                config.AnalogueC = true;
                const controlFlags = parseInt(jsonValues["0234"], 16);
                if ((controlFlags & 0x01) != 0) {
                    config.AnalogueCPrimaryReadings = true;
                }
                if ((controlFlags & 0x02) != 0) {
                    config.AnalogueCSecondaryReadings = true;
                }
                if ((controlFlags & 0x40) != 0) {
                    config.AnalogueCPrimaryMinLog = true;
                }
                if ((controlFlags & 0x80) != 0) {
                    config.AnalogueCSecondaryMinLog = true;
                }
                if ((controlFlags & 0x400) != 0) {
                    config.AnalogueCPrimaryEvents = true;
                }
                if ((controlFlags & 0x800) != 0) {
                    config.AnalogueCSecondaryEvents = true;
                }
                if ((controlFlags & 0x100) != 0) {
                    config.AnalogueCExtTransducerPower = true;
                }
                if ((controlFlags & 0x200) != 0) {
                    config.AnalogueCIntTransducerPower = true;
                }
                if ("0230" in jsonValues) {
                    config.AnalogueCRef = decodeString(jsonValues["0230"]);
                }
                if ("0233" in jsonValues) {
                    config.AnalogueCUnits = decodeString(jsonValues["0233"]);
                }
                if (hwoptions[6] > 0) {
                    config.AnalogueCType = "Pressure";
                }
                if ((hwoptions[12] & 0x07) > 0) {
                    config.AnalogueCType = "Analogue - Voltage";
                }
                if ((hwoptions[12] & 0x08) > 0) {
                    config.AnalogueCType = "Analogue - Current";
                    config.AnalogueCCurrent = true;
                }
                if ("0232" in jsonValues) {
                    config.AnalogueCDp = parseInt(jsonValues["0232"], 16)
                }
                switch (hwoptions[6]) {
                    case 1:
                        config.AnalogueCTransducerRange = 10;
                        config.AnalogueCSensor = "0 - 10 bar"
                        break;
                    case 2:
                        config.AnalogueCTransducerRange = 20;
                        config.AnalogueCSensor = "0 - 20 bar"
                        break;
                    case 4:
                        config.AnalogueCTransducerRange = 5;
                        config.AnalogueCSensor = "0 - 5 bar"
                        break;
                    default:
                        config.AnalogueCTransducerRange = 0;
                }
                // AnalogueTransfer
                if ("0360" in jsonValues) {
                    config.AnalogueCTransfer = decodeAnalogueTransfer(jsonValues["0360"]);
                }
                if ("0350" in jsonValues) {
                    const acdp = config.AnalogueCDp != null ? config.AnalogueCDp : 0;
                    config.Modbus1Offset = decodeModbusOffset(jsonValues["0350"], acdp);
                }
            }
        }
        if ("0244" in jsonValues) {
            // Channel D
            if ((config.MeterConfig & 0x800) == 0) {
                config.AnalogueD = false;
            }
            else {
                config.AnalogueD = true;
                const controlFlags = parseInt(jsonValues["0244"], 16);
                if ((controlFlags & 0x01) != 0) {
                    config.AnalogueDPrimaryReadings = true;
                }
                if ((controlFlags & 0x02) != 0) {
                    config.AnalogueDSecondaryReadings = true;
                }
                if ((controlFlags & 0x40) != 0) {
                    config.AnalogueDPrimaryMinLog = true;
                }
                if ((controlFlags & 0x80) != 0) {
                    config.AnalogueDSecondaryMinLog = true;
                }
                if ((controlFlags & 0x400) != 0) {
                    config.AnalogueDPrimaryEvents = true;
                }
                if ((controlFlags & 0x800) != 0) {
                    config.AnalogueDSecondaryEvents = true;
                }
                if ((controlFlags & 0x100) != 0) {
                    config.AnalogueDExtTransducerPower = true;
                }
                if ((controlFlags & 0x200) != 0) {
                    config.AnalogueDIntTransducerPower = true;
                }
                if ("0240" in jsonValues) {
                    config.AnalogueDRef = decodeString(jsonValues["0240"]);
                }
                if ("0243" in jsonValues) {
                    config.AnalogueDUnits = decodeString(jsonValues["0243"]);
                }
                if (hwoptions[7] > 0) {
                    config.AnalogueDType = "Pressure";
                }
                if ((hwoptions[13] & 0x07) > 0) {
                    config.AnalogueDType = "Analogue - Voltage";
                }
                if ((hwoptions[13] & 0x08) > 0) {
                    config.AnalogueDType = "Analogue - Current";
                    config.AnalogueDCurrent = true;
                }
                if ("0242" in jsonValues) {
                    config.AnalogueDDp = parseInt(jsonValues["0242"], 16);
                }
                switch (hwoptions[7]) {
                    case 1:
                        config.AnalogueDTransducerRange = 10;
                        config.AnalogueDSensor = "0 - 10 bar"
                        break;
                    case 2:
                        config.AnalogueDTransducerRange = 20;
                        config.AnalogueDSensor = "0 - 20 bar"
                        break;
                    case 4:
                        config.AnalogueDTransducerRange = 5;
                        config.AnalogueDSensor = "0 - 5 bar"
                        break;
                    default:
                        config.AnalogueCTransducerRange = 0;
                }
                // AnalogueTransfer
                if ("0361" in jsonValues) {
                    config.AnalogueDTransfer = decodeAnalogueTransfer(jsonValues["0361"]);
                }
                if ("0351" in jsonValues) {
                    const addp = config.AnalogueDDp != null ? config.AnalogueDDp : 0;
                    config.Modbus2Offset = decodeModbusOffset(jsonValues["0351"] ,addp);
                }

            }
        }
        if ("0254" in jsonValues) {
            // Channel E
            if ((config.MeterConfig & 0x2000) == 0) {
                config.AnalogueE = false;
            }
            else {
                config.AnalogueE = true;
                const controlFlags = parseInt(jsonValues["0254"], 16);
                if ((controlFlags & 0x01) != 0) {
                    config.AnalogueEPrimaryReadings = true;
                }
                if ((controlFlags & 0x02) != 0) {
                    config.AnalogueESecondaryReadings = true;
                }
                if ((controlFlags & 0x40) != 0) {
                    config.AnalogueEPrimaryMinLog = true;
                }
                if ((controlFlags & 0x80) != 0) {
                    config.AnalogueESecondaryMinLog = true;
                }
                if ((controlFlags & 0x400) != 0) {
                    config.AnalogueEPrimaryEvents = true;
                }
                if ((controlFlags & 0x800) != 0) {
                    config.AnalogueESecondaryEvents = true;
                }
                if ((controlFlags & 0x100) != 0) {
                    config.AnalogueEExtTransducerPower = true;
                }
                if ((controlFlags & 0x200) != 0) {
                    config.AnalogueEIntTransducerPower = true;
                }
                if ("0250" in jsonValues) {
                    config.AnalogueERef = decodeString(jsonValues["0250"]);
                }
                if ("0253" in jsonValues) {
                    config.AnalogueEUnits = decodeString(jsonValues["0253"]);
                }
                config.AnalogueEType = "Pressure";
                if ("0252" in jsonValues) {
                    config.AnalogueEDp = parseInt(jsonValues["0252"], 16);
                }
                if ("0352" in jsonValues) {
                    const aedp = config.AnalogueEDp != null ? config.AnalogueEDp : 0;
                    config.Modbus3Offset = decodeModbusOffset(jsonValues["0352"], aedp);
                }
            }
        }

        // Alarms
        const msgParam = 0x00E0;
        const cfgParam = 0x1A0;
        for (let i = 0; i < 15; i++) {
            // Alarm Messages
            let strParam = (msgParam + i).toString(16).padStart(4, '0').toUpperCase();
            if (strParam in jsonValues) {
                config.Alarms[i].Message = decodeString(jsonValues[strParam]);
            }
            // Alarm Config
            if (config.SWversion > "040B") {
                // versions 4.11 and above have 13 byte alarm slot
                strParam = (cfgParam + i).toString(16).padStart(4, '0').toUpperCase();
                if (strParam in jsonValues) {
                    const almConfig = stringToHexArray(jsonValues[strParam]);
                    config.Alarms[i].SetThreshold = flowAlarmThresholdFrom16bit(almConfig[0] * 256 + almConfig[1]);
                    config.Alarms[i].ClearThreshold = flowAlarmThresholdFrom16bit(almConfig[2] * 256 + almConfig[3]);
                    config.Alarms[i].SetCount = almConfig[8];
                    config.Alarms[i].MaxAlarms = almConfig[9];
                    config.Alarms[i].ClearCount = almConfig[12];

                    const setOperator = (almConfig[5] & 0xE0) >> 5;
                    const setInput = (almConfig[4] & 0x1F);
                    const actions = almConfig[10] * 256 + almConfig[11];
                    switch (setOperator) {
                        case 0:
                            config.Alarms[i].SetOperator = "Off";
                            break;
                        case 1:
                            config.Alarms[i].SetOperator = "EQ";
                            break;
                        case 2:
                            config.Alarms[i].SetOperator = "NE";
                            break;
                        case 3:
                            config.Alarms[i].SetOperator = "LT";
                            break;
                        case 4:
                            config.Alarms[i].SetOperator = "GT";
                            break;
                        case 5:
                            config.Alarms[i].SetOperator = "LE";
                            break;
                        case 6:
                            config.Alarms[i].SetOperator = "GE";
                            break;
                        case 7:
                            config.Alarms[i].SetOperator = "BT";
                            break;
                    }
                    switch (setInput) {
                        case 1:
                            config.Alarms[i].Input = "A";
                            break;
                        case 2:
                            config.Alarms[i].Input = "B";
                            break;
                        case 4:
                            config.Alarms[i].Input = "C";
                            break;
                        case 8:
                            config.Alarms[i].Input = "D";
                            break;
                        case 14:
                            config.Alarms[i].Input = "E";
                            break;
                        default:
                            // Ignore all other settings for now.....
                            config.Alarms[i].Input = "Off";
                            break
                    }
                    if ((actions & 0x0001) > 0) {
                        config.Alarms[i].SmsActionSetMessage = true;
                    }
                    if ((actions & 0x0002) > 0) {
                        config.Alarms[i].SmsActionSetFastline = true;
                    }
                    if ((actions & 0x0004) > 0) {
                        config.Alarms[i].SmsActionClrMessage = true;
                    }
                    if ((actions & 0x0008) > 0) {
                        config.Alarms[i].SmsActionClrFastline = true;
                    }
                    if ((actions & 0x0010) > 0) {
                        // Log event on set
                    }
                    if ((actions & 0x0020) > 0) {
                        // Log event on clr
                    }
                    if ((actions & 0x0040) > 0) {
                        config.Alarms[i].SmsActionSetSendUpdate = true;
                    }
                    if ((actions & 0x0080) > 0) {
                        config.Alarms[i].SmsActionClrSendUpdate = true;
                    }
                    if ((actions & 0x0100) > 0) {
                        config.Alarms[i].SmsActionSetSendData = true;
                    }
                    if ((actions & 0x0200) > 0) {
                        config.Alarms[i].SmsActionClrSendData = true;
                    }
                    if ((actions & 0x0400) > 0) {
                        config.Alarms[i].GprsActionSetConnect = true;
                    }
                    if ((actions & 0x0800) > 0) {
                        config.Alarms[i].GprsActionClrConnect = true;
                    }
                    if ((actions & 0x1000) > 0) {
                        // Create PDU message on set
                    }
                    if ((actions & 0x2000) > 0) {
                        // Create PDU message on clr
                    }

                }
            }
            else {
                // versions 4.10 and below have 11 byte alarm slot
                strParam = (cfgParam + i).toString(16).padStart(4, '0').toUpperCase();
                if (strParam in jsonValues) {
                    const almConfig = stringToHexArray(jsonValues[strParam]);
                    config.Alarms[i].SetThreshold = almConfig[0] * 256 + almConfig[1];
                    config.Alarms[i].ClearThreshold = almConfig[2] * 256 + almConfig[3];
                    config.Alarms[i].SetCount = almConfig[6];
                    config.Alarms[i].MaxAlarms = almConfig[7];
                    config.Alarms[i].ClearCount = almConfig[10];

                    const setOperator = (almConfig[4] & 0xE0) >> 5;
                    const setInput = (almConfig[4] & 0x1F);
                    const actions = almConfig[8] * 256 + almConfig[9];
                    switch (setOperator) {
                        case 0:
                            config.Alarms[i].SetOperator = "Off";
                            break;
                        case 1:
                            config.Alarms[i].SetOperator = "EQ";
                            break;
                        case 2:
                            config.Alarms[i].SetOperator = "NE";
                            break;
                        case 3:
                            config.Alarms[i].SetOperator = "LT";
                            break;
                        case 4:
                            config.Alarms[i].SetOperator = "GT";
                            break;
                        case 5:
                            config.Alarms[i].SetOperator = "LE";
                            break;
                        case 6:
                            config.Alarms[i].SetOperator = "GE";
                            break;
                        case 7:
                            config.Alarms[i].SetOperator = "BT";
                            break;
                    }
                    switch (setInput) {
                        case 1:
                            config.Alarms[i].Input = "A";
                            break;
                        case 2:
                            config.Alarms[i].Input = "B";
                            break;
                        case 4:
                            config.Alarms[i].Input = "C";
                            break;
                        case 8:
                            config.Alarms[i].Input = "D";
                            break;
                        case 14:
                            config.Alarms[i].Input = "E";
                            break;
                        default:
                            // Ignore all other settings for now.....
                            config.Alarms[i].Input = "Off";
                            break
                    }
                    if ((actions & 0x0001) > 0) {
                        config.Alarms[i].SmsActionSetMessage = true;
                    }
                    if ((actions & 0x0002) > 0) {
                        config.Alarms[i].SmsActionSetFastline = true;
                    }
                    if ((actions & 0x0004) > 0) {
                        config.Alarms[i].SmsActionClrMessage = true;
                    }
                    if ((actions & 0x0008) > 0) {
                        config.Alarms[i].SmsActionClrFastline = true;
                    }
                    if ((actions & 0x0010) > 0) {
                        // Log event on set
                    }
                    if ((actions & 0x0020) > 0) {
                        // Log event on clr
                    }
                    if ((actions & 0x0040) > 0) {
                        config.Alarms[i].SmsActionSetSendUpdate = true;
                    }
                    if ((actions & 0x0080) > 0) {
                        config.Alarms[i].SmsActionClrSendUpdate = true;
                    }
                    if ((actions & 0x0100) > 0) {
                        config.Alarms[i].SmsActionSetSendData = true;
                    }
                    if ((actions & 0x0200) > 0) {
                        config.Alarms[i].SmsActionClrSendData = true;
                    }
                    if ((actions & 0x0400) > 0) {
                        config.Alarms[i].GprsActionSetConnect = true;
                    }
                    if ((actions & 0x0800) > 0) {
                        config.Alarms[i].GprsActionClrConnect = true;
                    }
                    if ((actions & 0x1000) > 0) {
                        // Create PDU message on set
                    }
                    if ((actions & 0x2000) > 0) {
                        // Create PDU message on clr
                    }

                }

            }

            // Need to adjust thresholds for A & B channels
            // using sample rate and multiplier to give l/s
            if (config.Alarms[i].Input == "A") {
                const multiplier = decodeFlowMultiplier(parseInt(config.FlowAMultiplier, 16));
                let sampleRate = config.PrimarySampleValue || 0;
                if (config.PrimarySampleUnit == 'm') {
                    sampleRate = sampleRate * 60;
                }
                if (config.Alarms[i].SetThreshold != null) {
                    if (multiplier.units == "m3") {
                        // convert to litres
                        config.Alarms[i].SetThreshold = (config.Alarms[i].SetThreshold || 0) * 1000;
                    }
                    config.Alarms[i].SetThreshold = ((config.Alarms[i].SetThreshold || 0) * multiplier.factor) / sampleRate;
                }
                if (config.Alarms[i].ClearThreshold != null) {
                    if (multiplier.units == "m3") {
                        // convert to litres
                        config.Alarms[i].ClearThreshold = (config.Alarms[i].ClearThreshold || 0) * 1000;
                    }
                    config.Alarms[i].ClearThreshold = ((config.Alarms[i].ClearThreshold || 0) * multiplier.factor) / sampleRate;
                }
            }
            if (config.Alarms[i].Input == "B") {
                const multiplier = decodeFlowMultiplier(parseInt(config.FlowBMultiplier, 16));
                let sampleRate = config.PrimarySampleValue || 0;
                if (config.PrimarySampleUnit == 'm') {
                    sampleRate = sampleRate * 60;
                }
                if (config.Alarms[i].SetThreshold != null) {
                    if (multiplier.units == "m3") {
                        // convert to litres
                        config.Alarms[i].SetThreshold = (config.Alarms[i].SetThreshold || 0) * 1000;
                    }
                    config.Alarms[i].SetThreshold = ((config.Alarms[i].SetThreshold || 0) * multiplier.factor) / sampleRate;
                }
                if (config.Alarms[i].ClearThreshold != null) {
                    if (multiplier.units == "m3") {
                        // convert to litres
                        config.Alarms[i].ClearThreshold = (config.Alarms[i].ClearThreshold || 0) * 1000;
                    }
                    config.Alarms[i].ClearThreshold = ((config.Alarms[i].ClearThreshold || 0) * multiplier.factor) / sampleRate;
                }
            }
            if (config.Alarms[i].Input == "C") {
                const scaler = Math.pow(10, config.AnalogueCDp || 0);
                config.Alarms[i].SetThreshold = (config.Alarms[i].SetThreshold || 0) / scaler;
                config.Alarms[i].ClearThreshold = (config.Alarms[i].ClearThreshold || 0) / scaler;
            }
            if (config.Alarms[i].Input == "D") {
                const scaler = Math.pow(10, config.AnalogueDDp || 0);
                config.Alarms[i].SetThreshold = (config.Alarms[i].SetThreshold || 0) / scaler;
                config.Alarms[i].ClearThreshold = (config.Alarms[i].ClearThreshold || 0) / scaler;
            }
            if (config.Alarms[i].Input == "E") {
                const scaler = Math.pow(10, config.AnalogueEDp || 0);
                config.Alarms[i].SetThreshold = (config.Alarms[i].SetThreshold || 0) / scaler;
                config.Alarms[i].ClearThreshold = (config.Alarms[i].ClearThreshold || 0) / scaler;
            }
        }   // end for alarm slots
    }

    return config;
}

// Build Update JSON by comparing org with config and updating any changed fields

export function jsonFromConfig(simStore: Store,
    gprsStore: Store,
    phoneStore: Store,
    org?: DeviceConfig,
    config?: DeviceConfig,
): string {
    let updateJson = '{';

    if (!org) {
        // Create empty config if none specified
        org = CreateDeviceConfig();
    }

    if (config) {

        // Any existing updates, which may be overidden later
        if (config?.UpdateJSON) {
            updateJson = updateJson + config.UpdateJSON.substr(1, config.UpdateJSON.length - 2) + ',';
        }

        // SiteID
        if (org.Sitename != config.Sitename) {
            updateJson = updateJson + '"0081": "' + encodeString(config.Sitename, 15) + '",';
        }
        // Device GPS location
        if (config.Latitude != null && config.Longitude != null) {
            if (org.Latitude != config.Latitude
                || org.Longitude != config.Longitude) {
                updateJson = updateJson + '"0078": "' + getCooord(config.Latitude, config.Longitude) + '",';
            }
        }

        // Schedules
        if (config.PrimarySchedule) {
            if (!org.PrimarySchedule
                || config.PrimarySampleValue != org.PrimarySampleValue
                || config.PrimarySampleUnit != org.PrimarySampleUnit
                || config.PrimaryWindow != org.PrimaryWindow
                || config.PrimaryWindowStart != org.PrimaryWindowStart
                || config.PrimaryWindowEnd != org.PrimaryWindowEnd) {
                updateJson = updateJson + '"0060": "' + setScheduleWindow(config.PrimarySampleUnit, config.PrimarySampleValue, config.PrimaryWindow, config.PrimaryWindowStart, config.PrimaryWindowEnd) + '",';
            }
        }
        else {
            if (org.PrimarySchedule) {
                updateJson = updateJson + '"0060": "FFFFFFFFFFFFFFFF000000000000000000000000000000",';
            }
        }


        if (config.SecondarySchedule) {
            if (!org.SecondarySchedule
                || config.SecondarySampleValue != org.SecondarySampleValue
                || config.SecondarySampleUnit != org.SecondarySampleUnit
                || config.SecondaryWindow != org.SecondaryWindow
                || config.SecondaryWindowStart != org.SecondaryWindowStart
                || config.SecondaryWindowEnd != org.SecondaryWindowEnd) {
                updateJson = updateJson + '"0064": "' + setScheduleWindow(config.SecondarySampleUnit, config.SecondarySampleValue, config.SecondaryWindow, config.SecondaryWindowStart, config.SecondaryWindowEnd) + '",';
            }
        }
        else {
            if (org.SecondarySchedule) {
                updateJson = updateJson + '"0064": "FFFFFFFFFFFFFFFF000000000000000000000000000000",';
            }
        }
        if (config.AnalogueSchedule) {
            if (!org.AnalogueSchedule
                || config.AnalogueSampleValue != org.AnalogueSampleValue
                || config.AnalogueSampleUnit != org.AnalogueSampleUnit) {
                updateJson = updateJson + '"0065": "' + setScheduleWindow(config.AnalogueSampleUnit, config.AnalogueSampleValue, false, "Never", "Never") + '",';
            }
        }
        else {
            if (org.AnalogueSchedule) {
                updateJson = updateJson + '"0065": "FFFFFFFFFFFFFFFF000000000000000000000000000000",';
            }
        }
        // Alarm Rates
        if (config.PrimaryAlarm) {
            if (config.PrimaryAlarm != org.PrimaryAlarm
                || config.PrimaryAlarmUnit != org.PrimaryAlarmUnit
                || config.PrimaryAlarmValue != org.PrimaryAlarmValue) {
                updateJson = updateJson + '"8070": "' + setScheduleWindow(config.PrimaryAlarmUnit, config.PrimaryAlarmValue, false, "", "") + '",';
            }
        }
        else {
            if (org.PrimaryAlarm) {
                updateJson = updateJson + '"8070": "FFFFFFFFFFFFFFFF000000000000000000000000000000",';
            }
        }
        if (config.SecondaryAlarm) {
            if (config.SecondaryAlarm != org.SecondaryAlarm
                || config.SecondaryAlarmUnit != org.SecondaryAlarmUnit
                || config.SecondaryAlarmValue != org.SecondaryAlarmValue) {
                updateJson = updateJson + '"8071": "' + setScheduleWindow(config.SecondaryAlarmUnit, config.SecondaryAlarmValue, false, "", "") + '",';
            }
        }
        else {
            if (org.SecondaryAlarm) {
                updateJson = updateJson + '"8071": "FFFFFFFFFFFFFFFF000000000000000000000000000000",';
            }
        }
        if (config.AnalogueAlarm) {
            if (config.AnalogueAlarm != org.AnalogueAlarm
                || config.AnalogueAlarmUnit != org.AnalogueAlarmUnit
                || config.AnalogueAlarmValue != org.AnalogueAlarmValue) {
                updateJson = updateJson + '"8074": "' + setScheduleWindow(config.AnalogueAlarmUnit, config.AnalogueAlarmValue, false, "", "") + '",';
            }
        }
        else {
            if (org.AnalogueAlarm) {
                updateJson = updateJson + '"8074": "FFFFFFFFFFFFFFFF000000000000000000000000000000",';
            }
        }
        // Data TX schedule
        if (config.DataTxSchedule) {
            if (config.DataTxTime != null) {
                if (moment(org.DataTxTime).toISOString() != moment(config.DataTxTime).toISOString()
                    || org.DataTxInterval != config.DataTxInterval) {
                    updateJson = updateJson + '"0063": "' + setSchedule(config.DataTxTime, config.DataTxInterval) + '",';
                }
            }
        }
        else {
            if (org.DataTxSchedule) {
                updateJson = updateJson + '"0063": "FFFFFFFFFFFFFFFF000000000000000000000000000000",';
            }
        }
        // SMS Tx schedule
        if (config.SmsTxSchedule) {
            if (config.SmsTxTime != null) {
                if (moment(org.SmsTxTime).toISOString() != moment(config.SmsTxTime).toISOString()
                    || org.SmsTxInterval != config.SmsTxInterval) {
                    updateJson = updateJson + '"0061": "' + setSchedule(config.SmsTxTime, config.SmsTxInterval) + '",';
                }
            }
        }
        else {
            if (org.SmsTxSchedule) {
                updateJson = updateJson + '"0061": "FFFFFFFFFFFFFFFF000000000000000000000000000000",';
            }
        }


        // Listen schedule
        if (config.ListenSchedule) {
            if (config.ListenTime != null && config.ListenSchedule) {
                if (moment(org.ListenTime).toISOString() != moment(config.ListenTime).toISOString()
                    || org.ListenInterval != config.ListenInterval) {
                    updateJson = updateJson + '"0062": "' + setSchedule(config.ListenTime, config.ListenInterval) + '",';
                }
            }
        }
        else {
            if (org.ListenSchedule) {
                updateJson = updateJson + '"0062": "FFFFFFFFFFFFFFFF000000000000000000000000000000",';
            }
        }


        //Communication
        if (config.PreferredRAT != org.PreferredRAT) {
            updateJson = updateJson + '"0803": "' + config.PreferredRAT + '",';
        }
        if (config.Preferred4G != org.Preferred4G) {
            updateJson = updateJson + '"0804": "' + config.Preferred4G + '",';
        }
        if (config.SimSetting != org.SimSetting) {
            simStore.byKey(parseInt(config.SimSetting))
                .then((sim: APISimDetail) => {
                    updateJson = updateJson + '"0040": "' + encodeString(sim.apn, 25) + '",';
                    updateJson = updateJson + '"0041": "' + encodeString(sim.username, 25) + '",';
                    updateJson = updateJson + '"0042": "' + encodeString(sim.password, 25) + '",';
                });
        }
        if (config.DataCheckServer != org.DataCheckServer) {
            gprsStore.byKey(parseInt(config.DataCheckServer))
                .then((server: APIGprsDetail) => {
                    updateJson = updateJson + '"0043": "' + encodeString(server.hostname, 25) + '",';
                    updateJson = updateJson + '"0044": "' + server.port.toString(16).padStart(4, '0') + '",';
                });
        }

        // Channels
        // Any change in channel enable update meterConfig
        if (config.FlowA != org.FlowA
            || config.FlowB != org.FlowB
            || config.AnalogueC != org.AnalogueC
            || config.AnalogueD != org.AnalogueD
            || config.AnalogueE != org.AnalogueE
        ) {
            updateJson = updateJson + '"0011": "' + config.MeterConfig.toString(16).padStart(4, '0') + '",';
        }

        // Channel A
        if (config.FlowA) {
            // FlowA now enabled 
            if (config.FlowARef != org.FlowARef) {
                updateJson = updateJson + '"0210": "' + encodeString(config.FlowARef, 16) + '",';
            }
            if (config.FlowAMultiplier != org.FlowAMultiplier) {
                updateJson = updateJson + '"0012": "' + config.FlowAMultiplier.substr(2, 2) + '",';
            }
            if (config.FlowAPrimaryReadings != org.FlowAPrimaryReadings
                || config.FlowASecondaryReadings != org.FlowASecondaryReadings
                || config.FlowAPrimaryDeDup != org.FlowAPrimaryDeDup
                || config.FlowASecondaryDeDup != org.FlowASecondaryDeDup
                || config.FlowAPrimaryMinLog != org.FlowAPrimaryMinLog
                || config.FlowASecondaryMinLog != org.FlowASecondaryMinLog
                || config.FlowAPrimaryEvents != org.FlowAPrimaryEvents
                || config.FlowASecondaryEvents != org.FlowASecondaryEvents
            ) {
                let val = 0;
                if (config.FlowAPrimaryReadings) {
                    val |= 0x01;
                }
                if (config.FlowASecondaryReadings) {
                    val |= 0x02;
                }
                if (config.FlowAPrimaryDeDup) {
                    val |= 0x10;
                }
                if (config.FlowASecondaryDeDup) {
                    val |= 0x20;
                }
                if (config.FlowAPrimaryMinLog) {
                    val |= 0x40;
                }
                if (config.FlowASecondaryMinLog) {
                    val |= 0x80;
                }
                if (config.FlowAPrimaryEvents) {
                    val |= 0x400;
                }
                if (config.FlowASecondaryEvents) {
                    val |= 0x800;
                }
                updateJson = updateJson + '"0214": "' + val.toString(16).padStart(4, '0') + '",';
            }
            if (config.FlowASetOnSend && config.FlowATotal) {
                updateJson = updateJson + '"1014": "' + config.FlowATotal.toString(16).padStart(8, '0') + '",';
            }
        }
        else {
            if (org.FlowA) {
                // FlowA now disabled
                updateJson = updateJson + '"0214": "0000",';
            }
        }
        // Channel B
        if (config.FlowB) {
            // FlowB now enabled 
            if (config.FlowBRef != org.FlowBRef) {
                updateJson = updateJson + '"0220": "' + encodeString(config.FlowBRef, 16) + '",';
            }
            if (config.FlowBMultiplier != org.FlowBMultiplier) {
                updateJson = updateJson + '"0013": "' + config.FlowBMultiplier.substr(2, 2) + '",';
            }
            if (config.FlowBPrimaryReadings != org.FlowBPrimaryReadings
                || config.FlowBSecondaryReadings != org.FlowBSecondaryReadings
                || config.FlowBPrimaryDeDup != org.FlowBPrimaryDeDup
                || config.FlowBSecondaryDeDup != org.FlowBSecondaryDeDup
                || config.FlowBPrimaryMinLog != org.FlowBPrimaryMinLog
                || config.FlowBSecondaryMinLog != org.FlowBSecondaryMinLog
                || config.FlowBPrimaryEvents != org.FlowBPrimaryEvents
                || config.FlowBSecondaryEvents != org.FlowBSecondaryEvents
            ) {
                let val = 0;
                if (config.FlowBPrimaryReadings) {
                    val |= 0x01;
                }
                if (config.FlowBSecondaryReadings) {
                    val |= 0x02;
                }
                if (config.FlowBPrimaryDeDup) {
                    val |= 0x10;
                }
                if (config.FlowBSecondaryDeDup) {
                    val |= 0x20;
                }
                if (config.FlowBPrimaryMinLog) {
                    val |= 0x40;
                }
                if (config.FlowBSecondaryMinLog) {
                    val |= 0x80;
                }
                if (config.FlowBPrimaryEvents) {
                    val |= 0x400;
                }
                if (config.FlowBSecondaryEvents) {
                    val |= 0x800;
                }
                updateJson = updateJson + '"0224": "' + val.toString(16).padStart(4, '0') + '",';
            }
            if (config.FlowBSetOnSend && config.FlowBTotal) {
                updateJson = updateJson + '"1016": "' + config.FlowBTotal.toString(16).padStart(8, '0') + '",';
            }
        }
        else {
            if (org.FlowB) {
                // FlowB now disabled
                updateJson = updateJson + '"0224": "0000",';
            }
        }
        // Channel C
        if (config.AnalogueC) {
            // AnalogueC now enabled
            if (config.AnalogueCRef != org.AnalogueCRef) {
                updateJson = updateJson + '"0230": "' + encodeString(config.AnalogueCRef, 16) + '",';
            }
            if (config.AnalogueCPrimaryReadings != org.AnalogueCPrimaryReadings
                || config.AnalogueCSecondaryReadings != org.AnalogueCSecondaryReadings
                || config.AnalogueCPrimaryMinLog != org.AnalogueCPrimaryMinLog
                || config.AnalogueCSecondaryMinLog != org.AnalogueCSecondaryMinLog
                || config.AnalogueCPrimaryEvents != org.AnalogueCPrimaryEvents
                || config.AnalogueCSecondaryEvents != org.AnalogueCSecondaryEvents
                || config.ModbusPower1 != org.ModbusPower1
            ) {
                let val = 0;

                if (config.AnalogueCPrimaryReadings) {
                    val |= 0x01;
                }
                if (config.AnalogueCSecondaryReadings) {
                    val |= 0x02;
                }
                if (config.AnalogueCPrimaryMinLog) {
                    val |= 0x40;
                }
                if (config.AnalogueCSecondaryMinLog) {
                    val |= 0x80;
                }
                if (config.AnalogueCPrimaryEvents) {
                    val |= 0x400;
                }
                if (config.AnalogueCSecondaryEvents) {
                    val |= 0x800;
                }
                if (config.ModbusPower1) {
                    val |= 0x100;
                }
                if (config.AnalogueCIntTransducerPower) {
                    val |= 0x200;
                }
                if (config.AnalogueCExtTransducerPower) {
                    val |= 0x100;
                }

                updateJson = updateJson + '"0234": "' + val.toString(16).padStart(4, '0') + '",';
            }


            if (config.AnalogueCUnits != org.AnalogueCUnits) {
                updateJson = updateJson + '"0233": "' + encodeString(config.AnalogueCUnits, 3) + '",';
                if (!config.ModbusOnly || config.Model !== '3N') {
                    // Adjust path for change of units
                    const path = [0xA4, 0x10, 0x24, (config.AnalogueCTransducerRange == 20 ? 0x31 : 0x30), 0x40, 0x50, (config.AnalogueCUnits == 'mwg' || config.AnalogueCUnits == 'psi' ? 0x70 : 0x60), (config.AnalogueCUnits == 'mwg' || config.AnalogueCUnits == 'psi' ? 0x60 : 0)];
                    updateJson = updateJson + '"0231": "' + hexArrayToString(path) + '",';
                    // Adjust DP for change of units
                    let dp = 0;
                    if (config.AnalogueCUnits == "mwg") {
                        dp = 2;
                        updateJson = updateJson + '"0370": "0127D527100000",';
                    }
                    else if (config.AnalogueCUnits == "psi") {
                        dp = 2;
                        updateJson = updateJson + '"0370": "0138A827100000",';
                    }
                    else if (config.AnalogueCUnits == "Bar") {
                        dp = 3;
                    }
                    updateJson = updateJson + '"0232": "' + dp.toString(16).padStart(2, '0') + '",';
                }
            }
            if (config.ModbusOnly && config.ModbusUpdated[0]) {
                // Modbus Ch1
                updateJson = updateJson + '"1404": "' + EncodeModbusConfig(config.ModbusConfig[0]) + '",';
                updateJson = updateJson + '"0231": "' + EncodeModbusPath(config.ModbusConfig[0]) + '",';
                if (config.AnalogueCDp) {
                    updateJson = updateJson + '"0232": "' + config.AnalogueCDp.toString(16).padStart(2, '0') + '",';
                }
            }
            if (config.ModbusOnly && !config.ModbusUpdated[0]) {
                if (config.Modbus1Offset != org.Modbus1Offset) {
                    updateJson = updateJson + '"0231": "' + EncodeModbusPath(config.ModbusConfig[0]) + '",';
                }
            }
            // AnalogueTransfer
            if (config.AnalogueCTransfer && config.AnalogueCTransfer.hasUpdated) {
                updateJson = updateJson + '"0360": "' + encodeAnalogueTransfer(config.AnalogueCTransfer, config.AnalogueCCurrent) + '",';
            }
            //offset
            console.log('new offset' + config.Modbus1Offset);
            console.log('old offset' + org.Modbus1Offset);
            console.log(config.AnalogueCDp)
            
            if (config.Modbus1Offset != org.Modbus1Offset) {
                const cDp = (config.AnalogueCDp != null ? config.AnalogueCDp : 0);
                updateJson = updateJson + '"0350": "' + encodeModbusOffset(config.Modbus1Offset,cDp ) + '",';
            }

        }
        else {
            if (org.AnalogueC) {
                // AnalogueC now disabled
                updateJson = updateJson + '"0234": "0000",';
            }
        }
        // Channel D
        if (config.AnalogueD) {
            // AnalogueD now enabled
            if (config.AnalogueDRef != org.AnalogueDRef) {
                updateJson = updateJson + '"0240": "' + encodeString(config.AnalogueDRef, 16) + '",';
            }
            if (config.AnalogueDPrimaryReadings != org.AnalogueDPrimaryReadings
                || config.AnalogueDSecondaryReadings != org.AnalogueDSecondaryReadings
                || config.AnalogueDPrimaryMinLog != org.AnalogueDPrimaryMinLog
                || config.AnalogueDSecondaryMinLog != org.AnalogueDSecondaryMinLog
                || config.AnalogueDPrimaryEvents != org.AnalogueDPrimaryEvents
                || config.AnalogueDSecondaryEvents != org.AnalogueDSecondaryEvents
                || config.ModbusPower2 != org.ModbusPower2
            ) {
                let val = 0;
                if (config.AnalogueDPrimaryReadings) {
                    val |= 0x01;
                }
                if (config.AnalogueDSecondaryReadings) {
                    val |= 0x02;
                }
                if (config.AnalogueDPrimaryMinLog) {
                    val |= 0x40;
                }
                if (config.AnalogueDSecondaryMinLog) {
                    val |= 0x80;
                }
                if (config.AnalogueDPrimaryEvents) {
                    val |= 0x400;
                }
                if (config.AnalogueDSecondaryEvents) {
                    val |= 0x800;
                }
                if (config.ModbusPower2) {
                    val |= 0x100;
                }
                if (config.AnalogueDIntTransducerPower) {
                    val |= 0x200;
                }
                if (config.AnalogueDExtTransducerPower) {
                    val |= 0x100;
                }

                updateJson = updateJson + '"0244": "' + val.toString(16).padStart(4, '0') + '",';
            }
            if (config.AnalogueDUnits != org.AnalogueDUnits) {
                updateJson = updateJson + '"0243": "' + encodeString(config.AnalogueDUnits, 3) + '",';
                if (!config.ModbusOnly) {
                    // Adjust path for change of units
                    const path = [0xA5, 0x10, 0x25, (config.AnalogueDTransducerRange == 20 ? 0x31 : 0x30), 0x41, 0x51, (config.AnalogueDUnits == 'mwg' || config.AnalogueDUnits == 'psi' ? 0x70 : 0x61), (config.AnalogueDUnits == 'mwg' || config.AnalogueDUnits == 'psi' ? 0x61 : 0)];
                    updateJson = updateJson + '"0241": "' + hexArrayToString(path) + '",';
                    // Adjust DP for change of units
                    let dp = 0;
                    if (config.AnalogueDUnits == "mwg") {
                        dp = 2;
                        updateJson = updateJson + '"0370": "0127D527100000",';
                    }
                    else if (config.AnalogueDUnits == "psi") {
                        dp = 2;
                        updateJson = updateJson + '"0370": "0138A827100000",';
                    }
                    else if (config.AnalogueDUnits == "Bar") {
                        dp = 3;
                    }
                    updateJson = updateJson + '"0242": "' + dp.toString(16).padStart(2, '0') + '",';
                }
            }
            if (config.ModbusOnly && config.ModbusUpdated[1]) {
                // Modbus Ch2
                updateJson = updateJson + '"1405": "' + EncodeModbusConfig(config.ModbusConfig[1]) + '",';
                updateJson = updateJson + '"0241": "' + EncodeModbusPath(config.ModbusConfig[1]) + '",';
                if (config.AnalogueDDp) {
                    updateJson = updateJson + '"0242": "' + config.AnalogueDDp.toString(16).padStart(2, '0') + '",';
                }
            }
            if (config.ModbusOnly && !config.ModbusUpdated[1]) {
                if (config.Modbus2Offset != org.Modbus2Offset) {
                    updateJson = updateJson + '"0241": "' + EncodeModbusPath(config.ModbusConfig[1]) + '",';
                }
            }
            // AnalogueTransfer
            if (config.AnalogueDTransfer && config.AnalogueDTransfer.hasUpdated) {
                updateJson = updateJson + '"0361": "' + encodeAnalogueTransfer(config.AnalogueDTransfer, config.AnalogueDCurrent) + '",';
            }
            //offset
            if (config.Modbus2Offset != org.Modbus2Offset) {
                const dDp = (config.AnalogueDDp != null ? config.AnalogueDDp : 0);
                updateJson = updateJson + '"0351": "' + encodeModbusOffset(config.Modbus2Offset, dDp) + '",';
               // updateJson = updateJson + '"0351": "' + encodeModbusOffset(config.Modbus2Offset, config.AnalogueDDp) + '",';
            }

        }
        else {
            if (org.AnalogueD) {
                // AnalogueD now disabled
                updateJson = updateJson + '"0244": "0000",';
            }
        }
        // Channel E
        if (config.AnalogueE) {
            // AnalogueE now enabled
            if (config.AnalogueERef != org.AnalogueERef) {
                updateJson = updateJson + '"0250": "' + encodeString(config.AnalogueERef, 16) + '",';
            }
            if (config.AnalogueEPrimaryReadings != org.AnalogueEPrimaryReadings
                || config.AnalogueESecondaryReadings != org.AnalogueESecondaryReadings
                || config.AnalogueEPrimaryMinLog != org.AnalogueEPrimaryMinLog
                || config.AnalogueESecondaryMinLog != org.AnalogueESecondaryMinLog
                || config.AnalogueEPrimaryEvents != org.AnalogueEPrimaryEvents
                || config.AnalogueESecondaryEvents != org.AnalogueESecondaryEvents
                || config.ModbusPower2 != org.ModbusPower2
            ) {
                let val = 0;
                if (config.AnalogueEPrimaryReadings) {
                    val |= 0x01;
                }
                if (config.AnalogueESecondaryReadings) {
                    val |= 0x02;
                }
                if (config.AnalogueEPrimaryMinLog) {
                    val |= 0x40;
                }
                if (config.AnalogueESecondaryMinLog) {
                    val |= 0x80;
                }
                if (config.AnalogueEPrimaryEvents) {
                    val |= 0x400;
                }
                if (config.AnalogueESecondaryEvents) {
                    val |= 0x800;
                }
                if (config.ModbusPower3) {
                    val |= 0x100;
                }
                if (config.AnalogueEIntTransducerPower) {
                    val |= 0x200;
                }
                if (config.AnalogueEExtTransducerPower) {
                    val |= 0x100;
                }

                updateJson = updateJson + '"0254": "' + val.toString(16).padStart(4, '0') + '",';
            }
            if (config.AnalogueEUnits != org.AnalogueEUnits) {
                updateJson = updateJson + '"0253": "' + encodeString(config.AnalogueEUnits, 3) + '",';
            }
            if (config.ModbusOnly && config.ModbusUpdated[2]) {
                // Modbus Ch1
                updateJson = updateJson + '"1406": "' + EncodeModbusConfig(config.ModbusConfig[2]) + '",';
                updateJson = updateJson + '"0251": "' + EncodeModbusPath(config.ModbusConfig[2]) + '",';
                if (config.AnalogueEDp) {
                    updateJson = updateJson + '"0252": "' + config.AnalogueEDp.toString(16).padStart(2, '0') + '",';
                }
            }
            if (config.ModbusOnly && !config.ModbusUpdated[2]) {
                if (config.Modbus3Offset != org.Modbus3Offset) {
                    updateJson = updateJson + '"0251": "' + EncodeModbusPath(config.ModbusConfig[2]) + '",';
                }
            }
            //offset
            if (config.Modbus3Offset != org.Modbus3Offset) {
                const eDp = (config.AnalogueEDp != null ? config.AnalogueEDp : 0);
                updateJson = updateJson + '"0352": "' + encodeModbusOffset(config.Modbus3Offset, eDp) + '",';
            }
        }
        else {
            if (org.AnalogueE) {
                // AnalogueE now disabled
                updateJson = updateJson + '"0254": "0000",';
            }
        }

        // Modbus Comms
        if (config.ModbusOnly) {
            if (config.ModbusBitrate != org.ModbusBitrate) {
                updateJson = updateJson + '"1400": "' + config.ModbusBitrate + '",';
            }
            if (config.ModbusDatabits != org.ModbusDatabits
                || config.ModbusParity != org.ModbusParity
            ) {
                let dataFormat = 0;
                if (config.ModbusDatabits == 8) {
                    dataFormat = 0x01;
                }
                switch (config.ModbusParity) {
                    case "Odd":
                        dataFormat = dataFormat | 0x02;
                        break;
                    case "Even":
                        dataFormat = dataFormat | 0x04;
                }
                updateJson = updateJson + '"1401": "' + dataFormat.toString(16).padStart(2, "0") + '",';
            }
            if (config.ModbusVoltage != org.ModbusVoltage) {
                updateJson = updateJson + '"002B": "' + config.ModbusVoltage + '",';
            }
            if (config.ModbusSettle != org.ModbusSettle) {
                updateJson = updateJson + '"0020": "' + (config.ModbusSettle * 10).toString(16).padStart(2, "0") + '",';
            }

        }

        // Alarms
        let resetAlarms = false;
        const msgParam = 0x00E0;
        const cfgParam = 0x1A0;
        for (let i = 0; i < 15; i++) {
            // Alarm Messages
            let strParam = (msgParam + i).toString(16).padStart(4, '0').toUpperCase();
            if (config.Alarms[i].Message != org.Alarms[i].Message) {
                updateJson = updateJson + '"' + strParam + '": "' + encodeString(config.Alarms[i].Message, 15) + '",';
            }
            // Alarm Config
            if (config.SWversion >= "040B") {
                // 4.11 and above have 13 byte alarm config
                strParam = (cfgParam + i).toString(16).padStart(4, '0').toUpperCase();
                const almConfig = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                if (JSON.stringify(config.Alarms[i]) != JSON.stringify(org.Alarms[i])) {

                    almConfig[8] = (config.Alarms[i].SetCount || 0) % 256;
                    almConfig[9] = (config.Alarms[i].MaxAlarms || 0) % 256;
                    almConfig[12] = (config.Alarms[i].ClearCount || 0) % 256;

                    let setOperator = 0;
                    let setInput = 0;
                    let clrOperator = 0;
                    let clrInput = 0;
                    let actions = 0;

                    switch (config.Alarms[i].SetOperator) {
                        case "Off":
                            setOperator = 0;
                            clrOperator = 0;
                            break;
                        case "EQ":
                            setOperator = 1;
                            clrOperator = 2;
                            break;
                        case "NE":
                            setOperator = 2;
                            clrOperator = 1;
                            break;
                        case "LT":
                            setOperator = 3;
                            clrOperator = 6;
                            break;
                        case "GT":
                            setOperator = 4;
                            clrOperator = 5;
                            break;
                        case "LE":
                            setOperator = 5;
                            clrOperator = 4;
                            break;
                        case "GE":
                            setOperator = 6;
                            clrOperator = 3;
                            break;
                        case "BT":
                            setOperator = 7;
                            clrOperator = 7;
                            break;
                    }
                    almConfig[5] = setOperator << 5;
                    almConfig[7] = clrOperator << 5;

                    switch (config.Alarms[i].Input) {
                        case "A":
                            setInput = 1;
                            clrInput = 1;
                            break;
                        case "B":
                            setInput = 2;
                            clrInput = 2;
                            break;
                        case "C":
                            setInput = 4;
                            clrInput = 4;
                            break;
                        case "D":
                            setInput = 8;
                            clrInput = 8;
                            break;
                        case "E":
                            setInput = 14;
                            clrInput = 14;
                            break;
                    }
                    almConfig[4] = setInput;
                    almConfig[6] = clrInput;

                    if (config.Alarms[i].SmsActionSetMessage) {
                        actions = actions | 0x0071;
                    }
                    if (config.Alarms[i].SmsActionSetFastline) {
                        actions = actions | 0x0032;
                    }
                    if (config.Alarms[i].SmsActionClrMessage) {
                        actions = actions | 0x00B4;
                    }
                    if (config.Alarms[i].SmsActionClrFastline) {
                        actions = actions | 0x0038;
                    }
                    if (config.Alarms[i].SmsActionSetSendUpdate) {
                        actions = actions | 0x1070;
                    }
                    if (config.Alarms[i].SmsActionClrSendUpdate) {
                        actions = actions | 0x20B0;
                    }
                    if (config.Alarms[i].SmsActionSetSendData) {
                        actions = actions | 0x0170;
                    }
                    if (config.Alarms[i].SmsActionClrSendData) {
                        actions = actions | 0x02B0;
                    }
                    if (config.Alarms[i].GprsActionSetConnect) {
                        actions = actions | 0x0430;
                    }
                    if (config.Alarms[i].GprsActionClrConnect) {
                        actions = actions | 0x0830;
                    }

                    almConfig[10] = Math.trunc(actions / 256);
                    almConfig[11] = actions % 256;

                    // Need to adjust thresholds for A & B channels
                    // using sample rate and multiplier to convert l/s into volume per reading period 
                    let setThreshold = config.Alarms[i].SetThreshold || 0;
                    let clearThreshold = config.Alarms[i].ClearThreshold || 0;
                    if (config.Alarms[i].Input == "A") {
                        const multiplier = decodeFlowMultiplier(parseInt(config.FlowAMultiplier, 16));
                        let sampleRate = config.PrimarySampleValue || 0;
                        if (config.PrimarySampleUnit == 'm') {
                            sampleRate = sampleRate * 60;
                        }
                        if (config.Alarms[i].SetThreshold != null) {
                            if (multiplier.units == "m3") {
                                // convert to m3
                                setThreshold = setThreshold / 1000;
                            }
                            setThreshold = valueFromFlowAlarmThreshold(setThreshold * sampleRate / multiplier.factor);
                        }
                        if (config.Alarms[i].ClearThreshold != null) {
                            if (multiplier.units == "m3") {
                                // convert to m3
                                clearThreshold = clearThreshold / 1000;
                            }
                            clearThreshold = valueFromFlowAlarmThreshold(clearThreshold * sampleRate / multiplier.factor);
                        }
                    }
                    if (config.Alarms[i].Input == "B") {
                        const multiplier = decodeFlowMultiplier(parseInt(config.FlowBMultiplier, 16));
                        let sampleRate = config.PrimarySampleValue || 0;
                        if (config.PrimarySampleUnit == 'm') {
                            sampleRate = sampleRate * 60;
                        }
                        if (config.Alarms[i].SetThreshold != null) {
                            if (multiplier.units == "m3") {
                                // convert to m3
                                setThreshold = setThreshold / 1000;
                            }
                            setThreshold = valueFromFlowAlarmThreshold(setThreshold * sampleRate / multiplier.factor);
                        }
                        if (config.Alarms[i].ClearThreshold != null) {
                            if (multiplier.units == "m3") {
                                // convert to m3
                                clearThreshold = clearThreshold / 1000;
                            }
                            clearThreshold = valueFromFlowAlarmThreshold(clearThreshold * sampleRate / multiplier.factor);
                        }
                    }
                    if (config.Alarms[i].Input == "C") {
                        const scaler = Math.pow(10, config.AnalogueCDp || 0);
                        setThreshold = (config.Alarms[i].SetThreshold || 0) * scaler;
                        clearThreshold = (config.Alarms[i].ClearThreshold || 0) * scaler;
                    }
                    if (config.Alarms[i].Input == "D") {
                        const scaler = Math.pow(10, config.AnalogueDDp || 0);
                        setThreshold = (config.Alarms[i].SetThreshold || 0) * scaler;
                        clearThreshold = (config.Alarms[i].ClearThreshold || 0) * scaler;
                    }
                    if (config.Alarms[i].Input == "E") {
                        const scaler = Math.pow(10, config.AnalogueEDp || 0);
                        setThreshold = (config.Alarms[i].SetThreshold || 0) * scaler;
                        clearThreshold = (config.Alarms[i].ClearThreshold || 0) * scaler;
                    }

                    almConfig[0] = Math.trunc(setThreshold / 256);
                    almConfig[1] = setThreshold % 256;
                    almConfig[2] = Math.trunc(clearThreshold / 256);
                    almConfig[3] = clearThreshold % 256;

                    updateJson = updateJson + '"' + strParam + '": "' + hexArrayToString(almConfig) + '",';
                    resetAlarms = true;
                }
            }
            else {
                // 4.10 and below have 11 byte alarm config
                strParam = (cfgParam + i).toString(16).padStart(4, '0').toUpperCase();
                const almConfig = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                if (JSON.stringify(config.Alarms[i]) != JSON.stringify(org.Alarms[i])) {

                    almConfig[6] = (config.Alarms[i].SetCount || 0) % 256;
                    almConfig[7] = (config.Alarms[i].MaxAlarms || 0) % 256;
                    almConfig[10] = (config.Alarms[i].ClearCount || 0) % 256;

                    let setOperator = 0;
                    let setInput = 0;
                    let clrOperator = 0;
                    let clrInput = 0;
                    let actions = 0;

                    switch (config.Alarms[i].SetOperator) {
                        case "Off":
                            setOperator = 0;
                            clrOperator = 0;
                            break;
                        case "EQ":
                            setOperator = 1;
                            clrOperator = 2;
                            break;
                        case "NE":
                            setOperator = 2;
                            clrOperator = 1;
                            break;
                        case "LT":
                            setOperator = 3;
                            clrOperator = 6;
                            break;
                        case "GT":
                            setOperator = 4;
                            clrOperator = 5;
                            break;
                        case "LE":
                            setOperator = 5;
                            clrOperator = 4;
                            break;
                        case "GE":
                            setOperator = 6;
                            clrOperator = 3;
                            break;
                        case "BT":
                            setOperator = 7;
                            clrOperator = 7;
                            break;
                    }
                    almConfig[4] = setOperator << 5;
                    almConfig[5] = clrOperator << 5;

                    switch (config.Alarms[i].Input) {
                        case "A":
                            setInput = 1;
                            clrInput = 1;
                            break;
                        case "B":
                            setInput = 2;
                            clrInput = 2;
                            break;
                        case "C":
                            setInput = 4;
                            clrInput = 4;
                            break;
                        case "D":
                            setInput = 8;
                            clrInput = 8;
                            break;
                        case "E":
                            setInput = 14;
                            clrInput = 14;
                            break;
                    }
                    almConfig[4] = setInput + almConfig[4];
                    almConfig[5] = clrInput + almConfig[5];

                    if (config.Alarms[i].SmsActionSetMessage) {
                        actions = actions | 0x0071;
                    }
                    if (config.Alarms[i].SmsActionSetFastline) {
                        actions = actions | 0x0032;
                    }
                    if (config.Alarms[i].SmsActionClrMessage) {
                        actions = actions | 0x00B4;
                    }
                    if (config.Alarms[i].SmsActionClrFastline) {
                        actions = actions | 0x0038;
                    }
                    if (config.Alarms[i].SmsActionSetSendUpdate) {
                        actions = actions | 0x1070;
                    }
                    if (config.Alarms[i].SmsActionClrSendUpdate) {
                        actions = actions | 0x20B0;
                    }
                    if (config.Alarms[i].SmsActionSetSendData) {
                        actions = actions | 0x0170;
                    }
                    if (config.Alarms[i].SmsActionClrSendData) {
                        actions = actions | 0x02B0;
                    }
                    if (config.Alarms[i].GprsActionSetConnect) {
                        actions = actions | 0x0430;
                    }
                    if (config.Alarms[i].GprsActionClrConnect) {
                        actions = actions | 0x0830;
                    }

                    almConfig[8] = Math.trunc(actions / 256);
                    almConfig[9] = actions % 256;

                    // Need to adjust thresholds for A & B channels
                    // using sample rate and multiplier to convert l/s into volume per reading period 
                    let setThreshold = config.Alarms[i].SetThreshold || 0;
                    let clearThreshold = config.Alarms[i].ClearThreshold || 0;
                    if (config.Alarms[i].Input == "A") {
                        const multiplier = decodeFlowMultiplier(parseInt(config.FlowAMultiplier, 16));
                        let sampleRate = config.PrimarySampleValue || 0;
                        if (config.PrimarySampleUnit == 'm') {
                            sampleRate = sampleRate * 60;
                        }
                        if (config.Alarms[i].SetThreshold != null) {
                            if (multiplier.units == "m3") {
                                // convert to m3
                                setThreshold = setThreshold / 1000;
                            }
                            setThreshold = setThreshold * sampleRate / multiplier.factor;
                        }
                        if (config.Alarms[i].ClearThreshold != null) {
                            if (multiplier.units == "m3") {
                                // convert to m3
                                clearThreshold = clearThreshold / 1000;
                            }
                            clearThreshold = clearThreshold * sampleRate / multiplier.factor;
                        }
                    }
                    if (config.Alarms[i].Input == "B") {
                        const multiplier = decodeFlowMultiplier(parseInt(config.FlowBMultiplier, 16));
                        let sampleRate = config.PrimarySampleValue || 0;
                        if (config.PrimarySampleUnit == 'm') {
                            sampleRate = sampleRate * 60;
                        }
                        if (config.Alarms[i].SetThreshold != null) {
                            if (multiplier.units == "m3") {
                                // convert to m3
                                setThreshold = setThreshold / 1000;
                            }
                            setThreshold = setThreshold * sampleRate / multiplier.factor;
                        }
                        if (config.Alarms[i].ClearThreshold != null) {
                            if (multiplier.units == "m3") {
                                // convert to m3
                                clearThreshold = clearThreshold / 1000;
                            }
                            clearThreshold = clearThreshold * sampleRate / multiplier.factor;
                        }
                    }
                    if (config.Alarms[i].Input == "C") {
                        const scaler = Math.pow(10, config.AnalogueCDp || 0);
                        setThreshold = (config.Alarms[i].SetThreshold || 0) * scaler;
                        clearThreshold = (config.Alarms[i].ClearThreshold || 0) * scaler;
                    }
                    if (config.Alarms[i].Input == "D") {
                        const scaler = Math.pow(10, config.AnalogueDDp || 0);
                        setThreshold = (config.Alarms[i].SetThreshold || 0) * scaler;
                        clearThreshold = (config.Alarms[i].ClearThreshold || 0) * scaler;
                    }
                    if (config.Alarms[i].Input == "E") {
                        const scaler = Math.pow(10, config.AnalogueEDp || 0);
                        setThreshold = (config.Alarms[i].SetThreshold || 0) * scaler;
                        clearThreshold = (config.Alarms[i].ClearThreshold || 0) * scaler;
                    }

                    // Ensure only integer values are converted to bytes
                    almConfig[0] = Math.trunc(setThreshold / 256);
                    almConfig[1] = Math.trunc(setThreshold % 256);
                    almConfig[2] = Math.trunc(clearThreshold / 256);
                    almConfig[3] = Math.trunc(clearThreshold % 256);

                    updateJson = updateJson + '"' + strParam + '": "' + hexArrayToString(almConfig) + '",';
                    resetAlarms = true;
                }
            }


        }   // end for each alarm slot

        if (resetAlarms) {
            updateJson = updateJson + '"1051": "",';
        }

        // Custom entries
        if (config.Custom != "") {
            const lines = config.Custom.split(/\r?\n/);
            lines.map((line: string) => {
                if (line.indexOf('=') == -1) {
                    // CMD with no value
                    if (line != '') {
                        updateJson = updateJson + '"' + line + '":"",';
                    }
                }
                else {
                    const entry = line.split("=");
                    if (entry[0] != "") {
                        updateJson = updateJson + '"' + entry[0] + '":"' + entry[1] + '",';
                    }
                }
            });
        }
    }

    // Remove trailing , before }
    if (updateJson != "{") {
        updateJson = updateJson.substr(0, updateJson.length - 1) + "}";
        return updateJson;
    }
    else {
        return "{}";
    }

}

 

