import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import process from 'process';
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AuthProvider } from "./auth/authProvider.jsx";
import AuthRoutes from "./auth/authRoutes.jsx";
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';
import { IntlProvider } from 'react-intl';
import enMessages from './locales/en.json';
import frMessages from './locales/fr.json';
import deMessages from './locales/de.json';
import esMessages from './locales/es.json';
import ptMessages from './locales/pt.json';
if (window.location.hostname.includes('zonelog.net')) {
    import('./assets/css/material-dashboard-reactZL.css?v=1.8.0');
} else {
    import('./assets/css/material-dashboard-react.css?v=1.8.0');
}

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';


const theme = createTheme({
    palette: {
        primary: {
            /*main: '#EED202', */
            main: window.location.hostname.includes('zonelog.net') ? '#ffc222' : '#E8d34d'
        },
        secondary: {
            /* main: '#00BF00' */
            main: window.location.hostname.includes('zonelog.net') ? '#00a18e' :'#32ad6d'

        },
        warning: {
            main: '#e8844d'
        },
        error: {
            main: '#ad3e32'
        },
        grey: {
            main: window.location.hostname.includes('zonelog.net') ? '#B0B7BC' : '#e0e0e0', 
        }
        
        
    },
    components: {
        MuiButton: {
            styleOverrides: {
                primary: {
                    backgroundColor: '#E8d34d',
                },
                secondary: {
                    borderColor: '#143C67',
                    color: '#ffffff',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                colorGrey: {
                    backgroundColor: window.location.hostname.includes('zonelog.net') ? '#B0B7BC' : '#e0e0e0',
                    color: '#000000',
                },
            },
        },

    },

    
    
});

config({ licenseKey }); 

//import LandingPage from "./views/LandingPage/LandingPage.js";
const hist = createBrowserHistory();


const messages = {
    'en-GB': enMessages,
    'fr-FR': frMessages,
    'de-DE': deMessages,
    'es-ES': esMessages,
    'pt-PT': ptMessages
};

const App = () => { 
    

    const [locale, setLocale] = useState(sessionStorage.getItem('language'));

    useEffect(() => {
        const handleStorageChange = () => {
            console.log('language')
            const newLocale = sessionStorage.getItem('language') || 'en-GB';
            setLocale(newLocale);
        };

        window.addEventListener('storage', handleStorageChange);
        console.log('listening')
        return () => {
            window.removeEventListener('storage', handleStorageChange);
            console.log('stopped listening')
        };
    }, []);

 
    return (
        
        <IntlProvider locale={locale} messages={messages[locale]}>
            <ThemeProvider theme={theme}>

                <AuthProvider>
                    <Router history={createBrowserHistory()} basename={"/"}>
                        <AuthRoutes />
                    </Router>
                </AuthProvider>
            </ThemeProvider>
        </IntlProvider>
    );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
