import React from 'react'
import TextField from '@mui/material/TextField';


interface Props {
    value: number;
    onChange: () => void
    name: string
    changeGroup: string | undefined
}
const CustomNumberInputChanger: React.FC<Props> = ({ value, onChange, name, changeGroup }) => {

    return (
        <div style={{ display: "flex", position: "relative" }}>
            <TextField
                size="small"
                style={{ width: "135px", background: "#ffffff" }}
                value={parseFloat(value.toFixed(2))}
                name={name}
                onChange={(e): void => { onChange(e.target.name, parseFloat(e.target.value), changeGroup)}}
            />
            <div style={{ position: 'absolute', display: "flex", flexDirection: "column", right: "5px", top: "3px", background: "transparent" }}>
                <i className="fa-solid fa-caret-up fa-xl" style={{ lineHeight: 0.75 }} onClick={(): void => onChange(name, value + 1, changeGroup)}></i>
                <i className="fa-solid fa-caret-down fa-xl" style={{ lineHeight: 0.70 }} onClick={(): void => onChange(name, value - 1, changeGroup)}></i>
            </div>
        </div>
    )
}
export default CustomNumberInputChanger