
import React from "react";
import { AuthContext } from "../../auth/authProvider.jsx";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import FormField from 'components/Fields/FormField';
import { ReactComponent as AshLogo } from 'assets/ashLogo.svg';
import { CreateUrl } from 'Utils/ApiHelper.js';
import { Redirect } from "react-router-dom";
import BG from '../../assets/BG-Aquaguard.jpg';
import { withStyles, createStyles } from '@mui/styles';
import ResetPassword from 'components/Users/ResetPassword.tsx';
import { CardFooter } from 'reactstrap';
import GutermannLogo from '../../assets/gutermann/gutermnn-full-brand-white.png';
import { injectIntl } from 'react-intl';
const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontSize: 60,
        fontWeight: "100",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
};
const style = createStyles(styles);

export class ForgotPassword extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        
        this.state = ({ userName: "", emailSent: false, emailError: false, doReset: props.location.search.startsWith('?confirm'), redirect: false });
    }

    usernameChanged = (event) => {
        this.setState({ userName: event.value });
    }

    sendResetPassword = () => {

        const me = this;
        this.setState({ emailSent: false, emailError: false });
        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
            body: JSON.stringify({
                userEmail: this.state.userName,
                portalUri: window.location.protocol + '//' + window.location.hostname + ":" + window.location.port,
            })
        };

        // Cannot use CallPostAPI as user not authenticated
        fetch(CreateUrl('/api/aquaguard/ForgotPassword'), requestOptions)
            .then(async response => {
                if (response.ok) {
                    me.setState({ emailSent: true });
                }
                else {
                    me.setState({ emailSent: true, emailError: true });
                }

            });
    }

    passwordReset = (newPassword) => {  // returns Promise<boolean>

        const params = this.props.location.search.split('&');

        const token = params[1].split('=')[1];
        const userId = params[2].split('=')[1];
        const userGuid = params[3].split('=')[1];

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                UserId: userId,
                UserGuid: userGuid,
                token: token,
                newPassword: newPassword,
            })
        };

        return new Promise(
            function (resolve) {
                fetch(CreateUrl('/api/aquaguard/ResetPassword'), requestOptions)
                    .then(async response => {
                        if (response.ok) {
                            resolve(true);
                        }
                        else {
                            resolve(false);
                        }
                    })

                    .catch(function (error) {
                        resolve(false);
                        console.log(error);
                    });
            }
        );
    }

    resetDone = () => {
        this.setState({ redirect: true });
    }

    render() {

        const { classes, intl } = this.props;

        // ensures that fullname contains at least two names separated with a space
        const validateUsername = (value) => {
            if (value.indexOf('@') == -1 || value.indexOf('.') == -1) throw new Error('Invalid Username/Email');
        };

        return (
            (!this.state.redirect ?
                (
                    <div style={{
                        backgroundImage: `url(${BG})`,
                        width: '100vw',
                        height: '100vh',
                        overflow: 'hidden',


                    }}>

                        <div className='some-page-wrapper'>
                            <div className='row'>

                                <div className='column' >
                                    <div className='blue-column'>
                                        <Card style={{
                                            height: '100vh',
                                            width: '40vw',
                                            background: 'linear-gradient(130deg, rgba(0, 0, 0, 0.7) 0%, rgb(20,60,103, 0.6) 70%',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: 'translate(-50%, -50%)',
                                            boxShadow: "2px 2px 10px black"

                                        }}>
                                            <CardHeader className={'center'}>
                                                {window.location.hostname.includes('zonelog.net') ? <img src={GutermannLogo} style={{ width: "70%", height: "70%", margin: "60px 0 20px 0" }} />
                                                    : <AshLogo />}
                                                <h3 className={classes.cardTitleWhite}>{window.location.hostname.includes('zonelog.net') ? "ZONELOG" : "AquaGuard"}</h3>
                                            </CardHeader>
                                    {!this.state.doReset ?
                                        <CardBody style={{ height: '330px' }}>
                                                    <form style={{marginTop: "100px"}} action="/" method="POST" noValidate>

                                                <div>
                                                    {/** Render the fullname form field passing the name validation fn **/}
                                                            <FormField type="text" fieldId="username" label={intl.formatMessage({ id: 'User Email' })} placeholder="" validator={validateUsername} onStateChanged={this.usernameChanged} required />
                                                </div>
                                                <Button
                                                            style={{
                                                                width: '40%',
                                                                position: 'absolute', left: '50%',
                                                                transform: 'translate(-50%, 50%)',

                                                            }}
                                                            variant="contained"
                                                            color="primary"
                                                    onClick={this.sendResetPassword}>
                                                            {intl.formatMessage({ id: 'Reset Password' })}
                                                    </Button>

                                            </form>
                                        </CardBody>
                                    :
                                        <CardBody style={{ height: '230px' }}>
                                            {/*<div className={classes.cardCategoryWhite}>*/}
                                            {/*    {intl.formatMessage({ id: 'Enter new password for' })} {this.state.userName}*/}
                                            {/*</div>*/}
                                                    <ResetPassword onSet={this.passwordReset} resetDone={this.resetDone} classes={classes} isForgot={true} />
                                        </CardBody>
                                    }
                                        {this.state.emailSent &&
                                            <CardFooter>
                                                {this.state.emailError ?
                                                <div className="error">
                                                    {intl.formatMessage({ id: 'There was a problem sending a password reset email.' })} <br />
                                                            {intl.formatMessage({ id: 'Please contact your system administrator.' })}
                                                    </div>
                                                :
                                                <div className={classes.cardCategoryWhite}>
                                                            {intl.formatMessage({ id: 'An email has been sent with link to reset your password.' })}
                                                    </div>
                                                }
                                            </CardFooter>
                                        }
                                        </Card>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )
                :
                (
                    <Redirect to={'/Login'} />
                )
            )
        )
    }
}

export default injectIntl(withStyles(style)(ForgotPassword));

