import React, { ChangeEvent, ReactNode } from "react";
import { Redirect } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

// @mui/material
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from "@mui/material/IconButton";
import { withStyles, createStyles } from '@mui/styles';
import { Container, Grid } from '@mui/material';
import MaterialButton from '@mui/material/Button';

// aquaguard
import { CreateUrl, CallGetAPI, CallPutAPI, CallPostAPI} from 'Utils/ApiHelper.js';
import { buildAPIGetLoggerSummaryModel } from "models/APIGetLoggerSummaryModel";
import ConnectionsPanel from "../../components/Connections/ConnectionsPanel";
import ReadingsPanel from "../../components/Readings/ReadingsPanel";
import MultiLoggerGraph from '../Groups/MultiLoggerGraph';
import MultiLoggerGraphV2 from '../Groups/MultiLoggerGraphV2'
import EventsPanel from '../../components/Events/EventsPanel';
import LCLSummary from "components/DeviceSummary/LCLSummary"
import { APIGetLoggerReadingsModel } from "models/APIGetLoggerReadingsModel";
import AlarmsPanel from "../../components/Alarms/AlarmsPanel";

// DevExtreme Components
import { Accordion, Item } from 'devextreme-react/accordion';
import { Popup } from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react/scroll-view';
import { Typography } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ImagesPanel from 'components/ImageUpload/ImagesPanel'
import MinimumNightFlow from 'components/MinimumNightFlow/MinimumNightFlow'
import UploadDialog from "../../components/ImageUpload/UploadDialog"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import zIndex from "@mui/material/styles/zIndex";
import ConfigurationPanel from "../../components/Connections/ConfigurationsPanel";
import Comments from "../../components/Comments/Comments";
const style = createStyles(styles as Record<string, any>);



interface ReadingsState {
    state: {
        loading: boolean;
        readingsData: Array<APIGetLoggerReadingsModel>;
    };
}

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    history: {
        goBack: () => void;
    };
    location: {
        state: {
            serial: string;
        };
        search: string;
    };
    mapKey: string;
}

interface State {
    redirect: boolean;
    redirectPath: string | undefined;
    redirectProps: string | undefined;
    startDate: Date;
    endDate: Date;
    loggerType: string;
    serial: string;
    modelId: string;
    isLoading: boolean;
    allowBack: boolean;
    loggerIds: number[];
    exportDialog: boolean;
    exportType: string;
    exportData: boolean[];
    exportEnable: boolean;
    showMLGPopUp: boolean;
    siteName: string;
    isDormant: boolean;
    txFreq: number;
    imageCount: number;
    uploadDialogOpen: boolean;
}

class NewLCLDeviceDetail extends React.Component<Props, State> {

    // REFs to the channel reading components for Data export
    ChannelMLGReadings: React.RefObject<ReadingsState>;

    timer: any = null;     // Check for all readings loaded ready for export

    getValueChangedHandler: (productIndex: any) => (e: any) => void;

    constructor(props: Readonly<Props>) {
        super(props);

        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - 7);

        this.state = {
            redirect: false,
            redirectPath: undefined,
            redirectProps: undefined,
            startDate: startDate,
            endDate: endDate,
            loggerType: '',
            serial: '',
            modelId: '',
            isLoading: true,
            allowBack: true,
            loggerIds: [],
            exportDialog: false,
            exportType: "XLSX",
            exportData: [false, false, false, false, false],
            exportEnable: false,
            showMLGPopUp: false,
            siteName: "",
            isDormant: false,
            txFreq: 24,
            imageCount: 0,
            uploadDialogOpen: false
        };

        this.showMLGPopUp = this.showMLGPopUp.bind(this)
        this.hideMLGPopUp = this.hideMLGPopUp.bind(this)        

        this.ChannelMLGReadings = React.createRef();
    }

    //called from componentDidMount or say a periodic refresh timer
    getDeviceDataFromAPI = () => {
        const me = this;
        return;
    }

    blankFunction = function () {
        return undefined;
    };

    showMLGPopUp(): void {
        this.setState({ showMLGPopUp: true });
    }

    hideMLGPopUp(): void {
        this.setState({ showMLGPopUp: false });
    }


    /*
    //https://supportcenter.devexpress.com/ticket/details/t967161/accordion-how-to-expand-all-items-on-initialized
    handleAccordionInitialized = (e: any) => {
        const selectedAccordionItems: any = [];

        const items = e.component.option('items');

        //existing selected items
        this.state.selectedAccordionItems.forEach(item => {
            selectedAccordionItems.push(item);
        });

        //always show top 2 items details / readings
        selectedAccordionItems.push(items[0]);
        selectedAccordionItems.push(items[1]);

        this.setState({ selectedAccordionItems: selectedAccordionItems });
    }
    */

    /*
    componentDidMount(): void {
        this.setState({ isLoading: true });
        this.getDeviceDataFromAPI();
    }
    */

    componentDidMount(): void {
        // Get logger serial - either from location.state (link from React page), or query string (?serial=)
        this.reloadData()
        
    }

    reloadData(): void {
        let serial = "";
        let allowBack = true;
        if (this.props.location.state) {
            serial = this.props.location.state.serial;
        }
        else {
            serial = (this.props.location.search.split('='))[1];
            allowBack = false;
        }

        this.setState({ serial: serial, allowBack: allowBack });

        //get detail on logger here
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/LoggerBySerial?serial=' + serial), {})
            .then(response => {
                const data = buildAPIGetLoggerSummaryModel(response);
                const txFreq = data.TransmissionFrequency == null ? 24 : data.TransmissionFrequency;
                const startDate = new Date();

                sessionStorage.setItem('selectedLoggers', (data.Id + 500000).toString())
                startDate.setDate(me.state.endDate.getDate() - 7);
                //alert(me.state.endDate);

                //alert(startDate);

                if (txFreq > 24) {
                    // If more than 24 reads/day set range to 1 day
                    //startDate.setDate(me.state.endDate.getDate() - 1); VERY BIZARRE THIS SEEMS TO SET STARTDATE TO 1 MONTH AGO NOT 1 DAY!!
                    startDate.setDate(startDate.getDate() + 6);

                    //alert(startDate);
                }

                CallGetAPI(CreateUrl('/api/aquaguard/ImageCount?chariotId=' + data.Id), {})
                    .then(data => {

                        me.setState(
                            {
                                imageCount: data,
                            })

                    })
                    .catch(function () {
                        me.setState(
                            {
                                imageCount: 0,
                            })
                    });

                me.setState({
                    loggerType: data.Model,
                    modelId: data.ModelId,
                    loggerIds: [data.Id + 500000],       // Chariots have Id offset by 500000 for LoggerDetailsByIdList
                    siteName: data.SiteNameUserFriendly + "(" + data.SiteName + ")",
                    isDormant: data.Dormant,
                    txFreq: txFreq,
                    startDate: startDate,
                });
                sessionStorage.setItem('selectedLoggers', (data.Id + 500000).toString())
            })
            .catch((error: Error) => {
                me.setState(
                    {
                        loggerType: 'unknown'
                    })
                console.log("Unauthorized!:{0}", error)
            });

        this.setState({ isLoading: false });
    }

    componentWillUnmount():void {
        sessionStorage.setItem('selectedLoggers', "")
    }

    handleUploadButtonClick(): void  {
        this.setState({ uploadDialogOpen: !this.state.uploadDialogOpen})
    }
 


    render(): ReactNode {
        const { classes, history, location } = this.props;


        return (!this.state.redirect) ?
            (<div >

                {this.state.isLoading &&
                    <div style={{
                        position: 'relative', left: '40%', top: '50%',
                    }}>
                        <ClipLoader
                            size={50}
                            color={"#123abc"}
                            loading={this.state.isLoading}
                        />
                    </div>
                }

                {!this.state.isLoading &&
                    <div>

                        <UploadDialog
                            show={this.state.uploadDialogOpen}
                            title="Upload Image"
                            siteId={0}
                            loggerId={this.state.loggerIds[0]}
                            onSubmit={(): void => {
                                this.setState({ uploadDialogOpen: false })
                                this.reloadData()

                            }}
                            onCancel={(): void => this.setState({ uploadDialogOpen: false })}
                        />
                        {/* GRID FORMATTING using MUI https://mui.com/material-ui/react-grid */}
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                            {true && //this.state.totalLCLoggers < 1 &&

                                <Card>
                                    <CardHeader color="primary" className="view view-cascade d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                        <div style={{ display: 'inline', height: '40px', width: '100%' }}>
                                            {this.state.allowBack && <IconButton
                                                style={{ display: 'inline', marginTop: -14 }}
                                                onClick={(): void => history.goBack()}
                                                size="large"><ArrowBackIcon style={{ fill: "white" }} /></IconButton>}
                                        <h4 style={{ display: 'inline' }} className={classes.cardTitleWhite}>{this.state.serial + " (Site : " + this.state.siteName + ")"}</h4>
                                        
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                    <Container
                                        style={{
                                            minWidth: "100%"
                                        }}
                                    >
                                        <div>
                                            {/*<h4><b>{this.state.serial}</b></h4>*/}
                                            <Accordion
                                                        collapsible={true}
                                                        multiple={true}
                                                 
                                            //selectedItems={this.state.selectedAccordionItems}
                                            //onInitialized={this.handleAccordionInitialized}
                                            >
                                                       
                                                        <Item title='Device Overview' >
                                                    <div>
                                                                <LCLSummary serial={parseInt(this.state.serial)} mapKey={this.props.mapKey} reloadData={this.reloadData } />
                                                    </div>
                                                </Item>
                                                <Item title='Images'>
                                                    <div>
                                                                {this.state.imageCount > 0 && <ImagesPanel loggerId={this.state.loggerIds[0]} chariotId={this.state.loggerIds[0]} />}
                                                                <MaterialButton
                                                                    variant="contained"
                                                                    onClick={():void => this.handleUploadButtonClick()}
                                                                >
                                                                    Upload
                                                                </MaterialButton>
                                                            </div>
                                                        </Item>
                                                        
                                                <Item title='Graph'>
                                                <div>


                                                    <Popup
                                                                    id="popupMLG"
                                                                    visible={this.state.showMLGPopUp}
                                                                    title={"Logger Serial " + this.state.serial}
                                                                    showTitle={true}
                                                                    showCloseButton={true}
                                                                    fullScreen={true}
                                                                    onHiding={this.hideMLGPopUp.bind(this)}
                                                                    closeOnOutsideClick={false}
                                                                    style={{zIndex: 1} }
                                                                >
                                                            <ScrollView
                                                                width='100%'
                                                                height='100%'
                                                                showScrollbar={'always'}
                                                            >
                                                                <div id="textBlock">
                                                                                <MultiLoggerGraphV2 ref={this.ChannelMLGReadings} showDates={true} startDate={this.state.startDate} endDate={this.state.endDate} location={{ state: { groupId: 0, loggers: this.state.loggerIds, showBackButton: false, site: null } }} history={{ goBack: this.blankFunction }} />

                                                                </div>
                                                            </ScrollView>
                                                    </Popup>

                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                    <div style={{width: "100%"} }>
                                                            <MaterialButton style={{float: "right", marginRight: "30px", width: "300px"}} color="secondary" variant="contained" onClick={this.showMLGPopUp}>Full Screen Graph</MaterialButton>
                                                                    </div>
                                                                    <div>

                                                                            <MultiLoggerGraphV2 ref={this.ChannelMLGReadings} showDates={true} startDate={this.state.startDate} endDate={this.state.endDate} location={{ state: { groupId: 0, loggers: this.state.loggerIds, showBackButton: false, site: null } }} history={{ goBack: this.blankFunction }} /> 
                                                                            
                                                            </div>       
                                                        {/* PREVIOUSLY JUST READINGS -> <ReadingsPanel serials={[this.state.serial]} />*/}
                                                    </div>
                                                </div>
                                                </Item>
                                                {/* <Item title='Minimum Night Flow' visible={true}>*/}
                                                {/*    <div>*/}
                                                {/*                <MinimumNightFlow serial={this.state.serial } />*/}
                                                {/*    </div>*/}
                                                        {/*</Item>*/}
                                                {/*<Item title="Comments">*/}
                                                {/*    <Comments lcLoggerId={this.state.loggerIds[0]} loggerId={null} siteId={null} />*/}
                                                {/*</Item>*/}
                                                <Item title='Readings' visible={false}>
                                                    <div>
                                                        <ReadingsPanel serials={[this.state.serial]} />
                                                    </div>
                                                </Item>
                                                <Item title='Alarms' visible={true}>
                                                    <div>
                                                        <AlarmsPanel serial={this.state.serial} />
                                                    </div>
                                                </Item>
                                                <Item title='Connections'>
                                                    <div>
                                                        <ConnectionsPanel serials={[this.state.serial]} />
                                                    </div>
                                                </Item>
                                                <Item title='Events'>
                                                    <div>
                                                        <EventsPanel serials={[this.state.serial]} />
                                                    </div>
                                                        </Item>
                                                        <Item title='Configuration Updates'>
                                                    <div>
                                                        <ConfigurationPanel serial={this.state.serial} />
                                                    </div>
                                                </Item>


                                            </Accordion>
                                        </div>
                                    </Container>

                                </CardBody>
                                </Card>

                                }
                            </Grid>
                        </Grid>
                    </div>
                }
            </div>)
            :
            (<Redirect push to={{
                pathname: this.state.redirectPath,
                state: this.state.redirectProps
            }} />);
    }
}
export default withStyles(style)(NewLCLDeviceDetail);