import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Chip, useTheme } from '@mui/material';
import deMessages from "devextreme/localization/messages/de.json";
import frMessages from "devextreme/localization/messages/fr.json";
import esMessages from "devextreme/localization/messages/es.json";
import ptMessages from "devextreme/localization/messages/pt.json";
import { locale, loadMessages } from "devextreme/localization";
import { injectIntl, FormattedMessage } from 'react-intl';
// DevExtreme
import { DataGrid, Column, Export, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import Button from '@mui/material/Button';
//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import dxDataGrid, { dxDataGridRowObject } from "devextreme/ui/data_grid";

import { isLCLLogger } from 'variables/chariotProductIds';
import { GutermannModelConverter } from "../../Utils/GutermannModelCoverter";

const styles: Record<string, any> = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};


const style = createStyles(styles);


interface DeviceData {
    id: number;
    serial: string;
    model: string;
    lastConnected: Date | null;
    latestBattery: number | null;
    siteName: string;
    siteNameUserFriendly: string;
    company: string;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        cardCategoryWhite: string;
    };
    history: {
        goBack: () => void;
    };
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    selectedLogger: number;
    devices: Array<DeviceData>;
    filterValue: Array<string | string[]>;
    tempDevice: DeviceData | undefined;
    anchorEl: EventTarget & HTMLButtonElement | null;
    redirect: boolean;
    redirectPath: string | undefined;
    redirectProps: { serial: string | undefined; deviceId: number | undefined } | undefined;
}

class LowBattery extends React.Component<Props, State> {

    gridRef: React.RefObject<DataGrid>;

    constructor(props: Props | Readonly<Props>) {
        super(props);
        // Ref to Datagrid to store Page, Sort & Filters
        this.gridRef = React.createRef();
        locale(sessionStorage.getItem('language'));
        loadMessages(frMessages);
        loadMessages(deMessages);
        loadMessages(esMessages);
        loadMessages(ptMessages);
        this.state = {
            tableHidden: false,
            visible: false,
            selectedLogger: 0,
            devices: [],
            loading: true,
            authorized: true,
            filterValue: [],
            tempDevice: undefined,
            anchorEl: null,
            redirect: false,
            redirectPath: undefined,
            redirectProps: undefined
        };

    }
    get dataGrid(): dxDataGrid | undefined {
        return this.gridRef.current?.instance;
    }


    componentDidMount(): void {
        const deviceData = new Array<DeviceData>();
 
        const me = this;


        CallGetAPI(CreateUrl('/api/Dashboard/lowbattery?companyid=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                if (data?.length > 0) {
                    // Copy the data records into deviceData, adding the clickEvent
                    deviceData.length = 0; //clear dummy data
                    for (let i = 0; i < data.length; i++) {

                        if (window.location.hostname.includes('zonelog.net')) {
                            data[i].model = GutermannModelConverter(data[i].model)
                        }
                        const serial = data[i].serial;
                        const id = parseInt(data[i].id);
                        const rec: DeviceData = {
                            id: id,
                            serial: serial,
                            model: data[i].model,
                            lastConnected: data[i].lastConnected ? adjustTime(moment(data[i].lastConnected).toDate()) : null,
                            latestBattery: data[i].latestBattery,
                            siteName: data[i].siteName,
                            siteNameUserFriendly: data[i].siteNameUserFriendly,
                            company: data[i].company

                        };
                        deviceData.push(rec);
                    }
                    console.log(deviceData)
                    me.setState(
                        {
                            devices: deviceData,
                            tableHidden: false,
                            loading: false
                        })
                }
                else {
                    me.setState(
                        {
                            devices: [],
                            tableHidden: false,
                            loading: false
                        })
                }
            })
            .catch(function () {
                me.setState(
                    {
                        authorized: false
                    })
            });
            
    }

    handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: dxDataGridRowObject): void {   //React.MouseEvent<HTMLButtonElement, MouseEvent>
        const device = this.state.devices.find((row) => row.id === id.key);

        this.setState(
            {
                tempDevice: device,
                anchorEl: event.currentTarget
            });
    }


    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }



    //-------------------------

    //save changes, for both edit and new

    deviceDetailsClick = (): void => {

        this.setState(
            {
                redirect: true,
                //redirectPath: this.state.tempDevice?.model != "LCL" ? '/portal/DeviceDetail' : '/portal/LCLDeviceDetail',
                redirectPath: !isLCLLogger(this.state.tempDevice?.model) ? '/portal/DeviceDetail' : '/portal/LCLDeviceDetail',
                redirectProps: {
                    serial: this.state.tempDevice?.serial,
                    deviceId: this.state.tempDevice?.id
                }
            });
    }

    deviceOverviewClick = (serial: number | null, model: string | null): void => {
        this.setState(
            {
                redirect: true,
                //redirectPath: this.state.tempDevice?.model != "LCL" ? '/portal/DeviceDetail' : '/portal/LCLDeviceDetail',
                redirectPath: !isLCLLogger(model ? model : this.state.tempDevice?.model) ? '/portal/DeviceDetail' : '/portal/LCLDeviceDetail',
                redirectProps: {
                    serial: typeof (serial) === 'number' ? serial : this.state.tempDevice?.serial,
                    mapKey: this.props.mapKey
                }
            });
    }



    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm:ss");
    }

    onExporting(e: any): void {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('UpdatesPending');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), window.location.hostname.includes('zonelog.net') ? 'ZONELOG Export.xlsx':'Aquaguard Export.xlsx');
                });
        });
        e.cancel = true;
    }

    moreRender(key: dxDataGridRowObject): ReactNode {
        return (
            <IconButton size="small" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => { this.handleClick(e, key); }}><MoreHorizIcon /></IconButton>
        );
    }

    serialColumnTemplate(item: any): ReactNode {
        const theme = useTheme();

        if (item == null) return <div></div>;
        else {
            return <div style={{ float: "right" }}>
                <Chip
                    label={item.data.serial}
                    sx={{ backgroundColor: theme.palette.grey.main, color: theme.palette.grey.contrastText }}
                    onClick={(): void => this.deviceOverviewClick(item.data.serial, item.data.model)}
                />
            </div>
        }
    }

    buttonRender(item): ReactNode {
        return <Button variant="contained" color="secondary" onClick={(): void => this.deviceOverviewClick(item.data.serial, item.data.model)}>{this.props?.intl.formatMessage({ id: 'Overview' })}</Button>
    }

    render(): ReactNode {
        const { classes, intl } = this.props;

        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                 (!this.state.redirect) ?
                (<div>
                    {this.state.loading &&
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <ClipLoader
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                    }
                    {!this.state.loading &&
                        <div>

                            {this.state.tableHidden &&

                                    <ApiFailed />
                            }
                            {!this.state.tableHidden &&
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardHeader color="primary">
                                            <div style={{marginLeft: "7px" }}>
                                                {/*
                                                <IconButton
                                                    style={{ display: 'inline' }}
                                                    onClick={(): void => this.props.history.goBack()}
                                                    size="large"><ArrowBackIcon style={{ fill: "white" }} /></IconButton>
                                                */}
                                                <h4 style={{ display: 'inline' }} className={classes.cardTitleWhite}>Low Battery</h4>
                                                <p className={classes.cardCategoryWhite}>
                                                    {intl.formatMessage({ id: 'Devices where Battery voltage has been less than 3.2v, in the last 24 hours' })}
                                                </p>
                                            </div>
                                            </CardHeader>
                                            <CardBody>
                                                <DataGrid
                                                    dataSource={this.state.devices}
                                                    keyExpr="id"
                                                    ref={this.gridRef}
                                                    defaultFilterValue={this.state.filterValue}
                                                    onExporting={this.onExporting}
                                                    allowColumnResizing={true}
                                                    allowColumnReordering={true}
                                                    >
                                                <StateStoring enabled={true} type="localStorage" storageKey="updateListGrid" />
                                                <Export enabled={true} />
                                                <GroupPanel visible={true} />
                                                <Grouping autoExpandAll={true} />
                                                <FilterRow visible={true} />
                                                <Column dataField="id" visible={false} dataType="number" />
                                                <Column caption="Company" dataField="company" dataType="string" groupIndex={0} />
                                                <Column dataField="serial" dataType="string" caption={intl.formatMessage({ id: 'Serial' })} cellRender={this.serialColumnTemplate.bind(this)} />
                                                <Column dataField="siteNameUserFriendly" caption={intl.formatMessage({ id: "Site Name" })} />
                                                <Column dataField="siteName" caption={intl.formatMessage({ id: "Site Ref" })} />
                                                <Column caption={intl.formatMessage({ id: 'Latest Battery' })} dataField="latestBattery" dataType="number" />
                                                <Column caption={intl.formatMessage({ id: 'Model' })} dataField="model" dataType="string" />
                                                <Column caption={intl.formatMessage({ id: 'Last Connected' })} dataField="lastConnected" dataType="datetime" customizeText={this.dateColumnCustomizeText} sortIndex="1" sortOrder={'Desc'} />
                                                {/*<Column type="buttons" cellRender={this.moreRender.bind(this)} fixed={true} fixedPosition="right" />*/}
                                                <Column type="buttons" cellRender={this.buttonRender.bind(this)} fixed={true} />
                                                <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                <Paging defaultPageSize={10} />
                                                </DataGrid>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            }
                        </div>
                    }
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                        >
                        {!isLCLLogger(this.state.tempDevice?.model) &&
                            <MenuItem onClick={this.deviceOverviewClick}>Overview</MenuItem>
                        }
                        <MenuItem onClick={this.deviceDetailsClick}>Details</MenuItem>
                    </Menu>
                </div>)
                :
                 (<Redirect push to={{
                    pathname: this.state.redirectPath,
                    state: this.state.redirectProps
                }}/>)
            )
            :
            (
                <Redirect to={'/Login'} />
            );
    }
}

export default injectIntl(withStyles(style)(LowBattery))

