/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";

import Badge from '@mui/material/Badge';
import { AuthContext } from "../../auth/authProvider.jsx";

import WavesIcon from '@mui/icons-material/Waves';
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

import VersionBox from 'components/Version/VersionBox';
import zIndex from '@mui/material/styles/zIndex';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';
const useStyles = makeStyles(styles);



export default function Sidebar(props) {
    const authContext = useContext(AuthContext);

    useEffect(() => {
        authContext.getUser()
            .then(function (user) {
                if (sessionStorage.getItem('userLevel') === 'useradmin'
                    && window.location.hostname.includes('zonelog')
                    && sessionStorage.getItem('companyId') === '57') {
                    setIsGutermannAdmin(true)
                    }
            setUser(user);
            setUserLevel(window.sessionStorage.getItem('userLevel'));
            
        });
       
    }, []);

    const [user, setUser] = useState(null);
    const [userLevel, setUserLevel] = useState("restricted");
    const [isGutermannAdmin, setIsGutermannAdmin] = useState(false)
    const classes = useStyles();
    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    }
    const { color, logo, image, logoText, routes } = props;
    var links = (
        <List className={classes.list}>
            {routes.map((prop, key) => {
                var activePro = " ";
                var listItemClasses;

                listItemClasses = classNames({
                    [" " + classes[color]]: activeRoute(prop.layout + prop.path)
                });

                const blackFontClasses = classNames({
                    [" " + classes.blackFont]: activeRoute(prop.layout + prop.path)
                });
                if (user) {
                    if (isGutermannAdmin || userLevel === 'identityadmin') {
                        if (prop.visible && prop.roles.includes(userLevel)) {
                            return (
                                <NavLink
                                    to={prop.layout + prop.path}
                                    className={activePro + classes.item}
                                    activeClassName="active"
                                    key={key}
                                    onClick={() => props.handleDrawerToggle()}
                                >
                                    <ListItem button className={classes.itemLink + listItemClasses}>
                                        {typeof prop.icon === "string" ? (
                                            <Icon
                                                className={classNames(classes.itemIcon, blackFontClasses, {
                                                    [classes.itemIconRTL]: props.rtlActive
                                                })}
                                            >

                                                <Badge badgeContent={4} color="error">
                                                    {console.log('thisone1') }
                                                    {prop.icon}
                                                </Badge>

                                            </Icon>
                                        ) : (
                                            <Badge badgeContent={prop.alerts} color="error" className={classNames(classes.itemIcon, blackFontClasses, {
                                                [classes.itemIconRTL]: props.rtlActive
                                            })}>
                                                <prop.icon />
                                            </Badge>
                                        )}
                                        <ListItemText
                                            primary={props.rtlActive ? prop.rtlName : <FormattedMessage id={prop.name} />}
                                            className={classNames(classes.itemText, blackFontClasses, {
                                                [classes.itemTextRTL]: props.rtlActive
                                            })}
                                            disableTypography={true}
                                        />
                                    </ListItem>
                                </NavLink>
                            )
                        } else {
                            return null;
                        }
                    } else {
                        if (prop.visible && prop.roles.includes(userLevel) && prop.path !== '/Companies') {
                            return (
                                <NavLink
                                    to={prop.layout + prop.path}
                                    className={activePro + classes.item}
                                    activeClassName="active"
                                    key={key}
                                    onClick={() => props.handleDrawerToggle()}
                                >
                                    <ListItem button className={classes.itemLink + listItemClasses}>
                                        {typeof prop.icon === "string" ? (
                                            <Icon
                                                className={classNames(classes.itemIcon, blackFontClasses, {
                                                    [classes.itemIconRTL]: props.rtlActive
                                                })}
                                            >
                                                {console.log('hithere 1') }
                                                <Badge badgeContent={4} color="error">
                                                    {prop.icon}
                                                </Badge>

                                            </Icon>
                                        ) : (
                                            <Badge badgeContent={prop.alerts} color="error" className={classNames(classes.itemIcon, blackFontClasses, {
                                                [classes.itemIconRTL]: props.rtlActive
                                            })}>
                                                    <prop.icon />
                                                
                                            </Badge>
                                        )}
                                        <ListItemText
                                            primary={props.rtlActive ? prop.rtlName : <FormattedMessage id={prop.name} />}
                                            className={classNames(classes.itemText, blackFontClasses, {
                                                [classes.itemTextRTL]: props.rtlActive
                                            })}
                                            disableTypography={true}
                                        />
                                    </ListItem>
                                </NavLink>
                            )
                        } else {
                            return null;
                        }
                    }
                } else {
                    return null;
                }   
            })}
        </List>
    );
    var brand = (
        <div className={classes.logo} >
            <a style={{ color: "white"}} className={classNames(classes.logoLink, {
                    [classes.logoLinkRTL]: props.rtlActive
                })}
                target="_blank"
            >
                <div className={classes.logoImage}>
                    <WavesIcon className={classes.img}/>
                </div>
                {logoText}
            </a>
        </div>


    );


    var closeMenu = (
        <div className={classes.logo} >
            <IconButton
                aria-label="delete"
                onClick={() => props.handleDrawerToggle()}
                size="large"
                style={{outline: "none"}}>
                <MenuIcon style={{ fill: 'white' }} />
            </IconButton>
        </div>


    );

    return (
        <div>
            <Drawer sx={{ display: {md: 'none', xs: 'block'}}}
                variant="temporary"
                anchor={props.rtlActive ? "left" : "right"}
                open={props.open}
                classes={{
                    paper: classNames(classes.drawerPaper, {
                        [classes.drawerPaperRTL]: props.rtlActive
                    })
                }}
                onClose={props.handleDrawerToggle}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                onClick={props.handleDrawerToggle}
                >
                {brand}
                <div className={classes.sidebarWrapper}>
                    {links}
                </div>
                {image !== undefined ? (
                    <div
                        className={classes.background}
                    />
                ) : null}
                <div style={{position: "relative", top: "-85px", zIndex: 5}}>
                    <VersionBox />
                </div>
            </Drawer>

            <Drawer sx={{ display: { md: 'block', xs: 'none' }}}
                anchor={props.rtlActive ? "right" : "left"}
                variant="temporary"
                open
                onClose={() => props.handleDrawerToggle()}
                classes={{
                    paper: classNames(classes.drawerPaper, {
                        [classes.drawerPaperRTL]: props.rtlActive
                    })
                }}
            >
                {closeMenu}
                <div className={classes.sidebarWrapper}>{links}</div>
                {console.log('hit') }
                {image !== undefined ? (
                    <div
                        className={classes.background}
                            
                    />
                ) : null}
                <VersionBox />
            </Drawer>
        </div>
    );
}

Sidebar.propTypes = {
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
    logo: PropTypes.string,
    image: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool
};
