import React, { useState, useEffect, useRef } from 'react'
import { CreateUrl, CallGetAPI, CallPostAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import { DataGrid, Column, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring, Format, Export, HeaderFilter } from 'devextreme-react/data-grid';
import { APILoggerDetail, buildAPILoggerDetail } from 'models/APILoggerDetail';

import { Redirect } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Menu, Chip, useTheme } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button';
import UploadDialog from '../../components/ImageUpload/UploadDialog';
import SiteModal from './NewSiteModal2024'
import Tabs from 'devextreme-react/tabs'
import Loggers from "@mui/icons-material/SettingsRemote";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Tooltip from '@mui/material/Tooltip';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded';
import GoogleMapsMultiPolygon from '../../components/Map/NewGoogleMaps2024MultiPolygon';
import { GutermannModelConverter } from "../../Utils/GutermannModelCoverter";
import CHF from "assets/CHF.png";
import CHF1P from "assets/CHF1P.png";
import CHF2P from "assets/CHF2P.png";
import ZL from "assets/ZoneLog_Logger.png"
import yellowMarker from '../../assets/mapLocationMarkers/pinYellow.png';
import redMarker from '../../assets/mapLocationMarkers/pinRed.png';
import { isLCLLogger } from 'variables/chariotProductIds';
import ClipLoader from "react-spinners/ClipLoader";
interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
    };
    history: {
        goBack: () => void;
    };
    location: {
        state: {
            serial: number | null
        };

    };
}



interface Site {
    id: number;
    siteNameUserFriendly: string;
    siteName: string;
    created: Date;
    deleted: Date | null;
    Latitude: number | null;
    Longitude: number | null;
    //devices: Array<Device>;
    groups: Array<{ id: number; displayName: string }>;
    defaultSite: boolean;
    fkCompanyId: number;
    fkEASiteId: number | null;
    EASite: string;
    lastUpdate: Date | null;
    alarmState: number | null;
    alarmsEnabled: number | null;
    Loggers: Array<APILoggerDetail>;
    LastReading?: Date | null;
    HasActiveLoggers?: string;

}

interface RedirectProps {
    siteName: string;
    siteId: number | null;
    serial: string;
    deviceId: number | null;
}

interface GPS {
    position: { lat: number, lng: number }
    icon: string
    serial: number
    id: number
    tooltip: string
    siteId: number


}

interface Coordinates {
    lat: number
    lng: number
}


interface Boundary {
    site: number
    path: Array<Coordinates>
    styling: {
        strokeWeight: number
        strokeColor: string
        areaColor: string
        transparency: number
    }
}

interface Offset {
    x: number
    y: number
}

interface Company {
    name: string
    id: number
    isParent: boolean
}





const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);


const Sites: React.FC<Props> = ({ classes, history, location }) => {


    const defaultCenter = window.location.hostname.includes("zonelog.net")
        ? { lat: 47.17442830752493, lng: 8.517189402835617 }
        : { lat: 50.744094943532446, lng: -3.99597602646894 };




    const [authorized, setAuthorized] = useState<boolean>(true)
    const [activeSites, setActiveSites] = useState<Array<Site>>([])
    const [inactiveSites, setInactiveSites] = useState<Array<Site>>([])
    const [redirect, setRedirect] = useState<boolean>(false)
    const [redirectPath, setRedirectPath] = useState<string>("")
    const [redirectProps, setRedirectProps] = useState<RedirectProps>({
        siteName: "",
        siteId: null,
        serial: "",
        deviceId: null
    })
    const [anchorEl, setAnchorEl] = useState<HTMLElement>()
    const [tempSite, setTempSite] = useState<Site>({
        siteName: "",
        siteNameUserFriendly: "",
        loggers: [],
        latitude: 0,
        longitude: 0,
        createdDate: undefined,
        fkCompanyId: parseInt(sessionStorage.getItem('companyId') || '0'),
        defaultSite: false,
        fkEASiteId: null,
    })
    const [imageDialog, setImageDialog] = useState(false)
    const [modal, setModal] = useState(false)
    const [modalTitle, setModalTitle] = useState<string>("")
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [loggersToBeAssigned, setLoggersToBeAssigned] = useState<Array<APILoggerDetail>>([])
    const [editSite, setEditSite] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [loggersToBeUnassigned, setLoggersToBeUnassigned] = useState<Array>([])
    const [markers, setMarkers] = useState<Array<GPS>>([])
    const [boundaries, setBoundaries] = useState<Array<Boundary>>([])
    const activeGridRef = useRef(null)
    const inactiveGridRef = useRef(null)
    const mapRef = useRef(null)
    const anchorElRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const [hoveredBoundary, setHoveredBoundary] = useState<Site>({})
    const [tooltipHovered, setTooltipHovered] = useState<boolean>(false)
    const [mouseOverBoundary, setMouseOverBoundary] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [companies, setCompanies] = useState<Array<Company>>([])



    const reloadData = (): void => {
        setLoading(true)
        setBoundaries([])
            CallGetAPI(CreateUrl('/api/aquaguard/SitesForCompany?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
                .then(data => {
                    CallGetAPI(CreateUrl(`/api/aquaguard/GetCompanyAndChildren?companyGUID=${sessionStorage.getItem('companyGuid')}`), {})
                        .then(companyData => {
                            const companiesTemp = [...companies]
                            companyData.forEach(company => companiesTemp.push({ name: company.name, id: company.id, isParent: company.parentId ? false : true }))
                            companiesTemp.sort(function (x, y) {
                                return Number(y.isParent) - Number(x.isParent)
                            })
                            if (data.length > 0) {
                                const preppedData = data.map(site => {
                                    if (site.siteName) {
                                        site.siteName = `${site.siteName.charAt(0).toUpperCase()}${site.siteName.slice(1)}`
                                        return site
                                    } else return site
                                })

                                preppedData.sort((a, b) => {
                                    return a.siteName < b.siteName ? -1 : 1
                                })
                                const activeSiteData = []
                                const inActiveSiteData = []
                                const unAssignedLoggers = []
                                const markersTemp = []
                                const boundariesTemp: Boundary = []
                                preppedData.forEach(site => {
                                
                                    if (site.loggers?.length === 0 && site.siteName !== 'Unassigned') {
                                    
                                        if (site.boundary) {
                                            const boundaryInfo = JSON.parse(site.boundary)
                                            if (Array.isArray(boundaryInfo.path)) {
                                                boundariesTemp.push({
                                                    site: site.siteId,
                                                    path: boundaryInfo.path,
                                                    styling: {
                                                        strokeWeight: boundaryInfo.styling.strokeWeight,
                                                        strokeColor: boundaryInfo.styling.strokeColor,
                                                        areaColor: boundaryInfo.styling.areaColor,
                                                        transparency: boundaryInfo.styling.transparency
                                                    }
                                                })
                                            }
                                        }
                                        inActiveSiteData.push(site)
                                    } else if (site.defaultSite) {

                                        if (site.loggers.length > 0) {
                                            site.loggers.forEach(logger => {
                                                logger.companyName = site.companyName
                                                if (logger.dormant === false) {
                                                    unAssignedLoggers.push(logger)
                                                }
                                            })
                                        }
                                        unAssignedLoggers.sort((a, b) => a.deploymentDate + b.deploymentDate)
                                    }
                                    else {
                                    
                                        site.isParent = site.isParent ? 'Yes' : 'No'

                                        activeSiteData.push(site)
                                        if (site.boundary) {
                                            const boundaryInfo = JSON.parse(site.boundary)
                                            if (Array.isArray(boundaryInfo.path)) {
                                                boundariesTemp.push({
                                                    site: site.siteId,
                                                    path: boundaryInfo.path,
                                                    styling: {
                                                        strokeWeight: boundaryInfo.styling.strokeWeight,
                                                        strokeColor: boundaryInfo.styling.strokeColor,
                                                        areaColor: boundaryInfo.styling.areaColor,
                                                        transparency: boundaryInfo.styling.transparency
                                                    }
                                                })
                                            }
                                        }
                                        site.loggers.map(logger => {
                                            let imgSrc = ""
                                            if (logger.model) {
                                                if (logger.model.slice(0, 2) === 'CH') {
                                                    imgSrc = CHF
                                                    if (logger.model.includes('2P')) {
                                                        imgSrc = CHF2P
                                                    } else if (logger.model.includes('1P')) {
                                                        imgSrc = CHF1P
                                                    }
                                                }
                                            }
                                            markersTemp.push({
                                                position: logger.latitude && logger.longitude ? { lat: logger.latitude, lng: logger.longitude } : { lat: defaultCenter.lat, lng: defaultCenter.lng },
                                                key: logger.loggerId,
                                                serial: logger.serial,
                                                tooltip: {
                                                    model: window.location.hostname.includes('zonelog.net') ? GutermannModelConverter(logger.model) : logger.model,
                                                    img: window.location.hostname.includes('zonelog.net') ? ZL : imgSrc
                                                },
                                                icon: logger.latitude && logger.longitude ? yellowMarker : redMarker,
                                                siteId: site.siteId
                                            })
                                        })
                                    }
                                    setLoading(false)
                                })

                                setCompanies(companiesTemp)
                                setBoundaries(boundariesTemp)
                                setMarkers(markersTemp)
                                setActiveSites(activeSiteData)
                                setInactiveSites(inActiveSiteData)
                                setLoggersToBeAssigned(unAssignedLoggers)

                            }
                        })
                        .catch(err => {
                            console.log(err)
                            setAuthorized(false)
                        })
            })
            .catch(err => {
                console.log(err)
                setAuthorized(false)
            })
    }


    useEffect(() => {
        reloadData()
    }, [])

    const handleDeviceRender = ({ data }) => {
        return data.loggers.length
        /*if (data.loggers.length === 1) {
            return <Chip label={data.loggers[0].serial} color="primary"/>
        } else {
            return <div>
                {data.loggers.map(logger => <Chip key={logger.serial} label={logger.serial} color="primary" style={{marginRight: "10px"} } />)}
            </div>
        
        }*/
    }

    const createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    const createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };


    const deviceDetailsClick = (serial: string, id: number): void => {
        if (id < 50000) {

            setRedirect(true)
            setRedirectPath('/portal/DeviceOverview')
            setRedirectProps({
                serial: serial,
                deviceId: id,
                siteId: null,
                siteName: ''
            })               
        }
        else {
            setRedirect(true)
            setRedirectPath('/portal/LCLDeviceDetail')
            setRedirectProps({
                serial: serial,
                deviceId: id,
                siteId: null,
                siteName: ''
            })             
        }
    }

    const moreRender = (site: any): React.ReactNode => {
        return (
        <div style={{display: "flex", justifyContent: "center"} }>
            <IconButton size="small" onClick={(e): void => { handleClick(e, site) }}><MoreHorizIcon /></IconButton>
        </div>)
    }

    const handleClick = (event: any, site: Site): void => {
            setAnchorEl(event.currentTarget)
            setTempSite(site)
    }

    const handleClose = () => {
        setAnchorEl(null);
        setTempSite({
            siteName: "",
            siteNameUserFriendly: "",
            loggers: [],
            latitude: 0,
            longitude: 0,
            createdDate: undefined,
            fkCompanyId: parseInt(sessionStorage.getItem('companyId') || '0'),
            defaultSite: false,
            fkEASiteId: null,
        })
    }

    const toggleImageModal = ():void => {      
        setImageDialog(!imageDialog)
        setAnchorEl(null)
    }

    const toggleEditModal = (): void => {
        if (modal) {
            setTempSite({
                siteName: "",
                siteNameUserFriendly: "",
                loggers: [],
                latitude: 0,
                longitude: 0,
                createdDate: undefined,
                fkCompanyId: parseInt(sessionStorage.getItem('companyId') || '0'),
                defaultSite: false,
                fkEASiteId: null,
            })
            setEditSite(false)
        } else {
            setEditSite(true)
        }
        setModal(!modal)
        setModalTitle('Edit Site -')
        setAnchorEl(null)
        

    }

    const toggleNewModal = ():void => {
        setTempSite({
            siteName: "",
            siteNameUserFriendly: "",
            loggers: [],
            latitude: 0,
            longitude: 0,
            createdDate: undefined,
            fkCompanyId: parseInt(sessionStorage.getItem('companyId') || '0'),
            defaultSite: false,
            fkEASiteId: null,
        })
        openNewSiteModal()    
        
    }

    ///need to loook at this
    const handleLoggersToBeUnassigned = (logger) => {
        const loggersToBeUnassignedTemp = [...loggersToBeUnassigned, logger]
        const siteTemp = tempSite
        if (siteTemp.loggers.length > 1) {
            siteTemp.loggers = siteTemp.loggers.filter(siteLogger => siteLogger.serial !== logger.serial)
        } else {
            siteTemp.loggers = []
        }
        setTempSite(siteTemp)
        setLoggersToBeUnassigned(loggersToBeUnassignedTemp)
    }

    const openNewSiteModal = ():void => {
        setModal(!modal)
        setModalTitle('New Site')
        setAnchorEl(null)
        setEditSite(false)
    }

    const handleTextFieldChange = (name: string, value: string): void => {
        
        const siteTemp = { ...tempSite }
        siteTemp[name] = value
        setTempSite(siteTemp)
    }


    const toggleDeleteModal = () => {
        setModalTitle('Delete Site')
        setDeleteModal(!deleteModal)
        setAnchorEl(null)
    }

    const toggleSiteDetail = () => {
            setRedirect(true)
            setRedirectPath('/portal/SiteDetail')
            setRedirectProps({
                siteName: tempSite.siteNameUserFriendly || tempSite.siteName,
                siteId: tempSite.siteId,
                serial: '',
                deviceId: null
            })
    }

    const deleteRow = () => {
            CallDeleteAPI(CreateUrl('/api/aquaguard/Site?siteId=' + tempSite.siteId), {})
                .then(async response => {
                    if (response.status == 200) {
                        createSuccessNotification();
                        reloadData()
                    }
                })
                .catch(function (error) {
                    createErrorNotification();
                    console.log(error);
                });
        
        toggleDeleteModal()
    }

    const siteAlarmsClick = (): void => {

                setRedirect(true)
                setRedirectPath('/portal/SiteAlarmConfigs')
                setRedirectProps({
                    siteName: tempSite.siteNameUserFriendly || tempSite.siteName,
                    siteId: tempSite.siteId,
                    serial: '',
                    deviceId: null
                })

    }


    const handleSiteChange = (selected, assignDate, polygonPath, boundaryStyling, hasBoundary, tempName, companyId): void => {
        let boundaryInfo = null
        if (Array.isArray(polygonPath) && polygonPath.length > 0) {
            boundaryInfo = JSON.stringify({ path: polygonPath, styling: boundaryStyling })
        }

        if (tempName.siteNameTemp && tempName.siteRefTemp) {
            if (!editSite) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        SiteName: tempName.siteRefTemp,
                        SiteNameUserFriendly: tempName.siteNameTemp,
                        Latitude: tempSite.Latitude || 0,
                        Longitude: tempSite.Longitude || 0,
                        CreatedDate: new Date(),
                        FkCompanyId: companyId,
                        DefaultSite: false,
                        FkEASiteId: null,
                        Boundary: hasBoundary && selected.length !== 0 ? boundaryInfo : null
                    })
                };
                CallGetAPI(CreateUrl('/api/aquaguard/Site'), requestOptions)
                    .then(data => {
                        createSuccessNotification()

                        if (selected.length > 0) {

                            const loggerIds: Array<{ Id: number }> = [];
                            selected.forEach(logger => loggerIds.push({ Id: logger.loggerId }))
                            const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    siteId: data.id,
                                    lastUpdate: data.lastUpdate,
                                    loggers: loggerIds,
                                    assignLogReadingsFromDate: assignDate,
                                    deploymentGuid: null
                                })
                            };

                            CallPostAPI(CreateUrl('/api/Aquaguard/AssignLoggersToSite'), requestOptions)
                                .then(data => {
                                    createSuccessNotification()
                                    setTimeout(() => reloadData(), 500)
                                })
                                .catch(err => {
                                    createErrorNotification()
                                    console.log(err)
                                })
                        }
                        handleUnAssigningLoggersCall()
                        if (selected.length === 0 && loggersToBeUnassigned.length === 0) setTimeout(() => reloadData(), 500)
                    })
                    .catch(err => {
                        createErrorNotification()
                        console.log(err)
                    })
            } else {
                const requestOptions = {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        Id: tempSite.siteId,
                        SiteName: tempName.siteRefTemp,
                        SiteNameUserFriendly: tempName.siteNameTemp,
                        Created: tempSite.created,
                        Deleted: tempSite.deleted,
                        Latitude: tempSite.Latitude || 0,
                        Longitude: tempSite.Longitude || 0,
                        FKCompanyId: companyId,
                        DefaultSite: false,
                        LastUpdate: tempSite.lastUpdate,
                        FkEASiteId: null,
                        Boundary: hasBoundary ? boundaryInfo : null
                    })
                };

                CallPutAPI(CreateUrl('/api/Aquaguard/Site/' + tempSite.siteId), requestOptions)
                    .then(data => {
                        createSuccessNotification()
                        if (selected.length > 0) {

                            const loggerIds: Array<{ Id: number }> = [];
                            selected.forEach(logger => loggerIds.push({ Id: logger.loggerId }))
                            const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    siteId: tempSite.siteId,
                                    lastUpdate: tempSite.lastUpdate,
                                    loggers: loggerIds,
                                    assignLogReadingsFromDate: assignDate,
                                    deploymentGuid: null
                                })
                            };

                            CallPostAPI(CreateUrl('/api/Aquaguard/AssignLoggersToSite'), requestOptions)
                                .then(data => {
                                    createSuccessNotification()
                                    setTimeout(() => reloadData(), 500)
                                })
                                .catch(err => {
                                    createErrorNotification()
                                    console.log(err)
                                })
                        }
                        handleUnAssigningLoggersCall()
                        if (selected.length === 0 && loggersToBeUnassigned.length === 0) setTimeout(() => reloadData(), 500)
                    })
                    .catch(err => {
                        createErrorNotification()
                        console.log(err)
                    })

            } 
        }
        else {
            window.alert('Site must have a name and ref')
        }
        
    }
    const handleUnAssigningLoggersCall = (): void => {

        if (loggersToBeUnassigned.length > 0) {
            const loggerIds: Array<{ Id: number }> = [];
            loggersToBeUnassigned.map((l) => {
                loggerIds.push({ Id: l.loggerId });
            });
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    loggers: loggerIds,
                    shutdown: false,
                    force: true
                })
            };
            CallPostAPI(CreateUrl('/api/Aquaguard/UndeployLoggers'), requestOptions)
                .then(data => {
                    createSuccessNotification()
                    setLoggersToBeUnassigned([])
                    setSelectedTab(0)
                })
                .catch(err => {
                    createErrorNotification()
                    setLoggersToBeUnassigned([])
                    console.log(err)
                })
            setTimeout(() => reloadData(), 500)
        }
        
    } 

    const clearState = ():void => {
        localStorage.removeItem('siteListGridActive')
        localStorage.removeItem('siteListGridInActive')
        activeGridRef.current = null
        inactiveGridRef.current = null
    }

    const onMapLoad = (map): void => {

        mapRef.current = map;
        map.setMapTypeId("hybrid")
        fitBoundsToMarkers();
    };

    const fitBoundsToMarkers = () => {
        if (mapRef.current && markers.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            markers.forEach((marker) => {
                bounds.extend(marker.position);
            });
            
            if (boundaries.length > 0 ) {

                boundaries.forEach(boundary => {
                    if (Array.isArray(boundary.path)) {
                        boundary.path.forEach((corner) => {
                            bounds.extend(corner)
                        })
                    }
                })
            }
            mapRef.current.fitBounds(bounds);
        }
    };


    const handlePolygonClick = (siteId: number): void => {
        
        let boundaryClicked = activeSites.filter(site => site.siteId === siteId)[0]
        if (!boundaryClicked) {
            boundaryClicked = inactiveSites.filter(site => site.siteId === siteId)[0]
        }
        if (boundaryClicked) {
            setRedirect(true)
            setRedirectPath('/portal/SiteDetail')
            setRedirectProps({
                siteName: boundaryClicked.siteNameUserFriendly || boundaryClicked.siteName,
                siteId: boundaryClicked.siteId,
                serial: '',
                deviceId: null
            })  
        } else alert('Error finding site details')
    }

    const handleMouseOverPolygon = (siteId: number, e: React.MouseEvent): void => {
        setMouseOverBoundary(true)
        

        const boundaryHovered = activeSites.filter(site => site.siteId === siteId)[0]
        setShowTooltip(true)
        setHoveredBoundary(boundaryHovered)
    }


    const handleMouseLeavePolygon = (): void => {
        setMouseOverBoundary(false)
        setTimeout(() => {
            if (!tooltipHovered) {
                setShowTooltip(false)
            }
        }, 2000)
    }


    const handleMouseOverTooltip = (e): void => {
        e.stopPropagation()
        setTooltipHovered(true)
    }
    const handleMouseLeaveTooltip = (): void => {
        setTooltipHovered(false)
        if (!mouseOverBoundary) { 
            setShowTooltip(false)
        }
    }

    const handleEditClick = (e, siteId: number): void => {
        e.stopPropagation()
        const selectedSite = activeSites.filter(site => site.siteId === siteId)[0]
        setTempSite(selectedSite)
        toggleEditModal()
    }

    const handleMarkerClick = (marker) => {
        const relatedSite = activeSites.filter(site => site.loggers.map(logger => logger.serial).includes(marker.serial))[0]
        setRedirect(true)
        setRedirectPath('/portal/SiteDetail')
        setRedirectProps({
            siteName: relatedSite.siteNameUserFriendly || relatedSite.siteName,
            siteId: relatedSite.siteId,
            serial: '',
            deviceId: null
        })  
    }    

    const handleChipClick = (serial: number, loggerId: number):void => {

        const clickedChip = activeSites
            .find(site => site.loggers.some(logger => logger.serial === serial))
            ?.loggers.find(logger => logger.serial === serial);
        setRedirect(true)
        setRedirectPath(!isLCLLogger(clickedChip.model) ? '/portal/DeviceDetail' : '/portal/LCLDeviceDetail')
        setRedirectProps({
            serial: serial,
            mapKey: 'test'
        })
    }

    const renderSiteName = (data) => {
        const theme = useTheme()

        return (<Chip
            label={data.data.siteNameUserFriendly}
            onClick={(): void => handlePolygonClick(data.data.siteId)}
            sx={{ backgroundColor: theme.palette.grey.main, color: theme.palette.grey.contrastText }}
        />)
        
    }





    const tabNames = [
        {
            id: 0,
            text: "Sites With Loggers",
            icon: "fa fa-mobile"

        },
        {
            id: 1,
            text: "Sites Without Loggers",
            icon: "fa fa-ban"
        }
 
    ]
    return (
        authorized ? (
            !redirect ? 
                <div>
                    {loading &&
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <ClipLoader
                                size={150}
                                color={"#123abc"}
                                loading={loading}
                            />
                        </div>}
                    {!loading && <div>
                        <Modal
                            show={deleteModal}
                            onHide={(): void => toggleDeleteModal()}
                            dialogClassName="modal-50w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    {modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="show-grid">
                                Delete Site {tempSite.siteNameUserFriendly || tempSite.siteName} AND ALL associated Readings?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => deleteRow()}>Continue</Button>
                                <Button color="secondary" variant="contained" onClick={(): void => toggleDeleteModal()}>Cancel</Button>
                            </Modal.Footer>
                        </Modal>
                        {companies.length > 0 && <SiteModal
                            open={modal}
                            modalTitle={`${modalTitle} ${tempSite?.siteNameUserFriendly ? tempSite?.siteNameUserFriendly : ""}`}
                            onCancel={toggleEditModal}
                            site={tempSite}
                            handleTextFieldChange={handleTextFieldChange}
                            unAssignedLoggers={loggersToBeAssigned}
                            selectedLogger={location.state?.serial ? location.state.serial : null}
                            editSite={editSite}
                            handleLoggersToBeUnassigned={handleLoggersToBeUnassigned}
                            onSubmit={handleSiteChange}
                            companies={companies}
                        />}

                        <UploadDialog
                            show={imageDialog}
                            title="Add images to site"
                            siteId={tempSite ? tempSite.siteId : 0}
                            loggerId={0}
                            onSubmit={toggleImageModal}
                            onCancel={toggleImageModal}
                        />
                        <GridContainer >
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">

                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <IconButton
                                                style={{ display: 'inline' }}
                                                onClick={(): void => history.goBack()}
                                                size="large"><ArrowBackIcon style={{ fill: "white" }} />
                                            </IconButton>
                                            <h4 style={{ display: 'inline', paddingTop: 12 }} className={classes.cardTitleWhite}>Sites</h4>
                                        </div>
                                        <div>
                                            <div style={{ display: 'inline' }}>
                                                <Tooltip title="clear sort/filter">
                                                    <IconButton
                                                        edge="start"
                                                        color="inherit"
                                                        onClick={(): void => clearState()}
                                                        aria-label="clear sort/filter"
                                                    >
                                                        <SettingsBackupRestoreRoundedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="refresh data">
                                                    <IconButton
                                                        edge="start"
                                                        color="inherit"
                                                        onClick={(): void => reloadData()}
                                                        aria-label="refresh"
                                                    >
                                                        <RefreshRoundedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            {sessionStorage.getItem('canConfig') === "true" && <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={toggleNewModal}>Add New Site</Button>}
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ width: "60%", marginRight: "10px" }}>
                                                <Tabs
                                                    onItemClick={(e): void => setSelectedTab(e.itemIndex)}
                                                    dataSource={tabNames}
                                                    defaultSelectedIndex={0}
                                                    style={{ fontSize: "18px" }}
                                                    selectionMode={'single'}
                                                    selectedIndex={selectedTab}


                                                />
                                                {selectedTab === 0 &&
                                                    <div style={{ border: "1px solid #dddddd", padding: "10px" }} >
                                                        <div style={{ border: "1px solid #dddddd" }}>
                                                            <DataGrid
                                                                dataSource={activeSites}
                                                                allowColumnResizing={true}
                                                                allowColumnReordering={true}
                                                                ref={activeGridRef}
                                                            >
                                                                <GroupPanel visible={true} />
                                                                <FilterRow visible={true} />
                                                                <StateStoring enabled={true} type="localStorage" storageKey="siteListGridActive" />
                                                                <Column dataField="isParent" caption="Parent Company" groupIndex={0} defaultSortOrder={'desc'} />
                                                                <Column dataField="companyName" groupIndex={1} caption="Company" />
                                                                <Column dataField="siteId" dataType="number" visible={false} />
                                                                <Column dataField="siteNameUserFriendly" dataType="string" caption="Site Name" cellRender={renderSiteName} />
                                                                <Column dataField="siteName" dataType="string" caption="Site Ref" />
                                                                <Column dataField="alarmsEnabled" dataType="number" caption="Alarms" />
                                                                <Column dataField="loggers" caption="Devices" cellRender={handleDeviceRender} />
                                                                <Column fixed={true} fixedPosition='right' type="edit" cellRender={(data): React.ReactNode => moreRender(data.data)} key='more' caption="Action" width={75} />
                                                                <Pager allowedPageSizes={[5, 10, 20, 50]} showPageSizeSelector={true} />
                                                                <Paging defaultPageSize={10} />
                                                            </DataGrid>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedTab === 1 &&
                                                    <div style={{ border: "1px solid #dddddd", padding: "10px" }} >
                                                        <div style={{ border: "1px solid #dddddd" }}>
                                                            <DataGrid
                                                                dataSource={inactiveSites}
                                                                ref={inactiveGridRef}
                                                            >
                                                                <GroupPanel visible={true} />
                                                                <FilterRow visible={true} />
                                                                <StateStoring enabled={true} type="localStorage" storageKey="siteListGridInActive" />
                                                                <Column dataField="isParent" caption="Parent Company" groupIndex={0} defaultSortOrder={'desc'} />
                                                                <Column dataField="companyName" groupIndex={1} caption="Company" />
                                                                <Column dataField="siteId" dataType="number" visible={false} />
                                                                <Column dataField="siteNameUserFriendly" dataType="string" caption="Site Name" />
                                                                <Column dataField="siteName" dataType="string" caption="Site Ref" />
                                                                <Column dataField="alarmsEnabled" dataType="number" caption="Alarms" />
                                                                <Column fixed={true} fixedPosition='right' type="edit" cellRender={(data): React.ReactNode => moreRender(data.data)} key='more' caption="Action" width={75} />
                                                                <Pager allowedPageSizes={[5, 10, 20, 50]} showPageSizeSelector={true} />
                                                                <Paging defaultPageSize={10} />
                                                            </DataGrid>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div style={{ width: "40%", marginleft: "10px" }}>
                                                {markers.length > 0 &&
                                                    <GoogleMapsMultiPolygon
                                                        markers={markers}
                                                        onMapLoad={onMapLoad}
                                                        boundaries={boundaries}
                                                        onPolygonClick={handlePolygonClick}
                                                        onMouseOverPolygon={showTooltip ? null : handleMouseOverPolygon}
                                                        onMouseLeavePolygon={handleMouseLeavePolygon}
                                                        showTooltip={showTooltip}
                                                        hoveredBoundary={hoveredBoundary}
                                                        onMouseOverTooltip={handleMouseOverTooltip}
                                                        onMouseLeaveTooltip={handleMouseLeaveTooltip}
                                                        onEditClick={handleEditClick}
                                                        handleMarkerClick={handleMarkerClick}
                                                        handleChipClick={handleChipClick}
                                                    />}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>

                        {anchorEl && <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            disableScrollLock={true}
                        >
                            {sessionStorage.getItem('canConfig') === "true" && <MenuItem onClick={toggleEditModal}>Edit</MenuItem>}
                            <MenuItem onClick={toggleSiteDetail}>Details</MenuItem>
                            {!window.location.hostname.includes('zonelog.net') && <MenuItem onClick={(): void => { siteAlarmsClick() }}>Alarm Configs</MenuItem>}
                            <MenuItem onClick={toggleImageModal}>Upload Picture</MenuItem>
                            {sessionStorage.getItem('canConfig') === "true" && <MenuItem onClick={(): void => { toggleDeleteModal() }}>Delete</MenuItem>}
                        </Menu>}
                    </div>}
                </div>
                :
            <Redirect push to={{
                pathname: redirectPath,
                state: redirectProps
            }} />
            ) :
        <Redirect to={'/Login'} />
    )
}
export default withStyles(style)(Sites);