import React from 'react'
import { APILoggerDetail } from '../../models/APILoggerDetail';
import RangeSelector, {
    Size, Chart as ChartOptions, Margin, Scale, Behavior, RangeSelectorTypes, ValueAxis as RangeAxis
} from 'devextreme-react/range-selector';
import Legend from 'devextreme-react/chart';
interface Readings extends Record<string, any> {
    date: Date;

}

interface Props {
    graphData: Array<Readings>
    updateVisualRange: (e: RangeSelectorTypes.ValueChangedEvent) => void
    startDate: Date
    endDate: Date
    loggers: Array<APILoggerDetail>
    renderRangeFinderSeries: (logger: APILoggerDetail) => void
}
const GraphRangeSelector: React.FC<Props> = ({graphData, updateVisualRange, startDate, endDate, loggers, renderRangeFinderSeries }) => {
    return <RangeSelector
        dataSource={graphData}
        onValueChanged={updateVisualRange}
        style={{ background: "#ffffff", margin: "10px", padding: "10px" }}
    >
        <Behavior valueChangeMode="onHandleMove" />
        <Size height={150} />
        <Scale minorTickCount={1} startValue={startDate} endValue={endDate} />

        <ChartOptions>
            <Legend visible={false} />
            {loggers.map((logger) => {
                return renderRangeFinderSeries(logger)
            }
            )}

        </ChartOptions>
    </RangeSelector>
}
export default GraphRangeSelector