import React, { useState, useEffect, useRef } from 'react'
import { GoogleMap, LoadScriptNext, Polygon, Marker } from "@react-google-maps/api";
import { Overlay } from 'react-bootstrap';
import { CreateUrl, CallGetAPI, CallPutAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import { APILoggerDetail, buildAPILoggerDetail } from 'models/APILoggerDetail';

import GoogleMapsSiteTooltip from './GoogleMapsTooltip'
interface Coordinates {
    lat: number
    lng: number
}

interface GPS {
    position: { lat: number, long: number }
    icon: string
    serial: number
    id: number
    tooltip: string


}

interface Props {
    markers: Array<GPS>
    onMapLoad: () => void
    boundaries: Array<Boundary>
    onPolygonClick: (value: number) => void
    onMouseOverPolygon: (value: number) => void
    onMouseLeavePolygon: () => void
    showTooltip: boolean
    hoveredBoundary: Site | undefined
    hoveredMarker: Site | undefined
    onMouseOverTooltip: (value: number) => void
    onMouseLeaveTooltip: () => void
    onEditClick: (value: number) => void
    handleMarkerClick: (value: number) => void
    tooltipOffset: {
        x: number
        y: number
    }
    setZoom: (value: number) => void
    setMapCenter: () => void
    handleChipClick: (serial: number, loggerId: number) => void
}





interface Boundary {
    site: number
    path: Array<Coordinates>
    styling: {
        strokeWeight: number
        strokeColor: string
        areaColor: string
        transparency: number
    }
}

interface Site {
    siteId: number;
    siteNameUserFriendly: string;
    siteName: string;
    created: Date;
    deleted: Date | null;
    Latitude: number | null;
    Longitude: number | null;
    //devices: Array<Device>;
    groups: Array<{ id: number; displayName: string }>;
    defaultSite: boolean;
    fkCompanyId: number;
    fkEASiteId: number | null;
    EASite: string;
    lastUpdate: Date | null;
    alarmState: number | null;
    alarmsEnabled: number | null;
    Loggers: Array<APILoggerDetail>;
    LastReading?: Date | null;
    HasActiveLoggers?: string;

}



const GoogleMapsMultiPolygon: React.FC<Props> = ({
    markers,
    onMapLoad,
    boundaries,
    onPolygonClick,
    onMouseLeavePolygon,
    onMouseOverPolygon,
    showTooltip,
    hoveredBoundary,
    onMouseOverTooltip,
    onMouseLeaveTooltip,
    onEditClick,
    handleMarkerClick,
    handleChipClick,


}) => {

    
    const defaultCenter = window.location.hostname.includes("zonelog.net")
        ? { lat: 47.17442830752493, lng: 8.517189402835617 }
        : { lat: 50.744094943532446, lng: -3.99597602646894 };

    const [center, setCenter] = useState<Coordinates>(defaultCenter)
    const [mapKey, setMapKey] = useState<string>("")
    const [mapCenter, setMapCenter] = useState<Coordinates>()


    useEffect(() => {
        CallGetAPI(CreateUrl('/api/aquaguard/mapkey'), {})
            .then(data => {
                setMapKey(data)
            })
            .catch(err => console.log(err));

    }, [])

    const containerStyle = {
        height: "75vh",
        width: "100%",
        marginLeft: "5px",
        minHeight: "500px",
    };

    const handlePolygonLoad = (polygon, map) => {
        polygon.addListener("mouseover", () => {
            setMapCenter({ lat: map.getCenter().lat(), lng: map.getCenter().lng() })
        });
    };

    const mapStyles = [
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }], // Hide all labels for Points of Interest (e.g., hospital, park)
        },
        {
            featureType: "poi.business",
            elementType: "labels",
            stylers: [{ visibility: "off" }], // Hide business-related labels (e.g., shops, restaurants)
        },
        {
            featureType: "poi.medical",
            elementType: "labels",
            stylers: [{ visibility: "off" }], // Hide medical-related labels (e.g., hospital, clinic)
        },
        {
            featureType: "poi.place_of_worship",
            elementType: "labels",
            stylers: [{ visibility: "off" }], // Hide places of worship labels
        },
        {
            featureType: "poi.school",
            elementType: "labels",
            stylers: [{ visibility: "off" }], // Hide school labels
        }
    ];


    
    return (
        <div style={{ width: "100%" }}>
            {mapKey && <LoadScriptNext googleMapsApiKey={mapKey}>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    onLoad={onMapLoad}
                    options={{
                        styles: mapStyles,
                        disableDefaultUI: false
                    }}
                >
                        {boundaries.length > 0 && boundaries.map((boundary, key) => {
                            
                            return (
                                <React.Fragment key={boundary.site}>
                                    <Polygon
                                        key={boundary.site}
                                        paths={boundary.path}
                                        options={{
                                            editable: false,
                                            draggable: false,
                                            strokeColor: boundary.styling.strokeColor,
                                            strokeOpacity: 0.8,
                                            strokeWeight: boundary.styling.strokeWeight,
                                            fillColor: boundary.styling.areaColor,
                                            fillOpacity: boundary.styling.transparency / 100,

                                        }}
                                        onClick={(): void => onPolygonClick(boundary.site)}
                                        onMouseOver={(e): void => showTooltip ? null : onMouseOverPolygon(boundary.site, e)}
                                        onMouseOut={(): void => onMouseLeavePolygon()}
                                        onLoad={(polygon) => handlePolygonLoad(polygon, polygon.getMap())}
                                    />
                                    {hoveredBoundary && hoveredBoundary.siteId === boundary.site && showTooltip &&
                                        <GoogleMapsSiteTooltip
                                        tooltipData={hoveredBoundary}
                                        key={key}
                                        mapCenter={mapCenter}
                                        handleChipClick={handleChipClick}
                                        mappedSiteData={boundary}
                                        onMouseOverTooltip={onMouseOverTooltip}
                                        onMouseLeaveTooltip={onMouseLeaveTooltip}
                                        onEditClick={onEditClick}
                                        onViewClick={onPolygonClick}
                                        />}
                                    </React.Fragment>
                                    )
                        })}                       


                    {markers.length > 0 && markers.map((marker) => {
                        return (<React.Fragment key={marker.serial }>
                            <Marker
                                key={marker.key}
                                position={marker.position}
                                icon={marker.icon}
                                onClick={(): void => handleMarkerClick(marker)}
                            />
                            
                        </React.Fragment>)
                    })}
                </GoogleMap>
            </LoadScriptNext >}
        </div>
    )
}

export default GoogleMapsMultiPolygon