 import React, { useState, useEffect, ChangeEvent } from "react"
import { TextField, Chip, Button, Select, MenuItem } from "@mui/material"
import GridItem from "components/Grid/GridItem.js";
import DateRange from '../Dates/DateRange';
import moment, { Moment } from 'moment';
import { CheckBox } from 'devextreme-react';
import { CreateUrl, CallPostAPI, CallGetAPI, CallDeleteAPI, CallPutAPI } from 'Utils/ApiHelper.js';
import { NotificationManager } from 'react-notifications';
import Modal from 'react-bootstrap/Modal';
import { Button as DevButton } from 'devextreme-react/button';
import DataGrid, {Column} from 'devextreme-react/data-grid';
import ClipLoader from "react-spinners/ClipLoader";
import ExcelJS, { Column as exportColumn } from 'exceljs';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import RadioGroup, { RadioGroupTypes } from 'devextreme-react/radio-group';
import { GutermannModelConverter } from "../../Utils/GutermannModelCoverter";
import { FormattedMessage, useIntl } from 'react-intl';
import deMessages from "devextreme/localization/messages/de.json";
import frMessages from "devextreme/localization/messages/fr.json";
import esMessages from "devextreme/localization/messages/es.json";
import ptMessages from "devextreme/localization/messages/pt.json";
import { locale, loadMessages } from "devextreme/localization";
interface props {
    deviceData: Array<DeviceData>;
    loggerIds: Array<number>;
    removeLogger?: (numberToRemove: number) => void;
    onCancel: () => void;
    fromGroup: boolean;
    templateClicked: boolean;
    
}

interface Filters {
    model: boolean
    deployed: boolean
    siteName: boolean
    siteRef: boolean
    deviceSiteName: boolean
    gps: boolean
    lastConnection: boolean
    currentSignal: boolean
    firmware: boolean
    batterySaverPer: boolean
    batteryPer: boolean
    batteryLife: boolean
    minFlowAL: boolean
    maxFlowAL: boolean
    avgFlowAL: boolean
    consumptionAL: boolean
    minFlowAM3: boolean
    maxFlowAM3: boolean
    avgFlowAM3: boolean
    consumptionAM3: boolean
    minFlowBL: boolean
    maxFlowBL: boolean
    avgFlowBL: boolean
    consumptionBL: boolean
    minFlowBM3: boolean
    maxFlowBM3: boolean
    avgFlowBM3: boolean
    consumptionBM3: boolean
    minPressMb: boolean
    maxPressMb: boolean
    avgPressMb: boolean
    minPressPsi: boolean
    maxPressPsi: boolean
    avgPressPsi: boolean
    minPressBar: boolean
    maxPressBar: boolean
    avgPressBar: boolean
    minPressMHead: boolean
    maxPressMHead: boolean
    avgPressMHead: boolean
    energyLevel: boolean
    coverageClass: boolean
    actType: boolean
    transmissionsPerDay: boolean
    dataConnectionsMade: boolean
    batteryVoltage: boolean
    lastStatusMessage: boolean
    lastDataPoint: boolean
    lastMessageType: boolean
    relayStatus: boolean
    cellId: boolean
    freqBand: boolean
    temperature: boolean
}

interface CheckBoxesInfo {
    model: boolean
    deployed: boolean
    siteName: boolean
    siteRef: boolean
    deviceSiteName: boolean
    firmware: boolean
    gps: boolean
    energyLevel: boolean
    coverageClass: boolean
    actType: boolean
    relayStatus: boolean
    cellId: boolean
    freqBand: boolean
    temperature: boolean
}

interface CheckBoxesStats {
    
    lastConnection: boolean
    currentSignal: boolean
    batteryPer: boolean
    batteryLife: boolean
    batterySaverPer: boolean
    transmissionsPerDay: boolean
    dataConnectionsMade: boolean
    batteryVoltage: boolean
    lastStatusMessage: boolean
    lastDataPoint: boolean
    lastMessageType: boolean
    
    
}

interface DeviceData {
    id: number;
    serial: string;
    site: string;
    model: string;
    channels: string;
    lastConnection: Date | null;
    alarmState: string;
    siteId: number | null;
    siteName: string;
    siteRef: string;
    deployed: string;
    sendToLogger: boolean;
    updateRequested: Date | null;
    updateConfirmed: Date | null;
    firmwareToUpdate: number | null;
    firmwareSent: Date | null;
    firmwareConfirmed: Date | null;
    gpsLatitude: number | null;
    gpsLongitude: number | null;
    configurationId: string;
    undeploy: boolean;
    shutdown: boolean;
    dormant: boolean;
    company: string;
    modelId: string;
    channelsAvail: number;
    meterConfig: number;
}

interface FilterOptionFlow {
    filterSelected: string;
    units: string;
    channel: string;
}

interface FilterOptionPressure {
    filterSelected: string;
    units: string;
}

interface Template {
    id: number;
    companyId: number;
    created: string;
    updated: string;
    reportName: string;
    filterWanted: string;
    loggerIds: string;
    loggerSerials: string;
    daysSelected: number;
    userId: number;
    exportType: string;
    reportType: string;
}

interface ExportReturnData {
    Serial?: number;
    Model: string;
    SiteRef: string;
    SiteName: string;
    DeviceSiteName: string;
    Deployed?: boolean;
    Gps: string;
    LastConnection?: Date;
    CurrentSignal?: number;
    Firmware: string;
    BatteryPer?: number;
    BatterySaverPer?: number;
    BatteryLife?: number;
    BatteryVoltage?: number;
    MinFlowAL?: number;
    MaxFlowAL?: number;
    AvgFlowAL?: number;
    ConsumptionAL?: number;
    MinFlowAM3?: number;
    MaxFlowAM3?: number;
    AvgFlowAM3?: number;
    ConsumptionAM3?: number;
    MinFlowBL?: number;
    MaxFlowBL?: number;
    AvgFlowBL?: number;
    ConsumptionBL?: number;
    MinFlowBM3?: number;
    MaxFlowBM3?: number;
    AvgFlowBM3?: number;
    ConsumptionBM3?: number;
    MinPressMbC?: number;
    MaxPressMbC?: number;
    AvgPressMbC?: number;
    MinPressPsiC?: number;
    MaxPressPsiC?: number;
    AvgPressPsiC?: number;
    MinPressBarC?: number;
    MaxPressBarC?: number;
    AvgPressBarC?: number;
    MinPressMHeadC?: number;
    MaxPressMHeadC?: number;
    AvgPressMHeadC?: number;
    MinPressMbD?: number;
    MaxPressMbD?: number;
    AvgPressMbD?: number;
    MinPressPsiD?: number;
    MaxPressPsiD?: number;
    AvgPressPsiD?: number;
    MinPressBarD?: number;
    MaxPressBarD?: number;
    AvgPressBarD?: number;
    MinPressMHeadD?: number;
    MaxPressMHeadD?: number;
    AvgPressMHeadD?: number;
    MinPressMbE?: number;
    MaxPressMbE?: number;
    AvgPressMbE?: number;
    MinPressPsiE?: number;
    MaxPressPsiE?: number;
    AvgPressPsiE?: number;
    MinPressBarE?: number;
    MaxPressBarE?: number;
    AvgPressBarE?: number;
    MinPressMHeadE?: number;
    MaxPressMHeadE?: number;
    AvgPressMHeadE?: number;
    CoverageClass0?: number;
    CoverageClass1?: number;
    CoverageClass2?: number;
    EnergyLevel1?: number;
    EnergyLevel2?: number;
    EnergyLevel3?: number;
    EnergyLevel4?: number;
    EnergyLevel5?: number;
    NBIoT?: number;
    LTM?: number;
    DataConnectionsPer?: number;
    TransmissionFreq?: number;
    LastStatusMessage?: Date;
    LastDataPoint?: Date;
    LastMessageType?: string;
    RelayStatus?: boolean;
    cellId?: number;
    freqBand?: number;
    startDate: Date;
    endDate: Date;
    temperature?: string;
   

}









const ReportScheduler: React.FC<props> = ({deviceData, loggerIds, removeLogger, onCancel, fromGroup, templateClicked }) => {


    
    
    const intl = useIntl()
    /// STATE
    const [selectedLoggers, setSelectedLoggers] = useState(loggerIds)
    const [loading, setLoading] = useState<boolean>(false)
    const [startDate, setStartDate] = useState<Moment>(moment().subtract(7, "days").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
    const [offsetStartDate, setOffsetStartDate] = useState<Moment>(moment().subtract(7, "days").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
    const [offsetEndDate, setOffsetEndDate] = useState<Moment>(moment().subtract(1, "days").set({ hour: 23, minute: 59, second: 59, millisecond: 0 }))
    const [endDate, setEndDate] = useState<Moment>(moment().subtract(1, "days").set({ hour: 23, minute: 59, second: 59, millisecond: 0 }))
    const [offset, setOffset] = useState<number>(sessionStorage.getItem('utcOffset') ? parseInt(sessionStorage.getItem('utcOffset').slice(1)) : 0)
    const [eaSiteId, setEaSiteId] = useState<number>(0)
    const [selectAllInfo, setSelectAllInfo] = useState<boolean>(false)
    const [selectAllStats, setSelectAllStats] = useState<boolean>(false)
    const [exportType, setExportType] = useState<string>("CSV")
    const [scheduleFrequency, setScheduleFrequency] = useState<string>("daily")
    const [now, setNow] = useState<boolean>( true)
    const [schedule, setSchedule] = useState<boolean>(false)
    const [processed, setProcessed] = useState<number>(0)
    const [reportSelected, setReportSelected] = useState<boolean>(/*(sessionStorage.getItem('userLevel') === 'useradmin' || sessionStorage.getItem('userLevel') === 'identityadmin') ? false : true*/ true)
    const [allCheckBoxesFalse, setAllCheckBoxesFalse] = useState<boolean>(true)
    const [checkboxesInfo, setCheckboxesInfo] = useState<CheckBoxesInfo>({
        model: false,
        deployed: false,
        siteName: false,
        siteRef: false,
        deviceSiteName: false,
        firmware: false,
        gps: false,
        energyLevel: false,
        coverageClass: false,
        actType: false,
        relayStatus: false,
        cellId: false,
        freqBand: false,
        temperature: false
    })
    const [checkboxesStats, setCheckboxesStats] = useState<CheckBoxesStats>({
        lastConnection: false,
        currentSignal: false,
        batteryPer: false,
        batteryLife: false,
        batterySaverPer: false,
        transmissionsPerDay: false,
        dataConnectionsMade: false,
        batteryVoltage: false,
        lastStatusMessage: false,
        lastDataPoint: false,
        lastMessageType: false,
        
    })
    const [filters, setFilters] = useState<Filters>({
        model: false,
        deployed: false,
        siteName: false,
        siteRef: false,
        deviceSiteName: false,
        gps: false,
        energyLevel: false,
        coverageClass: false,
        actType: false,
        transmissionsPerDay: false,
        dataConnectionsMade: false,
        batteryVoltage: false,
        lastStatusMessage: false,
        lastDataPoint: false,
        lastMessageType: false,
        relayStatus: false,
        lastConnection: false,
        currentSignal: false,
        firmware: false,
        batteryPer: false,
        batterySaverPer: false,
        batteryLife: false,
        minFlowAL: false,
        maxFlowAL: false,
        avgFlowAL: false,
        consumptionAL: false,
        minFlowAM3: false,
        maxFlowAM3: false,
        avgFlowAM3: false,
        consumptionAM3: false,
        minFlowBL: false,
        maxFlowBL: false,
        avgFlowBL: false,
        consumptionBL: false,
        minFlowBM3: false,
        maxFlowBM3: false,
        avgFlowBM3: false,
        consumptionBM3: false,
        minPressMb: false,
        maxPressMb: false,
        avgPressMb: false,
        minPressPsi: false,
        maxPressPsi: false,
        avgPressPsi: false,
        minPressBar: false,
        maxPressBar: false,
        avgPressBar: false,
        minPressMHead: false,
        maxPressMHead: false,
        avgPressMHead: false,
        cellId: false,
        freqBand: false,
        temperature: false
    })
    
    const [addedFlowOptions, setAddedFlowOptions] = useState<FilterOptionFlow[]>([{ filterSelected: "", units: "", channel: "" }])
    const [addedPressureOptions, setAddedPressureOptions] = useState<FilterOptionPressure[]>([{filterSelected: "", units: ""}])
    const [toggleTemplateModal, setToggleTemplateModal] = useState<boolean>(false)
    const [templateList, setTemplateList] = useState<Template[] | undefined>([])
    const [templateLoaded, setTemplateLoaded] = useState<boolean>(false)
    const [reportName, setReportName] = useState<string>("")
    const [newTemplate, setNewTemplate] = useState<boolean>(false)
    const [message, setMessage] = useState<string>("")
    const [showMessage, setShowMessage] = useState<boolean>(false)
    const [loadedTemplateId, setLoadedTemplateId] = useState<number>()
    const [typeOfReport, setTypeOfReport] = useState<string>("Cumulative Data")
    const [relayPresent, setRelayPresent] = useState<boolean>(false)
    
    // DYNAMIC MODAL TITLE
    let modalTitle = 'Select a report now or schedule a report'
    if (reportSelected && now && reportName) {
        modalTitle = reportName
    } else { 
        modalTitle = 'Generate Report Now'
    }

    const reportTypes = ["Cumulative Data", "Daily Data Breakdown"]



    useEffect(() => {

        // console.log("DRAWGRAPH Push to API -> " + '/api/aquaguard/GetLogReadings?companyid=0&logger=' + logger.Serial + "&startDate=" + moment(this.state.startDate).format("yyyy-MM-DD") + "&endDate=" + moment(this.state.endDate).format("yyyy-MM-DD") + "T23:59:59");
        let offsetStartDateTemp:Moment| string
        let offsetEndDateTemp:Moment | string
        if (sessionStorage.getItem('utcOffset').slice(0, 1) === '+') {
            offsetStartDateTemp = moment(startDate).subtract(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
            offsetEndDateTemp = moment(endDate).subtract(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
        } else if (sessionStorage.getItem('utcOffset').slice(0, 1) === '-') {
            offsetStartDateTemp = moment(startDate).add(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
            offsetEndDateTemp = moment(endDate).add(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
        } else {
            offsetStartDateTemp = moment(startDate).format("yyyy-MM-DDTHH:mm:ss");
            offsetEndDateTemp = moment(endDate).format("yyyy-MM-DDTHH:mm:ss");
        }
        setOffsetStartDate(offsetStartDateTemp)
        setOffsetEndDate(offsetEndDateTemp)
    }, [])
    
    

    

    const generateReport = (): void => {
        

        if (typeOfReport === "Cumulative Data") {
            setLoading(true)
            const exportData: ExportReturnData[] = [];
            const filter = combineFilters()
            let loggers = []
            if (selectedLoggers && selectedLoggers.length > 0) {
                loggers = selectedLoggers.map((item: number) => {
                    return { id: item }
                })
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    startDate: moment(offsetStartDate).format("YYYY-MM-DD HH:mm:ss"),
                    endDate: moment(offsetEndDate).format("YYYY-MM-DD HH:mm:ss"),
                    loggers: loggers,
                    model: filter.model,
                    deployed: filter.deployed,
                    siteName: filter.siteName,
                    siteRef: filter.siteRef,
                    deviceSiteName: filter.deviceSiteName,
                    gps: filter.gps,
                    energyLevel: filter.energyLevel,
                    coverageClass: filter.coverageClass,
                    actType: filter.actType,
                    transmissionsPerDay: filter.transmissionsPerDay,
                    dataConnectionsMade: filter.dataConnectionsMade,
                    batteryVoltage: filter.batteryVoltage,
                    lastStatusMessage: filter.lastStatusMessage,
                    lastDataPoint: filter.lastDataPoint,
                    lastMessageType: filter.lastMessageType,
                    relayStatus: filter.relayStatus,
                    lastConnection: filter.lastConnection,
                    currentSignal: filter.currentSignal,
                    firmware: filter.firmware,
                    batteryPer: filter.batteryPer,
                    batterySaverPer: filter.batterySaverPer,
                    batteryLife: filter.batteryLife,
                    minFlowAL: filter.minFlowAL,
                    maxFlowAL: filter.maxFlowAL,
                    avgFlowAL: filter.avgFlowAL,
                    consumptionAL: filter.consumptionAL,
                    minFlowAM3: filter.minFlowAM3,
                    maxFlowAM3: filter.maxFlowAM3,
                    avgFlowAM3: filter.avgFlowAM3,
                    consumptionAM3: filter.consumptionAM3,
                    minFlowBL: filter.minFlowBL,
                    maxFlowBL: filter.maxFlowBL,
                    avgFlowBL: filter.avgFlowBL,
                    consumptionBL: filter.consumptionBL,
                    minFlowBM3: filter.minFlowBM3,
                    maxFlowBM3: filter.maxFlowBM3,
                    avgFlowBM3: filter.avgFlowBM3,
                    consumptionBM3: filter.consumptionBM3,
                    minPressMb: filter.minPressMb,
                    maxPressMb: filter.maxPressMb,
                    avgPressMb: filter.avgPressMb,
                    minPressPsi: filter.minPressPsi,
                    maxPressPsi: filter.maxPressPsi,
                    avgPressPsi: filter.avgPressPsi,
                    minPressBar: filter.minPressBar,
                    maxPressBar: filter.maxPressBar,
                    avgPressBar: filter.avgPressBar,
                    minPressMHead: filter.minPressMHead,
                    maxPressMHead: filter.maxPressMHead,
                    avgPressMHead: filter.avgPressMHead,
                    cellId: filter.cellId,
                    freqBand: filter.freqBand,
                    temperature: filter.temperature
                })
            };

            console.log(requestOptions)
            CallPostAPI(CreateUrl("/api/aquaguard/bespokereport"), requestOptions)

                .then(response => {

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json(); // This returns a promise that resolves with the JSON content of the response body
                })
                .then(data => {
                    console.log(data)
                    console.log(offsetStartDate)
                    for (let i = 0; i < data.length; i++) {
                        console.log(data)
                        if (data[i].batteryVoltage) {
                            data[i].batteryVoltage /= 1000 
                        }
                        if (data[i].lastStatusMessage) {
                            data[i].lastStatusMessage = moment(data[i].lastStatusMessage).format("YYYY-MM-DD HH:mm:ss")
                        }
                        if (data[i].lastDataPoint) {
                            data[i].lastDataPoint = moment(data[i].lastDataPoint).format("YYYY-MM-DD HH:mm:ss")
                        }
                        if (data[i].lastConnection) {
                            data[i].lastConnection = moment(data[i].lastConnection).format("YYYY-MM-DD HH:mm:ss")
                        }
                        if (data[i].firmware) {
                            data[i].firmware = parseFloat(data[i].firmware)
                        }
                        if (data[i].model && window.location.hostname.includes('zonelog.net')) {
                            data[i].model = GutermannModelConverter(data[i].model)
                        }
                        
                        data[i].date = `${moment(offsetStartDate).format('MMM DD HH:mm')} - ${moment(offsetEndDate).format('MMM DD HH:mm')}`;
                        data[i].dateUTC = `${moment(startDate).format('MMM DD HH:mm')} - ${moment(endDate).format('MMM DD HH:mm')}`;
                        exportData.push(data[i])
                        console.log(data[i].date)
                    }


                    const headers = [];
                    headers.push({ header: "Date Range Local Time", key: "date", width: 30 });
                    headers.push({ header: "Date Range UTC", key: "dateUTC", width: 30 });
                    headers.push({ header: "Serial", key: "serial", width: 15 }); // Example width for Serial column
                    console.log(filters)
                    for (const key in filters) {
                        if (filters[key] === true) {
                            switch (key) {
                                case "minPressMb":
                                    headers.push({ header: "Minimum Pressure Mb Chan C", key: "minPressMbC", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan C", key: "minPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure Mb Chan D", key: "minPressMbD", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan D", key: "minPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure Mb Chan E", key: "minPressMbE", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan E", key: "minPressureETimeStamp", width: 30 })
                                    break;
                                case "maxPressMb":
                                    headers.push({ header: "Maximum Pressure Mb Chan C", key: "maxPressMbC", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan C", key: "maxPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure Mb Chan D", key: "maxPressMbD", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan D", key: "maxPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure Mb Chan E", key: "maxPressMbE", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan E", key: "maxPressureETimeStamp", width: 30 })
                                    break;
                                case "avgPressMb":
                                    headers.push({ header: "Average Pressure Mb Chan C", key: "avgPressMbC", width: 30 });
                                    headers.push({ header: "Average Pressure Mb Chan D", key: "avgPressMbD", width: 30 });
                                    headers.push({ header: "Average Pressure Mb Chan E", key: "avgPressMbE", width: 30 });
                                    break;
                                case "minPressPsi":
                                    headers.push({ header: "Minimum Pressure PSI Chan C", key: "minPressPsiC", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan C", key: "minPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure PSI Chan D", key: "minPressPsiD", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan D", key: "minPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure PSI Chan E", key: "minPressPsiE", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan E", key: "minPressureETimeStamp", width: 30 })
                                    break;
                                case "maxPressPsi":
                                    headers.push({ header: "Maximum Pressure PSI Chan C", key: "maxPressPsiC", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan C", key: "maxPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure PSI Chan D", key: "maxPressPsiD", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan D", key: "maxPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure PSI Chan E", key: "maxPressPsiE", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan E", key: "maxPressureETimeStamp", width: 30 })
                                    break;
                                case "avgPressPsi":
                                    headers.push({ header: "Average Pressure PSI Chan C", key: "avgPressPsiC", width: 30 });
                                    headers.push({ header: "Average Pressure PSI Chan D", key: "avgPressPsiD", width: 30 });
                                    headers.push({ header: "Average Pressure PSI Chan E", key: "avgPressPsiE", width: 30 });
                                    break;
                                case "minPressBar":
                                    headers.push({ header: "Minimum Pressure Bar Chan C", key: "minPressBarC", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan C", key: "minPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure Bar Chan D", key: "minPressBarD", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan D", key: "minPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure Bar Chan E", key: "minPressBarE", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan E", key: "minPressureETimeStamp", width: 30 })
                                    break;
                                case "maxPressBar":
                                    headers.push({ header: "Maximum Pressure Bar Chan C", key: "maxPressBarC", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan C", key: "maxPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure Bar Chan D", key: "maxPressBarD", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan D", key: "maxPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure Bar Chan E", key: "maxPressBarE", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan E", key: "maxPressureETimeStamp", width: 30 })
                                    break;
                                case "avgPressBar":
                                    headers.push({ header: "Average Pressure Bar Chan C", key: "avgPressBarC", width: 30 });
                                    headers.push({ header: "Average Pressure Bar Chan D", key: "avgPressBarD", width: 30 });
                                    headers.push({ header: "Average Pressure Bar Chan E", key: "avgPressBarE", width: 30 });
                                    break;
                                case "minPressMHead":
                                    headers.push({ header: "Minimum Pressure mH2O Chan C", key: "minPressMHeadC", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan C", key: "minPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure mH2O Chan D", key: "minPressMHeadD", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan D", key: "minPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure mH2O Chan E", key: "minPressMHeadE", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan E", key: "minPressureETimeStamp", width: 30 })
                                    break;
                                case "maxPressMHead":
                                    headers.push({ header: "Maximum Pressure mH2O Chan C", key: "maxPressMHeadC", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan C", key: "maxPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure mH2O Chan D", key: "maxPressMHeadD", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan D", key: "maxPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure mH2O Chan E", key: "maxPressMHeadE", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan E", key: "maxPressureETimeStamp", width: 30 })
                                    break;
                                case "avgPressMHead":
                                    headers.push({ header: "Average Pressure mH2O Chan C", key: "avgPressMHeadC", width: 30 });
                                    headers.push({ header: "Average Pressure mH2O Chan D", key: "avgPressMHeadD", width: 30 });
                                    headers.push({ header: "Average Pressure mH2O Chan E", key: "avgPressMHeadE", width: 30 });
                                    break;
                                case "batteryPer":
                                    headers.push({ header: "Battery Percentage Remaining", key: "batteryPer", width: 30 });
                                    break;
                                case "batterySaverPer":
                                    headers.push({ header: "Percentage of Connections in Saver Mode", key: "batterySaverPer", width: 30 });
                                    break;
                                case "batteryLife":
                                    headers.push({ header: "Remaining Battery Life (Years)", key: "batteryLife", width: 30 });
                                    break;
                                case "transmissionsPerDay":
                                    headers.push({ header: "Transmissions set each day", key: "transmissionFreq", width: 30 });
                                    break;
                                case "dataConnectionsMade":
                                    headers.push({ header: "% of connections made", key: "dataConnectionsPer", width: 30 });
                                    break;
                                case "coverageClass":
                                    headers.push({ header: "Sum of Class 0", key: "coverageClass0", width: 20 });
                                    headers.push({ header: "Sum of Class 1", key: "coverageClass1", width: 20 });
                                    headers.push({ header: "Sum of Class 2", key: "coverageClass2", width: 20 });
                                    break;
                                case "energyLevel":
                                    headers.push({ header: "Sum of Energy Level 1", key: "energyLevel1", width: 20 });
                                    headers.push({ header: "Sum of Energy Level 2", key: "energyLevel2", width: 20 });
                                    headers.push({ header: "Sum of Energy Level 3", key: "energyLevel3", width: 20 });
                                    headers.push({ header: "Sum of Energy Level 4", key: "energyLevel4", width: 20 });
                                    headers.push({ header: "Sum of Energy Level 5", key: "energyLevel5", width: 20 });
                                    break;
                                case "actType":
                                    headers.push({ header: "No.of NBIoT", key: "nbIoT", width: 30 });
                                    headers.push({ header: "No.of LTM", key: "ltm", width: 30 });
                                    break;
                                case "minFlowAL":
                                    headers.push({ header: "Min Flow (l/s) Channel A", key: "minFlowAL", width: 30 });
                                    headers.push({ header: "Time of Min Flow A", key: "minFlowTimeStampA", width: 30 })
                                    break
                                case "maxFlowAL":
                                    headers.push({ header: "Max Flow (l/s) Channel A", key: "maxFlowAL", width: 30 });
                                    headers.push({ header: "Time of Max Flow A", key: "maxFlowTimeStampA", width: 30 })
                                    break
                                case "avgFlowAL":
                                    headers.push({ header: "Avg Flow (l/s) Channel A", key: "avgFlowAL", width: 30 });
                                    break
                                case "minFlowBL":
                                    headers.push({ header: "Min Flow (l/s) Channel B", key: "minFlowBL", width: 30 });
                                    headers.push({ header: "Time of Min Flow B", key: "minFlowTimeStampB", width: 30 })
                                    break
                                case "maxFlowBL":
                                    headers.push({ header: "Max Flow (l/s) Channel B", key: "maxFlowBL", width: 30 });
                                    headers.push({ header: "Time of Max Flow B", key: "maxFlowTimeStampB", width: 30 })
                                    break
                                case "avgFlowBL":
                                    headers.push({ header: "Avg Flow (l/s) Channel B", key: "avgFlowBL", width: 30 });
                                    break
                                case "consumptionAL":
                                    headers.push({ header: "Consumption (l) Channel A", key: "consumptionAL", width: 30 })
                                    break
                                case "consumptionAM3":
                                    headers.push({ header: "Consumption (m3) Channel A", key: "consumptionAM3", width: 30 })
                                    break
                                case "minFlowAM3":
                                    headers.push({ header: "Min Flow (m3/h) Channel A", key: "minFlowAM3", width: 30 });
                                    headers.push({ header: "Time of Min Flow A", key: "minFlowTimeStampA", width: 30 })
                                    break
                                case "maxFlowAM3":
                                    headers.push({ header: "Max Flow (m3/h) Channel A", key: "maxFlowAM3", width: 30 });
                                    headers.push({ header: "Time of Max Flow A", key: "maxFlowTimeStampA", width: 30 })
                                    break
                                case "avgFlowAM3":
                                    headers.push({ header: "Avg Flow (m3/h) Channel A", key: "avgFlowAM3", width: 30 });
                                    break
                                case "minFlowBM3":
                                    headers.push({ header: "Min Flow (m3/h) Channel B", key: "minFlowBM3", width: 30 });
                                    headers.push({ header: "Time of Min Flow B", key: "minFlowTimeStampB", width: 30 })
                                    break
                                case "maxFlowBM3":
                                    headers.push({ header: "Max Flow (m3/h) Channel B", key: "maxFlowBM3", width: 30 });
                                    headers.push({ header: "Time of Max Flow B", key: "maxFlowTimeStampB", width: 30 })
                                    break
                                case "avgFlowBM3":
                                    headers.push({ header: "Avg Flow (m3/h) Channel B", key: "avgFlowBM3", width: 30 });
                                    break
                                case "consumptionBL":
                                    headers.push({ header: "Consumption (l) Channel B", key: "consumptionBL", width: 30 })
                                    break
                                case "consumptionBM3":
                                    headers.push({ header: "Consumption (m3) Channel B", key: "consumptionBM3", width: 30 })
                                    break
                                default:
                                    headers.push({ header: `${key.slice(0, 1).toUpperCase()}${key.slice(1)}`, key: key, width: 20 });
                                    break;
                            }
                        }
                    }

                    


                    ///// CREATE EXPORT
                    const workbook = new ExcelJS.Workbook();


                    console.log(exportData)
                    let worksheet = null;

                    
                    if (exportData.length > 0) {
                        // New worksheet, Freeze first row - col headers
                        worksheet = workbook.addWorksheet(window.location.hostname.includes('zonelog.net') ? `Bespoke Report from ZONELOG` : `Bespoke Report from Aquaguard`, { views: [{ state: 'frozen', xSplit: 0, ySplit: 2 }] });

                        worksheet.columns = headers
                        







                        if (exportType == "XLSXT") {
                            // Add header row
                            worksheet.addRow(worksheet.columns.map(column => column.header));

                            //exportData.sort((a, b) => (a.dateStamp ? new Date(a.dateStamp).valueOf() : 0) - (b.dateStamp ? new Date(b.dateStamp).valueOf() : 0));

                            // Add data rows

                            worksheet.addRows(exportData);



                            // Add a table to the worksheet
                            worksheet.addTable({
                                name: 'DataTable',
                                ref: 'A2', // Reference to the top-left cell of the data range
                                displayName: 'MyTable',
                                columns: worksheet.columns.map(column => ({ name: column.header, filterButton: true })),
                                rows: exportData.map(data => worksheet.columns.map(column => data[column.key])),
                            });
                        } else {
                            //readingsData.sort((a, b) => (a.dateStamp ? new Date(a.dateStamp).valueOf() : 0) - (b.dateStamp ? new Date(b.dateStamp).valueOf() : 0));
                            worksheet.addRows(exportData);
                        }
                    }

                    if (exportType == "CSV") {

                        const zip = new JSZip();
                        const promises = new Array<Promise<any>>();

                        // Generate CSV files
                        workbook.worksheets.map((sht) => {
                            const options: ExcelJS.CsvWriteOptions = {
                                sheetName: sht.name,
                                sheetId: sht.id,
                                dateUTC: true,
                            } as ExcelJS.CsvWriteOptions;
                            promises.push(workbook.csv.writeBuffer(options)
                                .then(function (buffer: Buffer) {
                                    zip.file(sht.name + ".csv", buffer.buffer);
                                })
                            );
                        });

                        Promise.all(promises).then(() => {
                            if (workbook.worksheets.length > 0) {
                                zip.generateAsync({ type: "blob" }).then(function (content: Blob) {
                                    saveAs(content, window.location.hostname.includes('zonelog.net') ? 'ZONELOG Export.zip' : 'Aquaguard Export.zip');
                                });

                            }
                        });

                    }

                    else {

                        // Download the workbook
                        if (workbook.worksheets.length > 0) {
                            workbook.xlsx.writeBuffer().then(function (buffer: Buffer) {
                                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), window.location.hostname.includes('zonelog.net') ? `Bespoke Report from ZONELOG.xlsx` : `Bespoke Report from Aquaguard.xlsx`);
                            });
                        }

                    }



                    setLoading(false)
                    onExit()
                   





                })
                .catch(function (error) {
                    createErrorNotification();
                    //setMessage("Error Saving Template")
                    //setShowMessage(true)
                    setLoading(false)
                    onExit()
                    console.log(error);
                });

        } else {
            //console.log("hit")
            setLoading(true)
            const filter = combineFilters();
            const dateDiff = endDate.diff(startDate, 'days');

            let loggers = [];
            if (selectedLoggers && selectedLoggers.length > 0) {
                loggers = selectedLoggers.map((item: number) => {
                    return { id: item };
                });
            }

            const counterStartDate = startDate.clone();
            const counterTempEndDate = endDate.clone().subtract(dateDiff, 'days');

            const counterEndDate = counterTempEndDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate();

            let offsetCounterStartDate
            let offsetCounterEndDate
            if (sessionStorage.getItem('utcOffset').slice(0, 1) === '+') {
                offsetCounterStartDate = moment(counterStartDate).subtract(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
                offsetCounterEndDate = moment(counterEndDate).subtract(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
            } else if (sessionStorage.getItem('utcOffset').slice(0, 1) === '-') {
                offsetCounterStartDate = moment(counterStartDate).add(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
                offsetCounterEndDate = moment(counterEndDate).add(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
            } else {
                offsetCounterStartDate = moment(counterStartDate).format("yyyy-MM-DDTHH:mm:ss");
                offsetCounterEndDate = moment(counterEndDate).format("yyyy-MM-DDTHH:mm:ss");
            }

            const combinedData = [];
            const apiCalls = [];
            let counter = 0
            for (let i = 0; i < dateDiff + 1; i++) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        startDate: moment(offsetCounterStartDate).add(counter, "days").format("YYYY-MM-DD HH:mm:ss"),
                        endDate: moment(offsetCounterEndDate).add(counter, "days").format("YYYY-MM-DD HH:mm:ss"),
                        loggers: loggers,
                        model: filter.model,
                        deployed: filter.deployed,
                        siteName: filter.siteName,
                        siteRef: filter.siteRef,
                        deviceSiteName: filter.deviceSiteName,
                        gps: filter.gps,
                        energyLevel: filter.energyLevel,
                        coverageClass: filter.coverageClass,
                        actType: filter.actType,
                        transmissionsPerDay: filter.transmissionsPerDay,
                        dataConnectionsMade: filter.dataConnectionsMade,
                        batteryVoltage: filter.batteryVoltage,
                        lastStatusMessage: filter.lastStatusMessage,
                        lastDataPoint: filter.lastDataPoint,
                        lastMessageType: filter.lastMessageType,
                        relayStatus: filter.relayStatus,
                        lastConnection: filter.lastConnection,
                        currentSignal: filter.currentSignal,
                        firmware: filter.firmware,
                        batteryPer: filter.batteryPer,
                        batterySaverPer: filter.batterySaverPer,
                        batteryLife: filter.batteryLife,
                        minFlowAL: filter.minFlowAL,
                        maxFlowAL: filter.maxFlowAL,
                        avgFlowAL: filter.avgFlowAL,
                        consumptionAL: filter.consumptionAL,
                        minFlowAM3: filter.minFlowAM3,
                        maxFlowAM3: filter.maxFlowAM3,
                        avgFlowAM3: filter.avgFlowAM3,
                        consumptionAM3: filter.consumptionAM3,
                        minFlowBL: filter.minFlowBL,
                        maxFlowBL: filter.maxFlowBL,
                        avgFlowBL: filter.avgFlowBL,
                        consumptionBL: filter.consumptionBL,
                        minFlowBM3: filter.minFlowBM3,
                        maxFlowBM3: filter.maxFlowBM3,
                        avgFlowBM3: filter.avgFlowBM3,
                        consumptionBM3: filter.consumptionBM3,
                        minPressMb: filter.minPressMb,
                        maxPressMb: filter.maxPressMb,
                        avgPressMb: filter.avgPressMb,
                        minPressPsi: filter.minPressPsi,
                        maxPressPsi: filter.maxPressPsi,
                        avgPressPsi: filter.avgPressPsi,
                        minPressBar: filter.minPressBar,
                        maxPressBar: filter.maxPressBar,
                        avgPressBar: filter.avgPressBar,
                        minPressMHead: filter.minPressMHead,
                        maxPressMHead: filter.maxPressMHead,
                        avgPressMHead: filter.avgPressMHead,
                        cellId: filter.cellId,
                        freqBand: filter.freqBand,
                        temperature: filter.temperature
                    })
                };
                console.log(requestOptions)
                apiCalls.push(
                    CallPostAPI(CreateUrl("/api/aquaguard/bespokereport"), requestOptions)
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.json(); // This returns a promise that resolves with the JSON content of the response body
                        })
                        .then(data => {
                            setProcessed(combinedData.length + 1)
                            for (let j = 0; j < data.length; j++) {

                                if (data[j].batteryVoltage) {
                                    data[j].batteryVoltage /= 1000
                                }
                                if (data[j].lastStatusMessage) {
                                    data[j].lastStatusMessage = moment(data[j].lastStatusMessage).format("YYYY-MM-DD HH:mm:ss")
                                }
                                if (data[j].lastDataPoint) {
                                    data[j].lastDataPoint = moment(data[j].lastDataPoint).format("YYYY-MM-DD HH:mm:ss")
                                }
                                if (data[j].lastConnection) {
                                    data[j].lastConnection = moment(data[j].lastConnection).format("YYYY-MM-DD HH:mm:ss")
                                }
                                if (data[j].firmware) {
                                    data[j].firmware = parseFloat(data[j].firmware)
                                }
                            }
                            combinedData.push(data);
                        })
                        .catch(err => {
                            console.log(err)
                            createErrorNotification()
                            setLoading(false)
                            onExit()
                        })
                );
                counter += 1
                
            }

            Promise.all(apiCalls).then(() => {
                console.log(combinedData)
                const refactoredData = []
                const startDateClone = startDate.clone()
                let dayCounter = 0
                for (let j = 0; j < combinedData.length; j++) {
                    for (let k = 0; k < loggerIds.length; k++) {
                        if (k === 0) {
                            startDateClone.add(dayCounter, 'days')
                        }
                        combinedData[j][k].date = `${moment(combinedData[j][k].startDate).format('MMM DD YYYY')} ${moment(combinedData[j][k].startDate).format('HH:mm:ss')} to ${moment(combinedData[j][k].endDate).format('HH:mm:ss')}`
                        combinedData[j][k].minFlowTimeStampA = moment(combinedData[j][k].minFlowTimeStampA).format('HH:mm:ss')
                        combinedData[j][k].maxFlowTimeStampA = moment(combinedData[j][k].maxFlowTimeStampA).format('HH:mm:ss')
                        combinedData[j][k].minFlowTimeStampB = moment(combinedData[j][k].minFlowTimeStampB).format('HH:mm:ss')
                        combinedData[j][k].maxFlowTimeStampB = moment(combinedData[j][k].maxFlowTimeStampB).format('HH:mm:ss')
                        combinedData[j][k].minPressureCTimeStamp = moment(combinedData[j][k].minPressureCTimeStamp).format('HH:mm:ss')
                        combinedData[j][k].maxPressureCTimeStamp = moment(combinedData[j][k].maxPressureCTimeStamp).format('HH:mm:ss')
                        combinedData[j][k].minPressureDTimeStamp = moment(combinedData[j][k].minPressureDTimeStamp).format('HH:mm:ss')
                        combinedData[j][k].maxPressureDTimeStamp = moment(combinedData[j][k].maxPressureDTimeStamp).format('HH:mm:ss')
                        combinedData[j][k].minPressureETimeStamp = moment(combinedData[j][k].minPressureETimeStamp).format('HH:mm:ss')
                        combinedData[j][k].maxPressureETimeStamp = moment(combinedData[j][k].maxPressureETimeStamp).format('HH:mm:ss')
                        refactoredData.push(combinedData[j][k])
                    }
                    dayCounter = 1
                }
                refactoredData.sort((a, b) => {

                    if (a.serial < b.serial) return -1;
                    if (a.serial > b.serial) return 1;

                    if (a.startDate < b.startDate) return -1;
                    if (a.startDate > b.startDate) return 1;

                    return 0;
                });
                console.log(refactoredData)
                if (refactoredData.length > 0) {
                    

                    const headers = [];
                    headers.push({ header: "Date", key: "date", width: 30 });
                    headers.push({ header: "Serial", key: "serial", width: 15 }); // Example width for Serial column

                    for (const key in filters) {
                        if (filters[key] === true) {
                            switch (key) {
                                case "minPressMb":
                                    headers.push({ header: "Minimum Pressure Mb Chan C", key: "minPressMbC", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan C", key: "minPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure Mb Chan D", key: "minPressMbD", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan D", key: "minPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure Mb Chan E", key: "minPressMbE", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan E", key: "minPressureETimeStamp", width: 30 })
                                    break;
                                case "maxPressMb":
                                    headers.push({ header: "Maximum Pressure Mb Chan C", key: "maxPressMbC", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan C", key: "maxPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure Mb Chan D", key: "maxPressMbD", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan D", key: "maxPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure Mb Chan E", key: "maxPressMbE", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan E", key: "maxPressureETimeStamp", width: 30 })
                                    break;
                                case "avgPressMb":
                                    headers.push({ header: "Average Pressure Mb Chan C", key: "avgPressMbC", width: 30 });
                                    headers.push({ header: "Average Pressure Mb Chan D", key: "avgPressMbD", width: 30 });
                                    // headers.push({ header: "Average Pressure Mb Chan E", key: "avgPressMbE", width: 30 });
                                    break;
                                case "minPressPsi":
                                    headers.push({ header: "Minimum Pressure PSI Chan C", key: "minPressPsiC", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan C", key: "minPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure PSI Chan D", key: "minPressPsiD", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan D", key: "minPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure PSI Chan E", key: "minPressPsiE", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan E", key: "minPressureETimeStamp", width: 30 })
                                    break;
                                case "maxPressPsi":
                                    headers.push({ header: "Maximum Pressure PSI Chan C", key: "maxPressPsiC", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan C", key: "maxPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure PSI Chan D", key: "maxPressPsiD", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan D", key: "maxPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure PSI Chan E", key: "maxPressPsiE", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan E", key: "maxPressureETimeStamp", width: 30 })
                                    break;
                                case "avgPressPsi":
                                    headers.push({ header: "Average Pressure PSI Chan C", key: "avgPressPsiC", width: 30 });
                                    headers.push({ header: "Average Pressure PSI Chan D", key: "avgPressPsiD", width: 30 });
                                    headers.push({ header: "Average Pressure PSI Chan E", key: "avgPressPsiE", width: 30 });
                                    break;
                                case "minPressBar":
                                    headers.push({ header: "Minimum Pressure Bar Chan C", key: "minPressBarC", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan C", key: "minPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure Bar Chan D", key: "minPressBarD", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan D", key: "minPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure Bar Chan E", key: "minPressBarE", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan E", key: "minPressureETimeStamp", width: 30 })
                                    break;
                                case "maxPressBar":
                                    headers.push({ header: "Maximum Pressure Bar Chan C", key: "maxPressBarC", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan C", key: "maxPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure Bar Chan D", key: "maxPressBarD", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan D", key: "maxPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure Bar Chan E", key: "maxPressBarE", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan E", key: "maxPressureETimeStamp", width: 30 })
                                    break;
                                case "avgPressBar":
                                    headers.push({ header: "Average Pressure Bar Chan C", key: "avgPressBarC", width: 30 });
                                    headers.push({ header: "Average Pressure Bar Chan D", key: "avgPressBarD", width: 30 });
                                    headers.push({ header: "Average Pressure Bar Chan E", key: "avgPressBarE", width: 30 });
                                    break;
                                case "minPressMHead":
                                    headers.push({ header: "Minimum Pressure mH2O Chan C", key: "minPressMHeadC", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan C", key: "minPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure mH2O Chan D", key: "minPressMHeadD", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan D", key: "minPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Minimum Pressure mH2O Chan E", key: "minPressMHeadE", width: 30 });
                                    headers.push({ header: "Time of Min Pressure Chan E", key: "minPressureETimeStamp", width: 30 })
                                    break;
                                case "maxPressMHead":
                                    headers.push({ header: "Maximum Pressure mH2O Chan C", key: "maxPressMHeadC", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan C", key: "maxPressureCTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure mH2O Chan D", key: "maxPressMHeadD", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan D", key: "maxPressureDTimeStamp", width: 30 })
                                    headers.push({ header: "Maximum Pressure mH2O Chan E", key: "maxPressMHeadE", width: 30 });
                                    headers.push({ header: "Time of Max Pressure Chan E", key: "maxPressureETimeStamp", width: 30 })
                                    break;
                                case "avgPressMHead":
                                    headers.push({ header: "Average Pressure mH2O Chan C", key: "avgPressMHeadC", width: 30 });
                                    headers.push({ header: "Average Pressure mH2O Chan D", key: "avgPressMHeadD", width: 30 });
                                    headers.push({ header: "Average Pressure mH2O Chan E", key: "avgPressMHeadE", width: 30 });
                                    break;
                                case "batteryPer":
                                    headers.push({ header: "Battery Percentage Remaining", key: "batteryPer", width: 30 });
                                    break;
                                case "batterySaverPer":
                                    headers.push({ header: "Percentage of Connections in Saver Mode", key: "batterySaverPer", width: 30 });
                                    break;
                                case "batteryLife":
                                    headers.push({ header: "Remaining Battery Life (Years)", key: "batteryLife", width: 30 });
                                    break;
                                case "transmissionsPerDay":
                                    headers.push({ header: "Transmissions set each day", key: "transmissionFreq", width: 30 });
                                    break;
                                case "dataConnectionsMade":
                                    headers.push({ header: "% of connections made", key: "dataConnectionsPer", width: 30 });
                                    break;
                                case "coverageClass":
                                    headers.push({ header: "Sum of Class 0", key: "coverageClass0", width: 20 });
                                    headers.push({ header: "Sum of Class 1", key: "coverageClass1", width: 20 });
                                    headers.push({ header: "Sum of Class 2", key: "coverageClass2", width: 20 });
                                    break;
                                case "energyLevel":
                                    headers.push({ header: "Sum of Energy Level 1", key: "energyLevel1", width: 20 });
                                    headers.push({ header: "Sum of Energy Level 2", key: "energyLevel2", width: 20 });
                                    headers.push({ header: "Sum of Energy Level 3", key: "energyLevel3", width: 20 });
                                    headers.push({ header: "Sum of Energy Level 4", key: "energyLevel4", width: 20 });
                                    headers.push({ header: "Sum of Energy Level 5", key: "energyLevel5", width: 20 });
                                    break;
                                case "actType":
                                    headers.push({ header: "No.of NBIoT", key: "nbIoT", width: 30 });
                                    headers.push({ header: "No.of LTM", key: "ltm", width: 30 });
                                    break;
                                case "minFlowAL":
                                    headers.push({ header: "Min Flow (l/s) Channel A", key: "minFlowAL", width: 30 });
                                    headers.push({ header: "Time of Min Flow A", key: "minFlowTimeStampA", width: 30 })
                                    break
                                case "maxFlowAL":
                                    headers.push({ header: "Max Flow (l/s) Channel A", key: "maxFlowAL", width: 30 });
                                    headers.push({ header: "Time of Max Flow A", key: "maxFlowTimeStampA", width: 30 })
                                    break
                                case "avgFlowAL":
                                    headers.push({ header: "Avg Flow (l/s) Channel A", key: "avgFlowAL", width: 30 });
                                    break
                                case "minFlowBL":
                                    headers.push({ header: "Min Flow (l/s) Channel B", key: "minFlowBL", width: 30 });
                                    headers.push({ header: "Time of Min Flow B", key: "minFlowTimeStampB", width: 30 })
                                    break
                                case "maxFlowBL":
                                    headers.push({ header: "Max Flow (l/s) Channel B", key: "maxFlowBL", width: 30 });
                                    headers.push({ header: "Time of Max Flow B", key: "maxFlowTimeStampB", width: 30 })
                                    break
                                case "avgFlowBL":
                                    headers.push({ header: "Avg Flow (l/s) Channel B", key: "avgFlowBL", width: 30 });
                                    break
                                case "consumptionAL":
                                    headers.push({ header: "Consumption (l) Channel A", key: "consumptionAL", width: 30 })
                                    break
                                case "consumptionAM3":
                                    headers.push({ header: "Consumption (m3) Channel A", key: "consumptionAM3", width: 30 })
                                    break
                                case "minFlowAM3":
                                    headers.push({ header: "Min Flow (m3/h) Channel A", key: "minFlowAM3", width: 30 });
                                    headers.push({ header: "Time of Min Flow A", key: "minFlowTimeStampA", width: 30 })
                                    break
                                case "maxFlowAM3":
                                    headers.push({ header: "Max Flow (m3/h) Channel A", key: "maxFlowAM3", width: 30 });
                                    headers.push({ header: "Time of Max Flow A", key: "maxFlowTimeStampA", width: 30 })
                                    break
                                case "avgFlowAM3":
                                    headers.push({ header: "Avg Flow (m3/h) Channel A", key: "avgFlowAM3", width: 30 });
                                    break
                                case "minFlowBM3":
                                    headers.push({ header: "Min Flow (m3/h) Channel B", key: "minFlowBM3", width: 30 });
                                    headers.push({ header: "Time of Min Flow B", key: "minFlowTimeStampB", width: 30 })
                                    break
                                case "maxFlowBM3":
                                    headers.push({ header: "Max Flow (m3/h) Channel B", key: "maxFlowBM3", width: 30 });
                                    headers.push({ header: "Time of Max Flow B", key: "maxFlowTimeStampB", width: 30 })
                                    break
                                case "avgFlowBM3":
                                    headers.push({ header: "Avg Flow (m3/h) Channel B", key: "avgFlowBM3", width: 30 });
                                    break
                                case "consumptionBL":
                                    headers.push({ header: "Consumption (l) Channel B", key: "consumptionBL", width: 30 })
                                    break
                                case "consumptionBM3":
                                    headers.push({ header: "Consumption (m3) Channel B", key: "consumptionBM3", width: 30 })
                                    break
                                default:
                                    headers.push({ header: `${key.slice(0, 1).toUpperCase()}${key.slice(1)}`, key: key, width: 20 });
                                    break;
                            }
                        }
                    }

                    ///// CREATE EXPORT
                    const workbook = new ExcelJS.Workbook();
                    let worksheet = null;
                    worksheet = workbook.addWorksheet(window.location.hostname.includes('zonelog.net') ? `Bespoke Report from ZONELOG` : `Bespoke Report from Aquaguard`, { views: [{ state: 'frozen', xSplit: 0, ySplit: 2 }] });
                    worksheet.columns = headers;
                    
                        //readingsData.sort((a, b) => (a.dateStamp ? new Date(a.dateStamp).valueOf() : 0) - (b.dateStamp ? new Date(b.dateStamp).valueOf() : 0));


                    
                    worksheet.addRows(refactoredData);
                    
                
                    if (exportType == "CSV") {
                        const zip = new JSZip();
                        const promises = new Array<Promise<any>>();

                        // Generate CSV files
                        workbook.worksheets.map((sht) => {
                            const options: ExcelJS.CsvWriteOptions = {
                                sheetName: sht.name,
                                sheetId: sht.id,
                                dateUTC: true,
                            } as ExcelJS.CsvWriteOptions;
                            promises.push(workbook.csv.writeBuffer(options)
                                .then(function (buffer: Buffer) {
                                    zip.file(sht.name + ".csv", buffer.buffer);
                                })
                            );
                        });

                        Promise.all(promises).then(() => {
                            
                            if (workbook.worksheets.length > 0) {
                                zip.generateAsync({ type: "blob" }).then(function (content: Blob) {
                                    saveAs(content, window.location.hostname.includes('zonelog.net') ? 'ZONELOG Export.zip' : 'Aquaguard Export.zip' );
                                });
                            }
                        });
                        setLoading(false)
                        onExit()
                    } else {

                        // Download the workbook
                        if (workbook.worksheets.length > 0) {
                            workbook.xlsx.writeBuffer().then(function (buffer: Buffer) {
                                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), window.location.hostname.includes('zonelog.net') ? `Bespoke Report from ZONELOG.xlsx` : `Bespoke Report from Aquaguard.xlsx`);
                            });
                        }
                        setLoading(false)
                        onExit()
                    }
                }
            });
            
        }
        
    }


    
    

    

    // SETS REPORT NAME
    const handleNameChange = (e: ChangeEvent<HTMLInputElement>):void => {
        setReportName(e.target.value)
        
    }

    // MANAGES CHECKBOXES STATE FOR WHEN FILTERS SELECTED CHANGES
    const handleCheckBoxInfoChange = (name: string, value: boolean): void => {

        setCheckboxesInfo(prevState => ({
            ...prevState,
            [name]: value
        }));

    };

    const handleCheckBoxStatsChange = (name: string, value: boolean): void => {

        setCheckboxesStats(prevState => ({
            ...prevState,
            [name]: value
        }));

    };

    // IF SELECTALL IS SELECTED
    const handleSelectAllInfo = (name: string, value: boolean): void => {

        const turnOnAllChecks = { ...checkboxesInfo }
        if (value) {
            Object.keys(turnOnAllChecks).forEach(key => {
                turnOnAllChecks[key] = true
            })
            setSelectAllInfo(true)
            if (!relayPresent) {
                turnOnAllChecks.relayStatus = false
            }
        } else {
            Object.keys(turnOnAllChecks).forEach(key => {
                turnOnAllChecks[key] = false
            })
            setSelectAllInfo(false)
        }
        setCheckboxesInfo(turnOnAllChecks)
    }

    const handleSelectAllStats = (name: string, value: boolean): void => {
        console.log(relayPresent)
        const turnOnAllChecks = { ...checkboxesStats }
        if (value) {
            Object.keys(turnOnAllChecks).forEach(key => {
                
                turnOnAllChecks[key] = true
                
            })
            
            console.log(turnOnAllChecks)
            setSelectAllStats(true)
        } else {
            Object.keys(turnOnAllChecks).forEach(key => {
                turnOnAllChecks[key] = false
            })
            setSelectAllStats(false)
        }
        setCheckboxesStats(turnOnAllChecks)
    }

    //CHANGE EXPORT TYPE
    const handleExportAsType = (e: ChangeEvent<HTMLInputElement> ):void => {
        setExportType(e.target.value)
        
    }

    //CHNAGE SCHEDULE FREQUENCY
    const handleScheduleFrequency = (e: ChangeEvent<HTMLInputElement>): void => {
        setScheduleFrequency(e.target.value)
    }


    const loadTemplate = (): void => {
        const templateData = new Array<Template> ()
        CallGetAPI(CreateUrl(`/api/aquaguard/reportsforcompany?companyId=${sessionStorage.getItem('companyId')}`), {})
            .then((data) => {
                console.log(data)
                if (!data) {
                    alert("there are no templates saved")
                } else {
                    setToggleTemplateModal(!toggleTemplateModal)
                    
                    for (let i = 0; i < data.length; i++) {
                        const rec: Template = {
                            id: data[i].id,
                            companyId: data[i].companyId,
                            created: data[i].created,
                            updated: data[i].updated,
                            reportName: data[i].reportName,
                            filterWanted: data[i].filtersWanted,
                            loggerIds: data[i].loggerIds,
                            loggerSerials: data[i].loggerSerials,
                            daysSelected: data[i].daysSelected,
                            userId: data[i].userId,
                            exportType: data[i].exportType,
                            reportType: data[i].reportType
                        }
                        templateData.push(rec)
                    }
                    setTemplateList(templateData)
                    
                }
            })
                .catch(function (error) {
                    createErrorNotification();
                    console.log(error);
                });
    }


    //// RENDER MANAGEMENT FOR WHEN REPORT NOW IS SELECTED
    //const generateNow = (): void => {
    //    setNow(true)
    //    setSchedule(false)
    //    setReportSelected(true)
    //}

    //// RENDER MANAGEMENT FOR WHEN SCHEUDLE A REPORT IS SELECTED
    //const generateSchedule = (): void => {

    //    setNow(false)
    //    setSchedule(true)
    //    setReportSelected(true)
    //}


    const handleUnitFormat = (unit: string): string => {
        
        switch (unit) {
            case "l/h":
                return "L"
            case "l":
                return "L"
            case "m3/h":
                return "M3"
            case "m3":
                return "M3"
            default: 
                return unit     
        }

    }

    const combineFilters = (): Filters => {
        const filterAdd = filters
        // CONVERTS BOOLS FROM FILTER SELECTION INTO STRING OF 1'S AND 0'S - THEY ARE IN ORDER OF THE CHECKBOXES STATE
        for (const key in filterAdd) {
            filterAdd[key] = false
        }
        console.log(checkboxesStats)
        for (const key in checkboxesInfo) {
            // Check if checkboxesInfo[key] is true
            if (checkboxesInfo[key]) {
                // If filter also has the same key and its value is false, update it to true
                if (Object.prototype.hasOwnProperty.call(filterAdd, key) && !filterAdd[key]) {
                    filterAdd[key] = true;
                }
            }
        }

        for (const key in checkboxesStats) {
            // Check if checkboxesInfo[key] is true
            if (checkboxesStats[key]) {
                // If filter also has the same key and its value is false, update it to true
                if (Object.prototype.hasOwnProperty.call(filterAdd, key) && !filterAdd[key]) {
                    filterAdd[key] = true;
                }
            }
        }




        for (let i = 0; i < addedFlowOptions.length; i++) {
            const filterSelected = addedFlowOptions[i].filterSelected;
            const channelSelected = addedFlowOptions[i].channel
            const unitSelected = handleUnitFormat(addedFlowOptions[i].units)

            const adjustedUnit = `${filterSelected}${channelSelected}${unitSelected}`
            
            if (adjustedUnit && Object.prototype.hasOwnProperty.call(filterAdd, adjustedUnit)) {
                filterAdd[adjustedUnit] = true
            }
        }

        for (let i = 0; i < addedPressureOptions.length; i++) {
            const filterSelected = addedPressureOptions[i].filterSelected;
            const unitSelected = handleUnitFormat(addedPressureOptions[i].units)
            const adjustedUnit = `${filterSelected}${unitSelected}`
            if (adjustedUnit && Object.prototype.hasOwnProperty.call(filterAdd, adjustedUnit)) {
                filterAdd[adjustedUnit] = true
            }
        }

        return filterAdd
    }

    
    // POST API CALL TO SEND REPORT SCHEDULE TO DB
    const postTemplate = (): void => {

        const filterAdd = combineFilters()
        
        
        
        // VALIDATION FOR POST REQ
        let counter = 0
        let valid = false
        for (const key in filterAdd) {
            
            
            if (filterAdd[key]) {
                counter++
                
            }
            
            
        }
        if (counter && reportName && selectedLoggers.length > 0) {
            valid = true
        } else {
            alert("You have missing data points in your template")
        }
        



        
        /// API CALL
        setFilters(filterAdd)
        const body = JSON.stringify(filterAdd)
        if (newTemplate) {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    companyId: parseInt(sessionStorage.getItem('companyId') || ''),
                    reportName: reportName,
                    reportType: typeOfReport === 'Cumulative Data' ? 'cumulative' : 'daily data breakdown',
                    filtersWanted: body,
                    loggerIds: selectedLoggers.join(', '),
                    loggerSerials: deviceData.filter(item => selectedLoggers.includes(item.id)).map((logger: DeviceData) => logger.serial).join(', '),
                    daysSelected: endDate.diff(startDate, 'days'),
                    userId: parseInt(sessionStorage.getItem('userId') || ''),
                    exportType: exportType
                })
            };

            

            if (valid) {
                CallPostAPI(CreateUrl("/api/aquaguard/reportsforcompany"), requestOptions)
                    .then((data) => {
                        
                        createSuccessNotification();
                        setNewTemplate(false)
                        setTemplateLoaded(true)
                        setMessage("Template Saved")
                        setShowMessage(true)

                    })
                    .catch(function (error) {
                        createErrorNotification();
                        setMessage("Error Saving Template")
                        setShowMessage(true)
                        console.log(error);
                    });
            }
        } else {
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    companyId: parseInt(sessionStorage.getItem('companyId') || ''),
                    reportName: reportName,
                    reportType: typeOfReport === 'Cumulative Data' ? 'cumulative' : 'daily data breakdown',
                    filtersWanted: body,
                    loggerIds: selectedLoggers.join(', '),
                    loggerSerials: deviceData.filter(item => selectedLoggers.includes(item.id)).map((logger: DeviceData) => logger.serial).join(', '),
                    daysSelected: endDate.diff(startDate, 'days'),
                    userId: parseInt(sessionStorage.getItem('userId') || ''),
                    exportType: exportType
                })
            };

            

            if (valid) {
                CallPutAPI(CreateUrl(`/api/aquaguard/reportsforcompany/${loadedTemplateId}`), requestOptions)
                    .then((data) => {
                        
                        createSuccessNotification();
                        setNewTemplate(false)
                        setTemplateLoaded(true)
                        setMessage("Template Updated")
                        setShowMessage(true)

                    })
                    .catch(function (error) {
                        createErrorNotification();
                        setMessage("Error Saving Template")
                        setShowMessage(true)
                        console.log(error);
                    });
            }
        }
        
        
    };

    //NOTIFICATIONS
    const createSuccessNotification = (): void => {
        NotificationManager.success('Saved Template', 'Success')

    };

    const createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };



    //EXIT 
    const onExit = (): void => {
        onCancel()
        setNow(false)
        setSchedule(false)
        setReportSelected(false)
    }


    //CHECKS IF ALL FILTERS ARE FALSE AND DISABLES SCHEDULE BUTTON TO SEND THROUGH API
    const checkAllFalse = ():void => {
        const check = Object.values(checkboxesInfo).every(state => state === false) && Object.values(checkboxesStats).every(state => state === false)
        setAllCheckBoxesFalse(check)
    }
    
    const handleFlowConsumptionSelect = (e: ChangeEvent<HTMLInputElement>, idx: number):void => {
        const { value } = e.target;
        const flowConsumption = [...addedFlowOptions];
        if (value === 'minFlow' || value === 'maxFlow' || value === 'avgFlow' || value === 'consumption' || value === 'loss') {
            flowConsumption[idx].filterSelected = value;
            flowConsumption[idx].units = ''; 
        } else if (value === 'A' || value === 'B') {
            flowConsumption[idx].channel = value
        } else {
            flowConsumption[idx].units = value;
        }
        setAddedFlowOptions(flowConsumption);
    };

    const handlePressureSelect = (e: ChangeEvent<HTMLInputElement>, idx: number): void => {
        const { value } = e.target;
        const pressure = [...addedPressureOptions];

        if (value === 'minPress' || value === 'maxPress' || value === 'avgPress') {
            pressure[idx].filterSelected = value;
            pressure[idx].units = ''; 
        } else {
            pressure[idx].units = value;
        }
        setAddedPressureOptions(pressure);
    };
    

    
    const handleAddClick = (type: string): void => {
        if (type === "flow") {
            setAddedFlowOptions([...addedFlowOptions, { filterSelected: "", units: "", channel: ""}])
        } else {
            setAddedPressureOptions([...addedPressureOptions, { filterSelected: "", units: "" }])
        }  
    }

    const handleRemoveLogger = (id: number): void => {
        if (removeLogger) {
            removeLogger(id)
        }
    }


    const loggersTemplate = ({ data }) => {
        //const chips = []
        const serials = data.loggerSerials.split(',')
        /*const ids = data.loggerIds.split(',')
        for (let i = 0; i < ids.length; i++) {
            chips.push(<Chip color="warning" key={ids[i]} label={serials[i]} />)
        }*/
        return serials.length
    }

    const loadItem = ({ data }) => {
        
        return (
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "100%" }}>
                <Button variant="contained" size="small" color="primary" onClick={():void => handleTemplateLoad(data)}>Load</Button>

            </div>
        )
    }

    const deleteItem = ({ data }) => {

        return (
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "100%" }}>
                <Button variant="contained" size="small" color="error" onClick={(): void => handleDeleteItem(data)}>Delete</Button>

            </div>
        )
    }

    const handleDeleteItem = (data: Template): void => {
        const confirmDelete = window.confirm(intl.formatMessage({ id: "Are you sure you want to delete this template?" }))

        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
        if (confirmDelete) {
            CallDeleteAPI(CreateUrl(`/api/aquaguard/reportsforcompany?Id=${data.id}`), requestOptions)
                .then(rtn => {
                    const updateList = templateList?.filter(item => {
                        return item.id !== data.id
                    })
                    setTemplateList(updateList)
                })
                .catch(err => console.log(err))
        }
    }

    const handleTemplateLoad = (data: Template):void => {
    console.log(data)
        const pressureArray:FilterOptionPressure[] = []
        const flowArray:FilterOptionFlow[] = []
        
        const templateLoggerInfo = checkboxesInfo
        const templateLoggerStats = checkboxesStats
        const fields = JSON.parse(data.filterWanted)
        console.log(fields)
        for (const key in templateLoggerInfo) {
            templateLoggerInfo[key] = false
        }
        for (const key in templateLoggerStats) {
            templateLoggerStats[key] = false
        }
        for (const key in fields) {
            
            if (key.slice(3, 8) === 'Press' && fields[key]) {
                const pressureOption = { filterSelected: "", units: "" }
                pressureOption.filterSelected = key.slice(0, 8)
                pressureOption.units = key.slice(8)
                pressureArray.push(pressureOption)
            } else if (key.slice(3, 7) === 'Flow' && fields[key]) {
                const flowOption = { filterSelected: "", units: "", channel: "" }
                flowOption.filterSelected = key.slice(0, 7)
                console.log(key.slice(7))
                if (key.slice(7, 8) === 'A') {
                    flowOption.channel = 'A'
                } else {
                    flowOption.channel = 'B'
                }
                if (key.slice(8) === 'L') {
                    flowOption.units = 'l/h'
                } else {
                    flowOption.units = 'm3/h'
                }

                flowArray.push(flowOption)
            } else if (key.slice(0, 11) === 'consumption' && fields[key]) {
                const flowOption = { filterSelected: "", units: "", channel: "" }
                flowOption.filterSelected = key.slice(0, 11)
                if (key.slice(11, 12) === 'A') {
                    flowOption.channel = 'A'
                } else {
                    flowOption.channel = 'B'
                }
                if (key.slice(12) === 'L') {
                    flowOption.units = 'l'
                } else {
                    flowOption.units = 'm3'
                }
                flowArray.push(flowOption)
            } 
                
            
            if (fields[key]) {
                
                // If filter also has the same key and its value is false, update it to true
                if (Object.prototype.hasOwnProperty.call(templateLoggerInfo, key) && !templateLoggerInfo[key]) {
                    templateLoggerInfo[key] = true;
                }
                if (Object.prototype.hasOwnProperty.call(templateLoggerStats, key) && !templateLoggerStats[key]) {
                    templateLoggerStats[key] = true;
                }
                
            }
        }
        
        const infoStatsAllSelected = Object.values(templateLoggerInfo).every(value => value === true)
        if (infoStatsAllSelected) {
            setSelectAllInfo(true)
        }
        console.log(Object.values(templateLoggerInfo), infoStatsAllSelected)
        let allSelectedExceptRelay = true
        for (let i = 0; i < Object.values(templateLoggerInfo).length; i++) {
            const relay = 10
            if (Object.values(templateLoggerInfo)[i] === false && i !== relay) {
                console.log('hit')
                allSelectedExceptRelay = false
            }
        }
        setSelectAllInfo(allSelectedExceptRelay)
        if (Object.values(templateLoggerStats).every(value => value === true)) {
            setSelectAllStats(true)
        }
        


        if (flowArray.length === 0) {
            flowArray.push({ filterSelected: "", units: "", channel: "" })
        }
        if (pressureArray.length === 0) {
            pressureArray.push({ filterSelected: "", units: "" })
        }
            

        const loggers = data.loggerIds.split(", ").map((item: string) => parseInt(item))
        setExportType(data.exportType)
        setStartDate(moment().subtract(data.daysSelected, "days").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
        setAddedFlowOptions(flowArray)
        setAddedPressureOptions(pressureArray)
        setSelectedLoggers(loggers)
        setCheckboxesStats(templateLoggerStats)
        setCheckboxesInfo(templateLoggerInfo)
        setReportName(data.reportName)
        setToggleTemplateModal(!toggleTemplateModal)
        setTemplateLoaded(true)
        setNewTemplate(false)
        setLoadedTemplateId(data.id)
        setTypeOfReport(data.reportType === 'cumulative' ? 'Cumulative Data' : 'Daily Data Breakdown')
    }

    const filterInTemplate = ({ data }) => {
        let displayData = ""
        const fields = JSON.parse(data.filterWanted)
        
        
        for (const key in fields) {
            // Check if checkboxesInfo[key] is true
            if (fields[key]) {
                displayData += `${key}, `
                }
        }
        displayData = displayData.slice(0, displayData.length-2)

        return displayData
    }

    const handleDateChange = (start: Date, end: Date, rainfall: boolean): void => {
        
        const updatedEnd = moment(end).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate();
        console.log(!moment(end).isSame(moment(), 'day'));
        if (!moment(end).isSame(moment(), 'day') && typeOfReport === "Cumulative Data") {
            end = updatedEnd;
       
        } 
        console.log(start, end)
        setStartDate(moment(start));
        setEndDate(moment(end));
    };


    const resetTemplate = ():void => {
        setNewTemplate(false)
        setTemplateLoaded(false)
        setAddedFlowOptions([{ filterSelected: "", units: "" }])
        setAddedPressureOptions([{ filterSelected: "", units: "" }])
        setReportName("")
        setTypeOfReport("Cumulative Data")
        setStartDate(moment().subtract(7, "days").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
        setEndDate(moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }))
        const info = checkboxesInfo
        const stats = checkboxesStats

        for (const key in info) {
            info[key] = false
        }
        for (const key in stats) {
            stats[key] = false
        }
        setCheckboxesInfo(info)
        setCheckboxesStats(stats)
    }

    const handleReportTypeChange = (e):void => {
        setTypeOfReport(e.value)
    }

    




    
    

    ///USEEFFECTS

    // USEEFFECT TO CHECK IF FILTERSHAVE BEEN SELECTED OR NOT 
    useEffect(() => {
        checkAllFalse()
    }, [checkboxesInfo, checkboxesStats])



    /// INFO MESSAGE TIMER
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMessage(false);
        }, 5000);
        return ():void => clearTimeout(timer)
    }, [showMessage])

    useEffect(() => {
        if (selectedLoggers && selectedLoggers.length === 0 && !templateClicked) {
            const timer = setTimeout(() => {
                setShowMessage(false);
            }, 5000);
            return (): void => {
                clearTimeout(timer)
                onExit();
            }
        }
        setLoading(false);
    }, [selectedLoggers])


    useEffect(() => {
        setLoading(true)
        if (selectedLoggers && selectedLoggers.length === 0) {
            loadTemplate();
        }
        let loggers = []
        if (selectedLoggers && selectedLoggers.length > 0) {
            loggers = selectedLoggers.map((item: number) => {
                return { id: item }
            })
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                loggers
            )
        };

        locale(sessionStorage.getItem("language"));
        loadMessages(frMessages);
        loadMessages(deMessages);
        loadMessages(esMessages);
        loadMessages(ptMessages);

        CallPostAPI(CreateUrl("/api/aquaguard/LoggerRelayByIdList"), requestOptions)
            .then(response => {
                return response.json(); // This returns a promise that resolves with the JSON content of the response body
            })
            .then(data => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].relayStatus !== null) {
                        setRelayPresent(true)
                    }
                }
            })
            .then(setLoading(false))
            .catch(err => console.log(err))
    }, [])

    


    
    
    




    ///STYLES
    const overViewStyle = {
        
        borderRadius: "5px",
        padding: "10px",
        
        boxShadow: "2px 2px 10px #00000060",
        marginBottom: "30px",
        
    }
    


    //RENDER
    return (
        <>
            {loading &&
                <div style={{
                    position: 'fixed', left: '50%', top: '250px',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1300,
                    textAlign: "center",
                    background: "#fff",
                    padding: "2%"

                }}>
                    <h4 style={{ color: "#123abc", backgroundColor: "fff" }}>{(endDate.diff(startDate, 'days') > 30 || selectedLoggers.length > 50) ? 'Preparing your export... Reports with large numbers of loggers and/or a wide date range can take some time, please be patient. Your report will download automatically once it is ready' : 'Preparing your export...'}</h4>
                    <p style={{ color: "#123abc"}}>Processed days so far: {processed} </p>
                    <ClipLoader
                        size={150}
                        color={"#123abc"}
                        loading={loading}
                    />
                </div>
            }
            {!loading && <div>
            <Modal
                show={toggleTemplateModal}
                style={{
                    zIndex: 10001, 
                    width: "90%",
                    height: "60%",
                    position: "absolute",
                    left: "5%",
                    top: "20%"
                    

                }}
                //onHide={():void => setToggleTemplateModal(!toggleTemplateModal)}
            >
                <div>
                    <Modal.Header>
                            <Modal.Title><FormattedMessage id="Load Template" /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DataGrid
                            allowColumnResizing={true}
                            allowColumnReordering={true}
                            dataSource={templateList}
                            //keyExpr="id"
                        >
                            <Column dataField="id" visible={false} dataType="number" />
                            <Column dataField="loggerIds" visible={false} dataType="number" />
                            <Column dataField="userId" visible={false} dataType="number" />
                            <Column dataField="reportName" dataType="string" caption={intl.formatMessage({ id: "Template Name" })} />
                                <Column dataField="reportType" dataType="string" caption={intl.formatMessage({ id: "Report Type" })} />
                                <Column dataField="exportType" dataType="string" caption={intl.formatMessage({ id: "Export Type" })} />
                                <Column dataField="filterWanted" cellRender={filterInTemplate} caption={intl.formatMessage({ id: "Data Included" })} />
                                <Column dataField="created" dataType="string" caption={intl.formatMessage({ id: "Created" })} />
                                <Column dataField="updated" dataType="string" caption={intl.formatMessage({ id: "Updated" })}  />
                                <Column dataField="loggerSerials" cellRender={loggersTemplate} caption={intl.formatMessage({ id: "Loggers in report" })} width="150" />
                                <Column dataField="daysSelected" caption={intl.formatMessage({ id: "Date range of report" })} />
                            <Column dataField="load" width="100" caption={<FormattedMessage id="Load" />} cellRender={loadItem} allowFiltering={false} allowHeaderFiltering={true} />
                            <Column dataField="delete" width="100" caption={<FormattedMessage id="Delete" />} cellRender={deleteItem} allowFiltering={false} allowHeaderFiltering={true} />
                            </DataGrid>
                    </Modal.Body>
                </div>
                </Modal>
            {<div style={{filter: toggleTemplateModal ? "blur(5px)": "none"}}>
                <Modal.Header>

                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <GridItem>

                            <Modal.Title id="example-custom-modal-styling-title">
                                {modalTitle}
                            </Modal.Title>

                        </GridItem>
                        <GridItem>
                                <Button color="warning" variant="contained" onClick={(): void => loadTemplate()}><FormattedMessage id="Load Template" /></Button>
                                <Button color="primary" style={{ marginLeft: "10px" }} variant="contained" onClick={(): void => resetTemplate()}><FormattedMessage id="Reset Filters" /></Button>
                        </GridItem>
                    </div>

                </Modal.Header>
                <Modal.Body className="show-grid">
                    <GridItem>
                            <h4 style={{ fontSize: "22px" }}><FormattedMessage id="Report information" /></h4>
                    </GridItem>
                    <GridItem>
                            <div style={{ borderLeft: "10px solid #000000", width: "100%", marginRight: "5px", backgroundImage: "linear-gradient(30deg, #f7f7f7, #f7f7f7 71%, #808080)", ...overViewStyle }}>
                                <h5 style={{ margin: "0 0 10px 15px" }}><b><FormattedMessage id="Selected Loggers" /></b></h5>
                                <div style={{ margin: "0 0 10px 15px" }}>{deviceData.filter(item => selectedLoggers.includes(item.id)).map((logger: DeviceData) => {
                                    return !fromGroup ? <Chip key={logger.id} label={logger.serial} onDelete={(): void => handleRemoveLogger(logger.id)} color="secondary" style={{ margin: "2px", color: '#ffffff' }} /> :
                                        <Chip key={logger.id} label={logger.serial} color="secondary" style={{ margin: "2px", color: '#ffffff' }} />
                            })}
                            </div>

                            {/*{!reportSelected && !now && */}
                            {/*    <div style={{ display: 'flex', justifyContent: 'space-around' }}>*/}
                            {/*        <div style={{ display: 'flex' }}>*/}
                            {/*            <Button color="primary" style={{ margin: "10px" }} variant="contained" onClick={(): void => generateNow()}>Generate report now</Button>*/}
                            {/*            <Button color="secondary" style={{ margin: "10px" }} variant="contained" onClick={(): void => generateSchedule()}>Create regularised report template</Button>*/}
                            {/*        </div>*/}
                            {/*    </div>}*/}

                            {now && reportSelected &&

                                <GridItem>
                                        <DateRange type={"datetime"} startDate={moment(startDate).toDate()} endDate={moment(endDate).toDate()} onDateChange={handleDateChange} rainfall={eaSiteId > 0} showButton={false} handleRedrawClick={null} />
                                </GridItem>
                                }
                                <div style={{margin: "0 0 10px 15px"} }>
                                    <h5 style={{ fontSize: "18px" }}><FormattedMessage id="Type of report" /></h5>
                                    <div style={{display: "flex"} }>
                                    <RadioGroup items={reportTypes} onValueChanged={handleReportTypeChange} value={typeOfReport} layout="horizontal" />
                                        {typeOfReport !== "Cumulative Data" && <p style={{ margin: "0 0 0 20px", fontSize: "18px" }}><FormattedMessage id="Warning : If you change a start or end time, this time range will be applied to each of the day's data in the report" /></p>}
                                    </div>
                                </div>
                        </div>
                        </GridItem>
                        
                    {reportSelected &&
                        <div>
                            <GridItem>
                                <h4 style={{fontSize: "22px"} }><FormattedMessage id="Filters" /></h4>
                            </GridItem>
                        </div>}
                    
                    {reportSelected &&
                        <GridItem>
                            <div style={{ display: "flex" }}>
                                <div style={{ display: "flex", borderLeft: "10px solid #00BF00", width: "50%", marginRight: "5px", backgroundImage: "linear-gradient(30deg, #f7f7f7, #f7f7f7 45%, #00BF00)", ...overViewStyle }}>
                                    <div style={{ minWidth: "75%" }}>
                                        <GridItem>
                                                <h5 style={{ fontSize: "18px" }}><b><FormattedMessage id="Device information" /></b></h5>
                                        </GridItem>
                                        <GridItem>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ display: "flex", flexDirection: "column", marginRight: "2%" }}>
                                                    <CheckBox value={selectAllInfo} text={intl.formatMessage({ id: "Select all" })} onValueChange={(e): void => handleSelectAllInfo('selectAll', e)} style={{ margin: "5px 0", fontWeight: "800" }} />
                                                    <CheckBox value={checkboxesInfo.model} text={intl.formatMessage({ id: "Model" })} onValueChange={(e): void => handleCheckBoxInfoChange('model', e)} style={{ margin: "5px 0" }} />
                                                    <CheckBox value={checkboxesInfo.deployed} text={intl.formatMessage({ id: "Deployed" })} onValueChange={(e): void => handleCheckBoxInfoChange('deployed', e)} style={{ margin: "5px 0" }} />
                                                    <CheckBox value={checkboxesInfo.firmware} text={intl.formatMessage({ id: "Current firmware version" })} onValueChange={(e): void => handleCheckBoxInfoChange('firmware', e)} style={{ margin: "5px 0" }} />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", marginRight: "2%" }}>
                                                    <CheckBox value={checkboxesInfo.siteName} text={intl.formatMessage({ id: "Site name" })} onValueChange={(e): void => handleCheckBoxInfoChange('siteName', e)} style={{ margin: "5px 0" }} />
                                                    <CheckBox value={checkboxesInfo.siteRef} text={intl.formatMessage({ id: "Site reference" })} onValueChange={(e): void => handleCheckBoxInfoChange('siteRef', e)} style={{ margin: "5px 0" }} />
                                                    <CheckBox value={checkboxesInfo.deviceSiteName} text={intl.formatMessage({ id: "Device site name" })} onValueChange={(e): void => handleCheckBoxInfoChange('deviceSiteName', e)} style={{ margin: "5px 0" }} />
                                                    <CheckBox value={checkboxesInfo.gps} text={intl.formatMessage({ id: "GPS co-ordinates" })} onValueChange={(e): void => handleCheckBoxInfoChange('gps', e)} style={{ margin: "5px 0" }} />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", marginRight: "2%" }}>
                                                    <CheckBox value={checkboxesInfo.energyLevel} text={intl.formatMessage({ id: "Energy level" })} onValueChange={(e): void => handleCheckBoxInfoChange('energyLevel', e)} style={{ margin: "5px 0" }} />
                                                    <CheckBox value={checkboxesInfo.coverageClass} text={intl.formatMessage({ id: "Coverage class" })} onValueChange={(e): void => handleCheckBoxInfoChange('coverageClass', e)} style={{ margin: "5px 0" }} />
                                                    <CheckBox value={checkboxesInfo.actType} text={intl.formatMessage({ id: "Network type" })} onValueChange={(e): void => handleCheckBoxInfoChange('actType', e)} style={{ margin: "5px 0" }} />
                                                    <CheckBox value={checkboxesInfo.cellId} text={intl.formatMessage({ id: "Cell ID" })} onValueChange={(e): void => handleCheckBoxInfoChange('cellId', e)} style={{ margin: "5px 0" }} />
                                                    
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <CheckBox value={checkboxesInfo.freqBand} text={intl.formatMessage({ id: "Frequency Band" })} onValueChange={(e) => handleCheckBoxInfoChange('freqBand', e)} style={{ margin: "5px 0" }} />
                                                    <CheckBox value={checkboxesInfo.temperature} text={intl.formatMessage({ id: "Internal Temperature" })} onValueChange={(e) => handleCheckBoxInfoChange('temperature', e)} style={{ margin: "5px 0" }} />
                                                    {relayPresent && <CheckBox value={checkboxesInfo.relayStatus} text={intl.formatMessage({ id: "Relay status" })} onValueChange={(e): void => handleCheckBoxInfoChange('relayStatus', e)} style={{ margin: "5px 0" }} />}
                                                </div>

                                            </div>
                                        </GridItem>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                        <i className="fa fa-circle-info " aria-hidden="true" style={{ fontSize: "100px", color: "#f7f7f7" }}></i>
                                    </div>
                                </div>


                                    {console.log(combineFilters()) }

                                    



                                <div style={{ display: "flex", borderLeft: "10px solid #EED202", width: "50%", marginLeft: "5px", backgroundImage: "linear-gradient(30deg, #f7f7f7, #f7f7f7 45%, #EED202)", ...overViewStyle }}>
                                    <div style={{ minWidth: "75%" }}>
                                            <GridItem>
                                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                                    <h5 style={{ fontSize: "18px" }}><b><FormattedMessage id="Device Stats" /></b></h5>
                                                    <div style={{ display: "flex" }}>
                                                        {!window.location.hostname.includes('zonelog.net') && <p style={{ fontSize: "12px", margin: "auto 44px" }}><FormattedMessage id=" * Only available on CharIot Loggers" /></p>}
                                                    </div>
                                                </div>
                                            </GridItem>
                                        <GridItem>
                                            <div style={{ display: "flex" }}>

                                                <div style={{ display: "flex", flexDirection: "column", marginRight: "2%" }}>
                                                    <CheckBox value={selectAllStats} text={intl.formatMessage({ id: "Select all" })} onValueChange={(e): void => handleSelectAllStats('selectAll', e)} style={{ margin: "5px 0", fontWeight: "800" }} />
                                                    <CheckBox value={checkboxesStats.currentSignal} text={intl.formatMessage({ id: "Current signal" })} onValueChange={(e): void => handleCheckBoxStatsChange('currentSignal', e)} style={{ margin: "5px 0" }} />
                                                    <CheckBox value={checkboxesStats.lastConnection} text={intl.formatMessage({ id: "Last connected" })} onValueChange={(e): void => handleCheckBoxStatsChange('lastConnection', e)} style={{ margin: "5px 0" }} />
                                                    <CheckBox value={checkboxesStats.dataConnectionsMade} text={window.location.hostname.includes('zonelog.net') ? intl.formatMessage({ id: "% Data connections made" }) : intl.formatMessage({ id: "% Data connections made*" })} onValueChange={(e): void => handleCheckBoxStatsChange('dataConnectionsMade', e)} style={{ margin: "5px 0" }} />
                                                        
                                                   
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", marginRight: "2%" }}>

                                                        <CheckBox value={checkboxesStats.batteryVoltage} text={intl.formatMessage({ id: "Battery voltage" })} onValueChange={(e): void => handleCheckBoxStatsChange('batteryVoltage', e)} style={{ margin: "5px 0" }} /> 
                                                        <CheckBox value={checkboxesStats.batteryPer} text={window.location.hostname.includes('zonelog.net') ? intl.formatMessage({ id: "Remaining battery %" }) : intl.formatMessage({ id: "Remaining battery %*" })} onValueChange={(e): void => handleCheckBoxStatsChange('batteryPer', e)} style={{ margin: "5px 0" }} /> 
                                                        <CheckBox value={checkboxesStats.batterySaverPer} id="batterySaverPer" text={window.location.hostname.includes('zonelog.net') ? intl.formatMessage({ id: "% Connections in saver mode" }) : intl.formatMessage({ id: "% Connections in saver mode*" })} onValueChange={(e): void => handleCheckBoxStatsChange('batterySaverPer', e)} style={{ margin: "5px 0" }} />
                                                        <CheckBox value={checkboxesStats.batteryLife} text={window.location.hostname.includes('zonelog.net') ? intl.formatMessage({ id: "Estimated lifespan left" }) : intl.formatMessage({ id: "Estimated lifespan left*" })} onValueChange={(e): void => handleCheckBoxStatsChange('batteryLife', e)} style={{ margin: "5px 0" }} />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <CheckBox value={checkboxesStats.transmissionsPerDay} text={intl.formatMessage({ id: "Transmissions per day" })} onValueChange={(e): void => handleCheckBoxStatsChange('transmissionsPerDay', e)} style={{ margin: "5px 0" }} /> 
                                                        <CheckBox value={checkboxesStats.lastDataPoint} text={intl.formatMessage({ id: "Last data point" })} onValueChange={(e): void => handleCheckBoxStatsChange('lastDataPoint', e)} style={{ margin: "5px 0" }} />
                                                        <CheckBox value={checkboxesStats.lastStatusMessage} text={intl.formatMessage({ id: "Last status message" })} onValueChange={(e): void => handleCheckBoxStatsChange('lastStatusMessage', e)} style={{ margin: "5px 0" }} />
                                                        <CheckBox value={checkboxesStats.lastMessageType} text={intl.formatMessage({ id: "Last message type" })} onValueChange={(e): void => handleCheckBoxStatsChange('lastMessageType', e)} style={{ margin: "5px 0" }} />
                                                </div>
                                                </div>
                                                {checkboxesStats.dataConnectionsMade && endDate.format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') && <h6 style={{marginTop: "5px", color: 'black', fontSize: "15px", textTransform: "lowercase"} }><FormattedMessage id="% of data connections made will not be accurate if the end date is today" /></h6>}
                                        </GridItem>
                                        
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                        <i className="fa fa-chart-simple " aria-hidden="true" style={{ fontSize: "100px", color: "#f7f7f7" }}></i>
                                    </div>
                                </div>
                            </div>


                            <div style={{ display: "flex" }}>
                                <div style={{ display: "flex", borderLeft: "10px solid #009fe3", width: "50%", marginRight: "5px", backgroundImage: "linear-gradient(30deg, #f7f7f7, #f7f7f7 45%, #009fe3)", ...overViewStyle }}>
                                    <div style={{ minWidth: "75%" }}>
                                        <GridItem>
                                            <div>
                                                <h5 style={{ fontSize: "18px" }}><b><FormattedMessage id="Flow and Consumption" /></b></h5>
                                                <DevButton icon="plus" onClick={(): void => handleAddClick('flow')} disabled={ (addedFlowOptions[addedFlowOptions.length - 1].units === "") || (!addedFlowOptions[addedFlowOptions.length - 1].filterSelected) } />
                                                
                                            </div>
                                        </GridItem>
                                        <GridItem>
                                            <div style={{ width: "100%", margin: "8px 0" }}>
                                                {addedFlowOptions.map((option, idx) => (
                                                    <div key={idx} style={{ display: "flex", margin: "7px 7px 7px 0" }}>
                                                        
                                                        <Select
                                                            size="small"
                                                            style={{ minWidth: "200px", maxWidth: "200px", backgroundColor: "#ffffff" }}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>): void => handleFlowConsumptionSelect(e, idx)}
                                                            value={addedFlowOptions[idx].filterSelected}
                                                        >
                                                            <MenuItem value="minFlow"><FormattedMessage id="Minimum Flow" /></MenuItem>
                                                            <MenuItem value="maxFlow"><FormattedMessage id="Maximum Flow" /></MenuItem>
                                                            <MenuItem value="avgFlow"><FormattedMessage id="Average Flow" /></MenuItem>
                                                            <MenuItem value="consumption"><FormattedMessage id="Consumption" /></MenuItem>
                                                            
                                                        </Select>
                                                        
                                                        {/* Conditionally render the second Select based on the value of filterSelected */}
                                                        {addedFlowOptions[idx].filterSelected !== 'consumption' && addedFlowOptions[idx].filterSelected !== 'loss' && addedFlowOptions[idx].filterSelected && (
                                                            <div style={{display: "flex"} }>
                                                                <h5 style={{ margin: "7px 10px 0 10px" }}><FormattedMessage id="in" /></h5>
                                                                <Select
                                                                    size="small"
                                                                    style={{ minWidth: "200px", maxWidth: "200px", backgroundColor: "#ffffff" }}
                                                                    onChange={(e: ChangeEvent<HTMLInputElement>): void => handleFlowConsumptionSelect(e, idx)}
                                                                    value={addedFlowOptions[idx].units}
                                                                >
                                                                    <MenuItem value="l/h"><FormattedMessage id="litres/second" /></MenuItem>
                                                                    <MenuItem value="m3/h"><FormattedMessage id="meters" /><sup>3</sup><FormattedMessage id="/hour" /></MenuItem>
                                                                </Select>
                                                            </div>
                                                        )}
                                                        {addedFlowOptions[idx].filterSelected === 'consumption' && addedFlowOptions[idx].filterSelected && (
                                                            <div style={{ display: "flex" }}>
                                                                <h5 style={{ margin: "7px 10px 0 10px" }}><FormattedMessage id="in" /></h5>
                                                                <Select
                                                                    size="small"
                                                                    style={{ minWidth: "200px", maxWidth: "200px", backgroundColor: "#ffffff" }}
                                                                    onChange={(e: ChangeEvent<HTMLInputElement>): void => handleFlowConsumptionSelect(e, idx)}
                                                                    value={addedFlowOptions[idx].units}
                                                                >
                                                                    <MenuItem value="l"><FormattedMessage id="litres" /></MenuItem>
                                                                    <MenuItem value="m3"><FormattedMessage id="meters" /><sup>3</sup></MenuItem>
                                                                </Select>
                                                            </div>
                                                        )}
                                                        {addedFlowOptions[idx].units && <div style={{ display: "flex" }}>
                                                            <h5 style={{ margin: "7px 10px 0 10px" }}><FormattedMessage id="on" /></h5>
                                                            <Select
                                                                size="small"
                                                                style={{ minWidth: "200px", maxWidth: "200px", backgroundColor: "#ffffff" }}
                                                                onChange={(e: ChangeEvent<HTMLInputElement>): void => handleFlowConsumptionSelect(e, idx)}
                                                                value={addedFlowOptions[idx].channel}
                                                            >
                                                                <MenuItem value="A">{window.location.hostname.includes('zonelog.net') ? <FormattedMessage id="Flow A" /> : <FormattedMessage id="Channel A" />}</MenuItem>
                                                                <MenuItem value="B">{window.location.hostname.includes('zonelog.net') ? <FormattedMessage id="Flow B" /> : <FormattedMessage id="Channel B" />}</MenuItem>
                                                            </Select>
                                                        </div>}
                                                        
                                                    </div>
                                                ))}
                                            </div>
                                        </GridItem>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                        <i className="fa fa-water " aria-hidden="true" style={{ fontSize: "100px", color: "#f7f7f7" }}></i>
                                    </div>
                                </div>










                                <div style={{ display: "flex", borderLeft: "10px solid #6f2da8", width: "50%", marginLeft: "5px", backgroundImage: "linear-gradient(30deg, #f7f7f7, #f7f7f7 45%, #6f2da8)", ...overViewStyle }}>
                                    <div style={{ minWidth: "75%" }}>
                                        <GridItem>
                                            <div>
                                                    <h5 style={{ fontSize: "18px" }}><b><FormattedMessage id="Pressure" /></b></h5>
                                                <DevButton icon="plus" onClick={(): void => handleAddClick('pressure')} disabled={(addedPressureOptions[addedPressureOptions.length - 1].units === "") || (!addedPressureOptions[addedPressureOptions.length - 1].filterSelected)} />
                                            </div>
                                        </GridItem>

                                        <GridItem>
                                            <div style={{ width: "100%", margin: "8px 0" }}>
                                                {addedPressureOptions.map((option, idx) => (
                                                    <div key={idx} style={{ display: "flex", margin: "7px 7px 7px 0" }}>
                                                        <Select
                                                            size="small"
                                                            style={{ width: "50%", backgroundColor: "#ffffff" }}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>): void => handlePressureSelect(e, idx)}
                                                            label="pressure"
                                                            value={addedPressureOptions[idx].filterSelected}
                                                        >
                                                            <MenuItem value="minPress"><FormattedMessage id="Minimum Pressure" /></MenuItem>
                                                            <MenuItem value="maxPress"><FormattedMessage id="Maximum Pressure" /></MenuItem>
                                                            <MenuItem value="avgPress"><FormattedMessage id="Average Pressure" /></MenuItem>

                                                        </Select>

                                                        {addedPressureOptions[idx].filterSelected && (
                                                            <div style={{ display: "flex", width: "100%" }}>
                                                                <h5 style={{ margin: "7px 10px 0 10px" }}>in</h5>
                                                                <Select
                                                                    size="small"
                                                                    style={{ width: "50%", backgroundColor: "#ffffff" }}
                                                                    onChange={(e: ChangeEvent<HTMLInputElement>): void => handlePressureSelect(e, idx)}
                                                                    value={addedPressureOptions[idx].units}
                                                                >
                                                                    <MenuItem value="Mb">mb</MenuItem>
                                                                    <MenuItem value="Psi">psi</MenuItem>
                                                                    <MenuItem value="Bar">bar</MenuItem>
                                                                    <MenuItem value="MHead">mH2O<sub></sub></MenuItem>
                                                                </Select>
                                                            </div>
                                                        )}

                                                    </div>
                                                ))}
                                            </div>
                                        </GridItem>
                                        
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                        <i className="fa fa-gauge " aria-hidden="true" style={{ fontSize: "100px", color: "#f7f7f7" }}></i>
                                    </div>
                                </div>
                            </div>

                            

                            
                            {schedule &&
                                <div style={{ marginTop: "20px", width: "100%" }}>
                                        <h4 style={{ marginBottom: "10px", fontSize: "22px" }}>Schedule reports</h4>
                                        {/*NOT IN USE ATM */}
                                    <Select
                                        size="small"
                                        aria-label="scheduleAs"
                                        name="scheduleAs"
                                        value={scheduleFrequency}
                                        onChange={(e: ChangeEvent<HTMLInputElement>): void => handleScheduleFrequency(e)}
                                        label="Schedule report for"
                                        style={{ width: 300 }}
                                    >
                                        <MenuItem value="daily">Daily (00:00 - 23:59)</MenuItem>
                                        <MenuItem value="weekly">Weekly (Monday 00:00 - Sunday 23:59)</MenuItem>
                                        <MenuItem value="monthly">Monthly (1<sup>st</sup>   Day of month 00:00 - Last Day of month 23:59)</MenuItem>
                                        <MenuItem value="yearly">Yearly (1<sup>st</sup> January 00:00 - 31st December 23:59) </MenuItem>
                                    </Select>
                                </div>}

                        </GridItem>}

                </Modal.Body>
                <Modal.Footer>
                    <div style={{ width: "100%" }}>
                        <GridItem>
                                <h4 style={{ fontSize: "22px" }}><FormattedMessage id="Export" /></h4>
                        </GridItem>
                        <GridItem>
                            <div style={{ borderLeft: "10px solid #000000", width: "100%", margin: "0 5px 0 0", backgroundImage: "linear-gradient(30deg, #f7f7f7, #f7f7f7 71%, #808080)", ...overViewStyle }}>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                    {now &&
                                        <GridItem>
                                            <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                                    <h5 style={{ margin: "0 10px 0 0", fontSize: "18px" }}><b><FormattedMessage id="Export" /> {typeOfReport} <FormattedMessage id="Report as" /></b></h5>
                                                <Select
                                                    size="small"
                                                    aria-label="exportAs"
                                                    name="exportAs"
                                                    value={exportType}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>): void => handleExportAsType(e)}
                                                    label="Export Type"
                                                    style={{ width: 300, backgroundColor: "#ffffff" }}
                                                >
                                                    <MenuItem value="CSV">CSV</MenuItem>
                                                    <MenuItem value="XLSX">XLSX</MenuItem>
                                                        {typeOfReport == 'Cumulative Data' && <MenuItem value="XLSXT">XLSX Table <FormattedMessage id="Filter options enabled" /></MenuItem>}
                                                </Select>
                                            </div>
                                        </GridItem>}
                                    

                                    <div style={{display: "flex", width: "55%", justifyContent: "space-between"}} >
                                            {newTemplate && <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                                <h5 style={{ fontSize: "18px", margin: "0  10px 0 0" }}><b><FormattedMessage id="Template Name" />: <sup>*</sup></b></h5>
                                            <TextField
                                                size="small"
                                                style={{ width: 300, backgroundColor: "#ffffff" }}
                                                value={reportName}
                                                onChange={(e: ChangeEvent<HTMLInputElement>):void => handleNameChange(e)}
                                            >
                                            </TextField>
                                        </div>}
                                        
                                            
                                        
                                    <div style={{ display: "flex", height: "40px", margin: newTemplate ? "auto 0" : "auto 0 auto auto" }}>
                                        
                                            {/*sessionStorage.getItem("companyId") === "0" && <p style={{margin: "auto 0"} }><b>To schedule a report you need to impersonate a company user</b></p>*/}
                                            {showMessage && <h5 style={{ margin: "auto", fontSize: "18px" }}><b>{message}</b></h5>}
                                            {now && <Button color="primary" disabled={newTemplate} variant="contained" style={{ margin: "0 10px" }} onClick={(): void => generateReport()}><FormattedMessage id="Generate Report" /></Button>}
                                            {!templateLoaded && <Button color="warning" variant="contained" style={{ margin: "0 10px 0 0 " }} onClick={(): void => newTemplate ? postTemplate() : setNewTemplate(true)}>{newTemplate ? <FormattedMessage id="Save Template" /> : <FormattedMessage id="Create Template"/>}</Button>}
                                                {templateLoaded && <Button color="warning" variant="contained" style={{ margin: "0 10px 0 0 " }} onClick={(): void => postTemplate()}><FormattedMessage id="Update Template" /></Button>}
                                        
                                                <Button color="secondary" variant="contained" onClick={(): void => onExit()}><FormattedMessage id="Exit" /></Button>
                                        </div>
                                    </div>
                                        
                                    </div>
                            </div>
                            </GridItem>
                    </div>
                </Modal.Footer>
            </div>}
            </div>}
        </>
    )
}
export default ReportScheduler