const GutermannModelConverter = (model:string):string => {
    let label = ""
    //console.log(model)
    switch (model) {
        case "CHFL":
        case "CHAR-E-F":
        case "CHAR-I-F":
        case "ZONE-E-F":
        case "ZONE-E-F-NB":
            label = "ZL-2F"
            break
        case "CHF1":
        case "CHAR-E-F1P-X":
        case "CHAR-I-F1P-X":
        case "ZONE-E-F1P-X":
        case "ZONE-E-F1P-X-NB":
        case "CHRT":
            label = "ZL-2F-1P"
            break
        case "CHF2":
        case "CHAR-E-F2P-X":
        case "CHAR-I- F2P-X":
        case "ZONE-E-F2P-X":
        case "ZONE-E-F2P-X-NB":
            label = "ZL-2F-2P"
            break
        case "CH1P":
        case "CHAR-E-1P-X":
        case "CHAR-I-1P-X":
        case "ZONE-E-1P-X":
        case "ZONE-I-1P-X":
            label = "ZL-1P"
            break
        case "CH2P":
        case "CHAR-E-2P-X":
        case "CHAR-I-2P-X":
        case "ZONE-E-2P-X":
        case "ZONE-I-2P-X":
            label = "ZL-2P" 
            break
        default:
            label = model
    }
    return label
    
}



const GutermannModelDeConverter = (model: string):string => {
    let label = ""
    //console.log(model)
    switch (model) {
        case "ZL-2F":
        case "CHAR-E-F":
        case "CHAR-I-F":
        case "ZONE-E-F":
        case "ZONE-E-F-NB":
            label = "CHFL"
            break
        case "ZL-2F-1P":
        case "CHAR-E-F1P-X":
        case "CHAR-I-F1P-X":
        case "ZONE-E-F1P-X":
        case "ZONE-E-F1P-X-NB":
            label = "CHF1"
            break
        case "ZL-2F-2P":
        case "CHAR-E-F2P-X":
        case "CHAR-I- F2P-X":
        case "ZONE-E-F2P-X":
        case "ZONE-E-F2P-X-NB":
            label = "CHF2"
            break
        case "ZL-1P":
        case "CHAR-E-1P-X":
        case "CHAR-I-1P-X":
        case "ZONE-E-1P-X":
        case "ZONE-I-1P-X":
            label = "CH1P"
            break
        case "ZL-2P":
        case "CHAR-E-2P-X":
        case "CHAR-I-2P-X":
        case "ZONE-E-2P-X":
        case "ZONE-I-2P-X":
            label = "CH2P"
            break
        case "Awaiting Config":
            label = "CHRT"
            break
        default:
            label = model
    }
    return label
}

export { GutermannModelConverter, GutermannModelDeConverter };


