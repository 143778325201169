import React, { useState, useEffect } from 'react'
import { APILoggerDetail, buildAPILoggerDetail } from '../../models/APILoggerDetail';
import { Select, MenuItem, ToggleButtonGroup, ToggleButton } from "@mui/material"
import { GraphTemplate } from '../../models/GraphTemplateModel'
interface Props {
    colors: {
        red: Array<string>
        yellow: Array<string>
        green: Array<string>
        brown: Array<string>
        blue: Array<string>
        orange: Array<string>
        purple: Array<string>

    }
    name: string
    color: string
    loggers: Array<APILoggerDetail>
    currentUnit: string
    type: string
    handleHideLoggerChannel: (serial: number, channel: string) => void
    handleUnitChange: (value : string, channel: string) => void
    unitsAndValues: Array<GraphTemplate>
    handleGraphTypeChange: (value:string, channel:string) => void
}
const GraphChannelDisplay: React.FC<Props> = ({ colors, name, color, loggers, currentUnit, type, handleHideLoggerChannel, unitsAndValues, handleUnitChange, handleGraphTypeChange }) => {
    const flowUnits = ['l/s', 'm3/h', 'MGD', 'GPD', 'gps', 'cfs']
    const pressureUnits = ['bar', 'mb', 'psi', 'mH2O', 'mwg']
    const totalUnits = ['l', 'm3', 'gal', 'ft3']

    useEffect(() => {
        let channelTemp = ""
        let graphTypeTemp = ""
        if (name === 'Channel A' || name === 'Flow 1') {
            channelTemp = 'A'
            graphTypeTemp = unitsAndValues[0].graphSettings.flowAGraphType
        } else if (name === 'Totaliser A' || name === 'Totaliser 1') {
            channelTemp = 'AT'
            graphTypeTemp = unitsAndValues[0].graphSettings.flowATotalGraphType
        } else if (name === 'Consumption A' || name === 'Consumption 1') {
            channelTemp = 'AC'
            graphTypeTemp = unitsAndValues[0].graphSettings.flowAConsumptionGraphType
        } else if (name === 'Channel B' || name === 'Flow 2') {
            channelTemp = 'B'
            graphTypeTemp = unitsAndValues[0].graphSettings.flowBGraphType
        } else if (name === 'Totaliser B' || name === 'Totaliser 2') {
            channelTemp = 'BT'
            graphTypeTemp = unitsAndValues[0].graphSettings.flowBTotalGraphType
        } else if (name === 'Consumption B' || name === 'Consumption B') {
            channelTemp = 'BC'
            graphTypeTemp = unitsAndValues[0].graphSettings.flowBConsumptionGraphType
        } else if (name === 'Channel C' || name === 'Pressure 1') {
            channelTemp = 'C'
            graphTypeTemp = unitsAndValues[0].graphSettings.pressureCGraphType
        } else if (name === 'Channel D' || name === 'Pressure 2') {
            channelTemp = 'D'
            graphTypeTemp = unitsAndValues[0].graphSettings.pressureDGraphType
        } else if (name === 'Channel E' || name === 'Pressure 3') {
            channelTemp = 'E'
            graphTypeTemp = unitsAndValues[0].graphSettings.pressureEGraphType
        }
        setChannel(channelTemp)
        setGraphType(graphTypeTemp)
    }, [unitsAndValues])

        
        
    
    const [tooltip, setShowTooltip] = useState<boolean>(false)
    const [channel, setChannel] = useState<string>("")
    const [graphType, setGraphType] = useState<string>("")
    return (
        <div onMouseOver={(): void => setShowTooltip(true)} onMouseLeave={(): void => setShowTooltip(false)} style={{ position: "relative" } }>
            <div
                style={{
                    display: "flex",
                    borderLeft: `6px solid ${colors[color][0]}`,
                    boxShadow: "2px 2px 10px #00000060",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    borderBottomLeftRadius: tooltip ? "0px" : "5px",
                    borderBottomRightRadius: tooltip ? "0px" : "5px",
                    alignItems: "center",
                    padding: "6px 12px",
                    marginRight: "20px"
                }}>


            <p style={{ margin: "2px 0 0 0" }}>{name}</p>
        </div>
            {tooltip && <div
                style={{
                    backgroundColor: '#eeeeee',
                    marginLeft: "5px",
                    paddingTop: "10px",
                    position: "absolute",
                    width: "78%",
                    borderLeft: "1px solid #d9d9d9",
                    borderRight: "1px solid #d9d9d9",
                    borderBottom: "1px solid #d9d9d9",
                    borderBottomLeftRadius: "5px",
                    borderBottomRightRadius: "5px",
                    boxShadow: "2px 2px 10px #00000060"
                }}>{loggers.map((logger, index) => {
                    const device = unitsAndValues.filter(dev => dev.serial === logger.Serial)[0]

                    if (!(logger.ChannelsAvail & 0x01) && channel === 'A') return
                    if (!(logger.ChannelsAvail & 0x01) && channel === 'AT') return
                    if (!(logger.ChannelsAvail & 0x01) && channel === 'AC') return
                    if (!(logger.ChannelsAvail & 0x02) && channel === 'B') return
                    if (!(logger.ChannelsAvail & 0x02) && channel === 'BT') return
                    if (!(logger.ChannelsAvail & 0x02) && channel === 'BC') return
                    if (!(logger.ChannelsAvail & 0x04) && channel === 'C') return
                    if (!(logger.ChannelsAvail & 0x08) && channel === 'D') return
                    if (!(logger.ChannelsAvail & 0x10) && channel === 'E') return



                    let colorBG = colors[color][index]
                    if (!device.graphSettings.flowAVisible && channel === 'A') {
                        colorBG = 'gray'
                    }
                    if (!device.graphSettings.flowATotalVisible && channel === 'AT') {
                        colorBG = 'gray'
                    }
                    if (!device.graphSettings.flowAConsumptionVisible && channel === 'AC') {
                        colorBG = 'gray'
                    }
                    if (!device.graphSettings.flowBVisible && channel === 'B') {
                        colorBG = 'gray'
                    }
                    if (!device.graphSettings.flowBTotalVisible && channel === 'BT') {
                        colorBG = 'gray'
                    }
                    if (!device.graphSettings.flowBConsumptionVisible && channel === 'BC') {
                        colorBG = 'gray'
                    }
                    if (!device.graphSettings.pressureCVisible && channel === 'C') {
                        colorBG = 'gray'
                    }
                    if (!device.graphSettings.pressureDVisible && channel === 'D') {
                        colorBG = 'gray'
                    }
                    if (!device.graphSettings.pressureEVisible && channel === 'E') {
                        colorBG = 'gray'
                    }

                    return (<div key={logger.Serial} onClick={(): void => handleHideLoggerChannel(logger.Serial, channel)} style={{ display: "flex", justifyContent: "center", width: "100%", marginRight: "20px", cursor: "pointer" }}>
                        <div style={{ backgroundColor: colorBG, width: "20px", height: "20px", margin: "5px 10px 0 0" }} >
                    </div>
                    <p style={{ marginTop: "3px" }}>{logger.Serial}</p>
                    </div>)

                })}
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <div>
                        <p style={{ textAlign: "center" }}>Display As</p>
                        <ToggleButtonGroup style={{ margin: "-8px 0 10px 0" }} onChange={(e):void => handleGraphTypeChange(e.target.id, channel) }>
                            <ToggleButton value='spline'  size="small" style={{ backgroundColor: graphType === 'spline' ? colors[color][0] : 'inherit'} }>
                                <span id="spline" style={{ color: graphType === 'spline' ? 'white' : 'inherit', textDecoration: graphType === 'spline' ? 'underline' : 'none'} }>Line</span>
                            </ToggleButton>
                            <ToggleButton value='bar' size="small" style={{ backgroundColor: graphType !== 'spline' ? colors[color][0] : 'inherit' }}>
                                <span id="bar" style={{ color: graphType !== 'spline' ? 'white' : 'inherit', textDecoration: graphType !== 'spline' ? 'underline' : 'none' }}>Bar</span>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    {type === 'pressure' && pressureUnits.includes(currentUnit) ?  <div>
                        <p style={{ textAlign: "center" }}>Unit</p>

                        <Select
                            size="small"
                            value={currentUnit}
                            style={{ margin: "-8px 0 10px 0" }}
                            onChange={(e): void => handleUnitChange(e.target.value, channel)}
                        >
                            {pressureUnits.map(unit => (<MenuItem key={unit} value={unit}>{unit}</MenuItem>))}
                        </Select>
                    </div> :
                        
                    <div>
                        {type !== 'pressure' && <div>
                            <p style={{ textAlign: "center" }}>Unit</p>
                            <Select
                                size="small"
                                value={currentUnit}
                                style={{ margin: "-8px 0 10px 0" }}
                                onChange={(e): void => handleUnitChange(e.target.value, channel)}
                            >
                                {type === 'flow' && flowUnits.map(unit => (<MenuItem key={unit} value={unit}>{unit}</MenuItem>))}
                                {type === 'total' && totalUnits.map(unit => (<MenuItem key={unit} value={unit}>{unit}</MenuItem>))}
                                {type === 'consumption' && totalUnits.map(unit => (<MenuItem key={unit} value={unit}>{unit}</MenuItem>))}
                            </Select>
                        </div>}
                    </div>}
                </div>
            </div>}
            
        </div>
    )
}
export default GraphChannelDisplay