import React, { useEffect, useState } from 'react';
import GridItem from '../Grid/GridItem';
import { TextField, Chip, Button, Select, MenuItem, Checkbox } from "@mui/material"
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MuiColorInput } from 'mui-color-input';
import { APILoggerDetail, buildAPILoggerDetail } from '../../models/APILoggerDetail';
import MiniGraph from './miniGraph'
import CustomNumberInputChanger from '../../components/CustomInput/CustomNumberInputChanger'
interface OverView {
    borderRadius: string
    padding: string
    boxShadow: string
    marginBottom: string
}

interface ModalParagraphStyle {
    margin: string
    color: string
    fontWeight: number
}

interface DisplayUnits {
    name: string
    value: string
}

interface Unit {
    name: string
    value: string
}

interface Props {
    overViewStyle: OverView
    borderColor: string
    modalParagraphStyle: ModalParagraphStyle
    handleGraphSettingsChange: (name: string, value: any, changeGroup: string) => void
    changeGroup: string
    isPressure: boolean
    visible: boolean
    unit: string
    minState: string
    minValue: number
    maxState: string
    maxValue: number
    valueAs: string | null
    axisPosition: string
    lineWidth: number
    graphType: string
    color: string
    labelSize: number
    startDate: Date
    loggers: Array<APILoggerDetail>
    channel: string
    displayUnits: Array<DisplayUnits>
    isTotaliser: boolean
    totalPerDay: boolean
    companyDefault: boolean
    defaultColor: boolean
    pressureUnits: Array<Unit>
}



const GraphSettingsComponent: React.FC<Props> = ({
    overViewStyle,
    borderColor,
    modalParagraphStyle,
    handleGraphSettingsChange,
    changeGroup,
    isPressure,
    visible,
    unit,
    minState,
    minValue,
    maxState,
    maxValue,
    valueAs,
    axisPosition,
    lineWidth,
    graphType,
    color,
    labelSize,
    startDate,
    loggers,
    channel,
    displayUnits,
    isTotaliser,
    totalPerDay,
    companyDefault,
    defaultColor,
    pressureUnits

}) => {

    const [nonPressureAnalog, setNonPressureAnalog] = useState<boolean>(false)

    useEffect(() => {
        const testArr = pressureUnits.map(unit => unit.value)
        if (!testArr.includes(unit)) {
            setNonPressureAnalog(true)
        }

    }, [])




    return (
        <div style={{ display: "flex", borderLeft: `10px solid ${borderColor}`, width: "98.7%", margin: " 20px 300px 0 10px", ...overViewStyle }}>
            <div style={{ minWidth: "100%" }}>
                <GridItem>
                    <div>
                        <h4 style={{ color: "#000000", marginTop: "5px", fontSize: "21px" }}><b>{channel}</b></h4>
                    </div>
                </GridItem>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <div style={{ width: "35%" }}>
                        <GridItem>
                            <h6 style={{ fontSize: "14px", margin: "10px 0", color: "#000000" }}><b>Value settings</b></h6>
                            <div style={{ display: "flex", marginBottom: "4px" }}>
                                <p style={modalParagraphStyle}>Visible: </p>

                                <Checkbox checked={visible} color="secondary" name={'Visible'} onChange={(e): void => handleGraphSettingsChange(e.target.name, e.target.checked, changeGroup)} />
                            </div>
                            {visible && isTotaliser && <div style={{ display: "flex", marginBottom: "4px" }}>
                                <p style={modalParagraphStyle}>Display As Total for 24 hour period: </p>

                                <Checkbox checked={totalPerDay} color="secondary" name={'PerDay'} onChange={(e): void => handleGraphSettingsChange(e.target.name, e.target.checked, changeGroup)} />
                            </div>}
                            {visible &&  <div style={{ display: "flex" }}>
                                <p style={modalParagraphStyle}>Display Unit: </p>
                                {!nonPressureAnalog || !isPressure ? <Select
                                    size="small"
                                    style={{ marginLeft: "10px", background: "#ffffff" }}
                                    value={unit}
                                    name={'Unit'}
                                    onChange={(e): void => handleGraphSettingsChange(e.target.name, e.target.value, changeGroup)}
                                >
                                    {displayUnits.map(item => {
                                        return (<MenuItem value={item.value} style={{ zIndex: 111111111111 }} key={item}>{item.name}</MenuItem>)
                                    })}

                                </Select> :
                                    <p style={{color: '#000000', margin: '0 0 0 10px'} }>{unit}</p>
                            }
                            </div>}
                            {visible && <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={modalParagraphStyle}>{`Minimum ${channel} Axis Value: `}</p>
                                <div style={{ display: 'flex', margin: '7px 0 0 14px', alignItems: 'center' }}>
                                    <FormControlLabel value="default" style={{ color: "#000000" }} control={<Radio color="secondary" />} name={"MinState"} label="Default" checked={minState === 'default'} onChange={(e): void => handleGraphSettingsChange(e.target.name, e.target.value, changeGroup)} />
                                    <FormControlLabel value="custom" style={{ color: "#000000" }} control={<Radio color="secondary" />} name={"MinState"} label="Custom" checked={minState === 'custom'} onChange={(e): void => handleGraphSettingsChange(e.target.name, e.target.value, changeGroup)} />
                                </div>
                                {minState === 'custom' &&
                                    <div style={{ display: "flex" }}>
                                        <CustomNumberInputChanger value={minValue} onChange={handleGraphSettingsChange} name={'MinValue'} changeGroup={changeGroup} />
                                        <p style={{ ...modalParagraphStyle, marginLeft: "5px" }}>{unit}</p>
                                    </div>}

                            </div>}

                            {visible && <div style={{ display: "flex", alignItems: "center", marginTop: "-10px" }}>
                                <p style={modalParagraphStyle}>{`Maximum ${channel} Axis Value: `}</p>
                                <div style={{ display: 'flex', margin: '7px 0 0 10px', alignItems: 'center' }}>
                                    <FormControlLabel value="default" style={{ color: "#000000" }} control={<Radio color="secondary" />} name={"MaxState"} label="Default" checked={maxState === 'default'} onChange={(e): void => handleGraphSettingsChange(e.target.name, e.target.value, changeGroup)} />
                                    <FormControlLabel value="custom" style={{ color: "#000000" }} control={<Radio color="secondary" />} name={"MaxState"} label="Custom" checked={maxState === 'custom'} onChange={(e): void => handleGraphSettingsChange(e.target.name, e.target.value, changeGroup)} />
                                </div>

                                {maxState === 'custom' &&
                                    <div style={{ display: "flex" }}>
                                        <CustomNumberInputChanger value={maxValue} onChange={handleGraphSettingsChange} name={'MaxValue'} changeGroup={changeGroup} />
                                        <p style={{ ...modalParagraphStyle, marginLeft: "5px" }}>{unit}</p>
                                    </div>}
                            </div>}
                            {isPressure && !nonPressureAnalog && visible && <div style={{ display: "flex", alignItems: "center", marginTop: "-10px" }}>
                                <p style={modalParagraphStyle}>Value As: </p>
                                <div style={{ display: 'flex', margin: '7px 0 0 10px', alignItems: 'center' }}>
                                    <FormControlLabel value="min" style={{ color: "#000000" }} control={<Radio color="secondary" />} label="Min" checked={valueAs === 'min'} name={"ValueAs"} onChange={(e): void => handleGraphSettingsChange(e.target.name, e.target.value, changeGroup)} />
                                    <FormControlLabel value="mean" style={{ color: "#000000" }} control={<Radio color="secondary" />} label="Mean" checked={valueAs === 'mean'} name={"ValueAs"} onChange={(e): void => handleGraphSettingsChange(e.target.name, e.target.value, changeGroup)} />
                                    <FormControlLabel value="max" style={{ color: "#000000" }} control={<Radio color="secondary" />} label="Max" checked={valueAs === 'max'} name={"ValueAs"} onChange={(e): void => handleGraphSettingsChange(e.target.name, e.target.value, changeGroup)} />
                                </div>
                            </div>}
                        </GridItem>
                    </div>
                    {visible && <div style={{ width: "30%" }} >
                        <GridItem>
                            <h6 style={{ fontSize: "14px", margin: "12px 0", color: "#000000" }}><b>Display settings</b></h6>
                            <div style={{ display: "flex", alignItems: "center", marginTop: "-12px" }}>
                                <p style={modalParagraphStyle}>Axis Position: </p>
                                <div style={{ display: 'flex', margin: '7px 0 0 10px', alignItems: 'center' }}>
                                    <FormControlLabel value="left" style={{ color: "#000000" }} control={<Radio color="secondary" />} label="Left" checked={axisPosition === 'left'} name={"AxisPosition"} onChange={(e): void => handleGraphSettingsChange(e.target.name, e.target.value, changeGroup)} />
                                    <FormControlLabel value="right" style={{ color: "#000000" }} control={<Radio color="secondary" />} label="Right" checked={axisPosition === 'right'} name={"AxisPosition"} onChange={(e): void => handleGraphSettingsChange(e.target.name, e.target.value, changeGroup)} />
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", marginTop: "-13px" }}>
                                <p style={modalParagraphStyle}>Graph Type: </p>
                                <div style={{ display: 'flex', margin: '7px 0 0 10px', alignItems: 'center' }}>
                                    <FormControlLabel value="spline" style={{ color: "#000000" }} control={<Radio color="secondary" />} label="Line" checked={graphType === 'spline'} name={"GraphType"} onChange={(e): void => handleGraphSettingsChange(e.target.name, 'spline', changeGroup) } />
                                    <FormControlLabel value="bar" style={{ color: "#000000" }} control={<Radio color="secondary" />} label="Bar" checked={graphType === 'bar'} name={"GraphType"} onChange={(e): void => handleGraphSettingsChange(e.target.name, 'bar', changeGroup)} />
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ ...modalParagraphStyle, marginRight: "10px" }}>Line/Bar Width: </p>
                                <CustomNumberInputChanger value={lineWidth} onChange={handleGraphSettingsChange} name={'LineWidth'} changeGroup={changeGroup} />
                                <p style={{ ...modalParagraphStyle, paddingLeft: "7px" }}>px</p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", margin: "7px 0 7px 0" }}>
                                {companyDefault && <div style={{ display: "flex" }}>
                                    <p style={modalParagraphStyle}>Keep Default Colour Pattern:</p>
                                    <Checkbox checked={defaultColor} color="secondary" name={'DefaultColor'} onChange={(e): void => handleGraphSettingsChange(e.target.name, e.target.checked, changeGroup)} />
                                </div>}
                                {!defaultColor && <div style={{ display: "flex" }}>
                                    <p style={modalParagraphStyle}>Colour: </p>
                                    <MuiColorInput format="hex" size="small" value={color} name={"Color"} style={{ marginLeft: "10px", background: "#ffffff", width: "150px", zIndex: 100000000 }} onChange={(e): void => handleGraphSettingsChange("Color", e, changeGroup)} />
                                </div>}
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ ...modalParagraphStyle, marginRight: "10px" }}>Label Font Size: </p>
                                <CustomNumberInputChanger value={labelSize} onChange={handleGraphSettingsChange} name={'LabelSize'} changeGroup={changeGroup} />
                                <p style={{ ...modalParagraphStyle, paddingLeft: "7px" }}>px</p>
                            </div>

                        </GridItem>

                    </div>}
                    {visible && <div style={{ width: "30%", marginRight: "40px" }}>
                        <GridItem>
                            <div>
                                {loggers.length > 0 && <MiniGraph
                                    startDate={startDate}
                                    visible={visible}
                                    channel={channel}
                                    logger={loggers[0].Serial}
                                    unit={unit}
                                    minAxis={minValue}
                                    maxAxis={maxValue}
                                    axisPosition={axisPosition}
                                    graphType={graphType}
                                    lineWidth={lineWidth}
                                    color={color}
                                    labelSize={labelSize}
                                />}
                            </div>
                        </GridItem>
                    </div>}
                </div>

            </div>
        </div>
    )
}
export default GraphSettingsComponent