import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { DataGrid, Column, FilterRow, Pager, Paging, StateStoring } from 'devextreme-react/data-grid';

//Moment date/time formatting
//https://momentjs.com/docs/
import Moment from 'moment';

import ClipLoader from "react-spinners/ClipLoader";
//modal window imports
import Grid from '@mui/material/Grid';
import Modal from 'react-bootstrap/Modal'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { TextField, Select, Switch, Menu, MenuItem, Button, FormControl } from '@mui/material';
import FilePicker from 'components/File/FilePicker';
import BackupIcon from '@mui/icons-material/Backup';
import Chip from '@mui/material/Chip';
import { Redirect } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// import makeAnimated from 'react-select/animated';
import { CreateUrl, CallGetAPI, CallPostAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";
import { APIGetFirmwaresModel, buildAPIFirmwaresModel } from "models/APIGetFirmwaresModel";



const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);
let flsFile = 'No file selected';
let fileReader: FileReader;

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
    };
}


interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    modal: boolean;
    deleteModal: boolean;
    colour: string;
    editFirmware: boolean;
    tempFirmware: APIGetFirmwaresModel;
    anchorEl: HTMLElement | null;
    modalTitle: string;
    allowSave: boolean;
    file: ArrayBuffer;
    fileFirmwareUrl: string;
    product: string;
    firmwareFLSloaded: boolean;
    firmwareMajor: string;
    firmwareMajorError: boolean;
    firmwareMinor: string;
    firmwareMinorError: boolean;
    firmwareRc: string;
    firmwareRcError: boolean;
}

export class Firmware extends Component<Props, State> {
    store: ArrayStore;
    gridRef: React.RefObject<DataGrid>;
    dofileFirmware: HTMLAnchorElement | null;

    constructor(props: Readonly<Props>) {

        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: []
        });

        this.state = {
            loading: false,
            tableHidden: false,
            visible: false,
            modal: false,
            deleteModal: false,
            colour: '',
            editFirmware: false,
            tempFirmware: {
                id: 0,
                model: "All",
                versionStr: "",
                version: 0,
                filename: "",
                fls: "",
                devVersion: 0
            },
            anchorEl: null,
            authorized: true,
            modalTitle: "",
            allowSave: false,
            file: new ArrayBuffer(0),
            fileFirmwareUrl: "",
            product: "",
            firmwareFLSloaded: false,
            firmwareMajor: "",
            firmwareMajorError: false,
            firmwareMinor: "",
            firmwareMinorError: false,
            firmwareRc: "",
            firmwareRcError: false,
        };

        this.gridRef = React.createRef();
        this.store.clear();

    }

    createSuccessNotification = (msg: string): void => {

        NotificationManager.success(msg, 'Success')

    };

    createErrorNotification = (msg: string): void => {

        NotificationManager.error(msg, '', 5000);

    };

    componentDidMount(): void {
        if (sessionStorage.getItem('portalUpdate') === 'true') {
            this.clearState()
        }
        if (this.state.product != "") {
            this.reloadData(this.state.product);
        }
    }


    clearState = (): void => {
        this.gridRef.current?.instance.state(null);
    }

    // if editId is not null, then reload() re-displays Modal with record id = editId after refresh
    reloadData = (product: string | null): void => {
        const me = this;

        me.store.clear();

        function fetchData(product: string): Promise<any> { // Replace 'any' with the expected response type
            return CallGetAPI(CreateUrl(`/api/aquaguard/Firmware?model=${product}`), {});
        }

        if (product === 'CHFL') {
            const productsToFetch = ['CHFL', 'CHF1', 'CHF2', 'CHRT', 'CH2P', 'CH1P'];
            const fetchPromises = productsToFetch.map((p, index) => {
                return fetchData(p)
                    .then((data) => {
                        if (data.length > 0) {
                            const records = [];
                            for (let i = 0; i < data.length; i++) {
                                records.push({ type: 'insert', data: data[i], index: i });
                            }
                            me.store.push(records);
                        }
                    })
                    .catch(() => {
                        // Handle error for each product fetch if needed
                    });
            });

            // Wait for all fetchPromises to complete
            Promise.all(fetchPromises)
                .then(() => {
                    me.setState({
                        tableHidden: false,
                        loading: false,
                    });
                })
                .catch(() => {
                    me.setState({
                        tableHidden: true,
                        loading: false
                    });
                });
        }
        else
        {

            CallGetAPI(CreateUrl('/api/aquaguard/Firmware?model=' + product), {})
                .then(data => {
                    if (data.length > 0) {

                        // Copy the data records into deviceData, adding the clickEvent
                        const records = [];
                        for (let i = 0; i < data.length; i++) {
                            records.push({ type: 'insert', data: data[i], index: i });
                        }

                        me.store.push(records);

                        me.setState(
                            {
                                tableHidden: false,
                                loading: false
                            })
                    }
                    else {
                        me.setState(
                            {
                                tableHidden: false,
                                loading: false
                            })
                    }
                })
                .catch(function () {
                    me.setState(
                        {
                            authorized: true,
                            tableHidden: true,
                            loading: false

                        })
                });
        }

    }

    //new row
    newFirmware = (): void => {
        this.setState({
            modal: true,
            editFirmware: false,
            tempFirmware: {
                id: 0,
                model: "All",
                versionStr: "",
                version: 0,
                filename: "",
                fls: "",
                devVersion: 0
            },
            modalTitle: "New Firmware",
            allowSave: false,
        });

    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    handleProductChange = (e: any): void => {

        const inputValue: string = e.target.value;

        this.setState({ product: inputValue, loading: inputValue != "" });
        this.reloadData(inputValue);
    }

    checkRemoveFirmware = (): void => {
        this.setState({
            anchorEl: null,
            deleteModal: true
        });
    }

    removeFirmware = (): void => {

        const me = this;

        CallDeleteAPI(CreateUrl('/api/aquaguard/Firmware?Id=' + this.state.tempFirmware.id), {})
            .then(async response => {
                if (response.status == 200) {

                    me.createSuccessNotification("Firmware removed")

                    me.store.push([{ type: 'remove', key: me.state.tempFirmware.id }]);

                    me.setState({
                        deleteModal: false,
                    });
                }
                else {
                    me.setState({
                        deleteModal: false,
                    });
                }
                console.log(response)

            })
            .catch(function (error) {
                me.createErrorNotification("Error removing firmware")
                console.log(error)
                me.setState({
                    deleteModal: false,
                });
            });
    }

    //do nothing, close the modal
    cancel = (): void => {
        this.setState({
            anchorEl: null
        });
        if (this.state.modal)
            this.toggleModal();

    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }

    majorVerChanged = (event: any): void => {
        try {
            if (!isNaN(parseInt(event.target.value))) {
                this.setState({ firmwareMajor: event.target.value, firmwareMajorError: false });
            }
            else {
                this.setState({ firmwareMajorError: true });
            }
        }
        catch (error) {
            this.setState({ firmwareMajorError: true });
        }
    }

    minorVerChanged = (event: any): void => {

        try {
            if (!isNaN(parseInt(event.target.value))) {
                this.setState({ firmwareMinor: event.target.value, firmwareMinorError: false });
            }
            else {
                this.setState({ firmwareMinorError: true });
            }
        }
        catch (error) {
            this.setState({ firmwareMinorError: true });

        }
    }

    rcChanged = (event: any): void => {

        try {
            if (!isNaN(parseInt(event.target.value))) {
                this.setState({ firmwareRc: event.target.value, firmwareRcError: false });
            }
            else {
                this.setState({ firmwareRcError: true });
            }
        }
        catch (error) {
            this.setState({ firmwareRcError: true });

        }
    }

    readFile = (file: Array<File>): void => {
        fileReader = new FileReader();
        fileReader.onloadend = this.handleFileRead;
        fileReader.readAsDataURL(file[0]);

        flsFile = file[0].name;
        this.setState({ firmwareFLSloaded: false });
    }

    removeFile = (): void => {
        flsFile = 'No file selected';
        this.setState({ firmwareFLSloaded: false });
    }

    handleFileRead = (e: any): void => {

        this.setState({ firmwareFLSloaded: true });
    }

    uploadFLSClick = async (e: any): Promise<void> => {


        let fileAsBase64 = fileReader.result as string;
        fileAsBase64 = fileAsBase64.replace("data:application/octet-stream;base64,", "");

        const versionStr = this.state.firmwareMajor + "." + this.state.firmwareMinor + "." + this.state.firmwareRc;
        const version = parseInt(this.state.firmwareMajor) * 256 + parseInt(this.state.firmwareMinor);

        const content: APIGetFirmwaresModel = {
            id: 0,
            model: this.state.product,
            versionStr: versionStr,
            version: version,
            filename: flsFile,
            fls: fileAsBase64,
            devVersion: parseInt(this.state.firmwareRc),
        };

        // Send Firmware file name and contents to API
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json-patch+json' },
            body: JSON.stringify(content)
        };

        const me = this;

            try {
                const data = await CallPostAPI(CreateUrl('/api/aquaguard/FirmwareFLS'), requestOptions);
                console.log(data);
                me.createSuccessNotification("Firmware FLS file uploaded");
                flsFile = 'No file selected';
                this.setState({ firmwareFLSloaded: false });
                this.reloadData(this.state.product);

                if (content.model === 'CHFL') {
                    // Array of models to make additional API POST calls
                    const modelsToPost = ['CHF1', 'CHF2', 'CHRT', 'CH1P', 'CH2P'];

                    // Create an array of promises for the additional API POST calls
                    const additionalPostPromises = modelsToPost.map(model => {
                        const additionalContent: APIGetFirmwaresModel = { ...content, model };
                        const additionalRequestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json-patch+json' },
                            body: JSON.stringify(additionalContent)
                        };
                        return CallPostAPI(CreateUrl('/api/aquaguard/FirmwareFLS'), additionalRequestOptions);
                    });

                    // Wait for all additional POST calls to resolve
                    await Promise.all(additionalPostPromises);
                    console.log("All additional POST calls completed.");
                }
            } catch (error) {
                console.log(error);
                me.createErrorNotification('Error uploading file');
            }

    };


    //-------------------------

    handleClick(event: any, id: number): void {
        this.store.byKey(id)
            .then((dl) => {
     
                this.setState(
                    {
                        tempFirmware: dl,
                        anchorEl: event.currentTarget,
                    });
                },
                (error) => {
                    console.log(error);
                });
    }

    moreRender(Task: any): React.ReactNode {
        return (
            <IconButton size="small" onClick={(e): void => { this.handleClick(e, Task.data.id); }}><MoreHorizIcon /></IconButton>
        );
    }

    descRender(item: any): React.ReactNode {
        return (
            <div style={{ width: (item.column.width - 20), height: 100, whiteSpace: "normal", overflowWrap: 'break-word', overflowY: "scroll"  }}>{item.value}</div>
        )
    }



    render(): React.ReactNode {
        const { classes } = this.props;


        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                <div>

                    <Modal
                        show={this.state.modal}
                            onHide={(): void => this.toggleModal()}
                        dialogClassName="modal-100w"
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                        >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {this.state.modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <h3>Firmware Upload</h3>
                                    Product: {this.state.product}
                                </Grid>
                                <Grid item xs={8} >
                                    Version:
                                    <TextField
                                        id="outlined-input-majorVer"
                                        label="Major version"
                                        onChange={this.majorVerChanged}
                                        variant="outlined"
                                        size="small"
                                        error={this.state.firmwareMajorError}
                                        helperText={this.state.firmwareMajorError ? "Version must be numeric" : ""}
                                    />
                                    &nbsp;.&nbsp;
                                    <TextField
                                        id="outlined-input-minorVer"
                                        label="Minor version"
                                        onChange={this.minorVerChanged}
                                        variant="outlined"
                                        size="small"
                                        error={this.state.firmwareMinorError}
                                        helperText={this.state.firmwareMinorError ? "Version must be numeric" : ""}
                                    />
                                    &nbsp;rc&nbsp;
                                    <TextField
                                        id="outlined-input-rc"
                                        label="rc"
                                        onChange={this.rcChanged}
                                        variant="outlined"
                                        size="small"
                                        error={this.state.firmwareRcError}
                                        helperText={this.state.firmwareRcError ? "RC must be numeric" : ""}
                                    />

                                </Grid>
                                <Grid item xs={6} >
                                    Select firmware file:
                                    <FilePicker
                                        onChange={this.readFile}
                                    >
                                        <IconButton aria-label="delete" size="large">
                                            <BackupIcon />
                                        </IconButton>
                                    </FilePicker>
                                </Grid>
                                <Grid item xs={4}>
                                    {flsFile &&
                                        <Chip
                                            style={{
                                                position: 'relative', left: '15%', top: '50%',
                                                transform: 'translate(-0%, -50%)'
                                            }}
                                            label={flsFile} onDelete={(): void => this.removeFile()} color="primary" />
                                    }
                                </Grid>
                           </Grid>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="primary" onClick={this.uploadFLSClick} disabled={!this.state.firmwareFLSloaded && !this.state.firmwareMajorError && !this.state.firmwareMinorError} >
                                Upload FLS
                            </Button>
                            <Button color="secondary" onClick={(): void => this.cancel()}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>


                    <Modal
                        show={this.state.deleteModal}
                        onHide={(): void => this.setState({ deleteModal: false})}
                        dialogClassName="modal-100w"
                        aria-labelledby="example-delete-modal-styling-title"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-delete-modal-styling-title">
                                Confirm deletion
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            Remove {this.state.tempFirmware.filename} from available Firmwares?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="primary" onClick={(): void => this.removeFirmware()} >Remove</Button>
                            <Button color="secondary" onClick={(): void => this.setState({ deleteModal: false })}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    {this.state.loading &&
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2000
                        }}>
                            <ClipLoader
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>

                    }
                    {!this.state.loading &&

                        <div>


                            {this.state.tableHidden &&
                                    <ApiFailed />
                            }
                            {!this.state.tableHidden && 
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                        <h4 className={classes.cardTitleWhite}>Firmwares</h4>
                                            <IconButton aria-label="add" onClick={(): void => this.newFirmware()} size="large">
                                                    <AddCircleOutlineIcon />
                                                </IconButton>
                                            </CardHeader>
                                            <CardBody>
                                        Select Product:
                                        <Select
                                            name="Product"
                                            style={{ margin: 0, paddingBottom: 0 }}
                                            value={this.state.product}
                                            onChange={this.handleProductChange.bind(this)}
                                        >
                                            <MenuItem value="">Select....</MenuItem>
                                            <MenuItem value="TOR-N">TOR-N</MenuItem>
                                            <MenuItem value="SWG">SWG</MenuItem>
                                            <MenuItem value="CHFL">CharIot All Other Decoder Models (CHF1, CHF2, CHFL, CHRT, CH1P, CH2P)</MenuItem>
                                            <MenuItem value="CHFR">CharIot Relay (CHFR)</MenuItem>
                                         </Select>
                                        {this.state.product != "" &&
                                            <DataGrid

                                                repaintChangesOnly={true}
                                                dataSource={new DataSource({ store: this.store })}
                                                ref={this.gridRef} >
                                                <StateStoring enabled={true} type="localStorage" storageKey="FirmwareListGrid" />
                                                <FilterRow visible={true} />

                                                <Column dataField="model" visible={true} dataType="string" groupIndex={1}/>
                                                <Column dataField="id" visible={true} dataType="number" />
                                                <Column dataField="versionStr" visible={true} dataType="string" />
                                                <Column dataField="devVersion" visible={true} dataType="number" />
                                                <Column dataField="filename" visible={true} dataType="string" width={200} />
                                                <Column fixed={true} fixedPosition='right' type="buttons" cellRender={this.moreRender.bind(this)} width={35} />


                                                <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                <Paging defaultPageSize={10} />
                                            </DataGrid>
                                        }
                                            </CardBody>
                                        </Card>
                                    </GridItem>

                                </GridContainer>
                            }

                        </div>
                    }


                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                            disableScrollLock={true}
                    >
                            <MenuItem onClick={this.checkRemoveFirmware.bind(this)}>Delete</MenuItem>
                    </Menu>

                    <NotificationContainer />

                </div>
                )

            :
            (
                <Redirect to={'/Login'} />
            );

    }

}

export default withStyles(style)(Firmware);

