import React, { ReactNode, Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import Moment from 'react-moment';
import { APIGetLoggerSummaryModel, buildAPIGetLoggerSummaryModel } from "models/APIGetLoggerSummaryModel";
import { APIGetLoggerConnectionsModel, buildAPIGetLoggerConnectionsModel } from "models/APIGetLoggerConnectionsModel";
import { APIGetLoggerEventsModel, buildAPIGetLoggerEventsModel } from "models/APIGetLoggerEventsModel";
import { Redirect } from 'react-router-dom';
import Map from 'devextreme-react/map';

import { withStyles, createStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { compose, spacing, palette, border } from '@mui/system';
import { AlarmSummary } from "./AlarmSummary";
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MapIcon from '@mui/icons-material/Map';

import Modal from 'react-bootstrap/Modal'
import MapTile from "components/Tiles/MapTile.js";
import Update from "@mui/icons-material/Update";

//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'

import ConnectionsGraph from '../Connections/ConnectionsGraph';
import SignalGauge from '../Connections/NewSignalGauge';
import BatteryData from '../Connections/BatteryInfoTor'
// Device image
import TOR from "assets/TOR-1N.png";
import TORS from "assets/TOR-4S.png";
import ProductPopup from './ProductPopup';
import yellowMarker from '../../assets/mapLocationMarkers/pinYellow.png';
import redMarker from '../../assets/mapLocationMarkers/pinRed.png';

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
};

const style = createStyles(styles);

const Box = styled('div')(compose(spacing, palette));


const overViewStyle = {
    backgroundColor: "#f7f7f7",
    borderRadius: "5px",
    padding: "10px 0 0 0",
    width: "90%",
    boxShadow: "2px 2px 10px #00000060",
    marginBottom: "30px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    serial: number;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    logger: APIGetLoggerSummaryModel | null;
    connectionsData: Array<APIGetLoggerConnectionsModel>;
    eventData: Array<APIGetLoggerEventsModel>;
    redirect: boolean;
    redirectPath: string;
    redirectProps: {
        siteName: string;
        siteId: number | undefined;
        serial: string | undefined;
        deviceId: number | undefined;
    } | null;
    viewMapModal: boolean;
    productPopup: boolean;
    mapKey: string;
    carrier: string;
    network: string;
}

class TORSummary extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            logger: null,
            connectionsData: [],
            eventData: [],
            redirect: false,
            redirectPath: '',
            redirectProps: null,
            viewMapModal: false,
            productPopup: false,
            mapKey: '',
            carrier: '',
            network: ''
        };
    }

    componentDidMount(): void {
        let connectionsData = new Array<APIGetLoggerConnectionsModel>();
        let eventData = new Array<APIGetLoggerEventsModel>();

        //get detail on logger here
        const me = this;
        CallGetAPI(CreateUrl('/api/aquaguard/mapkey'), {})
            .then(data => {
                this.setState({ mapKey: data })


            })
        CallGetAPI(CreateUrl('/api/aquaguard/LoggerBySerial?serial=' + me.props.serial),{})
            .then(response => {
                    me.setState({
                    logger: buildAPIGetLoggerSummaryModel(response)
                });

            })
            .catch(function () {
                me.setState(
                    {
                        authorized: false
                    })
            });

        CallGetAPI(CreateUrl('/api/aquaguard/LoggerConnections?serial=' + this.props.serial + "&startDate=" + moment().subtract(7, "days").format("yyyy-MM-DD") + "&endDate=" + moment().endOf("day").format("yyyy-MM-DDTHH:mm:ss")), {})
            .then(json => {
                connectionsData = connectionsData.concat(buildAPIGetLoggerConnectionsModel(json));

                me.setState({
                    connectionsData: connectionsData,
                });
            })
            .catch(function (ex) {
                me.setState(
                    {
                        authorized: false,
                        connectionsData: []
                    });
                console.log(ex);
            })

     //   let eventInfo: Array<APIGetLoggerEventsModel>;
        let firstEventWithDetails: APIGetLoggerEventsModel | undefined; // Holds the first matching event
        CallGetAPI(CreateUrl('/api/aquaguard/LoggerEvents?serial=' + this.props.serial + "&startDate=" + moment().subtract(7, "days").format("yyyy-MM-DD") + "&endDate=" + moment().endOf("day").format("yyyy-MM-DDTHH:mm:ss")), {})
            .then(response => {
                eventData = eventData.concat(buildAPIGetLoggerEventsModel(response));                                
                firstEventWithDetails = eventData.find(item => item.eventCode == "MODEM_LOG" && item.details !== null); //code 41                
                if (firstEventWithDetails) {
                    const opIndex = firstEventWithDetails.details.toString().indexOf(' Op:');
                    if (opIndex !== -1) {
                        // Extract the value after "Op:" and before the next space
                        const opSubstring = firstEventWithDetails.details.toString().substring(opIndex + 4).trim();

                        const firstSpaceIndex = opSubstring.indexOf(' ');
                        // If there is a space, extract the string before it, otherwise take the whole string
                        const op = firstSpaceIndex !== -1 ? opSubstring.substring(0, firstSpaceIndex) : opSubstring;
                        me.setState({
                            carrier: op,
                        });

                        const dashIndex = opSubstring.indexOf('-');
                        if (dashIndex !== -1) {
                            const networkType = opSubstring.substring(dashIndex + 1).trim(); // Extracts the string after '-'
                            const firstSpaceIndex = networkType.indexOf(' ');
                            const network = firstSpaceIndex !== -1 ? networkType.substring(0, firstSpaceIndex) : networkType;
                            me.setState({
                                network: network, 
                            });
                        }
                    }

                } else {
                    console.log('No events with details found.');
                }
            })
            .catch(err => console.log(err));


        this.setState({ loading: false, tableHidden: false });
    }

    viewSiteClick(): void {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/SiteDetail',
                redirectProps: {
                    siteName: this.state.logger?.SiteNameUserFriendly || this.state.logger?.SiteName || "",
                    siteId: this.state.logger?.FkSiteId,
                    serial: this.state.logger?.Serial.toString(),
                    deviceId: undefined
                }
            });
    }

    viewDeploymentClick(): void {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/DeploymentList',
                redirectProps: {
                    siteName: this.state.logger?.SiteNameUserFriendly || this.state.logger?.SiteName || "",
                    siteId: this.state.logger?.FkSiteId,
                    serial: this.state.logger?.Serial.toString(),
                    deviceId: this.state.logger?.Id
                }
            });
    }

   

    showProductPopup(): void {
        this.setState({ productPopup: true })
    }

    hideProductPopup(): void {
        this.setState({ productPopup: false })
    }

    render(): ReactNode {
        const defaultCenter = window.location.hostname.includes("zonelog.net")
            ? { lat: 47.17442830752493, lng: 8.517189402835617 }
            : { lat: 50.744094943532446, lng: -3.99597602646894 };
        const channels: Array<string> = [];
        if (this.state.logger?.ChannelsAvail && (this.state.logger?.ChannelsAvail & 0x01) > 0) {
            channels.push("Flow A");
        }
        if (this.state.logger?.ChannelsAvail && (this.state.logger?.ChannelsAvail & 0x02) > 0) {
            channels.push("Flow B");
        }
        if (this.state.logger?.ChannelsAvail && (this.state.logger?.ChannelsAvail & 0x04) > 0) {
            if ((this.state.logger?.ChannelsAvail & 0x20) > 0) {
                channels.push("Pressure C");
            }
            else {
                channels.push("Analogue C");
            }
        }
        if (this.state.logger?.ChannelsAvail && (this.state.logger?.ChannelsAvail & 0x08) > 0) {
            if ((this.state.logger?.ChannelsAvail & 0x40) > 0) {
                channels.push("Pressure D");
            }
            else {
                channels.push("Analogue D");
            }
        }
        if (this.state.logger?.ChannelsAvail && (this.state.logger?.ChannelsAvail & 0x10) > 0) {
            if ((this.state.logger?.ChannelsAvail & 0x80) > 0) {
                channels.push("Pressure E");
            }
            else {
                channels.push("Analogue E");
            }
        }


        return (!this.state.redirect) ?
            (<div>

                <ProductPopup show={this.state.productPopup} product={this.state.logger?.Model} image={TOR} title="TOR-N Logger" onClose={this.hideProductPopup.bind(this)} />

            {this.state.loading &&
                <div style={{
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)'
                }}>
                    <ClipLoader
                        size={150}
                        color={"#123abc"}
                        loading={this.state.loading}
                    />
                </div>
            }
            {!this.state.loading &&
                <div>
                    {this.state.tableHidden &&
                            <ApiFailed />
                    }
                        {!this.state.tableHidden && this.state.logger &&
                               
                            <Box padding={1}>
                        
                    <GridContainer direction="row"  margin-bottom={10} spacing={3}>
                        <GridItem xs={12} sm={6} md={4} style={{ marginBottom: 10 }}>
                                        <div style={{ borderLeft: "10px solid #00BF00", ...overViewStyle, width: "100%" }}>
                                <Box style={{ width: "100%", height: "100%", padding: "0 20px 20px 20px" }}>
                                            <h5 style={{textAlign: "center"} }><b>Device</b></h5>
                                {this.state.logger &&
                                                    <div style={{textAlign: "center", marginBottom: "10px"} }>
                                                    {this.state.logger.Model == 'TOR-S' ?
                                                        <img src={TORS}  width={100} onClick={this.showProductPopup.bind(this)} style={{ cursor: "pointer" }} />
                                                        :
                                                        <img src={TOR}  width={100} onClick={this.showProductPopup.bind(this)} style={{ cursor: "pointer" }} />
                                        }
                                                </div>
                                }
                            <div >
                                <b style={{ fontWeight: 700 }}>Device type:</b>&nbsp;&nbsp; {this.state.logger.Model} : {this.state.logger.ModelId}
                            </div>
                            <div>
                                <b style={{ fontWeight: 700 }}>Serial:</b> {this.state.logger.Serial}
                            </div>
                             <div>
                                <b style={{ fontWeight: 700 }}>SW Version:</b> {this.state.logger.Swversion}{this.state.logger.Devversion > 0 ? <span>rc{this.state.logger.Devversion}</span> : <span></span>}
                            </div>
                            <div>
                                <b style={{ fontWeight: 700 }}>Channels:</b> {channels.join(",")}
                            </div>
                            <div>
                                <b style={{ fontWeight: 700 }}>Last record:</b> <Moment format="HH:mm DD/MM/YYYY">{this.state.logger.LastReading}</Moment> 
                                                </div>
                                                
                            <div>
                                                    <b style={{ fontWeight: 700 }}>Network Type:</b>  {this.state.network}
                            </div>
                            <div>
                                                    <b style={{ fontWeight: 700 }}>Carrier:</b> {this.state.carrier}
                            </div>
                            <hr />
                                <div>
                                    <b style={{ fontWeight: 700 }}>Site:</b> {this.state.logger.SiteNameUserFriendly ? this.state.logger.SiteNameUserFriendly : this.state.logger.SiteName}
                                    {this.state.logger.SiteName &&
                                                        <Button style={{float: "right", marginRight: "30px"}} variant="contained" size="small" color="secondary" onClick={this.viewSiteClick.bind(this)} >View site</Button>
                                    }
                                                </div>
                                                <div style={{marginTop: "15px"} }>
                                {this.state.logger.Deployed ?

                                    <div>
                                                        <b style={{ fontWeight: 700 }}>Deployed:</b> {moment(this.state.logger.Deployed).format("DD/MM/yyyy")} <Button style={{ float: "right", marginRight: "33px" }} size="small" variant="contained" color="primary" onClick={this.viewDeploymentClick.bind(this)}>History</Button>
                                    </div>
                                    :
                                    <div>
                                                            <b style={{ fontWeight: 700 }}>Not deployed</b> <Button style={{ float: "right", marginRight: "33px" }} variant="contained" size="small" color="primary" onClick={this.viewDeploymentClick.bind(this)}>History</Button>
                                    </div>
                                                    }
                                </div>

                                <hr />
                                {this.state.logger.GpsLatitude != null &&
                                        <div> 
                                        <b style={{ fontWeight: 700 }}>GPS:</b> {this.state.logger.GpsLatitude.toFixed(2)}N {this.state.logger.GpsLongitude?.toFixed(2)}W
                                        </div>
                                }
                                            </Box>
                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={this.state.connectionsData.length > 0 ? 5 : 8}>
                                        
                                            <div>

                                                <Map
                                                    //zoom={15}
                                                    defaultZoom={15}
                                                    provider="google"
                                                    markers={[
                                                        {
                                                            location: this.state.logger.GpsLatitude && this.state.logger.GpsLongitude ? [this.state.logger.GpsLatitude, this.state.logger.GpsLongitude] : [defaultCenter.lat, defaultCenter.lng],
                                                            iconSrc: this.state.logger.GpsLatitude && this.state.logger.GpsLongitude ? yellowMarker : redMarker
                                                        }
                                                    ]}
                                                    apiKey={this.state.mapKey}
                                                    width="100%"
                                                    height={600}
                                                    controls={true}
                                                    type="hybrid"
                                                />
                                                <i>What3Words - {this.state.logger?.What3Words}</i>
                                            </div>

                                    </GridItem>
                                    
                                    <GridItem xs={12} sm={6} md={3} style={{ marginBottom: 10 }}>
                                        {this.state.connectionsData.length > 0 &&
                                            <div style={{ borderLeft: "10px solid #00ADBA", ...overViewStyle, width: "100%" }}>
                                                <SignalGauge connectionsData={this.state.connectionsData} />
                                            </div>}
                                        {this.state.connectionsData.length > 0 && this.state.logger.LastConnected &&
                                            <div style={{ borderLeft: "10px solid #009FE3", ...overViewStyle, width: "100%" }}>
                                                <div style={{ marginLeft: "-20px" }}>
                                                    <ConnectionsGraph connectionsData={this.state.connectionsData} lastConnection={this.state.logger.LastConnected} />
                                                </div>
                                            </div>}
                                        {this.state.connectionsData.length > 0 &&
                                            <div style={{ borderLeft: "10px solid #6F2DA8", ...overViewStyle, width: "100%" }}>
                                                <BatteryData connectionsData={this.state.connectionsData} />
                                            </div>}
                                    </GridItem>
                        
                            
                                
                                    
                        

                        
                                    
                                </GridContainer>
                                
                            </Box>

                }
                </div>
            }

        </div>
            )
            :
    (<Redirect push to={{
        pathname: this.state.redirectPath,
        state: this.state.redirectProps
    }} />);
    }

}

export default withStyles(style)(TORSummary);