import React, { useEffect, useState, ReactNode, useRef, useCallback } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Button, Checkbox, Chip, useTheme, Select, MenuItem, Slider } from '@mui/material';
import { DataGrid, Column, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring, Format, Export, HeaderFilter, Selection } from 'devextreme-react/data-grid';
import TextField from '@mui/material/TextField';
import { CreateUrl, CallGetAPI, CallPutAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import { APILoggerDetail, buildAPILoggerDetail } from 'models/APILoggerDetail';
import greenMarker from '../../assets/mapLocationMarkers/pinGreen.png';
import yellowMarker from '../../assets/mapLocationMarkers/pinYellow.png';
import greyMarker from '../../assets/mapLocationMarkers/pinGrey.png'
import SiteDeviceChips from './SiteDeviceChips'
import dxDataGrid, { dxDataGridRowObject } from "devextreme/ui/data_grid";
import DateBox from 'devextreme-react/date-box';
import moment from 'moment'
import Tabs from 'devextreme-react/tabs'
import { MuiColorInput } from 'mui-color-input';
import CHF from "assets/CHF.png";
import CHF1P from "assets/CHF1P.png";
import CHF2P from "assets/CHF2P.png";
import ZL from "assets/ZoneLog_Logger.png"
import redMarker from '../../assets/mapLocationMarkers/pinRed.png';
import FilePicker from 'components/File/FilePicker';
import GoogleMapComponent from '../../components/Map/NewGoogleMaps2024';
import CustomNumberInputChanger from '../../components/CustomInput/CustomNumberInputChanger'
import { up } from 'esri/views/3d/webgl/RenderCamera';
import Tooltip from '@mui/material/Tooltip';
import { GutermannModelConverter } from "../../Utils/GutermannModelCoverter";
interface Props {
    open: boolean
    modalTitle: string
    onCancel: () => void
    site: Site
    selectedLogger: number | null
    unAssignedLoggers: Array<APILoggerDetail>
    editSite: boolean
    onSubmit: (selected : Array, assignDate : Date, polygonPath : Array<Coordinates>, boundaryStyling: BoundaryStyling) => void
    handleLoggersToBeUnassigned: () => void
    companies: Array<Company>
}

interface GPS {
    position: { lat: number, long: number }
    icon: string
    serial: number
    id: number
    tooltip: string
    
    
}

interface Company {
    name: string
    id: number
    isParent: boolean
}



interface Site {
    id: number;
    SiteNameUserFriendly: string;
    SiteName: string;
    created: Date;
    deleted: Date | null;
    Latitude: number | null;
    Longitude: number | null;
    groups: Array<{ id: number; displayName: string }>;
    defaultSite: boolean;
    fkCompanyId: number;
    fkEASiteId: number | null;
    EASite: string;
    lastUpdate: Date | null;
    alarmState: number | null;
    alarmsEnabled: number | null;
    Loggers: Array<APILoggerDetail>;
    LastReading?: Date | null;
    HasActiveLoggers?: string;
    boundary?: string;

}

interface Tab {
    id: number | undefined
    text: string | undefined
    icon: string | undefined
}

interface BoundaryStyling {
    strokeWeight: number
    strokeColor: string
    areaColor: string
    transparency: number
}

interface KML {
    fileName: string
    coordinates: Array<Coordinates>
}

interface Coordinates {
    lat: number
    lng: number
}

interface Name {
    siteNameTemp: string
    siteRefTemp: string
}


const SiteModal: React.FC<Props> = ({ open, modalTitle, onCancel, site, unAssignedLoggers, editSite, onSubmit, handleLoggersToBeUnassigned, selectedLogger, companies }) => {


    const defaultCenter = window.location.hostname.includes("zonelog.net")
        ? { lat: 47.17442830752493, lng: 8.517189402835617 }
        : { lat: 50.744094943532446, lng: -3.99597602646894 };





    const mapRef = useRef(null);
    const [mapKey, setMapKey] = useState<string>("")
    const [markers, setMarkers] = useState<Array<GPS>>([])
    const [showGrid, setShowGrid] = useState<boolean>(false)
    const [selected, setSelected] = useState([])
    const [fromDate, setFromDate] = useState(new Date())
    const [currentZoom, setCurrentZoom] = useState<number>(18)
    const [message, setMessage] = useState<boolean>(false)
    const [hasCreatedSite, setHasCreatedSite] = useState<boolean>(false)
    const [polygonPath, setPolygonPath] = useState([]);
    const [center, setCenter] = useState(defaultCenter);
    const [hasBoundary, setHasBoundary] = useState<boolean>(false)
    const [createBoundary, setCreateBoundary] = useState<boolean>(false)
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [tabs, setTabs] = useState<Array<Tab>>([])
    const [boundaryStyling, setBoundaryStyle] = useState<BoundaryStyling>(
        {
            strokeWeight: 2,
            strokeColor: '#FF0000',
            areaColor: '#FF0000',
            transparency: 35
        })
    const [hoveredMarker, setHoveredMarker] = useState(null)
    const [uploadModal, setUploadModal] = useState<boolean>(false)
    const [kml, setKml] = useState<KML>({ fileName: "", coordinates: [] })
    const [fileLoadError, setFileLoadError] = useState<boolean>(true)
    const [assignDate, setAssignDate] = useState<Date>(moment().toDate())
    const [tempName, setTempName] = useState<Name>({ siteNameTemp: "", siteRefTemp: "" })
    const [editBoundary, setEditBoundary] = useState<boolean>(false)
    const [updateBoundary, setUpdateBoundary] = useState<boolean>(false)
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const [tooltipHovered, setTooltipHovered] = useState<boolean>(false)
    const [selectedCompany, setSelectedCompany] = useState<string>("")

    useEffect(() => {
        setHasCreatedSite(editSite)
    }, [editSite])

    useEffect(() => {
        let markersTemp = []
        console.log(site)
        if (site.boundary) {
            const boundaryInfo = JSON.parse(site.boundary)
            console.log(boundaryInfo)
            if ((Array.isArray(boundaryInfo.path))) {
                setHasBoundary(true)
                setPolygonPath(boundaryInfo.path)
                setBoundaryStyle(boundaryInfo.styling)
            }
        } else {
            setPolygonPath([])
        }
            if (site.loggers.length > 0) {
                markersTemp = site && site.loggers.length > 0 ? site.loggers.map(logger => {
                    let hasNoGps = false
                    if (!logger.latitude || !logger.longitude) {
                        logger.hasGps = false
                    } else {
                        logger.hasGps = true
                    }
                    
                    let marker = yellowMarker
                    if (!logger.latitude && !logger.longitude) {
                        hasNoGps = true
                        marker = redMarker
                    }
                    console.log(logger)
                    return {

                        position: hasNoGps ? { lat: defaultCenter.lat, lng: defaultCenter.lng } : { lat: logger.latitude, lng: logger.longitude },
                        icon: marker,
                        tooltip: {
                            deviceName: logger.deviceName
                        },
                        serial: logger.serial,
                        loggerId: logger.loggerId,
                        key: logger.loggerId,
                        defaultGps: hasNoGps
                    }
                })
                    : []
                if (selectedLogger) {
                    const loggerDetailSelected = unAssignedLoggers.filter(logger => logger.serial === selectedLogger)[0]
                    let hasNoGps = false
                    if (!loggerDetailSelected.latitude || !loggerDetailSelected.longitude) {
                        loggerDetailSelected.hasGps = false
                    } else {
                        loggerDetailSelected.hasGps = true
                    }
                    
                    let marker = greyMarker
                    if (!loggerDetailSelected.latitude && !loggerDetailSelected.longitude) {
                        hasNoGps = true
                        marker = redMarker
                    }

                    const selectedLoggerMarker = {
                        position: hasNoGps ? { lat: defaultCenter.lat, lng: defaultCenter.lng } : { lat: loggerDetailSelected.latitude, lng: loggerDetailSelected.longitude },
                        icon: marker,
                        tooltip: {
                            deviceName: loggerDetailSelected.deviceName
                        },
                        serial: loggerDetailSelected.serial,
                        loggerId: loggerDetailSelected.loggerId,
                        key: loggerDetailSelected.loggerId,
                        defaultGps: hasNoGps
                    }
                    markersTemp = [...markersTemp, selectedLoggerMarker]
                    selected.push(loggerDetailSelected)
                    handleAddDeviceClick()
                }
                
                setMarkers(markersTemp)

            } else if (selectedLogger && unAssignedLoggers.length > 0) {
                console.log(selectedLogger, unAssignedLoggers)
                const loggerDetailSelected = unAssignedLoggers?.filter(logger => logger.serial === selectedLogger)[0]
                console.log(loggerDetailSelected)
                let hasNoGps = false
                if (!loggerDetailSelected.latitude || !loggerDetailSelected.longitude) {
                    loggerDetailSelected.hasGps = false
                } else {
                    loggerDetailSelected.hasGps = true
                }

                let marker = greyMarker
                if (!loggerDetailSelected?.latitude && !loggerDetailSelected?.longitude) {
                    hasNoGps = true
                    marker = redMarker
                }

                const selectedLoggerMarker = {
                    position: hasNoGps ? { lat: defaultCenter.lat, lng: defaultCenter.lng } : { lat: loggerDetailSelected.latitude, lng: loggerDetailSelected.longitude },
                    icon: marker,
                    tooltip: {
                        deviceName: loggerDetailSelected.deviceName
                    },
                    serial: loggerDetailSelected.serial,
                    loggerId: loggerDetailSelected.loggerId,
                    key: loggerDetailSelected.loggerId,
                    defaultGps: hasNoGps
                }
                markersTemp = [...markersTemp, selectedLoggerMarker]
                selected.push(loggerDetailSelected)
                
                setMarkers(markersTemp)
            }

            else {
                setMarkers([])
            }
        setTempName({ siteNameTemp: site.siteNameUserFriendly, siteRefTemp: site.siteName })
        setSelectedCompany(site.companyName)
    }, [site, unAssignedLoggers])

    


    
    const resetBoundaryAction = (reset: boolean) => {
        if (polygonPath.length === 0 || reset) {
            if (markers.length > 0) {
                const filterOutZeroValuesMarker = markers.filter(marker => marker.position.lat !== 0 && marker.position.lng !== 0)
                const avgLat = filterOutZeroValuesMarker.reduce((sum, marker) => sum + marker.position.lat, 0) / filterOutZeroValuesMarker.length;
                const avgLng = filterOutZeroValuesMarker.reduce((sum, marker) => sum + marker.position.lng, 0) / filterOutZeroValuesMarker.length;

                const polygonTemp = []
                polygonTemp.push(
                    { lat: avgLat - 0.005, lng: avgLng + 0.005 },
                    { lat: avgLat - 0.005, lng: avgLng - 0.005 },

                    { lat: avgLat + 0.005, lng: avgLng - 0.005 },
                    { lat: avgLat + 0.005, lng: avgLng + 0.005 }
                )
                setPolygonPath(polygonTemp)
                setCenter({ lat: avgLat, lng: avgLng });
            }
        }
    }


    useEffect(() => {
        console.log(companies)
        if (companies?.length > 0) {
            if (companies.length > 1 && editSite) {
                const selectedCompany = companies.filter(company => company.id === site.fkCompanyId)[0]
                setSelectedCompany(selectedCompany.name)
            } else {
                setSelectedCompany(companies[0].name)
                
            }
        }
    }, [companies, site, editSite])

    useEffect(() => {
        unAssignedLoggers.map(logger => {
            if (!logger.latitude || !logger.longitude) {
                logger.hasGps = false
            } else {
                logger.hasGps = true
            }
        })
    }, [unAssignedLoggers.length >0])

    useEffect(() => {
        resetBoundaryAction()
    }, [markers])


    useEffect(() => {
        const timeout = setTimeout(() => {
            fitBoundsToMarkers();
        }, 50);
        return () => clearTimeout(timeout);
    }, [markers, createBoundary, hasBoundary, updateBoundary]);




    const fitBoundsToMarkers = () => {
        console.log(mapRef)
        if (mapRef.current && markers.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            markers.forEach((marker) => {
                bounds.extend(marker.position);
            });
            if (hasBoundary) {
                polygonPath.forEach((corner) => {
                    bounds.extend(corner)
                })
            }
            mapRef.current.fitBounds(bounds);
        } else if (mapRef.current && polygonPath.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            polygonPath.forEach((corner) => {
                bounds.extend(corner)
            })
            mapRef.current.fitBounds(bounds);
        }
    };


    

    const selectRender = (key: dxDataGridRowObject): ReactNode => {
        return (
            <Checkbox value={key.key} checked={selected.indexOf(key.key) >= 0} />
        )
    }

    const selectionChanged = (data): void => {
        let markersTemp = [...markers]
        if (data.currentSelectedRowKeys.length > 0) {
            data.currentSelectedRowKeys.forEach(device => {
                let gpsData = false
                const loggerDetailSelected = device
                if (!loggerDetailSelected.latitude || !loggerDetailSelected.longitude) {
                    loggerDetailSelected.latitude = defaultCenter.lat
                    loggerDetailSelected.longitude = defaultCenter.lng
                    gpsData = false
                    loggerDetailSelected.hasGps = gpsData
                } else {
                    gpsData = true
                    loggerDetailSelected.hasGps = gpsData
                }
                if (gpsData) {

                    markersTemp.push({ id: loggerDetailSelected.loggerId, position: { lat: loggerDetailSelected.latitude, lng: loggerDetailSelected.longitude }, tooltip: { model: loggerDetailSelected.model }, icon: greyMarker, serial: loggerDetailSelected.serial, key: loggerDetailSelected.serial })
                } else {
                    markersTemp.push({ id: loggerDetailSelected.loggerId, position: { lat: loggerDetailSelected.latitude, lng: loggerDetailSelected.longitude }, tooltip: { model: loggerDetailSelected.model }, icon: redMarker, serial: loggerDetailSelected.serial, key: loggerDetailSelected.serial })
                }
            })
        } else {
            data.currentDeselectedRowKeys.forEach(device => {
                const loggerDetailDeselected = device
                if (!loggerDetailDeselected.hasGps) {
                    loggerDetailDeselected.latitude = null
                    loggerDetailDeselected.longitude = null
                }
                markersTemp = markersTemp.filter(marker => marker.serial !== loggerDetailDeselected.serial)
            })
        }


        setSelected(data.selectedRowKeys)
        setMarkers(markersTemp)
        
    }

    
    const onPolygonClick = () => {
        /* do nothing */
    }

    const onMarkerClick = () => {
        if (unAssignedLoggers.length > 0) {
            setSelectedTab(1)
            handleAddDeviceClick()
        }
    }
   

   

    const renderChip = (data): ReactNode => {
        const theme = useTheme();
        return data.data.hasGps ? (
            <div style={{float: "right"}}>
                <Chip
                    sx={{ backgroundColor: theme.palette.grey.main, color: theme.palette.grey.contrastText }}
                    label={data.value}
                />
            </div>
                    
        ) : (
            <div style={{ color: theme.palette.error, display: "flex", justifyContent: "space-between" } }>
                    
                <div>
                <Tooltip title={`No Gps Location for Device: Will appear at default location (${window.location.hostname.includes('zonelog.net') ? 'Gutermann HQ' : 'Ashridge HQ'}) `}>
                    <i className={"fa-solid fa-triangle-exclamation"} style={{ margin: "8px 0 0 0", position: "absolute" }}></i>
                        </Tooltip>
                    </div>
                <Chip color="error" label={data.value} />
            </div>
        );
    };

    
    const handleDateChange = (e):void => {
        setAssignDate(e)

    }

    const modelRender = (data:string):string => {
        if (window.location.hostname.includes('zonelog.net')) {
            return GutermannModelConverter(data.value)
        } else {
            return data.value
        }
    }

    const handleCreateSite = () => {
        if (selected.length > 0) {
            handleAddDeviceClick()
        }
        setHasCreatedSite(true)
    }

    


    const containerStyle = {
        height: "80vh",
        width: "100%",
        marginLeft: "5px",
        minHeight: "500px",
    };

    

    

    const onMapLoad = (map) => {

        mapRef.current = map;
        map.setMapTypeId("hybrid")
        fitBoundsToMarkers();

        if (!hasBoundary && markers.length === 1) {
            setTimeout(() => {
                mapRef.current.setZoom(18)
            }, 500)
        }
    };

    const handleStylingChange = (name: string, value: string | number): void => {
     
        console.log(value)
        const styling: BoundaryStyling = { ...boundaryStyling }
        if (isNaN(value) && name === 'strokeWeight') {
            value = 0
        } 
        styling[name] = value
        setBoundaryStyle(styling)
    }

    

    const handleCreateBoundaryClick = () => {
        const tabsTemp = [...tabs]
        const hasTab = tabsTemp.filter(tab => tab.id === 0)
        if (hasTab.length === 0) {
            setCreateBoundary(true)
            setEditBoundary(true)
            tabsTemp.unshift({
                id: 0,
                text: "Boundary",
                icon: "fa fa-ruler"
            })
            setTabs(tabsTemp)
        }
        setSelectedTab(0)
    }

    const handleAddDeviceClick = () => {
        const tabsTemp = [...tabs]
        const hasTab = tabsTemp.filter(tab => tab.id === 1)        
        if (hasTab.length === 0) {
            setShowGrid(true)
            tabsTemp.push({
                id: 1,
                text: "Devices",
                icon: "fa fa-mobile"
            })
            setTabs(tabsTemp)
        }
        setSelectedTab(1)
        
    }

    const updatePolygonPath = useCallback((path) => {
        if (!path || typeof path.getLength !== 'function') return;

        const newCoordinates = [];
        const len = path.getLength();

        for (let i = 0; i < len; i++) {
            const point = path.getAt(i);
            newCoordinates.push({
                lat: point.lat(),
                lng: point.lng()
            });
        }

        // Force a new reference to trigger re-render
        setPolygonPath([...newCoordinates]);
    }, []);

    const handleTooltipShow = (serial: number): void => {

        setHoveredMarker(serial)
    }

    const handleTooltipHide = ():void => {
        setHoveredMarker(null)
    }


    const renderLat = (data): ReactNode => {

        if (!data.value) {
            
            return <div><p style={{ color: "red", textAlign: "center" }}>NO GPS LOCATION</p></div>
        } else {
            return data.value
        }
        
    }
    const renderLong = (data): ReactNode => {
        if (!data.value) {
            return <div><p style={{ color: "red", textAlign: "center" }}>NO GPS LOCATION</p></div>
        } else {
            return data.value
        }
    }

    const toggleUploadModal = () => {
        setUploadModal(!uploadModal)
        setKml({ fileName: "", coordinates: {lat: 0, lng: 0}})
    }

    const readFile = (files: Array<File>): void => {
        if (!files || files.length === 0) return; // Ensure files exist

        const file = files[0]; // Select the first file

        // Check if file is a Blob
        if (!(file instanceof Blob)) {
            console.error("Invalid file type. Expected a Blob or File.");
            return;
        }

        const reader = new FileReader();

        reader.onload = (event) => {
            const fileContent = event.target?.result as string;

            if (!fileContent) {
                console.error("Failed to read file content.");
                return;
            }


            // Parse the KML content
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(fileContent, "text/xml");

            // Extract coordinates from the KML
            const coordinates = [];
            const placemarks = xmlDoc.getElementsByTagName("Placemark");
            let coordArray = []
            for (let i = 0; i < placemarks.length; i++) {
                const polygon = placemarks[i].getElementsByTagName("Polygon")[0];
                if (polygon) {
                    const coordString = polygon
                        .getElementsByTagName("coordinates")[0]
                        .textContent?.trim();

                    if (coordString) {
                        coordArray = coordString
                            .split(" ")
                            .filter((coord) => coord !== "")
                            .map((coord) => {
                                    const [lng, lat] = coord.split(",");
                                    return { lat: parseFloat(lat), lng: parseFloat(lng) };
                                
                            });
                        if (coordArray.length > 1000) {
                            const alterArray = [];
                            const step = Math.ceil(coordArray.length / 1000);

                            for (let i = 0; i < coordArray.length; i++) {
                                if (i % step === 0) {
                                    alterArray.push(coordArray[i]);
                                }
                            }
                            coordArray = alterArray
                        }   


                    }
                }
            }
            setKml({ fileName: file.path, coordinates: coordArray })
            setFileLoadError(false)
        };

        reader.onerror = () => {
            console.error("There was an error reading the file!");

        };

        // Read the file as text
        reader.readAsText(file);
    };

    const loadKml = ():void => {
        setPolygonPath(kml.coordinates)
        toggleUploadModal()
        setFileLoadError(true)
        setHasBoundary(true)
        setUpdateBoundary(true)
    }

    const toggleCloseModal = () => {
        onCancel()
        setHasBoundary(false)
        setCreateBoundary(false)
        setHasCreatedSite(false)
        setBoundaryStyle({
            strokeWeight: 2,
            strokeColor: '#FF0000',
            areaColor: '#FF0000'
        })
        setTabs([])
        setKml({ fileName: "", coordinates: [] })
        setTempName({ siteNameTemp: site.siteNameUserFriendly, siteRefTemp: site.siteName })
        setEditBoundary(false)
        setUpdateBoundary(false)
        setHasCreatedSite(false)
    }


    const handleRemoveDeviceChipAndMarker = (logger) => {

        let markersTemp = markers
        let selectedTemp = selected
        if (site.loggers.filter(siteLogger => siteLogger.serial === logger.serial)[0]) {
            const confirm = window.confirm('Are you sure you want to remove this assigned logger from this site? This logger will be removed when you click Update')
            if (confirm) {
                markersTemp = markersTemp.filter(marker => marker.serial !== logger.serial)
                handleLoggersToBeUnassigned(logger)
            }
        } else {
            markersTemp = markersTemp.filter(marker => marker.serial !== logger.serial)
            selectedTemp = selectedTemp.filter(loggerSelected => loggerSelected.serial !== logger.serial)
        }
        

        setSelected(selectedTemp)
        setMarkers(markersTemp)
    }

    const handleTextFieldChange = (name, value) => {
        console.log(value)
        const nameChange = { ...tempName }
        nameChange[name] = value
        setTempName(nameChange)

    }

    const handleUpdate = (): void => {
        console.log(selectedCompany, companies)
        
        const companyId = companies.filter(company => company.name === selectedCompany)[0].id
        setEditBoundary(false)
        setUpdateBoundary(false)
        onSubmit(selected, assignDate, polygonPath, boundaryStyling, hasBoundary || createBoundary ? true : false, tempName, companyId)
        onCancel()
        setPolygonPath([])
        setHasBoundary(false)
        setCreateBoundary(false)
        setSelected([])
        setTabs([])

    }

    const resetBoundary = (): void => {
        setHasBoundary(false)
        setPolygonPath([])
        setBoundaryStyle({
            strokeWeight: 2,
            strokeColor: '#FF0000',
            areaColor: '#FF0000'
        })
        resetBoundaryAction(true)
    }

    const handleShowTooltip = ():void => {
        setShowTooltip(true)
        setTooltipHovered(true)
    }

    const handleHideTooltip = ():void => {
        if (!tooltipHovered) {
            setShowTooltip(false)
        }
    }

    const handleRightVertexClick = (event):void => {
        

        const vertexIndex = event.vertex;
        if (vertexIndex !== undefined) {
            // Remove the vertex
            const updatedCoords = [...polygonPath];
            updatedCoords.splice(vertexIndex, 1);
            setPolygonPath(updatedCoords);
        }
    }


    const handleCompanySelect = (e) => {
        setSelectedCompany(e.target.value)
    }

    const handleOpenNewTab = (): void => {
        const kmlContent = `
        <?xml version="1.0" encoding="UTF-8"?>
        <kml xmlns="http://www.opengis.net/kml/2.2">
            <Document>
                <Style id="ourPolygonStyle">
                    <LineStyle>
                        <color>70ff0000</color>
                        <width>2</width>
                    </LineStyle>
                    <PolyStyle>
                        <color>3dff5500</color>
                    </PolyStyle>
                </Style>
                <Placemark>
                    <styleUrl>#ourPolygonStyle</styleUrl>
                    <name>Example KML</name>
                    <Polygon>
                        <outerBoundaryIs>
                            <LinearRing>
                                <coordinates>
                                    -0.299764379640615,53.614706670928 -0.299089268055338,53.6137855510461 
                                    -0.298237520714404,53.6125616667435 -0.294430509127837,53.6130990096648 
                                    -0.292290458756932,53.6135360006464 -0.292243160054971,53.6133581963828 
                                    -0.292169780873306,53.6132645851037 -0.29211532320091,53.6132674031952 
                                    -0.291399202205104,53.6121316766049 -0.290418259863241,53.6106317938998 
                                    -0.288449990720463,53.6113075223088 -0.287078608116785,53.6118002490539 
                                    -0.287072795053816,53.6117929770689 -0.286051669562688,53.6103167461154 
                                    -0.285532855992471,53.6095029301127 -0.285476831863963,53.6092828493468 
                                    -0.284841605031251,53.6088422032045 -0.283280552706442,53.6077150718523 
                                    -0.28119464269145,53.6062629662217 -0.281340041464587,53.6061410337282 
                                    -0.282954651195288,53.6052005346287 -0.282958674773711,53.6051781249214 
                                    -0.282994716235737,53.6049961175699 -0.2830082150726,53.6049244159795 
                                    -0.282964628802689,53.604917500048 -0.282783010808519,53.6048879348481 
                                    -0.282950009472808,53.604644897375 -0.283039128419342,53.6044969033114
                                </coordinates>
                            </LinearRing>
                        </outerBoundaryIs>
                    </Polygon>
                </Placemark>
            </Document>
        </kml>
    `;

        const newTab = window.open();
        if (newTab) {
            newTab.document.open();
            newTab.document.write(`<pre>${kmlContent.replace(/</g, "&lt;").replace(/>/g, "&gt;")}</pre>`);
            newTab.document.close();
        }
    };

    const chipClick = () => {
        console.log(':-)')
    }


    return (
        <div>
            {site &&
                <div>
            <Modal
                        show={uploadModal}
                        style={{ marginTop: "300px", width: "50%", transform: "translateX(50%)", zIndex: 100000000 } }
            >
            <Modal.Header>
            <Modal.Title>Upload KML</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!kml.fileName && <FilePicker onChange={readFile} />}
                            {kml.fileName && kml.coordinates.length > 0 && <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"} }>
                             <p>Succesfully uploaded File:</p>
                            <Chip color="primary" label={kml.fileName} />
                            </div>}
                            {kml.fileName && kml.coordinates.length > 0 && fileLoadError && <p>Error loading file</p>}
            </Modal.Body>
            <Modal.Footer>
                            <Button variant="contained" onClick={():void => loadKml() } style={{marginRight: "10px"} }>Upload</Button>
                            <Button variant="contained" onClick={():void => toggleUploadModal()} color="error">Cancel</Button>
            </Modal.Footer>
            </Modal>
            <Modal
                        show={open}
                        style={{ zIndex: 1100} }
                className="modal-100vw"
            >
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                        <Modal.Body>
                            {console.log(selectedCompany)}
                    <div style={{ display: "flex", width: "100%", justifyContent: 'space-between' }}>
                        <div style={{display: "flex", flexDirection: "column", width: "50%", padding: "0 10px", alignItems: "center" } }>
                            <div>
                            <div style={{ display: "flex" }}>
                            <p style={{ margin: "8px 10px 0 0" }}>Site Name:</p>
                            <TextField
                                size="small"
                                style={{ width: "400px", background: "#ffffff" }}
                                value={tempName.siteNameTemp}
                                name={"siteNameTemp"}
                                onChange={(e): void => { handleTextFieldChange(e.target.name, e.target.value) }}
                            />
                            </div>
                            <div style={{ display: "flex", marginTop: "20px" }}>
                                <p style={{ margin: "8px 10px 0 0" }}>Site Reference:</p>
                                <TextField
                                    size="small"
                                    style={{ width: "374px", background: "#ffffff" }}
                                    value={tempName.siteRefTemp}
                                    name={"siteRefTemp"}
                                    onChange={(e): void => { handleTextFieldChange(e.target.name, e.target.value) }}
                                            />
                                
                                        </div>
                                        {companies?.length > 1 && !editSite && <div style={{ display: "flex", marginTop: "20px" }}>
                                            <p style={{ margin: "8px 10px 0 0" }}>Company To Assign To:</p>
                                            <Select
                                                size="small"
                                                value={selectedCompany}
                                                onChange={(e) => handleCompanySelect(e)}
                                                style={{ width: "316px", background: "#ffffff" }}
                                            >
                                                {companies.map(company => {
                                                    return (<MenuItem value={company.name} key={company.id}>{`${company.name} ${company.isParent ? '(Top Level)' : '(Sub)'}`}</MenuItem>)
                                                })}

                                            </Select></div>}
                                        {!editSite && !hasCreatedSite && <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div>
                                                
                                                <div style={{display: "flex", justifyContent: "center"} }>
                                                    <Button style={{ marginTop: "20px" }} variant="contained" disabled={!tempName.siteNameTemp || !tempName.siteRefTemp} onClick={(): void => handleCreateSite()}>Continue</Button>
                                                </div>
                                        </div>
                                            </div>}
                                    </div>
                            {(hasCreatedSite || editSite) && <div>
                                <div style={{ display: "flex", marginTop: "20px", justifyContent: 'center' }}>
                                            <p style={{ margin: "8px 10px 10px 0" }}>Devices:</p>
                                            {console.log(selected) }
                                            <SiteDeviceChips deviceDetailsClick={chipClick} data={markers} deleteNeeded={true} removeDevice={handleRemoveDeviceChipAndMarker} added={selected} />
                                    {message && <p>Removed devices from the site will update when the device next dials in</p>}
                                        </div>      
                                        {markers && markers.some(marker => marker.defaultGps === true) && <p>{`If devices have red markers they do not have a GPS location and have been set to ${window.location.hostname.includes('zonelog.net') ? 'Gutermann HQ' : 'Ashridge HQ'} `}</p>}
                                        <div style={{ display: "flex", marginTop: "20px" }}>

                                            <Button variant="contained" color="warning" disabled={markers.length === 0 && polygonPath.length === 0} onClick={(): void => handleCreateBoundaryClick()} style={{ marginRight: "10px" }}>{hasBoundary ? "Update Boundary for Site" : "Create Boundary for Site"}</Button>
                                    <Button variant="contained" color="secondary" onClick={(): void => handleAddDeviceClick()}>Unassigned Devices That can be added to site</Button>
                                </div>
                            </div>}
                            {tabs.length > 0 && tabs &&
                                <div style={{width: "100%" , display: "flex", justifyContent: "flex-start"} }>
                                    <Tabs
                                    onItemClick={(e): void => setSelectedTab(e.itemIndex)}
                                    dataSource={tabs}
                                    defaultSelectedIndex={0}
                                    style={{ fontSize: "18px", marginTop: "20px", width: tabs.length === 1 ? "50%" : "100%" }}
                                    selectionMode={'single'}
                                    selectedIndex={selectedTab}
                                    />
                                </div>
                                }
                                    {(showGrid && hasCreatedSite && selectedTab === 1) &&
                                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <div style={{ border: "1px solid #dddddd", padding: "10px", width: "100%" }}>
                                                <h4 style={{ fontSize: '18px', textAlign: "center" }}>Click the checkboxes to add unassigned devices to site (devices will be added when you click update)</h4>     
                                    <DataGrid
                                        dataSource={unAssignedLoggers.filter(logger => logger.companyName === selectedCompany)}
                                        selectedRowKeys={selected}
                                        onSelectionChanged={selectionChanged}
                                        allowColumnResizing={true}
                                        allowColumnReordering={true}
                                    >
                                        <Selection
                                            mode="multiple"
                                            selectAllMode='allPages'
                                            showCheckBoxesMode='always'
                                            />
                                        <FilterRow visible={true} />
                                        <StateStoring enabled={true} type="localStorage" storageKey="unAssignedDeviceListGrid" />
                                        <Column dataField="serial" dataType="string" cellRender={renderChip} width={140} />
                                        <Column dataField="deviceName"dataType="string"/>
                                        <Column dataField="latitude" dataType="number" cellRender={renderLat } />
                                        <Column dataField="longitude" dataType="number" cellRender={renderLong } />
                                        <Column type="selection" caption="Select" fixed={true} fixedPosition="right" />
                                        <Column visible={false} type="buttons" cellRender={selectRender} fixed={true} fixedPosition="right" caption="Select" width={50} />
                                        <Column dataField="model" dataType="string" cellRender={modelRender} />
                                                

                                        <Pager allowedPageSizes={[5, 10, 20, 50]} showPageSizeSelector={false} />
                                        <Paging defaultPageSize={5} />
                                        </DataGrid>

                                    {selected.length > 0 && <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: "10px" }}>
                                        <p style={{ margin: "8px 10px 0 0" }}>Select Date to Assign Device(s):</p>
                                            <DateBox style={{ marginRight: "10px" }} value={fromDate} onValueChange={handleDateChange} displayFormat="EEEE, MMM dd" />

                                                </div>}

                                </div>
                            </div>
                                    }

                        {selectedTab === 0 && createBoundary &&
                        <div style={{ width: "100%", border: "1px solid #d0d0d0", padding: "10px 10px 30px 10px" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{width: "100%"} }>
                                    <div style={{ display: "flex", marginTop: "20px" }}>
                                        <p style={{margin: "8px 10px 0 0"} }><b>Area Colour:</b></p>
                                        <MuiColorInput size="small" name="areaColor" value={boundaryStyling.areaColor} onChange={(e):void => handleStylingChange('areaColor', e)}  />
                                    </div>
                                    <div style={{ display: "flex", marginTop: "20px" }}>
                                        <p style={{ margin: "8px 15px 0 0" }}><b>Area Transparency:</b></p>
                                        <Slider color="black" size="small" valueLabelDisplay="auto" style={{ width: "42%", marginTop: "5px" }} value={boundaryStyling.transparency} onChange={(e): void => handleStylingChange('transparency', e.target.value)} />
                                    </div>
                                    <div style={{ display: "flex", marginTop: "20px" }}>
                                        <p style={{ margin: "8px 12px 0 0" }}><b>Line Colour:</b></p>
                                        <MuiColorInput size="small" name="strokeColor" value={boundaryStyling.strokeColor} onChange={(e):void => handleStylingChange('strokeColor', e)} />
                                    </div>
                                    <div style={{ display: "flex", marginTop: "20px" }}>
                                        <p style={{ margin: "8px 15px 0 0" }}><b>Line Width:</b></p>
                                        <CustomNumberInputChanger value={boundaryStyling.strokeWeight} onChange={handleStylingChange} name={'strokeWeight'} />
                                        <p style={{margin: "6px 0 0 8px"} }>px</p>
                                    </div>
                                    
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "85%" }}>
                                    <Button variant="contained" style={{marginBottom: "10px"}} color="secondary" onClick={():void => resetBoundary() }>Reset Boundary</Button>
                                    <Button variant="contained" onClick={(): void => toggleUploadModal()} >Upload KML of Boundary</Button>
                                    <div style={{position: "relative"} }>
                                        <p onMouseOver={(): void => handleShowTooltip()} onMouseLeave={(): void => handleHideTooltip()} style={{textDecoration: "underline", marginTop: "5px"} }>Is my KML the correct format?</p>
                                        {(showTooltip || tooltipHovered) && <div onMouseOver={(): void => { setTooltipHovered(true) }} onMouseLeave={():void => { setTooltipHovered(false); setShowTooltip(false)}} style={{ position: "absolute", top: "20px", left: "40px", zIndex: 1000000000, background: "white", padding: "10px", border: "1px solid black" }}>Your kml file must contain a polygon element
                                            similar to this: <br /> {`<Polygon><outerBoundaryIs><LinearRing><coordinates>`} <br />-0.299764379640615,53.614706670928 -0.299089268055338.... <br/> You can open it in a simple text editor like notepad to check
                                            <div style={{display: "flex", justifyContent: "center", marginTop: "10px"} }>
                                                <Button variant="contained" onClick={() => handleOpenNewTab() }>See full KML example</Button>
                                            </div>
                                        </div>}
                                    </div>                             
                                                </div>
                                
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <div style={{ border: "1px solid #d0d0d0", padding: "10px 10px 0 10px", margin: "20px 0 0 0"} }>
                                            <p><b>Click and Drag</b> the points to expand/shrink the area.</p>
                                            <p><b>Double Click</b> on a boundary point to remove from the area</p>
                                            </div>
                                            </div>
                        </div>}
                    </div>
                                <div style={{ width: "50%" }}>
                                <GoogleMapComponent
                                    //mapKey={mapKey}
                                        containerStyle={containerStyle}
                                        center={center}
                                        onMapLoad={onMapLoad}
                                        markers={markers}
                                        hasBoundary={hasBoundary}
                                        createBoundary={createBoundary}
                                        polygonPath={polygonPath}
                                        boundaryStyling={boundaryStyling}
                                        onPolygonClick={onPolygonClick}
                                        updatePolygonPath={updatePolygonPath}
                                        handleMouseOverMarker={handleTooltipShow}
                                        handleMouseOutMarker={handleTooltipHide}
                                        onMarkerClick={onMarkerClick}
                                        hoveredMarker={hoveredMarker}
                                        editBoundary={editBoundary}
                                        showTooltip={true}
                                        handleRightVertexClick={handleRightVertexClick}
                                /> 
                                </div>
            </div>                        
        </Modal.Body>
                        <Modal.Footer>
                {hasCreatedSite && <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => handleUpdate()}>{editSite ? 'Update' : 'Create' }</Button>}
                {<Button color="error" variant="contained" onClick={(): void => toggleCloseModal()}>Cancel</Button>}
        </Modal.Footer>

                    </Modal>
</div>}
</div>
    )
}
export default SiteModal