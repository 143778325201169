import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import ApiFailed from '../../Utils/ApiFailed';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CreateUrl, CallGetAPI, CallPostAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TextField from '@mui/material/TextField';
import Modal from 'react-bootstrap/Modal'
import Grid from '@mui/material/Grid';
import { Button, Checkbox } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { Redirect } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import DeviceReportDialog from "components/Reports/DeviceReport";
import dxDataGrid from "devextreme/ui/data_grid" // DataGrid instance type
import { LoadPanel, DataGrid, Column, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring, HeaderFilter, Selection } from 'devextreme-react/data-grid';
import type { InitializedEvent } from 'devextreme/ui/data_grid';
import Tabs from 'devextreme-react/tabs'
import ArrayStore from 'devextreme/data/array_store';

import GroupMembers from './GroupMembers'
import Select from 'react-select'
import MuiSelect from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import makeAnimated from 'react-select/animated';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Sites from "@mui/icons-material/SettingsRemote";
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import Regions from '@mui/icons-material/LocationCity';
import DataSource from "devextreme/data/data_source";
import { dxEvent } from "devextreme/events";
import { AnimatedTree } from 'react-tree-graph'
import 'react-tree-graph/dist/style.css'
import LoggerReportDialog from "components/Reports/LoggerReportDialog";
import moment from 'moment'
import MultipleDevicesIcon from '../../assets/jss/material-dashboard-react/components/MultipleDevicesIcon'
import PersonIcon from '@mui/icons-material/Person';
import { NU } from "country-flag-icons/react/3x2";




const animatedComponents = makeAnimated();

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    editModalStyle: {
        width: "90%",
        height: "600px",
    }
};


const style = createStyles(styles);

interface Group {
    id: number;
    groupId: number;
    name: string;
    parentId: Array<number>;
    path: string;
    lastUpdate: Date | null;
    level: string;
    subGroups: number;
    tags: Array<string>;
}

interface Options {
    value: number;
    label: string;
}

interface GroupOptions {
    value: number;
    label: string;
    parents: number;
    subGroups: number;
    generation: number
}

interface UserOptions {
    value: number
    email: string
    canConfig: boolean
    firstLogin: boolean
    lastLogin: Date
}

interface SiteOptions {
    siteName: string
    siteRef: string
    numberOfDevices: number
    value: number
}

interface GroupMember {
    value: number;
    label: string;
    groupType: string;
    parentId: number;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
    };
    groupId: number;
    regionOptions: { value: number; label: string }[];
    dataGridInstance: dxDataGrid,
    saveInstance: (e: InitializedEvent) => void,
    history: {
        goBack: () => void;
    };
}

interface HoveredData {
    numOfDevices: number
    users: Array<string>
    sites: number

}

interface State {
    dataSource: DataSource;
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    visible: boolean;
    selectedLogger: number;
    tempGroup: Group;
    edit: boolean;
    anchorEl: HTMLElement | null;
    modal: boolean;
    deleteModal: boolean;
    modalTitle: string;
    editGroup: boolean;
    redirect: boolean;
    redirectPath: string;
    redirectProps: {
        group: Group;
        groupOptions: Array<GroupOptions>;
        userOptions: Array<Options>;
        siteOptions: Array<Options>;
        regionOptions: Array<Options>;
        contactOptions: Array<Options>;
    } |
    {
        groupId: number;
        loggers: Array<number>;
        loggerId: number | null;
        showBackButton: boolean;
    }
    | null;
    groupUsersOptions: Array<Options>;
    groupSitesOptions: Array<Options>;
    groupGroupsOptions: Array<GroupOptions>;
    groupRegionsOptions: Array<Options>;
    groupContactsOptions: Array<Options>;
    tempGroupUsers: Array<Options>;
    tempGroupSites: Array<Options>;
    tempGroupGroups: Array<GroupOptions>;
    tempGroupRegions: Array<Options>;
    tempGroupContacts: Array<Options>;
    tempGroupParent: number;
    orgGroupMembers: Array<GroupMember>;
    reportDialog: boolean;
    loggersInGroup: Array<LoggerDetails>;
    treeData: TreeData,
    hoveredItem: TreeData,
    tooltip: Tooltip
    selectedTab: number
    hoveredData: HoveredData
    treeHeight: number
}

interface Tooltip {
    visible: boolean
    x: number
    y: number
}

interface TreeData {
    name: string
    children: Array<TreeData>
    groupId: number
    groupGUID: string
    generation: number
    id: number
    parentId: number
    path: string
    subGroups: number
    key: number
}

interface LoggerDetails {
    id: number;
    serial: number;
}

export class Groups extends Component<Props, State> {
    store: ArrayStore;
    //dataSource: DataSource | Group[];
    gridRef: React.RefObject<DataGrid>;
    datagridInstance?: dxDataGrid;

    constructor(props: Readonly<Props>) {
        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: [],
            errorHandler: (error: Error): void => {
                console.log(error.message);
            }
        });
        this.store.clear();

        this.state = {
            dataSource : new DataSource(this.store),
            tableHidden: false,
            visible: false,
            selectedLogger: 0,
            tempGroup: {
                id: 0,
                groupId: 0,
                name: '',
                parentId: [0],
                path: '',
                lastUpdate: null,
                level: '',
                subGroups: 0,
                tags: [],
            },
            edit: false,
            anchorEl: null,
            modal: false,
            deleteModal: false,
            modalTitle : '',
            editGroup: false,
            loading: true,
            authorized: true,
            redirect: false,
            redirectPath: '',
            redirectProps: null,
            groupUsersOptions: [],
            groupSitesOptions: [],
            groupGroupsOptions: [],
            groupRegionsOptions: [],
            groupContactsOptions: [],
            tempGroupUsers: [],
            tempGroupSites: [],
            tempGroupGroups: [],
            tempGroupRegions: [],
            tempGroupContacts: [],
            tempGroupParent: 0,
            orgGroupMembers: [],
            reportDialog: false,
            loggersInGroup: [],
            treeData: {},
            hoveredItem: {},
            hoveredData: {},
            tooltip: { visible: false, x: 0, y: 0 },
            selectedTab: 0,
            treeHeight: 0
        };

        this.gridRef = React.createRef();

        this.handleClick = this.handleClick.bind(this);
        this.moreRender = this.moreRender.bind(this);
        this.saveInstance = this.saveInstance.bind(this);

    }

    saveInstance = (e: InitializedEvent): void => {
        this.datagridInstance = e.component;
        e.component?.beginCustomLoading('Loading...');
    }

    /*
    saveInstance = (e: { component: any; }): void => {
        this.datagridInstance = e.component;
    }
    */

    createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createFailedInsertErrorNotification = (): void => {
        NotificationManager.error('Error creating new record', '', 5000);
    };

    createDuplicateInsertErrorNotification = (): void => {
        NotificationManager.error('Group with that name already exists', '', 5000);
    };

    createConflictNotification = (): void => {
        NotificationManager.warning('Changes conflict with another user. Refreshing groups', 'Click to retry', 5000, () => {
            this.reloadData(this.state.tempGroup.id);
        });
    };

    // Utility function to convert Array<GroupMembers> to Array<GroupOptions>
    groupMembersToGroups(members: Array<GroupMember>): Array<GroupOptions> {
        const output: Array<GroupOptions> = [];
        members.map((m: GroupMember) => {
            const selectedGroup = this.state.groupGroupsOptions.filter(group => group.value === m.value)[0]
            output.push(selectedGroup)
        });

        return output;
    }

    // Utility function to convert Array<GroupMembers> to Array<Options>
    groupMembersToOptions(members: Array<GroupMember>): Array<Options> {
        const output = [];
        console.log(members)
        if (members.some(member => member.groupType === 'SITE')) {
            members.map((m: GroupMember) => {
                const siteToAdd = this.state.groupSitesOptions.filter(site => site.value === m.value)[0]
                output.push(siteToAdd)
                console.log(m, this.state.groupSitesOptions)
            });
        }
        if (members.some(member => member.groupType === 'USER')) {
            members.map((m: GroupMember) => {
                const userToAdd = this.state.groupUsersOptions.filter(user => user.value === m.value)[0]
                console.log(userToAdd)
                output.push(userToAdd)
                console.log(m, this.state.groupUsersOptions)
            });
        }
        

        return output;
    }

    componentDidMount(): void {

        //setTimeout(() => {
        //    this.datagridInstance?.refresh();
        //    this.setState({ loading: false })
        //}, 1000);

        this.reloadData(null);
 
        this.datagridInstance?.endCustomLoading();
    }

    
    // if editId is not null, then reload() re-displays Modal with record id = editId after refresh
    reloadData = (editId: number | null): void => {
        const me = this;
        me.setState(
            {
                tableHidden: true,
                loading: true
            });

        const groupUsersOptions: Array<UserOptions> = [];
        let groupSitesOptions: Array<SiteOptions> = [];
        const groupGroupsOptions: Array<GroupOptions> = [];
        const groupRegionsOptions: Array<Options> = [];
        const groupContactsOptions: Array<Options> = [];
        const level = ['Top Level', 'Second Level', 'Third Level']
        const records = [];
        const treeData = {
            name: `${sessionStorage.getItem('companyName')}`,
            children: [],
            depthOfTree: 0,
            label: <><rect height="20" width={sessionStorage.getItem('companyName').length < 11 ? sessionStorage.getItem('companyName').length * 8 : sessionStorage.getItem('companyName').length * 7} y="-30" x="-20" fill="white" /><text dx="-18" dy="-16">{sessionStorage.getItem('companyName')}</text></>,
        }
        CallGetAPI(CreateUrl('/api/aquaguard/GroupsForCompany?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
            .then(data => {
                console.log(data)
                if (data.length > 0) {
                    data.sort((a, b) => a.generation - b.generation)
                    // Copy the data records into deviceData, adding the clickEvent

                    
                    for (let i = 0; i < data.length; i++) {
                        const groupId = data[i].groupId;
                        const rec: Group = {
                            id: data[i].id,
                            groupId: groupId,
                            name: data[i].groupName,
                            parentId: data[i].generation == 0 ? [0] : [data[i].parentId],
                            path: data[i].path,
                            lastUpdate: data[i].lastUpdate,
                            level: level[data[i].generation],
                            subGroups: data[i].subGroups,
                            tags: []
                        };

                            
                        // Group may already exist if multiple parents
                        if (groupGroupsOptions.some(g => g.value == groupId)) {
                            // Just add parentId to existing record and increment parents in groupGroupsOptions
                            records.filter(r => r.data.groupId == groupId)[0]?.data.parentId.push(data[i].parentId);
                            groupGroupsOptions.filter(g => g.value == groupId)[0].parents++;
                        }
                        else {
                            if (!groupGroupsOptions.some(g => g.value == groupId)) {
                                groupGroupsOptions.push({ value: groupId, label: rec.name, subGroups: data[i].subGroups, parents: rec.parentId.length, generation: data[i].generation, level: level[data[i].generation] });
                            }
                            records.push(rec);
                        }
                        /*const data = {
                            name: 'Parent',
                            children: [{
                                name: 'Child One',
                                children: [
                                    { name: 'Child Three' },
                                    { name: 'Child Four' },
                                    { name: 'Child Four' },
                                    {
                                        name: 'Child Four',
                                        children: [{ name: 'test 5' }]
                                    },
                                    { name: 'Child Four' },
                                    { name: 'Child Four' },
                                    { name: 'Child Four' },
                                    { name: 'Child Four' },
                                ]
                            }, {
                                name: 'Child Two'
                            }]
                        };*/
 
                    }
                    const groupsTemp = { ...treeData }

                    data.map(group => {

                        if (group.generation === 0) {
                            if (groupsTemp.depthOfTree < 1) {
                                groupsTemp.depthOfTree = 1
                            }
                            groupsTemp.children.push({
                                name: group.groupName,
                                children: [],
                                groupId: group.groupId,
                                groupGUID: group.groupGUID,
                                generation: group.generation,
                                id: group.id,
                                parentId: group.parentId,
                                path: group.path,
                                subGroups: group.subGroups,
                                key: group.id,
                                label: <><rect height="20" width={group.groupName.length < 11 ? group.groupName.length * 8 : group.groupName.length * 7} y="-30" x= "-20" fill="white" /><text dx="-18" dy="-16">{group.groupName}</text></>,
                            })
                        }
                    })

                    groupsTemp.children.map(subGroup => {
                        data.forEach(group => {
                            if (group.parentId === subGroup.groupId && group.generation === 1) {
                                if (groupsTemp.depthOfTree < 2) {
                                    groupsTemp.depthOfTree = 2
                                }
                                console.log(group, subGroup)
                                subGroup.children.push({
                                    name: group.groupName,
                                    children: [],
                                    groupId: group.groupId,
                                    groupGUID: group.groupGUID,
                                    generation: group.generation,
                                    id: group.id,
                                    parentId: group.parentId,
                                    path: group.path,
                                    key: group.id,
                                    label: <><rect height="20" width={group.groupName.length < 11 ? group.groupName.length * 8 : group.groupName.length * 7} y="-30" x="-20" fill="white" /><text dx="-18" dy="-16">{group.groupName}</text></>,
                                })
                            }
                        })
                    })


                    groupsTemp.children.forEach(childGroup1stLevel => {
                        childGroup1stLevel.children.map(childGroup2ndLevel => {
                            data.forEach(group => {
                                if (group.parentId === childGroup2ndLevel.groupId && group.generation === 2) {
                                    if (groupsTemp.depthOfTree < 3) {
                                        groupsTemp.depthOfTree = 3
                                    }
                                    console.log(group, childGroup2ndLevel)
                                    childGroup2ndLevel.children.push({
                                        name: group.groupName,
                                        children: [],
                                        groupId: group.groupId,
                                        groupGUID: group.groupGUID,
                                        generation: group.generation,
                                        id: group.id,
                                        parentId: group.parentId,
                                        path: group.path,
                                        subGroups: group.subGroups,
                                        key: group.id,
                                        label: <><rect height="20" width={group.groupName.length < 11 ? group.groupName.length * 8 : group.groupName.length * 7} y="-30" fill="white" /><text dx="2" dy="-16">{group.groupName}</text></>,
                                    })
                                }
                            })
                        })
                    })
                    console.log(groupsTemp)
                    me.setState(
                        {
                            dataSource : records,
                            groupGroupsOptions : groupGroupsOptions,
                            tableHidden: false,
                            loading: false,
                            treeData: groupsTemp
                        })

                        if (editId != null) {
                            me.store.byKey(editId)
                                .then((group) => {

                                    this.setState(
                                        {
                                            tempGroup: group,
                                            editGroup: true,
                                            modal: !me.state.modal,
                                            anchorEl: null,
                                            modalTitle: "Edit Group",
                                            
                                        });
                                });
                    }
                }
                else {
                    me.setState(
                        {
                            tableHidden: false,
                            loading: false
                        })
                }
            },
                // reject() - API error
                () => {
                    me.setState(
                        {
                            tableHidden: true,
                            loading: false
                        });
                    console.log("API Error");

                })
            .then(() => {
                console.log(records)
                records.forEach(record => {
                    CallGetAPI(CreateUrl('/api/aquaguard/GetAllSitesForGroup?groupid=' + record.groupId), {})
                        .then(result => {
                            console.log(result)
                        })
                })
                
            })
            .catch(function (error) {
                me.setState(
                    {
                        tableHidden: true,
                        loading: false
                    })
                console.log(error)
            });

        CallGetAPI(CreateUrl('/api/aquaguard/SitesForCompany?companyId=' + sessionStorage.getItem('companyId')), {})
                .then(data => {
                    if (data.length > 0) {
                       console.log(data)
                        for (let i = 0; i < data.length; i++) {
                            const id = data[i].siteId;
                            if (!groupSitesOptions.some(s => s.value == id)) {
                                groupSitesOptions.push({
                                    value: id,
                                    siteName: data[i].siteNameUserFriendly,
                                    siteRef: data[i].siteName,
                                    numberOfDevices: data[i].loggers.length
                                });
                            }
                        }
                        
                        groupSitesOptions = groupSitesOptions.filter(site => site.siteName !== 'Unassigned')
                        console.log(groupSitesOptions)
                        groupSitesOptions.sort((a, b) => a.siteRef.localeCompare(b.siteRef))
                        console.log(groupSitesOptions)
                        me.setState(
                            {
                                groupSitesOptions: groupSitesOptions,
                            })
                    }
                })
                .catch(function () {
                    me.setState(
                        {
                            loading: false
                        })
                });

        CallGetAPI(CreateUrl('/api/aquaguard/Users?companyId=' + sessionStorage.getItem('companyId')), {})
            .then(data => {
                if (data.length > 0) {
                    console.log(data)
                    for (let i = 0; i < data.length; i++) {

                        groupUsersOptions.push({
                            value: data[i].id,
                            email: data[i].email,
                            canConfig: data[i].canConfig,
                            firstLogin: data[i].firstLogin,
                            lastLogin: data[i].lastLogin,
                        });
                    }
                    groupUsersOptions.sort((a, b) => a.email.localeCompare(b.email))
                    me.setState(
                        {
                            groupUsersOptions: groupUsersOptions,
                        });
                }
            })
            .catch(function () {
                me.setState(
                    {
                        loading: false
                    });
            });

        CallGetAPI(CreateUrl('/api/aquaguard/RegionsForCompany?companyId=' + sessionStorage.getItem('companyId')), {})
                .then(data => {
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            const name = data[i].name;
                            const id = data[i].id;
                            groupRegionsOptions.push({ value: id, label: name });
                        }
                        me.setState(
                            {
                                groupRegionsOptions: groupRegionsOptions,
                            });
                    }
                })
                .catch(function () {
                    me.setState(
                        {
                            loading: false
                        });
                });


    }


    //new row
    newGroup = (): void => {
        this.setState({
            modal: !this.state.modal,
            editGroup: false,
            tempGroup: {
                id: -1,
                groupId: -1,
                name: 'New Group',
                parentId: [0],
                path: '',
                lastUpdate: null,
                level: '',
                subGroups: 0,
                tags: [],
            },
            tempGroupUsers: [],
            tempGroupSites: [],
            tempGroupGroups: [],
            tempGroupRegions: [],
            tempGroupParent: 0,
            modalTitle : "New Group"
        });
    }

    //edit row
    editGroup = (): void => {
        this.setState({loading: true})
        //fetch the selected group's members
        const me = this;
        CallGetAPI(CreateUrl('/api/aquaguard/AllMembersForGroup?groupid=' + me.state.tempGroup.groupId.toString()), {})
            .then(data => {
                const groupMembers: Array<GroupMember> = [];
                console.log(data)
                if (data.length > 0) {

                    // Copy the member records into correct array

                    for (let i = 0; i < data.length; i++) {
                        const id = data[i].memberId;
                        switch (data[i].groupType) {
                            case 'COMPANY':
                                // Ignore COMPANY members
                                break;

                            case 'GROUP': {
                                const group = me.state.groupGroupsOptions.filter(g => g.value == id);
                                console.log(group)
                                if (group.length > 0) {
                                    groupMembers.push({ value: id, label: group[0].label, groupType: "GROUP", parents: data[i].groupId });
                                }
                                break;
                            }
                            case 'USER': {
                                const user = me.state.groupUsersOptions.filter(g => g.value == id);
                                console.log(user)
                                if (user.length > 0) {
                                    groupMembers.push({ value: id, email: user[0].email, groupType: "USER", parentId: data[i].groupId });
                                }
                                break;
                            }
                            case 'SITE': {
                                const site = me.state.groupSitesOptions.filter(g => g.value == id);
                                console.log(site)
                                if (site.length > 0) {
                                    groupMembers.push({ value: id, siteName: site[0].siteName, siteRef: site[0].siteRef, groupType: "SITE", parentId: data[i].groupId });
                                }
                                break;
                            }
                            case 'REGION': {
                                const region = me.state.groupRegionsOptions.filter(g => g.value == id);
                                if (region.length > 0) {
                                    groupMembers.push({ value: id, label: region[0].label, groupType: "REGION", parentId: data[i].groupId });
                                }
                                break;
                            }

                        }



                    }

                }

                let tempGroupParent = 0;
                if (me.state.tempGroup.level != "Top Level") {
                    tempGroupParent = me.state.groupGroupsOptions.filter(g => g.value == me.state.tempGroup.parentId[0])[0].value;
                }

                me.setState({
                    editGroup: true,
                    modal: !me.state.modal,
                    anchorEl: null,
                    modalTitle : "Edit Group",
                    orgGroupMembers: groupMembers,      // Includes ALL members of hierarchy - only DIRECT children editable
                    tempGroupGroups: this.groupMembersToGroups(groupMembers.filter(g => g.groupType == "GROUP" && g.parentId == me.state.tempGroup.groupId)),
                    tempGroupUsers: this.groupMembersToOptions(groupMembers.filter(g => g.groupType == "USER" && g.parentId == me.state.tempGroup.groupId)),
                    tempGroupSites: this.groupMembersToOptions(groupMembers.filter(g => g.groupType == "SITE" && g.parentId == me.state.tempGroup.groupId)),
                    tempGroupRegions: this.groupMembersToOptions(groupMembers.filter(g => g.groupType == "REGION" && g.parentId == me.state.tempGroup.groupId)),
                    tempGroupParent: tempGroupParent
                });
                setTimeout(() => {
                    this.datagridInstance?.refresh();
                    this.setState({ loading: false })
                }, 1000);

                me.reloadData(null);

                me.datagridInstance?.endCustomLoading();
            })
            .catch(function (error) {
                console.log(error)
            });
               
    }


    customSortingMethod = (a, b) => {
        const level = ['Top Level', 'Second Level', 'Third Level']
        return level.indexOf(a) - level.indexOf(b)
    }

    //save changes, for both edit and new
    saveGroup = (): void => {
        const me = this;

        if (this.state.editGroup === false) { //adding a new group
            const tempGroup = this.state.tempGroup;
            
            this.setState({
                tempGroup: tempGroup,
                modal: !this.state.modal,
                loading: true
            });

            //send group to API
           
           
            //save group
            //let parentId = 0;
            //if (me.state.tempGroupParent != 0) {
                 //parentId = me.state.groupGroupsOptions.filter(g => g.value == me.state.tempGroupParent)[0].value;
            //}

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json-patch+json' },
                //mode: 'no-cors',
                body: JSON.stringify({
                    parentId: me.state.tempGroupParent,
                    companyId: sessionStorage.getItem('companyId'),
                    name: tempGroup.name,
                    active: true
                })
            };
                     
            CallGetAPI(CreateUrl('/api/aquaguard/Group'), requestOptions)
                .then(data => {
                    const group = me.state.tempGroup;
                    group.id = data.id
                    group.lastUpdate = data.lastUpdate;
                    me.store.push([{ type: 'insert', key: group.id, data: group }]);
                    me.setState({
                        tempGroup: group,
                    });

                    // Add or remove group members
                    const tempGroupMembers: Array<GroupMember> = [];
                    me.state.tempGroupGroups.map(g => tempGroupMembers.push({ value: g.value, label: g.label, groupType: "GROUP", parentId: g.parents[0] }));
                    me.state.tempGroupUsers.map(u => tempGroupMembers.push({ value: u.value, label: u.email, groupType: "USER", parentId: 0 }));
                    me.state.tempGroupSites.map(s => tempGroupMembers.push({ value: s.value, label: `${s.siteName}(${s.siteRef})`, groupType: "SITE", parentId: 0 }));
                    me.state.tempGroupRegions.map(r => tempGroupMembers.push({ value: r.value, label: r.label, groupType: "REGION", parentId: 0 }));

                    if (tempGroupMembers.length > 0) {
                        const updatePromises: Array<Promise<any>> = [];
                        const addedmembers = [];
                        tempGroupMembers.map((grp) => {
                            // Need to add member
                            const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json-patch+json' },
                                //mode: 'no-cors',
                                body: JSON.stringify({ groupId: group.id, memberId: grp.value, groupType: grp.groupType })
                            };
                            updatePromises.push(
                                CallPostAPI(CreateUrl('/api/aquaguard/AddGroupMember'), requestOptions)
                                    .then(() => {
                                        addedmembers.push({ memberId: grp.value, groupType: grp.groupType });
                                    })
                                    .catch(function (ex) {
                                        console.log(ex);
                                    })
                            );
                        });

                        Promise.all(updatePromises).then(() => {
                            me.createSuccessNotification();
                            //UI update
                            setTimeout(() => {
                                this.datagridInstance?.refresh();
                                //window.location.reload()
                            }, 1000);

                            me.reloadData(null);

                            me.datagridInstance?.endCustomLoading();

                        })
                            .catch(function (error) {
                                me.createFailedInsertErrorNotification();
                                console.log(error);
                            });
                    }
                    else {
                        // No members to add
                        me.createSuccessNotification();
                        //UI update
                        setTimeout(() => {
                            this.datagridInstance?.refresh();
                            this.setState({loading: false})
                        }, 1000);

                        me.reloadData(null);

                        me.datagridInstance?.endCustomLoading();
                    }

                },
                // reject() - insert failed
                    (data) => {
                        if (data.status == 409) {
                            me.createDuplicateInsertErrorNotification();
                        }
                        else {
                            me.createFailedInsertErrorNotification();
                        }
                })
            .catch(function (error) {
                    me.createFailedInsertErrorNotification()
                    console.log(error)
                });
        }
        else {//modifying an existing group
           
            const tempGroup = this.state.tempGroup;

            this.store.push([{ type: 'update', key: tempGroup.id, data: tempGroup }]);

            this.setState({
                modal: !this.state.modal,
                loading: true
            });

            //********************
            //send model to API
          
            const me = this;

            //save group
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
                body: JSON.stringify({
                    id: tempGroup.groupId,
                    name: tempGroup.name,
                    path: tempGroup.path,
                    active: true,
                    lastUpdate: tempGroup.lastUpdate,
                })
            };

            CallPutAPI(CreateUrl('/api/Aquaguard/Group/' + tempGroup.groupId), requestOptions)
                .then(async response => {
                    if (response.status == 409) {
                        // Conflicting edit detected
                        me.createConflictNotification();
                        console.log("Edit conflict");
                        me.reloadData(tempGroup.id);
                    }
                    else {
                        if (response.status == 200) {
                            // Add or remove group members
                            const tempGroupMembers: Array<GroupMember> = [];
                            me.state.tempGroupGroups.map(g => tempGroupMembers.push({ value: g.value, label: g.label, groupType: "GROUP", parentId: g.parents[0] }));
                            me.state.tempGroupUsers.map(u => tempGroupMembers.push({ value: u.value, label: u.label, groupType: "USER", parentId: 0 }));
                            me.state.tempGroupSites.map(s => tempGroupMembers.push({ value: s.value, label: s.label, groupType: "SITE", parentId: 0 }));
                            me.state.tempGroupRegions.map(r => tempGroupMembers.push({ value: r.value, label: r.label, groupType: "REGION", parentId: 0 }));

                            const updatePromises: Array<Promise<any>> = [];
                            const removedMembers = [];
                            const addedMembers = [];
                            me.state.orgGroupMembers.map((grp) => {
                                if (!tempGroupMembers.some(g => g.value == grp.value && g.groupType == grp.groupType)) {
                                    // Need to remove member
                                    const requestOptions = {
                                        method: 'POST',
                                        headers: { 'Content-Type': 'application/json' },
                                        //mode: 'no-cors',
                                        body: JSON.stringify({ groupId: tempGroup.groupId, memberId: grp.value, groupType: grp.groupType, company: sessionStorage.getItem('companyId') })
                                    };
                                    updatePromises.push(
                                        CallPostAPI(CreateUrl('/api/aquaguard/RemoveGroupMember'), requestOptions)
                                            .then(() => {
                                                removedMembers.push({ memberId: grp.value, groupType: grp.groupType });
                                            })
                                            .catch(function (ex) {
                                                console.log(ex);
                                            })
                                    );
                                }
                            });
                            tempGroupMembers.map((grp) => {
                                if (!me.state.orgGroupMembers.some(g => g.value == grp.value && g.groupType == grp.groupType)) {
                                    // Need to add member
                                    const requestOptions = {
                                        method: 'POST',
                                        headers: { 'Content-Type': 'application/json' },
                                        //mode: 'no-cors',
                                        body: JSON.stringify({ groupId: tempGroup.groupId, memberId: grp.value, groupType: grp.groupType, company: sessionStorage.getItem('companyId') })
                                    };
                                    updatePromises.push(
                                        CallPostAPI(CreateUrl('/api/aquaguard/AddGroupMember'), requestOptions)
                                            .then(() => {
                                                addedMembers.push({ memberId: grp.value, groupType: grp.groupType });
                                            })
                                            .catch(function (ex) {
                                                console.log(ex);
                                            })
                                    );
                                }
                            });

                            Promise.all(updatePromises).then(() => {
                                me.createSuccessNotification();
                                
                                //UI update
                                setTimeout(() => {
                                    this.datagridInstance?.refresh();
                                    this.setState({loading: false})
                                }, 1000);

                                me.reloadData(null);

                                me.datagridInstance?.endCustomLoading();

                            })
                            .catch(function (error) {
                                me.createFailedInsertErrorNotification();
                                console.log(error);
                            });
                        }
                        else
                            me.createErrorNotification();
                    }
                })
                .catch(function (error) {
                    me.createErrorNotification()
                    console.log(error)
                });
        }
    }


    //do nothing, close the modal
    cancel = (): void => {
        this.toggleModal();
        this.setState({selectedTab: 0})
    }

    toggleModal = (): void => {
        this.setState({
            modal: !this.state.modal
        });
    }
    //show edit buttons
    toggleEdit = (): void => {
        this.setState({
            edit: !this.state.edit
        });
    }

    toggleDeleteModal = (): void => {
        this.setState({
            deleteModal: !this.state.deleteModal,
            anchorEl: null,
            modalTitle: "Warning"
        });

    }

    deleteRow = (): void => {

        const me = this;

        this.setState({
            deleteModal: !this.state.deleteModal,
        });

        //delete from database
        CallDeleteAPI(CreateUrl('/api/aquaguard/Group?id=' + this.state.tempGroup.groupId), {})
            .then(async response => {
                if (response.status == 200) {
                    me.createSuccessNotification();
                    //remove the old version of the row
                    //this.store.push([{ type: 'remove', key: me.state.tempGroup.id }]);
                    this.reloadData(null)
                }
                else {
                    me.createErrorNotification();
                }
            })
            .catch(function (error) {
                me.createErrorNotification();
                console.log(error);
            });

    }

    //field changed events---
    groupNameChanged = (event: any): void => {
        const tempGrp = this.state.tempGroup;
        tempGrp.name = event.target.value;
        this.setState( { tempGroup: tempGrp }); 
    }
    groupTagsChanged = (event: any): void => {
        const tempGrp = this.state.tempGroup;
        tempGrp.tags = event.target.value;
        this.setState( { tempGroup: tempGrp }); 
    }
    //-------------------------


    handleUsersInputChange = (data): void => {
        const selectedUsers = data.selectedRowKeys
        this.setState({
            tempGroupUsers: selectedUsers
        });
        
        //save the added item
    };

    handleSitesInputChange = (data): void => {
        const selectedSites = data.selectedRowKeys
        this.setState({
            tempGroupSites: selectedSites
        });
        //save the added item
    };

    handleRegionsInputChange = (event: any): void => {
        let mulitSelectContents = event;
        if (mulitSelectContents === null) {
            mulitSelectContents = [];
        }
        this.setState({
            tempGroupRegions: mulitSelectContents
        });
        //save the added item
    };

    handleGroupsInputChange = (data): void => {
        const selectedGroups = data.selectedRowKeys
        this.setState({
            tempGroupGroups: selectedGroups
        });

        //save the added item
    };

    handleParentInputChange = (event: any): void => {
        this.setState({
            tempGroupParent: event.target.value,
        })
    };

    handleClick(event: React.MouseEvent<HTMLElement>, id: number): void {
                this.setState(
                    {
                        tempGroup: this.state.dataSource.filter(group => group.id === id)[0],
                        anchorEl: event.currentTarget
                    });
    }

    moreRender(group: any): React.ReactNode {
        return (
            <IconButton size="small" onClick={(e): void => { this.handleClick(e, group.data.id); }}><MoreHorizIcon /></IconButton>
        );
    }

    groupMembers(key: any): React.ReactNode {
        //const site = this.state.rows.find((row) => row.id === key.data.id);
       
        //var group = this.state.rows.find((row) => row.id === id);
        return (
            <GroupMembers data={[{label: 'Group1', type: 'group'}, {label: '44404', type: 'device'}]} />
        );
    }

    handleClose = (): void => {
        this.setState({ anchorEl: null });
    }

    groupDetailsClick = (): void => {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/groupDetail',
                redirectProps: {
                    group: this.state.tempGroup,
                    groupOptions: this.state.groupGroupsOptions,
                    userOptions: this.state.groupUsersOptions,
                    siteOptions: this.state.groupSitesOptions,
                    regionOptions: this.state.groupRegionsOptions,
                    contactOptions: this.state.groupContactsOptions,
                }
            });
    }

    graphLoggersClick = (): void => {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/multiLoggerGraph',
                redirectProps: {
                    groupId: this.state.tempGroup.groupId,
                    loggers: [],
                    loggerId: null,
                    showBackButton: true,
                }
            });
    }

    toggleReportDialog = (): void => {
        CallGetAPI(CreateUrl(`/api/aquaguard/GetLoggers?companyid=${sessionStorage.getItem('companyId')}&filterGroup=${this.state.tempGroup.groupId}`), {})
            .then(data => {
                const loggers = data.map(item => { return { id: item.id, serial: item.serial } })
                if (loggers.length > 0) {
                    this.setState({
                        loggersInGroup: loggers,
                        reportDialog: !this.state.reportDialog,
                        anchorEl: null
                    })
                } else {
                    alert("Group does not contain any loggers, please add some loggers to this group")
                }
            })
    }


    /// Replace Group ID's with name
    groupsRender(cellInfo: any): React.ReactNode {
        const names: string[] = [];
        if (cellInfo.value == null)
            return "";
        else
            cellInfo.value.map((id: number) => {
                if (id == 0 || this.state.groupGroupsOptions.filter(g => g.value == id).length == 0) {
                    names.push("NONE");
                }
                else {
                    names.push(this.state.groupGroupsOptions.filter(g => g.value == id)[0].label);
                }
            });

        return (<p><pre>{names.join('\n')}</pre></p>);
    }

    //loadTabs():unknown {
    //    const tabs = []
    //    let tempParentPresent = false
    //    if (this.state.groupGroupsOptions.filter(group => group.value === this.state.tempGroupParent).length > 0) {
    //        if (this.state.groupGroupsOptions.filter(group => group.value === this.state.tempGroupParent)[0].generation < 1) {
    //            tempParentPresent = true
    //        }
    //    } else if (this.state.tempGroupParent === 0) {
    //        tempParentPresent = true
    //    }
    //    if (sessionStorage.getItem('canConfig') === 'true') {
    //        tabs.push({
    //            tabName: "Users",
    //            tabIcon: PeopleOutlineIcon,
    //            tabContent: (
    //                <div style={{ padding: 10 }}>
    //                    <Select
    //                        isMulti
    //                        components={animatedComponents}
    //                        options={this.state.groupUsersOptions}
    //                        defaultValue={this.state.tempGroupUsers}
    //                        onChange={this.handleUsersInputChange}
    //                    />
    //                </div>
    //            ),
    //        })
    //    }
    //    tabs.push({
    //        tabName: "Sites",
    //        tabIcon: Sites,
    //        tabContent: (
    //            <div style={{ padding: 10 }}>
    //                <Select
    //                    isMulti
    //                    components={animatedComponents}
    //                    options={this.state.groupSitesOptions}
    //                    defaultValue={this.state.tempGroupSites}
    //                    onChange={this.handleSitesInputChange}
    //                />
    //            </div>
    //        )
    //    })
    //    if (tempParentPresent) {
    //        tabs.push({
    //            tabName: "Groups",
    //            tabIcon: GroupWorkIcon,
    //            tabContent: (
    //                <div style={{ padding: 10 }}>
    //                    <Select
    //                        isMulti
    //                        components={animatedComponents}
    //                        options={this.state.groupGroupsOptions.filter((g) => {
    //                            //const isParent = ("\\" + currentGroup.path + "\\").indexOf("\\" + g.label + "\\") >= 0;
    //                            //return g.value != currentGroup.id && g.value != this.state.tempGroupParent && !currentGroup.parentId.includes(g.value) && (g.subGroups == 0 || g.parents < 2);
    //                            return g.value != this.state.tempGroup.groupId && g.value != this.state.tempGroupParent && !this.state.tempGroup.parentId.includes(g.value) && (g.subGroups == 0 || g.parents < 2);

    //                        })}
    //                        defaultValue={this.state.tempGroupGroups}
    //                        onChange={this.handleGroupsInputChange}
    //                    />
    //                </div>
    //            )
    //        })
    //    } else {
    //        tabs.push({
    //            tabName: "Groups",
    //            tabIcon: GroupWorkIcon,
    //            tabContent: (
    //                <div style={{ padding: 10 }}>
    //                    <h4 style={{fontSize: "18px", textAlign: "center"}}>Sub groups can only go to three levels</h4>
    //                </div>
    //            )
    //        })
    //    }




    //    return tabs
    //}

    loadTabs(): any {
        const tabs = []
        let tempParentPresent = false
            if (this.state.groupGroupsOptions.filter(group => group.value === this.state.tempGroupParent).length > 0) {
                if (this.state.groupGroupsOptions.filter(group => group.value === this.state.tempGroupParent)[0].generation < 1) {
                    tempParentPresent = true
                }
            } else if (this.state.tempGroupParent === 0) {
                tempParentPresent = true
            }
        if (sessionStorage.getItem('canConfig') === 'true') {
            tabs.push({
                id: 0,
                text: "Users",
                icon: () => <PersonIcon />
            })
        }
        tabs.push({
                id: 1,
                text: "Sites",
            icon: () => <MultipleDevicesIcon  />
        })
        if (tempParentPresent) {
            tabs.push({
                id: 2,
                text: "Groups",
                icon: () => <GroupWorkIcon />
            })
        }

        return tabs
    }

    handleNodeClick(nodeKey, event):void {
        const itemSelected = this.state.dataSource.filter(d => d.name === nodeKey)[0]
        if (itemSelected) {
            this.setState(
                {
                    redirect: true,
                    redirectPath: '/portal/groupDetail',
                    redirectProps: {
                        group: itemSelected,
                        groupOptions: this.state.groupGroupsOptions,
                        userOptions: this.state.groupUsersOptions,
                        siteOptions: this.state.groupSitesOptions,
                        regionOptions: this.state.groupRegionsOptions,
                        contactOptions: this.state.groupContactsOptions,
                    }
                });
        } 
    }

    handleMouseEnterNode(event, nodeKey): void {
        const hoveredItem = this.state.dataSource.filter(d => d.name === nodeKey)[0]
        
        console.log(hoveredItem)
        if (hoveredItem?.groupId) {
            CallGetAPI(CreateUrl('/api/aquaguard/AllMembersForGroup?groupid=' + hoveredItem.groupId), {})
                .then(data => {
                    let sumOfDevices = 0
                    let sumOfSites = 0
                    const users = []
                    console.log(data, this.state.groupUsersOptions)
                    data.forEach(item => {
                        if (item.groupType === 'SITE') {
                            sumOfDevices += this.state.groupSitesOptions.filter(site => site.value === item.memberId)[0]?.numberOfDevices
                            sumOfSites += 1
                        }
                        if (item.groupType === 'USER') {
                            console.log(users)
                            const user = this.state.groupUsersOptions.filter(user => user.value === item.memberId)[0]?.email
                            if (!users.includes(user)) {
                                users.push(user)
                            }
                        }
                    })

                    const hoveredData = {
                        numOfDevices: isNaN(sumOfDevices) ? 0 : sumOfDevices,
                        users: users,
                        sites: sumOfSites
                    }
                    if (hoveredItem && hoveredData) {
                        this.setState({
                            hoveredItem: hoveredItem,
                            hoveredData: hoveredData,
                            tooltip: {
                                visible: true,
                                x: event.clientX,
                                y: event.clientY
                            }
                        })
                    }
                })
        }
            
        
        
    }

    handleMouseLeave(): void {
        this.setState({
            hoveredItem: {},
            tooltip: {
                visible: false,
                x: 0,
                y: 0
            }
        })
    }

    selectRender(key): React.ReactNode {
        return (
            <Checkbox value={key.key} checked={this.state.selected.indexOf(key.key) >= 0} onChange={this.checkRow.bind(this)} />
        )
    }

    canConfigRender(data): React.ReactNode {

        if (data.data.canConfig) {
            return <p>Yes</p>
        } else {
            return <p>No</p>
        }
    }

    firstLoginRender(data): React.ReactNode {

        if (!data.data.firstLogin) {
            return <p>Yes</p>
        } else {
            return <p>No</p>
        }
    }

    

    customSortingMethodGen = (a, b) => {

        console.log(a, b)
        const level = ['Top Level', 'Second Level', 'Third Level']
        return level.indexOf(a) - level.indexOf(b)
    }


    render(): React.ReactNode {

        
        const { classes } = this.props;
        const currentGroup = this.state.tempGroup;
        const generationList = ['Top Level', 'Second Level', 'Third Level']
        console.log(this.state.tempGroup, this.state.groupSitesOptions)
        return (this.state.authorized) ? //if we are authorized, show page, else redirect to login page
            (
                (!this.state.redirect) ?
                    (
                        <div>
                        
                    <Modal
                                show={this.state.deleteModal}
                                onHide={(): void => this.toggleDeleteModal()}
                                dialogClassName="modal-50w"
                                aria-labelledby="example-custom-modal-styling-title"
                                centered
                                style={{zIndex: 1000000000} }
                                >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {this.state.modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            Delete Group {this.state.tempGroup.name}?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.deleteRow()}>Continue</Button>
                            <Button color="secondary" variant="contained" onClick={(): void => this.toggleDeleteModal()}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modal}
                        onHide={(): void => this.toggleModal()}
                        dialogClassName="modal-50w"
                        contentClassName={styles.editModalStyle}
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                                >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {this.state.modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                                    <Grid container spacing={2}>

                                            <Grid item xs={6} >
                                                <TextField
                                                id="outlined-input-name"
                                                label="Name"
                                                defaultValue={this.state.tempGroup.name}
                                                variant="outlined"
                                                onChange={this.groupNameChanged}
                                                style={{width: "100%"} }
                                                />
                                            </Grid>
                                        <Grid item xs={6}>
                                            
                                            {this.state.editGroup ?
                                                <TextField
                                                    id="outlined-input-parent"
                                                    label="Parent Group"
                                                    variant="outlined"
                                                    disabled={this.state.editGroup}
                                                    defaultValue={this.state.tempGroupParent == 0 ? "Top level" :
                                                        this.state.groupGroupsOptions.filter((g) => g.value == this.state.tempGroupParent)[0].label + ` (${generationList[this.state.groupGroupsOptions.filter((g) => g.value == this.state.tempGroupParent)[0].generation]})`}
                                                    style={{ width: "100%" }}
                                                />
                                                
                                                :
                                                <FormControl variant="outlined" style={{ width: "100%" }}  >
                                                    <InputLabel id="parent-select-helper-label">Parent Group</InputLabel>
                                                    <MuiSelect
                                                        label="Parent Group"
                                                        labelId="parent-select-helper-label"
                                                        value={this.state.tempGroupParent}
                                                        onChange={this.handleParentInputChange.bind(this)}

                                                        displayEmpty={true}
                                                        style={{width: "100%"} }
                                                    >
                                                        <MenuItem value={0}>New Top level</MenuItem>
                                                        {this.state.groupGroupsOptions.filter((g) => g.value != currentGroup.id)
                                                            .map((grp) => {
                                                                
                                                                
                                                                if (grp.generation < 2) return <MenuItem value={grp.value} key={grp.value}>{`${grp.label} (${generationList[grp.generation]})`}</MenuItem>
                                                            })
                                                        }
                                                    </MuiSelect>
                                                </FormControl>
                                            }
                                            </Grid>
                                        <Grid item md={12} >
                                            <Tabs
                                                dataSource={this.loadTabs()}
                                                itemRender={(item): React.ReactNode => {
                                                    return (<div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {item.icon()}
                                                            
                                                            <span style={{ marginLeft: '8px' }}>{item.text}</span>
                                                        </div>)                                                 
                                                    }
                                                }
                                                defaultSelectedIndex={0}
                                                style={{ fontSize: "18px" }}
                                                selectionMode={'single'}
                                                selectedIndex={this.state.selectedTab}
                                                onItemClick={(e): void => this.setState({ selectedTab: e.itemIndex })}
                                            />
                                            {this.state.selectedTab === 0 && <div style={{ border: "1px solid #dddddd", padding: "10px", width: "100%" }}>
                                                {console.log(this.state.tempGroupUsers) }
                                                 <DataGrid
                                                    dataSource={this.state.groupUsersOptions}
                                                    onSelectionChanged={this.handleUsersInputChange}
                                                    selectedRowKeys={this.state.tempGroupUsers}
                                                    allowColumnResizing={true}
                                                    allowColumnReordering={true}
                                                >
                                                    <FilterRow visible={true} />
                                                    <Selection
                                                        mode="multiple"
                                                        selectAllMode='allPages'
                                                        showCheckBoxesMode='always' //'onClick'
                                                    />
                                                    <StateStoring enabled={true} type="localStorage" storageKey="groupUsersGrid" />
                                                    <Column dataField="email" type="string" caption="Email" />
                                                    <Column
                                                        dataField="canConfig"
                                                        //type="boolean"
                                                        caption="User Can Configure?"
                                                        cellRender={this.canConfigRender.bind(this)}
                                                        lookup={{
                                                            dataSource: [
                                                                { value: true, display: "Yes" },
                                                                { value: false, display: "No" },
                                                            ],
                                                            valueExpr: "value",
                                                            displayExpr: "display",
                                                        }}
                                                    />
                                                    <Column type="selection" caption="Select" fixed={true} fixedPosition="right" />
                                                    <Column visible={false} type="buttons" cellRender={this.selectRender.bind(this)} fixed={true} fixedPosition="right" caption="Select" width={50} />
                                                </DataGrid>
                                            </div>}
                                            {this.state.selectedTab === 1 && <div style={{ border: "1px solid #dddddd", padding: "10px", width: "100%" }}>
                                                <div style={{ padding: 10 }}>
                                                    {console.log(this.state.tempGroupSites)}
                                                    <DataGrid
                                                        dataSource={this.state.groupSitesOptions}
                                                        onSelectionChanged={this.handleSitesInputChange}
                                                        selectedRowKeys={this.state.tempGroupSites}
                                                        allowColumnResizing={true}
                                                        allowColumnReordering={true}
                                                    >
                                                        <FilterRow visible={true} />
                                                        <Selection
                                                            mode="multiple"
                                                            selectAllMode='allPages'
                                                            showCheckBoxesMode='always' //'onClick'
                                                        />
                                                        <StateStoring enabled={true} type="localStorage" storageKey="groupSitesGrid" />
                                                        <Column dataField="siteName" type="string" caption="Site Name" />
                                                        <Column dataField="siteRef" type="string" caption="Site Ref" />
                                                        <Column dataField="numberOfDevices" type="number" caption="Number Of Devices At Site" />
                                                        <Column type="selection" caption="Select" fixed={true} fixedPosition="right" />
                                                    </DataGrid>

                                                </div>
                                            </div>}
                                            {this.state.selectedTab === 2 && <div style={{ border: "1px solid #dddddd", padding: "10px", width: "100%" }}>
                                                {console.log(this.state.tempGroupGroups) }
                                                <DataGrid
                                                    dataSource={this.state.groupGroupsOptions.filter((g) => { return g.value != this.state.tempGroup.groupId && g.value != this.state.tempGroupParent && !this.state.tempGroup.parentId.includes(g.value) && (g.subGroups == 0 || g.parents < 2) && g.generation !== 0 })  }
                                                    onSelectionChanged={this.handleGroupsInputChange}
                                                    selectedRowKeys={this.state.tempGroupGroups}
                                                    allowColumnResizing={true}
                                                    allowColumnReordering={true}
                                                >
                                                    <FilterRow visible={true} />
                                                    <Selection
                                                        mode="multiple"
                                                        selectAllMode='allPages'
                                                        showCheckBoxesMode='always' //'onClick'
                                                    />
                                                    <GroupPanel visible={true} />
                                                    <StateStoring enabled={true} type="localStorage" storageKey="groupGroupsGrid" />
                                                    <Column dataField="level" groupIndex={0} sortingMethod={this.customSortingMethodGen} />
                                                    <Column dataField="label" type="string" caption="Group Name" />
                                                    <Column type="selection" caption="Select" fixed={true} fixedPosition="right" />
                                                    <Column visible={false} type="buttons" cellRender={this.selectRender.bind(this)} fixed={true} fixedPosition="right" caption="Select" width={50} />
                                                </DataGrid>
                                            </div>}
                                        </Grid>
                            </Grid>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="primary" variant="contained" style={{ marginRight: "10px" }}  onClick={(): void => this.saveGroup()}>Save</Button>
                            <Button color="secondary" variant="contained" onClick={(): void => this.cancel()}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>


                            <DeviceReportDialog
                                show={this.state.reportDialog}
                                title="Generate Report for Loggers on Sites in Group"
                                loggerIds={this.state.loggersInGroup.map(item => item.id)}
                                onCancel={this.toggleReportDialog.bind(this)}
                                deviceData={this.state.loggersInGroup}
                                fromGroup={true}
                                templateClicked={false}

                            />
                            {/*<LoggerReportDialog*/}
                            {/*    show={this.state.reportDialog}*/}
                            {/*    title="Generate Report for Loggers on Sites in Group"*/}
                            {/*    groupId={this.state.tempGroup.groupId}*/}
                            {/*    onSubmit={this.toggleReportDialog.bind(this)}*/}
                            {/*    onCancel={this.toggleReportDialog.bind(this)}*/}
                            {/*/>*/}

                            {//this.state.loading &&
                                <div hidden={!this.state.loading} style={{
                                    position: 'absolute', left: '50%', top: '50%',
                                    transform: 'translate(-50%, -50%)'
                                }}>
                                    <ClipLoader
                                        size={150}
                                        color={"#123abc"}
                                        loading={this.state.loading}
                                    />
                                </div>
                            }
                        
                            {//!this.state.loading &&
                                <div hidden={this.state.loading}>
                                    {this.state.tableHidden &&
                                        <ApiFailed />
                                    }
                                    {//!this.state.tableHidden &&
                                        <div hidden={this.state.tableHidden}>
                                            <GridContainer>

                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Card>
                                                        
                                                        <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <IconButton
                                                                    style={{ display: 'inline' }}
                                                                    onClick={(): void => this.props.history.goBack()}
                                                                    size="large"><ArrowBackIcon style={{ fill: "white" }} />
                                                                </IconButton>
                                                                <h4 style={{ display: 'inline', paddingTop: 12 }} className={classes.cardTitleWhite}>Groups</h4>
                                                            </div>
                                                            <IconButton aria-label="add" onClick={(): void => this.newGroup()} size="large">
                                                                <AddCircleOutlineIcon style={{ fill: "white" }} />
                                                            </IconButton>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <div style={{display: "flex", minHeight: "350px"} }>
                                                                <div style={{width: "60%"} }>
                                                            <DataGrid
                                                                //onInitialized={this.saveInstance}
                                                                //repaintChangesOnly={true}
                                                                dataSource={this.state.dataSource}
                                                                ref={this.gridRef}
                                                                allowColumnResizing={true}
                                                                allowColumnReordering={true}
                                                            >
                                                                <LoadPanel enabled={true} />
                                                                <StateStoring enabled={true} type="localStorage" storageKey="groupsListGrid" />
                                                                <GroupPanel visible={true} />
                                                                <Grouping autoExpandAll={true} />
                                                                <FilterRow visible={true} />
                                                                <HeaderFilter visible={true} />

                                                                <Column dataField="level" visible={true} groupIndex={0} sortingMethod={this.customSortingMethod} />
                                                                <Column dataField="name" visible={true} dataType="string" width={250} />
                                                                <Column dataField="parentId" visible={true} caption="Parent Group(s)" dataType='string' cellRender={this.groupsRender.bind(this)} />
                                                                <Column dataField="subGroups" visible={true} dataType='number' width={100} />

                                                                {/* <Column caption="Members" width={200} fixed={true} fixedPosition='right'  type="buttons" cellRender={this.groupMembers.bind(this)} key='more'/> */}
                                                                <Column fixed={true} fixedPosition='right' type="buttons" cellRender={this.moreRender.bind(this)} key='more' />

                                                                <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                                <Paging defaultPageSize={20} />
                                                                    </DataGrid>
                                                                </div>
                                                                <div style={{
                                                                    width: "40%", position: 'relative', marginLeft: "100px"
                                                                }}>
                                                                    <div style={{ width: "580px", height: "100%", display: "flex", zIndex: 0 }}>
                                                                        <div style={{ width: "100%", borderRight: this.state.treeData.depthOfTree > 0 ? "dashed 1px black" : 'none' }}><p style={{ textAlign: "center", marginTop: "8px" }}><b>Company</b></p></div>
                                                                        {this.state.treeData.depthOfTree >= 1 && <div style={{ width: "100%", borderRight: this.state.treeData.depthOfTree > 1 ? "dashed 1px black" : "none" }}><p style={{ textAlign: "center", marginTop: "8px" }}><b>Top Level</b></p></div>}
                                                                        {this.state.treeData.depthOfTree >= 2 && <div style={{ width: "100%", borderRight: this.state.treeData.depthOfTree > 2 ? "dashed 1px black" : 'none' }}><p style={{ textAlign: "center", marginTop: "8px" }}><b>Second Level</b></p></div>}
                                                                        {this.state.treeData.depthOfTree >= 3 && <div style={{ width: "100%" }}><p style={{ textAlign: "center", marginTop: "8px" }}><b>Third Level</b></p></div>}
                                                                    </div>
                                                                    {console.log(this.state.dataSource) }
                                                                    {this.state.treeData.name && <div style={{ position: "absolute", top: "20px" }}> 
                                                                        
                                                                        <AnimatedTree
                                                                        
                                                                        data={this.state.treeData}
                                                                        height={this.state.dataSource.length < 4 ? this.state.dataSource.length * 100 : 600}
                                                                        width={575}
                                                                        animated
                                                                        textProps={{
                                                                            dx: -20, 
                                                                            dy: -15, 
                                                                            textAnchor: 'start'  
                                                                        }}
                                                                        gProps={{
                                                                            onClick: (event, nodeKey): void => this.handleNodeClick(nodeKey, event),
                                                                            onMouseEnter: (event, nodeKey): void => this.handleMouseEnterNode(event, nodeKey),
                                                                            onMouseLeave: (): void => this.handleMouseLeave(),
                                                                            className: 'tree-item'
                                                                        }}
                                                                        svgProps={{
                                                                            className: 'custom-tree',
                                                                            
                                                                            }}
                                                                        labelProp='label'

                                                                    /></div>}
                                                                    
                                                                </div>
                                                                {this.state.tooltip.visible &&
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        left: this.state.tooltip.x - 400,
                                                                        top: this.state.tooltip.y - 200,
                                                                        border: "1px solid black",
                                                                        boxShadow: "0 0 10px black",
                                                                        padding: "20px",
                                                                        backgroundColor: 'white',
                                                                        zIndex: 1000000
                                                                    }}
                                                                    >
                                                                        <p><b>No. of Devices:</b> {this.state.hoveredData.numOfDevices}  </p>
                                                                        <p><b>No. of Users:</b> {this.state.hoveredData.users.length}</p>
                                                                        <p><b>No. of Sites:</b> {this.state.hoveredData.sites}</p>
                                                                        <p><b>Last updated:</b> {moment(this.state.hoveredItem.lastUpdate).format('Do MMM HH:mm:ss')}</p>
                                                                    </div>}
                                                            <style>{`
                                                                    .custom-tree {
                                                                      padding: 20px;
                                                                    }
                                                                    .tree-item:hover {
                                                                        cursor: pointer;
                                                                    }
                                                                    .tree-item-text {
                                                                        background-color: white !important;
                                                                        z-index: 1000;
                                                                    }
                                                                  `}</style>
                                                            </div>
                                                            </CardBody>
                                                            
                                                    </Card>
                                                </GridItem>
                                            </GridContainer>
                                        </div>
                                    }
                                </div>
                            }

                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                onClose={this.handleClose}
                                disableScrollLock={true}
                            >
                                <MenuItem onClick={this.editGroup}>Edit</MenuItem>
                                <MenuItem onClick={this.groupDetailsClick}>Details</MenuItem>
                                <MenuItem onClick={this.graphLoggersClick.bind(this)}>Graph Loggers</MenuItem>
                                <MenuItem onClick={this.toggleReportDialog.bind(this)}>Export Logger Report</MenuItem>
                                {sessionStorage.getItem('canConfig') === 'true' && <MenuItem onClick={this.toggleDeleteModal}>Delete</MenuItem>}
                            </Menu>

                            <NotificationContainer />

                        </div>
                )
                :
                (<Redirect push to={{
                    pathname: this.state.redirectPath,
                    state: this.state.redirectProps
                }}/>)
           )
            :
            (
                <Redirect to={'/Login'} />
            );
    }
}

export default withStyles(styles)(Groups);

