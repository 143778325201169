import React from "react";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { ReactComponent as AshLogo } from '../../assets/ashLogo.svg';
import CardFooter from '../../components/Card/CardFooter.js';
import BG from '../../assets/BG-Aquaguard.jpg';
import Footer from 'components/Navbars/footer'
import { Link } from 'react-router-dom'
import GutermannLogo from '../../assets/gutermann/gutermnn-full-brand-white.png'
const PrivacyPolicy: React.FC = () => {

    
    
    return (
        <>
            <div style={{
                backgroundImage: `url(${BG})`,
                width: '100vw',
                height: '100vh',
                


            }}>

                <div className='some-page-wrapper'>
                    <div>

                        <div className='column' >
                            <div>
                                <Card style={{
                                    margin: "3vw 0 0 0",
                                    background: 'linear-gradient(130deg, rgba(0, 0, 0, 0.7) 0%, rgb(20,60,103, 0.6) 70%',
                                    width: "90vw",
                                    height: "80vh",
                                    boxShadow: "2px 2px 10px black",
                                    padding: "5vh 0 0 0",
                                    overflow: "auto",
                                    msOverflowStyle: "none",  
                                    scrollbarWidth: "none"    
                                }}>
                                    <div>
                                        <CardHeader>
                                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"} }>
                                                {window.location.hostname.includes('zonelog.net') ? <img src={GutermannLogo} style={{ width: "30%", height: "90%", margin: "60px 0 20px 0" }} /> : <AshLogo style={{ width: "50%", marginTop: "-50px", textAlign: "center" }} />}
                                                <h3 style={{ color: "white", marginTop: "-30px", fontSize: "28px" }}>{window.location.hostname.includes('zonelog.net') ? 'ZONELOG' : 'AquaGuard'}</h3>
                                                <h1 style={{ marginTop: "10px", color: "white", fontSize: "33px" }}>{window.location.hostname.includes('zonelog.net') ? 'Legal' : 'Privacy Policy'}</h1>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            {!window.location.hostname.includes('zonelog.net') ?
                                                <div style={{ color: "white", margin: "0 20px" }}>
                                                <h4 style={{fontSize: "22px" } }>Introduction</h4>
                                                <p style={{fontSize: "14px"} }>This privacy policy sets out how Seiche Water Technology Group uses and protects any information that
                                                you give Seiche Water Technology Group when you use our website and/or provide us with personal
                                                information via email, in person or in writing.
                                                Seiche Water Technology Group is committed to ensuring that your privacy is protected. Should we ask
                                                you to provide certain information by which you can be identified when using this website, then you can
                                                    be assured that it will only be used in accordance with this privacy statement. </p>
                                                <h4 style={{ fontSize: "22px" }}>What we collect</h4>
                                                <p style={{fontSize: "22px" } }>We may collect the following information:</p>
                                                <ul>
                                                    <li style={{ fontSize: "14px" }}>name and job title</li>
                                                    <li style={{ fontSize: "14px" }}>contact information including email address</li>
                                                    <li style={{ fontSize: "14px" }}>demographic information such as postcode, preferences, and interests</li>
                                                    <li style={{ fontSize: "14px" }}>other information relevant to customer surveys and/or offers</li>
                                                </ul>
                                                <h4 style={{ fontSize: "22px" }}>What we do with the information we gather</h4>
                                                <p style={{ fontSize: "14px" }}>We require this information to understand your needs and provide you with a better service, and in
                                                    particular for the following reasons:</p>
                                                <ul>
                                                    <li style={{ fontSize: "14px" }}>Internal record keeping</li>
                                                    <li style={{ fontSize: "14px" }}>We may use the information to improve our products and services</li>
                                                    <li>We may periodically send promotional emails about new products, special offers or other
                                                        information which we think you may find interesting using the email address which you have
                                                        provided</li>
                                                    <li style={{ fontSize: "14px" }}>From time to time, we may also use your information to contact you for market research purposes.
                                                        We may contact you by email, phone, or mail. We may use the information to customise the
                                                        website according to your interests</li>
                                                </ul>
                                                <h4 style={{ fontSize: "22px" }}>Security</h4>
                                                <p style={{ fontSize: "14px" }}>We are committed to ensuring that your information is secure. In order to prevent unauthorised access
                                                    or disclosure, we have put in place suitable physical, electronic and managerial procedures to
                                                    safeguard and secure the information we collect online.</p>
                                                <h4 style={{ fontSize: "22px" }}>How we use cookies</h4>
                                                <p style={{ fontSize: "14px" }}>A cookie is a small file which asks permission to be placed on your computer&#39;s hard drive. Once you
                                                    agree, the file is added, and the cookie helps analyse web traffic or lets you know when you visit a
                                                    particular site. Cookies allow web applications to respond to you as an individual. The web application
                                                    can tailor its operations to your needs, likes and dislikes by gathering and remembering information
                                                    about your preferences.
                                                    We use traffic log cookies to identify which pages are being used. This helps us analyse data about web
                                                    page traffic and improve our website in order to tailor it to customer needs. We only use this information
                                                    for statistical analysis purposes and then the data is removed from the system.
                                                    Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you
                                                    find useful and which you do not. A cookie in no way gives us access to your computer or any
                                                    information about you, other than the data you choose to share with us.
                                                    You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but
                                                    you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from
                                                    taking full advantage of the website.</p>
                                                <h4 style={{ fontSize: "22px" }}>Links to other websites</h4>
                                                <p style={{ fontSize: "14px" }}>Our website may contain links to other websites of interest. However, once you have used these links to
                                                    leave our site, you should note that we do not have any control over that other website. Therefore, we
                                                    cannot be responsible for the protection and privacy of any information which you provide whilst visiting
                                                    such sites and such sites are not governed by this privacy statement. You should exercise caution and
                                                    look at the privacy statement applicable to the website in question.
                                                </p>
                                                <h4 style={{ fontSize: "22px" }}>Controlling your personal information</h4>
                                                <p style={{ fontSize: "14px" }}>You may choose to restrict the collection or use of your personal information in the following ways:</p>
                                                <ul>
                                                    <li style={{ fontSize: "14px" }}>whenever you are asked to fill in a form on the website, look for the box that you can click to
                                                        indicate that you do not want the information to be used by anybody for direct marketing
                                                        purposes</li>
                                                    <li style={{ fontSize: "14px" }}>if you have previously agreed to us using your personal information for direct marketing purposes,
                                                        you may change your mind at any time by writing to or emailing us at <a style={{textDecoration: "underline", color: "white"}} href="mailto:marketing@seiche.com">marketing@seiche.com</a></li>
                                                </ul>
                                                <p style={{ fontSize: "14px" }}>We will not sell, distribute, or lease your personal information to third parties unless we have your
                                                    permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you
                                                    wish this to happen.
                                                    You may request details of personal information which we hold about you under the Data Protection
                                                    Act 1998. A small fee will be payable. If you would like a copy of the information held on you please
                                                    write to: SWTG, Seiche Ltd, Bradworthy Industrial Estate, Langdon Road, Bradworthy, Holsworthy, Devon,
                                                    United Kingdom, EX22 7SF.
                                                    If you believe that any information, we are holding on you is incorrect or incomplete, please write to or
                                                    email us as soon as possible, at the above address. We will promptly correct any information found to
                                                    be incorrect.
                                                    We will hold your information for 24 months, after which is will be deleted securely.
                                                </p>
                                                <h4 style={{ fontSize: "22px" }}>Maintaining this Policy</h4>
                                                <p style={{ fontSize: "14px" }}>SWTG will monitor the effectiveness of this policy and its general compliance within SWTG.
                                                    This policy will be kept up to date and amended accordingly to reflect any changes in response to
                                                    revised legislation and applicable standards and guidelines.
                                                </p>
                                                </div> : 
                                                <div style={{ color: "white", margin: "0 20px" }}>
                                                    <h2>Imprint</h2>
                                                        CORPORATE HEADQUARTERS<br />
                                                        Gutermann AG<br />
                                                        Gubelstrasse 15<br />
                                                        CH-6300 Zug<br />
                                                        Switzerland<br />
                                                        <br />
                                                        <p>Email: <a href="mailto:info@gutermann-water.com" style={{color: "white", textDecoration: "underline"} }>info@gutermann-water.com</a></p>
                                                        <p>Phone: +41-41-760-6033</p>

                                                    <h3>General Terms & Conditions</h3>
                                                        Please find the General Terms & Conditions for buying our products here.<br />

                                                    <h3>Data Protection and Privacy</h3>
                                                        The following data protection declaration applies to the use of our online services<br />

                                                    www.gutermann-water.com<br />
                                                    www.myeasyscan.net<br />
                                                    www.gutermann.cloud<br />
                                                    www.zonescan.net<br />
                                                    www.zonelog.net<br />

                                                        (hereinafter &quot;Website&quot;).<br />

                                                    We attach great importance to data protection. Your personal data is collected and processed in compliance with the applicable data protection regulations, in particular the General Data Protection Regulation (GDPR).<br />

                                                    <h4>1 Responsible</h4>
                                                    Responsible for the collection, processing and use of your personal data within the meaning of Art. 4 No. 7 GDPR is<br />

                                                    Uri Gutermann<br />
                                                    Landis + Gyr-Strasse 1<br />
                                                    CH-6300 Zug<br />
                                                    Switzerland<br />

                                                        If you wish to object to the collection, processing or use of your data by us in accordance with these data protection regulations as a whole or for individual measures, you can address your objection to the person responsible.<br />

                                                    You can save and print out this data protection declaration at any time.<br />

                                                    <h4>2 General purposes of processing</h4>
                                                    We only process personal data of our users if this is necessary to provide a functional website as well as our contents and services. The processing of personal data of our users takes place regularly only after consent of the user. An exception applies in those cases where prior consent cannot be obtained for real reasons and the processing of the data is permitted by law.<br />

                                                    <h4>3 Data erasure and storage time</h4>
                                                    The personal data of the person concerned will be deleted or blocked as soon as the purpose of storage ceases to apply. Furthermore, data may be stored if this has been provided for by the European or national legislator in EU regulations, laws or other provisions to which the person responsible is subject. The data will also be blocked or deleted if a storage period prescribed by the aforementioned standards expires, unless there is a need for further storage of the data for the conclusion or fulfilment of a contract.<br />

                                                    <h4>4 Which data we use and why</h4>
                                                    <h5>4.1 Hosting</h5>
                                                    The hosting services we use serve to provide the following services: Infrastructure and platform services, computing capacity, storage and database services, security services and technical maintenance services that we use to operate the Site.<br />

                                                    We or our hosting provider process inventory data, contact data, content data, contract data, usage data, meta- and communication data of customers, interested parties and visitors to this website on the basis of our legitimate interests in an efficient and secure provision of our website in accordance with Art.6 Para. 1 S. 1 f) GDPR in conjunction with. Art. 28 GDPR.<br />

                                                    <h5>4.2 Access data</h5>
                                                    We collect information about you when you use this website. We automatically collect information about your usage behavior and interaction with us and record data about your computer or mobile device. We collect, store and use data about every access to our website (so-called server log files). Access data includes:<br />
                                                    <ul>
                                                        <li>Name and URL of the retrieved file</li>
                                                        <li>Date and time of retrieval</li>
                                                        <li>Transferred data volume</li>
                                                        <li>Message about successful retrieval (HTTP response code)</li>
                                                        <li>Browser type and browser version</li>
                                                        <li>Operating system</li>
                                                        <li>Referer URL (i.e. the previously visited page)</li>
                                                        <li>Websites accessed by the user&apos;s system through our website</li>
                                                        <li>Internet service provider of the user</li>
                                                        <li>IP address and the requesting provider</li>
                                                        <li>This log data is automatically deleted after 30 days.</li>
                                                    </ul>

                                                    We use this log data without allocation to your person or other profiling for statistical evaluations for the purpose of operating, security and optimisation of our website, but also for anonymous recording of the number of visitors to our website (traffic) and the extent and type of use of our website and services, as well as for billing purposes in order to measure the number of clicks received from cooperation partners. Based on this information, we can provide personalized and location-based content, analyze traffic, troubleshoot and correct errors, and improve our services.<br />

                                                    This is also our legitimate interest pursuant to Art 6 para. 1 sentence 1 f) GDPR.<br />

                                                    We reserve the right to check the log data subsequently if there is a justified suspicion of illegal use based on concrete evidence. We store IP addresses in the log files for a limited period of time if this is necessary for security purposes or for the provision or billing of a service, e.g. if you use one of our offers. After the process is aborted, we delete the IP address if it is no longer required for security purposes. We also store IP addresses if we have a concrete suspicion of a criminal offence in connection with the use of our website. In addition, we store the date of your last visit as part of your account (e.g. when registering, logging in, clicking on links, etc.).<br />

                                                    <h5>4.3 Cookies</h5>
                                                    We use so-called session cookies to optimize our website. A session cookie is a small text file that is sent by the respective servers when you visit a website and stored temporarily on your hard drive. This file as such contains a so-called session ID, with which you can assign various requests of your browser to the common session. This will allow your computer to be recognized when you return to our website. These cookies are deleted after you close your browser. They are used, for example, to enable you to use the shopping basket function across several pages.<br />

                                                    We also use persistent cookies (also small text files that are stored on your terminal device) to a small extent, which remain on your terminal device and enable us to recognize your browser the next time you visit. These cookies are stored on your hard disk and are deleted automatically after the specified time. Their life span is 1 month to 10 years. This enables us to present our services to you in a more user-friendly, effective and secure manner and, for example, to display information on the site that is specifically tailored to your interests.<br />

                                                    Our legitimate interest in the use of cookies pursuant to Art. 6 para. 1 sentence 1 f) GDPR is to make our website more user-friendly, effective and secure.<br />

                                                    The following data and information are stored in the cookies:<br />

                                                    <ul>
                                                        <li>log-in information</li>
                                                        <li>Language settings</li>
                                                        <li>Entered search terms</li>
                                                        <li>Information about the number of visits to our website and use of individual functions of our website.</li>
                                                    </ul>
                                                    When the cookie is activated, it is assigned an identification number and your personal data is not assigned to this identification number. Your name, IP address or similar data that would allow the cookie to be assigned to you will not be placed in the cookie. Based on the cookie technology, we only receive pseudonymous information, for example about which pages of our website have been visited, which products have been viewed, etc. We do not collect any personal data from you.<br />

                                                    You can set your browser so that you are informed in advance about the setting of cookies and can decide in individual cases whether you want to exclude the acceptance of cookies for certain cases or in general, or that cookies are completely prevented. This may limit the functionality of the website.<br />

                                                        <h5>4.4 Data for the fulfilment of our contractual obligations</h5>
                                                    We process personal data that we need to fulfil our contractual obligations, such as name, address, e-mail address, ordered products, billing and payment data. The collection of this data is necessary for the conclusion of the contract.<br />

                                                    The data will be deleted after expiry of the warranty periods and statutory retention periods. Data associated with a user account (see below) will always be retained for the duration of the account.<br />

                                                    The legal basis for the processing of this data is Art. 6 Par. 1 S. 1 b) GDPR, because this data is required so that we can fulfil our contractual obligations towards you.<br />

                                                    <h5>4.5 User account</h5>
                                                    You can create a user account on our website. If you wish, we need the personal data requested during login. When logging in later, only your email or user name and the password you have chosen are required. For the new registration we collect master data (e.g. name, address), communication data (e.g. e-mail address) and access data (user name and password). To ensure your proper registration and to prevent unauthorized registrations by third parties, you will receive an activation link by e-mail after registration to activate your account. Only after successful registration do we permanently store the data transmitted by you in our system.<br />

                                                    You can have us delete a user account once it has been created at any time without incurring any costs other than the transmission costs according to the basic tariffs. A message in text form to the contact data (e.g. e-mail, fax, letter) mentioned under point 1 is sufficient for this. We will then delete your stored personal data, as far as we do not have to store them for the processing of orders or due to legal storage obligations.<br />

                                                    The legal basis for the processing of these data is your consent pursuant to Art. 6 para. 1 sentence 1 a) GDPR.<br />

                                                    <h5>4.6 Newsletter</h5>
                                                    <h6>4.6.1 Description and scope of data processing</h6>
                                                    To register for the newsletter, the data requested in the registration process is required. The registration for the newsletter is logged. The following data is requested:<br />

                                                    Full name<br />
                                                    email address<br />
                                                    Address<br />
                                                    Phone (optional)<br />
                                                    In addition, the following data is collected upon registration:<br />

                                                    Date and time of registration<br />
                                                    After registering, you will receive a message at the email address provided asking you to confirm your registration (�double opt-in�). This is necessary to prevent third parties from registering with your email address.<br />

                                                    You can revoke your consent to receive the newsletter at any time and thus cancel the newsletter.<br />

                                                    We store the registration data as long as it is required for sending the newsletter. We store the logging of the registration and the shipping address as long as there was an interest in proving the originally given consent, as a rule these are the limitation periods for civil law claims, i.e. a maximum of three years.<br />

                                                    The legal basis for sending the newsletter is your consent in accordance with Art. 6 para. 1 sentence 1 a) in connection with Art. 7 GDPR. The legal basis for logging the registration is our legitimate interest in proving that the dispatch was carried out with your consent.<br />

                                                    You can cancel your registration at any time without incurring any costs other than the transmission costs according to the basic rates. A message in text form to the contact data (e.g. e-mail, fax, letter) mentioned under point 1 is sufficient for this. Of course you will also find a unsubscribe link in every newsletter.<br />

                                                    <h5>4.7 Product recommendations</h5>
                                                    We will send you regular product recommendations by e-mail, independent of the newsletter. In this way, we will provide you with information about products from our offer that you may be interested in based on your recent purchases of goods or services from us. In doing so, we strictly comply with legal requirements. You can object to this at any time without incurring any costs other than the transmission costs according to the basic tariffs. A message in text form to the contact data (e.g. e-mail, fax, letter) mentioned under point 1 is sufficient for this. Of course, you will also find a unsubscribe link in every e-mail.<br />

                                                    The legal basis for this is the legal permission according to Art. 6 para. 1 sentence 1 f) GDPR.<br />

                                                    <h5>4.8 E-Mail Contact</h5>
                                                    If you contact us (e.g. via contact form or e-mail), we will process your details to process your inquiry and in the event that follow-up questions arise.<br />

                                                    If the data processing takes place for the execution of pre-contractual measures which take place at your request or, if you are already our customer, for the execution of the contract, the legal basis for this data processing is Art. 6 Para. 1 S. 1 b) GDPR.<br />

                                                    We will only process further personal data if you give your consent (Art. 6 para. 1 sentence 1 a) GDPR) or if we have a legitimate interest in processing your data (Art. 6 para. 1 sentence 1 f) GDPR). For example, there is a legitimate interest in replying to your e-mail.<br />

                                                    <h5>4.9 Google Analytics</h5>
                                                    We use Google Analytics, a web analysis service of Google Inc. (&quot;Google&quot;). Google Analytics uses &quot;cookies&quot;, which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about the use of this website by page visitors is usually transferred to a Google server in the USA and stored there.<br />

                                                    This is also our legitimate interest pursuant to Art 6 para. 1 sentence 1 f) GDPR.<br />

                                                    Google has signed and certified itself under the Privacy Shield Agreement between the European Union and the USA. By doing so, Google undertakes to comply with the standards and regulations of European data protection law. Further information can be found in the following linked entry: <a href="https://www.privacyshield.gov/participant?id=a2zt00000000001L5AAI&status=Active" style={{color: "white", textDecoration: "underline"} }>https://www.privacyshield.gov/participant?id=a2zt00000000001L5AAI&status=Active.</a><br />

                                                    We have activated IP anonymization on this website (anonymizeIp). However, this will cause Google to reduce your IP address within Member States of the European Union or in other states party to the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and shortened there. On our behalf, Google will use this information to evaluate your use of the website, to compile reports on website activity and to provide us with other services relating to website and Internet use.<br />

                                                    The IP address transmitted by your browser in the context of Google Analytics is not merged with other Google data. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website.<br />

                                                    You can also prevent the transmission to Google of data generated by the cookie and relating to your use of the website (including your IP address) and the processing of this data by Google by downloading and installing the browser plug-in available under the following link: <a href="http://tools.google.com/dlpage/gaoptout?hl=en" style={{color: "white", textDecoration: "underline"} }>http://tools.google.com/dlpage/gaoptout?hl=en.</a><br />
 

                                                    <h5>4.10 Storage time</h5>
                                                    Unless specifically stated, we only store personal data for as long as is necessary to fulfil the purposes pursued. In some cases, the legislator provides for the storage of personal data, for example in tax or commercial law. In these cases, the data will only be stored by us for these legal purposes, but will not be processed in any other way and deleted after expiry of the legal retention period.<br />

                                                    <h4>5 Your rights as a data subject</h4>
                                                    According to the applicable laws, you have various rights regarding your personal data. If you wish to assert these rights, please send your request by e-mail or by post to the address specified in section 1, clearly identifying yourself.<br />

                                                    Below you will find an overview of your rights.<br />

                                                   <h5> 5.1 Right to information</h5>
                                                    You have the right to clear information about the processing of your personal data.<br />
                                                    <br />
                                                    In detail:<br />

                                                    You have the right to receive confirmation from us at any time as to whether personal data relating to you will be processed. If this is the case, you have the right to request from us free of charge information about the personal data stored about you together with a copy of this data. Furthermore, there is a right to the following information:<br />
                                                    <ol>
                                                        <li>processing purposes;</li>
                                                        <li>the categories of personal data being processed;</li>
                                                        <li>the recipients or categories of recipients to whom the personal data have been or are still being disclosed, in particular recipients in third countries or international organisations;</li>
                                                        <li>if possible, the planned duration for which the personal data will be stored or, if this is not possible, the criteria for determining this duration;</li>
                                                        <li>the existence of a right to have your personal data concerning you corrected or deleted or to have the data controller restrict or object to such processing;</li>
                                                        <li>the existence of a right of appeal to a supervisory authority;</li>
                                                        <li>if the personal data is not collected from you, all available information about the origin of the data;</li>
                                                        <li>the existence of automated decision making including profiling in accordance with Art. 22 para. 1 and 4 GDPR and &ndash; at least in these cases &ndash; meaningful information about the logic involved and the scope and intended effects of such processing for you.</li>
                                                    </ol>
                                                    If personal data are transferred to a third country or an international organisation, you have the right to be informed of the appropriate guarantees in accordance with Art. 46 GDPR in connection with the transfer.<br />

                                                    <h5>5.2 Right to correction</h5>
                                                    You have a right of rectification and/or completion vis-&agrave;-vis the data controller if the personal data processed concerning you are incorrect or incomplete. The person responsible shall make the correction without delay<br />

                                                    <h5>5.3 Right to cancellation (&quot;Right to oblivion&quot;)</h5>
                                                    In a number of cases we are obliged to delete personal data concerning you.<br />

                                                    Pursuant to Art. 17 para. 1 GDPR, you have the right to demand that we delete personal data concerning you without delay, and we are obliged to delete personal data without delay if one of the following reasons applies:<br />
                                                    <ol>
                                                        <li>personal data are no longer necessary for the purposes for which they were collected or otherwise processed.</li>
                                                        <li>you withdraw your consent, on which the processing was based pursuant to Art. 6 para. 1 sentence 1 a) GDPR or Art. 9 para. 2 a) GDPR, and there is no other legal basis for the processing.</li>
                                                        <li>you file an objection to the processing pursuant to Art. 21 para. 1 GDPR and there are no overriding legitimate grounds for the processing, or you file an objection to the processing pursuant to Art. 21 para. 2 GDPR.</li>
                                                        <li>The personal data have been processed unlawfully.</li>
                                                        <li>The deletion of personal data is necessary to fulfil a legal obligation under Union law or the law of the Member States to which we are subject.</li>
                                                        <li>The personal data was collected in relation to information society services offered in accordance with Art. 8 para. 1 GDPR.</li>
                                                        <li>If we have made the personal data public and we are obliged to delete them pursuant to Art. 17 para. 1 GDPR, we will take appropriate measures, including technical measures, taking into account the available technology and implementation costs, to inform those responsible for data processing who process the personal data that you have requested them to delete all links to this personal data or copies or replications of this personal data.</li>
                                                    </ol>
                                                    <h6>5.3.1 Exceptions</h6>
                                                    The right to cancellation does not exist insofar as the processing is necessary<br />
                                                    <ol>
                                                        <li>to exercise freedom of expression and information;</li>
                                                        <li>for the performance of a legal obligation required for processing under the law of the Union or of the Member States to which the controller is subject or for the performance of a task in the public interest or in the exercise of official authority conferred on the controller;</li>
                                                        <li>for reasons of public interest in the field of public health pursuant to Art. 9 para. 2 lit. h and i and Art. 9 para. 3 GDPR;</li>
                                                        <li>for archiving purposes in the public interest, scientific or historical research purposes or for statistical purposes pursuant to Art. 89 para. 1 GDPR, insofar as the law referred to under a) is likely to render impossible or seriously impair the attainment of the objectives of such processing, or</li>
                                                        <li>to assert, exercise or defend legal claims.</li>
                                                    </ol>
                                                    <h5>5.4 Right to limitation of processing</h5>
                                                    In a number of cases you are entitled to request us to restrict the processing of your personal data.<br />

                                                    You have the right to request us to restrict processing if one of the following conditions is met:<br />
                                                    <ol>
                                                        <li>you dispute the accuracy of your personal data for a period of time that enables us to verify the accuracy of your personal data,</li>
                                                        <li>the processing is unlawful and you have refused to delete the personal data and have instead requested the restriction of the use of the personal data;</li>
                                                        <li>we no longer need the personal data for the purposes of processing, but you do need the data to assert, exercise or defend legal claims, or</li>
                                                        <li>you have filed an objection against the processing pursuant to Art. 21 para. 1 GDPR, as long as it is not yet clear whether the justified reasons of our company outweigh yours.</li>
                                                    </ol>
                                                    <h5>5.5 Right to Data Transferability</h5>
                                                    You have the right to receive, transmit or have us transmit personal data concerning you in machine-readable form.<br />

                                                    You have the right to receive the personal data concerning you that you have provided to us in a structured, current and machine-readable format, and you have the right to transmit this data to another person in charge without our interference, provided that<br />
                                                    <ol>
                                                        <li>processing is based on consent pursuant to Art. 6 para. 1 sentence 1 a) GDPR or Art. 9 para. 2 a) GDPR or on a contract pursuant to Art. 6 para. 1 sentence 1 b) GDPR and</li>
                                                        <li>processing is carried out using automated methods.</li>
                                                    </ol>
                                                    When exercising your right to data transferability in accordance with paragraph 1, you have the right to request that the personal data be transferred directly by us to another person responsible, insofar as this is technically feasible.<br />

                                                    <h5>5.6 Right of objection</h5>
                                                    You have the right to object to the lawful processing of your personal data by us if this is justified by your particular situation and if our interests in the processing do not outweigh ours.<br />

                                                    In detail:<br />

                                                    You have the right to object at any time, for reasons arising from your particular situation, to the processing of personal data concerning you on the basis of Art. 6 para. 1 sentence 1 e) or f) GDPR; this also applies to profiling based on these provisions. We no longer process personal data unless we can prove compelling grounds for processing that outweigh your interests, rights and freedoms, or the processing serves to assert, exercise or defend legal claims.<br />

                                                    If we process personal data for direct marketing purposes, you have the right to object at any time to the processing of personal data concerning you for the purpose of such advertising; this also applies to profiling, insofar as it is associated with such direct marketing.<br />

                                                    You have the right to object to the processing of personal data concerning you, for scientific or historical research purposes or for statistical purposes in accordance with Art. 89 para. 1 GDPR, for reasons arising from your particular situation, unless the processing is necessary to fulfil a task in the public interest.<br />

                                                    <h5>5.7 Automated decisions including profiling</h5>
                                                    You have the right not to be subject to a decision based exclusively on automated processing � including profiling � that has legal effect against you or significantly impairs you in a similar manner.<br />

                                                    An automated decision making based on the collected personal data does not take place.<br />

                                                    <h5>5.8 Right to revoke consent under data protection law</h5>
                                                    You have the right to revoke your consent to the processing of personal data at any time.<br />

                                                    <h5>5.9 Right of appeal to a supervisory authority</h5>
                                                    You have the right of appeal to a supervisory authority, in particular in the Member State where you are staying, working or suspected of having infringed the law, if you believe that the processing of personal data concerning you is unlawful.<br />

                                                    <h4>6 Data security</h4>
                                                    We make every effort to ensure the security of your data in accordance with the applicable data protection laws and technical possibilities.<br />

                                                    Your personal data will be transmitted encrypted. We use the SSL (Secure Socket Layer) coding system, but point out that data transmission over the Internet (e.g. communication by e-mail) can have security gaps. A complete protection of data against access by third parties is not possible.<br />

                                                    To secure your data, we maintain technical and organisational security measures in accordance with Art. 32 GDPR, which we constantly adapt to the state of the art.<br />

                                                    Furthermore, we do not guarantee that our offer will be available at certain times; disruptions, interruptions or failures cannot be ruled out. The servers we use are regularly and carefully secured.<br />

                                                    <h4>7 Disclosure of data to third parties</h4>
                                                    In principle, we only use your personal data within our company.<br />

                                                    If and to the extent that we involve third parties in the performance of contracts (such as logistics service providers), these personal data are only received to the extent to which the transfer is necessary for the corresponding service.<br />

                                                    <h4>8 Data Protection Officer</h4>
                                                    If you have any questions or concerns about data protection, please contact our data protection officer:<br />

                                                    Uri Gutermann: Email: <a href="mailto:uri.gutermann@gutermann-water.com" style={{color: "white", textDecoration: "underline"} }>uri.gutermann@gutermann-water.com</a>

                                                    Copyrights<br />
                                                    The content and works provided on these Web pages are governed by the copyright laws of Switzerland. Duplication, processing, distribution, or any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator.
                                                </div>
                                                }   
                                        </CardBody>
                                        <CardFooter>
                                            <div style={{ margin: "20px auto" }}>
                                                <Link to={sessionStorage.getItem('userId') ? "/Portal/Dashboard" : "/"}>
                                                    <Button variant="contained">Back to homepage</Button>
                                                </Link>
                                            </div>
                                        </CardFooter>
                                    </div>
                                </Card>
                                
                            </div>
                        </div>

                    </div>
                    <div style={{ position: "fixed", bottom: 0, width: "100%" }}>
                        <Footer />
                    </div>
                </div>

            </div>
        </>
    )
}
export default PrivacyPolicy