import React from 'react';

const ProgressBar = ({ progress }) => {

    const container = {
        height: 20,
        width: "100%",
        backgroundColor: "whitesmoke",
        marginTop: 5,
        marginBottom: 5,
        border: "1px solid black",
        position: "relative"
    };

    const bar = {
        height: "100%",
        width: `${progress}%`,
        backgroundColor: "#00bf00",
        borderRadius: "inherit"
    };

    const label = {
        color: "#000000",
        position: "absolute",
        top: 0,
        left: "50%",
        transform: 'translateX(-50%)',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        fontWeight: 'bold'  // optional: for better visibility
    };

    return (
        <div style={container}>
            <div style={bar} role="progressbar"
                aria-valuenow={progress}
                aria-valuemin={0}
                aria-valuemax={100}>
            </div>
            <span style={label}>{progress > 100 ? '99%' : progress + '%'}</span>
        </div>
    );
};

export default ProgressBar;
