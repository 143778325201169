import React, { ReactNode } from "react";
import Modal from 'react-bootstrap/Modal';
import { CreateUrl, CallPostAPI } from 'Utils/ApiHelper.js';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import MaterialButton from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { FormControl } from '@mui/material';
import BackupIcon from '@mui/icons-material/Backup';
import Chip from '@mui/material/Chip';
import FilePicker from 'components/File/FilePicker';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import { withStyles, createStyles } from '@mui/styles';

const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);
let fileReader: FileReader;

interface Upload {
    id: number;
    caption: string;
    filename: string;
    filetype: string;
    fkSiteId: number;
    fkLoggerId: number;
    filecontent: string | null;
}
interface Props {
    classes: {
        cardTitleWhite: string;
    };
    show: boolean;
    title: string;
    siteId: number;
    loggerId: number;
    onSubmit: (result: number, update: boolean) => void;    //result = 0 (OK) 1 (Error) -1 (Exception), update = true to refresh data
    onCancel: () => void;
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    tempUpload: Upload;
    allowSave: boolean;
    file: ArrayBuffer;
    uploadFile: File | null;
    caption: string;
    fileMb: number;
    fileTypeError: boolean;
}

class UploadDialog extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            tempUpload: {
                id: 0,
                caption: "",
                filename: "No file selected",
                filetype: "",
                fkSiteId: this.props.siteId,
                fkLoggerId: this.props.loggerId,   
                filecontent: null
            },
            allowSave: false,
            file: new ArrayBuffer(0),
            uploadFile: null,
            caption: "",
            fileMb: 0,
            fileTypeError: false,
        };

    }

    handleCaptionChanged(event: any): void {
        this.setState({ caption: event.target.value });
    }

    handleShutdownChanged(event: any): void {
    //    this.setState({ shutdown: event.target.checked });
    }

    getCookie(name: string): string {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        let ret: string | undefined;
        const cookie = parts.pop();
        if (cookie != undefined && parts.length == 2) {
            ret = cookie.split(";").shift();
        }

        if (ret != undefined) {
            return ret;
        }
        else {
            return "";
        }
    }

    createSuccessNotification = (): void => {
        NotificationManager.success('File uploaded', 'Success')

    };

    createErrorNotification = (): void => {
        NotificationManager.error('Error uploading file - too large? 10Mb limit', 'Click me!', 5000, () => {
            alert('The maximum file size for images is 10Mb');
        });
    };

    upload(): void {

        const me = this;

        const formData = new FormData();
        formData.append('fkSiteId', this.props.siteId?.toString());
        formData.append('fkLoggerId', this.props.loggerId.toString()),        
        formData.append('caption', this.state.caption);

        if (this.state.uploadFile) {
            formData.append('file', this.state.uploadFile);
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'RequestVerificationToken': this.getCookie('RequestVerificationToken')
            },
            enc: 'multipart/form-data',
            body: formData,
        };



        CallPostAPI(CreateUrl('/ImageUpload/UploadDatabase'), requestOptions)
            .then(async response => {
                if (response.status == 201) {
                    me.createSuccessNotification();


                    me.props.onSubmit(0, true);
                }
                else {
                    me.createErrorNotification();
                    me.props.onSubmit(1, false);
                }

            })
            .catch(function (error) {
                me.props.onSubmit(-1, false);
                console.log(error);
            });

        // Always clear form, otherwise have to wait for upload
        me.setState({
            caption: "",
            tempUpload: {
                id: 0,
                caption: "",
                filename: "No file selected",
                filetype: "",
                fkSiteId: this.props.siteId,
                fkLoggerId: this.props.loggerId,
                filecontent: null
            },
            allowSave: false,
            uploadFile: null,
        });
    }

    // file handling
    readFile = (file: Array<File>): void => {
        fileReader = new FileReader();
        fileReader.onloadend = this.handleFileRead;
        fileReader.readAsArrayBuffer(file[0]);

        const tmpUpload = this.state.tempUpload;
        tmpUpload.filename = file[0].name;
        const namebits = file[0].name.split(".");
        tmpUpload.filetype = namebits[namebits.length - 1].toUpperCase();
        // Check for .jpg, .gif, .png, .pdf
        let typeError = true;
        if (tmpUpload.filetype == "JPG"
            || tmpUpload.filetype == "GIF"
            || tmpUpload.filetype == "PNG"
            || tmpUpload.filetype == "PDF"
        ) {
            typeError = false;
        }
        this.setState(
            {
                tempUpload: tmpUpload,
                uploadFile: file[0],
                fileTypeError: typeError,
            });
    }

    handleFileRead = (e: any): void => {
        const content = fileReader.result as ArrayBuffer;
        console.log(content.byteLength / 1048576)
        const size = Math.ceil(content?.byteLength / 1048576);
        this.setState({
            file: content,
            allowSave: (size <= 10) && !this.state.fileTypeError,
            fileMb: size,
        });
    };

    removeFile = (): void => {
        const tmpUpload = this.state.tempUpload;
        tmpUpload.filename = 'No file selected';
        tmpUpload.filetype = "";
        this.setState(
            {
                tempUpload: tmpUpload,
                allowSave: false,
                fileMb: 0,
                fileTypeError: false,
            });
    }

    render(): ReactNode {

        return (

            <Modal
                show={this.props.show}
                onHide={(): void => this.props.onCancel()}
                dialogClassName="modal-50w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div>
                                Select/drop a single file to upload. On a mobile device it may allow you to take a photo using camera.
                                Files must be .jpg, .gif, .png or .pdf and be less than 10Mb in size.
                            </div>
                        </Grid>
                        {this.state.fileMb > 10 &&
                            <Grid item xs={12}>
                                <div style={{ color: "red" }} >
                                    File MUST be less than 10Mb
                                </div>
                            </Grid>
                        }
                        {this.state.fileTypeError &&
                            <Grid item xs={12}>
                                <div style={{ color: "red" }} >
                                    Wrong file type selected
                                </div>
                            </Grid>
                        }
                       <Grid item xs={12}>
                            <div style={{paddingBottom: 8} }>
                                {!this.state.tempUpload?.filetype &&
                                    <FormControl fullWidth >
                                        <FilePicker
                                            onChange={this.readFile}
                                        >
                                            <IconButton aria-label="delete" size="large">
                                                <BackupIcon />
                                            </IconButton>
                                        </FilePicker>
                                    </FormControl>

                                }
                                {this.state.tempUpload?.filetype &&
                                    <Chip
                                    label={this.state.tempUpload.filename + " " + this.state.fileMb.toFixed(0) + "Mb"} onDelete={(): void => this.removeFile()} color="primary" />
                                    }
                           </div>
                        </Grid>
                        <Grid xs={12} item>
                            <FormControl fullWidth style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <TextField
                                id="outlined-input-siteref"
                                defaultValue={this.state.caption}
                                label="Image Caption"
                                onChange={this.handleCaptionChanged.bind(this)}
                                variant="outlined"
                                multiline
                                rows={5}
                                style={{ width: '100%', color: "black" }}
                            />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <MaterialButton color="primary" variant="contained" style={{marginRight: "10px"}} onClick={(): void => this.upload()} disabled={!this.state.allowSave} >Upload file</MaterialButton>
                    <MaterialButton color="secondary" variant="contained" onClick={(): void => this.props.onCancel()}>Cancel</MaterialButton>
                </Modal.Footer>
            </Modal>

        )
    }

}

export default withStyles(style)(UploadDialog);