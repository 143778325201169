import React, { ReactNode } from "react";
import Modal from 'react-bootstrap/Modal'
import Dialog from '@mui/material/Dialog';      // Use Dialog to show modal in front of NavBar
import { CreateUrl, CallGetAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import { Grid, Box, Select, MenuItem, Chip } from '@mui/material';
import MaterialButton from '@mui/material/Button';
import { DataGrid, Column, FilterRow, Pager, Paging } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from "devextreme/data/data_source";

import { APIGetLoggersWithUpdatesModel, buildAPIGetLoggersWithUpdatesModel } from 'models/APIGetLoggersWithUpdatesModel'
import { APIGetFirmwaresModel } from '../../models/APIGetFirmwaresModel';
import ClipLoader from "react-spinners/ClipLoader";
import ProgressBar from "components/Progress/ProgressBar"
import { NotificationManager } from 'react-notifications';
import deMessages from "devextreme/localization/messages/de.json";
import frMessages from "devextreme/localization/messages/fr.json";
import esMessages from "devextreme/localization/messages/es.json";
import ptMessages from "devextreme/localization/messages/pt.json";
import { locale, loadMessages } from "devextreme/localization";
import { injectIntl, FormattedMessage } from 'react-intl';
//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';

import { withStyles, createStyles } from '@mui/styles';


const styles: Record<string, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    AGmodal: {
        zIndex: "1300 !important"
    }
};



const style = createStyles(styles);

let modalTitle = "";

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    serial: number;
    reloadData: () => void
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    tempFirmware: APIGetLoggersWithUpdatesModel | null;
    isAvailable: boolean;
    requested: boolean;
    inProgress: boolean;
    Progress: number;
    updateModal: boolean;
    firmwares: Array<APIGetFirmwaresModel>;
    firmwareId: number;
}

class FirmwareUpdate extends React.Component<Props, State> {
    store: ArrayStore;
    gridRef: React.RefObject<DataGrid>;

    constructor(props: Readonly<Props>) {
        super(props);

        this.store = new ArrayStore({
            key: 'id',
            data: []
        });

        this.state = {
            loading: false,
            tableHidden: true,
            authorized: true,
            tempFirmware: null,
            isAvailable: false,
            requested: false,
            inProgress: false,
            Progress: 0,
            updateModal: false,
            firmwares: [],
            firmwareId: 0,
        };
    }

    componentDidMount(): void {
        this.reloadData()
    }

    reloadData(): void {
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/GetFirmwareUpdateForLogger?companyid=' + sessionStorage.getItem("companyId") + '&serial=' + this.props.serial), {})
            .then((data) => {

                console.log(data)
                    //Success
                const fw = buildAPIGetLoggersWithUpdatesModel(data);
                console.log(fw)
                let progress = -1;
                if (fw.FirmwareSent != null && fw.FirmwareSent > 0 && fw.FirmwareSize != null) {
                    progress = Math.round(fw.FirmwareSent * 100 / fw.FirmwareSize + 1);
                    this.props.firmwareNeeded(true)
                    console.log('hit')
                }
                if (fw.CurrentFirmware !== fw.AvailableFirmware) {
                    this.props.firmwareNeeded(true)
                    console.log('hit')
                }
                if (fw.CurrentFirmware === fw.AvailableFirmware) {
                    this.props.firmwareNeeded(false)
                }
                
                me.setState(
                    {
                        tempFirmware: fw,
                        isAvailable: true,
                        requested: fw.FirmwareToUpdate != null && progress == -1,
                        inProgress: progress >= 0,
                        Progress: progress,
                        tableHidden: false,
                        loading: false
                    })
            }
                ,   //Not found
                (reject) => {
                    me.setState(
                        {
                            tempFirmware: null,
                            isAvailable: false,
                            tableHidden: false,
                            loading: false
                        })
                    this.props.firmwareNeeded(false)
                }
            )
            .catch(function () {
                me.setState(
                    {
                        authorized: true,
                        tableHidden: true,
                        loading: false

                    })
            });
    }

    deviceFirmwareClick = (): void => {
        this.setState({loading: true})
        modalTitle = "Trigger Firmware update";
        const me = this;
        CallGetAPI(CreateUrl(`/api/aquaguard/LCLogger?id=${this.state.tempFirmware.Id}`), {})
            .then(logger => {
                CallGetAPI(CreateUrl('/api/aquaguard/Firmware?model=' + logger.productId), {})
                    .then(data => {
                        if (data.length > 0) {

                            // Copy the data records into deviceData, adding the clickEvent
                            const records = [];
                            for (let i = 0; i < data.length; i++) {
                                records.push(data[i] as APIGetFirmwaresModel);
                            }

                            me.setState(
                                {
                                    updateModal: true,
                                    firmwares: records,
                                    loading: false
                                })
                        }
                        else {
                            me.setState(
                                {
                                    firmwares: [],
                                    loading: false
                                })
                        }
                    })
                    .catch(function () {
                        me.setState(
                            {
                                firmwares: [],
                                loading: false
                            })

                    });
            })
       
        

    }

    toggleUpdateModal = (): void => {
        this.setState({
            updateModal: !this.state.updateModal,
        });
        modalTitle = "Warning"
    }

    handleFirmwareChange = (e: any): void => {

        const inputValue: string = e.target.value;

        this.setState({ firmwareId: parseInt(inputValue) });
    }



    doUpdate = (): void => {

        const me = this;

        //Call API to update the firmware
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ firmwareId: this.state.firmwareId, serial: this.props.serial, })
        };

        CallPostAPI(CreateUrl('/api/Aquaguard/SendChariotFirmware'), requestOptions)
            .then(response => {
                if (response.ok) {
                    me.createSuccessNotification('Device firmware will be updated');

                    const fw = me.state.tempFirmware;
                    if (fw) {
                        fw.FirmwareSentOn = new Date(Date.now());
                        fw.FirmwareToUpdate = me.state.firmwareId;
                        me.setState({ tempFirmware: fw });
                    }
                    setTimeout(() => {
                        setTimeout(() => this.props.reloadData(), 500)
                    }, 2000)

                }
                else {
                    me.createErrorNotification('Error Saving Firmware updates');
                }
                console.log(response);
            })
            .catch(function (error) {
                me.createErrorNotification('Error Saving Firmware updates');
                console.log(error);
            });

        this.setState(
            {
                updateModal: false,
            });
    }

    createSuccessNotification = (msg: string): void => {
        NotificationManager.success(msg, 'Success');

    };

    createErrorNotification = (type: string): void => {
        NotificationManager.error('Error Saving ' + type, 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    handleLatest(): void {

        this.setState({ firmwareId: this.state.firmwares[this.state.firmwares.length - 1].id })
    }

    render(): ReactNode {

        const { classes, intl } = this.props;

        return (
            <div>
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2000
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>

                }

                <Modal
                    show={this.state.updateModal}
                    onHide={(): void => this.toggleUpdateModal()}
                    dialogClassName="modal-25w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {modalTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <div style={{ width: "100%" }}>
                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <p style={{ marginTop: "4px" }}>{intl.formatMessage({ id: 'Logger you are updating the firmware of:' })} </p>
                                <Chip
                                    label={this.props.serial}
                                    color="primary"
                                />
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div>
                                    <div>
                                        <MaterialButton variant="contained" color="warning" onClick={(): void => { this.handleLatest() }}>{intl.formatMessage({ id: 'Get latest version' })}</MaterialButton>
                                    </div>


                                    <p style={{ marginTop: "10px", textAlign: "center" }}>{intl.formatMessage({ id: 'Or select a version' })}</p>
                                    <div style={{ display: "flex", justifyContent: "space-around", marginTop: "-5px" }}>
                                        <Select
                                            name="Firmware"
                                            style={{ margin: 0, paddingBottom: 0 }}
                                            value={this.state.firmwareId}
                                            onChange={this.handleFirmwareChange.bind(this)}
                                            size="small"
                                        >
                                            {
                                                this.state.firmwares.map(fw => {
                                                    return <MenuItem key={fw.id} value={fw.id}>{fw.versionStr}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <MaterialButton color="primary" variant="contained" style={{ marginRight: "10px" }} disabled={this.state.firmwareId == 0} onClick={(): void => this.doUpdate()}>{intl.formatMessage({ id: 'Continue' })}</MaterialButton>
                        <MaterialButton color="secondary" variant="contained" onClick={(): void => this.toggleUpdateModal()}>{intl.formatMessage({ id: 'Cancel' })}</MaterialButton>
                    </Modal.Footer>
                </Modal>
                {this.state.isAvailable && this.state.tempFirmware && this.state.tempFirmware?.CurrentFirmware != ".." &&
                    <div style={{ ...this.props.overViewStyle, borderLeft: "10px solid #FF6700" , width: "100%" }} >
                    <Box style={{ textAlign: "center", width: "100%"}}>
                        <div style={{ width: "100%", margin: "0 auto" }}>
                                <h5 style={{ textAlign: "center" }}><b>Firmware Info</b></h5>
                                <div style={{ display: this.state.inProgress ? "flex" : "block", justifyContent: "space-evenly"} }>
                        <div> 
                            <b>Current firmware:</b> {this.state.tempFirmware?.CurrentFirmware}
                        </div>
                        <div>
                            <b>Available firmware:</b> {this.state.tempFirmware?.AvailableFirmware}
                        </div>
                         </div>
                                
                    {this.state.inProgress &&
                        <div style={{marginTop: "10px" }}>
                            <h5>Update In Progress</h5>
                                        <div>Sent over: {this.state.tempFirmware?.FotaAct}</div>
                                        <div>Signal strength whilst sending: {this.state.tempFirmware?.FotaRssi}Rssi</div>
                            <div style={{ margin: "10px 0" }}>
                                <div>File size: {(this.state.tempFirmware?.FirmwareSize * 2048) / 1024}kb</div>
                                            <div>Data Sent so far: {(this.state.tempFirmware?.FirmwareSent * 2048) / 1024}kb</div>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <div style={{ width: "80%", } }>
                                                    <ProgressBar progress={this.state.Progress.toFixed(0)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                
                                } 
                                
                    {this.state.requested &&
                        <div style={{ color: "red" }}>
                        Update requested on {moment(this.state.tempFirmware.FirmwareSentOn).format("DD/MM/yyyy HH:mm")}, but not yet started
                        </div>
                    }
                    {!this.state.inProgress &&
                                <div style={{ width: "100%", textAlign: "center", margin: "10px 0 15px 0" }}>
                                    <MaterialButton color="primary" variant="contained" onClick={(): void => this.deviceFirmwareClick()}>
                                        Start Update
                                    </MaterialButton>
                                </div>

                            }
                        </div>
                        </Box>
                    </div>
                }
            </div>
        )
    }

}

export default injectIntl(withStyles(style)(FirmwareUpdate));