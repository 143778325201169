export interface APIGetLoggersWithUpdatesModel {

    Id: number;

    Serial: number;
    Site: string;
    Model: string;
    Channels: string;
    LastConnected: Date | null;
    AlarmState: number;
    SiteId: number;
    SiteName: string
    SiteNameUserFriendly: string
    Company: string

    Deployed : Date | null;

    // Update details
    CurrentFirmware: string
    AvailableFirmware: string

    ConfigUpdates: string
    //Progress
    FirmwareToUpdate: number | null;
    FirmwareSentOn: Date | null;
    FirmwareSent: number | null;
    FirmwareConfirmed : Date | null;

    FirmwareSize: number | null;
}

export function buildAPIGetLoggersWithUpdatesModel(r: any): APIGetLoggersWithUpdatesModel {
    return {
        Id: r.id,
        Serial: r.serial,
        Site: r.site,
        Model: r.model,
        Channels: r.channels,
        LastConnected: r.lastConnected,
        AlarmState: r.alarmState,
        SiteId: r.siteId,
        SiteName: r.siteName,
        SiteNameUserFriendly: r.siteNameUserFriendly,
        Company: r.company,
        Deployed: r.deployed,
        CurrentFirmware: r.currentFirmware,
        AvailableFirmware: r.availableFirmware,
        ConfigUpdates: r.configUpdates,
        FirmwareToUpdate: r.firmwareToUpdate,
        FirmwareSentOn: r.firmwareSentOn,
        FirmwareSent: r.firmwareSent,
        FirmwareConfirmed: r.firmwareConfirmed,
        FirmwareSize: r.firmwareSize,
        FotaAct: r.fotaAct,
        FotaBattery: r.fotaBattery,
        FotaBytesPerSec: r.fotaBytesPerSec,
        FotaPassFail: r.fotaPassFail,
        FotaPercentage: r.fotaPercentage,
        FotaRssi: r.fotaRssi,
        FotaSecs: r.fotaSecs,
        FotaSeq: r.fotaSeq,
        FotaTimestamp: r.foatTimestamp,
        FotaVer: r.fotaVer
    }
}