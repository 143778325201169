import React, {useState} from 'react'
import makeStyles from '@mui/styles/makeStyles';
import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
const Footer: React.FC = (BGColor) => {

    const [privacy, setPrivacy] = useState<boolean>(false);
    const useStyles = makeStyles(styles);
    
    // styles
    const classes = useStyles();

    const handlePrivacyClick = ():void => {
        setPrivacy(!privacy)
    }
    return (
        
        <div className={classes.appBar} >
            <div style={{display: "flex", justifyContent: "space-around", alignItems: "center", width: window.location.hostname.includes('zonelog.net') ? "60%" : "30%", margin: "auto auto 0 auto", padding: "10px 0 10px 0" } }>
                
                <Link to="/privacy">
                    <p style={{ textAlign: "center", color: "white", fontSize: "16px" }}>{window.location.hostname.includes('zonelog.net') ? <FormattedMessage id="Legal" /> :<FormattedMessage id="Privacy" />}</p>
                </Link>
                
                {!window.location.hostname.includes('zonelog.net') && <Link to="/legal">
                    <p style={{ textAlign: "center", color: "white", fontSize: "16px" }}><FormattedMessage id="Terms and Conditions" /></p>
                </Link>}
                {window.location.hostname.includes('zonelog.net') && <p style={{ textAlign: "center", fontSize: "16px", color: "white" }}>For support contact: support@gutermann-water.com</p>}
                {!window.location.hostname.includes('zonelog.net') && <p style={{ textAlign: "center", fontSize: "16px" }}><a href={"mailto:support@ash-eng.com"} style={{ color: "white" }}><FormattedMessage id="Contact" /></a></p>}
            </div>
        </div> 
        
    )
}

export default Footer