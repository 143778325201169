import React, { useState, useEffect, ChangeEvent, useCallback, useRef, useMemo } from "react" 
import Chart, { Grid as GridChart, ConstantLine, CommonSeriesSettings, ArgumentAxis, Series, Legend, ValueAxis, ZoomAndPan, ScrollBar, Tooltip, TickInterval, Aggregation, Point,  Title, AggregationInterval, Label, Height, Crosshair, Font, MinorTickInterval, Tick, MinorTick, Strip, CommonAxisSettings } from 'devextreme-react/chart';
import RangeSelector, {Size, Chart as ChartOptions, Margin, Scale, Behavior, RangeSelectorTypes, ValueAxis as RangeAxis 
} from 'devextreme-react/range-selector';
import { CreateUrl, CallGetAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import { APILoggerDetail, buildAPILoggerDetail } from '../../models/APILoggerDetail';
import ApiFailed from '../../Utils/ApiFailed';
import ClipLoader from "react-spinners/ClipLoader";
import { APIGetLoggerReadingsModel, buildAPIGetLoggerReadingsModel } from '../../models/APIGetLoggerReadingsModel';
import moment from 'moment'
import { decodeFlowMultiplier } from 'Utils/FlowMultiplier';
import { adjustTime } from '../../Utils/AdjustTime'
import DateRange from '../../components/Dates/DateRange';
import CardHeader from "components/Card/CardHeader.js";
import { withStyles, createStyles } from '@mui/styles';
import { Grid, Switch } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { VisualRange } from 'devextreme-react/common/charts';
import { TextField, Chip, Button, Select, MenuItem, Checkbox } from "@mui/material"
import Modal from 'react-bootstrap/Modal';
import { Item } from 'devextreme-react/tab-panel';
import Tabs from 'devextreme-react/tabs'
import { PeriodStatsCHR } from '../../components/Readings/PeriodStatsCHR'
import { PeriodStatsTOR } from '../../components/Readings/PeriodStatsTOR'
import { Export } from 'devextreme-react/data-grid';
import { enumChariotAlarmInput } from '../../models/enumChariotAlarmInput';
import { enumChariotAlarmOperator } from '../../models/enumChariotAlarmOperator';
import GraphSettingsComponent from '../../components/GraphTemplate/GraphSettingsComponent'
import { GraphTemplate, graphTemplateModel, GraphSettings, deepCopyAshridgeDefault } from '../../models/GraphTemplateModel'
import { NotificationManager } from 'react-notifications';
import ExcelJS, { Column } from 'exceljs';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { Radio, useTheme } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Tooltip as TooltipMUI } from '@mui/material';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { isLCLLogger } from 'variables/chariotProductIds';
import { Redirect } from "react-router-dom";
import GraphChannelDisplay from "../../components/GraphTemplate/graphChannelDisplay";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import GraphRangeSelector from "../../components/GraphTemplate/GraphRangeSelector";

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
    };
    history: {
        goBack: () => void;
    };
    location: {
        state: {
            groupId: number | null;
            loggers: Array<number>;
            showBackButton: boolean;
            site: number | null;
        }
    }
    startDateProps: Date;
    endDateProps: Date;
    showDates: boolean;
    ref: React.RefObject<unknown>;

}

interface Alarm {
    id: number;
    serial: number | undefined
    site: number | undefined
    channel: string;
    message: string;
    setLevel: number;
    clrLevel: number;
    setOperator: enumChariotAlarmOperator;
}

interface SiteDetails {
    siteName: string
    siteRef: string
}

interface RedirectProps {
    serial: number
    mapKey: string
}

interface IntervalTemp {
    serial: string
    intervals: {
        A: Array<ChannelInterval | null>
        B: Array<ChannelInterval | null>
        C: Array<ChannelInterval | null>
        D: Array<ChannelInterval | null>
        E: Array<ChannelInterval | null>
    }
}

interface ChannelInterval {
    interval: number
    count: number
}






interface Readings extends Record <string, any> {
    date: Date;

}
const MultiLoggerGraphV2: React.FC<Props> = ({classes, history, location, startDateProps, endDateProps, showDates, ref }) => {


    const chartRef = useRef(null);
    const styles: Record<any, any> = {
        formControl: {
            minWidth: 120,
        },
        cardCategoryWhite: {
            "&,& a,& a:hover,& a:focus": {
                color: "rgba(255,255,255,.62)",
                margin: "0",
                fontSize: "14px",
                marginTop: "0",
                marginBottom: "0"
            },
            "& a,& a:hover,& a:focus": {
                color: "#FFFFFF"
            }
        },
        cardTitleWhite: {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            color: "#FFFFFF",
            minHeight: "auto",
            fontWeight: "300",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            textDecoration: "none",
            "& small": {
                color: "#777",
                fontSize: "65%",
                fontWeight: "400",
                lineHeight: "1"
            }
        }
    };

    const style = createStyles(styles);

    //UseEffect for initial load

    //useEffect(() => {
    //    setLoading(true)
    //    reloadData(location.state.groupId, location.state.loggers, location.state.site, null)
    //}, [])





    //State
    const [loading, setLoading] = useState<boolean>(true)
    const [APIFailed, setAPIFailed] = useState<boolean>(false)
    const [authorised, setAuthorised] = useState<boolean>(true)
    const [siteNameUserFriendly, setSiteNameUserFriendly] = useState<string>("")
    const [startDate, setStartDate] = useState<moment>((startDateProps != null ? moment(startDateProps) : moment().subtract(7, "days")).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),)
    const [endDate, setEndDate] = useState<moment>(endDateProps != null ? moment(endDateProps) : moment().toDate())
    const [readingsData, setReadingsData] = useState<Array<APIGetLoggerReadingsModel>>([])
    const [graphData, setGraphData] = useState<Array<Readings>>([])
    const [loggers, setLoggers] = useState<Array<APILoggerDetail>>([])
    const [loggersToTemplate, setLoggersToTemplate] = useState<Array<APILoggerDetail>>([])
    const [ashridgeDefaultInUse, setAshridgeDefaultInUse] = useState<boolean>(false)
    ///modal
    const [createCompanyDefault, setCreateCompanyDefault] = useState<boolean>(false)
    const [createTemplateOpen, setCreateTemplateOpen] = useState<boolean>(false)
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [selectLoggerToTemplate, setSelectLoggerToTemplate] = useState<boolean>(false)
    ///Units an values State
    const [unitsAndValues, setUnitsAndValues] = useState<Array<GraphTemplate>>([])
    const [graphTemplate, setGraphTemplate] = useState<GraphTemplate>(graphTemplateModel)
    const [alarms, setAlarms] = useState<Array<Alarm>>([])
    const [checkAlarms, setCheckAlarms] = useState<boolean>(false)
    const [showFlow, setShowFlow] = useState<boolean>(false)
    const [showPressure, setShowPressure] = useState<boolean>(false)
    const [hasPressureChannels, setHasPressureChannels] = useState<boolean>(false)
    const [hasTotal, setHasTotal] = useState<boolean>(false)
    const [hasConsumption, setHasConsumption] = useState<boolean>(false)
    const [hasFlow, setHasFlow] = useState<boolean>(false)
    const [showTotal, setShowTotal] = useState<boolean>(false)
    const [showConsumption, setShowConsumption] = useState<boolean>(false)
    ///channel References 
    const [toggling, setToggling] = useState<boolean>(false)

    /// Range state
    const [visualRange, setVisualRange] = useState<VisualRange>({ startValue: startDate, endValue: endDate })
    const [exportAs, setExportAs] = useState<string>("CSV")
    const [exportModal, setExportModal] = useState<boolean>(false)
    const [noLoggersInGroup, setNoLoggersInGroup] = useState<boolean>(false)
    const [loggerSiteDetails, setLoggerSiteDetails] = useState<Array<SiteDetails>>([])
    const [enablePoint, setEnablePoint] = useState<boolean>(false)
    //redirect
    const [redirect, setRedirect] = useState<boolean>(false)
    const [redirectPath, setRedirectPath] = useState<string>("")
    const [redirectProps, setRedirectProps] = useState<RedirectProps>({serial: 0, mapKey: ""})
    const [combineY, setCombineY] = useState<boolean>(false)
    const [graphHeight, setGraphHeight] = useState<string>("50vh")
    const [combinedFlowAUnit, setCombinedFlowAUnit] = useState<string>("")
    const [combinedFlowBUnit, setCombinedFlowBUnit] = useState<string>("")
    const [combinedPressureCUnit, setCombinedPressureCUnit] = useState<string>("")
    const [combinedPressureDUnit, setCombinedPressureDUnit] = useState<string>("")
    const [combinedPressureEUnit, setCombinedPressureEUnit] = useState<string>("")
    const [combinedTotalAUnit, setCombinedTotalAUnit] = useState<string>("")
    const [combinedTotalBUnit, setCombinedTotalBUnit] = useState<string>("")
    const [combinedConsumptionAUnit, setCombinedConsumptionAUnit] = useState<string>("")
    const [combinedConsumptionBUnit, setCombinedConsumptionBUnit] = useState<string>("")
    const [splitDate, setSplitDate] = useState<boolean>(true)
    const [showButton, setShowButton] = useState<boolean>(false)





    const normaliseData = (graphData: Array<Readings>): Array<Readings> => {

        
        const minMaxValues = []
        graphData.forEach(data => {
            if (minMaxValues.length > 0 && minMaxValues.map(val => val.serial).includes(parseInt(data.serial))) {
                const selectedDevice = minMaxValues.filter(val => val.serial === parseInt(data.serial))[0]
                if (data.ChannelLetter === 'A') {
                    selectedDevice.flowAMin = Math.min(selectedDevice.flowAMin, data[`${data.serial}flowRateA`])
                    selectedDevice.flowAMax = Math.max(selectedDevice.flowAMax, data[`${data.serial}flowRateA`])
                    selectedDevice.flowATotalMin = Math.min(selectedDevice.flowATotalMin, data[`${data.serial}readingA`])
                    selectedDevice.flowATotalMax = Math.max(selectedDevice.flowATotalMax, data[`${data.serial}readingA`])
                    selectedDevice.flowAConsumptionMin = Math.min(selectedDevice.flowAConsumptionMin, data[`${data.serial}consumptionA`])
                    selectedDevice.flowAConsumptionMax = Math.max(selectedDevice.flowAConsumptionMax, data[`${data.serial}consumptionA`])
                }
                if (data.ChannelLetter === 'B') {
                    selectedDevice.flowBMin = Math.min(selectedDevice.flowBMin, data[`${data.serial}flowRateB`])
                    selectedDevice.flowBMax = Math.max(selectedDevice.flowBMax, data[`${data.serial}flowRateB`])
                    selectedDevice.flowBTotalMin = Math.min(selectedDevice.flowBTotalMin, data[`${data.serial}readingB`])
                    selectedDevice.flowBTotalMax = Math.max(selectedDevice.flowBTotalMax, data[`${data.serial}readingB`])
                    selectedDevice.flowBConsumptionMin = Math.min(selectedDevice.flowBConsumptionMin, data[`${data.serial}consumptionB`])
                    selectedDevice.flowBConsumptionMax = Math.max(selectedDevice.flowBConsumptionMax, data[`${data.serial}consumptionB`])
                }
                if (data.ChannelLetter === 'C') {
                    selectedDevice.pressureCMin = Math.min(selectedDevice.pressureCMin, data[`${data.serial}readingC`])
                    selectedDevice.pressureCMax = Math.max(selectedDevice.pressureCMax, data[`${data.serial}readingC`])
                }
                if (data.ChannelLetter === 'D') {
                    selectedDevice.pressureDMin = Math.min(selectedDevice.pressureDMin, data[`${data.serial}readingD`])
                    selectedDevice.pressureDMax = Math.max(selectedDevice.pressureDMax, data[`${data.serial}readingD`])
                }
                if (data.ChannelLetter === 'E') {
                    selectedDevice.pressureEMin = Math.min(selectedDevice.pressureEMin, data[`${data.serial}readingE`])
                    selectedDevice.pressureEMax = Math.max(selectedDevice.pressureEMax, data[`${data.serial}readingE`])
                }
            } else {
                minMaxValues.push({
                    serial: parseInt(data.serial),
                    flowAMin: data[`${data.serial}flowRateA`],
                    flowAMax: data[`${data.serial}flowRateA`],
                    flowATotalMin: data[`${data.serial}readingA`],
                    flowATotalMax: data[`${data.serial}readingA`],
                    flowAConsumptionMin: data[`${data.serial}consumptionA`],
                    flowAConsumptionMax: data[`${data.serial}consumptionA`],
                    flowBMin: data[`${data.serial}flowRateB`],
                    flowBMax: data[`${data.serial}flowRateB`],
                    flowBTotalMin: data[`${data.serial}readingB`],
                    flowBTotalMax: data[`${data.serial}readingB`],
                    flowBConsumptionMin: data[`${data.serial}consumptionB`],
                    flowBConsumptionMax: data[`${data.serial}consumptionB`],
                    pressureCMin: data[`${data.serial}readingC`],
                    pressureCMax: data[`${data.serial}readingC`],
                    pressureDMin: data[`${data.serial}readingD`],
                    pressureDMax: data[`${data.serial}readingD`],
                    pressureEMin: data[`${data.serial}readingE`],
                    pressureEMax: data[`${data.serial}readingE`],
                })
            }
            
        })
        const modifiedData = graphData.map(point => {
            const newpoint = {...point}
            const minMaxData = minMaxValues.filter(item => item.serial === parseInt(newpoint.serial))[0]
            if (newpoint.ChannelLetter === 'A') {
                newpoint[`${parseInt(point.serial)}flowRateA`] = (newpoint[`${parseInt(newpoint.serial)}flowRateA`] - minMaxData.flowAMin) / (minMaxData.flowAMax - minMaxData.flowAMin)
                newpoint[`${parseInt(point.serial)}readingA`] = (newpoint[`${parseInt(newpoint.serial)}readingA`] - minMaxData.flowATotalMin) / (minMaxData.flowATotalMax - minMaxData.flowATotalMin)
                newpoint[`${parseInt(point.serial)}consumptionA`] = (newpoint[`${parseInt(newpoint.serial)}consumptionA`] - minMaxData.flowAConsumptionMin) / (minMaxData.flowAConsumptionMax - minMaxData.flowAConsumptionMin)
            }
            if (newpoint.ChannelLetter === 'B') {
                newpoint[`${parseInt(point.serial)}flowRateB`] = (newpoint[`${parseInt(newpoint.serial)}flowRateB`] - minMaxData.flowBMin) / (minMaxData.flowBMax - minMaxData.flowBMin)
                newpoint[`${parseInt(point.serial)}readingB`] = (newpoint[`${parseInt(newpoint.serial)}readingB`] - minMaxData.flowBTotalMin) / (minMaxData.flowBTotalMax - minMaxData.flowBTotalMin)
                newpoint[`${parseInt(point.serial)}consumptionB`] = (newpoint[`${parseInt(newpoint.serial)}consumptionB`] - minMaxData.flowBConsumptionMin) / (minMaxData.flowBConsumptionMax - minMaxData.flowBConsumptionMin)
            }
            if (newpoint.ChannelLetter === 'C') {
                newpoint[`${parseInt(point.serial)}readingC`] = (newpoint[`${parseInt(newpoint.serial)}readingC`] - minMaxData.pressureCMin) / (minMaxData.pressureCMax - minMaxData.pressureCMin)
            }
            if (newpoint.ChannelLetter === 'D') {
                newpoint[`${parseInt(point.serial)}readingD`] = (newpoint[`${parseInt(newpoint.serial)}readingD`] - minMaxData.pressureDMin) / (minMaxData.pressureDMax - minMaxData.pressureDMin)
            }
            if (newpoint.ChannelLetter === 'E') {
                newpoint[`${parseInt(point.serial)}readingE`] = (newpoint[`${parseInt(newpoint.serial)}readingE`] - minMaxData.pressureEMin) / (minMaxData.pressureEMax - minMaxData.pressureEMin)
            }
            return newpoint
        })


        return modifiedData
    }
    //Use Memo to normalise data for Range finder to between 0 and 1
    const normalisedData = useMemo(() => {
        if (graphData.length > 0) {
          return normaliseData(graphData)
        }
    }, [graphData])

    


    /// Functions

    const reloadData = (groupId: number | null, loggersProps: Array<number>, site: number | null, dateChange: boolean | null): boolean => {
        setAlarms([])
        setLoading(true)
        setCheckAlarms(false)
        if (groupId != null && groupId != 0) {

            // Group selected - graph loggers deployed to sites in group
            CallGetAPI(CreateUrl('/api/aquaguard/GetAllLoggersForGroup?groupid=' + groupId), {})
                .then(data => {
            
                    if (data.length > 0) {

                        let siteId = 0;
                        // Copy the data records into deviceData, adding the clickEvent
                        const records: Array<APILoggerDetail> = [];
                        for (let i = 0; i < data.length; i++) {
                            const rec = buildAPILoggerDetail(data[i])
                            records.push(rec);

                            if (rec.FkSiteId != null) {
                                if (siteId == 0) {
                                    siteId = rec.FkSiteId;
                                }
                                else {
                                    // Check if all loggers from same site
                                    if (siteId != rec.FkSiteId) {
                                        siteId = -1;
                                    }
                                }
                            }
                        }

                        //// Load alarm levels if single Chariot logger
                        if (records.length === 1) {
                            if (records[0].LoggerId > 500000) {
                                loadLCAlarmLevels(records[0].Serial);
                            }
                            else {
                                loadLoggerAlarmLevels(records[0].Serial);
                            }
                        }
                        
                            if (siteId > 0) {
                                // Load alarm levels for Site if all loggers deployed to same site
                                if (records[0].FkSiteId != null) {
                                    loadSiteAlarmLevels(siteId);
                                }
                            }
                        
                        
                        drawGraph(records, null, dateChange);

                    }
                    else {
  
                        setNoLoggersInGroup(true)
                        setLoading(false)
                    }

                })
                .catch((error) => {
                    console.log(error);
                    setAPIFailed(true)
                    setAuthorised(false)

                })
        }


        if (loggersProps?.length > 0) {
            // List of loggers selected
            const loggerIds: Array<{ Id: number }> = [];
            let selectedLoggers = []
            if (loggersProps.length > 1) {
                selectedLoggers = sessionStorage.getItem('selectedLoggers').split(',').map(item => parseInt(item))
            } else {
                selectedLoggers.push(parseInt(sessionStorage.getItem('selectedLoggers')))
            }

            if (loggersProps.length > 0) {
                loggersProps.map((l) => {
                    if (selectedLoggers.includes(l)) {
                        loggerIds.push({ Id: l });
                    }
                });
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(loggerIds)
            };

            CallGetAPI(CreateUrl('/api/aquaguard/LoggerDetailsByIdList'), requestOptions)
                .then(data => {

                    

                    if (data.length > 0) {

                        // Copy the data records into deviceData, adding the clickEvent
                        const records: Array<APILoggerDetail> = [];
                        let siteId = 0;
                        for (let i = 0; i < data.length; i++) {
                            const rec = buildAPILoggerDetail(data[i])

                            records.push(rec);

                            if (rec.FkSiteId != null) {
                                if (siteId == 0) {
                                    siteId = rec.FkSiteId;
                                }
                                else {
                                    // Check if all loggers from same site
                                    if (siteId != rec.FkSiteId) {
                                        siteId = -1;
                                    }
                                }
                            }
                        }

                        // Load alarm levels if single Chariot logger
                        if (records.length === 1) {
                            if (records[0].LoggerId > 500000) {
                                loadLCAlarmLevels(records[0].Serial);
                            }
                            else {

                                loadLoggerAlarmLevels(records[0].Serial);
                            }
                        }
                        
                        drawGraph(records, null, dateChange);

                    }
                    else {
                        drawGraph([], null, dateChange);
                    }

                })
                .catch((error) => {
                    console.log(error)
                    setAPIFailed(true)
                    setAuthorised(false)
                        
                });
        }

        // For site just pull readings and alarm levels for site
        if (site != null && site != 0) {
            let extractedData = []
            let loggerIds: Array<{ Id: number }> = [];
            //TODO: Need to get SiteUserFriendlyName
            CallGetAPI(CreateUrl('/api/aquaguard/site?id=' + site), {})
                .then(data => {

                    if (data.siteNameUserFriendly != null) {
                            setSiteNameUserFriendly(data.siteNameUserFriendly.length > 0 ? data.siteNameUserFriendly : data.siteName)  
                    }
                    else {
                            setSiteNameUserFriendly(data.siteName.length > 0 ? data.siteName : "")
                    }
                    
                    CallGetAPI(CreateUrl('/api/aquaguard/HistoryForSite?siteId=' + site), {})
                        .then(data => {

                           extractedData = []
                           extractedData = data.filter(item => {
 
                               if (!moment(item.startDate).isAfter(endDate)) {
                                   if (item.endDate === null) {
                                       return item
                                   } else if (!moment(item.endDate).isBefore(startDate)) {

                                       return item
                                   }
                               }
                           })
                            extractedData = extractedData.filter((item, index, self) => index === self.findIndex(obj => obj.serial === item.serial))

                            if (extractedData.length > 0) {
                                loggerIds = []
                                extractedData.forEach(p => loggerIds.push({ Id: p.loggerId }))
                                const requestOptions = {
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json' },
                                    body: JSON.stringify(loggerIds)
                                };

                                CallGetAPI(CreateUrl('/api/aquaguard/LoggerDetailsByIdList'), requestOptions)
                                    .then(data => {
                                        const records: Array<APILoggerDetail> = [];
                                        const siteDeetsTemp = []
                                        for (let i = 0; i < data.length; i++) {
                                            const rec = buildAPILoggerDetail(data[i])

                                            records.push(rec);
                                            
                                            siteDeetsTemp.push({ siteRef: data.SiteName, siteName: data.SiteNameUserFriendly, serial: rec.Serial })
                                        }
                                        setLoggerSiteDetails(siteDeetsTemp)
                                        drawGraph(records, site, dateChange);
                                    })
                            } else {
                                drawGraph([], site, dateChange);
                            }
                            
                        })

                })
                .catch((error) => {
                    console.log(error);
                    setAPIFailed(true)
                    setAuthorised(false)
                });

            /*this.loadSiteAlarmLevels(site, this);*/

            
        }

        return true;
    }


     
    // checks for new keys that have been added to the graphing in future updtaes and enables them by default - users will have to turn them off and save new compnay default to override
    const findMissingKeys = (savedTemplate: GraphSettings, defaultTemplate: GraphSettings): GraphSettings => {

        const defaultKeys = Object.keys(defaultTemplate)
        const missingKeys = Object.keys(defaultTemplate).filter(key => !Object.keys(savedTemplate).includes(key))


        if (missingKeys.length > 0) {
            missingKeys.forEach(missing => {
                savedTemplate[missing] = defaultKeys[missing]
            })
        }
        for (const key in savedTemplate) {
           
            if (savedTemplate[key] === undefined) {
                savedTemplate[key] = defaultTemplate[key]
            }
            
        }

        return savedTemplate
    }

    

    const drawGraph = (loggerDataArray: Array<APILoggerDetail>, site: number | null, dateChange: boolean | null): void => {
        const siteDeetsTemp: SiteDetails = []

        loggerDataArray.forEach(logger => {
            if (logger.SiteName !== "Unassigned") {
                siteDeetsTemp.push({ siteRef: logger.SiteName, siteName: logger.SiteNameUserFriendly, serial: logger.Serial })
            }
        })
        setLoggerSiteDetails(siteDeetsTemp)
        setLoading(true)
        const ashridgeDefault: GraphTemplate = graphTemplateModel
        /// Ashridge default graph template
        const loggersUnitsAndValues = new Array<GraphTemplate>()
        
        //// Graph query in HERE

        ///call for active templates
        let companyDefault = false
   
            CallGetAPI(CreateUrl(`/api/aquaguard/graphTemplate?CompanyId=${sessionStorage.getItem('companyId')}`), {})


                .then(data => {
  
                    const extractedData = data.map(item => {
                        item.graphSettings = JSON.parse(item.graphSettings)
                        return item
                    })

                    const userTemplate = extractedData.filter(template => {
                        return loggerDataArray.map(item => item.Serial).includes(template.serial)
                    }).filter(template => {

                        return template.userId === parseInt(sessionStorage.getItem('userId'))
                    })

                    const companyTemplate = extractedData.filter(template => {
                        return !template.serial && !template.userId
                    })
 

                if (!site) {
                   


                    if (userTemplate.length > 0) {
                        // there is user templates
                        
                        if (userTemplate.length === loggerDataArray.length) {
                            userTemplate.forEach((template:GraphTemplate) => {

                                template.graphSettings = findMissingKeys(template.graphSettings, ashridgeDefault.graphSettings)
                                //pushes new template to units and values list
                                loggersUnitsAndValues.push(template)
                            })
                        } else {
                            const numberOfNonUserTemplateToAdd = loggerDataArray.length - userTemplate.length
                            if (companyTemplate.length > 0) {
                                userTemplate.forEach((template : GraphTemplate) => {
                                    template.graphSettings = findMissingKeys(template.graphSettings, ashridgeDefault.graphSettings)
                                    loggersUnitsAndValues.push(template)
                                })
                                const filteredOutLoggersWithUserTemplate = loggerDataArray.filter(logger => !userTemplate.map(template => template.serial).includes(logger.Serial))

                                for (let i = 0; i < numberOfNonUserTemplateToAdd; i++) {

                                    companyTemplate[0].graphSettings = findMissingKeys(companyTemplate[0].graphSettings, ashridgeDefault.graphSettings)

                                    companyTemplate[0].serial = filteredOutLoggersWithUserTemplate[i].Serial
                                    companyTemplate[0].channelsAvail = 0
                                    loggersUnitsAndValues.push(companyTemplate[0])
                                    companyDefault = true
                                }
                            } else {
                                userTemplate.forEach((template: GraphTemplate) => {
                                    template.graphSettings = findMissingKeys(template.graphSettings, ashridgeDefault.graphSettings)
                                    loggersUnitsAndValues.push(template)
                                })
                                for (let i = 0; i < numberOfNonUserTemplateToAdd; i++) {
                                    const newTemplate = deepCopyAshridgeDefault(ashridgeDefault)
                                    const filterOutLoggersWithTemplate = loggerDataArray.filter(logger => !userTemplate.map(template => template.serial).includes(logger.Serial))
                                    newTemplate.isAshridgeDefault = true
                                    
                                    newTemplate.serial = filterOutLoggersWithTemplate[i].Serial
                                    newTemplate.siteId = site
                                    loggersUnitsAndValues.push(newTemplate)
                                }
                            }
                        }
                    } else if (companyTemplate.length > 0) {
                        for (let i = 0; i < loggerDataArray.length; i++) {

                            const copy = deepCopyAshridgeDefault(companyTemplate[0])
                            
                            copy.graphSettings = findMissingKeys(copy.graphSettings, ashridgeDefault.graphSettings)
                            copy.serial = loggerDataArray[i].Serial
                            copy.channelsAvail = 0

                            loggersUnitsAndValues.push(copy)
                            companyDefault = true

                        }
                    } else {
                        setAshridgeDefaultInUse(true)
                        for (let i = 0; i < loggerDataArray.length; i++) {

                            const newTemplate = deepCopyAshridgeDefault(ashridgeDefault)
                            newTemplate.isAshridgeDefault = true
                            newTemplate.serial = loggerDataArray[i].Serial
                            newTemplate.siteId = site

                            loggersUnitsAndValues.push(newTemplate)

                        }
                    }


                } else {

                    ///if site render
                    if (userTemplate.length > 0) {
                        // there is user templates

                        if (userTemplate.length === loggerDataArray.length) {
                            userTemplate.forEach((template: GraphTemplate) => {
                                template.graphSettings = findMissingKeys(template.graphSettings, ashridgeDefault.graphSettings)
                                loggersUnitsAndValues.push(template)
                            })
                        } else {
                            const numberOfNonUserTemplateToAdd = loggerDataArray.length - userTemplate.length
                            if (companyTemplate.length > 0) {
                                userTemplate.forEach((template: GraphTemplate) => {
                                    template.graphSettings = findMissingKeys(template.graphSettings, ashridgeDefault.graphSettings)
                                    loggersUnitsAndValues.push(template)
                                })
                                const filteredOutLoggersWithUserTemplate = loggerDataArray.filter(logger => !userTemplate.map(template => template.serial).includes(logger.Serial))
                                for (let i = 0; i < numberOfNonUserTemplateToAdd; i++) {
                                    companyTemplate[0].graphSettings = findMissingKeys(companyTemplate[0].graphSettings, ashridgeDefault.graphSettings)
                                    companyTemplate[0].serial = filteredOutLoggersWithUserTemplate[i].Serial
                                    companyTemplate[0].channelsAvail = 0
                                    loggersUnitsAndValues.push(companyTemplate[0])
                                    companyDefault = true
                                }
                            } else {
                                userTemplate.forEach((template: GraphTemplate) => {
                                    template.graphSettings = findMissingKeys(template.graphSettings, ashridgeDefault.graphSettings)
                                    loggersUnitsAndValues.push(template)
                                })
                                for (let i = 0; i < numberOfNonUserTemplateToAdd; i++) {
                                    const newTemplate = deepCopyAshridgeDefault(ashridgeDefault)
                                    const filterOutLoggersWithTemplate = loggerDataArray.filter(logger => !userTemplate.map(template => template.serial).includes(logger.Serial))
                                    newTemplate.isAshridgeDefault = true

                                    newTemplate.serial = filterOutLoggersWithTemplate[i].Serial
                                    newTemplate.siteId = site
                                    loggersUnitsAndValues.push(newTemplate)
                                }
                            }
                        }
                    } else if (companyTemplate.length > 0) {
                        for (let i = 0; i < loggerDataArray.length; i++) {

                            const copy = deepCopyAshridgeDefault(companyTemplate[0])
                            copy.graphSettings = findMissingKeys(copy.graphSettings, ashridgeDefault.graphSettings)
                            copy.serial = loggerDataArray[i].Serial
                            copy.channelsAvail = 0
                            loggersUnitsAndValues.push(copy)
                            companyDefault = true

                        }
                    } else {
                        setAshridgeDefaultInUse(true)
                        for (let i = 0; i < loggerDataArray.length; i++) {

                            const newTemplate = deepCopyAshridgeDefault(ashridgeDefault)
                            newTemplate.isAshridgeDefault = true
                            newTemplate.serial = loggerDataArray[i].Serial
                            newTemplate.siteId = site

                            loggersUnitsAndValues.push(newTemplate)

                        }
                    }
                }




                    loggersUnitsAndValues.forEach(unit => unit.alarms = [])

                })
                .catch((error) => {
                    console.log(error);
                    setAPIFailed(true)
                    setAuthorised(false)
                });

        

        

        
        let readingsDataTemp = new Array<APIGetLoggerReadingsModel>();
        let graphDataTemp = new Array<Readings>();




    
        


    //    //get readings for the loggers here

    //    //generate array of Promises each adding the readingsData for each logger
        const promises = new Array<Promise<any>>();
        const offset = sessionStorage.getItem('utcOffset') ? parseInt(sessionStorage.getItem('utcOffset').slice(1)) : 0
        let offsetStartDate
        let offsetEndDate
        if (sessionStorage.getItem('utcOffset').slice(0, 1) === '+') {
            offsetStartDate = moment(startDate).subtract(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
            offsetEndDate = moment(endDate).subtract(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
        } else if (sessionStorage.getItem('utcOffset').slice(0, 1) === '-') {
            offsetStartDate = moment(startDate).add(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
            offsetEndDate = moment(endDate).add(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
        } else {
            offsetStartDate = moment(startDate).format("yyyy-MM-DDTHH:mm:ss");
            offsetEndDate = moment(endDate).format("yyyy-MM-DDTHH:mm:ss");
        }
        if (site == null) {
            
            loggerDataArray.map((logger) => {
                
                // console.log("DRAWGRAPH Push to API -> " + '/api/aquaguard/GetLogReadings?companyid=0&logger=' + logger.Serial + "&startDate=" + moment(this.state.startDate).format("yyyy-MM-DD") + "&endDate=" + moment(this.state.endDate).format("yyyy-MM-DD") + "T23:59:59");

                promises.push(
                    CallGetAPI(CreateUrl('/api/aquaguard/GetLogReadings?companyid=0&logger=' + logger.Serial + "&startDate=" + offsetStartDate + "&endDate=" + offsetEndDate), {})

                        .then(json => {
                            
                            readingsDataTemp = readingsDataTemp.concat(buildAPIGetLoggerReadingsModel(json));

                        })
                        .catch(function (ex) {
                           
                            console.log(ex);
                        })
                );


            });
        }
        else {
            promises.push(
                CallGetAPI(CreateUrl('/api/aquaguard/GetLogReadingsBySite?siteId=' + site + "&startDate=" + offsetStartDate + "&endDate=" + offsetEndDate), {})
                    .then(json => {

                        readingsDataTemp = readingsDataTemp.concat(buildAPIGetLoggerReadingsModel(json));

                    })
                    .catch(function (ex) {
                        
                        console.log(ex);
                    })
            );

        }
        loggersUnitsAndValues.forEach(data => {
            const settings = data.graphSettings
            if (settings.flowAMinState === 'default') {
                settings.flowAMinValue = Number.MAX_VALUE
            }
            if (settings.flowAMaxState === 'default') {
                settings.flowAMaxValue = Number.NEGATIVE_INFINITY
            }
            if (settings.flowATotalMinState === 'default') {
                settings.flowATotalMinValue = Number.MAX_VALUE
            }
            if (settings.flowATotalMaxState === 'default') {
                settings.flowATotalMaxValue = Number.NEGATIVE_INFINITY
            }
            if (settings.flowAConsumptionMinState === 'default') {
                settings.flowAConsumptionMinValue = Number.MAX_VALUE
            }
            if (settings.flowAConsumptionMaxState === 'default') {
                settings.flowAConsumptionMaxValue = Number.NEGATIVE_INFINITY
            }
            if (settings.flowBMinState === 'default') {
                settings.flowBMinValue = Number.MAX_VALUE
            }
            if (settings.flowBMaxState === 'default') {
                settings.flowBMaxValue = Number.NEGATIVE_INFINITY
            }
            if (settings.flowBTotalMinState === 'default') {
                settings.flowBTotalMinValue = Number.MAX_VALUE
            }
            if (settings.flowBTotalMaxState === 'default') {
                settings.flowBTotalMaxValue = Number.NEGATIVE_INFINITY
            }
            if (settings.flowBConsumptionMinState === 'default') {
                settings.flowBConsumptionMinValue = Number.MAX_VALUE
            }
            if (settings.flowBConsumptionMaxState === 'default') {
                settings.flowBConsumptionMaxValue = Number.NEGATIVE_INFINITY
            }
            if (settings.pressureCMinState === 'default') {
                settings.pressureCMinValue = Number.MAX_VALUE
            }
            if (settings.pressureCMaxState === 'default') {
                settings.pressureCMaxValue = Number.NEGATIVE_INFINITY
            }
            if (settings.pressureDMinState === 'default') {
                settings.pressureDMinValue = Number.MAX_VALUE
            }
            if (settings.pressureDMaxState === 'default') {
                settings.pressureDMaxValue = Number.NEGATIVE_INFINITY
            }
            if (settings.pressureEMinState === 'default') {
                settings.pressureEMinValue = Number.MAX_VALUE
            }
            if (settings.pressureEMaxState === 'default') {
                settings.pressureEMaxValue = Number.NEGATIVE_INFINITY
            }
        })
        //async promises return, add converted readings to graphData array and calculate maximum / minimum
        Promise.all(promises).then(() => {

            loggersUnitsAndValues.forEach(data => {
                const settings = data.graphSettings
                if (settings.flowAMinState === 'default') {
                    settings.flowAMinValue = Number.MAX_VALUE
                }
                if (settings.flowAMaxState === 'default') {
                    settings.flowAMaxValue = Number.NEGATIVE_INFINITY
                }
                if (settings.flowATotalMinState === 'default') {
                    settings.flowATotalMinValue = Number.MAX_VALUE
                }
                if (settings.flowATotalMaxState === 'default') {
                    settings.flowATotalMaxValue = Number.NEGATIVE_INFINITY
                }
                if (settings.flowAConsumptionMinState === 'default') {
                    settings.flowAConsumptionMinValue = Number.MAX_VALUE
                }
                if (settings.flowAConsumptionMaxState === 'default') {
                    settings.flowAConsumptionMaxValue = Number.NEGATIVE_INFINITY
                }
                if (settings.flowBMinState === 'default') {
                    settings.flowBMinValue = Number.MAX_VALUE
                }
                if (settings.flowBMaxState === 'default') {
                    settings.flowBMaxValue = Number.NEGATIVE_INFINITY
                }
                if (settings.flowBTotalMinState === 'default') {
                    settings.flowBTotalMinValue = Number.MAX_VALUE
                }
                if (settings.flowBConsumptionMinState === 'default') {
                    settings.flowBConsumptionMinValue = Number.MAX_VALUE
                }
                if (settings.flowBConsumptionMaxState === 'default') {
                    settings.flowBConsumptionMaxValue = Number.NEGATIVE_INFINITY
                }
                if (settings.flowBTotalMaxState === 'default') {
                    settings.flowBTotalMaxValue = Number.NEGATIVE_INFINITY
                }
                if (settings.pressureCMinState === 'default') {
                    settings.pressureCMinValue = Number.MAX_VALUE
                }
                if (settings.pressureCMaxState === 'default') {
                    settings.pressureCMaxValue = Number.NEGATIVE_INFINITY
                }
                if (settings.pressureDMinState === 'default') {
                    settings.pressureDMinValue = Number.MAX_VALUE
                }
                if (settings.pressureDMaxState === 'default') {
                    settings.pressureDMaxValue = Number.NEGATIVE_INFINITY
                }
                if (settings.pressureEMinState === 'default') {
                    settings.pressureEMinValue = Number.MAX_VALUE
                }
                if (settings.pressureEMaxState === 'default') {
                    settings.pressureEMaxValue = Number.NEGATIVE_INFINITY
                }
            })
            const previousValueAArray = []
            const previousValueBArray = []

            loggerDataArray.forEach(logger => {
                previousValueAArray.push({ serial: logger.Serial, value: null })
                previousValueBArray.push({ serial: logger.Serial, value: null })
            })

            
            

            readingsDataTemp.sort((a, b) => new Date(a.dateStamp) - new Date(b.dateStamp))


            let intervals:Array<IntervalTemp> = []

            

            /// manipulate readinds data
            readingsDataTemp.forEach((v: APIGetLoggerReadingsModel, idx: number) => {

                if (intervals.length === 0) {
                    intervals.push({
                        serial: v.loggerSerial,
                        intervals: {
                            A: [],
                            B: [],
                            C: [],
                            D: [],
                            E: []
                        }
                    })
                } else {
                    if (!intervals.map(int => int.serial).includes(v.loggerSerial)) {
                        intervals.push({
                            serial: v.loggerSerial,
                            intervals: {
                                A: [],
                                B: [],
                                C: [],
                                D: [],
                                E: []
                            }
                        })
                    }
                }




                if (v.dateStamp != null) {

                    let multiplier = decodeFlowMultiplier(0);
                    if (v.multiplier != null) {
                        multiplier = decodeFlowMultiplier(v.multiplier);

                    }

                    let value = v.value;
                    if (value == null) {
                        value = v.value2;
                    }

                    const prefix = v.loggerSerial;
                    

                    //-32768 is an invalid reading for logger, so should be ignored
                    // Flow is ALWAYS l/s regardless of logger units

                    if (v.channelletter == 'A') {
                        const updatedInterval = intervals.map(int => {
                            if (int.serial === v.loggerSerial) {
                                if (int.intervals.A.map(i => i.interval).includes(v.interval)) {
                                    int.intervals.A.map(i => {
                                        if (i?.interval === v.interval) {
                                            i.count += 1
                                        }
                                        return i
                                    })
                                } else {
                                    int.intervals.A.push({ interval: v.interval, count: 1 })
                                }
                            }
                            return int
                        })
                        intervals = updatedInterval

                        if (v.units == "mm") {
                            // Treat as accumulated rainfall
                            const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                            rec[prefix + 'Channel'] = "Reading";
                            rec[prefix + 'readingA'] = (v.difference != null && v.difference >= 0) ? v.difference * multiplier.factor : null;
                            rec[prefix + 'readingB'] = null;
                            rec[prefix + 'readingC'] = null;
                            rec[prefix + 'readingD'] = null;
                            rec[prefix + 'readingE'] = null;
                            rec[prefix + 'readingMax'] = (v.maxValue != -32768) ? v.maxValue : null;
                            rec[prefix + 'readingMin'] = (v.minValue != -32768) ? v.minValue : null;
                            rec[prefix + 'readingSet'] = null;
                            rec[prefix + 'readingClr'] = null;
                            // Sampling data
                            rec[prefix + 'readingCmin'] = null;
                            rec[prefix + 'readingCmax'] = null;
                            rec[prefix + 'readingDmin'] = null;
                            rec[prefix + 'readingDmax'] = null;
                            rec[prefix + 'readingEmin'] = null;
                            rec[prefix + 'readingEmax'] = null;
                            rec.ChannelLetter = 'A';
                            rec['unitA '] = 'mm';
                            rec.serial = prefix;
                            graphDataTemp.push(rec);

                            if (value != -32768) {

                                // Expect 4 readings per hour, aggregated
                                for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                                    if (parseInt(prefix) === loggersUnitsAndValues[i].serial) {
                                        loggersUnitsAndValues[i].intervals.A = v.interval
                                    }
                                    if (companyDefault) {
                                        loggersUnitsAndValues[i].channelsAvail |= 0x01

                                        if (v.difference != null && (v.difference * multiplier.factor * 4) > loggersUnitsAndValues[i].graphSettings.flowAMaxValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMaxValue = v.difference * multiplier.factor * 4;
                                        }
                                        if (v.difference != null && v.difference >= 0 && (v.difference * multiplier.factor) < loggersUnitsAndValues[i].graphSettings.flowAMinValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMinValue = v.difference * multiplier.factor;
                                        }
                                        if (v.difference != null && (v.difference * multiplier.factor * 4) > loggersUnitsAndValues[i].graphSettings.flowAMaxValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMaxValue = v.difference * multiplier.factor * 4;
                                        }
                                        if (v.difference != null && v.difference >= 0 && (v.difference * multiplier.factor) < loggersUnitsAndValues[i].graphSettings.flowAMinValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMinValue = v.difference * multiplier.factor;
                                        }
                                        if (v.units && loggersUnitsAndValues[i].graphSettings.flowAUnit == "") {
                                            loggersUnitsAndValues[i].graphSettings.flowAUnit = v.units;
                                        }

                                    } else if (loggersUnitsAndValues[i].isAshridgeDefault) {
                                        loggersUnitsAndValues[i].channelsAvail |= 0x01
                                        if (v.difference != null && (v.difference * multiplier.factor * 4) > loggersUnitsAndValues[i].graphSettings.flowAMaxValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMaxValue = v.difference * multiplier.factor * 4;
                                        }
                                        if (v.difference != null && v.difference >= 0 && (v.difference * multiplier.factor) < loggersUnitsAndValues[i].graphSettings.flowAMinValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMinValue = v.difference * multiplier.factor;
                                        }
                                        if (v.difference != null && (v.difference * multiplier.factor * 4) > loggersUnitsAndValues[i].graphSettings.flowAMaxValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMaxValue = v.difference * multiplier.factor * 4;
                                        }
                                        if (v.difference != null && v.difference >= 0 && (v.difference * multiplier.factor) < loggersUnitsAndValues[i].graphSettings.flowAMinValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMinValue = v.difference * multiplier.factor;
                                        }
                                        if (v.units && loggersUnitsAndValues[i].graphSettings.flowAUnit == "") {
                                            loggersUnitsAndValues[i].graphSettings.flowAUnit = v.units;
                                        }
                                    }


                                    else {
                                        if (loggersUnitsAndValues[i].serial === parseInt(v.loggerSerial)) {
                                            loggersUnitsAndValues[i].channelsAvail |= 0x01
                                            if (v.difference != null && (v.difference * multiplier.factor * 4) > loggersUnitsAndValues[i].graphSettings.flowAMaxValue) {
                                                loggersUnitsAndValues[i].graphSettings.flowAMaxValue = v.difference * multiplier.factor * 4;
                                            }
                                            if (v.difference != null && v.difference >= 0 && (v.difference * multiplier.factor) < loggersUnitsAndValues[i].graphSettings.flowAMinValue) {
                                                loggersUnitsAndValues[i].graphSettings.flowAMinValue = v.difference * multiplier.factor;
                                            }
                                            if (v.difference != null && (v.difference * multiplier.factor * 4) > loggersUnitsAndValues[i].graphSettings.flowAMaxValue) {
                                                loggersUnitsAndValues[i].graphSettings.flowAMaxValue = v.difference * multiplier.factor * 4;
                                            }
                                            if (v.difference != null && v.difference >= 0 && (v.difference * multiplier.factor) < loggersUnitsAndValues[i].graphSettings.flowAMinValue) {
                                                loggersUnitsAndValues[i].graphSettings.flowAMinValue = v.difference * multiplier.factor;
                                            }
                                            if (v.units && loggersUnitsAndValues[i].graphSettings.flowAUnit == "") {
                                                loggersUnitsAndValues[i].graphSettings.flowAUnit = v.units;
                                            }
                                        }
                                    }

                                }


                            }

                        } else {

                            let conversion = 1; //in what everthe unit of reading is
                            let flowConversion = 1;
                            let consumptionConversion = 1// always l/s
                            let unitsA = ""
                            let unitsAT = ""
                            let unitsAC = ""
                            for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                                const l = loggersUnitsAndValues[i].graphSettings

                                unitsA = l.flowAUnit
                                unitsAT = l.flowATotalUnit
                                unitsAC = l.flowAConsumptionUnit
                                if (loggersUnitsAndValues[i].isAshridgeDefault) {
                                    // sets unit to reading unit if not custom unit

                                    // totaliser
                                    unitsAT = v.units
                                    l.flowATotalUnit = v.units
                                    //consumption
                                    unitsAC = v.units
                                    l.flowAConsumptionUnit = v.units

                                    

                                    
                                }

                                if (parseInt(prefix) === loggersUnitsAndValues[i].serial) {
                                    flowConversion = flowConverter(l.flowAUnit, 'l/s')
                                }

                                ///Totaliser Units

                                if (parseInt(prefix) === loggersUnitsAndValues[i].serial) {
                                    if (v.units) {
                                        conversion = totalConverter(l.flowATotalUnit, v.units)

                                    }
                                }
                                ///Consumption Units

                                    if (parseInt(prefix) === loggersUnitsAndValues[i].serial) {
                                        if (v.units) {
                                            consumptionConversion = totalConverter(l.flowAConsumptionUnit, v.units)
                                        }
                                    }
                                
                            }
                            let previousValueA: number | null = null
                            if (moment(v.dateStamp).isSame(moment(v.dateStamp).startOf('day'), 'second')) {
                                previousValueAArray.forEach(item => {
                                    if (item.serial === parseInt(v.loggerSerial)) {
                                        previousValueA = item.value
                                    }
                                })
                            }


                            
                            if (previousValueA == null) {
                                previousValueA = value
                            }
                            
                            const consumption = (value != -32768 && moment(v.dateStamp).isSame(moment(v.dateStamp).startOf('day'), 'second')) ? value - previousValueA : null

                            const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                            rec[prefix + 'Channel'] = "Reading";
                            rec[prefix + 'readingA'] = (value != -32768) ? value * conversion : null
                            rec[prefix + 'consumptionA'] = (consumption != null) ? consumption * consumptionConversion : null
                            rec[prefix + 'readingB'] = null;
                            rec[prefix + 'consumptionB'] = null;
                            rec[prefix + 'readingC'] = null;
                            rec[prefix + 'readingD'] = null;
                            rec[prefix + 'readingE'] = null;
                            rec[prefix + 'readingMax'] = (v.maxValue != -32768) ? v.maxValue : null;
                            rec[prefix + 'readingMin'] = (v.minValue != -32768) ? v.minValue : null;
                            rec[prefix + 'readingSet'] = null;
                            rec[prefix + 'readingClr'] = null;
                            rec[prefix + 'flowRateA'] = parseFloat((v.flowRate * flowConversion).toFixed(3));
                            rec[prefix + 'readingCmin'] = null;
                            rec[prefix + 'readingCmax'] = null;
                            rec[prefix + 'readingDmin'] = null;
                            rec[prefix + 'readingDmax'] = null;
                            rec[prefix + 'readingEmin'] = null;
                            rec[prefix + 'readingEmax'] = null;
                            rec['unitA '] = unitsA
                            rec['unitAT'] = unitsAT
                            rec['unitAC'] = unitsAC
                            rec.ChannelLetter = 'A';
                            rec.serial = prefix;
                            rec.interval = v.interval
 
                            graphDataTemp.push(rec);

                            //limits

                            if (value !== -32768) {
                                // Loop over loggersUnitsAndValues to find matching logger serials
                                for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                                    
                                    const l = loggersUnitsAndValues[i].graphSettings
                                    if (v.flowRate && v.flowRate * flowConversion > l.flowAOriginalMax) {
                                        l.flowAOriginalMax = v.flowRate * flowConversion
                                    }
                                    if (v.flowRate && v.flowRate * flowConversion < l.flowAOriginalMin) {
                                        l.flowAOriginalMin = v.flowRate * flowConversion
                                    }

                                    loggersUnitsAndValues[i].channelsAvail |= 0x01
                                    if (loggersUnitsAndValues[i].isAshridgeDefault || l.flowAMinState === 'default' || l.flowAMaxState === 'default') {
                                        if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                            if (v.flowRate != null) {
                                                if (l.flowAMaxState === 'default') {
                                                    if (v.flowRate * flowConversion > l.flowAMaxValue) {
                                                        l.flowAMaxValue = v.flowRate * flowConversion;
                                                    }
                                                }
                                                if (l.flowAMinState === 'default') {
                                                    if (v.flowRate * flowConversion < l.flowAMinValue) {
                                                        l.flowAMinValue = v.flowRate * flowConversion;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    ///totals
                                    if (loggersUnitsAndValues[i].isAshridgeDefault || l.flowATotalMinState === 'default' || l.flowATotalMaxState === 'default') {
                                        if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                            if (v.value != null) {
                                                if (l.flowATotalMaxState === 'default') {
                                                    if (v.value > l.flowATotalMaxValue) {
                                                        l.flowATotalMaxValue = parseFloat((v.value * conversion).toFixed(2));
                                                    }
                                                }
                                                if (l.flowATotalMinState === 'default') {
                                                    if (v.value < l.flowATotalMinValue) {
                                                        l.flowATotalMinValue = parseFloat((v.value * conversion).toFixed(2));
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    ///Consumptions
                                    if (loggersUnitsAndValues[i].isAshridgeDefault || l.flowAConsumptionMinState === 'default' || l.flowAConsumptionMaxState === 'default') {
                                        if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                            if (consumption != null) {
                                                if (l.flowAConsumptionMaxState === 'default') {
                                                    if (consumption > l.flowAConsumptionMaxValue) {
                                                        l.flowAConsumptionMaxValue = parseFloat((consumption * consumptionConversion).toFixed(2));
                                                    }
                                                }
                                                if (l.flowAConsumptionMinState === 'default') {
                                                        l.flowAConsumptionMinValue = 0;
                                                }
                                            }
                                        }
                                    }

                                }
                                if (moment(v.dateStamp).isSame(moment(v.dateStamp).startOf('day'), 'second')) {
                                    previousValueAArray.forEach(item => {
                                        if (item.serial === parseInt(v.loggerSerial)) {
                                            item.value = value
                                        }
                                    })
                                }
                            }
                            
                        }
                    }

                    if (v.channelletter == 'B') {
                        const updatedInterval = intervals.map(int => {
                            if (int.serial === v.loggerSerial) {
                                if (int.intervals.B.map(i => i.interval).includes(v.interval)) {
                                    int.intervals.B.map(i => {
                                        if (i?.interval === v.interval) {
                                            i.count += 1
                                        }
                                        return i
                                    })
                                } else {
                                    int.intervals.B.push({ interval: v.interval, count: 1 })
                                }
                            }
                            return int
                        })
                        intervals = updatedInterval
                        let conversion = 1; //in what everthe unit of reading is
                        let flowConversion = 1; // always l/s
                        let consumptionConversion = 1
                        let unitsB = ""
                        let unitsBT = ""
                        let unitsBC = ""
                        for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                            const l = loggersUnitsAndValues[i].graphSettings
                            unitsB = l.flowBUnit
                            unitsBT = l.flowBTotalUnit
                            if (loggersUnitsAndValues[i].isAshridgeDefault) {

                                unitsBT = v.units
                                l.flowBTotalUnit = v.units
                                unitsBC = v.units
                                l.flowBConsumptionUnit = v.units
                            } else if (!companyDefault) {
                                flowConversion = flowConverter(l.flowBUnit, 'l/s')
                            } else {
                                flowConversion = flowConverter(l.flowBUnit, 'l/s')
                            }

                            //totaliser units

                                if (parseInt(prefix) === loggersUnitsAndValues[i].serial) {
                                    if (v.units) {
                                        conversion = totalConverter(l.flowBTotalUnit, v.units)
                                    }
                                }

                            ///Consumption Units

                                if (parseInt(prefix) === loggersUnitsAndValues[i].serial) {
                                    if (v.units) {
                                        consumptionConversion = totalConverter(l.flowAConsumptionUnit, v.units)
                                    }
                                }

                                if (v.units) {
                                    consumptionConversion = totalConverter(l.flowAConsumptionUnit, v.units)
                                }
                            
                        }
                        let previousValueB: number | null = null
                        if (moment(v.dateStamp).isSame(moment(v.dateStamp).startOf('day'), 'second')) {
                            previousValueBArray.forEach(item => {
                                if (item.serial === parseInt(v.loggerSerial)) {
                                    previousValueB = item.value
                                }
                            })
                        }

                        if (previousValueB == null) {
                            previousValueB = value
                        }

                        const consumption = (value != -32768 && moment(v.dateStamp).isSame(moment(v.dateStamp).startOf('day'), 'second')) ? value - previousValueB : null
                        const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                        rec[prefix + 'Channel'] = "Reading";
                        rec[prefix + 'readingA'] = null;
                        rec[prefix + 'consumptionA'] = null;
                        rec[prefix + 'readingB'] = (value != -32768) ? value * conversion : null;
                        rec[prefix + 'consumptionB'] = (consumption != null) ? consumption * consumptionConversion : null;
                        rec[prefix + 'readingC'] = null;
                        rec[prefix + 'readingD'] = null;
                        rec[prefix + 'readingE'] = null;
                        rec[prefix + 'readingSet'] = null;
                        rec[prefix + 'readingClr'] = null;
                        rec[prefix + 'flowRateB'] = parseFloat((v.flowRate * flowConversion).toFixed(3))
                        rec[prefix + 'readingCmin'] = null;
                        rec[prefix + 'readingCmax'] = null;
                        rec[prefix + 'readingDmin'] = null;
                        rec[prefix + 'readingDmax'] = null;
                        rec[prefix + 'readingEmin'] = null;
                        rec[prefix + 'readingEmax'] = null;
                        rec['unitB '] = unitsB
                        rec['unitBT'] = unitsBT
                        rec['unitBC'] = unitsBC
                        rec.ChannelLetter = 'B';
                        rec.serial = prefix;
                        rec.interval = v.interval
                        graphDataTemp.push(rec);

                        //limits

                        if (value !== -32768) {
                            // Loop over loggersUnitsAndValues to find matching logger serials
                            for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                                
                                const l = loggersUnitsAndValues[i].graphSettings
                                if (v.flowRate && v.flowRate * flowConversion > l.flowBOriginalMax) {
                                    l.flowBOriginalMax = v.flowRate * flowConversion    
                                }
                                if (v.flowRate && v.flowRate * flowConversion < l.flowBOriginalMin) {
                                    l.flowBOriginalMin = v.flowRate * flowConversion
                                }
                                if (loggersUnitsAndValues[i].isAshridgeDefault || l.flowBMinState === 'default' || l.flowBMaxState === 'default') {
                                    if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                        if (v.flowRate != null) {
                                            

                                            if (l.flowBMaxState === 'default') {
                                                if (v.flowRate * flowConversion > l.flowBMaxValue) {
                                                    l.flowBMaxValue = v.flowRate * flowConversion;
                                                }
                                            }
                                            if (l.flowBMinState === 'default') {
                                                if (v.flowRate * flowConversion < l.flowBMinValue) {
                                                    l.flowBMinValue = v.flowRate * flowConversion;
                                                }

                                            }
                                        }
                                    }
                                    if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                        loggersUnitsAndValues[i].channelsAvail |= 0x02
                                    }
                                }
                                ///totals
                                if (loggersUnitsAndValues[i].isAshridgeDefault || l.flowBTotalMinState === 'default' || l.flowBTotalMaxState === 'default') {
                                    if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                        if (v.value != null) {
                                            if (l.flowBTotalMaxState === 'default') {
                                                if (v.value > l.flowBTotalMaxValue) {
                                                    l.flowBTotalMaxValue = parseFloat((v.value * conversion).toFixed(2));
                                                }
                                            }
                                            if (l.flowBTotalMinState === 'default') {
                                                if (v.value < l.flowBTotalMinValue) {
                                                    l.flowBTotalMinValue = parseFloat((v.value * conversion).toFixed(2));
                                                }
                                            }
                                        }
                                    }
                                }
                                ///Consumptions
                                if (loggersUnitsAndValues[i].isAshridgeDefault || l.flowBConsumptionMinState === 'default' || l.flowBConsumptionMaxState === 'default') {
                                    if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                        if (consumption != null) {
                                            if (l.flowBConsumptionMaxState === 'default') {
                                                if (consumption > l.flowBConsumptionMaxValue) {
                                                    l.flowBConsumptionMaxValue = parseFloat((consumption * consumptionConversion).toFixed(2));
                                                }
                                            }
                                            if (l.flowBConsumptionMinState === 'default') {
                                                l.flowBConsumptionMinValue = 0;
                                            }
                                        }
                                    }
                                }
                            }
                            if (moment(v.dateStamp).isSame(moment(v.dateStamp).startOf('day'), 'second')) {
                                previousValueBArray.forEach(item => {
                                    if (item.serial === parseInt(v.loggerSerial)) {
                                        item.value = value
                                    }
                                })
                            }
                        }
                    }

                    if (v.channelletter == 'C') {
                        const updatedInterval = intervals.map(int => {
                            if (int.serial === v.loggerSerial) {
                                if (int.intervals.C.map(i => i.interval).includes(v.interval)) {
                                        int.intervals.C.map(i => {
                                        if (i?.interval === v.interval) {
                                            i.count += 1
                                        }
                                        return i
                                    })
                                } else {
                                    int.intervals.C.push({ interval: v.interval, count: 1 })
                                }
                            }
                            return int
                        })
                        intervals = updatedInterval
                        let conversion: number | null = 1;

                        let unitsC = ""
                        
                        for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                            const l = loggersUnitsAndValues[i].graphSettings
     
                            unitsC = l.pressureCUnit
                            l.pressureCOriginalUnit = v.units
                            if (loggersUnitsAndValues[i].isAshridgeDefault) {
                                unitsC = v.units
                                l.pressureCUnit = v.units
                            } else if (!companyDefault) {
                                if (parseInt(prefix) === loggersUnitsAndValues[i].serial) {
                                    if (v.units) {
                                        conversion = pressureConverter(l.pressureCUnit, v.units)
                                    }
                                }
                            } else {
                                if (v.units) {
                                    conversion = pressureConverter(l.pressureCUnit, v.units)
                                }
                            }
                            if (conversion === null) {
                                conversion = 1
                                unitsC = v.units
                                l.pressureCIsNotPressure = true
                            }
                        }

                        const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                        rec[prefix + 'Channel'] = "Reading";
                        rec[prefix + 'readingA'] = null;
                        rec[prefix + 'readingB'] = null;
                        rec[prefix + 'readingC'] = (value != -32768) ? parseFloat((value * conversion).toFixed(3)) : null;
                        rec[prefix + 'readingD'] = null;
                        rec[prefix + 'readingE'] = null;
                        rec[prefix + 'readingSet'] = null;
                        rec[prefix + 'readingClr'] = null;
                        rec[prefix + 'readingCmin'] = (v.minValue != -32768) ? parseFloat((v.minValue * conversion).toFixed(3)) : null;
                        rec[prefix + 'readingCmax'] = (v.maxValue != -32768) ? parseFloat((v.maxValue * conversion).toFixed(3)) : null;
                        rec[prefix + 'readingDmin'] = null;
                        rec[prefix + 'readingDmax'] = null;
                        rec[prefix + 'readingEmin'] = null;
                        rec[prefix + 'readingEmax'] = null;
                        rec.ChannelLetter = 'C';
                        rec['unitC '] = unitsC
                        rec.serial = prefix;
                        rec.interval = v.interval
                        graphDataTemp.push(rec);

                        if (value !== -32768) {
                            // Loop over loggersUnitsAndValues to find matching logger serials
                            for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                                
                                const l = loggersUnitsAndValues[i].graphSettings
                                if (v.value && v.value * conversion > l.pressureCOriginalMax) {
                                    l.pressureCOriginalMax = parseFloat((v.value * conversion).toFixed(3))
                                }
                                if (v.value && v.value * conversion < l.pressureCOriginalMin) {
                                    l.pressureCOriginalMin = parseFloat((v.value * conversion).toFixed(3))
                                }
                                if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {

                                    loggersUnitsAndValues[i].channelsAvail |= 0x04

                                    let inputValue = value
                                    if (l.pressureCValueAs === 'max') {
                                        inputValue = v.maxValue * conversion
                                    }
                                    if (l.pressureCValueAs === 'min') {
                                        inputValue = v.minValue * conversion
                                    }
                                    if (loggersUnitsAndValues[i].isAshridgeDefault || l.pressureCMinState === 'default' || l.pressureCMaxState === 'default') {
                                        
                                        if (inputValue != null) {
                                            if (inputValue * conversion  > l.pressureCMaxValue) {
                                                l.pressureCMaxValue = parseFloat((inputValue * conversion).toFixed(3))
                                            }
                                            if (inputValue * conversion < l.pressureCMinValue) {
                                                l.pressureCMinValue = parseFloat((inputValue * conversion).toFixed(3))
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (v.channelletter == 'D') {
                        const updatedInterval = intervals.map(int => {
                            if (int.serial === v.loggerSerial) {
                                if (int.intervals.D.map(i => i.interval).includes(v.interval)) {
                                    int.intervals.D.map(i => {
                                        if (i?.interval === v.interval) {
                                            i.count += 1
                                        }
                                        return i
                                    })
                                } else {
                                    int.intervals.D.push({ interval: v.interval, count: 1 })
                                }
                            }
                            return int
                        })
                        intervals = updatedInterval
                        let conversion = 1;

                        let unitsD = ""
                        for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                            const l = loggersUnitsAndValues[i].graphSettings
                            l.pressureDOriginalUnit = v.units
                            unitsD = l.pressureDUnit
                            if (loggersUnitsAndValues[i].isAshridgeDefault) {

                                unitsD = v.units
                                l.pressureDUnit = v.units

                            } else if (!companyDefault) {
                                if (parseInt(prefix) === loggersUnitsAndValues[i].serial) {
                                    if (v.units) {
                                        conversion = pressureConverter(l.pressureDUnit, v.units)
                                    }
                                }
                            } else {
                                if (v.units) {
                                    conversion = pressureConverter(l.pressureDUnit, v.units)
                                }
                            }
                            if (conversion === null) {
                                conversion = 1
                                unitsD = v.units
                                l.pressureDIsNotPressure = true
                            }
                        }


                        const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                        rec[prefix + 'Channel'] = "Reading";
                        rec[prefix + 'readingA'] = null;
                        rec[prefix + 'readingB'] = null;
                        rec[prefix + 'readingC'] = null;
                        rec[prefix + 'readingD'] = (value != -32768) ? parseFloat((value * conversion).toFixed(3)) : null;
                        rec[prefix + 'readingE'] = null;
                        rec[prefix + 'readingSet'] = null;
                        rec[prefix + 'readingClr'] = null;
                        rec[prefix + 'readingCmin'] = null;
                        rec[prefix + 'readingCmax'] = null;
                        rec[prefix + 'readingDmin'] = (v.minValue != -32768) ? parseFloat((v.minValue * conversion).toFixed(3)) : null;
                        rec[prefix + 'readingDmax'] = (v.maxValue != -32768) ? parseFloat((v.maxValue * conversion).toFixed(3)) : null;
                        rec[prefix + 'readingEmin'] = null;
                        rec[prefix + 'readingEmax'] = null;
                        rec.ChannelLetter = 'D';
                        rec.interval = v.interval
                        rec['unitD '] = unitsD
                        rec.serial = prefix;

                        graphDataTemp.push(rec);

                        if (value !== -32768) {
                            // Loop over loggersUnitsAndValues to find matching logger serials
                            for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                                
                                const l = loggersUnitsAndValues[i].graphSettings
                                if (v.value && v.value * conversion > l.pressureDOriginalMax) {
                                    l.pressureDOriginalMax = parseFloat((v.value * conversion).toFixed(3))
                                }
                                if (v.value && v.value * conversion < l.pressureDOriginalMin) {
                                    l.pressureDOriginalMin = parseFloat((v.value * conversion).toFixed(3))
                                }
                                if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                    loggersUnitsAndValues[i].channelsAvail |= 0x08
                                    let inputValue = value
                                    if (l.pressureDValueAs === 'max') {
                                        inputValue = v.maxValue * conversion
                                    }
                                    if (l.pressureDValueAs === 'min') {
                                        inputValue = v.minValue * conversion
                                    }
                                    if (loggersUnitsAndValues[i].isAshridgeDefault || l.pressureDMinState === 'default' || l.pressureDMaxState === 'default') {
                                        if (inputValue != null) {
                                            if (inputValue * conversion > l.pressureDMaxValue) {
                                                l.pressureDMaxValue = parseFloat((inputValue * conversion).toFixed(3))
                                            }
                                            if (inputValue * conversion < l.pressureDMinValue) {
                                                l.pressureDMinValue = parseFloat((inputValue * conversion).toFixed(3))
                                            }

                                        }


                                    }


                                }
                            }
                        }

                    }
                    if (v.channelletter == 'E') {
                        const updatedInterval = intervals.map(int => {
                            if (int.serial === v.loggerSerial) {
                                if (int.intervals.E.map(i => i.interval).includes(v.interval)) {
                                    int.intervals.E.map(i => {
                                        if (i?.interval === v.interval) {
                                            i.count += 1
                                        }
                                        return i
                                    })
                                } else {
                                    int.intervals.E.push({ interval: v.interval, count: 1 })
                                }
                            }
                            return int
                        })
                        intervals = updatedInterval

                        let conversion = null;

                        let unitsE = ""
                        for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                            
                                const l = loggersUnitsAndValues[i].graphSettings
                                l.pressureEOriginalUnit = v.units
                                unitsE = l.pressureEUnit
                                if (loggersUnitsAndValues[i].isAshridgeDefault) {

                                    unitsE = v.units
                                    l.pressureEUnit = v.units

                                } else if (!companyDefault) {
                                    if (v.units === 'psi' || v.units === 'mH2O' || v.units === 'mwg' || v.units === 'mb' || v.units === 'bar') {
                                        if (parseInt(prefix) === loggersUnitsAndValues[i].serial) {
                                            if (v.units) {
                                                conversion = pressureConverter(l.pressureEUnit, v.units)
                                            }
                                        }
                                    }
                                } else {
                                    if (v.units) {
                                        if (v.units === 'psi' || v.units === 'mH2O' || v.units === 'mwg' || v.units === 'mb' || v.units === 'bar') {
                                            conversion = pressureConverter(l.pressureEUnit, v.units)
                                        } else {
                                            unitsE = v.units
                                        }
                                    }
                                }
                            

                            if (conversion === null) {
                                conversion = 1
                                unitsE = v.units
                                l.pressureEIsNotPressure = true
                            }

                        }
                        

                        const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                        rec[prefix + 'Channel'] = "Reading";
                        rec[prefix + 'readingA'] = null;
                        rec[prefix + 'readingB'] = null;
                        rec[prefix + 'readingC'] = null;
                        rec[prefix + 'readingD'] = null;
                        rec[prefix + 'readingE'] = (value != -32768) ? parseFloat((value * conversion).toFixed(3)) : null;
                        rec[prefix + 'readingSet'] = null;
                        rec[prefix + 'readingClr'] = null;
                        rec[prefix + 'readingCmin'] = null;
                        rec[prefix + 'readingCmax'] = null;
                        rec[prefix + 'readingDmin'] = null;
                        rec[prefix + 'readingDmax'] = null;
                        rec[prefix + 'readingEmin'] = (v.minValue != -32768) ? parseFloat((v.minValue * conversion).toFixed(3)) : null;
                        rec[prefix + 'readingEmax'] = (v.maxValue != -32768) ? parseFloat((v.maxValue * conversion).toFixed(3)) : null;
                        rec.ChannelLetter = 'E';
                        rec['unitE '] = unitsE
                        rec.serial = prefix;
                        rec.interval = v.interval
                        graphDataTemp.push(rec);

                        if (value !== -32768 && value) {
                            // Loop over loggersUnitsAndValues to find matching logger serials
                            for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                                
                                const l = loggersUnitsAndValues[i].graphSettings
                                if (v.value && v.value * conversion > l.pressureEOriginalMax) {
                                    l.pressureEOriginalMax = parseFloat((v.value * conversion).toFixed(3))
                                }
                                if (v.value && v.value * conversion < l.pressureEOriginalMin) {
                                    l.pressureEOriginalMin = parseFloat((v.value * conversion).toFixed(3))
                                }
                                if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                    loggersUnitsAndValues[i].channelsAvail |= 0x10
                                    let inputValue = value

                                    if (l.pressureEValueAs === 'max') {
                                        inputValue = v.maxValue * conversion
                                    }
                                    if (l.pressureEValueAs === 'min') {
                                        inputValue = v.minValue * conversion
                                    }
                                    if (loggersUnitsAndValues[i].isAshridgeDefault || l.pressureEMinState === 'default' || l.pressureEMaxState === 'default') {
                                        if (inputValue != null) {
                                            if (inputValue * conversion > l.pressureEMaxValue) {
                                                l.pressureEMaxValue = parseFloat((inputValue * conversion).toFixed(3))
                                            }
                                            if (inputValue * conversion < l.pressureEMinValue) {
                                                l.pressureEMinValue = parseFloat((inputValue * conversion).toFixed(3))
                                                
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            
            
        })
        

            


            for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                
                const l = loggersUnitsAndValues[i].graphSettings
                const chan = loggersUnitsAndValues[i].channelsAvail
                if (l.flowAMinValue == Number.MAX_VALUE) {
                    if (!chan & 0x01) {
                        l.flowAMinValue = l.flowAMaxValue;
                    }
                }
                if (l.flowBMinValue == Number.MAX_VALUE) {
                    if (!chan & 0x02) {
                        l.flowBMinValue = l.flowBMaxValue;
                    }
                }
                if (l.pressureCMinValue == Number.MAX_VALUE) {
                    if (!chan & 0x04) {
                        l.pressureCMinValue = l.pressureCMaxValue;
                    }
                }
                if (l.pressureDMinValue == Number.MAX_VALUE) {
                    if (!chan & 0x08) {
                        l.pressureDMinValue = l.pressureDMaxValue;
                    }
                }
                if (l.pressureEMinValue == Number.MAX_VALUE) {
                    if (!chan & 0x10) {
                        
                        l.pressurEMinValue = l.pressureEMaxValue;
                    }
                }

                //if (l.pressureCMinState === 'default' && l.pressureDMinState === 'default' && l.pressureEMinState == 'default' && !l.pressureCIsNotPressure && !l.pressureDIsNotPressure && !l.pressureEIsNotPressure) {
                //    const minPressure = Math.min(l.pressureCMinValue, l.pressureDMinValue, l.pressureEMinValue)
                //    if (chan >= 16) {
                //        l.pressureCMinValue = minPressure
                //        l.pressureDMinValue = minPressure
                //        l.pressureEMinValue = minPressure
                //    } else if (chan >= 8) {
                //        l.pressureCMinValue = minPressure
                //        l.pressureDMinValue = minPressure
                //    }
                //}
                //if (l.pressureCMaxState === 'default' && l.pressureDMaxState === 'default' && l.pressureEMaxState == 'default' && !l.pressureCIsNotPressure && !l.pressureDIsNotPressure && !l.pressureEIsNotPressure) {
                //    const maxPressure = Math.max(l.pressureCMaxValue, l.pressureDMaxValue, l.pressureEMaxValue)
                //    l.pressureCMaxValue = maxPressure
                //    l.pressureDMaxValue = maxPressure
                //    l.pressureEMaxValue = maxPressure
                //}
            }

            
            for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                
                const data = loggersUnitsAndValues[i].graphSettings
                if (data.flowATotalPerDay || data.flowBTotalPerDay) {
                    
                    const graphDataClone = [...graphDataTemp]
                    
                    if (data.flowATotalPerDay) {
                        graphDataClone.map(item => {
                            const serial = Object.keys(item)[1].slice(0, Object.keys(item)[1].indexOf('Chan'))
                            if (Object.keys(item).some(key =>
                                key.includes('readingA') && item[key] !== null && !moment(item.date).isSame(moment(item.date).startOf('day')))) {
                                item[`${serial}readingA`] = null
                                return item
                            }
                            else {
                                return item
                            }
                        })
                        
                        
                    }
                    if (data.flowBTotalPerDay) {
                        graphDataClone.map(item => {
                            const serial = Object.keys(item)[1].slice(0, Object.keys(item)[1].indexOf('Chan'))
                            if (Object.keys(item).some(key =>
                                key.includes('readingB') && item[key] !== null && !moment(item.date).isSame(moment(item.date).startOf('day')))) {
                                item[`${serial}readingB`] = null
                                return item
                            }
                            else {
                                return item
                            }
                        })


                    }


                    graphDataTemp = graphDataClone
                }
            }



           


            graphDataTemp.sort((a, b) => a.date.getTime() - b.date.getTime());

            /// fills in any missing data points with null values to allow gaps to be displayed on graph
            //const data = [];
            
            //const endDate = moment(graphDataTemp[graphDataTemp.length - 1].date);
     
            //for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                
            //    const serialNumber = loggersUnitsAndValues[i].serial; // Get current logger serial
            //    const channels = ['A', 'B', 'C', 'D', 'E']


            //    for (const channel of channels) {
            //        console.log(channel)
            //        console.log(graphDataTemp.filter(item => item.ChannelLetter === 'C'))
            //        const startDate = moment(graphDataTemp.filter(item => item?.ChannelLetter === channel)[0]?.date);
            //        const currentDate = startDate.clone();
            //        console.log(channel)
            //        const intervalSeconds = intervals.filter(int => parseInt(int.serial) === serialNumber)[0].intervals[channel].reduce((prev, current) => prev.count > current.count ? prev : current, intervals[0] || {}).interval 
            //        console.log(intervalSeconds)
            //        if (intervalSeconds) {
            //            while (currentDate <= endDate) {
            //                let dataPoint = {};

            //                const existingDataPoint = graphDataTemp.find(item => moment(item.date).isSame(currentDate) && serialNumber === parseInt(item.serial) && item.ChannelLetter === channel);
            //                console.log(existingDataPoint)
            //                // Extract the numeric part from "125Channel"
            //                if (existingDataPoint) {

            //                    dataPoint = existingDataPoint



            //                } else {
            //                    dataPoint = {
            //                        [`${serialNumber}Channel`]: "Reading",
            //                        [`${serialNumber}readingA`]: null,
            //                        [`${serialNumber}consumptionA`]: null,
            //                        [`${serialNumber}readingB`]: null,
            //                        [`${serialNumber}consumptionB`]: null,
            //                        [`${serialNumber}readingC`]: null,
            //                        [`${serialNumber}readingClr`]: null,
            //                        [`${serialNumber}readingCmax`]: null,
            //                        [`${serialNumber}readingCmin`]: null,
            //                        [`${serialNumber}readingD`]: null,
            //                        [`${serialNumber}readingDmax`]: null,
            //                        [`${serialNumber}readingDmin`]: null,
            //                        [`${serialNumber}readingE`]: null,
            //                        [`${serialNumber}readingEmax`]: null,
            //                        [`${serialNumber}readingEmin`]: null,
            //                        [`${serialNumber}readingSet`]: null,
            //                        ChannelLetter: channel,
            //                        date: currentDate.clone().toDate(),
            //                        serial: serialNumber?.toString()
            //                    };
            //                }
            //                //if (dataPoint.ChannelLetter === 'C') {
            //                    data.push(dataPoint);
            //                //}
            //                currentDate.add(intervalSeconds / 60, 'minutes');
            //            }
            //        }
            //    }
            //}
            
            ////data.sort((a, b) => a.date.getTime() - b.date.getTime());
            //console.log(data, graphDataTemp)


            let hasFlow = false
            let hasPressure = false
            let hasTotal = false
            let hasConsumption = false
            loggersUnitsAndValues.forEach(item => {
                const i = item.graphSettings
                const channels = item.channelsAvail

                const channelA = 0x01
                const channelB = 0x02
                const channelC = 0x04
                const channelD = 0x08
                const channelE = 0x10  

                if (channels & (channelA || channelB)) {
                    setHasFlow(true)
                    if (i.flowAVisible) {
                        hasFlow = true
                    }
                    if (i.flowBVisible && i.flowBMaxValue) {
                        hasFlow = true
                    }
                }
                if (channels & (channelC || channelD || channelE)) { 
                    setHasPressureChannels(true)

                    if (i.pressureCVisible) {
                        hasPressure = true
                    }
                    if (i.pressureDVisible && channels > 8) {
                        hasPressure = true
                    }
                    if (i.pressureEVisible && channels > 16) {
                        hasPressure = true
                    }
                } 

                if (i.flowATotalVisible && channels & channelA) {

                    setHasTotal(true)
                    hasTotal = true
                }
                if (i.flowBTotalVisible && i.flowBMaxValue && channels & channelB) {
                    hasTotal = true
                }

                if (i.flowAConsumptionVisible && channels & channelA) {

                    setHasConsumption(true)
                    hasConsumption = true
                }
                if (i.flowBConsumptionVisible && channels & channelB) {
                    hasConsumption = true
                }
            })


            setShowFlow(hasFlow)
            setShowPressure(hasPressure)
            setShowTotal(hasTotal)
            setShowConsumption(hasConsumption)
            setGraphData(graphDataTemp)
            setReadingsData(readingsDataTemp)
            //units set
            setUnitsAndValues(loggersUnitsAndValues)
            setCheckAlarms(true)

            setLoggers(loggerDataArray)
            //console.log(loggerDataArray[0].Serial)
            if (graphDataTemp.length > 0 && loggerDataArray[0].Serial) {
                setLoggersToTemplate(loggerDataArray[0].Serial)
            }
            setLoading(false)
        })
        
        
    }



    useEffect(() => {
        
        if (alarms.length > 0) {
            checkAlarmAxisLevels();
        }
    }, [alarms, checkAlarms]);

    const checkAlarmAxisLevels = (): void => {

        const alarmDetails = {
            visible: false,
            minValue: Number.MAX_VALUE,
            maxValue: Number.NEGATIVE_INFINITY,
            channel: "",
            color: '#ff0000',
            transparency: "15",
            labelSize: 14,
            message: "",
            operator: ""
        };

        if (unitsAndValues.length > 0 && alarms.length > 0) {
            const loggerUnitsAndValues = [...unitsAndValues];
            loggerUnitsAndValues.forEach(values => values.alarms = [])
            const filteredLoggerSettings = loggerUnitsAndValues.filter(item =>
                alarms.some(alarm => alarm.serial === item.serial)
            );

            filteredLoggerSettings.forEach((loggerSetting) => {
                alarms.forEach((alarm: Alarm) => {
                    if (alarm.serial === loggerSetting.serial) {  
                        const alarmDetailsCopy = { ...alarmDetails };  // fresh copy for each loop
                        const a = alarmDetailsCopy;
                        const l = loggerSetting.graphSettings;
                        const letters = ['A', 'B', 'C', 'D', 'E']
                        for (const letter of letters) {
                            let input = ""
                            switch (letter) {
                                case 'A':
                                    input = 'flowA'
                                    break;
                                case 'B':
                                    input = 'flowB'
                                    break;
                                case 'C':
                                    input = 'pressureC'
                                    break;
                                case 'D':
                                    input = 'pressureD'
                                    break;
                                case 'E':
                                    input = 'pressureE'
                                    break;

                            }
                            if (alarm.channel === letter) {
                                a.channel = letter
                                a.message = alarm.message
                                let conversion = 1
                                if (letter === 'A' || letter === 'B') {
                                    conversion = flowConverter(l[`${input}Unit`], 'l/s')
                                } else {
                                    conversion = pressureConverter(l[`${input}Unit`], l[`${input}OriginalUnit`])

                                    if (conversion === null) {
                                        conversion = 1
                                    }
                                }
                                if (alarm.setOperator === enumChariotAlarmOperator.GreaterThan ||
                                    alarm.setOperator === enumChariotAlarmOperator.GreaterThanOrEqual) {
                                        a.operator = ">"
        
                                    if (alarm.setLevel * conversion > a.maxValue) {
                                        a.maxValue = parseFloat((alarm.setLevel * conversion).toFixed(2));

                                        if (l[`${input}MaxState`] === 'default') {
                                            l[`${input}MaxValue`] = Math.max(a.maxValue, l[`${input}MaxValue`]);
                                        }
                                        
                                        
                                    }

                                    if (alarm.clrLevel * conversion < a.minValue) {
                                        a.minValue = parseFloat((alarm.clrLevel * conversion).toFixed(2));
                                        if (l[`${input}MinState`] === 'default') {
                                            l[`${input}MinValue`] = Math.min(a.minValue, l[`${input}MinValue`]);
                                        }
                                    }
                                    if (loggerSetting.graphSettings[`${input}OriginalMax`] > alarm.clrLevel * conversion) {
                                        a.visible = true
                                        if (loggerSetting.graphSettings[`${input}MaxValue`] < alarm.setLevel) {
                                            loggerSetting.graphSettings[`${input}MaxValue`] = alarm.setLevel
                                        }
                                    }
                                } else if (alarm.setOperator === enumChariotAlarmOperator.LessThan ||
                                    alarm.setOperator === enumChariotAlarmOperator.LessThanOrEqual) {
                                        a.operator = "<"

                                    if (alarm.setLevel * conversion < a.minValue) {
                                        a.minValue = alarm.setLevel * conversion;
                                        if (l[`${input}MinState`] === 'default') {
                                            l[`${input}MinValue`] = Math.min(a.minValue, l[`${input}MinValue`]);
                                        }
                                        
                                    }

                                    if (alarm.clrLevel * conversion > a.maxValue) {
                                        a.maxValue = alarm.clrLevel * conversion;
                                        if (l[`${input}MaxState`] === 'default') {
                                            l[`${input}MaxValue`] = Math.max(a.maxValue, l[`${input}MaxValue`]);
                                        }
                                    }
                                    if (loggerSetting.graphSettings[`${input}OriginalMin`] < alarm.clrLevel * conversion) {
                                        a.visible = true
                                        if (loggerSetting.graphSettings[`${input}MinValue`] < alarm.setLevel) {
                                            loggerSetting.graphSettings[`${input}MinValue`] = alarm.setLevel
                                        }
                                    }
                                }
                            }
                        }
                        loggerSetting.alarms.push(alarmDetailsCopy);  // add only if not a duplicate
                    }
                });
            });
            
            loggerUnitsAndValues.forEach(units => {
                filteredLoggerSettings.forEach(setting => {
                    if (units.serial === setting.serial) {
                        units = setting
                    }
                })
            })

            setUnitsAndValues(loggerUnitsAndValues)
        }
    };


    const flowConverter = (unit: string, previousUnit: string | null): number => {
        const conversionTable: Record<string, Record<string, number>> = {
            'l/s': {
                'l/s': 1,
                'm3/h': 3.6,
                'gpm': 15.8503,
                'MGD': 0.022824,
                'gps': 0.264172,
                'cfs': 0.0353147,
                'GPD': 22712
            },
            'm3/h': {
                'l/s': 1 / 3.6,
                'm3/h': 1,
                'gpm': 4.40287,
                'MGD': 0.0063401,
                'gps': 0.073986,
                'cfs': 0.009810,
                'GPD': 6310
            },
            'gpm': {
                'l/s': 1 / 15.8503,
                'm3/h': 1 / 4.40287,
                'gpm': 1,
                'MGD': 0.00144,
                'gps': 0.01667,
                'cfs': 0.002228,
                'GPD': 1440
            },
            'MGD': {
                'l/s': 1 / 0.022824,
                'm3/h': 1 / 0.0063401,
                'gpm': 1 / 0.00144,
                'MGD': 1,
                'gps': 1.201,
                'cfs': 1.547,
                'GPD': 1_000_000
            },
            'gps': {
                'l/s': 1 / 0.264172,
                'm3/h': 1 / 0.073986,
                'gpm': 1 / 0.01667,
                'MGD': 1 / 1.201,
                'gps': 1,
                'cfs': 0.133681,
                'GPD': 86_400
            },
            'cfs': {
                'l/s': 1 / 0.0353147,
                'm3/h': 1 / 0.009810,
                'gpm': 1 / 0.002228,
                'MGD': 1 / 1.547,
                'gps': 1 / 0.133681,
                'cfs': 1,
                'GPD': 646_317
            },
            'GPD': {
                'l/s': 1 / 22712,
                'm3/h': 1 / 6310,
                'gpm': 1 / 1440,
                'MGD': 1 / 1_000_000,
                'gps': 1 / 86_400,
                'cfs': 1 / 646_317,
                'GPD': 1
            }
        };

        if (!previousUnit || !conversionTable[previousUnit] || !conversionTable[previousUnit][unit]) {
            return 1; // Default to 1 if conversion is not found
        }

        return conversionTable[previousUnit][unit];
    };

    const totalConverter = (userUnit: string, dbUnit: string): number => {
        let conversion = 1
        if (userUnit === 'l') {
            if (dbUnit === 'm3') {
                conversion = 1000
            } else if (dbUnit === 'gal') {
                conversion = 3.78541
            } else if (dbUnit === 'ft3') {
                conversion = 28.3168
            }
        } else if (userUnit === 'm3') {
            if (dbUnit === 'l') {
                conversion = 1 / 1000
            } else if (dbUnit === 'gal') {
                conversion = 0.00378541
            } else if (dbUnit === 'ft3') {
                conversion = 0.0283168
            }
        } else if (userUnit === 'gal') {
            if (dbUnit === 'l') {
                conversion = 0.264172
            } else if (dbUnit === 'm3') {
                conversion = 264.172
            } else if (dbUnit === 'ft3') {
                conversion = 7.48052
            }
        } else if (userUnit === 'ft3') {
            if (dbUnit === 'l') {
                conversion = 0.0353147
            } else if (dbUnit === 'm3') {
                conversion = 35.3147
            } else if (dbUnit === 'gal') {
                conversion = 0.133681
            }
        }
        return conversion
    }

const pressureConverter = (userUnit, dbUnit): number | null => {
    let conversion: number | null = 1
    if (userUnit === 'bar') {
        if (dbUnit === 'mb') {
            conversion = 1 / 1000
        } else if (dbUnit === 'mwg' || dbUnit === 'mH2O' || dbUnit === 'mH20') {
            conversion = 0.0980665
        } else if (dbUnit === 'psi') {
            conversion = 0.0689476
        }
    } else if (userUnit === 'mb') {
        if (dbUnit === 'bar') {
            conversion = 1000
        } else if (dbUnit === 'mwg' || dbUnit === 'mH2O' || dbUnit === 'mH20') {
            conversion = 98.0665
        } else if (dbUnit === 'psi') {
            conversion = 68.9476
        }

    } else if (userUnit === "mwg" || userUnit === 'mH2O' || userUnit === 'mH20') {
        if (dbUnit === "mwg" || dbUnit === 'mH2O' || dbUnit === 'mH20') {
            conversion = 1
        } else if (dbUnit === 'bar') {
            conversion = 10.1972
        } else if (dbUnit === 'mbar') {
            conversion = 0.0101972
        } else if (dbUnit === 'psi') {
            conversion = 0.70307
        }
    } else if (userUnit === 'psi') {
        if (dbUnit === 'bar') {
            conversion = 14.5038
        } else if (dbUnit === 'mwg' || dbUnit === 'mH2O' || dbUnit === 'mH20') {
            conversion = 0.4335
        } else if (dbUnit === 'mb') {
            conversion = 0.0145038
        }

    } else {
        conversion = null
    }

    return conversion
    }




    const loadLCAlarmLevels = (serial: number): void => {
        
        
        CallGetAPI(CreateUrl('/api/aquaguard/LCAlarmConfigurations?serial=' + serial), {})
            .then(data => {

                if (data.length > 0) {
                    const alarmsTemp: Array<Alarm> = [];
                    for (let i = 0; i < data.length; i++) {
                        let channel = "";
                        let setLevel = 0;
                        let clrLevel = 0;
                        if (data[i].setInput == enumChariotAlarmInput.FlowA && data[i].clrInput == enumChariotAlarmInput.FlowA) {
                            channel = "A";
                            setLevel = data[i].setThreshold;
                            clrLevel = data[i].clrThreshold;
                        }
                        if (data[i].setInput == enumChariotAlarmInput.FlowB && data[i].clrInput == enumChariotAlarmInput.FlowB) {
                            channel = "B";
                            setLevel = data[i].setThreshold /** this.flowFactor*/;
                            clrLevel = data[i].clrThreshold /** this.flowFactor*/;
                        }
                        if (data[i].setInput == enumChariotAlarmInput.Pressure1Latest && data[i].clrInput == enumChariotAlarmInput.Pressure1Latest) {
                            channel = "C";
                            setLevel = data[i].setThreshold;
                            clrLevel = data[i].clrThreshold;
                        }
                        if (data[i].setInput == enumChariotAlarmInput.Pressure2Latest && data[i].clrInput == enumChariotAlarmInput.Pressure2Latest) {
                            channel = "D";
                            setLevel = data[i].setThreshold;
                            clrLevel = data[i].clrThreshold;
                        }
                        if (channel != "") {
                            alarmsTemp.push({
                                id: data[i].id,
                                serial: serial,
                                site: undefined,
                                channel: channel,
                                message: data[i].alarmMessage,
                                setLevel: setLevel,
                                clrLevel: clrLevel,
                                setOperator: data[i].setOperator,
                            });
                        }


                    }

                    setAlarms(alarmsTemp)
                }
                

            })
            .catch(function (error) {
                console.log(error);
                
            });

    }


    const loadLoggerAlarmLevels = (serial: number): void =>  {

        // Only show alarm levels for single Chariot logger
        CallGetAPI(CreateUrl('/api/aquaguard/LoggerAlarmLevels?logger=' + serial + "&startDate=" + moment(startDate).format("yyyy-MM-DD") + "&endDate=" + moment(endDate).format("yyyy-MM-DD") + "T23:59:59"), {})
            .then(data => {
                if (data.length > 0) {

                const alarmsTemp = [];
                const channels: Array<string> = data[0].channels.split(',');
                    for (let i = 0; i < channels.length; i++) {
                    let channel = "";
                    let setLevel = 0;
                    let clrLevel = 0;
                    if (channels[i].startsWith("A:") && channels[i].length > 2) {
                        channel = "A";
                        setLevel = data[0].channelASetLevel
                        clrLevel = data[0].channelAClearLevel 
                    }
                    if (channels[i].startsWith("B:") && channels[i].length > 2) {
                        channel = "B";
                        setLevel = data[0].channelBSetLevel 
                        clrLevel = data[0].channelBClearLevel 
                    }
                    if (channels[i].startsWith("C:") && channels[i].length > 2) {
                        channel = "C";
                        setLevel = data[0].channelCSetLevel;
                        clrLevel = data[0].channelCClearLevel;
                    }
                    if (channels[i].startsWith("D:") && channels[i].length > 2) {
                        channel = "D";
                        setLevel = data[0].channelDSetLevel;
                        clrLevel = data[0].channelDClearLevel;
                    }
                    if (channels[i].startsWith("E:") && channels[i].length > 2) {
                        channel = "E";
                        setLevel = data[0].channelESetLevel;
                        clrLevel = data[0].channelEClearLevel;
                    }
                    if (channel != "" && setLevel !== null && clrLevel !== null) {
                        alarmsTemp.push({
                            id: i,
                            serial: serial,
                            site: undefined,
                            channel: channel,
                            message: channels[i].substring(2),
                            setLevel: setLevel,
                            clrLevel: clrLevel,
                            setOperator: enumChariotAlarmOperator.GreaterThan,
                        });
                    }


                }

                setAlarms(alarmsTemp)

            }

        })
        .catch(function (error) {
            console.log(error);

        });

    }

    const loadSiteAlarmLevels = (siteId: number): void => {

        // Only show alarm levels for single Chariot logger
        CallGetAPI(CreateUrl('/api/aquaguard/SiteAlarmConfigurations?siteId=' + siteId), {})
            .then(data => {

            if (data.length > 0) {
                const alarmsTemp = [...alarms];
                for (let i = 0; i < data.length; i++) {
                    let channel = "";
                    let setLevel = 0;
                    let clrLevel = 0;
                    if (data[i].setInput == enumChariotAlarmInput.FlowA && data[i].clrInput == enumChariotAlarmInput.FlowA) {
                        channel = "A";
                        setLevel = data[i].setThreshold /* * this.flowFactor; */
                        clrLevel = data[i].clrThreshold /** this.flowFactor; */
                    }
                    if (data[i].setInput == enumChariotAlarmInput.FlowB && data[i].clrInput == enumChariotAlarmInput.FlowB) {
                        channel = "B";
                        setLevel = data[i].setThreshold /* * this.flowFactor; */
                        clrLevel = data[i].clrThreshold /* * this.flowFactor; */
                    }
                    if (data[i].setInput == enumChariotAlarmInput.Pressure1Latest && data[i].clrInput == enumChariotAlarmInput.Pressure1Latest) {
                        channel = "C";
                        setLevel = data[i].setThreshold;
                        clrLevel = data[i].clrThreshold;
                    }
                    if (data[i].setInput == enumChariotAlarmInput.Pressure2Latest && data[i].clrInput == enumChariotAlarmInput.Pressure2Latest) {
                        channel = "D";
                        setLevel = data[i].setThreshold;
                        clrLevel = data[i].clrThreshold;
                    }
                    if (channel != "") {
                        alarmsTemp.push({
                            id: data[i].id,
                            serial: undefined,
                            site: siteId,
                            channel: channel,
                            message: data[i].alarmMessage,
                            setLevel: setLevel,
                            clrLevel: clrLevel,
                            setOperator: data[i].setOperator,
                        });
                    }
                }
                setAlarms(alarmsTemp)
            }
            
        })
        .catch(function (error) {
            console.log(error);
        
        });

    }







    const onDateChange = (startDateChange: moment, endDateChange : moment, rainfall): void => {

        let finalDate = moment(endDateChange).toDate() >= moment().toDate() ? moment(endDateChange).toDate() : moment(endDateChange).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate()
        if (finalDate > moment().toDate()) {
        finalDate = moment().toDate()
        }
        const beginDate = moment(startDateChange).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate()
        setStartDate(beginDate)
        setEndDate(finalDate)
        //setVisualRange({startValue: beginDate, endValue: finalDate})
        setShowButton(true)
    
        
    }

    const removeLogger = (serial: number): void => {
        const loggersRemove = [ ...loggers ];
        
        const idx = loggersRemove.findIndex((l) => l.Serial == serial);
        loggersRemove.splice(idx, 1);

        if (loggersRemove.length > 1) {
            sessionStorage.setItem('selectedLoggers', loggersRemove.map(logger => logger.LoggerId.toString()).join())
        } else {
            sessionStorage.setItem('selectedLoggers', loggersRemove[0].LoggerId.toString())
        }
            setLoggers(loggersRemove)
    }
    const handleRedrawClick = (): void => {
        setShowButton(false)
        setGraphData([])
        reloadData(location.state?.groupId ? location.state?.groupId : 0, location.state?.loggers, location.state?.site, true);
        setVisualRange({startValue: startDate, endValue: endDate})
    }

    useEffect(() => {
        reloadData(location.state?.groupId ? location.state?.groupId : 0, location.state?.loggers, location.state?.site, true);
    }, [])


    const updateVisualRange = useCallback((e: RangeSelectorTypes.ValueChangedEvent) => {
        if (!showButton) setVisualRange(e.value);
        
    }, [visualRange, showButton]);

    const toggleCreateTemplateOpen = (isSelected: boolean | null, companyDefault: boolean | null): void => {

        if (companyDefault) {
            const newTemplate: GraphTemplate = deepCopyAshridgeDefault(graphTemplate)
            newTemplate.channelsAvail = 31
            setGraphTemplate(newTemplate)
            setCreateTemplateOpen(!createTemplateOpen)
        } else {
            if ((sessionStorage.getItem('userLevel') === 'useradmin' || sessionStorage.getItem('userLevel') === 'identityadmin') && loggers.length === 1 && !isSelected) {
 
                setCreateTemplateOpen(false)
                setSelectLoggerToTemplate(true)
            }
            else if (loggers.length > 1) {
                if (!isSelected) {

                    setSelectLoggerToTemplate(!selectLoggerToTemplate)
                } else {
 
                    const templateToLoad = unitsAndValues.filter(item => item.serial === (loggersToTemplate))
                    setGraphTemplate(templateToLoad[0])
                    setCreateTemplateOpen(!createTemplateOpen)
                }
            } else {

                const templateToLoad = unitsAndValues.filter(item => item.serial === (loggersToTemplate))

                setGraphTemplate(templateToLoad[0])
                setCreateTemplateOpen(!createTemplateOpen)
            }
        }
    }

    const handleLoggerTemplateChange = (value: number): void => {
        setLoggersToTemplate(value)
    }

    const toggleCreateCompanyDefault = ():void => {
        setCreateCompanyDefault(!createCompanyDefault)
        toggleCreateTemplateOpen(false, true)
    }

    const closeGraphTemplateWindow = ():void => {
        setCreateTemplateOpen(false)
        setCreateCompanyDefault(false)
        setSelectLoggerToTemplate(false)
        setSelectedTab(0)
        setTimeout(reloadData(location.state.groupId, location.state.loggers, location.state.site, null), 500)
    }

    const handlePostGraphTemplate = (): void => {
        const info = graphTemplate
        const settings = JSON.stringify(info.graphSettings)
        let requestOptions = {}
        if (createCompanyDefault) {
            const confirm = window.confirm('Are you sure you want to overwrite the company default?')
            if (!confirm) return
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    serial: null,
                    siteId: null,
                    fkCompanyId: parseInt(sessionStorage.getItem('companyId')),
                    graphSettings: settings,
                    userId: null
                })
            };

        } else {
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    serial: parseInt(info.serial),
                    siteId: parseInt(info.siteId),
                    fkCompanyId: parseInt(sessionStorage.getItem('companyId')),
                    graphSettings: settings,
                    userId: parseInt(sessionStorage.getItem('userId'))
                })
            };
        }
        CallPostAPI(CreateUrl('/api/aquaguard/graphtemplate'), requestOptions )
            .then(data => {
                createSuccessNotification()
                closeGraphTemplateWindow()
                setTimeout(reloadData(location.state.groupId, location.state.loggers, location.state.site, null), 500)
                
            })
            .catch(err => {
                console.log(err)
                createErrorNotification()
            })
    }

    
    //NOTIFICATIONS
    const createSuccessNotification = (): void => {
        NotificationManager.success('Saved Template', 'Success')

    };

    const createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes');
    };

    const handleGraphSettingsChange = (name: string, value: any, channel: string): void => {
        const graphTemplateTemp = { ...graphTemplate };  
            if (isNaN(value) && typeof (value) !== 'string') {
                value = 0
            }
            graphTemplateTemp.graphSettings[`${channel}${name}`] = value;
        
        setGraphTemplate(graphTemplateTemp);
    };

    const colors = {
        red: ["#B22222", "#8B0000", "#DC143C", "#D2691E", "#E34234", "#CD5C5C", "#A52A2A", "#FA8072", "#FF4500", "#C71585"],
        yellow: ["#B8860B", "#DAA520", "#FFD700", "#FFC125", "#F4C430", "#CD9B1D", "#FFDEAD", "#EEDC82", "#FFCC00", "#FADA5E"],
        brown: ["#8B4513", "#A0522D", "#6B4423", "#8B5A2B", "#995C32", "#704214", "#5C4033", "#D2B48C", "#A67B5B", "#C19A6B"],
        green: ["#006400", "#32CD32", "#228B22", "#00FF00", "#3CB371", "#ADFF2F", "#7FFF00", "#6B8E23", "#008000", "#9ACD32"],
        blue: ["#0000CD", "#87CEEB", "#1E90FF", "#4169E1", "#4682B4", "#00BFFF", "#6495ED", "#5F9EA0", "#007FFF", "#6A5ACD"],
        orange: ["#FF6F00", "#FFCC66", "#FFA500", "#FF8500", "#FF9F1C", "#FFB84D", "#E67E22", "#FF4500", "#F28500", "#D2691E"],
        purple: ["#800080", "#9370DB", "#8A2BE2", "#9400D3", "#DDA0DD", "#9932CC", "#BA55D3", "#DA70D6", "#6A0DAD", "#A020F0"],
        teal: ["#008080", "#20B2AA", "#40E0D0", "#5F9EA0", "#66CDAA", "#2E8B57", "#48D1CC", "#008B8B", "#00CED1", "#468189"],
        pink: ["#FF69B4", "#FF1493", "#DB7093", "#FFC0CB", "#FF91A4", "#D87093", "#FFB6C1", "#FF85A2", "#FF6EC7", "#E75480"]
    };



    const renderAlarmSeries = (items, settings: GraphTemplate) => {
        let counter = 0
        let type = ""
        let unit = ""
        const alarmSeries = []
        items.forEach(item => {
           // if (settings.isAshridgeDefault) {
                switch (item.channel) {
                    case "A":
                        type = "Flow"
                        unit = unitsAndValues[0].graphSettings.flowAUnit
                        break
                    case "B":
                        type = "Flow"
                        unit = unitsAndValues[0].graphSettings.flowBUnit
                        break
                    case "C":
                        type = window.location.hostname.includes('zonelog.net') ? "Pressure" : "Pressure / Analog"
                        unit = unitsAndValues[0].graphSettings.pressureCUnit
                        break
                    case "D":
                        type = window.location.hostname.includes('zonelog.net') ? "Pressure" : "Pressure / Analog"
                        unit = unitsAndValues[0].graphSettings.pressureDUnit
                        break
                    case "E":
                        type = window.location.hostname.includes('zonelog.net') ? "Pressure" : "Pressure / Analog"
                        unit = unitsAndValues[0].graphSettings.pressureEUnit
                        break
            }


           // }
            if (item.operator === '>' && item.visible) {
                alarmSeries.push(<Strip startValue={item.maxValue} color={`#ff0000${item.transparency}`} key={`${item.color}${item.maxValue}`}>
                    <Label text={`${item.message} - Alarm (${type} > ${item.maxValue} ${unit})`} font={{ size: item.labelSize, color: "#000000" }} ></Label>
                </Strip>)
                alarmSeries.push(<Strip startValue={item.minValue} endValue={item.maxValue} color={`#ffbf0030`} key={`${item.color}${item.maxValue}`}>
                </Strip>)
            }
            if (item.operator === '<' && item.visible) {
                alarmSeries.push(<Strip endValue={item.maxValue} startValue={item.minValue} color={`#ffbf00${item.transparency}`} key={`${item.color}${item.maxValue}`}>
                </Strip>)
                alarmSeries.push(<Strip endValue={item.minValue} color={`#ff0000${item.transparency}`} key={`${item.color}${item.maxValue}`}>
                    <Label horizontalAlignment={'right'} text={`${item.message} - Alarm (${type} < ${item.minValue} ${unit})`} font={{ size: item.labelSize, color: "#000000" }} ></Label>
                </Strip>)
            }
        counter++
        })

        return alarmSeries
    }


    const renderValueAxis = (logger) => {
        let counter = 0;
        const valueAxisArray = [];
        if (!combineY) {
            
            unitsAndValues.map(item => {
                const hasAlarms = item.alarms.length > 0
                const i = item.graphSettings
                // flow A
                let flowAMinValueLogger = 0;
                let flowAMaxValueLogger = 0; // Default value now dynamic
                let flowAColor = '';
                let flowAVisibleLogger = false;
                let flowAAxisPosition = '';
                let flowALabelSize = 0;
                let flowAUnit = '';


                ///Total A
                let flowATotalMinValueLogger = 0;
                let flowATotalMaxValueLogger = 0; // Default value now dynamic
                let flowATotalColor = '';
                let flowATotalVisibleLogger = false;
                let flowATotalAxisPosition = '';
                let flowATotalLabelSize = 0;
                let flowATotalUnit = '';
                let flowATotal24Hrs = false


                ///Consumption A
                let flowAConsumptionMinValueLogger = 0;
                let flowAConsumptionMaxValueLogger = 0; // Default value now dynamic
                let flowAConsumptionColor = '';
                let flowAConsumptionVisibleLogger = false;
                let flowAConsumptionAxisPosition = '';
                let flowAConsumptionLabelSize = 0;
                let flowAConsumptionUnit = '';
                let flowAConsumption24Hrs = false

                //flow B
                let flowBMinValueLogger = 0;
                let flowBMaxValueLogger = 0; // Default value now dynamic
                let flowBColor = '';
                let flowBVisibleLogger = false;
                let flowBAxisPosition = '';
                let flowBLabelSize = 0;
                let flowBUnit = '';

                ///Consumption B
                let flowBConsumptionMinValueLogger = 0;
                let flowBConsumptionMaxValueLogger = 0; // Default value now dynamic
                let flowBConsumptionColor = '';
                let flowBConsumptionVisibleLogger = false;
                let flowBConsumptionAxisPosition = '';
                let flowBConsumptionLabelSize = 0;
                let flowBConsumptionUnit = '';
                let flowBConsumption24Hrs = false

                ///Total B
                let flowBTotalMinValueLogger = 0;
                let flowBTotalMaxValueLogger = 0; // Default value now dynamic
                let flowBTotalColor = '';
                let flowBTotalVisibleLogger = false;
                let flowBTotalAxisPosition = '';
                let flowBTotalLabelSize = 0;
                let flowBTotalUnit = '';
                let flowBTotal24Hrs = false

                //pressure C
                let pressureCMinValueLogger = 0;
                let pressureCMaxValueLogger = 0; // Default value now dynamic
                let pressureCColor = '';
                let pressureCVisibleLogger = false;
                let pressureCAxisPosition = '';
                let pressureCLabelSize = 0;
                let pressureCUnit = '';
                let pressureCChannelName = ""

                //pressure D
                let pressureDMinValueLogger = 0;
                let pressureDMaxValueLogger = 0; // Default value now dynamic
                let pressureDColor = '';
                let pressureDVisibleLogger = false;
                let pressureDAxisPosition = '';
                let pressureDLabelSize = 0;
                let pressureDUnit = '';
                let pressureDChannelName = ""

                //pressure E
                let pressureEMinValueLogger = 0;
                let pressureEMaxValueLogger = 0; // Default value now dynamic
                let pressureEColor = '';
                let pressureEVisibleLogger = false;
                let pressureEAxisPosition = '';
                let pressureELabelSize = 0;
                let pressureEUnit = '';
                let pressureEChannelName = ""
                // Match logger with item.serial and get graph settings
                if (item.serial === logger.Serial) {

                    //flow A
                    flowAMinValueLogger = i.flowAMinValue;
                    flowAMaxValueLogger = i.flowAMaxValue;
                    flowAVisibleLogger = i.flowAVisible;
                    flowAAxisPosition = i.flowAAxisPosition;
                    flowALabelSize = i.flowALabelSize;
                    flowAUnit = i.flowAUnit;

                    //Total A
                    flowATotalMinValueLogger = i.flowATotalMinValue;
                    flowATotalMaxValueLogger = i.flowATotalMaxValue;
                    flowATotalVisibleLogger = i.flowATotalVisible;
                    flowATotalAxisPosition = i.flowATotalAxisPosition;
                    flowATotalLabelSize = i.flowATotalLabelSize;
                    flowATotalUnit = i.flowATotalUnit;
                    flowATotal24Hrs = i.flowATotalPerDay

                    //Consumption A
                    flowAConsumptionMinValueLogger = i.flowAConsumptionMinValue;
                    flowAConsumptionMaxValueLogger = i.flowAConsumptionMaxValue;
                    flowAConsumptionVisibleLogger = i.flowAConsumptionVisible;
                    flowAConsumptionAxisPosition = i.flowAConsumptionAxisPosition;
                    flowAConsumptionLabelSize = i.flowAConsumptionLabelSize;
                    flowAConsumptionUnit = i.flowAConsumptionUnit;
                    flowAConsumption24Hrs = i.flowAConsumptionPerDay

                    //flow B
                    flowBMinValueLogger = i.flowBMinValue;
                    flowBMaxValueLogger = i.flowBMaxValue;
                    flowBVisibleLogger = i.flowBVisible;
                    flowBAxisPosition = i.flowBAxisPosition;
                    flowBLabelSize = i.flowBLabelSize;
                    flowBUnit = i.flowBUnit;

                    //Total B
                    flowBTotalMinValueLogger = i.flowBTotalMinValue;
                    flowBTotalMaxValueLogger = i.flowBTotalMaxValue;
                    flowBTotalVisibleLogger = i.flowBTotalVisible;
                    flowBTotalAxisPosition = i.flowBTotalAxisPosition;
                    flowBTotalLabelSize = i.flowBTotalLabelSize;
                    flowBTotalUnit = i.flowBTotalUnit;
                    flowBTotal24Hrs = i.flowBTotalPerDay

                    //Consumption B
                    flowBConsumptionMinValueLogger = i.flowBConsumptionMinValue;
                    flowBConsumptionMaxValueLogger = i.flowBConsumptionMaxValue;
                    flowBConsumptionVisibleLogger = i.flowBConsumptionVisible;
                    flowBConsumptionAxisPosition = i.flowBConsumptionAxisPosition;
                    flowBConsumptionLabelSize = i.flowBConsumptionLabelSize;
                    flowBConsumptionUnit = i.flowBConsumptionUnit;
                    flowBConsumption24Hrs = i.flowBConsumptionPerDay

                    // pressure C
                    pressureCMinValueLogger = i.pressureCMinValue
                    pressureCMaxValueLogger = i.pressureCMaxValue
                    pressureCVisibleLogger = i.pressureCVisible
                    pressureCAxisPosition = i.pressureCAxisPosition
                    pressureCLabelSize = i.pressureCLabelSize
                    pressureCUnit = i.pressureCUnit

                    if (pressureUnits.map(unit => unit.value).includes(pressureCUnit)) {
                        pressureCChannelName = 'Pressure C'
                    } else {
                        pressureCChannelName = 'Analog C'
                    }

                    // pressure D
                    pressureDMinValueLogger = i.pressureDMinValue
                    pressureDMaxValueLogger = i.pressureDMaxValue
                    pressureDVisibleLogger = i.pressureDVisible
                    pressureDAxisPosition = i.pressureDAxisPosition
                    pressureDLabelSize = i.pressureDLabelSize
                    pressureDUnit = i.pressureDUnit


                    if (pressureUnits.map(unit => unit.value).includes(pressureDUnit)) {
                        pressureDChannelName = 'Pressure D'
                    } else {
                        pressureDChannelName = 'Analog D'
                    }

                    // pressure E
                    pressureEMinValueLogger = i.pressureEMinValue
                    pressureEMaxValueLogger = i.pressureEMaxValue
                    pressureEVisibleLogger = i.pressureEVisible
                    pressureEAxisPosition = i.pressureEAxisPosition
                    pressureELabelSize = i.pressureELabelSize
                    pressureEUnit = i.pressureEUnit

                    if (pressureUnits.map(unit => unit.value).includes(pressureEUnit)) {
                        pressureEChannelName = 'Pressure E'
                    } else {
                        pressureEChannelName = 'Analog E'
                    }
                }

                // Handle default and non-default values for color
                if (!item.isAshridgeDefault) {
                    if (i.flowADefaultColor) {
                        flowAColor = colors.red[counter];
                    } else {
                        flowAColor = i.flowAColor;
                    }
                    if (i.flowATotalDefaultColor) {
                        flowATotalColor = colors.yellow[counter];
                    } else {
                        flowATotalColor = i.flowATotalColor;
                    }
                    if (i.flowAConsumptionDefaultColor) {
                        flowAConsumptionColor = colors.teal[counter];
                    } else {
                        flowAConsumptionColor = i.flowAConsumptionColor;
                    }
                    if (i.flowBDefaultColor) {
                        flowBColor = colors.green[counter];
                    } else {
                        flowBColor = i.flowBColor;
                    }
                    if (i.flowBTotalDefaultColor) {
                        flowBTotalColor = colors.brown[counter];
                    } else {
                        flowBTotalColor = i.flowBTotalColor;
                    }
                    if (i.flowBConsumptionDefaultColor) {
                        flowBConsumptionColor = colors.pink[counter];
                    } else {
                        flowBConsumptionColor = i.flowBConsumptionColor;
                    }
                    if (i.pressureCDefaultColor) {
                        pressureCColor = colors.blue[counter]
                    } else {
                        pressureCColor = i.pressureCColor
                    }
                    if (i.pressureDDefaultColor) {
                        pressureDColor = colors.orange[counter]
                    } else {
                        pressureDColor = i.pressureDColor
                    }
                    if (i.pressureEDefaultColor) {
                        pressureEColor = colors.purple[counter]
                    } else {
                        pressureEColor = i.pressureEColor
                    }
                    counter === 9 ? counter = 0 : counter++;
                } else {
                    flowAColor = colors.red[counter];
                    flowATotalColor = colors.yellow[counter];
                    flowAConsumptionColor = colors.teal[counter];
                    flowBColor = colors.green[counter];
                    flowBTotalColor = colors.brown[counter];
                    flowBConsumptionColor = colors.pink[counter];
                    pressureCColor = colors.blue[counter]
                    pressureDColor = colors.orange[counter]
                    pressureEColor = colors.purple[counter]
                    counter === 9 ? counter = 0 : counter++;
                }

                // Only render the ValueAxis if it's visible
                if (flowAVisibleLogger && item.channelsAvail & 0x01) {
                    valueAxisArray.push(
                        <ValueAxis
                            width={2}
                            name={`A ${logger.Serial}`}
                            visualRange={{ startValue: flowAMinValueLogger, endValue: flowAMaxValueLogger }}
                            allowDecimals={true}
                            title={{ text: flowAMinValueLogger === Number.NEGATIVE_INFINITY && flowAMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelARef} - NO DATA (Flow A)` : `${logger.Serial} ${logger.ChannelARef} in ${flowAUnit} (Flow A)`, font: { color: flowAColor, size: flowALabelSize } }}
                            position={flowAAxisPosition}
                            color={flowAColor}
                            key={`A-${logger.Serial}`} // Unique key for each axis
                        >
                            <Label font={{ color: flowAColor }} />
                            {hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'A'), item)}
                        </ValueAxis>
                    );
                }
                if (flowATotalVisibleLogger && item.channelsAvail & 0x01) {
                    valueAxisArray.push(
                        <ValueAxis
                            width={2}
                            name={`AT-Total ${logger.Serial}`}
                            visualRange={{ startValue: flowATotalMinValueLogger, endValue: flowATotalMaxValueLogger }}
                            allowDecimals={true}
                            title={{ text: flowATotalMinValueLogger === Number.NEGATIVE_INFINITY && flowATotalMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelARef} Total - NO DATA (Totaliser Pulse A)` : `${logger.Serial} ${logger.ChannelARef} in ${flowATotalUnit} ${flowATotal24Hrs ? '-24hr Total' : ''} (Totaliser Pulse A)`, font: { color: flowATotalColor, size: flowATotalLabelSize } }}
                            position={flowATotalAxisPosition}
                            color={flowATotalColor}
                            key={`AT-Total ${logger.Serial}`} // Unique key for each axis
                        >
                            <Label font={{ color: flowATotalColor }} />
                        </ValueAxis>
                    );
                }
                if (flowAConsumptionVisibleLogger && item.channelsAvail & 0x01) {
                    valueAxisArray.push(
                        <ValueAxis
                            width={2}
                            name={`AC-Consumption ${logger.Serial}`}
                            visualRange={{ startValue: flowAConsumptionMinValueLogger, endValue: flowAConsumptionMaxValueLogger }}
                            allowDecimals={true}
                            title={{ text: flowAConsumptionMinValueLogger === Number.NEGATIVE_INFINITY && flowAConsumptionMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelARef} Consumption - NO DATA (Consumption Pulse A)` : `${logger.Serial} ${logger.ChannelARef} in ${flowAConsumptionUnit} ${flowAConsumption24Hrs ? '-24hr Consumption' : ''} (Consumption Pulse A)`, font: { color: flowAConsumptionColor, size: flowAConsumptionLabelSize } }}
                            position={flowAConsumptionAxisPosition}
                            color={flowAConsumptionColor}
                            key={`AC-Consumption ${logger.Serial}`} // Unique key for each axis
                        >
                            <Label font={{ color: flowAConsumptionColor }} />
                        </ValueAxis>
                    );
                }
                if (flowBVisibleLogger && item.channelsAvail & 0x02) {
                    valueAxisArray.push(
                        <ValueAxis
                            width={2}
                            name={`B ${logger.Serial}`}
                            visualRange={{ startValue: flowBMinValueLogger, endValue: flowBMaxValueLogger }}
                            allowDecimals={true}
                            title={{ text: flowBMinValueLogger === Number.NEGATIVE_INFINITY && flowBMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelBRef} - NO DATA (Flow B)` : `${logger.Serial} ${logger.ChannelBRef} in ${flowBUnit} (Flow B)`, font: { color: flowBColor, size: flowBLabelSize } }}
                            position={flowBAxisPosition}
                            color={flowBColor}
                            key={`B-${logger.Serial}`} // Unique key for each axis
                        >
                            <Label font={{ color: flowBColor }} />
                            {hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'B'), item)}
                        </ValueAxis>
                    );
                }
                if (flowBTotalVisibleLogger && item.channelsAvail & 0x02) {
                    valueAxisArray.push(
                        <ValueAxis
                            width={2}
                            name={`BT-Total ${logger.Serial}`}
                            visualRange={{ startValue: flowBTotalMinValueLogger, endValue: flowBTotalMaxValueLogger }}
                            allowDecimals={true}
                            title={{ text: flowBTotalMinValueLogger === Number.NEGATIVE_INFINITY && flowBTotalMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelBRef} Total ${flowBTotalUnit} - NO DATA (Totaliser Pulse B)` : `${logger.Serial} ${logger.ChannelBRef} in ${flowBTotalUnit} ${flowBTotal24Hrs ? '-24hr Total (Totaliser Pulse B)' : ''}`, font: { color: flowBTotalColor, size: flowBTotalLabelSize } }}
                            position={flowBTotalAxisPosition}
                            color={flowBTotalColor}
                            key={`BT-Total ${logger.Serial}`} // Unique key for each axis
                        >
                            <Label font={{ color: flowBTotalColor }} />
                        </ValueAxis>
                    );
                }
                if (flowBConsumptionVisibleLogger && item.channelsAvail & 0x02) {
                    valueAxisArray.push(
                        <ValueAxis
                            width={2}
                            name={`BC-Consumption ${logger.Serial}`}
                            visualRange={{ startValue: flowBConsumptionMinValueLogger, endValue: flowBConsumptionMaxValueLogger }}
                            allowDecimals={true}
                            title={{ text: flowBConsumptionMinValueLogger === Number.NEGATIVE_INFINITY && flowBConsumptionMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelBRef} Consumption - NO DATA (Consumption Pulse B)` : `${logger.Serial} ${logger.ChannelBRef} in ${flowBConsumptionUnit} ${flowBConsumption24Hrs ? '-24hr Consumption' : ''} (Consumption Pulse B)`, font: { color: flowBConsumptionColor, size: flowBConsumptionLabelSize } }}
                            position={flowBConsumptionAxisPosition}
                            color={flowBConsumptionColor}
                            key={`BC-Consumption ${logger.Serial}`} // Unique key for each axis
                        >
                            <Label font={{ color: flowBConsumptionColor }} />
                        </ValueAxis>
                    );
                }
                if (pressureCVisibleLogger && item.channelsAvail & 0x04) {

                    valueAxisArray.push(
                        <ValueAxis
                            width={2}
                            name={`C ${logger.Serial}`}
                            visualRange={{ startValue: pressureCMinValueLogger, endValue: pressureCMaxValueLogger }}
                            allowDecimals={true}
                            title={{
                                text: pressureCMinValueLogger === Number.NEGATIVE_INFINITY && pressureCMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelCRef} - NO DATA (${pressureCChannelName})` : `${logger.Serial} ${logger.ChannelCRef} in ${pressureCUnit} (${pressureCChannelName})`, font: { color: pressureCColor, size: pressureCLabelSize }
                            }}
                            position={pressureCAxisPosition}
                            color={pressureCColor}
                            key={`C-${logger.Serial}`} // Unique key for each axis
                        >
                            <Label font={{ color: pressureCColor }} />
                            {hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'C'), item)}
                        </ValueAxis>
                    );
                }
                if (pressureDVisibleLogger && item.channelsAvail & 0x08) {
                    valueAxisArray.push(
                        <ValueAxis
                            width={2}
                            name={`D ${logger.Serial}`}
                            visualRange={{ startValue: pressureDMinValueLogger, endValue: pressureDMaxValueLogger }}
                            allowDecimals={true}
                            title={{
                                text: pressureDMinValueLogger === Number.NEGATIVE_INFINITY && pressureDMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelDRef} - NO DATA (${pressureDChannelName})` : `${logger.Serial} ${logger.ChannelDRef} in ${pressureDUnit} (${pressureDChannelName})`, font: { color: pressureDColor, size: pressureDLabelSize }
                            }}
                            position={pressureDAxisPosition}
                            color={pressureDColor}
                            key={`D-${logger.Serial}`} // Unique key for each axis
                        >
                            <Label font={{ color: pressureDColor }} />
                            {hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'D'), item)}
                        </ValueAxis>
                    );
                }
                if (pressureEVisibleLogger && item.channelsAvail & 0x10) {
                    valueAxisArray.push(
                        <ValueAxis
                            width={2}
                            name={`E ${logger.Serial}`}
                            visualRange={{ startValue: pressureEMinValueLogger, endValue: pressureEMaxValueLogger }}
                            allowDecimals={true}
                            title={{
                                text: pressureEMinValueLogger === Number.NEGATIVE_INFINITY && pressureEMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelERef} - NO DATA (${pressureEChannelName})` : `${logger.Serial} ${logger.ChannelERef} in ${pressureEUnit} (${pressureEChannelName})`, font: { color: pressureEColor, size: pressureELabelSize }
                            }}
                            position={pressureEAxisPosition}
                            color={pressureEColor}
                            key={`E-${logger.Serial}`} // Unique key for each axis
                        >
                            <Label font={{ color: pressureEColor }} />
                            {hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'E'), item)}
                        </ValueAxis>
                    );
                }
            });
        } else {

            //if combine Y is true and want Y axis combined
            let flowAMinValue = Number.MAX_VALUE
            let flowAMaxValue = Number.NEGATIVE_INFINITY
            let flowBMinValue = Number.MAX_VALUE
            let flowBMaxValue = Number.NEGATIVE_INFINITY
            let pressureCMinValue = Number.MAX_VALUE
            let pressureCMaxValue = Number.NEGATIVE_INFINITY
            let pressureDMinValue = Number.MAX_VALUE
            let pressureDMaxValue = Number.NEGATIVE_INFINITY
            let pressureEMinValue = Number.MAX_VALUE
            let pressureEMaxValue = Number.NEGATIVE_INFINITY
            let flowATotalMinValue = Number.MAX_VALUE
            let flowATotalMaxValue = Number.NEGATIVE_INFINITY
            let flowBTotalMinValue = Number.MAX_VALUE
            let flowBTotalMaxValue = Number.NEGATIVE_INFINITY
            let flowAConsumptionMinValue = Number.MAX_VALUE
            let flowAConsumptionMaxValue = Number.NEGATIVE_INFINITY
            let flowBConsumptionMinValue = Number.MAX_VALUE
            let flowBConsumptionMaxValue = Number.NEGATIVE_INFINITY
            let hasChannelA = false
            let hasChannelB = false
            let hasChannelC = false
            let hasChannelD = false
            let hasChannelE = false
            const flowAAxisPosition = 'left'
            const flowATotalAxisPosition = 'right'
            const flowAConsumptionAxisPosition = 'right'
            const flowBAxisPosition = 'left'
            const flowBTotalAxisPosition = 'right'
            const flowBConsumptionAxisPosition = 'right'
            const pressureCAxisPosition = 'left'
            const pressureDAxisPosition = 'right'
            const pressureEAxisPosition = 'left'
            const nonIntegratableUnits = {
                C: false,
                D: false,
                E: false
            }
            let unitsC = []
            let unitsD = []
            let unitsE = []
            unitsAndValues.forEach(u => {
                const sett = u.graphSettings
                unitsC.push(sett.pressureCUnit)
                unitsD.push(sett.pressureDUnit)
                unitsE.push(sett.pressureEUnit)
            })


            unitsC = unitsC.map(unit => pressureUnits.map(u => u.value).includes(unit))
            unitsD = unitsD.map(unit => pressureUnits.map(u => u.value).includes(unit))
            unitsE = unitsE.map(unit => pressureUnits.map(u => u.value).includes(unit))
            if (!unitsC.every(un => un === true)) {
                if (!unitsC.every(u => u === unitsC[0])) {
                    //non pressure units and not the same for all loggers
                    nonIntegratableUnits.C = true
                }
            }
            if (!unitsD.every(un => un === true)) {
                if (!unitsD.every(u => u === unitsD[0])) {
                    //non pressure units and not the same for all loggers
                    nonIntegratableUnits.D = true
                }
            }
            if (!unitsE.every(un => un === true)) {
                if (!unitsE.every(u => u === unitsE[0])) {
                    //non pressure units and not the same for all loggers
                   nonIntegratableUnits.E = true
                }
            }  



            unitsAndValues.map(unit => {

                if (unit.channelsAvail & 0x01) hasChannelA = true
                if (unit.channelsAvail & 0x02) hasChannelB = true
                if (unit.channelsAvail & 0x04) hasChannelC = true
                if (unit.channelsAvail & 0x08) hasChannelD = true
                if (unit.channelsAvail & 0x10) hasChannelE = true

                const settings = unit.graphSettings

                //determine min and max for combined
                //A
                if (settings.flowAMinValue < flowAMinValue) {
                    flowAMinValue = settings.flowAMinValue
                }
                if (settings.flowAMaxValue > flowAMaxValue) {
                    flowAMaxValue = settings.flowAMaxValue
                }
                if (settings.flowATotalMinValue < flowATotalMinValue) {
                    flowATotalMinValue = settings.flowATotalMinValue
                }
                if (settings.flowATotalMaxValue > flowATotalMaxValue) {
                    flowATotalMaxValue = settings.flowATotalMaxValue

                }
                if (settings.flowAConsumptionMinValue < flowAConsumptionMinValue) {
                    flowAConsumptionMinValue = settings.flowAConsumptionMinValue
                }
                if (settings.flowAConsumptionMaxValue > flowAConsumptionMaxValue) {
                    flowAConsumptionMaxValue = settings.flowAConsumptionMaxValue

                }
                
                //B
                if (settings.flowBMinValue < flowBMinValue) {
                    flowBMinValue = settings.flowBMinValue
                }
                if (settings.flowBMaxValue > flowBMaxValue) {
                    flowBMaxValue = settings.flowBMaxValue
                }
                if (settings.flowBTotalMinValue < flowBTotalMinValue) {
                    flowBTotalMinValue = settings.flowBTotalMinValue
                }
                if (settings.flowBTotalMaxValue > flowBTotalMaxValue) {
                    flowBTotalMaxValue = settings.flowBTotalMaxValue

                }
                if (settings.flowBConsumptionMinValue < flowBConsumptionMinValue) {
                    flowBConsumptionMinValue = settings.flowBConsumptionMinValue
                }
                if (settings.flowBConsumptionMaxValue > flowBConsumptionMaxValue) {
                    flowBConsumptionMaxValue = settings.flowBConsumptionMaxValue

                }
                //C
                if (!nonIntegratableUnits.C) {
                    if (settings.pressureCMinValue < pressureCMinValue) {
                        pressureCMinValue = settings.pressureCMinValue
                    }

                    if (settings.pressureCMaxValue > pressureCMaxValue) {
                        pressureCMaxValue = settings.pressureCMaxValue
                    }
                }
                //D
                if (!nonIntegratableUnits.D) {
                    if (settings.pressureDMinValue < pressureDMinValue) {
                        pressureDMinValue = settings.pressureDMinValue
                    }
                    if (settings.pressureDMaxValue > pressureDMaxValue) {
                        pressureDMaxValue = settings.pressureDMaxValue
                    }
                }
                //E
                if (!nonIntegratableUnits.E) {
                    if (settings.pressureEMinValue < pressureEMinValue) {
                        pressureEMinValue = settings.pressureEMinValue
                    }
                    if (settings.pressureEMaxValue > pressureEMaxValue) {
                        pressureEMaxValue = settings.pressureEMaxValue
                    }
                }


            })
            if (hasChannelA && combinedFlowAUnit) {
                if (showFlow) {
                    valueAxisArray.push(<ValueAxis
                        width={2}
                        name={`A`}
                        visualRange={{ startValue: flowAMinValue, endValue: flowAMaxValue }}
                        allowDecimals={true}
                        title={{ text: `${window.location.hostname.includes('zonelog.net') ? 'Flow A' : 'Channel A'} - ${combinedFlowAUnit}`, font: { color: colors.red[0], size: unitsAndValues[0].graphSettings.flowALabelSize } }}
                        position={flowAAxisPosition}
                        color={colors.red[0]}
                        key={`A`} // Unique key for each axis
                    >
                        <Label font={{ color: colors.red[0] }} />
                        {/*{hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'A'), item)}*/}
                    </ValueAxis>)
                }
                if (showTotal) {
                    valueAxisArray.push(<ValueAxis
                        width={2}
                        name={`AT`}
                        visualRange={{ startValue: flowATotalMinValue, endValue: flowATotalMaxValue }}
                        allowDecimals={true}
                        title={{ text: `Totaliser A - ${combinedTotalAUnit}`, font: { color: colors.yellow[0], size: unitsAndValues[0].graphSettings.flowATotalLabelSize } }}
                        position={flowATotalAxisPosition}
                        color={colors.yellow[0]}
                        key={`AT`} // Unique key for each axis
                    >
                        <Label font={{ color: colors.yellow[0] }} />
                        {/*{hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'A'), item)}*/}
                    </ValueAxis>)
                }
                if (showConsumption) {
                    valueAxisArray.push(<ValueAxis
                        width={2}
                        name={`AC`}
                        visualRange={{ startValue: flowAConsumptionMinValue, endValue: flowAConsumptionMaxValue }}
                        allowDecimals={true}
                        title={{ text: `Consumption A - ${combinedConsumptionAUnit}`, font: { color: colors.teal[0], size: unitsAndValues[0].graphSettings.flowAConsumptionLabelSize } }}
                        position={flowAConsumptionAxisPosition}
                        color={colors.teal[0]}
                        key={`AC`} // Unique key for each axis
                    >
                        <Label font={{ color: colors.teal[0] }} />
                        {/*{hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'A'), item)}*/}
                    </ValueAxis>)
                }
            }
            if (hasChannelB && combinedFlowBUnit) {
                if (showFlow) {
                    valueAxisArray.push(<ValueAxis
                        width={2}
                        name={`B`}
                        visualRange={{ startValue: flowBMinValue, endValue: flowBMaxValue }}
                        allowDecimals={true}
                        title={{ text: `${window.location.hostname.includes('zonelog.net') ? 'Flow 2' : 'Channel B'} - ${combinedFlowBUnit}`, font: { color: colors.green[0], size: unitsAndValues[0].graphSettings.flowBLabelSize } }}
                        position={flowBAxisPosition}
                        color={colors.green[0]}
                        key={`B`} // Unique key for each axis
                    >
                        <Label font={{ color: colors.green[0] }} />
                        {/*{hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'A'), item)}*/}
                    </ValueAxis>)
                }
                if (showTotal) {
                    valueAxisArray.push(<ValueAxis
                        width={2}
                        name={`BT`}
                        visualRange={{ startValue: flowBTotalMinValue, endValue: flowBTotalMaxValue }}
                        allowDecimals={true}
                        title={{ text: `${window.location.hostname.includes('zonelog.net') ? 'Totaliser 2' : 'Totaliser B'} - ${combinedTotalBUnit}`, font: { color: colors.brown[0], size: unitsAndValues[0].graphSettings.flowBTotalLabelSize } }}
                        position={flowBTotalAxisPosition}
                        color={colors.brown[0]}
                        key={`BT`} // Unique key for each axis
                    >
                        <Label font={{ color: colors.brown[0] }} />
                        {/*{hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'A'), item)}*/}
                    </ValueAxis>)
                }
                if (showConsumption) {
                    valueAxisArray.push(<ValueAxis
                        width={2}
                        name={`BC`}
                        visualRange={{ startValue: flowBConsumptionMinValue, endValue: flowBConsumptionMaxValue }}
                        allowDecimals={true}
                        title={{ text: `Consumption B - ${combinedConsumptionBUnit}`, font: { color: colors.pink[0], size: unitsAndValues[0].graphSettings.flowBConsumptionLabelSize } }}
                        position={flowBConsumptionAxisPosition}
                        color={colors.pink[0]}
                        key={`BC`} // Unique key for each axis
                    >
                        <Label font={{ color: colors.pink[0] }} />
                        {/*{hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'A'), item)}*/}
                    </ValueAxis>)
                }
            }
            if (showPressure) {
                if (hasChannelC && combinedPressureCUnit && !nonIntegratableUnits.C) {
                    valueAxisArray.push(<ValueAxis
                        width={2}
                        name={`C`}
                        visualRange={{ startValue: pressureCMinValue, endValue: pressureCMaxValue }}
                        allowDecimals={true}
                        title={{ text: `${window.location.hostname.includes('zonelog.net') ? 'Pressure 1' : 'Channel C'} - ${combinedPressureCUnit}`, font: { color: colors.blue[0], size: unitsAndValues[0].graphSettings.pressureCLabelSize } }}
                        position={pressureCAxisPosition}
                        color={colors.blue[0]}
                        key={`C`} // Unique key for each axis
                    >
                        <Label font={{ color: colors.blue[0] }} />
                        {/*{hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'A'), item)}*/}
                    </ValueAxis>)
                }
                if (hasChannelD && combinedPressureDUnit && !nonIntegratableUnits.D) {
                    valueAxisArray.push(<ValueAxis
                        width={2}
                        name={`D`}
                        visualRange={{ startValue: pressureDMinValue, endValue: pressureDMaxValue }}
                        allowDecimals={true}
                        title={{ text: `${window.location.hostname.includes('zonelog.net') ? 'Pressure 2' : 'Channel D'} - ${combinedPressureDUnit}`, font: { color: colors.orange[0], size: unitsAndValues[0].graphSettings.pressureDLabelSize } }}
                        position={pressureDAxisPosition}
                        color={colors.orange[0]}
                        key={`D`} // Unique key for each axis
                    >
                        <Label font={{ color: colors.orange[0] }} />
                        {/*{hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'A'), item)}*/}
                    </ValueAxis>)
                }
                if (hasChannelE && combinedPressureEUnit && !nonIntegratableUnits.E) {

                    valueAxisArray.push(<ValueAxis
                        width={2}
                        name={`E`}
                        visualRange={{ startValue: pressureEMinValue, endValue: pressureEMaxValue }}
                        allowDecimals={true}
                        title={{ text: `Channel E - ${combinedPressureEUnit}`, font: { color: colors.purple[0], size: unitsAndValues[0].graphSettings.pressureELabelSize } }}
                        position={pressureEAxisPosition}
                        color={colors.purple[0]}
                        key={`E`} // Unique key for each axis
                    >
                        <Label font={{ color: colors.purple[0] }} />
                        {/*{hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'A'), item)}*/}
                    </ValueAxis>)
                }
                if (nonIntegratableUnits.C && hasChannelC) {
                    unitsAndValues.map((item, idx) => {
                        const sett = item.graphSettings
                        
                        let pressureCMinValueSeperate = Number.MAX_VALUE
                        let pressureCMaxValueSeperate = Number.NEGATIVE_INFINITY

                        if (sett.pressureCMinValue < pressureCMinValueSeperate) {
                            pressureCMinValueSeperate = sett.pressureCMinValue
                        }
                        if (sett.pressureCMaxValue > pressureCMaxValueSeperate) {
                            pressureCMaxValueSeperate = sett.pressureCMaxValue
                        }
                        valueAxisArray.push(<ValueAxis
                            width={2}
                            name={`C ${logger.Serial}`}
                            visualRange={{ startValue: pressureCMinValueSeperate, endValue: pressureCMaxValueSeperate }}
                            allowDecimals={true}
                            title={{ text: `${window.location.hostname.includes('zonelog.net') ? 'Pressure 1' : 'Channel C'} - ${sett.pressureCUnit}`, font: { color: colors.blue[idx], size: sett.pressureCLabelSize } }}
                            position={sett.pressureCAxisPosition}
                            color={colors.blue[idx]}
                            key={`C ${logger.Serial}`} // Unique key for each axis
                        >
                            <Label font={{ color: colors.blue[idx] }} />
                        </ValueAxis>)
                    })
                }
                if (nonIntegratableUnits.D && hasChannelD) {
                    unitsAndValues.map((item, idx) => {
                        const sett = item.graphSettings

                        let pressureDMinValueSeperate = Number.MAX_VALUE
                        let pressureDMaxValueSeperate = Number.NEGATIVE_INFINITY

                        if (sett.pressureDMinValue < pressureDMinValueSeperate) {
                            pressureDMinValueSeperate = sett.pressureDMinValue
                        }
                        if (sett.pressureDMaxValue > pressureDMaxValueSeperate) {
                            pressureDMaxValueSeperate = sett.pressureDMaxValue
                        }
                        valueAxisArray.push(<ValueAxis
                            width={2}
                            name={`D ${logger.Serial}`}
                            visualRange={{ startValue: pressureDMinValueSeperate, endValue: pressureDMaxValueSeperate }}
                            allowDecimals={true}
                            title={{ text: `${window.location.hostname.includes('zonelog.net') ? 'Pressure 2' : 'Channel D'} - ${sett.pressureDUnit}`, font: { color: colors.orange[idx], size: sett.pressureDLabelSize } }}
                            position={sett.pressureDAxisPosition}
                            color={colors.orange[idx]}
                            key={`D ${logger.Serial}`} // Unique key for each axis
                        >
                            <Label font={{ color: colors.orange[idx] }} />
                        </ValueAxis>)
                    })
                }
                if (nonIntegratableUnits.E && hasChannelE) {
                    unitsAndValues.map((item, idx) => {
                        const sett = item.graphSettings

                        let pressureEMinValueSeperate = Number.MAX_VALUE
                        let pressureEMaxValueSeperate = Number.NEGATIVE_INFINITY

                        if (sett.pressureEMinValue < pressureEMinValueSeperate) {
                            pressureEMinValueSeperate = sett.pressureEMinValue
                        }
                        if (sett.pressureEMaxValue > pressureEMaxValueSeperate) {
                            pressureEMaxValueSeperate = sett.pressureEMaxValue
                        }
                        valueAxisArray.push(<ValueAxis
                            width={2}
                            name={`E ${logger.Serial}`}
                            visualRange={{ startValue: pressureEMinValueSeperate, endValue: pressureEMaxValueSeperate }}
                            allowDecimals={true}
                            title={{ text: `${window.location.hostname.includes('zonelog.net') ? 'Pressure 3' : 'Channel E'} - ${sett.pressureEUnit}`, font: { color: colors.purple[idx], size: sett.pressureELabelSize } }}
                            position={sett.pressureEAxisPosition}
                            color={colors.purple[idx]}
                            key={`E ${logger.Serial}`} // Unique key for each axis
                        >
                            <Label font={{ color: colors.purple[idx] }} />
                        </ValueAxis>)
                    })
                }
            }
        }
        return valueAxisArray;
    };



    const getValidValueField = (valueField, data) => {
        return data[valueField] !== null ? valueField : undefined;
    };
            

    const renderLoggerSeries = (logger: APILoggerDetail): unknown => {
        let counter = 0
        const loggerSeries = []
        unitsAndValues.map(item => {
            const i = item.graphSettings
            //flow A
            let flowAVisibleLogger = false
            let flowAGraphType = ""
            let flowAColor = ''
            let flowALineWidth = 0

            //Total A
            let flowATotalVisibleLogger = false
            let flowATotalGraphType = ""
            let flowATotalColor = ''
            let flowATotalLineWidth = 0


            //Consumption A
            let flowAConsumptionVisibleLogger = false
            let flowAConsumptionGraphType = ""
            let flowAConsumptionColor = ''
            let flowAConsumptionLineWidth = 0

            //flow B
            let flowBVisibleLogger = false
            let flowBGraphType = ""
            let flowBColor = ''
            let flowBLineWidth = 0

            //Total B
            let flowBTotalVisibleLogger = false
            let flowBTotalGraphType = ""
            let flowBTotalColor = ''
            let flowBTotalLineWidth = 0

            //Consumption B
            let flowBConsumptionVisibleLogger = false
            let flowBConsumptionGraphType = ""
            let flowBConsumptionColor = ''
            let flowBConsumptionLineWidth = 0

            //pressure C
            let pressureCVisibleLogger = false
            let pressureCGraphType = ""
            let pressureCColor = ''
            let pressureCLineWidth = 0
            let pressureCValueField = `${logger.Serial}readingC`

            //pressure D
            let pressureDVisibleLogger = false
            let pressureDGraphType = ""
            let pressureDColor = ''
            let pressureDLineWidth = 0
            let pressureDValueField = `${logger.Serial}readingD`

            //pressure E
            let pressureEVisibleLogger = false
            let pressureEGraphType = ""
            let pressureEColor = ''
            let pressureELineWidth = 0
            let pressureEValueField = `${logger.Serial}readingE`

            if (item.serial === logger.Serial) {
                //flow A
                flowAVisibleLogger = i.flowAVisible
                flowAGraphType = i.flowAGraphType
                flowALineWidth = i.flowALineWidth

                ///Total A
                flowATotalVisibleLogger = i.flowATotalVisible
                flowATotalGraphType = i.flowATotalGraphType
                flowATotalLineWidth = i.flowATotalLineWidth

                ///Consumption A

                flowAConsumptionVisibleLogger = i.flowAConsumptionVisible
                flowAConsumptionGraphType = i.flowAConsumptionGraphType
                flowAConsumptionLineWidth = i.flowAConsumptionLineWidth


                //flow B
                flowBVisibleLogger = i.flowBVisible
                flowBGraphType = i.flowBGraphType
                flowBLineWidth = i.flowBLineWidth
                ///Total B
                flowBTotalVisibleLogger = i.flowBTotalVisible
                flowBTotalGraphType = i.flowBTotalGraphType
                flowBTotalLineWidth = i.flowBTotalLineWidth
                ///Consumption A
                flowBConsumptionVisibleLogger = i.flowBConsumptionVisible
                flowBConsumptionGraphType = i.flowBConsumptionGraphType
                flowBConsumptionLineWidth = i.flowBConsumptionLineWidth

                //pressure C
                pressureCVisibleLogger = i.pressureCVisible
                pressureCGraphType = i.pressureCGraphType
                pressureCLineWidth = i.pressureCLineWidth
                //pressure D
                pressureDVisibleLogger = i.pressureDVisible
                pressureDGraphType = i.pressureDGraphType
                pressureDLineWidth = i.pressureDLineWidth
                //pressure E
                pressureEVisibleLogger = i.pressureEVisible
                pressureEGraphType = item.graphSettings.pressureEGraphType
                pressureELineWidth = item.graphSettings.pressureELineWidth
            }
            if (i.pressureCValueAs === 'max') {
                pressureCValueField = `${logger.Serial}readingCmax`
            }
            if (i.pressureCValueAs === 'min') {
                pressureCValueField = `${logger.Serial}readingCmin`
            }
            if (i.pressureDValueAs === 'max') {
                pressureDValueField = `${logger.Serial}readingDmax`
            }
            if (i.pressureDValueAs === 'min') {
                pressureDValueField = `${logger.Serial}readingDmin`
            }
            if (i.pressureEValueAs === 'max') {
                pressureEValueField = `${logger.Serial}readingEmax`
            }
            if (i.pressureEValueAs === 'min') {
                pressureEValueField = `${logger.Serial}readingEmin`
            }

            if (item.isAshridgeDefault || combineY) {
                flowAColor = colors.red[counter]
                flowATotalColor = colors.yellow[counter]
                flowAConsumptionColor = colors.teal[counter]
                flowBTotalColor = colors.brown[counter]
                flowBColor = colors.green[counter]
                flowBConsumptionColor = colors.pink[counter]
                pressureCColor = colors.blue[counter]
                pressureDColor = colors.orange[counter]
                pressureEColor = colors.purple[counter]
                counter === 9 ? counter = 0 : counter++;
            } else  {
                if (i.flowADefaultColor) {
                    flowAColor = colors.red[counter];
                } else {
                    flowAColor = i.flowAColor;
                }
                if (i.flowATotalDefaultColor) {
                    flowATotalColor = colors.yellow[counter];
                } else {
                    flowATotalColor = i.flowATotalColor;
                }
                if (i.flowAConsumptionDefaultColor) {
                    flowAConsumptionColor = colors.teal[counter];
                } else {
                    flowAConsumptionColor = i.flowAConsumptionColor;
                }
                if (i.flowBDefaultColor) {
                    flowBColor = colors.green[counter];
                } else {
                    flowBColor = i.flowBColor;
                }
                if (i.flowBTotalDefaultColor) {
                    flowBTotalColor = colors.brown[counter];
                } else {
                    flowBTotalColor = i.flowBTotalColor;
                }
                if (i.flowBConsumptionDefaultColor) {
                    flowBConsumptionColor = colors.pink[counter];
                } else {
                    flowBConsumptionColor = i.flowBConsumptionColor;
                }
                if (i.pressureCDefaultColor) {
                    pressureCColor = colors.blue[counter]
                } else {
                    pressureCColor = i.pressureCColor
                }
                if (i.pressureDDefaultColor) {
                    pressureDColor = colors.orange[counter]
                } else {
                    pressureDColor = i.pressureDColor
                }
                if (i.pressureEDefaultColor) {
                    pressureEColor = colors.purple[counter]
                } else {
                    pressureEColor = i.pressureEColor
                }
                counter === 9 ? counter = 0 : counter++;
            }
            
        
       
            if ((flowAVisibleLogger && item.channelsAvail & 0x01)) {
            loggerSeries.push(<Series
                color={flowAColor}
                key={`${logger.Serial}-A`}
                type={flowAGraphType}
                axis={combineY ? "A" : `A ${logger.Serial}`}
                valueField={`${logger.Serial}flowRateA`}
                name={`A ${logger.Serial}`}
                point={{ visible: false }} 
                width={flowALineWidth}
                barWidth = { flowALineWidth } />)
            }
            if ((flowATotalVisibleLogger && item.channelsAvail & 0x01)) {
                loggerSeries.push(<Series
                    color={flowATotalColor}
                    key={`${logger.Serial}-A-Total`}
                    type={flowATotalGraphType}
                    axis={combineY ? "AT" : `AT-Total ${logger.Serial}`}
                    valueField={`${logger.Serial}readingA`}
                    name={`AT-Total ${logger.Serial}`}
                    point={{ visible: false }}
                    width={flowATotalLineWidth}
                    barWidth={flowATotalLineWidth} />)
            }

            if ((flowAConsumptionVisibleLogger && item.channelsAvail & 0x01)) {
                loggerSeries.push(<Series
                    color={flowAConsumptionColor}
                    key={`${logger.Serial}-AC-Consumption`}
                    type={flowAConsumptionGraphType}
                    axis={combineY ? "AC" : `AC-Consumption ${logger.Serial}`}
                    valueField={`${logger.Serial}consumptionA`}
                    name={`AC-Consumption ${logger.Serial}`}
                    point={{ visible: false }}
                    width={flowAConsumptionLineWidth}
                    barWidth={flowAConsumptionLineWidth} />)
            }
            if (flowBVisibleLogger && item.channelsAvail & 0x02) {
            loggerSeries.push(<Series
                color={flowBColor}
                key={`${logger.Serial}-B`}
                type={flowBGraphType}
                axis={combineY ? "B" : `B ${logger.Serial}`}
                valueField={`${logger.Serial}flowRateB`}
                name={`B ${logger.Serial}`}
                point={{ visible: false }} 
                width = { flowBLineWidth }
                barWidth = { flowBLineWidth } />)
            }
            if (flowBTotalVisibleLogger && item.channelsAvail & 0x02) {
                loggerSeries.push(<Series
                    color={flowBTotalColor}
                    key={`${logger.Serial}-B-Total`}
                    type={flowBTotalGraphType}
                    axis={combineY ? "BT" : `BT-Total ${logger.Serial}`}
                    valueField={`${logger.Serial}readingB`}
                    name={`BT-Total ${logger.Serial}`}
                    point={{ visible: false }}
                    width={flowBTotalLineWidth}
                    barWidth={flowBTotalLineWidth} />)
            }
            if ((flowBConsumptionVisibleLogger && item.channelsAvail & 0x02)) {
                loggerSeries.push(<Series
                    color={flowBConsumptionColor}
                    key={`${logger.Serial}-BC-Consumption`}
                    type={flowBConsumptionGraphType}
                    axis={combineY ? "BC" : `BC-Consumption ${logger.Serial}`}
                    valueField={`${logger.Serial}consumptionB`}
                    name={`BC-Consumption ${logger.Serial}`}
                    point={{ visible: false }}
                    width={flowBConsumptionLineWidth}
                    barWidth={flowBConsumptionLineWidth} />)
            }
            if (pressureCVisibleLogger && item.channelsAvail & 0x04) {
            loggerSeries.push(<Series
                color={pressureCColor}
                key={`${logger.Serial}-C`}
                type={pressureCGraphType}
                axis={combineY ? "C" : `C ${logger.Serial}`}
                valueField={pressureCValueField}
                name={`C ${logger.Serial}`}
                point={{ visible: false }} 
                width={ pressureCLineWidth }
                barWidth={pressureCLineWidth}
                

            />)
            }
            if (pressureDVisibleLogger && item.channelsAvail & 0x08) {
                loggerSeries.push(<Series
                    color={pressureDColor}
                    key={`${logger.Serial}-D`}
                    type={pressureDGraphType}
                    axis={combineY ? "D" : `D ${logger.Serial}`}
                    valueField={pressureDValueField}
                    name={`D ${logger.Serial}`}
                    point={{ visible: false }}
                    width={pressureDLineWidth}
                    barWidth={pressureDLineWidth} />)
            }
            if (pressureEVisibleLogger && item.channelsAvail & 0x10) {
                loggerSeries.push(<Series
                    color={pressureEColor}
                    key={`${logger.Serial}-E`}
                    type={pressureEGraphType}
                    axis={combineY ? "E" : `E ${logger.Serial}`}
                    valueField={pressureEValueField}
                    name={`E ${logger.Serial}`}
                    point={{ visible: false }} // This hides individual points
                    width={pressureELineWidth}
                    barWidth={pressureELineWidth}
                    
                    
                />)
            }
        })

            return loggerSeries
    }


    const renderRangeFinderSeries = (logger) => {
        const rangeSeries = []
        let counter = 0

        

        unitsAndValues.map(item => {
            const i = item.graphSettings
            //flow A
            let flowAVisibleLogger = false
            let flowAColor = ''
            let flowAGraphType = ''

            //flow A Total
            let flowATotalVisibleLogger = false
            let flowATotalColor = ''
            let flowATotalGraphType = ''

            //flow A Consumption
            let flowAConsumptionVisibleLogger = false
            let flowAConsumptionColor = ''
            let flowAConsumptionGraphType = ''

            //flowB
            let flowBVisibleLogger = false
            let flowBColor = ''
            let flowBGraphType = ''

            //flow B Total
            let flowBTotalVisibleLogger = false
            let flowBTotalColor = ''
            let flowBTotalGraphType = ''

            //flow B Consumption
            let flowBConsumptionVisibleLogger = false
            let flowBConsumptionColor = ''
            let flowBConsumptionGraphType = ''

            //pressure C
            let pressureCVisibleLogger = false
            let pressureCColor = ''
            let pressureCGraphType = ''

            //pressure D
            let pressureDVisibleLogger = false
            let pressureDColor = ''
            let pressureDGraphType = ''

            //pressure E
            let pressureEVisibleLogger = false
            let pressureEColor = ''
            let pressureEGraphType = ''


            if (item.serial === logger.Serial) {
                // Flow A
                flowAVisibleLogger = i.flowAVisible
                flowAGraphType = i.flowAGraphType
                // Flow A Total
                flowATotalVisibleLogger = i.flowATotalVisible
                flowATotalGraphType = i.flowATotalGraphType
                // Flow A Consumption
                flowAConsumptionVisibleLogger = i.flowAConsumptionVisible
                flowAConsumptionGraphType = i.flowAConsumptionGraphType
                //Flow B
                flowBVisibleLogger = i.flowBVisible
                flowBGraphType = i.flowBGraphType
                // Flow B Total
                flowBTotalVisibleLogger = i.flowBTotalVisible
                flowBTotalGraphType = i.flowBTotalGraphType
                // Flow A Consumption
                flowBConsumptionVisibleLogger = i.flowBConsumptionVisible
                flowBConsumptionGraphType = i.flowBConsumptionGraphType
                //pressure C
                pressureCVisibleLogger = i.pressureCVisible
                pressureCGraphType = i.pressureCGraphType
                //pressure D
                pressureDVisibleLogger = i.pressureDVisible
                pressureDGraphType = i.pressureDGraphType

                //pressure E
                pressureEVisibleLogger = i.pressureEVisible
                pressureEGraphType = i.pressureEGraphType
            }
            if (item.isAshridgeDefault) {
                flowAColor = colors.red[counter]
                flowATotalColor = colors.yellow[counter]
                flowAConsumptionColor = colors.teal[counter]
                flowBColor = colors.green[counter]
                flowBTotalColor = colors.brown[counter]
                pressureCColor = colors.blue[counter]
                pressureDColor = colors.orange[counter]
                pressureEColor = colors.purple[counter]
                counter === 5 ? counter = 0 : counter++;
            } else {
                if (i.flowADefaultColor) {
                    flowAColor = colors.red[counter];
                } else {
                    flowAColor = i.flowAColor;
                }
                if (i.flowATotalDefaultColor) {
                    flowATotalColor = colors.yellow[counter];
                } else {
                    flowATotalColor = i.flowATotalColor;
                }
                if (i.flowAConsumptionDefaultColor) {
                    flowAConsumptionColor = colors.teal[counter];
                } else {
                    flowAConsumptionColor = i.flowAConsumptionColor;
                }
                if (i.flowBDefaultColor) {
                    flowBColor = colors.green[counter];
                } else {
                    flowBColor = i.flowBColor;
                }
                if (i.flowBTotalDefaultColor) {
                    flowBTotalColor = colors.brown[counter];
                } else {
                    flowBTotalColor = i.flowBTotalColor;
                }
                if (i.flowBConsumptionDefaultColor) {
                    flowBConsumptionColor = colors.pink[counter];
                } else {
                    flowBConsumptionColor = i.flowBConsumptionColor;
                }
                if (i.pressureCDefaultColor) {
                    pressureCColor = colors.blue[counter]
                } else {
                    pressureCColor = i.pressureCColor
                }
                if (i.pressureDDefaultColor) {
                    pressureDColor = colors.orange[counter]
                } else {
                    pressureDColor = i.pressureDColor
                }
                if (i.pressureEDefaultColor) {
                    pressureEColor = colors.purple[counter]
                } else {
                    pressureEColor = i.pressureEColor
                }
                counter === 9 ? counter = 0 : counter++;
            }
            
        
        
        if (flowAVisibleLogger && item.channelsAvail & 0x01) {
            rangeSeries.push(<Series
                argumentField="date"
                valueField={`${logger.Serial}flowRateA`}
                color={flowAColor}
                key={`${logger.Serial}-A`}
                type={flowAGraphType}
                
            />)
            }
        if (flowATotalVisibleLogger && item.channelsAvail & 0x01) {
            rangeSeries.push(<Series
                argumentField="date"
                valueField={`${logger.Serial}readingA`}
                color={flowATotalColor}
                key={`${logger.Serial}-A-Total`}
                type={flowATotalGraphType}
            />)
            }
        if (flowAConsumptionVisibleLogger && item.channelsAvail & 0x01) {
            rangeSeries.push(<Series
                argumentField="date"
                valueField={`${logger.Serial}consumptionA`}
                color={flowAConsumptionColor}
                key={`${logger.Serial}-A-Consumption`}
                type={flowAConsumptionGraphType}
            />)
        }
        if (flowBVisibleLogger && item.channelsAvail & 0x02) {
            rangeSeries.push(<Series
                argumentField="date"
                valueField={`${logger.Serial}flowRateB`}
                color={flowBColor}
                key={`${logger.Serial}-B`}
                type={flowBGraphType}
            />)
            }
        if (flowBTotalVisibleLogger && item.channelsAvail & 0x02) {
            rangeSeries.push(<Series
                argumentField="date"
                valueField={`${logger.Serial}readingB`}
                color={flowBTotalColor}
                key={`${logger.Serial}-B-Total`}
                type={flowBTotalGraphType}
            />)
            }
        if (flowBConsumptionVisibleLogger && item.channelsAvail & 0x02) {
            rangeSeries.push(<Series
                argumentField="date"
                valueField={`${logger.Serial}consumptionB`}
                color={flowBConsumptionColor}
                key={`${logger.Serial}-B-Consumption`}
                type={flowBConsumptionGraphType}
            />)
        }
        if (pressureCVisibleLogger && item.channelsAvail & 0x04) {
            rangeSeries.push(<Series
                argumentField="date"
                valueField={`${logger.Serial}readingC`}
                color={pressureCColor}
                key={`${logger.Serial}-C`}
                type={pressureCGraphType}
                
            />)
        }
        if (pressureDVisibleLogger && item.channelsAvail & 0x08) {
            rangeSeries.push(<Series
                argumentField="date"
                valueField={`${logger.Serial}readingD`}
                color={pressureDColor}
                key={`${logger.Serial}-D`}
                type={pressureDGraphType}
            />)
        }
        if (pressureEVisibleLogger && item.channelsAvail & 0x10) {
            rangeSeries.push(<Series
                argumentField="date"
                valueField={`${logger.Serial}readingE`}
                color={pressureEColor}
                key={`${logger.Serial}-E`}
                type={pressureEGraphType}
            />)
            }

        })

        return rangeSeries
    }



    

    const renderTooltip = (point: any): React.ReactNode => {
        const value = point.value
        const channel = point.seriesName
        const serial = channel.slice(2)
        let gutermannChan = ""
        switch (channel.slice(0,1)) {
            case 'A':
                gutermannChan = 'Flow A'
                break
            case 'B':
                gutermannChan = 'Flow B'
                break
            case 'C':
                gutermannChan ='Pressure C'
                break
            case 'D':
                gutermannChan = 'Pressure D'
                break
            case 'E':
                gutermannChan = 'Pressure E'
                break
            default :
                gutermannChan = channel.slice(0, 1)
                break
        }
        const siteDetailForLogger = loggerSiteDetails.filter(d => d.serial === parseInt(serial))[0]
        let unit = "";
        if (point.point.series?.axis) {

            const channelLetter = channel.slice(0, 2)
            if (!combineY) {
                const graphSettings = unitsAndValues.filter(item => item.serial === parseInt(point.point.data.serial))[0]?.graphSettings
                if (channelLetter === 'A ') {
                    unit = graphSettings?.flowAUnit
                } else if (channelLetter === 'AT') {
                    unit = graphSettings?.flowATotalUnit
                } else if (channelLetter === 'AC') {
                    unit = graphSettings?.flowAConsumptionUnit
                }

                else if (channelLetter === 'B ') {
                    unit = graphSettings?.flowBUnit
                } else if (channelLetter === 'BT') {
                    unit = graphSettings?.flowBTotalUnit
                } else if (channelLetter === 'BC') {
                    unit = graphSettings?.flowBConsumptionUnit
                }

                else if (channelLetter === 'C ') {
                    unit = graphSettings?.pressureCUnit
                }
                else if (channelLetter === 'D ') {
                    unit = graphSettings?.pressureDUnit
                }
                else if (channelLetter === 'E ') {
                    unit = graphSettings?.pressureEUnit
                }
            } else {
                
                if (channelLetter === 'A ') {
                    unit = combinedFlowAUnit
                } else if (channelLetter === 'AT') {
                    unit = combinedTotalAUnit
                } else if (channelLetter === 'AC') {
                    unit = combinedConsumptionAUnit
                } else if (channelLetter === 'B ') {
                    unit = combinedFlowBUnit
                } else if (channelLetter === 'BT') {
                    unit = combinedTotalBUnit
                } else if (channelLetter === 'BC') {
                    unit = combinedConsumptionBUnit
                } else if (channelLetter === 'C ') {
                    unit = combinedPressureCUnit
                } else if (channelLetter === 'D ') {
                    unit = combinedPressureDUnit
                } else if (channelLetter === 'E ') {
                    unit = combinedPressureEUnit
                }
            }
        }
            return (
            <div>
                <p>{window.location.hostname.includes('zonelog.net') ? `Channel: ${gutermannChan} ${serial}`  : `Channel: ${channel}`}</p>
                {value.toString().length > 5 ? <p> {`Value: ${value.toFixed(3)} ${unit}`}</p> : <p> {`Value: ${value} ${unit}`}</p>}
                {siteDetailForLogger?.siteName && <p>Site Name: {siteDetailForLogger.siteName}</p>}
                {siteDetailForLogger?.siteName && siteDetailForLogger?.siteRef !== 'Unallocated Devices' && <p>Site Ref: {siteDetailForLogger.siteRef}</p>}
                <p>{moment(point.argument).format('Do MMM HH:mm:ss')}</p>
               
            </div>
        )
    }

    


    const resetZoom = useCallback(() => {
        if (chartRef.current && chartRef.current.instance) {
            const chartInstance = chartRef.current.instance();
            chartInstance.option('argumentAxis.visualRange', { startValue: startDate, endValue: endDate });
        }
        setGraphHeight('50vh')
    }, [startDate, endDate])

    
    const handleToggleFlow = (e, type): void => {
        setToggling(true)
        const checked = e.target.checked
        const unitsAndValuesTemp = unitsAndValues

        unitsAndValuesTemp.forEach(logger => {
            const l = logger.graphSettings
            const chan = logger.channelsAvail
            if (type === 'Flow') {
                l.flowAVisible = checked
                if (chan & 0x02) {
                    l.flowBVisible = checked
                }
                setShowFlow(checked)
            } else if (type === 'Pressure') {
                if (chan & 0x04) {
                    l.pressureCVisible = checked
                }
                if (chan & 0x08) {
                    l.pressureDVisible = checked
                }
                if (!window.location.hostname.includes('zonelog.net') && (chan & 0x10)) {
                    l.pressureEVisible = checked
                }
                setShowPressure(checked)
            } else if (type === 'Total') {
                l.flowATotalVisible = checked
                if (chan & 0x02) {
                    l.flowBTotalVisible = checked
                }
                setShowTotal(checked)
            } else if (type === 'Consumption') {
                l.flowAConsumptionVisible = checked
                if (chan & 0x02) {
                    l.flowBConsumptionVisible = checked
                }
                setShowConsumption(checked)
            }
        })

        setUnitsAndValues(unitsAndValuesTemp)
        setToggling(false)
    }


    const customArgument = (date: Date): string => {
        if (moment(date).isSame(moment(date).startOf('day'), 'second')) {
            return moment(date).format('Do HH:mm')
        } else {
            return moment(date).format('HH:mm')
        }
    }

    

    const exportConfirm = (): void => {
        let readingsDataTemp: Array<APIGetLoggerReadingsModel> = []
        toggleExportModal();
        setLoading(true)
        const workbook = new ExcelJS.Workbook();
        readingsDataTemp = [...readingsData];
        let worksheet = null;
        const previousValueAArray = []
        const previousValueBArray = []
        unitsAndValues.forEach(entry => {
            if (entry.channelsAvail & 0x01) {
                previousValueAArray.push({serial: entry.serial, value: null})
            }
            if (entry.channelsAvail & 0x02) {
                previousValueBArray.push({ serial: entry.serial, value: null })
            }
        })
        readingsDataTemp.forEach(item => {
            // Get UTC Offset

            const utcOffset = parseInt(sessionStorage.getItem('utcOffset').slice(1));
            const offsetType = sessionStorage.getItem('utcOffset').slice(0, 1);

            // Adjust dateStamp based on offset
            const originalDate = moment.utc(item.dateStamp);
            let adjustedDate;

            if (offsetType === "-") {
                adjustedDate = originalDate.clone().subtract(utcOffset, 'hours');
            } else if (offsetType === "+") { 
                adjustedDate = originalDate.clone().add(utcOffset, 'hours');
            } else {
                adjustedDate = originalDate;
            }



            // Save both formatted and raw date data
            item.dateStampLocalDay = parseInt(adjustedDate.format('DD'));
            item.dateStampLocalMonth = parseInt(adjustedDate.format('MM'));
            item.dateStampLocalYear = parseInt(adjustedDate.format('YYYY'));
            item.timeStampLocal = adjustedDate.format('HH:mm:ss');
            item.originalDateStamp = originalDate.toDate();
            item.formattedDateStamp = originalDate.format('DD/MM/YY HH:mm:ss')
            item.adjustDateStamp = adjustedDate.format('DD/MM/YY HH:mm:ss')
            item.dateStampDay = parseInt(originalDate.format('DD'));
            item.dateStampMonth = parseInt(originalDate.format('MM'));
            item.dateStampYear = parseInt(originalDate.format('YYYY'));
            item.timeStamp = originalDate.format('HH:mm:ss');


            

            

            const loggerToExport = unitsAndValues.filter(unit => unit.serial === parseInt(item.loggerSerial))[0]

  

            

            //flow
            
            if (item.flowRate !== null) {
                if (item.flowRate > 0) {
                    item.flowRateFWD = item.flowRate
                    item.flowRateREV = 0
                } else if (item.flowRate < 0) {
                    item.flowRateREV = Math.abs(item.flowRate)
                    item.flowRateFWD = 0
                } else {
                    item.flowRateFWD = 0
                    item.flowRateREV = 0
                }
                let previousValueA: number | null = null
                let previousValueB: number | null = null
                
                if (item.channelletter === 'A') {
                    if (moment(item.dateStamp).isSame(moment(item.dateStamp).startOf('day'), 'second') && loggerToExport.graphSettings.flowAConsumptionVisible) {
                        previousValueAArray.forEach(i => {
                            if (i.serial === parseInt(item.loggerSerial)) {
                                if (i.value === null) {
                                    i.value = item.value
                                }
                                previousValueA = i.value
                            }
                        })
                        item.consumptionUnit = loggerToExport.graphSettings.flowAConsumptionUnit
                        const consumptionConversion = totalConverter(item.consumptionUnit, item.units)
                        item.consumption = parseFloat(((item.value - previousValueA) * consumptionConversion).toFixed(3))
                    }
                    item.flowUnit = loggerToExport.graphSettings.flowAUnit
                    item.totalUnit = loggerToExport.graphSettings.flowATotalUnit
                    const flowConversion = flowConverter(item.flowUnit, 'l/s')
                    const totalConversion = totalConverter(item.totalUnit, item.units)
                    item.flowRateFWD = parseFloat((item.flowRateFWD * flowConversion).toFixed(3))

                    item.channelletter = window.location.hostname.includes('zonelog.net') ? 'Flow A' : 'Channel A'

                    item.forwardTotal = parseFloat((item.value * totalConversion).toFixed(3))
                    

                }


                if (item.channelletter === 'B') {

                    if (moment(item.dateStamp).isSame(moment(item.dateStamp).startOf('day'), 'second') && loggerToExport.graphSettings.flowBConsumptionVisible) {

                        previousValueBArray.forEach(i => {
                            if (i.serial === parseInt(item.loggerSerial)) {
                                if (i.value === null) {
                                    i.value = item.value
                                }
                                previousValueB = i.value
                            }
                        })
                        item.consumptionUnit = loggerToExport.graphSettings.flowBConsumptionUnit
                        const consumptionConversion = totalConverter(item.consumptionUnit, item.units)
                        item.consumption = parseFloat(((item.value - previousValueB) * consumptionConversion).toFixed(3))
                    }
                    item.flowUnit = loggerToExport.graphSettings.flowBUnit
                    item.totalUnit = loggerToExport.graphSettings.flowBTotalUnit
                    const flowConversion = flowConverter(item.flowUnit, 'l/s')
                    const totalConversion = totalConverter(item.totalUnit, item.units)

                    item.flowRateFWD = parseFloat((item.flowRateFWD * flowConversion).toFixed(3))

                    item.channelletter = window.location.hostname.includes('zonelog.net') ? 'Flow B' : 'Channel B'
                    
                    
                    item.forwardTotal = parseFloat((item.value * totalConversion).toFixed(3))

                }
                
            }
            if (item.value !== null) {
                
                if (item.channelletter === 'C') {
                    let conversion = 1
                    const settingsUnit = loggerToExport.graphSettings.pressureCUnit
                    if (pressureUnits.map(unit => unit.value).includes(settingsUnit)) {
                        conversion = pressureConverter(settingsUnit, item.units)
                    }
                    item.value *= conversion
                    item.channelletter = window.location.hostname.includes('zonelog.net') ? 'Pressure C' : 'Analog C'
                    item.pressureValue = parseFloat(item.value.toFixed(3))
                    item.pressureUnit = loggerToExport.graphSettings.pressureCUnit

                }
                if (item.channelletter === 'D') {
                    let conversion = 1
                    const settingsUnit = loggerToExport.graphSettings.pressureDUnit
                    if (pressureUnits.map(unit => unit.value).includes(settingsUnit)) {
                        conversion = pressureConverter(settingsUnit, item.units)
                    }
                    item.value *= conversion
                    item.channelletter = window.location.hostname.includes('zonelog.net') ? 'Pressure D' : 'Analog D'
                    item.pressureValue = parseFloat(item.value.toFixed(3))
                    item.pressureUnit = loggerToExport.graphSettings.pressureDUnit
                }
                if (item.channelletter === 'E') {
                    let conversion = 1
                    const settingsUnit = loggerToExport.graphSettings.pressureEUnit
                    if (pressureUnits.map(unit => unit.value).includes(settingsUnit)) {
                        conversion = pressureConverter(settingsUnit, item.units)
                    }
                    item.value *= conversion
                    item.channelletter = window.location.hostname.includes('zonelog.net') ? 'Pressure E' : 'Analog E'
                    item.pressureValue = parseFloat(item.value.toFixed(3))
                    item.pressureUnit = loggerToExport.graphSettings.pressureEUnit
                }
            }
            if (moment(item.dateStamp).isSame(moment(item.dateStamp).startOf('day'), 'second')) {
                if (item.channelletter === 'Flow A' || item.channelletter === 'Channel A') {
                    previousValueAArray.forEach(i => {
                        if (i.serial === parseInt(item.loggerSerial)) {
                            i.value = item.value
                        }
                    })
                }
                if (item.channelletter === 'Flow B' || item.channelletter === 'Channel B') {
                    previousValueBArray.forEach(i => {
                        if (i.serial === parseInt(item.loggerSerial)) {
                            i.value = item.value
                        }
                    })
                }
            }
            //ont the serial
            item.loggerSerial = parseInt(item.loggerSerial)
        });


        readingsDataTemp.forEach(reading => {
            const loggerToExport = unitsAndValues.filter(unit => unit.serial === parseInt(reading.loggerSerial))[0]
            if ((loggerToExport.graphSettings.flowAVisible || loggerToExport.graphSettings.flowATotalVisible || loggerToExport.graphSettings.flowAConsumptionVisible) && (reading.channelletter === 'Flow A' || reading.channelletter === 'Channel A')) {
                if (!loggerToExport.graphSettings.flowAVisible) {
                    reading.flowRateFWD = null
                    reading.flowRateREV = null
                    reading.flowUnit = ""
                }
                if (!loggerToExport.graphSettings.flowATotalVisible) {
                    reading.forwardTotal = null
                    reading.totalUnit = ""
                }
                if (!loggerToExport.graphSettings.flowAConsumptionVisible) {
                    reading.consumption = null
                    reading.consumptionUnit = ""
                }
                return reading
            }
            if ((loggerToExport.graphSettings.flowBVisible || loggerToExport.graphSettings.flowBTotalVisible || loggerToExport.graphSettings.flowBConsumptionVisible) && reading.channelletter === 'Flow B' || reading.channelletter === 'Channel B') {
                if (!loggerToExport.graphSettings.flowBVisible) {
                    reading.flowRateFWD = null
                    reading.flowRateREV = null
                    reading.flowUnit = ""
                }
                if (!loggerToExport.graphSettings.flowBTotalVisible) {
                    reading.forwardTotal = null
                    reading.totalUnit = ""
                }
                if (!loggerToExport.graphSettings.flowBConsumptionVisible) {
                    reading.consumption = null
                    reading.consumptionUnit = ""
                }
                return reading
            }
            if (loggerToExport.graphSettings.pressureCVisible && (reading.channelletter === 'Pressure C' || reading.channelletter === 'Analog C')) {
                return reading
            }
            if (loggerToExport.graphSettings.pressureDVisible && (reading.channelletter === 'Pressure D' || reading.channelletter === 'Analog D')) {
                return reading
            }
            if (loggerToExport.graphSettings.pressureEVisible && (reading.channelletter === 'Pressure E' || reading.channelletter === 'Analog E')) {
                return reading
            }
        })
        const filteredReadingDataTemp = []

        let readingsChannelA = readingsDataTemp.filter(reading => reading.channelletter === 'Flow A' || reading.channelletter === 'Channel A')
        let readingsChannelB = readingsDataTemp.filter(reading => reading.channelletter === 'Flow B' || reading.channelletter === 'Channel B')
        const readingsChannelC = readingsDataTemp.filter(reading => reading.channelletter === 'Pressure C' || reading.channelletter === 'Analog C')
        const readingsChannelD = readingsDataTemp.filter(reading => reading.channelletter === 'Pressure D' || reading.channelletter === 'Analog D')
        const readingsChannelE = readingsDataTemp.filter(reading => reading.channelletter === 'Pressure E' || reading.channelletter === 'Analog E')

        const sett = unitsAndValues.map(u => u.graphSettings)



        // FLOW A FILTERING
        /// all flow A are off?
        if (!sett.every(u => u.flowAVisible)) {
            // check if totals are all off and any A channel Consumption are on
            if (!sett.every(u => u.flowATotalVisible) && sett.some(u => u.flowAConsumptionVisible)) {
                
                readingsChannelA = readingsChannelA.filter(item => moment(item.dateStamp).isSame(moment(item.dateStamp).startOf('day'), 'second'))
            }
            // if some totals are visible check and then only filter for the relevant serials
            if (sett.some(u => u.flowATotalVisible)) {
                // check which ones are 24hrs
                const dailySerials = []
                unitsAndValues.forEach(unit => {
                    if (unit.graphSettings.flowATotalPerDay) {
                        dailySerials.push(unit.serial)
                    }
                })
                /// chaecks channel A and filters out if logger has daily total
                readingsChannelA = readingsChannelA.filter(item => {
                    if (dailySerials.includes(item.loggerSerial) && moment(item.dateStamp).isSame(moment(item.dateStamp).startOf('day'), 'second')) {
                        return item
                    } else if (!dailySerials.includes(item.loggerSerial)) {
                        return item
                    }
                    
                })
            }
        }
        if (!sett.every(u => u.flowBVisible)) {
            // check if totals are all off and any A channel Consumption are on
            if (!sett.every(u => u.flowBTotalVisible) && sett.some(u => u.flowBConsumptionVisible)) {
                
                readingsChannelB = readingsChannelB.filter(item => moment(item.dateStamp).isSame(moment(item.dateStamp).startOf('day'), 'second'))
            }
            // if some totals are visible check and then only filter for the relevant serials
            if (sett.some(u => u.flowBTotalVisible)) {
                // check which ones are 24hrs
                const dailySerials = []
                unitsAndValues.forEach(unit => {
                    if (unit.graphSettings.flowBTotalPerDay) {
                        dailySerials.push(unit.serial)
                    }
                })
                /// chaecks channel A and filters out if logger has daily total
                readingsChannelB = readingsChannelB.filter(item => {
                    if (dailySerials.includes(item.loggerSerial) && moment(item.dateStamp).isSame(moment(item.dateStamp).startOf('day'), 'second')) {
                        return item
                    } else if (!dailySerials.includes(item.loggerSerial)) {
                        return item
                    }

                })
            }
        }
        readingsChannelA.forEach(reading => {
            filteredReadingDataTemp.push(reading)
        })
        readingsChannelB.forEach(reading => {
            filteredReadingDataTemp.push(reading)
        })
        readingsChannelC.forEach(reading => {
            filteredReadingDataTemp.push(reading)
        })
        readingsChannelD.forEach(reading => {
            filteredReadingDataTemp.push(reading)
        })
        readingsChannelE.forEach(reading => {
            filteredReadingDataTemp.push(reading)
        })
        if (filteredReadingDataTemp.length > 0) {

            worksheet = workbook.addWorksheet("All Channels", {
                views: [{ state: 'frozen', xSplit: 0, ySplit: 1 }]
            });

            const highestChannelAvail = Math.max(...unitsAndValues.map(unit => unit.channelsAvail));
            let defaultHeaders = []
            if (splitDate) {
                defaultHeaders = [
                    { header: "Serial", key: "loggerSerial" },
                    { header: "Device Name", key: "site", style: { width: 50 } },
                    { header: "Day Local (UTC Offset)", key: "dateStampLocalDay", style: { width: 60 } },
                    { header: "Month Local (UTC Offset)", key: "dateStampLocalMonth", style: { width: 60 } },
                    { header: "Year Local (UTC Offset)", key: "dateStampLocalYear", style: { width: 60 } },
                    { header: "Time Local (UTC Offset)", key: "timeStampLocal", style: { width: 60 } },
                    { header: "Day (UTC)", key: "dateStampDay", style: { width: 60 } },
                    { header: "Month (UTC)", key: "dateStampMonth", style: { width: 60 } },
                    { header: "Year (UTC)", key: "dateStampYear", style: { width: 60 } },
                    { header: "Time (UTC)", key: "timeStamp", style: { width: 60 } },
                    { header: "Channel", key: "channelletter" },
                    { header: "Channel Name", key: "channelSerial" },

                ]
            } else {
                defaultHeaders = [
                    { header: "Serial", key: "loggerSerial" },
                    { header: "Device Name", key: "site", style: { width: 50 } },
                    { header: "Date (UTC)", key: "formattedDateStamp", style: { width: 80 } },
                    { header: "Date (Local)", key: "adjustDateStamp", style: { width: 80 } },
                    { header: "Channel", key: "channelletter" },
                    { header: "Channel Name", key: "channelSerial" },

                ]
            }
            const headers = []
            headers.push(...defaultHeaders)
            if (unitsAndValues.some(({ graphSettings: { flowAVisible, flowBVisible }}) => flowAVisible || flowBVisible)) {
                headers.push({ header: "Flow Rate Fwd", key: "flowRateFWD" })
                headers.push({ header: "Flow Rate Rev", key: "flowRateREV" })
                headers.push({ header: "Flow Unit", key: "flowUnit"})

                
            }
            if (unitsAndValues.some(({ graphSettings: { flowATotalVisible, flowBTotalVisible } }) => flowATotalVisible || flowBTotalVisible)) {
                headers.push({ header: "Totaliser", key: "forwardTotal" })
                headers.push({ header: "Totaliser Unit", key: "totalUnit" })
            }

            if (unitsAndValues.some(({ graphSettings: { flowAConsumptionVisible, flowBConsumptionVisible } }) => flowAConsumptionVisible || flowBConsumptionVisible)) {
                headers.push({ header: "Consumption 24hrs", key: 'consumption' })
                headers.push({ header: "Consumption Unit", key: "consumptionUnit"})
            }

           
            

            if (highestChannelAvail >= 4 && unitsAndValues.some(({ graphSettings: { pressureCVisible } }) => pressureCVisible)) {
                headers.push({ header: window.location.hostname.includes('zonelog.net') ? "Pressure C" : "Analog C", key: "pressureValue" })
                headers.push({ header: window.location.hostname.includes('zonelog.net') ? "Pressure C Unit" : "Analog C Unit", key: "pressureUnit" })
            }
            if (highestChannelAvail >= 8 && unitsAndValues.some(({ graphSettings: { pressureDVisible } }) => pressureDVisible)) {
                headers.push({ header: window.location.hostname.includes('zonelog.net') ? "Pressure D" : "Analog D", key: "pressureValue" })
                headers.push({ header: window.location.hostname.includes('zonelog.net') ? "Pressure D Unit" : "Analog D Unit", key: "pressureUnit" })
            }
            if (highestChannelAvail >= 16 && !window.location.hostname.includes('zonelog.net') && unitsAndValues.some(({ graphSettings: { pressureEVisible } }) => pressureEVisible)) {
                headers.push({ header: window.location.hostname.includes('zonelog.net') ? "Pressure E" : "Analog E", key: "pressureValue" })
                headers.push({ header: window.location.hostname.includes('zonelog.net') ? "Pressure E Unit" : "Analog E Unit", key: "pressureUnit" })
            }
            
            worksheet.columns = headers;

            filteredReadingDataTemp.sort((a, b) => {
                const dateA = moment(a.originalDateStamp, 'Do MMM YYYY HH:mm:ss')
                const dateB = moment(b.originalDateStamp, 'Do MMM YYYY HH:mm:ss')
                if (dateA.isBefore(dateB)) return -1;
                if (dateA.isAfter(dateB)) return 1;
                return 0; // dates are equal
            });

            // Ensure no overlapping data when adding the table.
            const cleanData = filteredReadingDataTemp.filter(data => data !== undefined && data !== null);


            if (exportAs == "XLSX-T") { 
                worksheet.addTable({
                    name: 'DataTable',
                    ref: 'A2', // Reference below the header row
                    displayName: 'MyTable',
                    columns: worksheet.columns.map(column => ({ name: column.header, filterButton: true })),
                    rows: cleanData.map(data =>
                        worksheet.columns.map(column => {
                            // If column.key doesn't exist in data, fallback to empty string
                            return data[column.key];
                        })
                    ),
                });
                
            } else {
                worksheet.addRows(filteredReadingDataTemp);
            }
        }

        const downloadName = window.location.hostname.includes('zonelog.net') ? 'ZONELOG.NET Export' : 'Aquaguard Export'
        if (exportAs == "CSV") {
            const zip = new JSZip();
            const promises = workbook.worksheets.map(sheet => {
                const options: ExcelJS.CsvWriteOptions = { sheetName: sheet.name, sheetId: sheet.id, dateUTC: true };
                return workbook.csv.writeBuffer(options)
                    .then((buffer: Buffer) => zip.file(sheet.name + ".csv", buffer));
            });

            Promise.all(promises).then(() => {
                if (workbook.worksheets.length > 0) {
                    zip.generateAsync({ type: "blob" }).then(content => {
                        saveAs(content, `${downloadName}-Graph data from ${moment(startDate).format('DDMMMYYYY')} to ${moment(endDate).format('DDMMMYYYY')}.zip`);
                    });
                }
                reloadData(location.state.groupId, location.state.loggers, location.state.site, true)
            });
        } else {

            workbook.xlsx.writeBuffer().then(buffer => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${downloadName}-Graph Data from ${moment(startDate).format('DDMMMYYYY')} to ${moment(endDate).format('DDMMMYYYY')}.xlsx`);
            });
            reloadData(location.state.groupId, location.state.loggers, location.state.site, true)
        }
        
        setLoading(false)
        
    };



    const toggleExportModal = ():void => {
        setExportModal(!exportModal)
    }

    const handleExportTypeChange = (value): void => {

        setExportAs(value)
    }

    const deviceOverviewClick = (serial: number | null, model: string | null): void => {
                setRedirect(true)
                setRedirectPath(!isLCLLogger(model ? model : this.state.tempDevice?.model) ? '/portal/DeviceDetail' : '/portal/LCLDeviceDetail')
                setRedirectProps({
                    serial: serial
                    //mapKey: this.props.mapKey
                })
    }

    const handleHideLoggerChannel = (serial:number, channel: string):void => { 

        const unitUpdate = unitsAndValues.map(unit => {
            if (unit.serial === serial) {
                if (channel === 'A') {
                    unit.graphSettings.flowAVisible = !unit.graphSettings.flowAVisible
                }
                if (channel === 'AT') {
                    unit.graphSettings.flowATotalVisible = !unit.graphSettings.flowATotalVisible
                }
                if (channel === 'AC') {
                    unit.graphSettings.flowAConsumptionVisible = !unit.graphSettings.flowAConsumptionVisible
                }
                if (channel === 'B') {
                    unit.graphSettings.flowBVisible = !unit.graphSettings.flowBVisible
                }
                if (channel === 'BT') {
                    unit.graphSettings.flowBTotalVisible = !unit.graphSettings.flowBTotalVisible
                }
                if (channel === 'BC') {
                    unit.graphSettings.flowBConsumptionVisible = !unit.graphSettings.flowBConsumptionVisible
                }
                if (channel === 'C') {
                    unit.graphSettings.pressureCVisible = !unit.graphSettings.pressureCVisible
                }
                if (channel === 'D') {
                    unit.graphSettings.pressureDVisible = !unit.graphSettings.pressureDVisible
                }
                if (channel === 'E') {
                    unit.graphSettings.pressureEVisible = !unit.graphSettings.pressureEVisible
                }
            }
            return unit
        })
        let flowEnabled = false 
        let TotalEnabled = false 
        let ConsumptionEnabled = false 
        let pressureEnabled = false
        unitUpdate.forEach(u => {
            const sett = u.graphSettings
            if (sett.flowAVisible || sett.flowBVisible) flowEnabled = true
            if (sett.flowATotalVisible || sett.flowBTotalVisible) TotalEnabled = true
            if (sett.flowAConsumptionVisible || sett.flowBConsumptionVisible) ConsumptionEnabled = true
            if (sett.pressureCVisible || sett.pressureDVisible || sett.pressureEVisible) pressureEnabled = true
        })
        setShowFlow(flowEnabled)
        setShowConsumption(ConsumptionEnabled)
        setShowTotal(TotalEnabled)
        setShowPressure(pressureEnabled)
        setUnitsAndValues(unitUpdate)
    }


    const showRelativeChannelDisplay = (): React.ReactNode => {
        let hasChanA = false
        let hasChanB = false
        let hasChanC = false
        let hasChanD = false
        let hasChanE = false

        loggers.map(logger => {
            if (logger.ChannelsAvail & 0x01) {
                hasChanA = true
            }
            if (logger.ChannelsAvail & 0x02) {
                hasChanB = true
            }
            if (logger.ChannelsAvail & 0x04) {
                hasChanC = true
            }
            if (logger.ChannelsAvail & 0x08) {
                hasChanD = true
            }
            if (logger.ChannelsAvail & 0x10) {
                hasChanE = true
            }
        })
        
        return (<>
            {hasChanA && <GraphChannelDisplay
                colors={colors}
                name={window.location.hostname.includes('zonelog.net') ? 'Flow A' : 'Channel A'}
                loggers={loggers}
                color="red"
                currentUnit={combinedFlowAUnit}
                type={'flow'}
                handleHideLoggerChannel={handleHideLoggerChannel}
                unitsAndValues={unitsAndValues}
                handleUnitChange={handleUnitChange}
                handleGraphTypeChange={handleGraphTypeChange}
            />}
            {hasChanA && <GraphChannelDisplay
                colors={colors}
                name={window.location.hostname.includes('zonelog.net') ? 'Totaliser A' : 'Totaliser A'}
                loggers={loggers}
                color="yellow"
                currentUnit={combinedTotalAUnit}
                type={'total'}
                handleHideLoggerChannel={handleHideLoggerChannel}
                unitsAndValues={unitsAndValues}
                handleUnitChange={handleUnitChange}
                handleGraphTypeChange={handleGraphTypeChange}
            />}
            {hasChanA && <GraphChannelDisplay
                colors={colors}
                name={window.location.hostname.includes('zonelog.net') ? 'Consumption A' : 'Consumption A'}
                loggers={loggers}
                color="teal"
                currentUnit={combinedConsumptionAUnit}
                type={'consumption'}
                handleHideLoggerChannel={handleHideLoggerChannel}
                unitsAndValues={unitsAndValues}
                handleUnitChange={handleUnitChange}
                handleGraphTypeChange={handleGraphTypeChange}
            />}
            {hasChanB && <GraphChannelDisplay
                colors={colors}
                name={window.location.hostname.includes('zonelog.net') ? 'Flow B' : 'Channel B'}
                loggers={loggers}
                color="green"
                currentUnit={combinedFlowBUnit}
                type={'flow'}
                handleHideLoggerChannel={handleHideLoggerChannel}
                unitsAndValues={unitsAndValues}
                handleUnitChange={handleUnitChange}
                handleGraphTypeChange={handleGraphTypeChange}
            />}
            {hasChanB && <GraphChannelDisplay
                colors={colors}
                name={window.location.hostname.includes('zonelog.net') ? 'Totaliser B' : 'Totaliser B'}
                loggers={loggers}
                color="brown"
                currentUnit={combinedTotalBUnit}
                type={'total'}
                handleHideLoggerChannel={handleHideLoggerChannel}
                unitsAndValues={unitsAndValues}
                handleUnitChange={handleUnitChange}
                handleGraphTypeChange={handleGraphTypeChange}
            />}
            {hasChanB && <GraphChannelDisplay
                colors={colors}
                name={window.location.hostname.includes('zonelog.net') ? 'Consumption B' : 'Consumption B'}
                loggers={loggers}
                color="pink"
                currentUnit={combinedConsumptionBUnit}
                type={'consumption'}
                handleHideLoggerChannel={handleHideLoggerChannel}
                unitsAndValues={unitsAndValues}
                handleUnitChange={handleUnitChange}
                handleGraphTypeChange={handleGraphTypeChange}
            />}
            {hasChanC && <GraphChannelDisplay
                colors={colors}
                name={window.location.hostname.includes('zonelog.net') ? 'Pressure C' : 'Channel C'}
                loggers={loggers}
                color="blue"
                currentUnit={combinedPressureCUnit}
                type={'pressure'}
                handleHideLoggerChannel={handleHideLoggerChannel}
                unitsAndValues={unitsAndValues}
                handleUnitChange={handleUnitChange}
                handleGraphTypeChange={handleGraphTypeChange}
            />}
            {hasChanD && <GraphChannelDisplay
                colors={colors}
                name={window.location.hostname.includes('zonelog.net') ? 'Pressure D' : 'Channel D'}
                loggers={loggers}
                color="orange"
                currentUnit={combinedPressureDUnit}
                type={'pressure'}
                handleHideLoggerChannel={handleHideLoggerChannel}
                unitsAndValues={unitsAndValues}
                handleUnitChange={handleUnitChange}
                handleGraphTypeChange={handleGraphTypeChange}
            />}
            {hasChanE && !window.location.hostname.includes('zonelog.net') && <GraphChannelDisplay
                colors={colors}
                name={'Channel E'}
                loggers={loggers}
                color="purple"
                currentUnit={combinedPressureEUnit}
                type={'pressure'}
                handleHideLoggerChannel={handleHideLoggerChannel}
                unitsAndValues={unitsAndValues}
                handleUnitChange={handleUnitChange}
                handleGraphTypeChange={handleGraphTypeChange}
            />}
        </>)
    }
    const handleGraphTypeChange = (value: string, channel: string):void => {
        const units = [...unitsAndValues]
        units.map(unit => {
            const sett = unit.graphSettings
            if (channel === 'A') {
                sett.flowAGraphType = value
            }
            if (channel === 'AT') {
                sett.flowATotalGraphType = value
            }
            if (channel === 'AC') {
                sett.flowAConsumptionGraphType = value
            }
            if (channel === 'B') {
                sett.flowBGraphType = value
            }
            if (channel === 'BT') {
                sett.flowBTotalGraphType = value
            }
            if (channel === 'BC') {
                sett.flowBConsumptionGraphType = value
            }
            if (channel === 'C') {
                sett.pressureCGraphType = value
            }
            if (channel === 'D') {
                sett.pressureDGraphType = value
            }
            if (channel === 'E') {
                sett.pressureEGraphType = value
            }
        })
        setUnitsAndValues(units)
    }

    const handleUnitChange = (value: string, channel: string): void => {
        let unitsAndValuesTemp = unitsAndValues
        unitsAndValuesTemp.forEach(item => {
            const set = item.graphSettings
            if (channel === 'A') {
                set.flowAMinValue = Number.MAX_VALUE
                set.flowAMaxValue = Number.NEGATIVE_INFINITY
            }
            if (channel === 'AT') {
                set.flowATotalMinValue = Number.MAX_VALUE
                set.flowATotalMaxValue = Number.NEGATIVE_INFINITY
            }
            if (channel === 'AC') {
                set.flowAConsumptionMinValue = Number.MAX_VALUE
                set.flowAConsumptionMaxValue = Number.NEGATIVE_INFINITY
            }
            if (channel === 'B') {
                set.flowBMinValue = Number.MAX_VALUE
                set.flowBMaxValue = Number.NEGATIVE_INFINITY
            }
            if (channel === 'BT') {
                set.flowBTotalMinValue = Number.MAX_VALUE
                set.flowBTotalMaxValue = Number.NEGATIVE_INFINITY
            }
            if (channel === 'BC') {
                set.flowBConsumptionMinValue = Number.MAX_VALUE
                set.flowBConsumptionMaxValue = Number.NEGATIVE_INFINITY
            }
            if (channel === 'C') {
                set.pressureCMinValue = Number.MAX_VALUE
                set.pressureCMaxValue = Number.NEGATIVE_INFINITY
            }
            if (channel === 'D') {
                set.pressureDMinValue = Number.MAX_VALUE
                set.pressureDMaxValue = Number.NEGATIVE_INFINITY
            }
            if (channel === 'E') {
                set.pressureEMinValue = Number.MAX_VALUE
                set.pressureEMaxValue = Number.NEGATIVE_INFINITY
            }

        })
        const modData = graphData.map(item => {
            let serial
            const keys = Object.keys(item).filter(key => key.includes('Channel'));
            if (keys.length > 0) {
                const firstKey = keys[0];
                serial = parseInt(firstKey.slice(0, firstKey.indexOf('C')));
            }
            


            if (channel === 'A') {
                const previousUnit = combinedFlowAUnit
                if (Object.keys(item).some(key => new RegExp("flowRateA").test(key))) {
                    item[`${serial}flowRateA`] ? item[`${serial}flowRateA`] = item[`${serial}flowRateA`] / flowConverter(value, previousUnit) : null
                }
                unitsAndValuesTemp = unitsAndValuesTemp.map(unit => {
                    if ((unit.serial === serial) && item[`${serial}flowRateA`] !== null) {
                        unit.graphSettings.flowAUnit = value
                        if (item[`${serial}flowRateA`] < unit.graphSettings.flowAMinValue) {
                            unit.graphSettings.flowAMinValue = item[`${serial}flowRateA`]
                        }
                        if (item[`${serial}flowRateA`] > unit.graphSettings.flowAMaxValue) {
                            unit.graphSettings.flowAMaxValue = item[`${serial}flowRateA`]
                        }
                    }
                    return unit
                })
                setCombinedFlowAUnit(value)
            }
            if (channel === 'AT') {
                const previousUnit = combinedTotalAUnit
                if (Object.keys(item).some(key => new RegExp("readingA").test(key))) {
                    item[`${serial}readingA`] ? item[`${serial}readingA`] = item[`${serial}readingA`] / totalConverter(value, previousUnit) : null
                }
                unitsAndValuesTemp = unitsAndValuesTemp.map(unit => {
                    if ((unit.serial === serial) && item[`${serial}readingA`] !== null) {
                        unit.graphSettings.flowATotalUnit = value
                        if (item[`${serial}readingA`] < unit.graphSettings.flowATotalMinValue) {
                            unit.graphSettings.flowATotalMinValue = item[`${serial}readingA`]
                        }
                        if (item[`${serial}readingA`] > unit.graphSettings.flowATotalMaxValue) {
                            unit.graphSettings.flowATotalMaxValue = item[`${serial}readingA`]
                        }
                    }
                    return unit
                })
                setCombinedTotalAUnit(value)
            }
            if (channel === 'AC') {
                const previousUnit = combinedConsumptionAUnit
                if (Object.keys(item).some(key => new RegExp("consumptionA").test(key))) {
                    item[`${serial}consumptionA`] ? item[`${serial}consumptionA`] = item[`${serial}consumptionA`] / totalConverter(value, previousUnit) : null
                }
                unitsAndValuesTemp = unitsAndValuesTemp.map(unit => {
                    if ((unit.serial === serial) && item[`${serial}consumptionA`] !== null) {
                        unit.graphSettings.flowAConsumptionUnit = value
                        if (item[`${serial}consumptionA`] < unit.graphSettings.flowAConsumptionMinValue) {
                            unit.graphSettings.flowAConsumptionMinValue = item[`${serial}consumptionA`]
                        }
                        if (item[`${serial}consumptionA`] > unit.graphSettings.flowAConsumptionMaxValue) {
                            unit.graphSettings.flowAConsumptionMaxValue = item[`${serial}consumptionA`]
                        }
                    }
                    return unit
                })
                setCombinedConsumptionAUnit(value)
            }
            if (channel === 'B') {
                const previousUnit = combinedFlowBUnit
                if (Object.keys(item).some(key => new RegExp("flowRateB").test(key))) {
                    item[`${serial}flowRateB`] ? item[`${serial}flowRateB`] = item[`${serial}flowRateB`] / flowConverter(value, previousUnit) : null
                }
                unitsAndValuesTemp = unitsAndValuesTemp.map(unit => {
                    if ((unit.serial === serial) && item[`${serial}flowRateB`] !== null) {
                        unit.graphSettings.flowBUnit = value
                        if (item[`${serial}flowRateB`] < unit.graphSettings.flowBMinValue) {
                            unit.graphSettings.flowBMinValue = item[`${serial}flowRateB`]
                        }
                        if (item[`${serial}flowRateB`] > unit.graphSettings.flowBMaxValue) {
                            unit.graphSettings.flowBMaxValue = item[`${serial}flowRateB`]
                        }
                    }
                    return unit
                })
                setCombinedFlowBUnit(value)
            }
            if (channel === 'BT') {
                const previousUnit = combinedTotalBUnit
                if (Object.keys(item).some(key => new RegExp("readingB").test(key))) {
                    item[`${serial}readingB`] ? item[`${serial}readingB`] = item[`${serial}readingB`] / totalConverter(value, previousUnit) : null
                }
                unitsAndValuesTemp = unitsAndValuesTemp.map(unit => {
                    if ((unit.serial === serial) && item[`${serial}readingB`] !== null) {
                        unit.graphSettings.flowBTotalUnit = value
                        if (item[`${serial}readingB`] < unit.graphSettings.flowBTotalMinValue) {
                            unit.graphSettings.flowBTotalMinValue = item[`${serial}readingB`]
                        }
                        if (item[`${serial}readingB`] > unit.graphSettings.flowBTotalMaxValue) {
                            unit.graphSettings.flowBTotalMaxValue = item[`${serial}readingB`]
                        }
                    }
                    return unit
                })
                setCombinedTotalBUnit(value)
            }
            if (channel === 'BC') {
                const previousUnit = combinedConsumptionBUnit
                if (Object.keys(item).some(key => new RegExp("consumptionB").test(key))) {
                    item[`${serial}consumptionB`] ? item[`${serial}consumptionB`] = item[`${serial}consumptionB`] / totalConverter(value, previousUnit) : null
                }
                unitsAndValuesTemp = unitsAndValuesTemp.map(unit => {
                    if ((unit.serial === serial) && item[`${serial}consumptionB`] !== null) {
                        unit.graphSettings.flowBConsumptionUnit = value
                        if (item[`${serial}consumptionB`] < unit.graphSettings.flowBConsumptionMinValue) {
                            unit.graphSettings.flowBConsumptionMinValue = item[`${serial}consumptionB`]
                        }
                        if (item[`${serial}consumptionB`] > unit.graphSettings.flowBConsumptionMaxValue) {
                            unit.graphSettings.flowBConsumptionMaxValue = item[`${serial}consumptionB`]
                        }
                    }
                    return unit
                })
                setCombinedConsumptionBUnit(value)
            }
            if (channel === 'C') {
                const previousUnit = combinedPressureCUnit
                if (Object.keys(item).some(key => new RegExp("readingC").test(key))) {

                    item[`${serial}readingC`] ? item[`${serial}readingC`] = item[`${serial}readingC`] / pressureConverter(value, previousUnit) : null
                }
                unitsAndValuesTemp = unitsAndValuesTemp.map(unit => {
                    if ((unit.serial === serial) && item[`${serial}readingC`] !== null) {
                        unit.graphSettings.pressureCUnit = value
                        if (item[`${serial}readingC`] < unit.graphSettings.pressureCMinValue) {
                            unit.graphSettings.pressureCMinValue = item[`${serial}readingC`]
                        }
                        if (item[`${serial}readingC`] > unit.graphSettings.pressureCMaxValue) {
                            unit.graphSettings.pressureCMaxValue = item[`${serial}readingC`]
                        }
                    }
                    return unit
                })
                setCombinedPressureCUnit(value)
            }
            if (channel === 'D') {
                const previousUnit = combinedPressureDUnit
                if (Object.keys(item).some(key => new RegExp("readingD").test(key))) {
                    item[`${serial}readingD`] ? item[`${serial}readingD`] = item[`${serial}readingD`] / pressureConverter(value, previousUnit) : null
                }
                unitsAndValuesTemp = unitsAndValuesTemp.map(unit => {
                    if ((unit.serial === serial) && item[`${serial}readingD`] !== null) {
                        unit.graphSettings.pressureDUnit = value
                        if (item[`${serial}readingD`] < unit.graphSettings.pressureDMinValue) {
                            unit.graphSettings.pressureDMinValue = item[`${serial}readingD`]
                        }
                        if (item[`${serial}readingD`] > unit.graphSettings.pressureDMaxValue) {
                            unit.graphSettings.pressureDMaxValue = item[`${serial}readingD`]
                        }
                    }
                    return unit
                })
                setCombinedPressureDUnit(value)
            }
            if (channel === 'E') {
                const previousUnit = combinedPressureEUnit
                if (Object.keys(item).some(key => new RegExp("readingE").test(key))) {
                    item[`${serial}readingE`] ? item[`${serial}readingE`] = item[`${serial}readingE`] / pressureConverter(value, previousUnit) : null
                }
                unitsAndValuesTemp = unitsAndValuesTemp.map(unit => {
                    if ((unit.serial === serial) && item[`${serial}readingE`] !== null) {
                        unit.graphSettings.pressureEUnit = value
                        if (item[`${serial}readingE`] < unit.graphSettings.pressureEMinValue) {
                            unit.graphSettings.pressureEMinValue = item[`${serial}readingE`]
                        }
                        if (item[`${serial}readingE`] > unit.graphSettings.pressureEMaxValue) {
                            unit.graphSettings.pressureEMaxValue = item[`${serial}readingE`]
                        }
                    }
                    return unit
                })
                setCombinedPressureEUnit(value)
            }

            return item
        })
        
        
        setUnitsAndValues(unitsAndValuesTemp)
        setGraphData(modData)
        
    }



    const flowRateUnits = [
        { name: 'l/s', value: 'l/s' },
        { name: 'm3/h', value: 'm3/h' },
        { name: 'million gal/day (MGD)', value: 'MGD' },
        { name: 'gal/day (GPD)', value: 'GPD' },
        { name: 'gal/s (gps)', value: 'gps' },
        { name: 'feet3/s (cfs)', value: 'cfs' }
    ]

    const flowUnits = [
        { name: 'l', value: 'l' },
        { name: 'm3', value: 'm3' },
        { name: 'gal', value: 'gal' },
        { name: 'ft3', value: 'ft3' }
    ]

    const pressureUnits = [
        { name: 'bar', value: 'bar' },
        { name: 'millibar', value: 'mb' },
        { name: 'mH2O', value: 'mH2O' },
        { name: 'mwg', value: 'mwg' },
        { name: 'psi', value: 'psi' }
    ]


    const handleCombineY = ():void => {
        if (!combineY) {
            const pressureCUnitCount = [0,0,0,0,0]
            const pressureDUnitCount = [0,0,0,0,0]
            const pressureEUnitCount = [0,0,0,0,0]
            const flowAUnitCount = [0,0,0,0,0,0]
            const flowBUnitCount = [0,0,0,0,0,0]
            const flowATotalUnitCount = [0,0,0,0]
            const flowBTotalUnitCount = [0,0,0,0]
            const flowAConsumptionUnitCount = [0,0,0,0]
            const flowBConsumptionUnitCount = [0,0,0,0]
            let flowUnitA = ""
            let flowUnitB = ""
            let totalUnitA = ""
            let totalUnitB = ""
            let consumptionUnitA = ""
            let consumptionUnitB = ""
            let pressureCUnit = ""
            let pressureDUnit = ""
            let pressureEUnit = ""
            unitsAndValues.forEach(u => {
                const channelsAvail = u.channelsAvail
                const sett = u.graphSettings
                if (channelsAvail & 0x01) {
                    sett.flowAVisible = true
                    setHasFlow(true)
                    setHasConsumption(true)
                    setHasTotal(true)
                    flowRateUnits.forEach((unit, idx) => {
                        if (unit.value === sett.flowAUnit) {
                            flowAUnitCount[idx]++
                        }
                    })
                    sett.flowATotalVisible = true
                    flowUnits.forEach((unit, idx) => {
                        if (unit.value === sett.flowATotalUnit) {
                            flowATotalUnitCount[idx]++
                        }
                    })
                    sett.flowAConsumptionVisible = true
                    flowUnits.forEach((unit, idx) => {
                        if (unit.value === sett.flowAConsumptionUnit) {
                            flowAConsumptionUnitCount[idx]++
                        }
                    })
                }
                if (channelsAvail & 0x02) {
                    sett.flowBVisible = true
                    setHasFlow(true)
                    setHasConsumption(true)
                    setHasTotal(true)
                    flowRateUnits.forEach((unit, idx) => {
                        if (unit.value === sett.flowBUnit) {
                            flowBUnitCount[idx]++
                        }
                    })
                    sett.flowBTotalVisible = true
                    flowUnits.forEach((unit, idx) => {
                        if (unit.value === sett.flowBTotalUnit) {
                            flowBTotalUnitCount[idx]++
                        }
                    })
                    sett.flowBConsumptionVisible = true
                    flowUnits.forEach((unit, idx) => {
                        if (unit.value === sett.flowBConsumptionUnit) {
                            flowBConsumptionUnitCount[idx]++
                        }
                    })
                }

                if (channelsAvail & 0x04) {
                    setHasPressureChannels(true)
                    sett.pressureCVisible = true
                    if (pressureUnits.map(unit => unit.value).includes(sett.pressureCUnit)) {
                        pressureUnits.forEach((unit, idx) => {

                            if (unit.value === sett.pressureCUnit) {
                                pressureCUnitCount[idx]++
                            }
                        })
                    }
                }
                if (channelsAvail & 0x08) {
                    setHasPressureChannels(true)
                    sett.pressureDVisible = true
                    if (pressureUnits.map(unit => unit.value).includes(sett.pressureDUnit)) {
                        pressureUnits.forEach((unit, idx) => {
                            if (unit.value === sett.pressureDUnit) {
                                pressureDUnitCount[idx]++
                            }
                        })
                    }
                }
                if (channelsAvail & 0x10) {
                    setHasPressureChannels(true)
                    sett.pressureEVisible = true
                    if (pressureUnits.map(unit => unit.value).includes(sett.pressureEUnit)) {
                        pressureUnits.forEach((unit, idx) => {
                            if (unit.value === sett.pressureEUnit) {
                                pressureEUnitCount[idx]++
                            }
                        })
                    }
                }
                setShowFlow(true)
                setShowPressure(true)
                setShowTotal(true)
                setShowConsumption(true)
                
                
                
            })
            flowUnitA = flowRateUnits[flowAUnitCount.indexOf(Math.max(...flowAUnitCount))].value
            totalUnitA = flowUnits[flowATotalUnitCount.indexOf(Math.max(...flowATotalUnitCount))].value
            consumptionUnitA = flowUnits[flowAConsumptionUnitCount.indexOf(Math.max(...flowAConsumptionUnitCount))].value
            flowUnitB = flowRateUnits[flowBUnitCount.indexOf(Math.max(...flowBUnitCount))].value
            totalUnitB = flowUnits[flowBTotalUnitCount.indexOf(Math.max(...flowBTotalUnitCount))].value
            consumptionUnitB = flowUnits[flowBConsumptionUnitCount.indexOf(Math.max(...flowBConsumptionUnitCount))].value

            if (Math.max(...pressureCUnitCount) === 0) {
                const pressureCArr = unitsAndValues.map(log => log.graphSettings.pressureCUnit)
                const allSame = pressureCArr.every(unit => unit === pressureCArr[0])
                if (allSame) {
                    pressureCUnit = pressureCArr[0]
                } 
            } else {
                pressureCUnit = pressureUnits[pressureCUnitCount.indexOf(Math.max(...pressureCUnitCount))].value
            }
            if (Math.max(...pressureDUnitCount) === 0) {
                const pressureDArr = unitsAndValues.map(log => log.graphSettings.pressureDUnit)
                const allSame = pressureDArr.every(unit => unit === pressureDArr[0])
                if (allSame) {
                    pressureDUnit = pressureDArr[0]
                }
            } else {
                pressureDUnit = pressureUnits[pressureDUnitCount.indexOf(Math.max(...pressureDUnitCount))].value
            }

            if (Math.max(...pressureEUnitCount) === 0) {
                const pressureEArr = unitsAndValues.map(log => log.graphSettings.pressureEUnit)
                const allSame = pressureEArr.every(unit => unit === pressureEArr[0])
                if (allSame) {
                    pressureEUnit = pressureEArr[0]
                }
            } else {
                pressureEUnit = pressureUnits[pressureEUnitCount.indexOf(Math.max(...pressureEUnitCount))].value
            }
            

            setCombinedFlowAUnit(flowUnitA)
            setCombinedTotalAUnit(totalUnitA)
            setCombinedConsumptionAUnit(consumptionUnitA)
            setCombinedFlowBUnit(flowUnitB)
            setCombinedTotalBUnit(totalUnitB)
            setCombinedConsumptionBUnit(consumptionUnitB)
            
            setCombinedPressureCUnit(pressureCUnit)
            setCombinedPressureDUnit(pressureDUnit)
            setCombinedPressureEUnit(pressureEUnit)

            modifyGraphData(flowUnitA, flowUnitB, totalUnitA, totalUnitB, consumptionUnitA, consumptionUnitB, pressureCUnit, pressureDUnit, pressureEUnit)
            unitsAndValues.forEach(unit => {
                const sett = unit.graphSettings
                sett.flowAGraphType = 'spline'
                sett.flowATotalGraphType = 'bar'
                sett.flowAConsumptionGraphType = 'bar'
                sett.flowBGraphType = 'spline'
                sett.flowBTotalGraphType = 'bar'
                sett.flowBConsumptionGraphType = 'bar'
                sett.pressureCGraphType = 'spline'
                sett.pressureDGraphType = 'spline'
                sett.pressureEGraphType = 'spline'
            })
        } else {
            reloadData(location.state.groupId, location.state.loggers, location.state.site, true)
        }
        setCombineY(!combineY)
    }

    const modifyGraphData = (flowUnitA: string, flowUnitB: string, totalUnitA: string, totalUnitB: string, consumptionUnitA: string, consumptionUnitB: string , pressureCUnit: string, pressureDUnit: string, pressureEUnit: string):void => {
        setLoading(true)
        const modData = graphData.map(item => {
            const modItem = { ...item }
            
            const serial = parseInt(Object.keys(modItem).filter(key => new RegExp('Channel').test(key))[0].slice(0, Object.keys(modItem).filter(key => new RegExp('Channel').test(key))[0].indexOf('C')))
            
            const graphSettings = unitsAndValues.filter(unit => unit.serial === serial)[0]?.graphSettings

            if (Object.keys(modItem).some(key => key === `${serial}flowRateA` && modItem[`${serial}flowRateA`] !== null)) {
                if (graphSettings?.flowAUnit !== flowUnitA) {
                    modItem[`${serial}flowRateA`] = modItem[`${serial}flowRateA`] / flowConverter(flowUnitA, graphSettings?.flowAUnit)
                } 
            }
            if (Object.keys(modItem).some(key => key === `${serial}readingA` && modItem[`${serial}readingA`] !== null)) {
                if (graphSettings?.flowATotalUnit !== totalUnitA) {
                    modItem[`${serial}readingA`] = modItem[`${serial}readingA`] / totalConverter(totalUnitA, graphSettings?.flowATotalUnit)
                }
            }
            if (Object.keys(modItem).some(key => key === `${serial}consumptionA` && modItem[`${serial}consumptionA`] !== null)) {
                if (graphSettings?.flowAConsumptionUnit !== consumptionUnitA) {
                    modItem[`${serial}consumptionA`] = modItem[`${serial}consumptionA`] / totalConverter(consumptionUnitA, graphSettings?.flowAConsumptionUnit)
                }
            }
            if (Object.keys(modItem).some(key => key === `${serial}flowRateB` && modItem[`${serial}flowRateB`] !== null)) {
                if (graphSettings?.flowBUnit !== flowUnitB) {
                    modItem[`${serial}flowRateB`] = modItem[`${serial}flowRateB`] / flowConverter(flowUnitB, graphSettings?.flowBUnit)
                }
            }
            if (Object.keys(modItem).some(key => key === `${serial}readingB` && modItem[`${serial}readingB`] !== null)) {
                if (graphSettings?.flowBTotalUnit !== totalUnitB) {
                    modItem[`${serial}readingB`] = modItem[`${serial}readingB`] / totalConverter(totalUnitB, graphSettings?.flowBTotalUnit)
                }
            }
            if (Object.keys(modItem).some(key => key === `${serial}consumptionB` && modItem[`${serial}consumptionB`] !== null)) {
                if (graphSettings?.flowBConsumptionUnit !== consumptionUnitB) {
                    modItem[`${serial}consumptionB`] = modItem[`${serial}consumptionB`] / totalConverter(consumptionUnitB, graphSettings?.flowBConsumptionUnit)
                }
            }
            if (Object.keys(modItem).some(key => key === `${serial}readingC` && modItem[`${serial}readingC`] !== null)) {
                if (graphSettings?.pressureCUnit !== pressureCUnit) {

                    modItem[`${serial}readingC`] = modItem[`${serial}readingC`] / pressureConverter(pressureCUnit, graphSettings?.pressureCUnit)
                    modItem[`${serial}readingCmin`] = modItem[`${serial}readingCmin`] / pressureConverter(pressureCUnit, graphSettings?.pressureCUnit)
                    modItem[`${serial}readingCmax`] = modItem[`${serial}readingCmax`] / pressureConverter(pressureCUnit, graphSettings?.pressureCUnit)
                }
            }
            if (Object.keys(modItem).some(key => key === `${serial}readingD` && modItem[`${serial}readingD`] !== null)) {
                if (graphSettings?.pressureDUnit !== pressureDUnit) {

                    modItem[`${serial}readingD`] = modItem[`${serial}readingD`] / pressureConverter(pressureDUnit, graphSettings?.pressureDUnit)
                    modItem[`${serial}readingDmin`] = modItem[`${serial}readingDmin`] / pressureConverter(pressureDUnit, graphSettings?.pressureDUnit)
                    modItem[`${serial}readingDmax`] = modItem[`${serial}readingDmax`] / pressureConverter(pressureDUnit, graphSettings?.pressureDUnit)
                }
            }
            if (Object.keys(modItem).some(key => key === `${serial}readingE` && modItem[`${serial}readingE`] !== null)) {
                if (graphSettings?.pressureEUnit !== pressureEUnit) {

                    modItem[`${serial}readingE`] = modItem[`${serial}readingE`] / pressureConverter(pressureEUnit, graphSettings?.pressureEUnit)
                    modItem[`${serial}readingEmin`] = modItem[`${serial}readingEmin`] / pressureConverter(pressureEUnit, graphSettings?.pressureEUnit)
                    modItem[`${serial}readingEmax`] = modItem[`${serial}readingEmax`] / pressureConverter(pressureEUnit, graphSettings?.pressureEUnit)
                }
            }
           return modItem

        }).filter(item => item !== null)

        setGraphData(modData)
        setLoading(false)

        
    }


    const customizePoint = (e: {value: number | null }) => {
        if (e.value) {
            return {
                visible: true, size: 1  
}
        } else return {visible: false}
    }


    


    


    ///tabs
    const tabsIconandTextSingleLogger = [
        {
            id: 0,
            text: "Flow",
            icon: "fa fa-water",
            
        },
        {
            id: 1,
            text: window.location.hostname.includes('zonelog.net') ? "Pressure" : "Analog Channels",
            icon: "fa fa-gauge"
        },
        {
            id: 2,
            text: "Totaliser",
            icon: "fa fa-signal"
        },
        {
            id: 3,
            text: "Consumption",
            icon: "fa fa-faucet"
        }
    ]

    const tabsIconandTextNoPressureSingleLogger = [
        {
            id: 0,
            text: "Flow",
            icon: "fa fa-water",

        },
        {
            id: 1,
            text: "Totaliser",
            icon: "fa fa-signal"
        },
        {
            id: 2,
            text: "Consumption",
            icon: "fa fa-faucet"
        }
        
    ]

    const tabsIconandTextMultiLogger = [
        {
            id: 0,
            text: "Flow",
            icon: "fa fa-water",

        },
        {
            id: 1,
            text: window.location.hostname.includes('zonelog.net') ? "Pressure" : "Analog Channels",
            icon: "fa fa-gauge"
        },
        {
            id: 2,
            text: "Totaliser",
            icon: "fa fa-signal"
        },
        {
            id: 3,
            text: "Consumption",
            icon: "fa fa-faucet"
        }

    ]

    const tabsIconandTextNoPressureMultiLogger = [
        {
            id: 0,
            text: "Flow",
            icon: "fa fa-water",

        },
        {
            id: 1,
            text: "Totaliser",
            icon: "fa fa-signal"
        },
        {
            id: 2,
            text: "Consumption",
            icon: "fa fa-faucet"
        }
    ]



    const overViewStyle = {

        borderRadius: "5px",
        padding: "10px 10px 30px 10px",

        boxShadow: "2px 2px 10px #00000060",
        marginBottom: "30px",

    }
    
    
    const modalParagraphStyle = { margin: "auto 0 ", color: "#000000", fontWeight: 400 }

    const settings = graphTemplate.graphSettings


    const theme = useTheme()
    return (

        
        <>
            {!redirect ? <>

                {/*switch loading back around later */}
                {loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 13000
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={loading}
                        />
                    </div>
                }
                {!loading &&
                    <div>

                        {APIFailed &&
                            <ApiFailed />
                        }
                        {!APIFailed &&
                            <div style={{ position: "relative" }}>
                                <Modal
                                    show={exportModal}
                                    style={{
                                        width: "40%",
                                        height: "50%",
                                        //marginTop: "100px",
                                        position: 'absolute', left: '50%', top: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: 11000000
                                    }}
                                >
                                    <Modal.Header>
                                        <Modal.Title>Type of Export
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <FormControlLabel value="CSV" style={{ color: "#000000" }} control={<Radio color="secondary" />} name={"exportType"} label="CSV" checked={exportAs === 'CSV'} onChange={(e): void => handleExportTypeChange(e.target.value)} />
                                            <FormControlLabel value="XLSX" style={{ color: "#000000" }} control={<Radio color="secondary" />} name={"exportType"} label="XLSX" checked={exportAs === 'XLSX'} onChange={(e): void => handleExportTypeChange(e.target.value)} />
                                            <FormControlLabel value="XLSX-T" style={{ color: "#000000" }} control={<Radio color="secondary" />} name={"exportType"} label="XLSX-T" checked={exportAs === 'XLSX-T'} onChange={(e): void => handleExportTypeChange(e.target.value)} />
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                            <FormControlLabel style={{ color: "#000000" }} control={<Switch checked={splitDate} onChange={():void => setSplitDate(!splitDate) } />} label="Split Graph Export date in seperate columns" />
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="contained" style={{ marginRight: "10px" }} onClick={(): void => exportConfirm()}>Confirm</Button>
                                        <Button variant="contained" color="error" onClick={(): void => { toggleExportModal() }}>Cancel</Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal
                                    show={selectLoggerToTemplate}
                                    style={{
                                        width: "40%",
                                        height: "50%",
                                        marginTop: "100px",
                                        position: 'absolute', left: '50%', top: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        //zIndex: 10000
                                    }}
                                >
                                    <Modal.Header>
                                        <Modal.Title>Which logger would you like to create your settings for?
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                            {loggers.length > 1 ?
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <Select
                                                        value={loggersToTemplate}
                                                        size="small"
                                                        style={{ background: "#ffffff", zIndex: 1000, width: "214px" }}
                                                        onChange={(e): void => { handleLoggerTemplateChange(e.target.value) }}
                                                    >
                                                        {loggers.map((logger) => {

                                                            return (<MenuItem style={{ zIndex: 1111111111111111 }} value={logger.Serial} key={logger.LoggerId}>{logger.Serial}</MenuItem>)
                                                        })}
                                                    </Select>
                                                </div> :
                                                <Button variant="contained" style={{ width: "214px", margin: "auto" }} onClick={(): void => toggleCreateTemplateOpen(true, false)}>{loggers.length > 0 && loggers[0].Serial}</Button>}

                                            {sessionStorage.getItem('canConfig') === 'true' &&
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                                        <h5 style={{ margin: "0 auto" }}>or</h5>
                                                        <Button variant="contained" color="warning" onClick={(): void => toggleCreateCompanyDefault()}>Create Company Default</Button>
                                                    </div>
                                                </div>}
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>

                                        {loggers.length > 1 && <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={(): void => toggleCreateTemplateOpen(true)}>Select</Button>}
                                        <Button variant="contained" color="error" onClick={(): void => closeGraphTemplateWindow()}>Cancel</Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal
                                    show={createTemplateOpen}
                                    style={{
                                        //zIndex: 10001,
                                        width: "100%",
                                        height: "90%",
                                        position: 'absolute',
                                        transform: 'translate(0%, 30%)',




                                    }}
                                >
                                    <Modal.Header>
                                        <Modal.Title>Create Graph Settings Template</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {!createCompanyDefault && <div style={{ display: "flex", justifyContent: "space-between", borderLeft: "10px solid #000000", width: "98.7%", margin: " 20px 300px 0 10px", backgroundImage: "linear-gradient(30deg, #f7f7f7, #f7f7f7 71%, #808080)", ...overViewStyle }}>
                                            <div style={{ display: "flex" }}>
                                                <h4 style={{ margin: "auto" }}>Current Selected Loggers that styling will be applied to: </h4>
                                                <div>

                                                    {loggersToTemplate && <Chip style={{ margin: " 5px 5px auto 10px" }}
                                                        label={loggersToTemplate} color="primary" />
                                                    }
                                                </div>
                                            </div>
                                        </div>}
                                        <div style={{ display: "flex" }}>
                                            <Tabs
                                                onItemClick={(e): void => setSelectedTab(e.itemIndex)}
                                                dataSource={loggers.length === 1 ? (graphTemplate.channelsAvail > 3 ? tabsIconandTextSingleLogger : tabsIconandTextNoPressureSingleLogger) : (graphTemplate.channelsAvail > 3 ? tabsIconandTextMultiLogger : tabsIconandTextNoPressureMultiLogger)}
                                                defaultSelectedIndex={0}
                                                style={{ fontSize: "18px" }}
                                                selectionMode={'single'}
                                                selectedIndex={selectedTab}


                                            />


                                        </div>
                                        {unitsAndValues.length > 0 && <div style={{ border: "1px solid #dddddd" }}>
                                            {selectedTab === 0 &&
                                                <div>



                                                    {/*Flow A*/}

                                                    {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0]?.channelsAvail & 0x01)) &&
                                                        <GraphSettingsComponent
                                                        overViewStyle={overViewStyle}
                                                        borderColor={'#009fe3'}
                                                        modalParagraphStyle={modalParagraphStyle}
                                                        handleGraphSettingsChange={handleGraphSettingsChange}
                                                        changeGroup={"flowA"}
                                                        isPressure={false}
                                                        visible={settings.flowAVisible}
                                                        unit={settings.flowAUnit}
                                                        minState={settings.flowAMinState}
                                                        minValue={settings.flowAMinValue}
                                                        maxState={settings.flowAMaxState}
                                                        maxValue={settings.flowAMaxValue}
                                                        valueAs={null}
                                                        axisPosition={settings.flowAAxisPosition}
                                                        lineWidth={settings.flowALineWidth}
                                                        graphType={settings.flowAGraphType}
                                                        color={settings.flowAColor}
                                                        labelSize={settings.flowALabelSize}
                                                        startDate={startDate}
                                                        loggers={loggers}
                                                        channel={"Flow A"}
                                                        displayUnits={flowRateUnits}
                                                        isTotaliser={false}
                                                        totalPerDay={settings.flowATotalPerDay}
                                                        companyDefault={createCompanyDefault}
                                                        defaultColor={settings.flowADefaultColor}
                                                        pressureUnits={pressureUnits}
                                                        />
                                                    }



                                                    {/*Flow B*/}

                                                    {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0]?.channelsAvail & 0x02)) &&
                                                        <GraphSettingsComponent
                                                            overViewStyle={overViewStyle}
                                                            borderColor={'#eed202'}
                                                            modalParagraphStyle={modalParagraphStyle}
                                                            handleGraphSettingsChange={handleGraphSettingsChange}
                                                            changeGroup={"flowB"}
                                                            isPressure={false}
                                                            visible={settings.flowBVisible}
                                                            unit={settings.flowBUnit}
                                                            minState={settings.flowBMinState}
                                                            minValue={settings.flowBMinValue}
                                                            maxState={settings.flowBMaxState}
                                                            maxValue={settings.flowBMaxValue}
                                                            valueAs={null}
                                                            axisPosition={settings.flowBAxisPosition}
                                                            lineWidth={settings.flowBLineWidth}
                                                            graphType={settings.flowBGraphType}
                                                            color={settings.flowBColor}
                                                            labelSize={settings.flowBLabelSize}
                                                            startDate={startDate}
                                                            loggers={loggers}
                                                            channel={"Flow B"}
                                                            displayUnits={flowRateUnits}
                                                            isTotaliser={false}
                                                            totalPerDay={settings.flowBTotalPerDay}
                                                            companyDefault={createCompanyDefault}
                                                            defaultColor={settings.flowBDefaultColor}
                                                            pressureUnits={pressureUnits}
                                                        />}
                                                </div>

                                            }
                                            {!!(graphTemplate.channelsAvail > 3 ? selectedTab === 1 : selectedTab === 999) &&
                                                <div>
                                                    {/*Analoge C*/}

                                                    {(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0]?.channelsAvail & 0x04)) &&
                                                        <GraphSettingsComponent
                                                            overViewStyle={overViewStyle}
                                                            borderColor={'#6f2da8'}
                                                            modalParagraphStyle={modalParagraphStyle}
                                                            handleGraphSettingsChange={handleGraphSettingsChange}
                                                            changeGroup={"pressureC"}
                                                            isPressure={true}
                                                            visible={settings.pressureCVisible}
                                                            unit={settings.pressureCUnit}
                                                            minState={settings.pressureCMinState}
                                                            minValue={settings.pressureCMinValue}
                                                            maxState={settings.pressureCMaxState}
                                                            maxValue={settings.pressureCMaxValue}
                                                            valueAs={settings.pressureCValueAs}
                                                            axisPosition={settings.pressureCAxisPosition}
                                                            lineWidth={settings.pressureCLineWidth}
                                                            graphType={settings.pressureCGraphType}
                                                            color={settings.pressureCColor}
                                                            labelSize={settings.pressureCLabelSize}
                                                            startDate={startDate}
                                                            loggers={loggers}
                                                            channel={window.location.hostname.includes('zonelog.net') ? "Pressure C" : "Analog C"}
                                                            displayUnits={pressureUnits}
                                                            isTotaliser={false}
                                                            totalPerDay={settings.flowATotalPerDay}
                                                            companyDefault={createCompanyDefault}
                                                            defaultColor={settings.pressureCDefaultColor}
                                                            pressureUnits={pressureUnits}
                                                        />}


                                                    {/*Analoge D*/}

                                                    {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0]?.channelsAvail & 0x08)) &&
                                                        <GraphSettingsComponent
                                                            overViewStyle={overViewStyle}
                                                            borderColor={'#00ADBA'}
                                                            modalParagraphStyle={modalParagraphStyle}
                                                            handleGraphSettingsChange={handleGraphSettingsChange}
                                                            changeGroup={"pressureD"}
                                                            isPressure={true}
                                                            visible={settings.pressureDVisible}
                                                            unit={settings.pressureDUnit}
                                                            minState={settings.pressureDMinState}
                                                            minValue={settings.pressureDMinValue}
                                                            maxState={settings.pressureDMaxState}
                                                            maxValue={settings.pressureDMaxValue}
                                                            valueAs={settings.pressureDValueAs}
                                                            axisPosition={settings.pressureDAxisPosition}
                                                            lineWidth={settings.pressureDLineWidth}
                                                            graphType={settings.pressureDGraphType}
                                                            color={settings.pressureDColor}
                                                            labelSize={settings.pressureDLabelSize}
                                                            startDate={startDate}
                                                            loggers={loggers}
                                                            channel={window.location.hostname.includes('zonelog.net') ? "Pressure D" : "Analog D"}
                                                            displayUnits={pressureUnits}
                                                            isTotaliser={false}
                                                            totalPerDay={settings.flowATotalPerDay}
                                                            companyDefault={createCompanyDefault}
                                                            defaultColor={settings.pressureDDefaultColor}
                                                            pressureUnits={pressureUnits}
                                                        />}
                                                    

                                                    {/*Analoge E*/}

                                                    {!!((createCompanyDefault && !window.location.hostname.includes('zonelog.net')) || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0]?.channelsAvail & 0x10)) &&
                                                        <GraphSettingsComponent
                                                            overViewStyle={overViewStyle}
                                                            borderColor={'#FF0000'}
                                                            modalParagraphStyle={modalParagraphStyle}
                                                            handleGraphSettingsChange={handleGraphSettingsChange}
                                                            changeGroup={"pressureE"}
                                                            isPressure={true}
                                                            visible={settings.pressureEVisible}
                                                            unit={settings.pressureEUnit}
                                                            minState={settings.pressureEMinState}
                                                            minValue={settings.pressureEMinValue}
                                                            maxState={settings.pressureEMaxState}
                                                            maxValue={settings.pressureEMaxValue}
                                                            valueAs={settings.pressureEValueAs}
                                                            axisPosition={settings.pressureEAxisPosition}
                                                            lineWidth={settings.pressureELineWidth}
                                                            graphType={settings.pressureEGraphType}
                                                            color={settings.pressureEColor}
                                                            labelSize={settings.pressureELabelSize}
                                                            startDate={startDate}
                                                            loggers={loggers}
                                                            channel={window.location.hostname.includes('zonelog.net') ? "Pressure E" : "Analog E"}
                                                            displayUnits={pressureUnits}
                                                            isTotaliser={false}
                                                            totalPerDay={settings.flowATotalPerDay}
                                                            companyDefault={createCompanyDefault}
                                                            defaultColor={settings.pressureEDefaultColor}
                                                            pressureUnits={pressureUnits}
                                                        />}
                                                </div>

                                            }
                                            {selectedTab === 1 && graphTemplate.channelsAvail < 3 &&
                                                <div>
                                                    {/*Total A*/}

                                                    {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0]?.channelsAvail & 0x01)) &&
                                                        <GraphSettingsComponent
                                                            overViewStyle={overViewStyle}
                                                            borderColor={'#009fe3'}
                                                            modalParagraphStyle={modalParagraphStyle}
                                                            handleGraphSettingsChange={handleGraphSettingsChange}
                                                            changeGroup={"flowATotal"}
                                                            isPressure={false}
                                                            visible={settings.flowATotalVisible}
                                                            unit={settings.flowATotalUnit}
                                                            minState={settings.flowATotalMinState}
                                                            minValue={settings.flowATotalMinValue}
                                                            maxState={settings.flowATotalMaxState}
                                                            maxValue={settings.flowATotalMaxValue}
                                                            valueAs={null}
                                                            axisPosition={settings.flowATotalAxisPosition}
                                                            lineWidth={settings.flowATotalLineWidth}
                                                            graphType={settings.flowATotalGraphType}
                                                            color={settings.flowATotalColor}
                                                            labelSize={settings.flowATotalLabelSize}
                                                            startDate={startDate}
                                                            loggers={loggers}
                                                            channel={"Totaliser A"}
                                                            displayUnits={flowUnits}
                                                            isTotaliser={true}
                                                            totalPerDay={settings.flowATotalPerDay}
                                                            companyDefault={createCompanyDefault}
                                                            defaultColor={settings.flowATotalDefaultColor}
                                                            pressureUnits={pressureUnits}
                                                        />
                                                    }



                                                    {/*Total B*/}

                                                    {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0]?.channelsAvail & 0x02)) &&
                                                        <GraphSettingsComponent
                                                            overViewStyle={overViewStyle}
                                                            borderColor={'#eed202'}
                                                            modalParagraphStyle={modalParagraphStyle}
                                                            handleGraphSettingsChange={handleGraphSettingsChange}
                                                            changeGroup={"flowBTotal"}
                                                            isPressure={false}
                                                            visible={settings.flowBTotalVisible}
                                                            unit={settings.flowBTotalUnit}
                                                            minState={settings.flowBTotalMinState}
                                                            minValue={settings.flowBTotalMinValue}
                                                            maxState={settings.flowBTotalMaxState}
                                                            maxValue={settings.flowBTotalMaxValue}
                                                            valueAs={null}
                                                            axisPosition={settings.flowBTotalAxisPosition}
                                                            lineWidth={settings.flowBTotalLineWidth}
                                                            graphType={settings.flowBTotalGraphType}
                                                            color={settings.flowBTotalColor}
                                                            labelSize={settings.flowBTotalLabelSize}
                                                            startDate={startDate}
                                                            loggers={loggers}
                                                            channel={"Totaliser B"}
                                                            displayUnits={flowUnits}
                                                            isTotaliser={true}
                                                            totalPerDay={settings.flowATotalPerDay}
                                                            companyDefault={createCompanyDefault}
                                                            defaultColor={settings.flowBTotalDefaultColor}
                                                            pressureUnits={pressureUnits}
                                                        />}
                                                </div>}
                                            {selectedTab === 2 && graphTemplate.channelsAvail > 3 &&
                                                <div>
                                                    {/*Total A*/}

                                                    {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0]?.channelsAvail & 0x01)) &&
                                                        <GraphSettingsComponent
                                                            overViewStyle={overViewStyle}
                                                            borderColor={'#009fe3'}
                                                            modalParagraphStyle={modalParagraphStyle}
                                                            handleGraphSettingsChange={handleGraphSettingsChange}
                                                            changeGroup={"flowATotal"}
                                                            isPressure={false}
                                                            visible={settings.flowATotalVisible}
                                                            unit={settings.flowATotalUnit}
                                                            minState={settings.flowATotalMinState}
                                                            minValue={settings.flowATotalMinValue}
                                                            maxState={settings.flowATotalMaxState}
                                                            maxValue={settings.flowATotalMaxValue}
                                                            valueAs={null}
                                                            axisPosition={settings.flowATotalAxisPosition}
                                                            lineWidth={settings.flowATotalLineWidth}
                                                            graphType={settings.flowATotalGraphType}
                                                            color={settings.flowATotalColor}
                                                            labelSize={settings.flowATotalLabelSize}
                                                            startDate={startDate}
                                                            loggers={loggers}
                                                            channel={"Totaliser A"}
                                                            displayUnits={flowUnits}
                                                            isTotaliser={true}
                                                            totalPerDay={settings.flowATotalPerDay}
                                                            companyDefault={createCompanyDefault}
                                                            defaultColor={settings.flowATotalDefaultColor}
                                                            pressureUnits={pressureUnits}
                                                            />
                                                    }



                                                    {/*Total B*/}

                                                    {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0]?.channelsAvail & 0x02)) &&
                                                        <GraphSettingsComponent
                                                            overViewStyle={overViewStyle}
                                                            borderColor={'#eed202'}
                                                            modalParagraphStyle={modalParagraphStyle}
                                                            handleGraphSettingsChange={handleGraphSettingsChange}
                                                            changeGroup={"flowBTotal"}
                                                            isPressure={false}
                                                            visible={settings.flowBTotalVisible}
                                                            unit={settings.flowBTotalUnit}
                                                            minState={settings.flowBTotalMinState}
                                                            minValue={settings.flowBTotalMinValue}
                                                            maxState={settings.flowBTotalMaxState}
                                                            maxValue={settings.flowBTotalMaxValue}
                                                            valueAs={null}
                                                            axisPosition={settings.flowBTotalAxisPosition}
                                                            lineWidth={settings.flowBTotalLineWidth}
                                                            graphType={settings.flowBTotalGraphType}
                                                            color={settings.flowBTotalColor}
                                                            labelSize={settings.flowBTotalLabelSize}
                                                            startDate={startDate}
                                                            loggers={loggers}
                                                            channel={"Totaliser B"}
                                                            displayUnits={flowUnits}
                                                            isTotaliser={true}
                                                            totalPerDay={settings.flowBTotalPerDay}
                                                            companyDefault={createCompanyDefault}
                                                            defaultColor={settings.flowBTotalDefaultColor}
                                                            pressureUnits={pressureUnits}
                                                        />}
                                                </div>}
                                            {selectedTab === 2 && graphTemplate.channelsAvail < 3 && <div>
                                                {/*Consumption A*/}

                                                {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0]?.channelsAvail & 0x01)) &&
                                                    <GraphSettingsComponent
                                                        overViewStyle={overViewStyle}
                                                        borderColor={colors.teal[0]}
                                                        modalParagraphStyle={modalParagraphStyle}
                                                        handleGraphSettingsChange={handleGraphSettingsChange}
                                                        changeGroup={"flowAConsumption"}
                                                        isPressure={false}
                                                        visible={settings.flowAConsumptionVisible}
                                                        unit={settings.flowAConsumptionUnit}
                                                        minState={settings.flowAConsumptionMinState}
                                                        minValue={settings.flowAConsumptionMinValue}
                                                        maxState={settings.flowAConsumptionMaxState}
                                                        maxValue={settings.flowAConsumptionMaxValue}
                                                        valueAs={null}
                                                        axisPosition={settings.flowAConsumptionAxisPosition}
                                                        lineWidth={settings.flowAConsumptionLineWidth}
                                                        graphType={settings.flowAConsumptionGraphType}
                                                        color={settings.flowAConsumptionColor}
                                                        labelSize={settings.flowAConsumptionLabelSize}
                                                        startDate={startDate}
                                                        loggers={loggers}
                                                        channel={"Consumption A"}
                                                        displayUnits={flowUnits}
                                                        totalPerDay={false}
                                                        isTotaliser={false}
                                                        companyDefault={createCompanyDefault}
                                                        defaultColor={settings.flowAConsumptionDefaultColor}
                                                        pressureUnits={pressureUnits}
                                                    />
                                                }



                                                {/*Consumption B*/}

                                                {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0]?.channelsAvail & 0x02)) &&
                                                    <GraphSettingsComponent
                                                        overViewStyle={overViewStyle}
                                                        borderColor={colors.pink[0]}
                                                        modalParagraphStyle={modalParagraphStyle}
                                                        handleGraphSettingsChange={handleGraphSettingsChange}
                                                        changeGroup={"flowBConsumption"}
                                                        isPressure={false}
                                                        visible={settings.flowBConsumptionVisible}
                                                        unit={settings.flowBConsumptionUnit}
                                                        minState={settings.flowBConsumptionMinState}
                                                        minValue={settings.flowBConsumptionMinValue}
                                                        maxState={settings.flowBConsumptionMaxState}
                                                        maxValue={settings.flowBConsumptionMaxValue}
                                                        valueAs={null}
                                                        axisPosition={settings.flowBConsumptionAxisPosition}
                                                        lineWidth={settings.flowBConsumptionLineWidth}
                                                        graphType={settings.flowBConsumptionGraphType}
                                                        color={settings.flowBConsumptionColor}
                                                        labelSize={settings.flowBConsumptionLabelSize}
                                                        startDate={startDate}
                                                        loggers={loggers}
                                                        channel={"Consumption B"}
                                                        displayUnits={flowUnits}
                                                        totalPerDay={false}
                                                        isTotaliser={false}
                                                        companyDefault={createCompanyDefault}
                                                        defaultColor={settings.flowBConsumptionDefaultColor}
                                                        pressureUnits={pressureUnits}
                                                    />}
                                            </div>}
                                            {selectedTab === 3 && graphTemplate.channelsAvail > 3 && <div>
                                                {/*Consumption A*/}

                                                {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0]?.channelsAvail & 0x01)) &&
                                                    <GraphSettingsComponent
                                                        overViewStyle={overViewStyle}
                                                        borderColor={colors.teal[0]}
                                                        modalParagraphStyle={modalParagraphStyle}
                                                        handleGraphSettingsChange={handleGraphSettingsChange}
                                                        changeGroup={"flowAConsumption"}
                                                        isPressure={false}
                                                        visible={settings.flowAConsumptionVisible}
                                                        unit={settings.flowAConsumptionUnit}
                                                        minState={settings.flowAConsumptionMinState}
                                                        minValue={settings.flowAConsumptionMinValue}
                                                        maxState={settings.flowAConsumptionMaxState}
                                                        maxValue={settings.flowAConsumptionMaxValue}
                                                        valueAs={null}
                                                        axisPosition={settings.flowAConsumptionAxisPosition}
                                                        lineWidth={settings.flowAConsumptionLineWidth}
                                                        graphType={settings.flowAConsumptionGraphType}
                                                        color={settings.flowAConsumptionColor}
                                                        labelSize={settings.flowAConsumptionLabelSize}
                                                        startDate={startDate}
                                                        loggers={loggers}
                                                        channel={"Consumption A"}
                                                        displayUnits={flowUnits}
                                                        totalPerDay={false}
                                                        isTotaliser={false}
                                                        companyDefault={createCompanyDefault}
                                                        defaultColor={settings.flowAConsumptionDefaultColor}
                                                        pressureUnits={pressureUnits}
                                                    />
                                                }



                                                {/*Consumption B*/}

                                                {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0]?.channelsAvail & 0x02)) &&
                                                    <GraphSettingsComponent
                                                        overViewStyle={overViewStyle}
                                                        borderColor={colors.pink[0]}
                                                        modalParagraphStyle={modalParagraphStyle}
                                                        handleGraphSettingsChange={handleGraphSettingsChange}
                                                        changeGroup={"flowBConsumption"}
                                                        isPressure={false}
                                                        visible={settings.flowBConsumptionVisible}
                                                        unit={settings.flowBConsumptionUnit}
                                                        minState={settings.flowBConsumptionMinState}
                                                        minValue={settings.flowBConsumptionMinValue}
                                                        maxState={settings.flowBConsumptionMaxState}
                                                        maxValue={settings.flowBConsumptionMaxValue}
                                                        valueAs={null}
                                                        axisPosition={settings.flowBConsumptionAxisPosition}
                                                        lineWidth={settings.flowBConsumptionLineWidth}
                                                        graphType={settings.flowBConsumptionGraphType}
                                                        color={settings.flowBConsumptionColor}
                                                        labelSize={settings.flowBConsumptionLabelSize}
                                                        startDate={startDate}
                                                        loggers={loggers}
                                                        channel={"Consumption B"}
                                                        displayUnits={flowUnits}
                                                        totalPerDay={false}
                                                        isTotaliser={false}
                                                        companyDefault={createCompanyDefault}
                                                        defaultColor={settings.flowBConsumptionDefaultColor}
                                                        pressureUnits={pressureUnits}
                                                    />}
                                            </div>}


                                        </div>}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                            <div>
                                                {createCompanyDefault && <Button color="primary" style={{ marginRight: "10px" }} onClick={(): void => handlePostGraphTemplate()} variant="contained">Save Company Default</Button>}
                                                {!createCompanyDefault && <Button color="primary" style={{ marginRight: "10px" }} onClick={(): void => handlePostGraphTemplate()} variant="contained">Save User Template and Load onto Selected Logger</Button>}
                                                <Button color="error" variant="contained" onClick={(): void => closeGraphTemplateWindow()}>Exit</Button>
                                            </div>
                                        </div>
                                    </Modal.Footer>
                                </Modal>




                                <CardHeader color="primary" className="view view-cascade" style={{ marginTop: "80px" }}>
                                    <Grid container  >
                                        <Grid item lg={12} >
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ display: 'flex' }}>
                                                    {location && location.state && location.state.showBackButton &&
                                                        <IconButton
                                                            style={{ display: 'inline', marginTop: -14 }}
                                                            onClick={(): void => history.goBack()}
                                                            size="large"><ArrowBackIcon style={{ fill: "white" }} />
                                                        </IconButton>
                                                    }

                                                    {location && location.state  &&
                                                        <div style={{ marginRight: "6px" }}>
                                                            Loggers:&nbsp; <br />
                                                            {location.state.showBackButton && loggers.map((logger: APILoggerDetail) => {
                              
                                                                return <Chip key={logger.LoggerId} style={{ marginRight: "5px" }}
                                                                    label={logger.Serial}  onDelete={(): void => removeLogger(logger.Serial)} color="primary" />
                                                            })
                                                            }
                                                            {!location.state.showBackButton && loggers.map((logger: APILoggerDetail) => {
                                                                return <Chip key={logger.LoggerId} style={{ marginRight: "5px" }}
                                                                    label={logger.Serial} color="primary" onClick={(): void => { deviceOverviewClick(logger.Serial, logger.Model) }} />
                                                            })
                                                            }

                                                            {loggers.length == 0 &&
                                                                <div style={{ display: 'inline', fontWeight: "bold" }}>None selected!</div>
                                                            }
                                                        </div>
                                                    }
                                                    

                                                </div>
                                                
                                                <div style={{ margin: "auto 10px auto 0", display: "flex" }}>
                                                    <div>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", marginRight: "30px" }}>
                                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                                <Button sx={{ backgroundColor: theme.palette.grey.main, color: theme.palette.grey.contrastText }} style={{ margin: "0 0 5px 0 " }} onClick={() => setGraphHeight(`${parseInt(graphHeight.slice(0, 2)) - 5}vh `)} variant="contained"><ArrowForwardIcon style={{ transform: "rotate(-90deg)" }} />Graph</Button>
                                                                <Button sx={{ backgroundColor: theme.palette.grey.main, color: theme.palette.grey.contrastText }} variant="contained" onClick={() => setGraphHeight(`${parseInt(graphHeight.slice(0, 2)) + 5}vh `)}><ArrowForwardIcon style={{ transform: "rotate(90deg)" }} />Graph</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex", padding: " 5px 0 0 10px", margin: "5px 30px 5px 0" }}>
                                                        {hasFlow && <div style={{ marginRight: "10px" }}>
                                                            <p style={{ marginTop: "auto 0" }}>Flow</p>
                                                            <div style={{ display: "flex", justifyContent: "center", marginTop: "-25px" }}>
                                                                <Checkbox checked={showFlow} onClick={(e): void => handleToggleFlow(e, "Flow")} />
                                                            </div>
                                                        </div>}
                                                        {hasPressureChannels && <div style={{ marginRight: "10px" }}>
                                                            <p style={{ marginTop: "auto 0" }}>{window.location.hostname.includes('zonelog.net') ? "Pressure" : "Analog"}</p>
                                                            <div style={{ display: "flex", justifyContent: "center", marginTop: "-25px" }}>
                                                                <Checkbox checked={showPressure} onClick={(e): void => handleToggleFlow(e, "Pressure")} />
                                                            </div>
                                                        </div>}
                                                        {hasTotal && <div style={{ marginRight: "10px" }}>
                                                            <p style={{ marginTop: "auto 0" }}>Totaliser</p>
                                                            <div style={{ display: "flex", justifyContent: "center", marginTop: "-25px" }}>
                                                                <Checkbox checked={showTotal} onClick={(e): void => handleToggleFlow(e, "Total")} />
                                                            </div>
                                                        </div>}
                                                        {hasConsumption && <div style={{ marginRight: "10px" }}>
                                                            <p style={{ marginTop: "auto 0" }}>Consumption</p>
                                                            <div style={{ display: "flex", justifyContent: "center", marginTop: "-25px" }}>
                                                                <Checkbox checked={showConsumption} onClick={(e): void => handleToggleFlow(e, "Consumption")} />
                                                            </div>
                                                        </div>}
                                                    </div>
                                                    <div style={{ margin: "auto 0", minWidth: "450px" }}>
                                                        {loggers.length > 1 && <Button variant="contained" style={{ marginRight: "10px" }} onClick={(): void => handleCombineY()} sx={{ backgroundColor: theme.palette.grey.main, color: theme.palette.grey.contrastText }}>{combineY ? 'Seperate Y Axis For Channels' : 'Combine Y Axis for Channels'}</Button>}
                                                        <Button color="secondary" variant="contained" onClick={(): void => resetZoom()} style={{ marginRight: "10px" }} startIcon={<RefreshRoundedIcon />}>Refresh</Button>
                                                        <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={toggleExportModal}>Export Data</Button>
                                                        {unitsAndValues.length > 0 && <Button disabled={combineY}  color="warning" style={{ marginRight: "10px" }} variant="contained" onClick={(): void => toggleCreateTemplateOpen()}>Edit Graph Settings</Button>}
                                                    </div>

                                                </div>
                                            </div>

                                        </Grid>

                                    </Grid>
                                    <DateRange startDate={startDate} endDate={endDate} rainfall={false} onDateChange={(startDateChange, endDateChange, rainfall): void => onDateChange(startDateChange, endDateChange, rainfall)} type="date" showButton={showButton} handleRedrawClick={handleRedrawClick} />
                                    
                                </CardHeader>

                                {(graphData.length < 1 && !noLoggersInGroup) && <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><h4>No Data available between the dates selected, try expanding the data range</h4></div>}
                                {noLoggersInGroup && <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><h4>No Sites exist in this group</h4></div>}
                                {graphData.length > 0 && startDate && endDate &&

                                    <div>
                                        {combineY &&
                                            <div style={{display: "flex", justifyContent: "center", width: "100%", marginTop: "10px"} }>
                                                <div style={{display: "flex", justifyContent: "center"} }>
                                                    <p style={{ margin: "5px 20px 0 0" }}>For Combining Y Axis, Custom Graph Settings cannot be applied, instead the following convention will apply:</p>

                                                    { showRelativeChannelDisplay() }
                                                   

                                                </div>
                                            </div>}
                                        <div>

                                            <Chart
                                                dataSource={graphData}
                                                ref={chartRef}
                                                height={graphHeight}
                                                width="100%"
                                                //customizePoint={customizePoint }
                                            >
                                                <CommonSeriesSettings
                                                    argumentField="date"
                                                    ignoreEmptyPoints={true}
                                                    type={'line'}
                                                >
                                                    
                                                    <Point size={15} />
                                                    <Font weight={900} size={40} />
                                                </CommonSeriesSettings>
                                                <CommonAxisSettings
                                                    valueMarginsEnabled={true}
                                                    minValueMargin={ashridgeDefaultInUse || combineY ? 0.1 : 0}    //add extra 10% to show a 'gap'
                                                    maxValueMargin={ashridgeDefaultInUse || combineY ? 0.1 : 0}     //add extra 10% to show a 'gap'
                                                />

                                                {graphData.length > 0 && loggers.map((logger) => {
                                                    return renderLoggerSeries(logger)
                                                })}

                                                {unitsAndValues && alarms && loggers.map((logger) => {
                                                    return renderValueAxis(logger)
                                                })}

                                                {/*{location && location.state && location.state.site &&*/}
                                                {/*    renderSiteSeries()}*/}


                                                <ArgumentAxis
                                                    width={2}
                                                    allowDecimals={false}
                                                    argumentType="date"
                                                    minValueMargin={combineY ? 0.05 : 0}
                                                    maxValueMargin={combineY ? 0.05 : 0}
                                                    visualRange={visualRange}
                                                    color={'#000000'}
                                                    discreteAxisDivisionMode="crossLabels"

                                                >
                                                    <Label font={{ size: 16, color: '#000000' }} format={(date: Date): string => customArgument(date)} />
                                                    <GridChart visible={true} />
                                                </ArgumentAxis>
                                                <Export enabled={true} />
                                                <Crosshair
                                                    enabled={true}
                                                    color="#949494"
                                                    width={3}
                                                    dashStyle="dot"
                                                >
                                                    <Label
                                                        visible={true}
                                                        backgroundColor="#949494"
                                                    >
                                                        <Font
                                                            color="#fff"
                                                            size={14}
                                                        />
                                                    </Label>
                                                </Crosshair>
                                                <ZoomAndPan
                                                    allowMouseWheel={false}
                                                    argumentAxis="both"
                                                    valueAxis="none"
                                                    dragToZoom={true}

                                                />
                                                <Legend
                                                    visible={false}
                                                    position="outside"
                                                    horizontalAlignment="center"
                                                    verticalAlignment="top"

                                                />
                                                {!createTemplateOpen && !toggling && <Tooltip enabled={true} contentRender={renderTooltip} offsetY={-200} />}
                                            </Chart>
                                        
                                            </div>
                                        <div style={{ marginTop: "40px" }}>
                                            <CardHeader color="primary">
                                                <h4 style={{ display: "flex", justifyContent: "center" }}>Range Finder</h4>
                                                <GraphRangeSelector
                                                    graphData={normalisedData}
                                                    updateVisualRange={updateVisualRange}
                                                    startDate={visualRange.startValue}
                                                    endDate={visualRange.endValue}
                                                    loggers={loggers}
                                                    renderRangeFinderSeries={renderRangeFinderSeries}

                                                >

                                                </GraphRangeSelector>
                                            </CardHeader>
                                        </div>
                                    </div>}
                            </div>}
                    </div>}
            </> :
                (<Redirect push to={{
                    pathname: redirectPath,
                    state: redirectProps
                }} />)}
        </>
    )
}
export default MultiLoggerGraphV2