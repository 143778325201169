export interface GraphSettings {

    //A Flow
    flowAVisible: boolean
    flowAUnit: string
    flowAMinState: string
    flowAMaxState: string
    flowAMinValue: number
    flowAMaxValue: number
    flowAOriginalMax: number
    flowAOriginalMin: number
    flowAAxisPosition: string
    flowAGraphType: string
    flowALineWidth: number
    flowAColor: string
    flowADefaultColor: boolean
    flowALabelSize: number

    //A Total

    flowATotalVisible: boolean
    flowATotalPerDay: boolean
    flowATotalUnit: string
    flowATotalMinState: string
    flowATotalMaxState: string
    flowATotalMinValue: number
    flowATotalMaxValue: number
    flowATotalAxisPosition: string
    flowATotalGraphType: string
    flowATotalLineWidth: number
    flowATotalColor: string
    flowATotalDefaultColor: boolean
    flowATotalLabelSize: number

    //A Consumption

    flowAConsumptionVisible: boolean
    flowAConsumptionPerDay: boolean
    flowAConsumptionUnit: string
    flowAConsumptionMinState: string
    flowAConsumptionMaxState: string
    flowAConsumptionMinValue: number
    flowAConsumptionMaxValue: number
    flowAConsumptionOriginalMax: number
    flowAConsumptionOriginalMin: number
    flowAConsumptionAxisPosition: string
    flowAConsumptionGraphType: string
    flowAConsumptionLineWidth: number
    flowAConsumptionColor: string
    flowAConsumptionDefaultColor: boolean
    flowAConsumptionLabelSize: number

    ///flow B

    flowBVisible: boolean
    flowBUnit: string
    flowBMinState: string
    flowBMaxState: string
    flowBMinValue: number
    flowBMaxValue: number
    flowBOriginalMax: number
    flowBOriginalMin: number
    flowBAxisPosition: string
    flowBGraphType: string
    flowBLineWidth: number
    flowBColor: string
    flowBDefaultColor: boolean
    flowBLabelSize: number


    // B totaliser

    flowBTotalVisible: boolean
    flowBTotalPerDay: boolean
    flowBTotalUnit: string
    flowBTotalMinState: string
    flowBTotalMaxState: string
    flowBTotalMinValue: number
    flowBTotalMaxValue: number
    flowBTotalAxisPosition: string
    flowBTotalGraphType: string
    flowBTotalLineWidth: number
    flowBTotalColor: string
    flowBTotalDefaultColor: boolean
    flowBTotalLabelSize: number


    //B comsumption


    flowBConsumptionVisible: boolean
    flowBConsumptionPerDay: boolean
    flowBConsumptionUnit: string
    flowBConsumptionMinState: string
    flowBConsumptionMaxState: string
    flowBConsumptionMinValue: number
    flowBConsumptionMaxValue: number
    flowBConsumptionOriginalMax: number
    flowBConsumptionOriginalMin: number
    flowBConsumptionAxisPosition: string
    flowBConsumptionGraphType: string
    flowBConsumptionLineWidth: number
    flowBConsumptionColor: string
    flowBConsumptionDefaultColor: boolean
    flowBConsumptionLabelSize: number


    /// Pressure C


    pressureCVisible: boolean
    pressureCIsNotPressure: boolean
    pressureCUnit: string
    pressureCOriginalUnit: string
    pressureCMinState: string
    pressureCMaxState: string
    pressureCMinValue: number
    pressureCMaxValue: number
    pressureCOriginalMax: number
    pressureCOriginalMin: number
    pressureCValueAs: string
    pressureCAxisPosition: string
    pressureCGraphType: string
    pressureCLineWidth: number
    pressureCColor: string
    pressureCDefaultColor: boolean
    pressureCLabelSize: number

    /// Pressure D

    pressureDVisible: boolean
    pressureDIsNotPressure: boolean
    pressureDUnit: string
    pressureDOriginalUnit: string
    pressureDMinState: string
    pressureDMaxState: string
    pressureDMinValue: number
    pressureDMaxValue: number
    pressureDOriginalMax: number
    pressureDOriginalMin: number
    pressureDValueAs: string
    pressureDAxisPosition: string
    pressureDGraphType: string
    pressureDLineWidth: number
    pressureDColor: string
    pressureDDefaultColor: boolean
    pressureDLabelSize: number


    /// Pressure E

    pressureEVisible: boolean
    pressureEIsNotPressure: boolean
    pressureEUnit: string
    pressureEOriginalUnit: string
    pressureEMinState: string
    pressureEMaxState: string
    pressureEMinValue: number
    pressureEMaxValue: number
    pressureEOriginalMax: number
    pressureEOriginalMin: number
    pressureEValueAs: string
    pressureEAxisPosition: string
    pressureEGraphType: string
    pressureELineWidth: number
    pressureEColor: string
    pressureEDefaultColor: boolean
    pressureELabelSize: number
}

export interface Intervals {
    a: number | null
    b: number | null
    c: number | null
    d: number | null
    e: number | null
}

export interface GraphTemplate {
    serial: number | null
    siteId: number | null
    companyId: string
    userId: number
    alarms: Array
    isAshridgeDefault: boolean
    graphSettings: GraphSettings
    channelsAvail: number
    intervals: Intervals
}


export const graphTemplateModel = {
    serial: null,
    siteId: null,
    isDefault: false,
    fkCompanyId: parseInt(sessionStorage.getItem('companyId')),
    userId: null,
    isAshridgeDefault: false,
    channelsAvail: 0,
    alarms: [],
    intervals: {a: null, b: null, c: null, d: null, e: null},
    graphSettings: {

        //flowA
        flowAVisible: true,
        flowAUnit: "l/s",
        flowAMinState: "default",
        flowAMaxState: "default",
        flowAMinValue: Number.MAX_VALUE,
        flowAMaxValue: Number.NEGATIVE_INFINITY,
        flowAOriginalMin: Number.MAX_VALUE,
        flowAOriginalMax: Number.NEGATIVE_INFINITY,
        flowAAxisPosition: "left",
        flowAGraphType: "spline",
        flowALineWidth: 2,
        flowAColor: "#B22222",
        flowADefaultColor: false,
        flowALabelSize: 12,

        //Total A
        flowATotalVisible: true,
        flowATotalPerDay: true,
        flowATotalUnit: "l",
        flowATotalMinState: "default",
        flowATotalMaxState: "default",
        flowATotalMinValue: Number.MAX_VALUE,
        flowATotalMaxValue: Number.NEGATIVE_INFINITY,     
        flowATotalAxisPosition: "left",
        flowATotalGraphType: "bar",
        flowATotalLineWidth: 2,
        flowATotalColor: "#B8860B",
        flowATotalDefaultColor: false,
        flowATotalLabelSize: 12,


        //Consumption A
        flowAConsumptionVisible: window.location.hostname.includes('zonelog.net') ? false : true,
        flowAConsumptionPerDay: false,
        flowAConsumptionUnit: "m3",
        flowAConsumptionMinState: "default",
        flowAConsumptionMaxState: "default",
        flowAConsumptionMinValue: Number.MAX_VALUE,
        flowAConsumptionMaxValue: Number.NEGATIVE_INFINITY,
        flowAConsumptionOriginalMin: Number.MAX_VALUE,
        flowAConsumptionOriginalMax: Number.NEGATIVE_INFINITY,
        flowAConsumptionAxisPosition: "right",
        flowAConsumptionGraphType: "bar",
        flowAConsumptionLineWidth: 50,
        flowAConsumptionColor: "#008080",
        flowAConsumptionDefaultColor: false,
        flowAConsumptionLabelSize: 12,

        //flow B
        flowBVisible: true,
        flowBUnit: "l/s",
        flowBMinState: "default",
        flowBMaxState: "default",
        flowBMinValue: Number.MAX_VALUE,
        flowBMaxValue: Number.NEGATIVE_INFINITY,
        flowBOriginalMin: Number.MAX_VALUE,
        flowBOriginalMax: Number.NEGATIVE_INFINITY,
        flowBAxisPosition: "right",
        flowBGraphType: "spline",
        flowBLineWidth: 2,
        flowBColor: "#006400",
        flowBDefaultColor: false,
        flowBLabelSize: 12,


        //B total
        flowBTotalVisible: true,
        flowBTotalPerDay: true,
        flowBTotalUnit: "l",
        flowBTotalMinState: "default",
        flowBTotalMaxState: "default",
        flowBTotalMinValue: Number.MAX_VALUE,
        flowBTotalMaxValue: Number.NEGATIVE_INFINITY,
        flowBTotalAxisPosition: "right",
        flowBTotalGraphType: "bar",
        flowBTotalLineWidth: 50,
        flowBTotalColor: "#8B4513",
        flowBTotalDefaultColor: false,
        flowBTotalLabelSize: 12,

        //Consumption B
        flowBConsumptionVisible: window.location.hostname.includes('zonelog.net') ? false : true,
        flowBConsumptionPerDay: false,
        flowBConsumptionUnit: "m3",
        flowBConsumptionMinState: "default",
        flowBConsumptionMaxState: "default",
        flowBConsumptionMinValue: Number.MAX_VALUE,
        flowBConsumptionMaxValue: Number.NEGATIVE_INFINITY,
        flowBConsumptionOriginalMin: Number.MAX_VALUE,
        flowBConsumptionOriginalMax: Number.NEGATIVE_INFINITY,
        flowBConsumptionAxisPosition: "right",
        flowBConsumptionGraphType: "bar",
        flowBConsumptionLineWidth: 50,
        flowBConsumptionColor: "#FF69B4",
        flowBConsumptionDefaultColor: false,
        flowBConsumptionLabelSize: 12,

        //pressure C
        pressureCVisible: true,
        pressureCIsNotPressure: false,
        pressureCUnit: "bar",
        pressureCOriginalUnit: "",
        pressureCMinState: "default",
        pressureCMaxState: "default",
        pressureCMinValue: Number.MAX_VALUE,
        pressureCMaxValue: Number.NEGATIVE_INFINITY,
        pressureCOriginalMin: Number.MAX_VALUE,
        pressureCOriginalMax: Number.NEGATIVE_INFINITY,
        pressureCValueAs: "mean",
        pressureCAxisPosition: "left",
        pressureCGraphType: "spline",
        pressureCLineWidth: 2,
        pressureCColor: "#0000CD",
        pressureCDefaultColor: false,
        pressureCLabelSize: 12,

        //pressure D
        pressureDVisible: true,
        pressureDIsNotPressure: false,
        pressureDUnit: "bar",
        pressureDOriginalUnit: "",
        pressureDMinState: "default",
        pressureDMaxState: "default",
        pressureDMinValue: Number.MAX_VALUE,
        pressureDMaxValue: Number.NEGATIVE_INFINITY,
        pressureDOriginalMin: Number.MAX_VALUE,
        pressureDOriginalMax: Number.NEGATIVE_INFINITY,
        pressureDValueAs: "mean",
        pressureDAxisPosition: "right",
        pressureDGraphType: "spline",
        pressureDLineWidth: 2,
        pressureDColor: "#FF6F00",
        pressureDDefaultColor: false,
        pressureDLabelSize: 12,

        //pressure E
        pressureEVisible: true,
        pressureEIsNotPressure: false,
        pressureEUnit: "bar",
        pressureEOriginalUnit: "",
        pressureEMinState: "default",
        pressureEMaxState: "default",
        pressureEMinValue: Number.MAX_VALUE,
        pressureEMaxValue: Number.NEGATIVE_INFINITY,
        pressureEOriginalMin: Number.MAX_VALUE,
        pressureEOriginalMax: Number.NEGATIVE_INFINITY,
        pressureEValueAs: "mean",
        pressureEAxisPosition: "right",
        pressureEGraphType: "spline",
        pressureELineWidth: 2,
        pressureEColor: "#800080",
        pressureEDefaultColor: false,
        pressureELabelSize: 12,
    }
}

const deepCopyGraphSettings = (settings: GraphSettings): GraphSettings => {

    return {
        ...settings,

        //flowA
        flowAVisible: settings.flowAVisible,
        flowAUnit: settings.flowAUnit,
        flowAMinState: settings.flowAMinState,
        flowAMaxState: settings.flowAMaxState,
        flowAMinValue: settings.flowAMinValue,
        flowAMaxValue: settings.flowAMaxValue,
        flowAOriginalMin: settings.flowAOriginalMin,
        flowAOriginalMax: settings.flowAOriginalMax,
        flowAAxisPosition: settings.flowAAxisPosition,
        flowAGraphType: settings.flowAGraphType,
        flowALineWidth: settings.flowALineWidth,
        flowAColor: settings.flowAColor,
        flowALabelSize: settings.flowALabelSize,
        flowADefaultColor: settings.flowADefaultColor,

        //totalA
        flowATotalVisible: settings.flowATotalVisible,
        flowATotalPerDay: settings.flowATotalPerDay,
        flowATotalUnit: settings.flowATotalUnit,
        flowATotalMinState: settings.flowATotalMinState,
        flowATotalMaxState: settings.flowATotalMaxState,
        flowATotalMinValue: settings.flowATotalMinValue,
        flowATotalMaxValue: settings.flowATotalMaxValue,
        flowATotalAxisPosition: settings.flowATotalAxisPosition,
        flowATotalGraphType: settings.flowATotalGraphType,
        flowATotalLineWidth: settings.flowATotalLineWidth,
        flowATotalColor: settings.flowATotalColor,
        flowATotalLabelSize: settings.flowATotalLabelSize,
        flowATotalDefaultColor: settings.flowATotalDefaultColor,

        //Consumption A
        flowAConsumptionVisible: settings.flowAConsumptionVisible,
        flowAConsumptionPerDay: settings.flowAConsumptionPerDay,
        flowAConsumptionUnit: settings.flowAConsumptionUnit,
        flowAConsumptionMinState: settings.flowAConsumptionMinState,
        flowAConsumptionMaxState: settings.flowAConsumptionMaxState,
        flowAConsumptionMinValue: settings.flowAConsumptionMinValue,
        flowAConsumptionMaxValue: settings.flowAConsumptionMaxValue,
        flowAConsumptionOriginalMin: settings.flowAConsumptionOriginalMin,
        flowAConsumptionOriginalMax: settings.flowAConsumptionOriginalMax,
        flowAConsumptionAxisPosition: settings.flowAConsumptionAxisPosition,
        flowAConsumptionGraphType: settings.flowAConsumptionGraphType,
        flowAConsumptionLineWidth: settings.flowAConsumptionLineWidth,
        flowAConsumptionColor: settings.flowAConsumptionColor,
        flowAConsumptionLabelSize: settings.flowAConsumptionLabelSize,
        flowAConsumptionDefaultColor: settings.flowAConsumptionDefaultColor,


        //flow B
        flowBVisible: settings.flowBVisible,
        flowBUnit: settings.flowBUnit,
        flowBMinState: settings.flowBMinState,
        flowBMaxState: settings.flowBMaxState,
        flowBMinValue: settings.flowBMinValue,
        flowBMaxValue: settings.flowBMaxValue,
        flowBOriginalMin: settings.flowBOriginalMin,
        flowBOriginalMax: settings.flowBOriginalMax,
        flowBAxisPosition: settings.flowBAxisPosition,
        flowBGraphType: settings.flowBGraphType,
        flowBLineWidth: settings.flowBLineWidth,
        flowBColor: settings.flowBColor,
        flowBLabelSize: settings.flowBLabelSize,
        flowBDefaultColor: settings.flowBDefaultColor,

        //total B
        flowBTotalVisible: settings.flowBTotalVisible,
        flowBTotalPerDay: settings.flowBTotalPerDay,
        flowBTotalUnit: settings.flowBTotalUnit,
        flowBTotalMinState: settings.flowBTotalMinState,
        flowBTotalMaxState: settings.flowBTotalMaxState,
        flowBTotalMinValue: settings.flowBTotalMinValue,
        flowBTotalMaxValue: settings.flowBTotalMaxValue,
        flowBTotalAxisPosition: settings.flowBTotalAxisPosition,
        flowBTotalGraphType: settings.flowBTotalGraphType,
        flowBTotalLineWidth: settings.flowBTotalLineWidth,
        flowBTotalColor: settings.flowBTotalColor,
        flowBTotalLabelSize: settings.flowBTotalLabelSize,
        flowBTotalDefaultColor: settings.flowBTotalDefaultColor,

        //Consumption B
        flowBConsumptionVisible: settings.flowBConsumptionVisible,
        flowBConsumptionPerDay: settings.flowBConsumptionPerDay,
        flowBConsumptionUnit: settings.flowBConsumptionUnit,
        flowBConsumptionMinState: settings.flowBConsumptionMinState,
        flowBConsumptionMaxState: settings.flowBConsumptionMaxState,
        flowBConsumptionMinValue: settings.flowBConsumptionMinValue,
        flowBConsumptionMaxValue: settings.flowBConsumptionMaxValue,
        flowBConsumptionOriginalMin: settings.flowBConsumptionOriginalMin,
        flowBConsumptionOriginalMax: settings.flowBConsumptionOriginalMax,
        flowBConsumptionAxisPosition: settings.flowBConsumptionAxisPosition,
        flowBConsumptionGraphType: settings.flowBConsumptionGraphType,
        flowBConsumptionLineWidth: settings.flowBConsumptionLineWidth,
        flowBConsumptionColor: settings.flowBConsumptionColor,
        flowBConsumptionLabelSize: settings.flowBConsumptionLabelSize,
        flowBConsumptionDefaultColor: settings.flowBConsumptionDefaultColor,


        //pressure C
        pressureCVisible: settings.pressureCVisible,
        pressureCIsNotPressure: settings.pressureCIsNotPressure,
        pressureCUnit: settings.pressureCUnit,
        pressureCOriginalUnit: settings.pressureCOriginalUnit,
        pressureCMinState: settings.pressureCMinState,
        pressureCMaxState: settings.pressureCMaxState,
        pressureCMinValue: settings.pressureCMinValue,
        pressureCMaxValue: settings.pressureCMaxValue,
        pressureCOriginalMin: settings.pressureCOriginalMin,
        pressureCOriginalMax: settings.pressureCOriginalMax,
        pressureCValueAs: settings.pressureCValueAs,
        pressureCAxisPosition: settings.pressureCAxisPosition,
        pressureCGraphType: settings.pressureCGraphType,
        pressureCLineWidth: settings.pressureCLineWidth,
        pressureCColor: settings.pressureCColor,
        pressureCLabelSize: settings.pressureCLabelSize,
        pressureCDefaultColor: settings.pressureCDefaultColor,

        //Pressure D
        pressureDVisible: settings.pressureDVisible,
        pressureDIsNotPressure: settings.pressureDIsNotPressure,
        pressureDUnit: settings.pressureDUnit,
        pressureDOriginalUnit: settings.pressureDOriginalUnit,
        pressureDMinState: settings.pressureDMinState,
        pressureDMaxState: settings.pressureDMaxState,
        pressureDMinValue: settings.pressureDMinValue,
        pressureDMaxValue: settings.pressureDMaxValue,
        pressureDOriginalMin: settings.pressureDOriginalMin,
        pressureDOriginalMax: settings.pressureDOriginalMax,
        pressureDValueAs: settings.pressureDValueAs,
        pressureDAxisPosition: settings.pressureDAxisPosition,
        pressureDGraphType: settings.pressureDGraphType,
        pressureDLineWidth: settings.pressureDLineWidth,
        pressureDColor: settings.pressureDColor,
        pressureDLabelSize: settings.pressureDLabelSize,
        pressureDDefaultColor: settings.pressureDDefaultColor,

        //Pressure E
        pressureEVisible: settings.pressureEVisible,
        pressureEIsNotPressure: settings.pressureEIsNotPressure,
        pressureEUnit: settings.pressureEUnit,
        pressureEOriginalUnit: settings.pressureEOriginalUnit,
        pressureEMinState: settings.pressureEMinState,
        pressureEMaxState: settings.pressureEMaxState,
        pressureEMinValue: settings.pressureEMinValue,
        pressureEMaxValue: settings.pressureEMaxValue,
        pressureEOriginalMin: settings.pressureEOriginalMin,
        pressureEOriginalMax: settings.pressureEOriginalMax,
        pressureEValueAs: settings.pressureEValueAs,
        pressureEAxisPosition: settings.pressureEAxisPosition,
        pressureEGraphType: settings.pressureEGraphType,
        pressureELineWidth: settings.pressureELineWidth,
        pressureEColor: settings.pressureEColor,
        pressureELabelSize: settings.pressureELabelSize,
        pressureEDefaultColor: settings.pressureEDefaultColor
    }
}

export const deepCopyAshridgeDefault = (template: GraphTemplate): GraphTemplate => {
    return {
        ...template,
        graphSettings: deepCopyGraphSettings(template.graphSettings),
    };
};