import React from 'react'
import { APILoggerDetail, buildAPILoggerDetail } from 'models/APILoggerDetail';
import { OverlayView } from "@react-google-maps/api"
import SiteDeviceChips from '../../views/Sites/SiteDeviceChips'
import Button from '@mui/material/Button';

interface Site {
    id: number;
    siteNameUserFriendly: string;
    siteName: string;
    created: Date;
    deleted: Date | null;
    Latitude: number | null;
    Longitude: number | null;
    //devices: Array<Device>;
    groups: Array<{ id: number; displayName: string }>;
    defaultSite: boolean;
    fkCompanyId: number;
    fkEASiteId: number | null;
    EASite: string;
    lastUpdate: Date | null;
    alarmState: number | null;
    alarmsEnabled: number | null;
    Loggers: Array<APILoggerDetail>;
    LastReading?: Date | null;
    HasActiveLoggers?: string;

}

interface Coordinates {
    lat: number
    lng: number
}


interface Props {
    tooltipData: Site
    mapCenter: Coordinates
    key: number
    handleChipClick: () => void
    onMouseOverTooltip: () => void
    onMouseLeaveTooltip: () => void
    mappedSiteData: Site
    onEditClick: () => void
    onViewClick: () => void
}

const GoogleMapsSiteTooltip: React.FC<Props> = ({tooltipData, mapCenter, key, handleChipClick, onMouseLeaveTooltip, onMouseOverTooltip, mappedSiteData, onEditClick, onViewClick }) => {
    return (
        <OverlayView
            key={key}
            position={mapCenter}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}

            >
                <div style={{ width: "300px", height: "220px", background: "#ffffff", boxShadow: "0 0 10px black", border: "1px solid black", margin: '-100px 0 0 -150px' }} onMouseOver={(e): void => onMouseOverTooltip(e)} onMouseLeave={(): void => onMouseLeaveTooltip()}>
                <div style={{ margin: "10px" }}>
                        {tooltipData.siteName && <p><b>Site Ref:</b> {tooltipData.siteName.length > 29 ? `${tooltipData.siteName.slice(0, 30)}...` : tooltipData.siteName}</p>}
                        {tooltipData?.siteNameUserFriendly && <p><b>Site Name:</b> {tooltipData?.siteNameUserFriendly.length > 28 ? `${tooltipData.siteNameUserFriendly.slice(0, 29)}...` : tooltipData.siteNameUserFriendly}</p>}
                        <div style={{ display: "flex" }}>
                            <p><b>Devices:</b></p>
                        {<SiteDeviceChips data={tooltipData?.loggers} deviceDetailsClick={handleChipClick} />}
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "10px" }}>
                        {sessionStorage.getItem('canConfig') === "true" && <Button variant="contained" color='warning' onClick={(e): void => { onEditClick(e, mappedSiteData.site) }}>Edit Site</Button>}
                            <Button variant="contained" onClick={(): void => onViewClick(mappedSiteData.site)}>View Site</Button>

                        </div>
                    </div>
                </div>
            </OverlayView>
    )
}
export default GoogleMapsSiteTooltip