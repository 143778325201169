import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import makeStyles from '@mui/styles/makeStyles';
// core components
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "../assets/img/sidebar-2.jpg";
import logo from "../assets/img/reactlogo.png";

import { portalVersion } from "variables/portalVersion"

import { AuthContext } from "../auth/authProvider.jsx";
import { CreateUrl, CallGetAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import LoadingOverlay from '@ronchalant/react-loading-overlay'
import Footer from "components/Navbars/footer.tsx";
// Notifications
//import ReactNotification from 'react-notifications-component'
//import 'react-notifications-component/dist/theme.css'
import ResetPassword from "components/Users/ResetPassword.tsx";

const useStyles = makeStyles(styles);

export default function Portal({ ...rest }) {
    
    const switchRoutes = (user) => {
       
        
        // if (!user || user.expired) {
        //     userManager.signinRedirect();
        //     return (
        //       <div>Redirecting...</div>
        //     );
        //   } else {
            return(
                <Switch>
                    {routes.map((prop, key) => {
                        if (user && prop.layout === "/portal" && prop.roles.includes(userLevel)) {
                            return (
                                <Route
                                    path={prop.layout + prop.path}
                                    component={prop.component}
                                    key={key}
                                />
                            );
                        }
                        return null;
                    })}
                    <Redirect from="/portal" to="/portal/dashboard" />
                </Switch>
            );


                //}
    }

    // styles
    const classes = useStyles();

    const fullScreen = {
        position: 'fixed',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        background: 'rgba(51, 51, 51, 0.7)',
        zIndex: 10,
    }
    // ref to help us initialize PerfectScrollbar on windows devices
    const mainPanel = React.createRef();
    // states and functions
    const [image, setImage] = React.useState(bgImage);
    const [color, setColor] = React.useState("blue");
    const [fixedClasses, setFixedClasses] = React.useState("dropdown");
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleImageClick = image => {
        setImage(image);
    };
    const handleColorClick = color => {
        setColor(color);
    };
    const handleFixedClick = () => {
        if (fixedClasses === "dropdown") {
            setFixedClasses("dropdown show");
        } else {
            setFixedClasses("dropdown");
        }
    };
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const getRoute = () => {
        return window.location.pathname !== "/portal/maps";
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };

    const [user, setUser] = useState(null);

    const [authorised, setAuthorised] = useState(false);

    const [firstLogin, setFirstLogin] = useState(false);

    const [apiFailed, setApiFailed] = useState(false);

    const [impersonation, setImpersonation] = useState(null);
    const [userLevel, setUserLevel] = useState("restricted");
    const [lockedOut, setLockedOut] = useState(false);
    const [unknownUser, setUknownUser] = useState(false);

    const authContext = useContext(AuthContext);
   
    const passwordReset = (newPassword) => {  // returns Promise<boolean>

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                UserId: parseInt(sessionStorage.getItem('userId') || '0'),
                UserGuid: sessionStorage.getItem('userGuid') || '',
//                oldPassword: "Passw0rd!",
                oldPassword: "",
                newPassword: newPassword,
            })
        };

        return new Promise(
            function (resolve) {
                CallPostAPI(CreateUrl('/api/aquaguard/UserPassword'), requestOptions)
                    .then((data) => {
                        resolve(true);
                    })

                    .catch(function (error) {
                        resolve(false);
                        console.log(error);
                    });
            }
        );
    }

    const resetDone = () => {
        setFirstLogin(false);
        setAuthorised(true);
        setLockedOut(false);
    }

    // initialize and destroy the PerfectScrollbar plugin
    useEffect(() => {
        if (localStorage.getItem('portalVersion') !== portalVersion) {

            window.sessionStorage.setItem('portalUpdate', "true")
            
        }
        authContext.getUser()
            .then(function (user) {
                
                window.sessionStorage.setItem('loginError', false);
                window.sessionStorage.setItem('unknownUser', false);


                if (user.profile.userlevel != 'identityadmin') {
                    CallGetAPI(CreateUrl('/api/aquaguard/ResolveIdentity?company=' + user.profile.company.toString() + '&user=' + user.profile.sub.toString() + "&impersonated=false"), {})
                        .then(data => {
                            console.log(data)
                            // Check if user is locked out
                            if (data.lockedOut) {
                                setLockedOut(true);
                                setAuthorised(true);
                            }
                            else {
                               // window.sessionStorage.clear()
                                window.sessionStorage.setItem('loginError', false);
                                //                window.localStorage.setItem('companyId', data.companyId);
                                //                window.localStorage.setItem('companyGuid', user.profile.company.toString());
                                //                window.localStorage.setItem('userId', data.userId);
                                window.sessionStorage.setItem('companyId', data.companyId);
                                window.sessionStorage.setItem('companyGuid', user.profile.company.toString());
                                window.sessionStorage.setItem('userId', data.userId);
                                window.sessionStorage.setItem('userLevel', user.profile.userlevel);
                                window.sessionStorage.setItem('userGuid', user.profile.sub.toString());
                                window.sessionStorage.setItem('filterGroupId', data.filterGroup ? data.filterGroup : '0');
                                window.sessionStorage.setItem('filterGroupName', data.filterGroupName ? data.filterGroupName : "");
                                window.sessionStorage.setItem('dashboardJson', data.dashboardJson ? data.dashboardJson : "");
                                window.sessionStorage.setItem('companyName', data.companyName);
                                window.sessionStorage.setItem("flowUnits", data.flowUnits);
                                window.sessionStorage.setItem("utcOffset", data.utcOffset > 0 ? "+" + data.utcOffset : data.utcOffset);
                                window.sessionStorage.setItem('favourites', data.favourites)
                                window.sessionStorage.setItem('inactiveTimeframe', data.inactiveTimeframe)
                                window.sessionStorage.setItem("canConfig", data.canConfig);
                                window.sessionStorage.setItem('graphTotaliser', data.graphTotaliser)
                                window.sessionStorage.setItem('userName', user.profile.name)
                                window.sessionStorage.setItem('language', data.language)
                                
                                localStorage.setItem('portalVersion', portalVersion)
                                window.sessionStorage.setItem('inactiveTimeframe', data.inactiveTimeframe ? data.inactiveTimeframe : "24 Hours")
                                setUserLevel(user.profile.userlevel);
                                setFirstLogin(data.firstLogin);

                                let bValidServer = data.validServer;
                                bValidServer = true; //QUICK FIX, AG CLOUD Returns False, as reverse proxy in place so it cant match the users registered servers
                                if (bValidServer) {
                                    setUser(user);
                                }
                                setAuthorised(true);
                            }

                        },
                            // Reject
                            (responsedata) => {
                                if (responsedata.status == 404)
                                {
                                    console.log('ResolveIdentity Not Found Error');
                                    window.sessionStorage.setItem('loginError', true);
                                    window.sessionStorage.setItem('unknownUser', true);

                                    setAuthorised(true);
                                    setUknownUser(true);
                                } else

                                setApiFailed(true);
                            }
                        )
                        .catch(function (error) {
                            console.log(error)
                        });
                }
                else {
                    if (window.sessionStorage.getItem('impersonateUser')) {
                        CallGetAPI(CreateUrl('/api/aquaguard/ResolveIdentity?company=' + window.sessionStorage.getItem('impersonateCompany') + '&user=' + window.sessionStorage.getItem('impersonateUser') + "&impersonated=true"), {})

                            .then(data => {
                                console.log(data)
                                window.sessionStorage.setItem('companyId', data.companyId);
                                window.sessionStorage.setItem('companyGuid', window.sessionStorage.getItem('impersonateCompany'));
                               
                                window.sessionStorage.setItem('userId', data.userId);
                                window.sessionStorage.setItem('userLevel', data.userRole);
                                window.sessionStorage.setItem('userGuid', window.sessionStorage.getItem('impersonateUser'));
                                window.sessionStorage.setItem('filterGroupId', data.filterGroup ? data.filterGroup : 0);
                                window.sessionStorage.setItem('filterGroupName', data.filterGroupName ? data.filterGroupName : "");
                                window.sessionStorage.setItem('dashboardJson', data.dashboardJson ? data.dashboardJson : "");
                                window.sessionStorage.setItem('companyName', data.companyName);
                                window.sessionStorage.setItem("flowUnits", data.flowUnits);
                                window.sessionStorage.setItem("utcOffset", data.utcOffset > 0 ? "+" + data.utcOffset : data.utcOffset);
                                window.sessionStorage.setItem("canConfig", data.canConfig);
                                window.sessionStorage.setItem('graphTotaliser', data.graphTotaliser)
                                localStorage.setItem('portalVersion', portalVersion)
                                window.sessionStorage.setItem('favourites', data.favourites)
                                window.sessionStorage.setItem('inactiveTimeframe', data.inactiveTimeframe)
                                window.sessionStorage.setItem('language', data.language)
                                window.sessionStorage.setItem('userName', data.userName)
                                setImpersonation(data.userName + " [" + data.companyName + "]");
                                setUserLevel(data.userRole);
                                setUser(user);
                                setLockedOut(false);
                                setAuthorised(true);
                            },
                                // Reject
                                (responsedata) => {
                                    if (responsedata.status == 404) {
                                        console.log('ResolveIdentity Not Found Error');
                                        window.sessionStorage.setItem('loginError', true);
                                    } else
                                        setApiFailed(true);
                                }
                            )
                            .catch(function (error) {
                                console.log(error)
                            });
                    }
                    else {
                        
                        // ?? How to check for locked out identityadmin - they need to be removed from IS
                        window.sessionStorage.setItem('companyId', 0);
                        window.sessionStorage.setItem('companyGuid', user.profile.company.toString());
                        window.sessionStorage.setItem('userId', 0);
                        window.sessionStorage.setItem('userLevel', user.profile.userlevel);
                        window.sessionStorage.setItem('userGuid', user.profile.sub.toString());
                        window.sessionStorage.setItem('filterGroupId', 0);
                        window.sessionStorage.setItem('filterGroupName', "");
                        window.sessionStorage.setItem('dashboardJson', "");
                        window.sessionStorage.setItem('companyName', "Ashridge");
                        window.sessionStorage.setItem("utcOffset", "0");
                        window.sessionStorage.setItem('favourites', user.favourites)
                        window.sessionStorage.setItem('inactiveTimeframe', user.inactiveTimeframe)
                        window.sessionStorage.setItem("canConfig", "true");
                        window.sessionStorage.setItem('graphTotaliser', 'true')
                        window.sessionStorage.setItem('language', 'en-GB')
                        window.sessionStorage.setItem('userName', user.profile.name)
                        localStorage.setItem('portalVersion', portalVersion)
                        window.sessionStorage.setItem('inactiveTimeframe', user.inactiveTimeframe)
                        setUserLevel(user.profile.userlevel);
                        setFirstLogin(false);
                        setUser(user);
                        setLockedOut(false);
                        setAuthorised(true);
                    }
                }
        });


    }, []);

   
    return (
        
        (authorised) ? //if we are logged in, show page, else redirect to login page
            (
                (firstLogin) ?  // First login - redirect to set password page
                    (
                        //<div className={classes.wrapper}>
                        //    {mobileOpen && <Sidebar
                        //        routes={[]}
                        //        logoText={window.location.hostname.includes('zonelog.net')  ? "ZONELOG" : "AquaGuard"}
                        //        logo={logo}
                        //        image={image}
                        //        handleDrawerToggle={handleDrawerToggle}
                        //        open={mobileOpen}
                        //        color={color}
                        //        {...rest}
                        //    />}
                        //    <div className={classes.mainPanel} ref={mainPanel}>
                        //        <Navbar
                        //            logoText={window.location.hostname.includes('zonelog.net') ? "ZONELOG" : "AquaGuard"}
                        //            routes={[]}
                        //            handleDrawerToggle={handleDrawerToggle}
                        //            {...rest}
                        //        />
                                //<div className={classes.content}>
                                    //<div className={classes.container}>
                                        <ResetPassword onSet={passwordReset} resetDone={resetDone} classes={classes}/>
                                //    </div>
                                //</div>
                           
                                
                        //    </div>
                        //</div>
                    )
                    :
                    (
                        (!lockedOut) ?   // Check if user locked out - if so redirect to message page
                            (
                                (unknownUser) ?
                                (
                                    <Redirect to={'/Login'} />
                                )
                                :
                                (
                                    (user) ? //if we are logged in, show page, else redirect to login page
                                    (
                                        <div className={classes.wrapper} >
                                                    {mobileOpen && <Sidebar
                                                        routes={routes}
                                                        logoText={window.location.hostname.includes('zonelog.net') ? "ZONELOG" : "AquaGuard"}
                                                        logo={logo}
                                                        image={image}
                                                        handleDrawerToggle={handleDrawerToggle}
                                                        open={mobileOpen}
                                                        color={color}
                                                        {...rest}
                                                    />}
                                            <div className={classes.mainPanel} ref={mainPanel}>
                                                <Navbar
                                                    logoText={window.location.hostname.includes('zonelog.net') ? "ZONELOG" : "AquaGuard"}
                                                    routes={routes}
                                                    handleDrawerToggle={handleDrawerToggle}
                                                    impersonateUser={impersonation}
                                                    {...rest}
                                                />

                                                {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}


                                                {getRoute() ? (
                                                    <div className={classes.content} style={{
                                                                /*backgroundImage: `url(${BG})`*/
                                                    }}>
                                                        <div className={classes.container}>{switchRoutes(user)}</div>
                                                    </div>
                                                ) : (
                                                    <div className={classes.map}>{switchRoutes(user)}</div>
                                                        )}
                                                        <Footer />


                                            </div>
                                        </div>
                                    )
                                    :
                                    (
                                        <Redirect to={'/Login'} />
                                    )
                                )
                            )
                            :
                            (
                                <Redirect to={'/Lockout'} />
                            )
                    )
            )
            :
            (
                (apiFailed) ?
                    (
                        <div className="error">The system has failed to respond. Please contact the System Administrator
                        </div>
                    )
                    :
                    (
                    <LoadingOverlay
                        active={true}
                        spinner
                        text='Processing your login...'
                        styles={{ wrapper: fullScreen }}
                    >
                        <p>loading - callback</p>
                        </LoadingOverlay >
                    )
            )

    );
}
