import React, { ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import FormField from 'components/Fields/FormField';
import PasswordField from 'components/Fields/PasswordField';
import BG from '../../assets/BG-Aquaguard.jpg';
import Card from '@mui/material/Card';
import { ReactComponent as AshLogo } from '../../assets/ashLogo.svg';
import Button from '@mui/material/Button';
import GutermannLogo from '../../assets/gutermann/gutermnn-full-brand-white.png'
//import './ResetPassword.scss';
import { injectIntl } from 'react-intl';
interface Props {
    classes: {
        cardTitleWhite: string;
        cardCategoryWhite: string;
        formControl: string;
    };
    onSet: (newPassword: string) => Promise<boolean>;
    resetDone: () => void;
    isForgot: boolean
}

class ResetPassword extends React.Component<Props> {

    // Code cribed from https://www.digitalocean.com/community/tutorials/how-to-build-a-password-strength-meter-in-react

    // initialize state to hold validity of form fields
    state = { passwordConfirm: false, password: false, passwordValue: '', confirmValue: '', completed: false, failed: false };
    
    // higher-order function that returns a state change watch function
    // sets the corresponding state property to true if the form field has no errors
    fieldStateChanged = (field: string, value: string) => (state: any): void => this.setState({ [field]: state.errors.length === 0, [value]: state.value });

    // state change watch functions for each field
    confirmChanged = this.fieldStateChanged('passwordConfirm', 'confirmValue');
    passwordChanged = this.fieldStateChanged('password', 'passwordValue');

    doReset = (): void => {
        if (this.props.onSet) {
            this.props.onSet(this.state.passwordValue).then((result: boolean) => {
                this.setState({ completed: result, failed: !result });
            });
        }
        else {
            this.setState({ failed: true });
        }
    }

    resetDone = (): void => {
        
        if (this.props.resetDone) {
            this.props.resetDone();
        }
    }

    render(): ReactNode {

        const { password, passwordConfirm, passwordValue } = this.state;
        const formValidated = passwordConfirm && password;
        const { classes, intl } = this.props;


        // validation function for the fullname
        // ensures that fullname contains at least two names separated with a space
        const validatepasswordConfirm = (value: string): void => {
            if (value != passwordValue) throw new Error(this.props.intl.formatMessage({ id: 'Passwords do not match' }));
        };


        return (

            (!this.props.isForgot ? 
            <div style={{
                backgroundImage: `url(${BG})`,
                width: '100vw',
                height: '100vh',
                overflow: 'hidden',


            }}>
                <GridContainer>
                    <div style={{display: "flex", justifyContent: "center", width: "100%"} }>
                            <Card style={{
                                margin: "2vw 0 0 0",
                                background: 'linear-gradient(130deg, rgba(0, 0, 0, 0.7) 0%, rgb(20,60,103, 0.6) 70%',
                                width: "50vw",
                                height: "80vh",
                                boxShadow: "2px 2px 10px black",
                                padding: "3vh 0 0 0"

                            }}>
                                
                                <div>
                                    <div style={{display: "flex", justifyContent: "center"} }>
                                        {window.location.hostname.includes('zonelog.net') ? <img src={GutermannLogo} style={{ width: "80%", height: "90%", margin: "60px 0 20px 0" }} />
                                            : <AshLogo />}
                                    </div>
                                    <form action="/" method="POST" noValidate>
                                    {!this.state.completed && <div style={{ width: "100%" }}>
                                        <div style={{ margin: "-20px 20px 20px 20px" }}>
                                            <h3 style={{ color: "white", textAlign: "center" }}>{intl.formatMessage({ id: 'Set Your Pasword' })}</h3>
                                            <p style={{ color: "white", margin: "20px" }}>{intl.formatMessage({ id: 'To conform with our Strong Password policy, you are required to use a sufficiently strong password including a mixture of upper & lower case, numbers and symbols until bar turns green. Password must be more than 7 characters.' })}</p>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                            <div style={{ width: "90%" }}>
                                                <div style={{ width: "100%" }}>
                                                    {/** Render the password field component using thresholdLength of 8 and minStrength of 3 **/}
                                                    <PasswordField fieldId="password" label={intl.formatMessage({ id: "Password" })} placeholder="Enter Password" onStateChanged={this.passwordChanged} thresholdLength={7} minStrength={3} required />
                                                </div>
                                                <div style={{ width: "100%" }}>
                                                    {/** Render the fullname form field passing the name validation fn **/}
                                                    <FormField type="password" fieldId="passwordConfirm" label={intl.formatMessage({ id: "Confirm Password" })} placeholder="" validator={validatepasswordConfirm} onStateChanged={this.confirmChanged} required />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                            {formValidated && <Button variant="contained" onClick={this.doReset.bind(this)}>{intl.formatMessage({ id: 'Set Password' })}</Button>}
                                        </div>
                                    </div>}
                                        {/** Show the form button only if all fields are valid **/}
                                        
                                    </form>
                                    {(this.state.failed &&
                                    <div style={{display: "flex", justifyContent: "center"} }>
                                        <p style={{ color: "white" }}>{intl.formatMessage({ id: 'The system failed to reset your password, please contact system administrator' })}</p>
                                    </div>
                                    )}
                                </div>
                                
                                {this.state.completed &&
                                    <div>
                                    <div className={classes.cardCategoryWhite}>
                                        <h3 style={{ color: "white", textAlign: "center" }}>{intl.formatMessage({ id: 'Your password has been reset.' })}</h3>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "center"} }>
                                        <Button variant="contained" color="primary" onClick={this.resetDone.bind(this)}>{intl.formatMessage({ id: 'Login' })}</Button>
                                    </div>
                                    </div>
                            }

                        </Card>
                    </div>
                        </GridContainer>
                </div>
                :

                <div>
                <div>
                    <form action="/" method="POST" noValidate>
                        {!this.state.completed && <div style={{ width: "100%" }}>
                            <div style={{ margin: "-20px 20px 20px 20px" }}>
                                <h3 style={{ color: "white", textAlign: "center" }}>{intl.formatMessage({ id: 'Set Your Pasword' })}</h3>
                                <p style={{ color: "white", margin: "20px" }}>{intl.formatMessage({ id: 'To conform with our Strong Password policy, you are required to use a sufficiently strong password including a mixture of upper & lower case, numbers and symbols until bar turns green. Password must be more than 7 characters.' })}</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                <div style={{ width: "90%" }}>
                                    <div style={{ width: "100%" }}>
                                        {/** Render the password field component using thresholdLength of 8 and minStrength of 3 **/}
                                        <PasswordField fieldId="password" label={intl.formatMessage({ id: "Password" })} placeholder="Enter Password" onStateChanged={this.passwordChanged} thresholdLength={7} minStrength={3} required />
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        {/** Render the fullname form field passing the name validation fn **/}
                                        <FormField type="password" fieldId="passwordConfirm" label={intl.formatMessage({ id: "Confirm Password" })} placeholder="" validator={validatepasswordConfirm} onStateChanged={this.confirmChanged} required />
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                {formValidated && <Button variant="contained" onClick={this.doReset.bind(this)}>{intl.formatMessage({ id: 'Set Password' })}</Button>}
                            </div>
                        </div>}
                        {/** Show the form button only if all fields are valid **/}

                    </form>
                    {(this.state.failed &&
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <p style={{ color: "white" }}>{intl.formatMessage({ id: 'The system failed to reset your password, please contact system administrator' })}</p>
                        </div>
                        )}
                    </div>

                    {this.state.completed &&
                        <div>
                            <div className={classes.cardCategoryWhite}>
                                <h3 style={{ color: "white", textAlign: "center" }}>{intl.formatMessage({ id: 'Your password has been reset.' })}</h3>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button variant="contained" color="primary" onClick={this.resetDone.bind(this)}>{intl.formatMessage({ id: 'Login' })}</Button>
                            </div>
                        </div>
                    }
                </div>)
        )
    }

}

export default injectIntl(ResetPassword);