/*
 * Used to hold Chariot Config for use by ToolkitCHR component
 */

import moment from 'moment';
import { APIGetCurrentLCConfigurationModel } from './APIGetCurrentLCConfigurationModel';



export interface ChariotConfig {
    // Details tab
    Product: string;
    Model: string;
    SWversion: string;
    CurrentState: string;
    Guid: string;
    Sitename: string;
    Latitude: number | null;
    Longitude: number | null;
    LastConnection: Date | null | undefined;
    LoggerTime: Date | null | undefined;

    // Communication tab
    TransmissionInterval: number | null;
    PreferredLPWAN: string;
    MQTTServer: string;
    //Dormant: boolean;

    // Flow Channel tab
    FlowA: boolean;
    FlowARef: string;
    FlowASchedule: number;
    FlowAMultiplierStep: number;
    FlowAMultiplierValue: number;
    FlowAMultiplier: number;
    FlowAMultiplierStr: string;
    FlowAUnits: string;
    FlowAPulseType: number;

    // Second Flow Channel tab
    FlowB: boolean;
    FlowBRef: string;
    FlowBSchedule: number;
    FlowBMultiplierStep: number;
    FlowBMultiplierValue: number;
    FlowBMultiplier: number;
    FlowBMultiplierStr: string;
    FlowBUnits: string;
    FlowBPulseType: number;

    // Pressure1 Channel tab

    AnalogueC: boolean;
    AnalogueCSchedule: number;
    AnalogueCType: string;
    AnalogueCRef: string;
    AnalogueCUnits: string;
    AnalogueCMultiplier: number | null;
    AnalogueCMultiplierStr: string;
    // Analogue sampling
    AnalogueCSample: number;       //B6
    AnalogueCSampleType: number;     //B7
    AnalogueCSampleTime: number;        //B4

    // Pressure2 Channel tab
    AnalogueD: boolean;
    AnalogueDSchedule: number;
    AnalogueDType: string;
    AnalogueDRef: string;
    AnalogueDUnits: string;
    AnalogueDMultiplier: number | null;
    AnalogueDMultiplierStr: string;
    // Analogue sampling
    AnalogueDSample: number;       //C6
    AnalogueDSampleType: number;     //C7
    AnalogueDSampleTime: number;     //C4

    // Battery Reset
    BatteryReset: boolean;
    // Custom tab
    Custom: string;
    Dormant: boolean;
    // Existing Update Json
    UpdateJSON: string;

}

export interface ChariotAlarm {
    Idx: number;
    Id: number;
    AlarmMask: number;
    AlarmMessage: string;
    AlarmClass: number;
    SetInput: number;
    SetOperator: number;
    SetThreshold: number | null;
    SetThresholdStr: string;
    ClrInput: number;
    ClrOperator: number;
    ClrThreshold: number | null;
    ClrThresholdStr: string;
    SetCount: number;
    SetCountStr: string;
    ClrCount: number;
    ClrCountStr: string;
    MaxAlarms: number;
    MaxAlarmsStr: string;
    FkLCLoggerId: number;
    Unit: string | null;
}


function CreateChariotConfig(): ChariotConfig {
    return {
        // Details tab
        Product: "",
        Model: "",
        SWversion: "",
        CurrentState: "Decommissioned",
        Guid: "00000000-0000-0000-0000-000000000000",
        Sitename: "",
        Latitude: null,
        Longitude: null,
        LastConnection: null,
        LoggerTime: null,

        // Communication tab
        TransmissionInterval: 0,
        PreferredLPWAN: "00",
        MQTTServer: "",
        Dormant: false,

        //Channels tab
        FlowA: false,
        FlowARef: "",
        FlowASchedule: 0,
        FlowAMultiplierStep: 0,
        FlowAMultiplierValue: 0,
        FlowAMultiplier: 1,
        FlowAMultiplierStr: "1",
        FlowAUnits: "",
        FlowAPulseType: 0,

        //Second Channelstab
        FlowB: false,
        FlowBRef: "",
        FlowBSchedule: 0,
        FlowBMultiplierStep: 0,
        FlowBMultiplierValue: 0,
        FlowBMultiplier: 1,
        FlowBMultiplierStr: "1",
        FlowBUnits: "",
        FlowBPulseType: 0,


        AnalogueC: false,
        AnalogueCType: "",
        AnalogueCRef: "",
        AnalogueCSchedule: 0,
        AnalogueCUnits: "",
        AnalogueCMultiplier: 0,
        AnalogueCMultiplierStr: "",
        AnalogueCSample: 0,       
        AnalogueCSampleType: 0,
        AnalogueCSampleTime: 0,

        AnalogueD: false,
        AnalogueDType: "",
        AnalogueDRef: "",
        AnalogueDSchedule: 0,
        AnalogueDUnits: "",
        AnalogueDMultiplier: 0,
        AnalogueDMultiplierStr: "",
        AnalogueDSample: 0,
        AnalogueDSampleType: 0,
        AnalogueDSampleTime: 0,

        BatteryReset: false,

        Custom: "",

        // Existing update
        UpdateJSON: "",

    }

}

// Utility functions to convert config entries
function zeroPad(str: string, len: number): string {
    let result = str;
    while (result.length < len) {
        result = "0" + result;
    }
    return result;
}

function HexToSignedInt(num: string, numSize: number): number {
    const val = {
        mask: 0x8 * Math.pow(16, numSize - 1), //  0x8000 if numSize = 4
        sub: -0x1 * Math.pow(16, numSize)    //-0x10000 if numSize = 4
    };
    if ((parseInt(num, 16) & val.mask) == 0) { //negative
        return (parseInt(num, 16));
    } else {                                 //positive
        return (val.sub + parseInt(num, 16));
    }
}
function SignedIntToHex(num: number, numSize: number): string {
    const val = {
        mask: 0x8 * Math.pow(16, numSize - 1), //  0x8000 if numSize = 4
        sub: -0x1 * Math.pow(16, numSize)    //-0x10000 if numSize = 4
    };
    if (num >= 0) {
        return zeroPad(num.toString(16), numSize);
    }
    else {
        const neg = num - val.sub;
        return zeroPad(neg.toString(16), numSize);
    }
}

function ReverseSignedIntToHex(num: string, numSize: number): number {
    const val = {
        mask: 0x8 * Math.pow(16, numSize - 1), // 0x80000000 if numSize = 8
        sub: -0x1 * Math.pow(16, numSize)     // -0x100000000 if numSize = 8
    };

    const intValue = parseInt(num, 16); // Convert the hex string to an integer

    // Check if the value is negative based on the most significant bit
    if ((intValue & val.mask) === 0) {
        // Positive number
        return intValue;
    } else {
        // Negative number
        return val.sub + intValue;
    }
}


// convert string (of hex values from SMS) into array of numbers
function stringToHexArray(str: string): Array<number> {
    const a: Array<number> = [];
    for (let i = 0; i < str.length; i += 2) {
        a.push(parseInt("0x" + str.substr(i, 2),16));
    }
    return a;
}
//convert array of numbers to string (of hex values for SMS)
function hexArrayToString(arr: Array<number>): string {
    let str = "";
    for (let i = 0; i < arr.length; i++) {
        const val = Math.trunc(arr[i] % 256).toString(16)
        str = str + zeroPad(val, 2);
    }
    return str;
}
//convert string to string of hex (ASCII) values, pad to length with /0
function encodeString(input: string, length: number): string {
    const a: Array<number> = [];
    let i = 0;
    while (i < input.length && i < length) {
        a.push(input.charCodeAt(i));
        i++;
    }
    while (i < length) {
        a.push(0);
        i++;
    }
    return hexArrayToString(a);
}
//convert string of hex (ASCII) values to string
function decodeString(input: string): string {
    const a = stringToHexArray(input);
    let str = "";
    for (let i = 0; i < a.length; i++) {
        if (a[i] != 0) {
            str = str + String.fromCharCode(a[i]);
        }
    }
    return str;
}

// Version is 2 byte value as major-minor version
function getSwVersion(value: number): string {
    const majorStr = zeroPad(Math.trunc(value / 256).toString(),2);
    const minorStr = zeroPad((value % 256).toString(),2);

    return majorStr + "-" + minorStr;
}

function is2FlowChariot(swVersion: string): boolean {
    return swVersion >= "2.4.4";   // Firmware version >= 2.5, i.e. has second flow
}

/*
 *  Coordinates are stored as double values of Latitude (-90 to 90) & Longitude (-180 to 180)
 *  In parameter 0078 these are encoded as 4 byte hex values of each coordinate * 10,000,000 (7 decimal places)
 *  The byte values are converted to a string with LSB first
 *
 */
function getLatitude(coord: string, awaitingUpdate: boolean): number {
    let arr: any;
    if (awaitingUpdate) {
        //show values that have been requested - before logger config received
        const coords = reverseCoordLat(coord);
        return coords;
    } else {
        arr = stringToHexArray(coord);
        const latStr = zeroPad(arr[3].toString(16), 2) + zeroPad(arr[2].toString(16), 2) + zeroPad(arr[1].toString(16), 2) + zeroPad(arr[0].toString(16), 2);
        let lat = HexToSignedInt("0x" + latStr, 8);
        if (lat > 90 * 10000000 || lat < -90 * 10000000) {
            lat = 0;
        }
        return lat / 10000000;
    }     

}
function getLongitude(coord: string, awaitingUpdate: boolean): number {
    let arr: any;
    if (awaitingUpdate) {
        //show values that have been requested - before logger config received
        const coords = reverseCoordLong(coord);
        return coords;
    } else {
        arr = stringToHexArray(coord);
        const lngStr = zeroPad(arr[7].toString(16), 2) + zeroPad(arr[6].toString(16), 2) + zeroPad(arr[5].toString(16), 2) + zeroPad(arr[4].toString(16), 2);
        let lng = HexToSignedInt("0x" + lngStr, 8);
        if (lng > 180 * 10000000 || lng < -180 * 10000000) {
            lng = 0;
        }
        return lng / 10000000;
    }
}
function getCooord(lat: number, lng: number): string {
    const latStr = SignedIntToHex(Math.trunc(lat * 10000000), 8).substr(0, 8)
    const longStr = SignedIntToHex(Math.trunc(lng * 10000000), 8).substr(0, 8)
    const coordStr = latStr.substr(0, 2) + latStr.substr(2, 2) + latStr.substr(4, 2) + latStr.substr(6, 2) + longStr.substr(0, 2) + longStr.substr(2, 2) + longStr.substr(4, 2) + longStr.substr(6, 2) +"0000";
    return coordStr.toUpperCase();
}

function reverseCoordLat(coordStr: string): number {
    coordStr = coordStr.slice(0, -4);
    const latHexReversed = coordStr.slice(0, 8); // First 8 characters: "001784D7"
    const latHex =
        latHexReversed.slice(0, 2) + // First byte
        latHexReversed.slice(2, 4) + // Second byte
        latHexReversed.slice(4, 6) + // Second last byte
        latHexReversed.slice(6, 8); // Last byte
    const latInt = ReverseSignedIntToHex(latHex, 8);
    const lat = latInt / 10000000;
    return lat;
}

function reverseCoordLong(coordStr: string): number {
    coordStr = coordStr.slice(0, -4);
    const lngHexReversed = coordStr.slice(8, 16); // Next 8 characters: "80C3C901"
    const lngHex =
        lngHexReversed.slice(0, 2) +
        lngHexReversed.slice(2, 4) +
        lngHexReversed.slice(4, 6) +
        lngHexReversed.slice(6, 8);
    const lngInt = ReverseSignedIntToHex(lngHex, 8);
    const lng = lngInt / 10000000;
    return lng;
}

/*
 * GUID: convert between byte array & string
 * first four bytes should be reversed, as well as two next and two after them
 * 
 */
function guidFromArray(binary: Array<number>): string {
    // reverse first four bytes, and join with following two reversed, joined with following two reversed, joined with rest of the bytes
    const x: Array<number> = binary.slice(0, 4).reverse().concat(binary.slice(4, 6).reverse()).concat(binary.slice(6, 8).reverse()).concat(binary.slice(8));

    const guid = x.map(function (item) {
        // return hex value with "0" padding
        return ('00' + item.toString(16).toUpperCase()).substr(-2, 2);
    });

    return guid[0] + guid[1] + guid[2] + guid[3] + "-" + guid[4] + guid[5] + "-" + guid[6] + guid[7] + "-" + guid[8] + guid[9] + "-" + guid.slice(10).join("");
}
function guidToArray(guid: string): Array<number> {
    let x: Array<number> = [];

    if (guid.length >= 36) {
        // guid is format 4BA84883-4955-AE41-8C1E-79C4F008AECE
        //                0123456789012345678901234
        x.push(parseInt(guid.substr(6, 2), 16));
        x.push(parseInt(guid.substr(4, 2), 16));
        x.push(parseInt(guid.substr(2, 2), 16));
        x.push(parseInt(guid.substr(0, 2), 16));
        x.push(parseInt(guid.substr(11, 2), 16));
        x.push(parseInt(guid.substr(9, 2), 16));
        x.push(parseInt(guid.substr(16, 2), 16));
        x.push(parseInt(guid.substr(14, 2), 16));
        x.push(parseInt(guid.substr(21, 2), 16));
        x.push(parseInt(guid.substr(19, 2), 16));
        x.push(parseInt(guid.substr(24, 2), 16));
        x.push(parseInt(guid.substr(26, 2), 16));
        x.push(parseInt(guid.substr(28, 2), 16));
        x.push(parseInt(guid.substr(30, 2), 16));
        x.push(parseInt(guid.substr(32, 2), 16));
        x.push(parseInt(guid.substr(34, 2), 16));
    }
    else {
        x = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }

    return x;
}

// Phone numbers
//The number is stored with 16 nibbles swapped as this is how they are used in the SMS messages. Unused nibbles must be set to F.
function decodePhone(phoneParam: string): string {
    let num = "";
    const decode = stringToHexArray(phoneParam);
    if (decode[0] == 0xFF) {
        return "";
    }
    for (let i = 0; i < decode.length; i++) {
        // Low nibble first
        let digit = decode[i] & 0x0f;
        if (digit >= 0 && digit <= 9) {
            num = num + digit.toString();
        }
        // high nibble
        digit = (decode[i] & 0xf0) >> 4;
        if (digit >= 0 && digit <= 9) {
            num = num + digit.toString();
        }
    }

    return num;
}
function encodePhone(num: string): string {
    const param: Array<number> = [];
    for (let i = 0; i < 8; i++) {
        let val = 0;
        if (i * 2 < num.length) {
            // Low nibble first
            const digit = num.substr(i * 2, 1);
            val = parseInt(digit);
        }
        else {
            val = 0x0f;
        }
        if ((i * 2) + 1 < num.length) {
        // high nibble
            const digit = num.substr((i * 2) + 1, 1);
            val += parseInt(digit) * 256;
        }
        else {
            val += 0xf0;
        }
        param.push(val);
    }

    return hexArrayToString(param);

}

// Schedule - see schedule parameters.doc
// Encoded as 45 char hex string
// Hour encoded in chars 16-21 and Minute in chars 22-37 
// Interval gives 1,2,4,8,24 times per day (i.e. which hour bits to set working from time entered)
function setSchedule(start: Date, interval: string): string {
    const hour = moment(start).hour();
    const minute = moment(start).minute();

    console.log("IN: hour:" + hour + " min:" + minute + " int: " + interval);
    let hourStr = "000000";
    let minuteStr = "";

    switch (interval) {
        case "24":
            switch (hour % 12) {
                case 0:
                    hourStr = "001";
                    break;
                case 1:
                    hourStr = "002";
                    break;
                case 2:
                    hourStr = "004";
                    break;
                case 3:
                    hourStr = "008";
                    break;
                case 4:
                    hourStr = "010";
                    break;
                case 5:
                    hourStr = "020";
                    break;
                case 6:
                    hourStr = "040";
                    break;
                case 7:
                    hourStr = "080";
                    break;
                case 8:
                    hourStr = "100";
                    break;
                case 9:
                    hourStr = "200";
                    break;
                case 10:
                    hourStr = "400";
                    break;
                case 11:
                    hourStr = "800";
                    break;
            }
            if (hour > 12) {
                hourStr = hourStr + "000";
            }
            else {
                hourStr = "000" + hourStr;
            }
            break;
        case "12":
            switch (hour % 12) {
                case 0:
                    hourStr = "001001";
                    break;
                case 1:
                    hourStr = "002002";
                    break
                case 2:
                    hourStr = "004004";
                    break
                case 3:
                    hourStr = "008008";
                    break
                case 4:
                    hourStr = "010010";
                    break
                case 5:
                    hourStr = "020020";
                    break
                case 6:
                    hourStr = "040040";
                    break
                case 7:
                    hourStr = "080080";
                    break
                case 8:
                    hourStr = "100100";
                    break
                case 9:
                    hourStr = "200200";
                    break
                case 10:
                    hourStr = "400400";
                    break
                case 11:
                    hourStr = "800800";
                    break
            }
            break;
        case "6":
            switch (hour % 6) {
                case 0:
                    hourStr = "041041";
                    break;
                case 1:
                    hourStr = "082082";
                    break;
                case 2:
                    hourStr = "104104";
                    break;
                case 3:
                    hourStr = "208208";
                    break;
                case 4:
                    hourStr = "410410";
                    break;
                case 5:
                    hourStr = "820820";
                    break;

            }
            break;
        case "3":
            switch (hour % 3) {
                case 0:
                    hourStr = "249249";
                    break;
                case 1:
                    hourStr = "492492";
                    break;
                case 2:
                    hourStr = "924924";
                    break;
            }
            break;
        case "1":
            hourStr = "FFFFFF";
            break;
    }
    const c = Math.trunc(minute / 4);
    const bit = minute % 4;
    for (let i = 15; i >= 0; i--) {
        if (i != c) {
            minuteStr = minuteStr + "0";
        }
        else {
            minuteStr = minuteStr + (2 ** bit).toString();
        }
    }

    console.log("OUT: hour:" + hourStr + " min:" + minuteStr);

    return "FFFFFFFFFFFFFFFF" + hourStr + minuteStr + "00000000";
}

function getScheduleStart(val: string): Date {
    let hour = 0, minute = 0;
    for (let h = 21; h >= 16; h--) {
        const b = parseInt(val.substr(h, 1), 16);
        let mask = 0x01;
        for (let shift = 0; shift < 4; shift++) {
            if ((b & mask) != 0) {
                break;
            }
            hour++;
            mask = mask << 1;
        }
        if ((b & mask) != 0) {
            break;
        }
    }
    if (hour == 24) {
        hour = 0;
    }

    for (let m = 37; m >= 22; m--) {
        const b = parseInt(val.substr(m, 1), 16);
        let mask = 0x01;
        for (let shift = 0; shift < 4; shift++) {
            if ((b & mask) != 0) {
                break;
            }
            minute++;
            mask = mask << 1;
        }
        if ((b & mask) != 0) {
            break;
        }
    }
    if (minute > 59) {
        minute = 0;
    }

    return moment().hour(hour).minute(minute).second(0).toDate();
}

function getScheduleInterval(val: string): string {
    let count = 0;
    // Count number of hour bits set
    for (let h = 21; h >= 16; h--) {
        const b = parseInt(val.substr(h, 1), 16);
        let mask = 0x01;
        for (let shift = 0; shift < 4; shift++) {
            if ((b & mask) != 0) {
                count++;
            }
            mask = mask << 1;
        }
    }

    switch (count) {
        case 1:
            return "24";
        case 2:
            return "12";
        case 4:
            return "6";
        case 8:
            return "3";
        case 24:
            return "1";
        default:
            return "0";
    }
}

function isScheduleEnabled(value: string): boolean {
    return !(value.substr(16, 30) == "000000000000000000000000000000");
}


// ScheduleWindow - see schedule parameters.doc
// Encoded as 45 char hex string
// Hour encoded in chars 16-21 and Minute in chars 22-37 
// Will be FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF0000???? if Window=false (i.e 24 hour)
// Otherwise need to encode windowStart to windowEnd every day 
// Final 2 bytes are MinCycle/SecCycle - top bit set for seconds(unit), remaining bits = sampleValue
function setScheduleWindow(unit: string, sampleValue: number | null, window: boolean, windowStart: string, windowEnd: string ): string {
    let schedule = "FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF0000";
    let cycle = "0000";

    if (sampleValue != null && !isNaN(sampleValue)) {
        if (unit == "s" || unit == "") {
            cycle = (sampleValue + 32768).toString(16).padStart(4, "0");
        }
        else {
            cycle = sampleValue.toString(16).padStart(4, "0");
        }
    }
    if (window) {
        let mask = 1;
        let sched = 0;
        const winStart = parseInt(windowStart.split(":")[0]);
        const winEnd = parseInt(windowEnd.split(":")[0]);
        if (winStart == winEnd) {
            sched = 0xFFFFFF;
        }
        else {
            if (winEnd > winStart) {
                // Within 24 hour day
                for (let hour = 0; hour < 24; hour++) {
                    if (hour >= winStart && hour < winEnd) {
                        sched += mask;
                    }
                    mask = mask << 1;
                }
            }
            else {
                // Period crosses midnight
                for (let hour = 0; hour < 24; hour++) {
                    if (hour >= winStart || hour < winEnd) {
                        sched += mask;
                    }
                    mask = mask << 1;
                }
            }
        }
        schedule = "FFFFFFFFFFFFFFFF" + sched.toString(16).padStart(6, "0") + "FFFFFFFFFFFFFFFF0000";
    }

    return (schedule + cycle).toUpperCase();
}

function isScheduleWindowed(value: string): boolean {
    return !(value.substr(16, 6) == "FFFFFF");
}

function getScheduleWindow(value: string, start: boolean): string {

    let mask = 1;
    const sched = parseInt("0x" + value.substr(16,6));    //Extract hour bit mask 6 bytes, chars 16 - 21
    let winStart = 0;
    let winEnd = 23;
    let started = (sched & 0x0001) != 0;        // If bit 0 (00:00) set then Enabled over midnight
    for (let hour = 0; hour < 24; hour++) {
        if ((sched & mask) > 0) {
            // Look for first bit set as start
            if (!started) {
                winStart = hour;
                if ((sched & 0x0001) == 0) {
                    started = true;
                }
                else {
                    break;
                }
            }
        }
        else {
            // Once started look for bit not set
            if (started) {
                winEnd = hour;
                if ((sched & 0x0001) == 0) {
                    break;
                }
                else {
                    started = false;
                }
            }
        }
        mask = mask << 1;
    }

    if (start) {
        return winStart.toString().padStart(2, '0') + ":00";
    }
    else {
        return winEnd.toString().padStart(2, '0') + ":00";
    }

}

// Work out multiplier (from 1 = 0.0001, 5 = 1, 6 = 10 etc. )
function determineMultiplierStep(multiplier: number): number {
    if (multiplier < 1) {
        if (multiplier >= 0.1) {
            return 4;
        }
        if (multiplier >= 0.01) {
            return 3;
        }
        if (multiplier >= 0.001) {
            return 2;
        }
        if (multiplier >= 0.0001) {
            return 1;
        }
        return 1;
    }

    if (multiplier > 10000) {
        let remainder = multiplier;
        // Keep dividing by 10 until loose non-zero digit
        let ret = 5;
        while (Math.trunc(remainder / 10) == (remainder / 10)) {
            ret++;
            remainder = remainder / 10;
        }
        return ret;
    }
    //(multiplier > 0 && multiplier < 10000
    return 5;
}

// Functions to decode/encode DeviceConfig to APIGetLoggerConfigurationsModel & json for update
//
// Params:
//  product: string - product range of logger
//  model: string - model of logger
//  json: APIGetCurrentConfigurationModel - response from /api/aquaguard/CurrentLoggerConfiguration
//  simStore: ArrayStore - details of SIM settings from /api/aquaguard/SimDetailsForCompany
//  gprsStore: ArrayStore - details of Server serrings from /api/aquaguard/GprsDetailsForCompany
//  phoneStore: ArrayStore - details of phone numbers from /api/aquaguard/PhoneNumbersForCompany
//  newSim: function on calling component to add new Sim details
//  newGprs: function on calling component to add new Server details
//  newPhone: function on calling component to add new Phone number details
//
// TODO: Expand to cover all features of config.


export function configFromJson( product: string, 
                                json: APIGetCurrentLCConfigurationModel | undefined,
                                ): ChariotConfig {

    const config = CreateChariotConfig();
    config.Product = product.substring(0, 2);
    config.Model = product.substring(2, 2);

    config.LastConnection = json?.lastConnection;
    config.LoggerTime = json?.loggerTime;
    console.log("json " + JSON.stringify(json));
    
    if (json) {
        let awaitingUpdate = false;

        if (json.sendToLogger) {
            // Pending updates to be sent - save them in case of further updates
            config.UpdateJSON = json.updateJson;
            awaitingUpdate = true;
        }

        // Parse STD config into key:value pairs and merge updateJson values
        let jsonValues = {};
        const configValues = JSON.parse(json.registerJson);
        let updateValues = [];
        if (json.updateJson) {
            updateValues = JSON.parse(json.updateJson);
            jsonValues = { ...configValues, ...updateValues };
        }
        else {
            jsonValues = { ...configValues };
        }

        config.SWversion = json.firmwareVersion;
        if ("I7" in jsonValues) {
            config.Sitename = jsonValues["I7"];
        }

        if ("I6" in jsonValues) {
            config.Latitude = getLatitude(jsonValues["I6"], awaitingUpdate);
            config.Longitude = getLongitude(jsonValues["I6"], awaitingUpdate);
        }
        config.CurrentState = "Decommissioned";
        config.Guid = "00000000-0000-0000-0000-000000000000";
        if ("I2" in jsonValues) {
            config.CurrentState = "Commissioned";
            config.Guid = guidFromArray(stringToHexArray(jsonValues["I2"]));
        }

        //Communication
        if ("S2" in jsonValues) {
            config.TransmissionInterval = parseInt(jsonValues["S2"],16);
        }

        config.PreferredLPWAN = "00";
        if ("S3" in jsonValues) {
            config.PreferredLPWAN = jsonValues["S3"];
        }

        config.MQTTServer = "";
        if ("S4" in jsonValues) {
            if (jsonValues["S4"][0] != 0) {
                config.MQTTServer = jsonValues["S4"];
            }
        }

        // Flow Channel
        if ("A1" in jsonValues) {
            // Channel A
            config.FlowASchedule = parseInt(jsonValues["A1"],16);
            if (config.FlowASchedule == 0 ) {
                config.FlowA = false;
                config.FlowAPulseType = 0;
            }
            else {
                config.FlowA = true;
                config.FlowAUnits = "";
                if ("A0" in jsonValues) {
                    config.FlowAPulseType = parseInt(jsonValues["A0"],16);
                }
                if ("A4" in jsonValues) {
                    config.FlowAMultiplierStep = parseInt(jsonValues["A4"],16);
                }
                if ("A5" in jsonValues) {
                    config.FlowAMultiplierValue = parseInt(jsonValues["A5"],16);
                }
                if ("A2" in jsonValues) {
                    config.FlowAUnits = jsonValues["A2"];
                }
                if ("A3" in jsonValues) {
                    config.FlowARef = jsonValues["A3"];
                }
                config.FlowAMultiplier = config.FlowAMultiplierValue * Math.pow(10, (config.FlowAMultiplierStep - 5));
                config.FlowAMultiplierStr = config.FlowAMultiplier.toString();
            }
        }

        // SecondFlow Channel
        if ("X1" in jsonValues) {
            // Channel B
            config.FlowBSchedule = parseInt(jsonValues["X1"], 16);
            if (config.FlowBSchedule == 0) {
                config.FlowB = false;
                config.FlowBPulseType = 0;
            }
            else {
                config.FlowB = true;
                config.FlowBUnits = "";
                if ("X0" in jsonValues) {
                    config.FlowBPulseType = parseInt(jsonValues["X0"], 16);
                }
                if ("X4" in jsonValues) {
                    config.FlowBMultiplierStep = parseInt(jsonValues["X4"], 16);
                }
                if ("X5" in jsonValues) {
                    config.FlowBMultiplierValue = parseInt(jsonValues["X5"], 16);
                }
                if ("X2" in jsonValues) {
                    config.FlowBUnits = jsonValues["X2"];
                }
                if ("X3" in jsonValues) {
                    config.FlowBRef = jsonValues["X3"];
                }
                config.FlowBMultiplier = config.FlowBMultiplierValue * Math.pow(10, (config.FlowBMultiplierStep - 5));
                config.FlowBMultiplierStr = config.FlowBMultiplier.toString();
            }
        }

        // Pressure 1 channel

        if ("B1" in jsonValues) {
            // Channel C
            config.AnalogueCSchedule = parseInt(jsonValues["B1"],16);
            if (config.AnalogueCSchedule == 0) {
                config.AnalogueC = false;
            }
            else {
                config.AnalogueC = true;

                if ("B4" in jsonValues) {
                    if (is2FlowChariot(config.SWversion))
                    {
                        // B4 updated to Sampling time in fw2.5
                        const b6 = jsonValues["B4"].substring(0, 2);
                        const b7 = jsonValues["B4"].substring(2, 4);
                       // const b4 = jsonValues["B4"].substring(4);

                      //  config.AnalogueCSampleTime = parseInt(b4, 16);
                        config.AnalogueCSample = parseInt(b6, 16);
                        config.AnalogueCSampleType = parseInt(b7, 16);

                       
                       // console.log("config.AnalogueCSampleTime: " + config.AnalogueCSampleTime);
                        console.log("config.AnalogueCSample: " + config.AnalogueCSample);
                        console.log("config.AnalogueCSampleType: " + config.AnalogueCSampleType);

                    }
                    else
                    {
                        config.AnalogueCMultiplier = parseInt(jsonValues["B4"], 16);
                        config.AnalogueCMultiplierStr = config.AnalogueCMultiplier.toString();
                    }
                }
                if ("B2" in jsonValues) {
                    config.AnalogueCUnits = jsonValues["B2"];
                }
                if ("B3" in jsonValues) {
                    config.AnalogueCRef = jsonValues["B3"];
                }
                if ("B6" in jsonValues) {
                    config.AnalogueCSample = parseInt(jsonValues["B6"], 16);
                }
                if ("B7" in jsonValues) {
                    config.AnalogueCSampleType = parseInt(jsonValues["B7"], 16);
                }
            }
        }

        // Pressure 2 channel

        if ("C1" in jsonValues) {
            // Channel D
            config.AnalogueDSchedule = parseInt(jsonValues["C1"], 16);
            if (config.AnalogueDSchedule == 0) {
                config.AnalogueD = false;
            }
            else {
                config.AnalogueD = true;

                if ("C4" in jsonValues) {
                    if (is2FlowChariot(config.SWversion)) {
                        // C4 updated to Sampling time in fw2.5
                        config.AnalogueDSampleTime = parseInt(jsonValues["C4"], 16);
                    }
                    else {
                        config.AnalogueDMultiplier = parseInt(jsonValues["C4"], 16)
                        config.AnalogueDMultiplierStr = config.AnalogueDMultiplier.toString();
                    }
                }
                if ("C2" in jsonValues) {
                    config.AnalogueDUnits = jsonValues["C2"];
                }
                if ("C3" in jsonValues) {
                    config.AnalogueDRef = jsonValues["C3"];
                }
                if ("C6" in jsonValues) {
                    config.AnalogueDSample = parseInt(jsonValues["C6"], 16);
                }
                if ("C7" in jsonValues) {
                    config.AnalogueDSampleType = parseInt(jsonValues["C7"], 16);
                }
            }
        }

    }

    return config;
}

// Build Update JSON by comparing org with config and updating any changed fields

export function jsonFromConfig(org?: ChariotConfig,
    config?: ChariotConfig,
    ): string {
    let updateJson = '{';

    if (!org) {
        // Create empty config if none specified
        org = CreateChariotConfig();
    }

    if (config) {

        // Any existing updates, which may be overidden later
        if (config?.UpdateJSON) {
            updateJson = updateJson + config.UpdateJSON.substr(1, config.UpdateJSON.length - 2) + ',';
        }

        // SiteID
        if (org.Sitename != config.Sitename) {
            updateJson = updateJson + '"I7": "' + config.Sitename.substr(0,25) + '",';
        }
        // Device GPS location
        if (config.Latitude != null && config.Longitude != null) {
            if (org.Latitude != config.Latitude
                || org.Longitude != config.Longitude) {
                updateJson = updateJson + '"I6": "' + getCooord(config.Latitude, config.Longitude) + '",';
                console.log('I6 ' + updateJson);
            }
            console.log('I6 NO');
        }
     
        // Communication
        if (config.TransmissionInterval != org.TransmissionInterval) {
            updateJson = updateJson + '"S2": "' + config.TransmissionInterval?.toString(16).padStart(4,"0") + '",';
        }
        if (config)
        if (config.PreferredLPWAN != org.PreferredLPWAN) {
            updateJson = updateJson + '"S3": "' + config.PreferredLPWAN + '",';
        }

        if (config.MQTTServer != org.MQTTServer) {
            updateJson = updateJson + '"S4": "' + config.MQTTServer.substr(0, 25) + '",';
        }
        if (config.Dormant) {
            updateJson = updateJson + '"S0": "01",'
        }

        // Channels

        // Channel A
        if (config.FlowA) {
            // FlowA now enabled 
            if (config.FlowAPulseType != org.FlowAPulseType) {
                updateJson = updateJson + '"A0": "' + config.FlowAPulseType.toString(16).padStart(2, "0") + '",';
            }
            if (config.FlowASchedule != org.FlowASchedule) {
                updateJson = updateJson + '"A1": "' + config.FlowASchedule.toString(16).padStart(4, "0") + '",';
            }
            if (config.FlowARef != org.FlowARef) {
                updateJson = updateJson + '"A3": "' + config.FlowARef.substr(0, 15) + '",';
            }
            if (config.FlowAMultiplier != org.FlowAMultiplier) {
                const multiplierStep = determineMultiplierStep(config.FlowAMultiplier);
                updateJson = updateJson + '"A4": "' + multiplierStep.toString(16).padStart(2, "0") + '",';
                const multiplierValue = config.FlowAMultiplier / Math.pow(10, (multiplierStep - 5));
                updateJson = updateJson + '"A5": "' + multiplierValue.toString(16).padStart(4, "0") + '",';
            }
            if (config.FlowAUnits != org.FlowAUnits) {
                updateJson = updateJson + '"A2": "' + config.FlowAUnits + '",';
            }
        }
        else {
            if (org.FlowA) {
                // FlowA now disabled
                updateJson = updateJson + '"A1": "0000",';
            }
        }

        // Channel B
        if (is2FlowChariot(config.SWversion) && config.FlowB) {
            // FlowB now enabled 
            if (config.FlowBPulseType != org.FlowBPulseType) {
                updateJson = updateJson + '"X0": "' + config.FlowBPulseType.toString(16).padStart(2, "0") + '",';
            }
            if (config.FlowBSchedule != org.FlowBSchedule) {
                updateJson = updateJson + '"X1": "' + config.FlowBSchedule.toString(16).padStart(4, "0") + '",';
            }
            if (config.FlowBRef != org.FlowBRef) {
                updateJson = updateJson + '"X3": "' + config.FlowBRef.substr(0, 15) + '",';
            }
            if (config.FlowBMultiplier != org.FlowBMultiplier) {
                const multiplierStep = determineMultiplierStep(config.FlowBMultiplier);
                updateJson = updateJson + '"X4": "' + multiplierStep.toString(16).padStart(2, "0") + '",';
                const multiplierValue = config.FlowBMultiplier / Math.pow(10, (multiplierStep - 5));
                updateJson = updateJson + '"X5": "' + multiplierValue.toString(16).padStart(4, "0") + '",';
            }
            if (config.FlowBUnits != org.FlowBUnits) {
                updateJson = updateJson + '"X2": "' + config.FlowBUnits + '",';
            }
        }
        else {
            if (is2FlowChariot(config.SWversion) && org.FlowB) {
                // FlowA now disabled
                updateJson = updateJson + '"X1": "0000",';
            }
        }

        // Channel C
        if (config.AnalogueC) {
            // AnalogueC now enabled
            if (config.AnalogueCSchedule != org.AnalogueCSchedule) {
                updateJson = updateJson + '"B1": "' + config.AnalogueCSchedule.toString(16).padStart(4, "0") + '",';
            }
            if (config.AnalogueCRef != org.AnalogueCRef) {
                updateJson = updateJson + '"B3": "' + config.AnalogueCRef.substr(0, 15) + '",';
            }

            if (config.AnalogueCUnits != org.AnalogueCUnits) {
                updateJson = updateJson + '"B2": "' + config.AnalogueCUnits + '",';
            }
            if (is2FlowChariot(config.SWversion))
            {
               /* if (config.AnalogueCSampleTime != org.AnalogueCSampleTime) {
                    updateJson = updateJson + '"B4": "' + config.AnalogueCSampleTime.toString(16).padStart(4, "0") + '",';
                }
                if (config.AnalogueCSample != org.AnalogueCSample) {
                    updateJson = updateJson + '"B6": "' + config.AnalogueCSample.toString(16).padStart(2, "0") + '",';
                }
                if (config.AnalogueCSampleType != org.AnalogueCSampleType) {
                    updateJson = updateJson + '"B7": "' + config.AnalogueCSampleType.toString(16).padStart(2, "0") + '",';
                }*/

             //   const b4 = config.AnalogueCSampleTime.toString(16).padStart(4, "0");
                const b6 = config.AnalogueCSample.toString(16).padStart(2, "0");
                const b7 = config.AnalogueCSampleType.toString(16).padStart(2, "0");
                //  updateJson = updateJson + '"B4": "' + b6 + b7 + b4 + '",';
                //updateJson = updateJson + '"B4": "' + b4 + '",';
                updateJson = updateJson + '"B6": "' + b6 + '",';
                updateJson = updateJson + '"B7": "' + b7 + '",';
                console.log("updateJson: " + updateJson);
            }
            else {
                if (config.AnalogueCMultiplier != null && config.AnalogueCMultiplier != org.AnalogueCMultiplier) {
                    updateJson = updateJson + '"B4": "' + config.AnalogueCMultiplier.toString(16).padStart(4, "0") + '",';
                }
            }
        }
        else {
            if (org.AnalogueC) {
                // AnalogueC now disabled
                updateJson = updateJson + '"B1": "0000",';
            }
        }
        // Channel D
        if (config.AnalogueD) {
            // AnalogueD now enabled
            if (config.AnalogueDSchedule != org.AnalogueDSchedule) {
                updateJson = updateJson + '"C1": "' + config.AnalogueDSchedule.toString(16).padStart(4, "0") + '",';
            }
            if (config.AnalogueDRef != org.AnalogueDRef) {
                updateJson = updateJson + '"C3": "' + config.AnalogueDRef.substr(0, 15) + '",';
            }
            if (config.AnalogueDUnits != org.AnalogueDUnits) {
                updateJson = updateJson + '"C2": "' + config.AnalogueDUnits + '",';
            }
            if (is2FlowChariot(config.SWversion)) {
                if (config.AnalogueDSampleTime != org.AnalogueDSampleTime) {
                    updateJson = updateJson + '"C4": "' + config.AnalogueDSampleTime.toString(16).padStart(4, "0") + '",';
                }
                if (config.AnalogueDSample != org.AnalogueDSample) {
                    updateJson = updateJson + '"C6": "' + config.AnalogueDSample.toString(16).padStart(2, "0") + '",';
                }
                if (config.AnalogueDSampleType != org.AnalogueDSampleType) {
                    updateJson = updateJson + '"C7": "' + config.AnalogueDSampleType.toString(16).padStart(2, "0") + '",';
                }
            }
            else {
                if (config.AnalogueDMultiplier != null && config.AnalogueDMultiplier != org.AnalogueDMultiplier) {
                    updateJson = updateJson + '"C4": "' + config.AnalogueDMultiplier.toString(16).padStart(4, "0") + '",';
                }
            }

        }
        else {
            if (org.AnalogueD) {
                // AnalogueD now disabled
                updateJson = updateJson + '"C1": "0000",';
            }
        }

        if (config.BatteryReset) {
            updateJson = updateJson + '"BI": "FF",' 
        }



        // Custom entries
        if (config.Custom != "") {
            const lines = config.Custom.split(/\r?\n/);
            lines.map((line: string) => {
                if (line.indexOf('=') == -1) {
                    // CMD with no value
                    if (line != '') {
                        updateJson = updateJson + '"' + line + '": "?",';
                    }
                }
                else {
                    const entry = line.split("=");
                    if (entry[0] != "") {
                        updateJson = updateJson + '"' + entry[0] + '":"' + entry[1] + '",';
                    }
                }
            });
        }

    }


    // Remove trailing , before }
    if (updateJson != "{") {
        // Always add SV? to push changes to NFC
        updateJson = updateJson + '"SV": "?",';
        updateJson = updateJson.substr(0, updateJson.length - 1) + "}";
        return updateJson;
    }
    else {
        return "{}";
    }

}


