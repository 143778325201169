import React from 'react'

const MultipleDevicesIcon: React.FC = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="currentColor" style={{overflow: "visible", verticalAlign: "middle", width: "2em", height: "1em", marginTop: "-8px" } }>
        <g id="Layer_1" data-name="Layer 1">
            <path d="M6.8,12.23h-3.39c-.31,0-.56.25-.56.56v6.78c0,.31.25.56.56.56h3.39c.31,0,.56-.25.56-.56v-6.78c0-.31-.25-.56-.56-.56M5.11,15.62c-.62,0-1.13-.51-1.13-1.13s.51-1.13,1.13-1.13,1.13.51,1.13,1.13-.51,1.13-1.13,1.13M2.32,10.57l.8.8c.51-.51,1.22-.82,2-.82s1.48.32,2,.82l.8-.8c-.72-.72-1.71-1.16-2.79-1.16s-2.08.44-2.79,1.16M5.11,7.15c-1.72,0-3.27.69-4.39,1.82l.8.8c.92-.91,2.19-1.48,3.6-1.48s2.68.57,3.59,1.49l.8-.8c-1.12-1.13-2.67-1.82-4.39-1.82" />
            <path d="M13.74,9.34h-3.39c-.31,0-.56.25-.56.56v6.78c0,.31.25.56.56.56h3.39c.31,0,.56-.25.56-.56v-6.78c0-.31-.25-.56-.56-.56M12.04,12.73c-.62,0-1.13-.51-1.13-1.13s.51-1.13,1.13-1.13,1.13.51,1.13,1.13-.51,1.13-1.13,1.13M9.25,7.67l.8.8c.51-.51,1.22-.82,2-.82s1.48.32,2,.82l.8-.8c-.72-.72-1.71-1.16-2.79-1.16s-2.08.44-2.79,1.16M12.04,4.26c-1.72,0-3.27.69-4.39,1.82l.8.8c.92-.91,2.19-1.48,3.6-1.48s2.68.57,3.59,1.49l.8-.8c-1.12-1.13-2.67-1.82-4.39-1.82" />
            <path d="M20.59,12.23h-3.39c-.31,0-.56.25-.56.56v6.78c0,.31.25.56.56.56h3.39c.31,0,.56-.25.56-.56v-6.78c0-.31-.25-.56-.56-.56M18.9,15.62c-.62,0-1.13-.51-1.13-1.13s.51-1.13,1.13-1.13,1.13.51,1.13,1.13-.51,1.13-1.13,1.13M16.1,10.57l.8.8c.51-.51,1.22-.82,2-.82s1.48.32,2,.82l.8-.8c-.72-.72-1.71-1.16-2.79-1.16s-2.08.44-2.79,1.16M18.9,7.15c-1.72,0-3.27.69-4.39,1.82l.8.8c.92-.91,2.19-1.48,3.6-1.48s2.68.57,3.59,1.49l.8-.8c-1.12-1.13-2.67-1.82-4.39-1.82" />
        </g>
    </svg>)
}

    export default MultipleDevicesIcon