import React, { useState, useEffect, ReactNode, useRef } from 'react'
import { Button, Checkbox, Chip, useTheme } from '@mui/material';
import ClipLoader from "react-spinners/ClipLoader";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from "@mui/material/IconButton";
import ApiFailed from '../../Utils/ApiFailed';
import { withStyles, createStyles } from '@mui/styles';
import { DataGrid, Column, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring, Format, Export, HeaderFilter, Selection } from 'devextreme-react/data-grid';
import TextField from '@mui/material/TextField';
import { CreateUrl, CallGetAPI, CallPutAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import { APILoggerDetail, buildAPILoggerDetail } from 'models/APILoggerDetail';
import GoogleMapComponent from '../../components/Map/NewGoogleMaps2024';
import CHF from "assets/CHF.png";
import CHF1P from "assets/CHF1P.png";
import CHF2P from "assets/CHF2P.png";
import ZL from "assets/ZoneLog_Logger.png"
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ToolkitCHR from '../../components/Configuration/ToolkitCHR';
import ToolkitTOR from '../../components/Configuration/ToolkitTOR';
import yellowMarker from '../../assets/mapLocationMarkers/pinYellow.png';
import redMarker from '../../assets/mapLocationMarkers/pinRed.png';
import { isLCLLogger } from 'variables/chariotProductIds';
import CloseIcon from '@mui/icons-material/Close';
import { Redirect } from "react-router-dom";
import { Accordion, Item } from 'devextreme-react/accordion';
import MultiLoggerGraphV2 from "../Groups/MultiLoggerGraphV2";
import MultiLoggerGraph from '../Groups/MultiLoggerGraph';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { APIGetLoggerReadingsModel } from "models/APIGetLoggerReadingsModel";
import moment from 'moment'
import { GutermannModelConverter } from "../../Utils/GutermannModelCoverter";
import SiteModal from './NewSiteModal2024'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import ImagesPanel from 'components/ImageUpload/ImagesPanel'
import UploadDialog from "../../components/ImageUpload/UploadDialog"



interface Props {
    classes: {
        root: string;
        heading: string;
        cardTitleWhite: string;
    };
    history: {
        goBack: () => void;
    };
    location: {
        state: {
            siteId: number;
            siteName: string;
        };
        search: string;
    };
}

interface Site {
    id: number;
    siteNameUserFriendly: string;
    siteName: string;
    created: Date;
    deleted: Date | null;
    latitude: number | null;
    longitude: number | null;
    groups: Array<{ id: number; displayName: string }>;
    defaultSite: boolean;
    fkCompanyId: number;
    fkEASiteId: number | null;
    EASite: string;
    lastUpdate: Date | null;
    alarmState: number | null;
    alarmsEnabled: number | null;
    Loggers: Array<APILoggerDetail>;
    LastReading?: Date | null;
    HasActiveLoggers?: string;
    boundary?: string;

}


interface Coordinates {
    lat: number
    lng: number
}


interface GPS {
    position: { lat: number, long: number }
    icon: string
    serial: number
    id: number
    tooltip: string


}

interface ReadingsState {
    state: {
        loading: boolean;
        readingsData: Array<APIGetLoggerReadingsModel>;
    };
}

interface BoundaryStyling {
    strokeWeight: number
    strokeColor: string
    areaColor: string
}

interface RedirectProps {
    siteName: string;
    siteId: number | null;
    serial: string;
    deviceId: number | null;
}

interface Company {
    name: string
    id: number
    isParent: boolean
}


//const styles: Record<string, any> = {
//    formControl: {
//        minWidth: 120,
//    },
//    cardCategoryWhite: {
//        "&,& a,& a:hover,& a:focus": {
//            color: "rgba(255,255,255,.62)",
//            margin: "0",
//            fontSize: "14px",
//            marginTop: "0",
//            marginBottom: "0"
//        },
//        "& a,& a:hover,& a:focus": {
//            color: "#FFFFFF"
//        }
//    },
//    cardTitleWhite: {
//        position: 'absolute',
//        top: '50%',
//        transform: 'translateY(-50%)',
//        color: "#FFFFFF",
//        minHeight: "auto",
//        fontWeight: "300",
//        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
//        textDecoration: "none",
//        "& small": {
//            color: "#777",
//            fontSize: "65%",
//            fontWeight: "400",
//            lineHeight: "1"
//        }
//    }
//};

const style = createStyles(styles);
const SiteDetail: React.FC<Props> = ({ history, location, classes }) => {

    const channelMLGReadings:ReadingsState = useRef(null)
    const containerStyle = {
        height: "620px",
        width: "100%",
        marginLeft: "5px",
        minHeight: "500px",
    };

    const defaultCenter = window.location.hostname.includes("zonelog.net")
        ? { lat: 47.17442830752493, lng: 8.517189402835617 }
        : { lat: 50.744094943532446, lng: -3.99597602646894 };


    const [site, setSite] = useState<Site>({})
    const mapRef = useRef(null);
    const [loading, setLoading] = useState<boolean>(false)
    const [tableHidden, setTableHidden] = useState<boolean>(false)
    const [loggerData, setLoggerData] = useState<Array<APILoggerDetail>>([])
    const [historicLoggerData, setHistoricLoggerData] = useState<Array<APILoggerDetail>>([])
    const [mapKey, setMapKey] = useState<string>("")
    const [center, setCenter] = useState<Coordinates>(defaultCenter)
    const [markers, setMarkers] = useState<Array<GPS>>([])
    const [hasBoundary, setHasBoundary] = useState<boolean>(false)
    const [polygonPath, setPolygonPath] = useState([]);
    const [boundaryStyling, setBoundaryStyle] = useState<BoundaryStyling>(
        {
            strokeWeight: 2,
            strokeColor: '#FF0000',
            areaColor: '#FF0000'
        })
    const [hoveredMarker, setHoveredMarker] = useState(null)
    const [chariotModal, setChariotModal] = useState(false)
    const [torModal, setTorModal] = useState(false)
    const [tempDevice, setTempDevice] = useState<Array<APILoggerDetail>>([])
    const [authorized, setAuthorized] = useState<boolean>(true)
    const [redirect, setRedirect] = useState<boolean>(false)
    const [redirectPath, setRedirectPath] = useState<string>("")
    const [redirectProps, setRedirectProps] = useState<RedirectProps>({
        siteName: "",
        siteId: null,
        serial: "",
        deviceId: null
    })
    const [loggerIds, setLoggerIds] = useState([])
    const [modal, setModal] = useState(false)
    const [loggersToBeAssigned, setLoggersToBeAssigned] = useState([])
    const [loggersToBeUnassigned, setLoggersToBeUnassigned] = useState<Array>([])
    const [companies, setCompanies] = useState<Array<Company>>([])
    const [lastDataDate, setLastDataDate] = useState<Date | null>()
    const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false)
    useEffect(() => {
        reloadData()
    }, [])

    const reloadData = ():void => {
        setLoading(true)
        CallGetAPI(CreateUrl('/api/aquaguard/site?id=' + location.state.siteId), {})
            .then(data => {

                setPolygonPath([])
                const markersTemp = []
                const loggerIdsTemp = []
                setSite(data)
                console.log(site)
                setLoggerData(data.loggers)
                if (data.loggers.length > 0) {
                    data.loggers.forEach((logger:APILoggerDetail) => {

                        let imgSrc = ""
                        if (logger.model.slice(0, 2) === 'CH') {
                            imgSrc = CHF
                            if (logger.model.includes('2P')) {
                                imgSrc = CHF2P
                            } else if (logger.model.includes('1P')) {
                                imgSrc = CHF1P
                            }
                        }
                        markersTemp.push({
                            position: logger.latitude && logger.longitude ? { lat: logger.latitude, lng: logger.longitude } : { lat: defaultCenter.lat, lng: defaultCenter.lng },
                            key: logger.loggerId,
                            serial: logger.serial,
                            tooltip: {
                                model: window.location.hostname.includes('zonelog.net') ? GutermannModelConverter(logger.model) : logger.model,
                                img: window.location.hostname.includes('zonelog.net') ? ZL : imgSrc,
                                deviceName: logger.deviceName
                            },
                            icon: logger.latitude && logger.longitude ? yellowMarker : redMarker
                            
                        })

                        loggerIdsTemp.push(logger.loggerId)


                    })
                }

                if (data.boundary) {
                    const boundaryData = JSON.parse(data.boundary)
                    if (Array.isArray(boundaryData.path)) {
                        
                        setPolygonPath(boundaryData.path)
                        setBoundaryStyle(boundaryData.styling)
                        setHasBoundary(true)
                    }
                }

                setMarkers(markersTemp)

                if (loggerIdsTemp.length === 1) {
                    sessionStorage.setItem('selectedLoggers', loggerIdsTemp[0].toString())
                } else if (loggerIdsTemp.length > 1) {
                   
                   sessionStorage.setItem('selectedLoggers', loggerIdsTemp.map(logger => logger.toString()).join())
                } else {
                    sessionStorage.setItem('selectedLoggers', "")
                }
                setLoggerIds(loggerIdsTemp)
                CallGetAPI(CreateUrl('/api/aquaguard/SitesForCompany?companyId=' + sessionStorage.getItem('companyId') + '&filterGroup=' + sessionStorage.getItem('filterGroupId')), {})
                    .then(data => {
                        const unAssignedLoggers = []
                        data.map(site => {
                            if (site.defaultSite) {
                                if (site.loggers.length > 0) {
                                    site.loggers.forEach(logger => unAssignedLoggers.push(logger))
                                }
                                unAssignedLoggers.sort((a, b) => a.deploymentDate + b.deploymentDate)
                            }
                        })
                        setLoggersToBeAssigned(unAssignedLoggers)
                        
                    })
            })
            .catch(err => {
                setAuthorized(false)
                console.log(err)
            })
        //Historic Site Logger Info
            CallGetAPI(CreateUrl('/api/aquaguard/HistoryForSite?siteId=' + location.state.siteId), {})
                .then(data => {
                    let lastDataDateTemp = moment(0)
                    data.forEach(item => {
                        if (item.endDate && moment(item.endDate).isSameOrAfter(moment(lastDataDateTemp))) {
                           lastDataDateTemp = item.endDate
                        }
                    })
                    console.log(lastDataDateTemp)
                    setLastDataDate(lastDataDateTemp)
                    setHistoricLoggerData(data.filter(item => item.endDate !== null))
                    setLoading(false)
                })

        
    }

    

    const renderConfigBtn = (data): ReactNode => {
        
        return <Button variant="contained" onClick={():void => handleConfigClick(data)}>Configure</Button>
    }

    const renderOverviewBtn = (data): ReactNode => {
        return <Button variant="contained" color="secondary" onClick={(): void => onMarkerClick(data.data.serial) }>Overview</Button>
    }

    const handleConfigClick = (data):void => {
        setTempDevice(data.data)
        if (isLCLLogger(data.data.model)) {
            toggleChariotModal()
        } else {
            toggleTorModal()
        }
    }


    const onMapLoad = (map) => {
        mapRef.current = map;
        map.setMapTypeId("hybrid")
        fitBoundsToMarkers(); // Fit bounds after the map loads

        if (!hasBoundary && markers.length === 1) {
            setTimeout(() => {
                mapRef.current.setZoom(18)
            }, 500)
        }
    };

    const onPolygonClick = () => {
        /* do nothing */
    }

    const updatePolygonPath = () => {
        /*do nothing in this component */
    }


    const onMarkerClick = (serial: number): void => {
        
        const clickedMarker = loggerData.filter(logger => logger.serial === serial)

        setRedirect(true)
        setRedirectPath(!isLCLLogger(clickedMarker[0].model) ? '/portal/DeviceDetail' : '/portal/LCLDeviceDetail')
        setRedirectProps({
            serial: serial,
            mapKey: 'test'
        })
    }

    const handleTooltipShow = (serial: number): void => {

        setHoveredMarker(serial)
    }

    const handleTooltipHide = (): void => {
        setHoveredMarker(null)
    }

    const fitBoundsToMarkers = () => {

        if (mapRef.current && markers.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            markers.forEach((marker) => {
                bounds.extend(marker.position);
            });
            if (hasBoundary) {
                polygonPath.forEach((corner) => {
                    bounds.extend(corner)
                })
            }
            mapRef.current.fitBounds(bounds);
        } else if (mapRef.current && polygonPath.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            polygonPath.forEach((corner) => {
                bounds.extend(corner)
            })
            mapRef.current.fitBounds(bounds);
        }
    };

    const toggleChariotModal = () => {
        setChariotModal(!chariotModal)
    }

    const toggleTorModal = () => {
        setTorModal(!torModal)
    }


    const blankFunction = () => {
        return undefined;
    };

    const renderModelCol = (data):ReactNode => {

        if (window.location.hostname.includes('zonelog.net')) {
            return (<p>{GutermannModelConverter(data.data.model)}</p>)
        } else {
            return (<p>{data.data.model}</p>)
        }
    }

    const renderLastConnection = (data): ReactNode => {

        return (<p>{formatDate(data.data.lastConnection)}</p>)
    }
    const renderDeployDate = (data):ReactNode => {
        return (<p>{formatDate(data.data.deploymentDate)}</p>)
    }

    const formatDate = (date):string => {
        return moment(date).format('DD MMM YYYY HH:mm')
    }

    const toggleEditModal = (): void => {
        CallGetAPI(CreateUrl(`/api/aquaguard/GetCompanyAndChildren?companyGUID=${sessionStorage.getItem('companyGuid')}`), {})
            .then(companyData => {
                const companiesTemp = [...companies]
                companyData.forEach(company => companiesTemp.push({ name: company.name, id: company.id, isParent: company.parentId ? false : true }))
                companiesTemp.sort(function (x, y) {
                    return Number(y.isParent) - Number(x.isParent)
                })
                setCompanies(companiesTemp)
            })
                
        setModal(!modal)
    }

    const handleTextFieldChange = (name: string, value: string): void => {

        const siteTemp = { ...site }
        siteTemp[name] = value
        setSite(siteTemp)
    }

    const handleLoggersToBeUnassigned = (logger) => {
        const loggersToBeUnassignedTemp = [...loggersToBeUnassigned, logger]
        const siteTemp = site
        if (siteTemp.loggers.length > 1) {
            siteTemp.loggers = siteTemp.loggers.filter(siteLogger => siteLogger.serial !== logger.serial)
        } else {
            siteTemp.loggers = []
        }
        setSite(siteTemp)
        setLoggersToBeUnassigned(loggersToBeUnassignedTemp)
    }


    const handleSiteChange = (selected, assignDate, polygonPath, boundaryStyling, hasBoundary, tempName): void => { 
        let boundaryInfo = null
        if (Array.isArray(polygonPath) && polygonPath.length > 0) {
            boundaryInfo = JSON.stringify({ path: polygonPath, styling: boundaryStyling })
        }
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    Id: site.siteId,
                    SiteName: tempName.siteRefTemp,
                    SiteNameUserFriendly: tempName.siteNameTemp,
                    Created: site.created,
                    Deleted: site.deleted,
                    Latitude: site.Latitude || 0,
                    Longitude: site.Longitude || 0,
                    FKCompanyId: site.fkCompanyId,
                    DefaultSite: false,
                    LastUpdate: site.lastUpdate,
                    FkEASiteId: null,
                    Boundary: hasBoundary ? boundaryInfo : null
                })
            };

            CallPutAPI(CreateUrl('/api/Aquaguard/Site/' + site.siteId), requestOptions)
                .then(data => {
                    createSuccessNotification()
                    if (selected.length > 0) {

                        const loggerIds: Array<{ Id: number }> = [];
                        selected.forEach(logger => loggerIds.push({ Id: logger.loggerId }))
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                siteId: site.siteId,
                                lastUpdate: site.lastUpdate,
                                loggers: loggerIds,
                                assignLogReadingsFromDate: assignDate,
                                deploymentGuid: null
                            })
                        };

                        CallPostAPI(CreateUrl('/api/Aquaguard/AssignLoggersToSite'), requestOptions)
                            .then(data => {
                                setLoading(true)
                                createSuccessNotification()
                                setTimeout(() => reloadData(), 1000)
                            })
                            .catch(err => {
                                createErrorNotification()
                                console.log(err)
                            })
                    }
                    handleUnAssigningLoggersCall()
                    if (selected.length === 0 && loggersToBeUnassigned.length === 0) {

                        setTimeout(() => reloadData(), 1000)
                    }
                })
                .catch(err => {
                    createErrorNotification()
                    console.log(err)
                })

        


    }

    const handleUnAssigningLoggersCall = (): void => {

        if (loggersToBeUnassigned.length > 0) {
            const loggerIds: Array<{ Id: number }> = [];
            loggersToBeUnassigned.map((l) => {
                loggerIds.push({ Id: l.loggerId });
            });
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    loggers: loggerIds,
                    shutdown: false,
                    force: true
                })
            };
            CallPostAPI(CreateUrl('/api/Aquaguard/UndeployLoggers'), requestOptions)
                .then(data => {
                    createSuccessNotification()
                    setLoggersToBeUnassigned([])
                })
                .catch(err => {
                    createErrorNotification()
                    setLoggersToBeUnassigned([])
                    console.log(err)
                })
                setLoading(true)
            setTimeout(() => reloadData(), 1000)
        }

    } 

    const handleUploadButtonClick = ():void => {
        setUploadDialogOpen(!uploadDialogOpen)
    }

    const createSuccessNotification = (): void => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    const createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    const renderSerialTemplate = ({ data }): React.ReactNode => {
        const theme = useTheme()
        return <Chip
            label={data.serial}
            sx={{ backgroundColor: theme.palette.grey.main, color: theme.palette.grey.contrastText }}
            onClick={():void => onMarkerClick(data.serial) }
        />
    }

    


    return (
        <>
            {authorized ? ( !redirect ? <div>
                {loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={loading}
                        />
                    </div>}
                {!loading && <div>
                    {tableHidden &&
                        <ApiFailed />
                    }
                    {!tableHidden &&
                        <div>
                            {site.siteName && <SiteModal
                                open={modal}
                                modalTitle={`Edit Site - ${site?.siteNameUserFriendly ? site?.siteNameUserFriendly : ""}`}
                                onCancel={toggleEditModal}
                                site={site}
                                handleTextFieldChange={handleTextFieldChange}
                                unAssignedLoggers={loggersToBeAssigned}
                                editSite={true}
                                handleLoggersToBeUnassigned={handleLoggersToBeUnassigned}
                                onSubmit={handleSiteChange}
                                companies={companies }
                            />}
                            <Dialog
                                fullScreen
                                open={chariotModal}
                            >
                                <AppBar sx={{ position: 'relative' }}>
                                    <Toolbar>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={(): void => toggleChariotModal()}
                                            aria-label="close"
                                        >
                                            <ArrowBackIcon style={{ fill: "black" }} />
                                        </IconButton>
                                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                            {window.location.hostname.includes('zonelog.net') ? "ZONELOG Configuration" : "Chariot Configuration"}
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                                {tempDevice &&
                                    <ToolkitCHR
                                        serial={tempDevice?.serial}
                                        product={tempDevice?.model}
                                        model={tempDevice?.modelId}
                                        channels={tempDevice?.channelsAvail}
                                        meterConfig={tempDevice?.meterConfig}
                                    />
                                }
                            </Dialog>
                            <Dialog
                                fullScreen
                                open={torModal}
                            >
                                <AppBar sx={{ position: 'relative' }}>
                                    <Toolbar>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={(): void => toggleTorModal()}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                            {tempDevice?.model} Configuration
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                                {tempDevice &&
                                    <ToolkitTOR
                                        serial={tempDevice?.serial}
                                        product={tempDevice?.model}
                                        model={tempDevice?.modelId}
                                    />
                                }
                            </Dialog>
                            <UploadDialog
                                show={uploadDialogOpen}
                                title="Upload Image"
                                siteId={location.state.siteId}
                                loggerId={0}
                                onSubmit={(): void => {
                                    setUploadDialogOpen(false)
                                    setTimeout(() => reloadData(), 500)

                                }}
                                onCancel={(): void => setUploadDialogOpen(false)}
                            />
                            <GridContainer >
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>
                                        <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                            <div style={{ display: 'inline' }}>
                                                <IconButton
                                                    style={{ display: 'inline' }}
                                                    onClick={(): void => history.goBack()}
                                                    size="large"><ArrowBackIcon style={{ fill: "white" }} /></IconButton>
                                                <h4 style={{ display: 'inline' }} className={classes.cardTitleWhite}>{location.state.siteName}</h4>
                                                
                                            </div>
                                            <Button style={{ marginRight: "10px" }} onClick={(): void => { toggleEditModal()}} variant="contained">Edit Site</Button>
                                        </CardHeader>
                                        <CardBody>
                                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                                <div style={{ width: "60%", margin: "0 10px 0 0"}}>
                                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                                        <h4>Active Devices At Site</h4>
                                                        <DataGrid
                                                            dataSource={loggerData}
                                                        >
                                                            <Column dataField="serial" dataType="number" cellRender={renderSerialTemplate} />
                                                            <Column dataField="deviceName" dataType="string" />
                                                            <Column dataField="model" cellRender={renderModelCol} caption="Model" />
                                                            <Column dataField="lastConnection" cellRender={renderLastConnection} />
                                                            <Column dataField="deploymentDate" cellRender={renderDeployDate} caption="Assigned To Site"/>
                                                            <Column dataField="latitude" dataType="number" />
                                                            <Column dataField="longitude" dataType="number" />
                                                            <Column cellRender={renderConfigBtn} caption="Configure"/>
                                                            <Column cellRender={renderOverviewBtn } caption="Overview" />
                                                        </DataGrid>
                                                    </div>
                                                    {historicLoggerData.length > 0 && <div>
                                                        <h4>Historic Devices At Site</h4>
                                                        <DataGrid
                                                            dataSource={historicLoggerData}
                                                            keyExpr="id"
                                                        >
                                                            <StateStoring enabled={true} type="localStorage" storageKey="historyListGrid" />
                                                            <FilterRow visible={true} />
                                                            <Column dataField="id" visible={false} dataType="number" />
                                                            <Column dataField="serial" dataType="string" sortOrder={'asc'} />
                                                            <Column dataField="siteId" dataType="string" caption="Device Name" />
                                                            <Column dataField="startDate" dataType="datetime" />
                                                            <Column dataField="endDate" dataType="datetime" />
                                                            <Pager allowedPageSizes={[10, 20, 50]} showPageSizeSelector={true} />
                                                            <Paging defaultPageSize={10} />
                                                        </DataGrid>
                                                    </div>}
                                                </div>
                                                <div style={{width: "40%", margin: "0 0 20px 10px"} }>
                                                {(markers.length > 0 || polygonPath.length > 0) && <GoogleMapComponent
                                                        mapKey={mapKey}
                                                        containerStyle={containerStyle}
                                                        center={center}
                                                        onMapLoad={onMapLoad}
                                                        markers={markers}
                                                        hasBoundary={hasBoundary}
                                                        createBoundary={false}
                                                        polygonPath={polygonPath}
                                                        boundaryStyling={boundaryStyling}
                                                        onPolygonClick={onPolygonClick}
                                                        updatePolygonPath={updatePolygonPath}
                                                        handleMouseOverMarker={handleTooltipShow}
                                                        handleMouseOutMarker={handleTooltipHide}
                                                        onMarkerClick={onMarkerClick}
                                                        hoveredMarker={hoveredMarker}
                                                        editBoundary={false}
                                                        showTooltip={true}
                                                    />}
                                                </div>
                                            </div>
                                            {console.log(loggerData, historicLoggerData) }
                                            {(loggerData.length > 0 || historicLoggerData.length > 0) && <Accordion
                                                collapsible={true}
                                                multiple={false}
                                                defaultSelectedIndex={-1}

                                            >
                                                <Item title="Graph">
                                                    <div>
                                                        {loggerData.length > 0 ?
                                                            <MultiLoggerGraphV2 showDates={true} location={{ state: { groupId: 0, loggers: loggerIds, showBackButton: false, site: null } }} history={{ goBack: blankFunction }} />:
                                                            <MultiLoggerGraphV2 endDateProps={lastDataDate} startDateProps={moment(lastDataDate).subtract(7, 'days')} showDates={true} location={{ state: { groupId: 0, loggers: [], showBackButton: false, site: location.state.siteId } }} history={{ goBack: blankFunction }} />}
                                                    </div>
                                                </Item>
                                                <Item title="Images">
                                                    <div>
                                                        <ImagesPanel siteId={location.state.siteId} />
                                                        <Button
                                                            variant="contained"
                                                            onClick={(): void => handleUploadButtonClick()}
                                                        >
                                                            Upload
                                                        </Button>
                                                    </div>
                                                </Item>
                                            </Accordion>}
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </div>
                    }

                </div>}
            </div> : <Redirect push to={{
                pathname: redirectPath,
                state: redirectProps
            }} />) :
            <Redirect to={'/Login'} />} 
        </>
        )
}
export default withStyles(style)(SiteDetail);