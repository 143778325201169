import React, { useState, useEffect, useRef } from 'react'
import { DataGrid, Column, Export, Scrolling, FilterRow, StateStoring, Pager, Paging, HeaderFilter } from 'devextreme-react/data-grid';
import GridItem from "components/Grid/GridItem.js";
import DateBox from 'devextreme-react/date-box';
import GridContainer from "components/Grid/GridContainer.js";
import moment from 'moment'
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
interface Props {
    serial: number
}

interface Audit {
    details: string
    email: string
    serial: number 
    timestamp: Date
    type: string
    userGuid: string
}
const ConfigurationPanel: React.FC<Props> = ({ serial }) => {

    const gridRef = useRef(null)
    const [startDate, setStartDate] = useState<Date>(moment().subtract(7, 'days').toDate())
    const [endDate, setEndDate] = useState<Date>(moment().toDate())
    const [gridData, setGridData] = useState<Array<Audit>>()



    const startDateChanged = (date): void => {
        console.log(date)
        setStartDate(date.value)

    }

    const endDateChanged = (date) => {
        console.log(date)
        setEndDate(date.value)

    }

    const reloadData = () => {

        CallGetAPI(CreateUrl(`/api/aquaguard/audit?serial=${serial}&type=CONFIG&startDate=${moment(startDate).set({ 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }).format("YYYY-MM-DD HH:mm:ss")}&endDate=${moment(endDate).set({ 'hour': 23, 'minute': 59, 'second': 59, 'millisecond': 0 }).format("YYYY-MM-DD HH:mm:ss")}`), {})
            .then(data => {
                console.log(data)
                data.forEach(d => {
                    d.serial = parseInt(d.serial)
                })
                setGridData(data)
            })
    }

    const renderTimeStamp = ({ data }) => { 
        console.log(data)
        return moment(data.timeStamp).format("DD MMM YYYY HH:mm:ss")
    }


    useEffect(() => {
        reloadData()
    },[startDate, endDate])



    return (<div>
        <GridContainer>
        <GridItem xs={5} sm={5} md={5}>
            Start date :&nbsp;<DateBox id="startDate" displayFormat={"dd/MM/yyyy"} value={startDate} onValueChanged={startDateChanged} />
        </GridItem>
        <GridItem xs={5} sm={5} md={5}>
            End date :&nbsp;<DateBox id="endDate" displayFormat={"dd/MM/yyyy"} value={endDate} onValueChanged={endDateChanged} />
            </GridItem>
            {console.log(startDate) }
            <DataGrid
                ref={gridRef}
                dataSource={gridData}
                allowColumnResizing={true}
                columnAutoWidth={true}
                columnMinWidth={80}
                //onExporting={this.onExporting} >
                
            >
                <Scrolling mode="standard" useNative={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Column dataField="email" dataType="string" caption="Updated By" />
                <Column dataField="details" dataType="string" />
                <Column dataField="timeStamp" dataType="datetime" cellRender={renderTimeStamp} />
            </DataGrid>
        </GridContainer>
    </div>)
}

export default ConfigurationPanel