import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Button } from '@mui/material'
import { CreateUrl, CallGetAPI, CallPostAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import { DataGrid, Column as GridColumn, Export, GroupPanel, Grouping, FilterRow, Pager, Paging, StateStoring, HeaderFilter, Selection, Sorting } from 'devextreme-react/data-grid';
import { NotificationManager } from 'react-notifications';
interface Props {
    show: boolean
    onCancel: () => void
    logger: number
    onSubmit: () => void
}

interface CompaniesData {
    companyGUID: string;
    contactEmail: string;
    id: number;
    name: string;
    parentId: number | null
}

    const MoveLogger: React.FC<Props> = ({ show, onCancel, onSubmit,  logger }) => {
        const company = sessionStorage.getItem('companyGuid')
        useEffect(() => {
            
            
            CallGetAPI(CreateUrl(`/api/aquaguard/GetCompanyAndChildren?companyGUID=${company}`), {})
                .then((data: Array<CompaniesData>) => {
                    console.log(data)
                    setCompanies(data)
                })
                .catch(err => console.log(err))
        }, [])

    const [companies, setCompanies] = useState<Array<CompaniesData>>()


    const renderButton = (data) => {
        return (
            <Button variant="contained" onClick={():void => handleUpdateCompany(data)}>Select</Button>
        )
        }

        const handleUpdateCompany = (companyData) => {
            const confirm = window.confirm('Are you sure you want to move this logger?');

            if (confirm) {
                // Ensure logger exists and valid companyData is passed
                if (!companyData || !companyData.key || !companyData.key.companyGUID) {
                    console.error('Invalid companyData provided.');
                    return;
                }

                const loggerId = logger - 500000; // Ensure logger is defined somewhere
                const newCompanyGUID = companyData.key.companyGUID;

                // Check if loggerId and newCompanyGUID are valid
                if (isNaN(loggerId) || !newCompanyGUID) {
                    console.error('Invalid loggerId or newCompanyGUID.');
                    return;
                }

                // Make the API call
                CallGetAPI(CreateUrl(`/api/aquaguard/MoveLCLoggerCompany?loggerId=${loggerId}&newCompanyGUID=${newCompanyGUID}`), {})
                    .then(data => {
                        createSuccessNotification()
                        onSubmit()
                    })
                    .catch(error => {
                        createErrorNotification()
                        console.error('Error occurred during the API call:', error);
                    });
            }
        };
    
        const createSuccessNotification = (): void => {
            NotificationManager.success('Saved Changes', 'Success');

        };

        const createErrorNotification = (): void => {
            NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
                alert('callback');
            });
        };




    return (
        <Modal show={show} style={{  position: 'absolute', transform: 'translate(50%, 30%)' }} className="modal-50w">
            <Modal.Header>
            <Modal.Title>Move logger to new Company</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DataGrid
                    dataSource={companies}>
                    <GridColumn dataField="Id" visible={false} />
                    <GridColumn dataField="name" caption="company" />
                    <GridColumn dataField="contactEmail" caption="contact email" />
                    <GridColumn cellRender={renderButton} width={100 } />
                </DataGrid>
            </Modal.Body>
            <Modal.Footer>
                
                <Button variant="contained" color="secondary" onClick={onCancel }>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )
}
export default MoveLogger