import React, { useState, useEffect, useRef, useCallback } from 'react'
import { GoogleMap, LoadScriptNext, Polygon, Marker, OverlayView } from "@react-google-maps/api";
declare const google: any;

import { CreateUrl, CallGetAPI, CallPutAPI, CallPostAPI } from 'Utils/ApiHelper.js';
interface Props {
    containerStyle: ContainerStyle
    center: GPS
    onMapLoad: () => void
    markers: Array<MarkerProps>
    hasBoundary: boolean
    createBoundary: boolean
    polygonPath: Array<GPS>
    boundaryStyling: {
        strokeWeight: number
        strokeColor: string,
        areaColor: string,
        transparency: number
    }
    onPolygonClick: () => void
    updatePolygonPath: () => void
    handleMouseOverMarker: (serial: number) => void
    handleMouseOutMarker: () => void
    onMarkerClick: (serial: number) => void
    handleRightVertexClick: () => void
    hoveredMarker: number
    editBoundary: boolean
    showTooltip: boolean
}

interface ContainerStyle {
    height: string
    width: string
    marginLeft: string
    minHeight: string
}

interface GPS {
    lat: number,
    lng: number
}

interface MarkerProps {
    position: GPS
    icon: string
    tooltip: {
        model: string,
        img: string
    },
    serial: number
    loggerId: number
    key: number

}
const GoogleMapComponent: React.FC<Props> = ({
    containerStyle,
    center,
    onMapLoad,
    markers,
    hasBoundary,
    createBoundary,
    polygonPath,
    boundaryStyling,
    onPolygonClick,
    updatePolygonPath,
    handleMouseOverMarker,
    handleMouseOutMarker,
    onMarkerClick,
    hoveredMarker,
    editBoundary,
    showTooltip,
    handleRightVertexClick

}) => {



    const [mapKey, setMapKey] = useState<string>("");
    const polygonRef = useRef(null);
    const pathLengthRef = useRef(0); 

   

    const handleMouseOver = (serial: number):void => {
        handleMouseOverMarker(serial)
    }

    useEffect(() => {
        CallGetAPI(CreateUrl('/api/aquaguard/mapkey'), {})
            .then(data => {
                setMapKey(data)
            })
            .catch(err => console.log(err));
    }, [])

    const handlePathChange = useCallback((path, type = 'unknown', index = -1) => {
        if (!path || !path.getLength) return;

        const newLength = path.getLength();

        // Only update if length has changed or it's a set_at event
        if (newLength !== pathLengthRef.current || type === 'set_at') {
            pathLengthRef.current = newLength;

            const coordinates = Array.from({ length: newLength }, (_, i) => {
                const point = path.getAt(i);
                return {
                    lat: point.lat(),
                    lng: point.lng()
                };
            });

            updatePolygonPath(path);
        }
    }, [updatePolygonPath]);


    const handleDoubleClick = (e):void => {
        if (!polygonRef.current) return;
        handleRightVertexClick(e)
    }

    const mapStyles = [
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }], // Hide all labels for Points of Interest (e.g., hospital, park)
        },
        {
            featureType: "poi.business",
            elementType: "labels",
            stylers: [{ visibility: "off" }], // Hide business-related labels (e.g., shops, restaurants)
        },
        {
            featureType: "poi.medical",
            elementType: "labels",
            stylers: [{ visibility: "off" }], // Hide medical-related labels (e.g., hospital, clinic)
        },
        {
            featureType: "poi.place_of_worship",
            elementType: "labels",
            stylers: [{ visibility: "off" }], // Hide places of worship labels
        },
        {
            featureType: "poi.school",
            elementType: "labels",
            stylers: [{ visibility: "off" }], // Hide school labels
        }
    ];



    return (
        <div style={{width: "100%"} }>
        {mapKey && <LoadScriptNext googleMapsApiKey={mapKey}>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    onLoad={onMapLoad}
                    options={{
                        styles: mapStyles,
                        disableDefaultUI: false
                    }}
                >
                    {((hasBoundary || createBoundary) && polygonPath.length > 0) && 
                        <Polygon
                            paths={polygonPath}
                            options={{
                                editable: editBoundary,
                                draggable: false,
                                strokeColor: boundaryStyling.strokeColor,
                                strokeOpacity: 0.8,
                                strokeWeight: boundaryStyling.strokeWeight,
                                fillColor: boundaryStyling.areaColor,
                                fillOpacity: (boundaryStyling.transparency / 100),
                            }}
                            onClick={onPolygonClick}
                            onMouseUp={() => {
                                if (polygonRef.current) {
                                    const path = polygonRef.current.getPath();
                                    handlePathChange(path, 'mouseup');
                                }
                        }}
                            onDblClick={(e):void => handleDoubleClick(e)}
                        onLoad={(polygon) => {

                                polygonRef.current = polygon;
                                const path = polygon.getPath();
                                pathLengthRef.current = path.getLength();

                                // Set up listeners
                                const listeners = [
                                    path.addListener("insert_at", (index) => {
                                        handlePathChange(path, 'insert_at', index);
                                    }),
                                    path.addListener("set_at", (index) => {
                                        handlePathChange(path, 'set_at', index);
                                    }),
                                    path.addListener("remove_at", (index) => {
                                        handlePathChange(path, 'remove_at', index);
                                    })
                                ];

                                // Clean up on unmount
                                return () => {
                                    listeners.forEach(listener => {
                                        google.maps.event.removeListener(listener);
                                    });
                                };
                            }}
                        />

                    
                    }
                    {markers.length > 0 && markers.map((marker) => {           
                    return (<React.Fragment key={marker.serial}>
                        <Marker
                            key={marker.loggerId}
                            position={marker.position}
                            icon={marker.icon}
                            onMouseOver={(): void => handleMouseOver(marker.serial)}
                            onMouseOut={handleMouseOutMarker}
                            onClick={(): void => onMarkerClick(marker.serial)}
                            
                        />
                        {(hoveredMarker === marker.serial) && showTooltip && (
                            <OverlayView
                                key={marker.serial}
                                position={marker.position}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            >
                                
                                <div style={{ background: "white", padding: "5px", borderRadius: "3px", boxShadow: "0px 0px 6px rgba(0,0,0,0.2)", width: "250px", height: "100px", display: "flex", alignItems: "center" }}>
                                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", height: "100%", width: "100%" }}>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly", height: "100%" }} >
                                            <div style={{ marginTop: "10px" }}>
                                                <p><b>Serial: {marker.serial}</b></p>
                                                <p><b>Device Name: {marker.tooltip.deviceName}</b></p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </OverlayView>
                        )}
                    </React.Fragment>)
                })}
            </GoogleMap>
            </LoadScriptNext >}
        </div>
    )
}
export default GoogleMapComponent